/**
 * Only capital letters
 * @example
 * onlyCapitalLetters.test('good string'); // false
 * onlyCapitalLetters.test('GOOD STRING'); // true
 * onlyCapitalLetters.test('Good String'); // false
 * onlyCapitalLetters.test('GOODSTRING'); // true
 */
export const onlyCapitalLetters = /^[A-Z]*$/;

export const onlyLettersRegex = /^[A-Z]*$/i;

export const onlyLettersWithSpacesRegex = /^[A-Z'\s]*$/i;
