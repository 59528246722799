import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { createBulkUsers } from './create-bulk-users';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TBulkUploadResponse } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TUserBulkFileErrorResponse } from './user.types';

export const useBulkUploadUsers = (queryOptions?: TCustomUseMutationOptions<TBulkUploadResponse<TUserBulkFileErrorResponse>, AxiosError<{ uploaded_file: string[]; missing_columns?: string[] }>>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...queryOptions,
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.BULK_UPLOAD_USERS],
    mutationFn: createBulkUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.LIST_MANAGEMENT] });
    },
    onError: (error) => {
      const { response } = error;
      if (response && isClientError(error)) {
        const { uploaded_file, missing_columns } = response.data;
        if (uploaded_file) {
          showErrorToast('File error', uploaded_file.join('\n'));
          return;
        }
        if (missing_columns) {
          showErrorToast('Missing columns', missing_columns.join('\n'));
          return;
        }
        showErrorToast('System message', 'Unknown error occurred while uploading users');
      }
    },
  });
};
