import { Fragment } from 'react';
import { Input, Separator, Text } from '@purple/ui';
import type { TSafLanguages } from '~/pages/Main/Saf/constants';
import type { TAdminSubmitterTypeTextTranslationItem } from '~/services';

type TTextTranslationTableEditProperties = {
  data: TAdminSubmitterTypeTextTranslationItem[] | null;
  selectedLanguage: TSafLanguages;
  onChange: (data: TAdminSubmitterTypeTextTranslationItem[]) => void;
};

const TextTranslationTableEdit = ({ data, selectedLanguage, onChange }: TTextTranslationTableEditProperties) => {
  if (!data) return null;

  const translationChangeHandler = (itemId: string, value: string) => {
    const updatedData = data.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          translations: {
            ...item.translations,
            [selectedLanguage]: value,
          },
        };
      }
      return item;
    });

    onChange(updatedData);
  };

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-2 items-center gap-x-4 px-[20px] py-4">
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">FIELD NAME</Text>
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">TRANSLATION</Text>
      </div>
      <Separator className="bg-grey-200" />
      <div className="px-[20px] py-3">
        <Text variant="size-14" type="body-700" className="text-grey-950">My Information</Text>
      </div>
      {data.map((field) => (
        <Fragment key={field.id}>
          <Separator className="bg-grey-200" />
          <div className="grid grid-cols-2 items-center gap-x-4 px-[20px] py-4">
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.name}</Text>
            <div>
              <Input value={field.translations[selectedLanguage]} onChange={(event) => translationChangeHandler(field.id, event.target.value)} sizeVariant="small" />
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export { TextTranslationTableEdit };
