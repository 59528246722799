import { type FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { DISTRICT_STATUS, GRADE_CHOICES, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Button, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, NoDataAvailable, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableSelectedController, DataTableViewOptions, DistrictFilterComboBox, SchoolMultiSelect } from '~/components';
import { AddNewRuleModal, BulkImportRuleModal, EditRuleModal } from '~/components/Modals/Safs';
import { ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useSafRuleBulkDelete, useSafRuleFilters, useSafRuleListMutation, useSafsRuleList } from '~/services';
import { ASSIGNED_ROLE_QUERY_NAME, ASSIGNED_USER_QUERY_NAME, DISTRICT_QUERY_NAME, GRADES_QUERY_NAME, NEEDS_QUERY_NAME, SCHOOLS_QUERY_NAME } from './constants';
import { useSafsRuleColumns } from './useSafsRuleColumns';
import type { TAdminSafRuleItem } from '~/services';

export const SafRoutingRules: FC = () => {
  const [searchParameters] = useSearchParams();

  const [clickableSaf, setClickableSaf] = useState<TAdminSafRuleItem | null>(null);

  const { openModal: openDeleteSafModal, closeModal: closeDeleteSafModal } = useModal(
    ModalType.SAF_RULE_DELETE,
  );

  const { openModal: openDeleteSafBulkModal, closeModal: closeDeleteSafBulkModal } = useModal(
    ModalType.SAF_RULE_DELETE_BULK,
  );

  const { query: districtQuery, onQueryChange: onDistrictQueryChange, onClearQuery: onDistrictQueryClear } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });
  const { query: schoolsQuery, onQueryChange: onSchoolsQueryChange, onClearQuery: onSchoolsQueryClear } = useQueryParameter({ queryName: SCHOOLS_QUERY_NAME, resetOffset: true });

  useEffect(() => {
    if (!districtQuery) {
      onSchoolsQueryClear();
    }
  }, [districtQuery, onSchoolsQueryClear]);

  const { openModal: openAddNewRuleModal } = useModal(ModalType.ADD_NEW_RULE);
  const { openModal: openEditRuleModal } = useModal(ModalType.EDIT_RULE);
  const { openModal: openBulkImportRoutingRules } = useModal(ModalType.BULK_IMPORT_ROUTING_RULES);

  const { mutate: bulkDelete, isPending: isSafDeleting } = useSafRuleBulkDelete();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: safsData, isLoading: isSafsLoading } = useSafsRuleList({
    requestParameters: {
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      district: districtQuery || '',
      schools: schoolsQuery || '',
      assigned_roles: searchParameters.get(ASSIGNED_ROLE_QUERY_NAME) || '',
      assigned_users: searchParameters.get(ASSIGNED_USER_QUERY_NAME) || '',
      grades: searchParameters.get(GRADES_QUERY_NAME) || '',
      needs: searchParameters.get(NEEDS_QUERY_NAME) || '',
    },
  });

  const { data: filtersData } = useSafRuleFilters({
    requestParameters: {
      district: districtQuery || '',
      schools: schoolsQuery || '',
    },
    enabled: Boolean(districtQuery) && Boolean(schoolsQuery),
  });

  const safs = useMemo(() => safsData?.results ?? [], [safsData?.results]);

  const { mutate: getAllSafs, isPending: isGetAllSafsPending } = useSafRuleListMutation();

  const singleDeleteClickHandler = (item: TAdminSafRuleItem) => {
    setClickableSaf(item);
    openDeleteSafModal();
  };

  const confirmDeleteSafHandler = () => {
    if (clickableSaf) {
      bulkDelete({ ids: [clickableSaf.id] }, {
        onSuccess: () => {
          closeDeleteSafModal();
        },
      });
    }
  };

  const editRuleClickHandler = (item: TAdminSafRuleItem) => {
    setClickableSaf(item);
    openEditRuleModal();
  };

  const filterConfig = useMemo(
    () => {
      const gradeFilters = {
        grades: Object.values(GRADE_CHOICES).map((grade) => ({
          value: grade,
          label: grade,
        })),
      };

      const fetchedFilters = filtersData || {};

      const filters = {
        ...(fetchedFilters || {}),
        ...gradeFilters,
      };

      return convertToFilterConfig(filters, {
        snakeCaseValue: false,
      });
    },
    [filtersData],
  );

  const { table } = useDataTable({
    name: TableName.SAF_ROUTING_RULES,
    columns: useSafsRuleColumns({
      onDelete: singleDeleteClickHandler,
      onEdit: editRuleClickHandler,
    }),
    data: safs,
    rowCount: safsData?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
      },
    },
    getRowId: (originalRow) => originalRow.id,
    onSelectionChange: (rows, setSelectedRows) => {
      const selectedIds = Object.entries(rows).flatMap(([id, selected]) => (selected ? [id] : []));
      setSelectedRows(
        (prevRows) => [
          ...prevRows,
          ...safs.map(({ id, need }) => ({ id, name: need })),
        ]
          .filter((activity, index, self) => index === self.findIndex((a) => a.id === activity.id))
          .filter((activity) => selectedIds.includes(activity.id)),
      );
    },
  });

  const confirmBulkDeleteSafHandler = () => {
    const selectedRows = table.options.meta?.selectedRows ?? [];
    const ids = selectedRows.map((row) => row.original.id);
    if (ids.length) {
      bulkDelete({ ids }, {
        onSuccess: () => {
          closeDeleteSafBulkModal();
          table.options.meta?.clearSelectedRows?.();
        },
      });
    }
  };

  const selectAllFoundHandler = () => {
    getAllSafs({
      search: debounceSearch,
      limit: safsData?.count || 0,
      offset: 0,
      ordering: searchParameters.get(SORT_QUERY_NAME),
      ...(districtQuery && { district: districtQuery }),
      ...(schoolsQuery && { schools: schoolsQuery }),
    }, {
      onSuccess: (allSafsResponse) => {
        const selectedRows = allSafsResponse.results.reduce<Record<string, boolean>>((acc, item) => ({
          ...acc,
          [item.id]: true,
        }), {}) ?? {};
        table.setRowSelection(selectedRows);
        table.options.meta?.setSelectedRows?.(allSafsResponse.results.map((saf) => ({ id: saf.id, name: saf.need })) ?? []);
      },
    });
  };

  const selectedSchools = useMemo(() => {
    const array = schoolsQuery?.split(',') || [];
    return array;
  }, [schoolsQuery]);

  const canShowTable = useMemo(() => Boolean(districtQuery) && Boolean(schoolsQuery), [districtQuery, schoolsQuery]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4 p-4">
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <div className="min-w-[200px]">
              <DistrictFilterComboBox status={DISTRICT_STATUS.PUBLISHED} value={districtQuery || ''} onChange={onDistrictQueryChange} onClear={onDistrictQueryClear} placeholder="Select district" />
            </div>
            <div className="min-w-fit">
              <SchoolMultiSelect values={selectedSchools} onChange={(newValue) => onSchoolsQueryChange(newValue.join(','))} onClear={onSchoolsQueryClear} districtId={districtQuery} isDistrictRequired />
            </div>
            <AppFilters config={filterConfig} disabled={!canShowTable} />
            <SearchInput
              value={search}
              placeholder="Search"
              className="max-w-[300px]"
              onChange={onSearchChange}
              onClear={onClearSearch}
              disabled={!canShowTable}
            />
          </div>
          <div className="flex flex-row gap-4">
            <Button
              type="button"
              variant="destructive_secondary"
              size="default"
              disabled={table.options.meta?.selectedRows?.length === 0 || !canShowTable}
              onClick={openDeleteSafBulkModal}
            >
              Delete
            </Button>
            <DropdownRoot>
              <DropdownTrigger asChild className="[&>svg]:data-[state=open]:rotate-180">
                <Button type="button" variant="primary" iconRight={<PurpleIcon name="chevron-down" className="transition-transform" />}>Add New Rule</Button>
              </DropdownTrigger>
              <DropdownContent align="end" className="w-40">
                <DropdownItem iconName="document-add" onClick={openAddNewRuleModal}>
                  Add rule
                </DropdownItem>
                <DropdownItem iconName="clipboard-copy" onClick={openBulkImportRoutingRules}>
                  Bulk Import
                </DropdownItem>
              </DropdownContent>
            </DropdownRoot>
            {Boolean(canShowTable) && (
              <DataTableViewOptions table={table} />
            )}
          </div>
        </div>
        {Boolean(canShowTable) && (
          <DataTableSelectedController table={table} isSelectAllLoading={isGetAllSafsPending} onSelectAll={selectAllFoundHandler} />
        )}
      </div>
      {canShowTable
        ? (
            <DataTable
              table={table}
              loading={isSafsLoading}
            >
            </DataTable>
          )
        : (<NoDataAvailable iconName="folder-open" title="No Data Found" description="Please select district and school(s) to view data" className="min-h-96" />)}

      <CallToActionModal
        modalName={ModalType.SAF_RULE_DELETE}
        modalTitle="Delete SAF"
        modalDescription="Are you sure you want to delete the selected SAF rule?"
        modalTextContent="Are you sure you want to delete the selected SAF rule?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={confirmDeleteSafHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isSafDeleting}
      />
      <CallToActionModal
        modalName={ModalType.SAF_RULE_DELETE_BULK}
        modalTitle="Delete SAFs"
        modalDescription="Are you sure you want to delete the selected SAFs rules?"
        modalTextContent="Are you sure you want to delete the selected SAFs rules?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={confirmBulkDeleteSafHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isSafDeleting}
      />
      <AddNewRuleModal />
      <EditRuleModal id={clickableSaf?.id} />
      <BulkImportRuleModal />
    </div>
  );
};
