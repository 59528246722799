import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ActivityType } from '@purple/shared-types';
import { Form, FormControl, FormField, FormItem, FormMessage, Text, Textarea } from '@purple/ui';
import { type TActivityDetails, useUpdateActivity, useUpdateRecurringActivity } from '~/services';
import { ActivitySectionHeader } from '../../components';

const descriptionSchema = z
  .object({
    description: z.string().trim(),
  });

type TDescriptionSectionProps = {
  data: TActivityDetails;
};

export const DescriptionSection: React.FC<TDescriptionSectionProps> = (props) => {
  const { data } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateActivity, isPending } = useUpdateActivity();
  const { mutate: updateRecurringActivity, isPending: isRecurringPending } = useUpdateRecurringActivity();

  const defaultValues: z.infer<typeof descriptionSchema> = useMemo(
    () => ({
      description:
        data.details.community_activity_type.name === ActivityType.CRISIS_CALL_TRACKER
          ? data.details.description_of_crisis ?? ''
          : data.description ?? '',
    }),
    [data],
  );

  const form = useForm<z.infer<typeof descriptionSchema>>({
    resolver: zodResolver(descriptionSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof descriptionSchema>, recurringId?: number) => {
    const updateCallback = recurringId ? updateRecurringActivity : updateActivity;
    const entityId = recurringId ?? data.id;

    updateCallback(
      {
        id: entityId,
        ...(data.details.community_activity_type.name !== ActivityType.CRISIS_CALL_TRACKER && {
          description: formData.description,
        }),
        name: data.name,
        details: {
          school: data.details.school.id,
          ...(data.details.community_activity_type.name === ActivityType.CRISIS_CALL_TRACKER && {
            description_of_crisis: formData.description,
          }),
          ...(data.details.assigned_to && { assigned_to: data.details.assigned_to.id }),
          ...(data.details.service_categories && {
            service_categories: data.details.service_categories.map((category) => category.id),
          }),
          type: data.details.community_activity_type.name,
          duration: data.details.duration,
        },
        ...(recurringId !== null && {
          weekly_interval: data.details.recurring_group?.weekly_interval,
          days_of_week: data.details.recurring_group?.days_of_week,
          start_date_and_time: data.details.recurring_group?.start_date_and_time,
          end_date_and_time: data.details.recurring_group?.end_date_and_time,
        }),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          form.reset(formData);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <ActivitySectionHeader
        title="Description"
        editing={isEditing}
        loading={isPending}
        recurringLoading={isRecurringPending}
        allowRecurring={data.details.recurring_group !== null}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit((data) => saveDetailsClickHandler(data))}
        onRecurringSave={form.handleSubmit((formData) => saveDetailsClickHandler(formData, data.details.recurring_group?.id))}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="description"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        {...field}
                        isError={!!fieldState.error}
                        placeholder="Enter description here"
                        responsiveHeight
                        className="min-h-[80px] resize-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <Text className="text-balance font-primary text-base font-normal text-grey-950">
              {(data.details.community_activity_type.name === ActivityType.CRISIS_CALL_TRACKER
                ? data.details.description_of_crisis
                : data.description) ?? 'No description provided'}
            </Text>
          )}
    </div>
  );
};
