import React from 'react';
import { cn, Heading } from '@purple/ui';
import type { FC } from 'react';

type TContactTabContainerProperties = React.PropsWithChildren<
  React.ComponentPropsWithoutRef<'div'> & {
    title: string;
    headerClassName?: string;
    actions?: React.ReactNode;
  }
>;
export const ContactTabContainer: FC<TContactTabContainerProperties> = ({
  children,
  title,
  headerClassName,
  actions,
  className,
  ...rest
}) => {
  return (
    <div className="flex w-full flex-col">
      <div
        className={cn(
          'flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5',
          headerClassName,
        )}
      >
        <Heading tag="h2" variant="size-18" type="heading-600" className="leading-[25px]">
          {title}
        </Heading>
        {actions}
      </div>
      <div className={cn('flex w-full flex-col px-6 py-8', className)} {...rest}>
        {children}
      </div>
    </div>
  );
};
