import { TakeActionType } from '@purple/shared-types';
import { ALLOWED_BULK_TEMPLATES } from '@purple/shared-utils';

export const ACTIONS_QUERY_KEYS = {
  GET_ACTIONS_LIST: 'get-actions-list',
  GET_ACTION_TYPES_OPTIONS: 'get-action-types-options',
  GET_ACTIONS_LIST_FILTER_OPTIONS: 'get-actions-list-filter-options',
  GET_INDIVIDUAL_ACTION_DETAILS: 'get-individual-action-details',
  DELETE_ACTION: 'delete-action',
  UPDATE_ACTION_DETAILS: 'update-action_details',
  UPDATE_ACTION_TAGS: 'update-action-tags',
  GET_ACTION_CHOICES: 'get-action-choices',
  GET_ACTION_TYPES_LIST: 'get-action-types-list',
  GET_ACTIONS_STATISTICS: 'get-actions-statistics',
  GET_ACTION_TYPE: 'get-action-type',
  UPDATE_ACTION_TYPE: 'update-action-type',
  DOCUMENTED_ACTIONS_LIST: 'documented-actions-list',
  DOCUMENTED_ACTIONS_EXPORT: 'documented-actions-export',
  CREATE_BULK_ACTIONS: 'create-bulk-actions',
} as const;

export const BULK_TEMPLATE_TO_ACTION_TYPE_MAP = {
  [TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION]: ALLOWED_BULK_TEMPLATES.IMPORT_CAREER_PLANNING_SESSION_ACTIONS,
  [TakeActionType.CHECK_IN]: ALLOWED_BULK_TEMPLATES.IMPORT_CHECK_IN_ACTIONS,
  [TakeActionType.COUNSELING_SESSION]: ALLOWED_BULK_TEMPLATES.IMPORT_COUNSELING_SESSION_ACTIONS,
  [TakeActionType.CRISIS_RESPONSE_SESSION]: ALLOWED_BULK_TEMPLATES.IMPORT_CRISIS_RESPONSE_SESSION_ACTIONS,
  [TakeActionType.DISTRICT_INITIATIVE]: ALLOWED_BULK_TEMPLATES.IMPORT_DISTRICT_INITIATIVE_ACTIONS,
  [TakeActionType.GUIDANCE_COUNSELING_LESSON]: ALLOWED_BULK_TEMPLATES.IMPORT_GUIDANCE_COUNSELING_LESSON_ACTIONS,
  [TakeActionType.OBSERVATION]: ALLOWED_BULK_TEMPLATES.IMPORT_OBSERVATION_ACTIONS,
  [TakeActionType.REMINDER]: ALLOWED_BULK_TEMPLATES.IMPORT_REMINDER_ACTIONS,
  [TakeActionType.RESOURCE]: ALLOWED_BULK_TEMPLATES.IMPORT_RESOURCE_ACTIONS,
  [TakeActionType.SERVICE_LINK]: ALLOWED_BULK_TEMPLATES.IMPORT_SERVICE_LINK_ACTIONS,
  [TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING]: ALLOWED_BULK_TEMPLATES.IMPORT_STAKEHOLDER_COLLABORATIVE_MEETING_ACTIONS,
} as const;
