import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { addInvitePurpleUser } from './add-invite-pruple-user';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TUserManagement } from './user.types';

export const useAddInvitePurpleUser = (queryOptions?: TCustomUseMutationOptions<TUserManagement, AxiosError<{ email: string[] }>>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...queryOptions,
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.ADD_INVITE_PURPLE_USER],
    mutationFn: addInvitePurpleUser,
    onSuccess: (_, { email }) => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.LIST_MANAGEMENT] });
      showSuccessToast('System Message', `User has been invited successfully. An invitation email has been sent to ${email}`);
    },
    onError: (error) => {
      if (isClientError(error)) {
        if (error.response?.data.email) {
          showErrorToast('System Message', error.response.data.email.join(', '));
          return;
        }
        showErrorToast('System Message', 'Failed to invite user');
      }
    },
  });
};
