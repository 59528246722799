import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CallToActionModal, HierarchyNodeType, HierarchyView } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { SchoolHierarchyNodeType, useSchoolHierarchy, useSchoolHierarchyChoices, useUpdateSchoolHierarchy } from '~/services';
import { SchoolTabContainer } from '../../SchoolTabContainer';
import { adaptSchoolHierarchyToTreeData, adaptTreeDataToHierarchy, addNodeToTreeData, deleteNodeFromTreeData, editNodeInTreeData } from './helpers';
import { SchoolHierarchyStructureSkeleton } from './SchoolHierarchyStructureSkeleton';
import type { THierarchyBranch, THierarchyNode } from '~/components';

export const SchoolHierarchyStructureTab: React.FC = () => {
  const { id: schoolId } = useParams();

  const [dataTree, setDataTree] = useState<THierarchyNode[]>([]);
  const [deleteNodeId, setDeleteNodeId] = useState<string | number | null>(null);

  const { toggleModal: toggleDeleteModal } = useModal(ModalType.DELETE_SCHOOL_HIERARCHY_NODE);

  const { data: hierarchy, isFetching: isHierarchyFetching } = useSchoolHierarchy({ schoolId });
  const { data: choices, isFetching: isChoicesFetching } = useSchoolHierarchyChoices({
    schoolId: schoolId as string,
    node_types: Object.values(SchoolHierarchyNodeType),
  });
  const { mutate: updateHierarchy } = useUpdateSchoolHierarchy({ shouldRevalidate: false });

  const isLoading = useMemo(
    () => isHierarchyFetching || isChoicesFetching,
    [isHierarchyFetching, isChoicesFetching],
  );

  const schoolItemId = useMemo(
    () => dataTree.at(-1)?.children?.find((item) => item.type === HierarchyNodeType.SCHOOL)?.id,
    [dataTree],
  );

  useEffect(() => {
    setDataTree(adaptSchoolHierarchyToTreeData(hierarchy));
  }, [hierarchy]);

  const deleteNodeClickHandler = (nodeId: string | number) => {
    setDeleteNodeId(nodeId);
    toggleDeleteModal(true);
  };

  const deleteSubmitHandler = () => {
    if (!deleteNodeId) return;
    const savedTree = dataTree;
    const updatedTree = deleteNodeFromTreeData(dataTree, deleteNodeId);
    setDataTree(updatedTree);
    if (!schoolId) return;
    updateHierarchy({ school_id: schoolId, nodes: adaptTreeDataToHierarchy(updatedTree) }, {
      onError: () => {
        setDataTree(savedTree);
      },
    });
    toggleDeleteModal(false);
    setDeleteNodeId(null);
  };

  const addNodeSubmitHandler = (node: THierarchyBranch) => {
    const savedTree = [...dataTree];
    const updatedTree = addNodeToTreeData(dataTree, node);
    setDataTree(updatedTree);
    if (!schoolId) return;
    updateHierarchy({ school_id: schoolId, nodes: adaptTreeDataToHierarchy(updatedTree) }, {
      onSuccess: (data) => {
        setDataTree(adaptSchoolHierarchyToTreeData(data));
      },
      onError: () => {
        setDataTree(savedTree);
      },
    });
  };

  const editNodeClickHandler = (node: Pick<THierarchyNode, 'id' | 'name' | 'type'>) => {
    const savedTree = dataTree;
    const updatedTree = editNodeInTreeData(dataTree, node);
    setDataTree(updatedTree);
    if (!schoolId) return;
    updateHierarchy({ school_id: schoolId, nodes: adaptTreeDataToHierarchy(updatedTree) }, {
      onSuccess: (data) => {
        setDataTree(adaptSchoolHierarchyToTreeData(data));
      },
      onError: () => {
        setDataTree(savedTree);
      },
    });
  };

  if (isLoading || !hierarchy) return <SchoolHierarchyStructureSkeleton />;

  return (
    <SchoolTabContainer title="Hierarchy Structure" className="gap-6 lg:flex-row">
      <HierarchyView
        data={dataTree}
        nodeOptions={choices ?? []}
        initialSelectedItemId={schoolItemId}
        expandAll
        disableCollapse
        className="w-fit min-w-80"
        onNodeAdd={addNodeSubmitHandler}
        onNodeDelete={deleteNodeClickHandler}
        onNodeUpdate={editNodeClickHandler}
      />
      <CallToActionModal
        modalName={ModalType.DELETE_SCHOOL_HIERARCHY_NODE}
        modalTitle="Delete hierarchy node"
        modalDescription="By deleting this node, you will also delete all its children nodes. Are you sure you want to delete this node?"
        modalTextContent="Are you sure you want to remove node?"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteSubmitHandler}
        primaryButtonVariant="destructive_primary"
      />
    </SchoolTabContainer>
  );
};
