import { useMutation } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { addContactStudents } from './add-contact-students';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useAddContactStudents = (queryOptions?: TCustomUseMutationOptions<AxiosResponse, AxiosError>) => {
  return useMutation({
    ...queryOptions,
    mutationKey: [CONTACTS_QUERY_KEYS.ADD_CONTACT_STUDENTS],
    mutationFn: addContactStudents,
    onError: (error) => {
      if (isClientError(error)) {
        showErrorToast('System error', 'Unable to add students to the contact');
      }
    },
  });
};
