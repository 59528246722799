import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { getContactsStatistic } from './get-contacts-statistic';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TContactsStatisticsResponse } from './contacts.types';

export const useContactsStatistic = (queryOptions?: TCustomUseQueryOptions<TContactsStatisticsResponse, AxiosError>) => {
  const { isError, error, ...rest } = useQuery({
    ...queryOptions,
    queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_STATISTICS],
    queryFn: getContactsStatistic,
  });

  useEffect(() => {
    if (isError && isClientError(error)) {
      showErrorToast('System message', 'Unable to retrieve contacts statistic');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
