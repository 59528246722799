import { useMutation } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { sendEmailToContacts } from './send-email-to-contacts';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TContactsSendEmailPayload } from './contacts.types';

export const useSendEmailToContacts = (
  options?: TCustomUseMutationOptions<unknown, AxiosError, TContactsSendEmailPayload>,
) => {
  return useMutation({
    ...options,
    mutationKey: [CONTACTS_QUERY_KEYS.SEND_EMAIL_TO_CONTACTS],
    mutationFn: sendEmailToContacts,
    onSuccess: (...args) => {
      showSuccessToast('System message', 'The email was sent successfully');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to send email');
      options?.onError?.(...args);
    },
  });
};
