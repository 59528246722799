import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import { transferSaf } from './transfer-saf';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TAdminTransferSafPayload } from './saf.types';

export const useTransferSaf = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, { payload: TAdminTransferSafPayload }>({
    mutationKey: [ADMIN_SAF_QUERY_KEYS.EDIT_SAF_ROUTING_RULE],
    mutationFn: transferSaf,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System Message', 'Failed to transfer saf');
      }
    },
    onSuccess: () => {
      showSuccessToast('System message', 'The SAF has been successfully transferred');
      queryClient.invalidateQueries({ queryKey: [ADMIN_SAF_QUERY_KEYS.LIST] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_SAF_QUERY_KEYS.DETAIL] });
    },
  });
};
