import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getActionsListFilterOptions } from './get-actions-list-filter-options';
import type { AxiosError } from 'axios';
import type { TActionFiltersRequestParameters, TStudentCenteredActionsFilterOptions } from './actions.types';

export const useActionsListFilterOptions = (parameters?: TActionFiltersRequestParameters) => {
  const { isError, error, ...rest } = useQuery<TStudentCenteredActionsFilterOptions, AxiosError>({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_LIST_FILTER_OPTIONS, parameters],
    queryFn: () => getActionsListFilterOptions(parameters),
  });

  useEffect(() => {
    if (isError && isClientError(error)) {
      showErrorToast('System message', 'Unable to fetch student centered actions filter options');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
