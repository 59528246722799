import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PERMISSIONS_QUERY_KEYS } from '@purple/shared-utils';
import { useAppSelector } from '~/hooks';
import { userSelector } from '~/store/features/user';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { removeUserDetailsNegativePermission } from './remove-user-details-negative-permission';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useRemoveUserDetailsNegativePermission = (queryOptions?: TCustomUseMutationOptions<AxiosResponse, AxiosError>) => {
  const queryClient = useQueryClient();
  const { id } = useAppSelector(userSelector);
  return useMutation({
    ...queryOptions,
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.REMOVE_USER_DETAILS_NEGATIVE_PERMISSION],
    mutationFn: removeUserDetailsNegativePermission,
    onSuccess: (_, { userId }) => {
      if (userId === id) {
        queryClient.invalidateQueries({ queryKey: [PERMISSIONS_QUERY_KEYS.GET_USER_PERMISSIONS] });
      }
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_USER_DETAILS_PERMISSIONS] });
      showSuccessToast('System message', 'User negative permission removed successfully');
    },
    onError: (error) => {
      if (isClientError(error)) {
        showErrorToast('System message', 'Unable to remove user negative permission');
      }
    },
  });
};
