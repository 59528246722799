import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { nanoid } from '@reduxjs/toolkit';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { Button, Form, FormField, FormMessage, Text } from '@purple/ui';
import { SafNeedTypeFormItem } from '~/pages/Main/Districts/Details/components/tabs/SafProcess/SafNeedTypeFormItem';
import { ADMIN_SUBMITTER_TYPES_QUERY_KEYS, type TAdminSubmitterTypeDetail, useUpdateDistrictSafProcess } from '~/services';
import { sectionSchema } from './schema';
import type { FieldArrayWithId } from 'react-hook-form';
import type { z } from 'zod';

type TNeedTypesAreaProperties = {
  data: TAdminSubmitterTypeDetail;
};

const NeedTypesArea = ({ data }: TNeedTypesAreaProperties) => {
  const { needs, type, district } = data;

  const queryClient = useQueryClient();

  const filteredNeeds = useMemo(() => {
    return needs.filter((need) => need.submitter_types.includes(type));
  }, [needs, type]);

  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate: updateDistrictSafProcess, isPending } = useUpdateDistrictSafProcess();

  const defaultValues: z.infer<typeof sectionSchema> = useMemo(
    () => ({
      needs: Array.isArray(needs) ? needs : [],
    }),
    [needs],
  );

  const form = useForm<z.infer<typeof sectionSchema>>({
    resolver: zodResolver(sectionSchema),
    mode: 'onChange',
    defaultValues,
  });

  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: 'needs',
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const saveClickHandler = (formData: z.infer<typeof sectionSchema>) => {
    if (district) {
      const updatedNeeds = formData.needs.map((item) => {
        const targetNeed = needs?.find((need) => need.id === item.id);
        const { id: _id, ...itemWithoutId } = item;
        return targetNeed ? item : itemWithoutId;
      });
      updateDistrictSafProcess(
        {
          id: district,
          needs: updatedNeeds,
        },
        {
          onSuccess: () => {
            setMode('view');
            queryClient.invalidateQueries({ queryKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.DETAIL] });
            queryClient.invalidateQueries({ queryKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.LIST] });
          },
        },
      );
    }
  };

  const addNeedItemClickHandler = () => {
    append({
      id: nanoid(),
      name: `Need ${fields.length + 1}`,
      submitter_types: [type],
    });
  };

  const removeNeedItemHandler = (index: number) => {
    remove(index);
  };

  const needsItemSelectChangeHandler = (
    index: number,
    field: FieldArrayWithId<z.infer<typeof sectionSchema>, 'needs'>,
    value: boolean,
  ) => {
    const updatedTypes
      = value
        ? [...field.submitter_types, type]
        : !value
            ? field.submitter_types.filter((submitter_type) => submitter_type !== type)
            : field.submitter_types;

    const targetField = form.getValues('needs')[index] ?? field;

    update(index, {
      ...targetField,
      submitter_types: updatedTypes,
    });
  };

  return (
    <div className="flex flex-col gap-[22px] px-6 py-5">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-1">
          <Text variant="size-16" type="body-500" className="text-grey-title">Need Types available for this group of users</Text>
          <Text variant="size-16" type="body-400" className="text-grey-600">Set the types of needs based on the group of users</Text>
        </div>
        <div>
          {mode === 'view' && (
            <div className="flex flex-row gap-[12px]">
              <Button size="link" variant="link" iconLeft={<PurpleIcon name="pencil" />} onClick={() => setMode('edit')}>
                Edit
              </Button>
            </div>
          )}
          {mode === 'edit' && (
            <div className="flex flex-row gap-[12px]">
              <Button size="small" variant="secondary" onClick={() => setMode('view')}>
                Cancel
              </Button>
              <Button size="small" variant="primary" onClick={form.handleSubmit(saveClickHandler)} isLoading={isPending}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
      {mode === 'view' && (
        <ul className="flex w-full flex-col gap-2">
          {filteredNeeds
            .map((item) => (
              <li key={item.id}>
                <Text variant="size-16" type="body-400" className="text-grey-800">
                  {item.name}
                </Text>
              </li>
            ))}
        </ul>
      )}
      {mode === 'edit' && (
        <Form providerProps={form} className="flex w-full flex-col gap-2">
          {fields.map((field, index) => (
            <SafNeedTypeFormItem
              key={field.id}
              control={form.control}
              value={field.name}
              id={field.id}
              name={`needs.${index}.name`}
              selected={
                field.submitter_types.includes(type)
              }
              intermediate={false}
              disableRemove={fields.length === 1}
              onCheckedChange={(value) => needsItemSelectChangeHandler(index, field, value)}
              onRemove={() => removeNeedItemHandler(index)}
            />
          ))}
          <FormField name={'needs.root' as 'needs'} control={form.control} render={() => <FormMessage />} />
          <Button
            type="button"
            variant="tertiary"
            size="small"
            className="w-fit"
            iconLeft={<PurpleIcon name="plus" />}
            onClick={addNeedItemClickHandler}
          >
            Add new need
          </Button>
        </Form>

      )}
    </div>
  );
};

export { NeedTypesArea };
