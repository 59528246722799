import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DistrictUserStatusToBadgeVariant, formatDateShortMonth, formatDateShortMonthWithTime, getGradeGroups, getGradeLabel, ReadableDistrictUserStatus } from '@purple/shared-utils';
import {
  Button,
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
  NumberBadge,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TUserManagement } from '~/services';

type TUseSchoolUsersColumns = () => ColumnDef<TUserManagement>[];

export const useSchoolUsersColumns: TUseSchoolUsersColumns = () => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="link" className="line-clamp-1 h-auto w-fit min-w-0 break-all p-0" asChild>
                  <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: row.original.id } })}>
                    {row.original.full_name || '-'}
                  </Link>
                </Button>
              </TooltipTrigger>
              {row.original.full_name && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 180,
          meta: { label: 'Full Name' },
        },
        {
          accessorKey: 'status',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
          cell: ({ row }) => row.original.status === null
            ? '-'
            : (
                <NumberBadge
                  variant={DistrictUserStatusToBadgeVariant[row.original.status]}
                  className="rounded-full capitalize"
                >
                  {ReadableDistrictUserStatus[row.original.status]}
                </NumberBadge>
              ),
          size: 120,
          enableSorting: false,
          meta: { className: 'text-center', label: 'Status' },
        },
        {
          accessorKey: 'email',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.email || '-'}</span>
              </TooltipTrigger>
              {row.original.email && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.email}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 180,
          meta: { label: 'Email' },
        },
        {
          accessorKey: 'grades',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Grades" />,
          cell: ({ row }) => (
            <HoverCard>
              <HoverCardTrigger asChild>
                <NumberBadge variant="info" className="col-span-1 place-self-center text-nowrap rounded-full px-3 text-center text-sm font-medium text-grey-950">
                  {getGradeLabel(row.original.grades)}
                </NumberBadge>
              </HoverCardTrigger>
              {row.original.grades?.length > 0 && (
                <HoverCardPortal>
                  <HoverCardContent
                    onClick={(evt) => evt.stopPropagation()}
                    className="inline-flex w-full max-w-64 justify-center whitespace-pre-wrap border border-grey-200 p-2 text-xs font-medium leading-5 text-grey-950"
                  >
                    {getGradeGroups(row.original.grades)}
                  </HoverCardContent>
                </HoverCardPortal>
              )}
            </HoverCard>
          ),
          size: 120,
          enableSorting: false,
          meta: { className: 'text-center', label: 'Grades' },
        },
        {
          accessorKey: 'role',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Text type="body-500" variant="size-14" className="line-clamp-1 break-all capitalize text-grey-950">
                  {row.original.prounitas_roles?.join(', ') || '-'}
                </Text>
              </TooltipTrigger>
              {row.original.prounitas_roles?.length > 0 && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
                    {row.original.prounitas_roles.join('\n')}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 180,
          enableSorting: false,
        },
        {
          accessorKey: 'date_joined',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1">
                  {row.original.date_joined ? formatDateShortMonth(row.original.date_joined) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.date_joined && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(row.original.date_joined)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 130,
          meta: { label: 'Date Added' },
        },
        {
          accessorKey: 'last_login',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Last Login" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1">
                  {row.original.last_login ? formatDateShortMonth(row.original.last_login) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.last_login && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(row.original.last_login)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 130,
          enableSorting: false,
          meta: { label: 'Last Login' },
        },
      ] satisfies ColumnDef<TUserManagement>[],
    [],
  );

  return columns;
};
