import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  formatDateShortMonth,
  formatDateShortMonthWithTime,
  snakeToCamelWithSpaces,
} from '@purple/shared-utils';
import {
  NumberBadge,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import { SafPriorityToBadgeVariant, SafStatusToBadgeVariant } from './helpers';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminSafItem } from '~/services';

type TUseSchoolSafsColumns = () => ColumnDef<TAdminSafItem>[];

export const useSchoolSafsColumns: TUseSchoolSafsColumns = () => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'saf_number',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Saf Number" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to={AdminRoutes.App.Safs.SafDetail.makePath({ dynamicParameters: { id: row.original.id } })}
                  className="line-clamp-1 min-w-0 cursor-pointer break-all text-brand-blue-700"
                >
                  {row.original.saf_number}
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {row.original.saf_number}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 100,
          meta: { label: 'Saf Number' },
        },
        {
          accessorKey: 'subject',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Subject" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all text-grey-950">
                  {row.original.subject}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {row.original.subject}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 180,
          meta: { label: 'Subject' },
          enableSorting: false,
        },
        {
          accessorKey: 'priority',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Priority" />,
          cell: ({ row }) => (
            row.original.priority
              ? (
                  <NumberBadge variant={SafPriorityToBadgeVariant[row.original.priority]} className="rounded-full capitalize">
                    {row.original.priority}
                  </NumberBadge>
                )
              : <span className="line-clamp-1 min-w-0 break-all text-grey-950">-</span>
          ),
          size: 120,
          meta: { label: 'Priority' },
        },
        {
          accessorKey: 'submitter__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Submitted By" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                {row.original.created_by
                  ? (
                      <Link
                        to={AdminRoutes.App.Contacts.Details.makePath({ dynamicParameters: { contactId: row.original.created_by.id } })}
                        className="line-clamp-1 min-w-0 cursor-pointer break-all text-brand-blue-700"
                      >
                        {row.original.created_by.full_name}
                      </Link>
                    )
                  : (
                      <span className="line-clamp-1 min-w-0 break-all">
                        Anonymous
                      </span>
                    )}
              </TooltipTrigger>
              {row.original.created_by && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.created_by.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 120,
          meta: { label: 'Submitted By' },
        },
        {
          accessorKey: 'current_owner__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Owner" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                {row.original.current_owner
                  ? (
                      <Link
                        to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: row.original.current_owner.id } })}
                        className="line-clamp-1 min-w-0 cursor-pointer break-all text-brand-blue-700"
                      >
                        {row.original.current_owner.full_name}
                      </Link>
                    )
                  : (
                      <span className="line-clamp-1 min-w-0 break-all">-</span>
                    )}
              </TooltipTrigger>
              {row.original.current_owner && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.current_owner.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 120,
          meta: { label: 'Owner' },
        },
        {
          accessorKey: 'status',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
          cell: ({ row }) => (
            row.original.status
              ? (
                  <NumberBadge variant={SafStatusToBadgeVariant[row.original.status]} className="rounded-full capitalize">
                    {snakeToCamelWithSpaces(row.original.status)}
                  </NumberBadge>
                )
              : <span className="line-clamp-1 min-w-0 break-all text-grey-950">-</span>
          ),
          size: 120,
          meta: { label: 'Status' },
        },
        {
          accessorKey: 'student__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Student" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.student?.full_name || '-'}</span>
              </TooltipTrigger>
              {row.original.student && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.student.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 120,
          meta: { label: 'Student' },
        },
        {
          accessorKey: 'created_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Created On" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.created_at ? formatDateShortMonth(row.original.created_at) : '—'}
                </span>
              </TooltipTrigger>
              {row.original.created_at && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(row.original.created_at)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 120,
          meta: { label: 'Created On' },
        },
      ] satisfies ColumnDef<TAdminSafItem>[],
    [],
  );

  return columns;
};
