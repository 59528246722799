import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { cutGradeName } from '@purple/shared-utils';
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentsSearchListItem } from '~/services/students/students.types';

export const connectedStudentsColumns: ColumnDef<TStudentsSearchListItem>[] = [
  {
    accessorKey: 'full_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
    cell: ({ row: { original } }) => (
      original.full_name && original.full_name.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="link" asChild>
                  {/* add navigation to student details when it will be ready */}
                  <Link to="#">
                    <span className="line-clamp-1 w-fit break-all">
                      {original.full_name}
                    </span>
                  </Link>
                </Button>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {original.full_name}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : <span>—</span>
    ),
    size: 250,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: 'grade',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Grade" />,
    cell: ({ row: { original } }) => original.grade && original.grade.length > 0 ? <span className="text-nowrap">{cutGradeName(original.grade)}</span> : <span>—</span>,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: 'school',
    header: ({ column }) => <DataTableColumnHeader column={column} title="School" />,
    cell: ({ row: { original } }) => (
      original.school && original.school.name.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 w-fit break-all">
                  {original.school.name}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {original.school.name}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : <span>—</span>
    ),
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: 'district',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row: { original } }) => (
      original.district && original.district.name.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 w-fit break-all">
                  {original.district.name}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {original.district.name}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : <span>—</span>
    ),
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: 'subpopulations',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Subpopulations" />,
    cell: ({ row: { original } }) => (
      original.subpopulations && original.subpopulations.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 w-fit break-all">
                  {original.subpopulations.join(', ')}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {original.subpopulations.join(', ')}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : <span>—</span>
    ),
  },
  {
    id: 'actions',
    cell: ({ row: { original }, table }) => (
      table.options.meta?.studentsCount > 1
        ? (
            <Button variant="tertiary" className="h-auto p-2 hover:bg-error-bg active:bg-error-bg" onClick={() => table.options.meta?.setSelectedStudentId(original.id)}>
              <PurpleIcon name="trash" className="size-5 text-error-main" />
            </Button>
          )
        : null
    ),
    meta: { className: 'text-right' },
  },
];
