import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { formatDateShortMonth, formatDateShortMonthWithTime } from '@purple/shared-utils';
import {
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader, SelectableCell } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TPriorityListContactItem } from '~/services';

type TUseContactColumns = () => ColumnDef<TPriorityListContactItem>[];

export const useContactColumns: TUseContactColumns = () => {
  const columns = useMemo(
    () =>
      [
        SelectableCell(),
        {
          accessorKey: 'full_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link to={AdminRoutes.App.Contacts.Details.makePath({ dynamicParameters: { contactId: row.original.id } })}>
                  <span className="line-clamp-1 break-all text-brand-blue-700">{row.getValue('full_name')}</span>
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {row.getValue('full_name')}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 250,
          enableHiding: false,
          enableSorting: false,
          meta: { label: 'Full Name' },
        },
        {
          accessorKey: 'email',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Text type="body-500" variant="size-14" className="line-clamp-1 w-full break-all text-grey-950">
                  {row.original.email || '-'}
                </Text>
              </TooltipTrigger>
              {row.original.email && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.email}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 250,
          enableSorting: false,
          enableHiding: false,
        },
        {
          accessorKey: 'date_added',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Text type="body-500" variant="size-14" className="line-clamp-1 break-all capitalize text-grey-950">
                  {formatDateShortMonth(row.original.date_added) || '-'}
                </Text>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {formatDateShortMonthWithTime(row.original.date_added)}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 150,
          enableHiding: false,
          enableSorting: false,
          meta: { label: 'Date Added' },
        },
      ] satisfies ColumnDef<TPriorityListContactItem>[],
    [],
  );

  return columns;
};
