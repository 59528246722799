import { useMutation } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { getExportActivities } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActivityListQueryParameters } from '../activities.types';

export const useExportActivities = (
  params?: TActivityListQueryParameters,
  mutationOptions?: TCustomUseMutationOptions<Blob, AxiosError>,
) => {
  return useMutation({
    mutationKey: [ACTIVITIES_QUERY_KEYS.EXPORT, params],
    mutationFn: () => getExportActivities(params),
    onSuccess: (data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = `activities_${new Date().toISOString()}.xlsx`;
      document.body.append(link);
      link.click();
      link.remove();
      // Clean up the URL to prevent memory leaks
      window.URL.revokeObjectURL(url);

      showSuccessToast('System message', 'Table exported successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to export activities');
    },
    ...mutationOptions,
  });
};
