import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { DistrictStatusToBadgeVariant, formatDateShortMonth } from '@purple/shared-utils';
import {
  Button,
  cn,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Heading,
  NumberBadge,
} from '@purple/ui';
import { CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useDistrict, useUpdateDistrictStatus } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { DistrictInfoSkeleton } from './DistrictInfoSkeleton';

export const DistrictInfo: React.FC = () => {
  const { id } = useParams();

  const { openModal: openStatusModal, closeModal: closeStatusModal } = useModal(ModalType.CHANGE_DISTRICT_STATUS);

  const { data: district, isFetching } = useDistrict({ id: id as string });
  const { mutate: updateStatus, isPending: isStatusPending } = useUpdateDistrictStatus();

  const districtStatusUpdateHandler = useCallback(() => {
    if (!district) {
      return showErrorToast('District not found', 'Please select a district to update its status');
    }

    if (district.status === DISTRICT_STATUS.DRAFT) {
      return showErrorToast('District status error', 'Draft districts cannot be activated');
    }

    updateStatus(
      {
        id: district.id,
        status:
          district.status === DISTRICT_STATUS.PUBLISHED
            ? DISTRICT_STATUS.DEACTIVATED
            : district.status === DISTRICT_STATUS.DEACTIVATED
              ? DISTRICT_STATUS.PUBLISHED
              : district.status,
      },
      {
        onSuccess: () => {
          closeStatusModal();
        },
      },
    );
  }, [district, updateStatus, closeStatusModal]);

  if (isFetching || !district) {
    return <DistrictInfoSkeleton />;
  }

  return (
    <section className="flex w-full flex-wrap items-start gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex size-[60px] shrink-0 items-center justify-center rounded-lg bg-grey-100">
        <PurpleIcon name="disctrict" className="size-6 text-brand-blue-700" />
      </div>
      <div className="flex flex-col gap-4">
        <Heading tag="h1" variant="size-18" type="heading-600" className="text-base">
          {district.name}
        </Heading>
        <DescriptionList className="flex-col items-start gap-6 md:flex-row">
          <DescriptionItem className="w-auto flex-col items-start gap-1">
            <DescriptionTerm className="text-sm">Status</DescriptionTerm>
            <DescriptionDetails className="m-0">
              <NumberBadge variant={DistrictStatusToBadgeVariant[district.status]} className="rounded-full capitalize">
                {district.status}
              </NumberBadge>
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem className="w-auto flex-col items-start gap-1">
            <DescriptionTerm className="text-sm">Subdomain</DescriptionTerm>
            <DescriptionDetails className="m-0">
              <NumberBadge variant="info">{district.subdomain ?? '-'}</NumberBadge>
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem className="w-auto flex-col items-start gap-1">
            <DescriptionTerm className="text-nowrap text-sm">Create date/by:</DescriptionTerm>
            <DescriptionDetails className="m-0 inline-flex min-w-0 max-w-40 shrink-0 flex-col font-primary text-sm font-medium text-grey-950">
              <span>{district.created_at ? formatDateShortMonth(district.created_at) : '-'}</span>
              <strong className="inline-block truncate font-medium">{district.created_by?.full_name ?? '-'}</strong>
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem className="w-auto flex-col items-start gap-1">
            <DescriptionTerm className="text-nowrap text-sm">Last Updated:</DescriptionTerm>
            <DescriptionDetails className="m-0 inline-flex min-w-0 max-w-40 shrink-0 flex-col font-primary text-sm font-medium text-grey-950">
              <span>{district.updated_at ? formatDateShortMonth(district.updated_at) : '-'}</span>
              <strong className="inline-block truncate font-medium">
                {district.last_modified_by?.full_name ?? '-'}
              </strong>
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem className="w-auto flex-col items-start gap-1">
            <DescriptionTerm className="text-nowrap text-sm">Published:</DescriptionTerm>
            <DescriptionDetails className="m-0 inline-flex min-w-0 max-w-40 shrink-0 flex-col font-primary text-sm font-medium text-grey-950">
              <span>{district.published_at ? formatDateShortMonth(district.published_at) : '-'}</span>
              <strong className="inline-block truncate font-medium">{district.published_by?.full_name ?? '-'}</strong>
            </DescriptionDetails>
          </DescriptionItem>
        </DescriptionList>
      </div>
      <DropdownRoot>
        <DropdownTrigger asChild>
          <Button
            type="button"
            variant="secondary"
            size="icon_40"
            className="ml-auto size-10"
            iconLeft={<PurpleIcon name="dots-vertical" className="size-5 shrink-0" />}
          />
        </DropdownTrigger>
        <DropdownContent className="max-w-[150px] gap-1" align="end">
          <DropdownItem
            iconName={district.status === DISTRICT_STATUS.PUBLISHED ? 'X' : 'check'}
            className={cn(
              'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
              {
                'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main':
                  district.status === DISTRICT_STATUS.PUBLISHED,
              },
            )}
            onSelect={openStatusModal}
          >
            {district.status === DISTRICT_STATUS.PUBLISHED ? 'Deactivate' : 'Activate'}
          </DropdownItem>
        </DropdownContent>
      </DropdownRoot>
      <CallToActionModal
        modalName={ModalType.CHANGE_DISTRICT_STATUS}
        modalTitle={`${district.status === DISTRICT_STATUS.PUBLISHED ? 'Deactivate' : 'Activate'} District`}
        modalDescription="By changing the status of the district, you will change its access on the Purple Sense platform."
        modalTextContent={`Are you sure you want to ${
          district.status === DISTRICT_STATUS.PUBLISHED ? 'deactivate' : 'activate'
        } ${district.name} district?`}
        primaryButtonText={district.status === DISTRICT_STATUS.PUBLISHED ? 'Deactivate' : 'Activate'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={districtStatusUpdateHandler}
        primaryButtonVariant={district.status === DISTRICT_STATUS.PUBLISHED ? 'destructive_primary' : 'primary'}
        isLoading={isStatusPending}
      />
    </section>
  );
};
