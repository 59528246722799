import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PERMISSIONS_QUERY_KEYS } from '@purple/shared-utils';
import { useAppDispatch } from '~/hooks';
import { setPermissions } from '~/store/features/user-permissions';
import { queryRetryHelper } from '~/utils/api-requests';
import { getUserPermissions } from './get-user-permissions';

export const useGetUserPermissions = ({ enabled }: { enabled: boolean }) => {
  const dispatch = useAppDispatch();

  const { isError, data, isLoading, isSuccess } = useQuery({
    queryKey: [PERMISSIONS_QUERY_KEYS.GET_USER_PERMISSIONS],
    queryFn: () => getUserPermissions(),
    enabled,
    retry: queryRetryHelper,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setPermissions(data.permissions));
    }
  }, [isSuccess, data, dispatch]);

  return { isLoading, isError, data: data?.permissions };
};
