import type { TAppPermissions } from '@purple/permissions';
import type { IBasePaginationRequestParameters, IPaginatedResponse, IUser, TDistrictUserStatus, TFileField, TUserSchool, ValueOf } from '@purple/shared-types';
import type { USER_PERMISSION_TYPE } from './user.const';

export type TAdminUsersRequestParameters = IBasePaginationRequestParameters & {
  school?: string[];
  district?: string;
  status?: TDistrictUserStatus;
  is_active?: boolean;
};

export type TAdminUsersManagementRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
  district?: string | number | null;
  school?: string | null;
  status?: string | null;
  role?: string | null;
  token?: string | null;
};

export type TUserManagement = {
  id: string;
  date_joined: string;
  last_login?: string | null;
  email: string;
  full_name: string;
  grades: string[];
  prounitas_roles: string[];
  status: TDistrictUserStatus | null;
  district: string[];
  schools: TUserSchool[];
};

export type TUsersManagementResponse = IPaginatedResponse<TUserManagement[]>;

export type TUserManagementFilterOptionsRequestParameters = {
  district?: number;
};

export type TUserManagementFilterOptions = {
  role: string[];
  status: string[];
  school: {
    id: string;
    name: string;
  }[];
};

// NOTE: key is user id, value is array of school names
export type TUsersRoutingRuleCheck = Record<string, string[]>;

export type TUsersDeactivateCheck = {
  routing_rule_users: TUsersRoutingRuleCheck;
  opened_saf_users: string[];
  valid_users: string[];
};

export type TUserActivatePayload = {
  users: string[];
};

export type TUserRole = {
  id: number;
  name: string;
};

export type TUserInvitedBy = {
  id: string;
  email: string;
  full_name: string;
  avatar: string | null;
  title: string;
};

export type TUserDetailsInfo = {
  id: string;
  avatar: string | null;
  email: string;
  current_status: TDistrictUserStatus;
  roles: TUserRole[];
  date_joined: string;
  invited_by: TUserInvitedBy | null;
  first_name: string;
  last_name: string;
};

export type TUserBasicInfo = TUserDetailsInfo & {
  phone_number: string;
  title: string | null;
  department: string;
  responsible_for_grades: string[];
  is_show_on_scorecard: boolean;
  description: string;
  groups: {
    id: number;
    name: string;
  }[];
  purple_stats: {
    actions_created: 0;
    saf_prioritization_rate: 0;
    log_in: 4;
  };
};

export type TUserBasicDetailUpdatePayload = {
  prounitas_roles?: number[];
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  title?: string;
  department?: string;
  responsible_for_grades?: string[];
  is_show_on_scorecard?: boolean;
  description?: string;
  prounitas_groups?: number[];
};

export type TAdminUserLoginHistoryRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
};

export const UserLoginHistoryStatus = {
  SUCCESSFUL: 'successful',
  ERROR: 'failed',
} as const;
export type TUserLoginHistoryStatus = ValueOf<typeof UserLoginHistoryStatus>;

export const UserLoginHistoryType = {
  STANDARD: 'standard',
  SSO: 'sso',
  MOBILE: 'mobile',
} as const;
export type TUserLoginHistoryType = ValueOf <typeof UserLoginHistoryType>;

export const ReadableUserLoginHistoryType = {
  [UserLoginHistoryType.STANDARD]: 'Standard',
  [UserLoginHistoryType.SSO]: 'SSO',
  [UserLoginHistoryType.MOBILE]: 'Mobile',
} as const;

export type TUserLoginHistoryItem = {
  id: number;
  user: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar' | 'title'> | null;
  timestamp: string;
  browser: string;
  login_type: TUserLoginHistoryType;
  country: string;
  status: TUserLoginHistoryStatus;
  failure_reason: string;
  ip_address: string;
};

export type TAdminUserLoginHistoryResponse = IPaginatedResponse<TUserLoginHistoryItem[]>;

export type TPermissionType = ValueOf<typeof USER_PERMISSION_TYPE>;

export type TUserPermissionItem = {
  id: number;
  name: string;
  codename: TAppPermissions;
  description: string;
  portal: string;
  category: string;
  constructed_from: TPermissionType;
  constructed_name: string | null;
};

export type TUserPermissionRequestParameters = IBasePaginationRequestParameters & {
  constructed_from?: string | null;
  ordering?: string | null;
};

export type TUserPermissionListResponse = IPaginatedResponse<TUserPermissionItem[]>;

export type TUpdateUserDetailsPermissionsPayload = {
  prounitas_permissions: string[];
};

export type TUserDetailsPermissionsCodeNames = {
  permission_codenames: TAppPermissions[];
};

export type TUpdateUserDetailsPermissionsResponse = {
  prounitas_permissions: TUserPermissionItem[];
};

export type TUserBulkInvitePayload = {
  district: string;
  uploaded_file: File;
  role: string;
};

export type TUserBulkFileErrorResponse = {
  row: number;
  full_name: TFileField;
  email: TFileField;
  phone_number: TFileField;
  department: TFileField;
  title: TFileField;
  responsible_for_grades: TFileField;
  school: TFileField;
};

export type TAddInvitePurpleUserPayload = {
  email: string;
  district: string;
  full_name: string;
  phone_number?: string;
  department: string;
  title: string;
  responsible_for_grades: string[];
  schools: string[];
  description?: string;
  is_show_on_scorecard?: boolean;
  prounitas_roles: number[];
  is_district_crisis_representative?: boolean;
  is_school_crisis_representative?: boolean;
};

export type TImpersonateUserPayload = {
  user: string;
  district: number;
};

export type TImpersonateUserResponse = {
  token: string;
  refresh: string;
  subdomain: string;
};

export type TImpersonateUserResource = {
  id: number;
  name: string;
  subdomain: string;
};

export type TImpersonateUserResourcesResponse = {
  districts: TImpersonateUserResource[];
  service_providers: TImpersonateUserResource[];
};

export type TAdminUsersNotLoggedInReportParameters = IBasePaginationRequestParameters & {
  district?: string | null;
  ordering?: string | null;
  is_never: boolean | null;
};

export type TAdminUsersNotLoggedInReport = {
  id: string;
  name: string;
  email: string;
  roles: string[] | null;
  timestamp: string;
  district: string;
};

export type TAdminUsersNotLoggedInReportResponse = IPaginatedResponse<TAdminUsersNotLoggedInReport[]>;

export type TAdminUsersFrequencyDistributionReportParameters = IBasePaginationRequestParameters & {
  district?: string | null;
  ordering?: string | null;
  timestamp__gte?: string | null;
  timestamp__lte?: string | null;
  min_logins?: number | null;
};

export type TAdminUsersFrequencyDistribution = {
  id: string;
  name: string;
  email: string;
  roles: string[] | null;
  login_count: number;
  district: string;
};

export type TAdminUsersFrequencyDistributionReportResponse = IPaginatedResponse<TAdminUsersFrequencyDistribution[]>;

export type TAdminLoggedInByRoleReportParameters = IBasePaginationRequestParameters & {
  district?: string | null;
  ordering?: string | null;
  time_period?: string | null;
};

export type TAdminLoggedInByRoleReport = {
  id: number;
  role: string;
  login_count: number;
  district: string;
};

export type TAdminLoggedInByRoleReportResponse = IPaginatedResponse<TAdminLoggedInByRoleReport[]>;
