import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { updateContactDetails } from './update-contact-details';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TContactDetailItem } from './contacts.types';

export const useUpdateContactDetails = (queryOptions?: TCustomUseMutationOptions<TContactDetailItem, AxiosError>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...queryOptions,
    mutationKey: [CONTACTS_QUERY_KEYS.UPDATE_CONTACT_DETAILS],
    mutationFn: updateContactDetails,
    onError: (error) => {
      if (isClientError(error)) {
        showErrorToast('System message', 'An error occurred while updating the contact details. Please try again later.');
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACT_DETAILS, data.id] });
      showSuccessToast('Success', 'Contact details updated successfully');
    },
  });
};
