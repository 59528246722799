import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSubmitterTypesList } from './get-submitter-types-list';
import { ADMIN_SUBMITTER_TYPES_QUERY_KEYS } from './submitterTypes.const';
import type { AxiosError } from 'axios';
import type { TAdminSubmitterTypeListResponse, TAdminSubmitterTypesListRequestParameters } from './submitterTypes.types';

export const useSubmitterTypesList = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TAdminSubmitterTypesListRequestParameters;
  enabled?: boolean;
}) => {
  const { isError, error, ...rest } = useQuery<TAdminSubmitterTypeListResponse, AxiosError>({
    queryKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.LIST, requestParameters],
    queryFn: () => getSubmitterTypesList(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to fetch types list');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
