import { useCallback, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { AppFilters, Card, CardContent, CardData, CardDataValue, CardTitle, DateRangePicker, Heading, SearchInput } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { AdminRoutes, TableName } from '~/constants';
import { useDataTable } from '~/hooks';
import { useActionsDocumentStats, useActionsList, useActionsListFilterOptions } from '~/services';
import { SchoolTabContainer } from '../../SchoolTabContainer';
import { GeneralActionsSkeleton } from './GeneralActionsSkeleton';
import { useSchoolActionsColumns } from './useSchoolActionsColumns';
import type { DateRange, TFiltersConfig } from '@purple/ui';
import type { TStudentCenteredAction } from '~/services';

export const SchoolActionsTab: React.FC = () => {
  const { id: schoolId } = useParams();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const navigate = useNavigate();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = useActionsList({
    school: schoolId,
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    type: searchParameters.get('type'),
    creator: searchParameters.get('creator'),
    date_and_time__gte: searchParameters.get('date_and_time__gte'),
    date_and_time__lte: searchParameters.get('date_and_time__lte'),
  });
  const { data: options, isFetching: isOptionsLoading } = useActionsListFilterOptions();
  const { data: stats, isFetching: isStatsFetching } = useActionsDocumentStats({
    school: schoolId,
    search: debounceSearch,
    type: searchParameters.get('type'),
    creator: searchParameters.get('creator'),
    date_and_time__gte: searchParameters.get('date_and_time__gte'),
    date_and_time__lte: searchParameters.get('date_and_time__lte'),
  });

  const filterConfig: TFiltersConfig = useMemo(() => ({
    categories: [
      {
        label: 'Type',
        value: 'type',
        filters: options?.type.map((type) => ({
          label: snakeToCamelWithSpaces(type),
          value: type,
        })) || [],
      },
      {
        label: 'Creator',
        value: 'creator',
        filters: options?.creator.map((creator) => ({
          label: creator.full_name || 'Unknown',
          value: creator.id,
        })) || [],
      },
    ],
  }), [options]);

  const actions = useMemo(() => data?.results ?? [], [data?.results]);

  const columns = useSchoolActionsColumns();

  const { table } = useDataTable({
    name: TableName.SCHOOL_ACTIONS,
    columns,
    data: actions,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['title'],
      },
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  const rowClickHandler = useCallback((row: TStudentCenteredAction) => {
    navigate(AdminRoutes.App.Actions.Student.Details.makePath({ dynamicParameters: { actionId: row.id } }));
  }, [navigate]);

  const updateDateRange = (range: DateRange) => {
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.set('date_and_time__gte', format(range.from, 'yyyy-MM-dd'));
      range.to && newSearchParameters.set('date_and_time__lte', format(range.to, 'yyyy-MM-dd'));
      return newSearchParameters;
    });
  };

  const removeDateRange = useCallback(() => {
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.delete('date_and_time__gte');
      newSearchParameters.delete('date_and_time__lte');
      return newSearchParameters;
    });
  }, [setSearchParameters]);

  return (
    <SchoolTabContainer title="Action" className="gap-4 p-0">
      {isStatsFetching
        ? (
            <GeneralActionsSkeleton />
          )
        : (
            <div className="flex w-full flex-col gap-4 px-4 pt-8">
              <Heading tag="h3" type="heading-500" variant="size-18" className="text-grey-title text-base">
                General Actions Data
              </Heading>
              <ul className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
                <Card tag="li">
                  <CardTitle className="text-grey-950 text-sm">Total Actions</CardTitle>
                  <CardContent className="mt-auto">
                    <CardData value={stats?.total ?? 0}>
                      <CardDataValue
                        applyTrendingColor
                        enableAnimation
                        formatterOptions={{ maximumFractionDigits: 0 }}
                        className="text-brand-blue-950"
                      />
                    </CardData>
                  </CardContent>
                </Card>
                <Card tag="li">
                  <CardTitle className="text-grey-950 text-sm">Targeted Actions</CardTitle>
                  <CardContent className="mt-auto">
                    <CardData value={stats?.targeted ?? 0}>
                      <CardDataValue
                        applyTrendingColor
                        enableAnimation
                        formatterOptions={{ maximumFractionDigits: 0 }}
                        className="text-brand-blue-950"
                      />
                    </CardData>
                  </CardContent>
                </Card>
                <Card tag="li">
                  <CardTitle className="text-grey-950 text-sm">General Actions</CardTitle>
                  <CardContent className="mt-auto">
                    <CardData value={stats?.general ?? 0}>
                      <CardDataValue
                        applyTrendingColor
                        enableAnimation
                        formatterOptions={{ maximumFractionDigits: 0 }}
                        className="text-brand-blue-950"
                      />
                    </CardData>
                  </CardContent>
                </Card>
                <Card tag="li">
                  <CardTitle className="text-grey-950 text-sm">Attempted Actions</CardTitle>
                  <CardContent className="mt-auto">
                    <CardData value={stats?.attempted ?? 0}>
                      <CardDataValue
                        applyTrendingColor
                        enableAnimation
                        formatterOptions={{ maximumFractionDigits: 0 }}
                        className="text-brand-blue-950"
                      />
                    </CardData>
                  </CardContent>
                </Card>
                <Card tag="li">
                  <CardTitle className="text-grey-950 text-sm">Service Links</CardTitle>
                  <CardContent className="mt-auto">
                    <CardData value={stats?.service_provider ?? 0}>
                      <CardDataValue
                        applyTrendingColor
                        enableAnimation
                        formatterOptions={{ maximumFractionDigits: 0 }}
                        className="text-brand-blue-950"
                      />
                    </CardData>
                  </CardContent>
                </Card>
              </ul>
            </div>
          )}
      <DataTable table={table} loading={isFetching} onRowClick={rowClickHandler}>
        <div className="flex w-full items-center gap-4 p-4">
          <AppFilters config={filterConfig} loading={isOptionsLoading} />
          <DateRangePicker onUpdate={updateDateRange} align="start" triggerClassNames="h-10 w-64" onClear={removeDateRange} hasClearButton />
          <SearchInput
            value={search}
            placeholder="Search"
            className="max-w-[300px]"
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
          <DataTableViewOptions table={table} />
        </div>
      </DataTable>
    </SchoolTabContainer>
  );
};
