import { useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, cn, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';

type TListItemOptionsProperties = {
  onDeactivate: () => void;
  onActivate: () => void;
  isActive: boolean;
};

const ListItemOptions = ({
  onDeactivate,
  onActivate,
  isActive,
}: TListItemOptionsProperties) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const deactivateClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);

    onDeactivate();
  };

  const activateClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);

    onActivate();
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <Tooltip>
        <TooltipTrigger asChild>
          <DropdownTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="size-8 shrink-0 active:bg-grey-100"
              iconLeft={<PurpleIcon name="dots-vertical" className="shrink-0 text-grey-600" />}
              onFocusCapture={(event) => event.stopPropagation()}
            />
          </DropdownTrigger>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent withArrow={false}>Actions</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <DropdownContent className="max-w-[260px] gap-1" align="end">
        <DropdownItem
          iconName={isActive ? 'X' : 'check'}
          className={cn(
            'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
            {
              'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main': isActive,
            },
          )}
          onSelect={() => isActive ? deactivateClickHandler() : activateClickHandler()}
          onClick={(event) => event.stopPropagation()}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </DropdownItem>
      </DropdownContent>
    </DropdownRoot>
  );
};

export { ListItemOptions };
