export const SAF_LANGUAGES = [
  {
    label: 'English language',
    value: 'en',
  },
  {
    label: 'عربى',
    value: 'ar',
  },
  {
    label: 'Español',
    value: 'es',
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
  },
  {
    label: '中国人',
    value: 'zh',
  },
] as const;
export type TSafLanguages = typeof SAF_LANGUAGES[number]['value'];

export const SAF_FIELD_SETTINGS_HEADING = {
  my_information: 'My Information',
  student_information: 'Student Information',
  needs_information: 'Needs Information',
};
export type TSafFieldSettingsHeading = keyof typeof SAF_FIELD_SETTINGS_HEADING;

export const SAF_FIELD_SETTINGS_MAPPING = {
  [SAF_FIELD_SETTINGS_HEADING.my_information]: ['is_filling_for_myself', 'is_student_filling_form_for_himself', 'is_anonymous', 'submitter__first_name', 'submitter__last_name', 'submitter__email', 'submitter__phone', 'submitter_org_or_campus'],
  [SAF_FIELD_SETTINGS_HEADING.student_information]: ['submitted_student_first_name', 'submitted_student_middle_name', 'submitted_student_last_name', 'submitted_student_id', 'submitted_student_grade', 'submitted_student_parent_phone', 'submitted_school'],
  [SAF_FIELD_SETTINGS_HEADING.needs_information]: ['description', 'select_what_this_saf_is_for'],
};
export type TSafFieldSettingsMapping = typeof SAF_FIELD_SETTINGS_MAPPING;
