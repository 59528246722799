import axiosInstance from '../axios-config';
import { FILE_ENDPOINTS } from '../endpoints';
import type { TFileListQueryParameters, TFileListResponse } from '@purple/shared-types';

export const getFiles = async (parameters?: TFileListQueryParameters) => {
  const response = await axiosInstance.get<TFileListResponse>(FILE_ENDPOINTS.ROOT, {
    params: parameters,
  });

  return response.data;
};
