import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME, TakeActionDocumentType } from '@purple/shared-types';
import { convertToFilterConfig, snakeCaseToCapitalized } from '@purple/shared-utils';
import { AppFilters, AppSelectedFiltersList, Heading, SearchInput, Separator, Text } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions } from '~/components';
import { AdminRoutes, ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { ActionsDetailsQueryValues, ActionsDetailsTabsValues } from '~/pages/Main/Actions/Details/constants';
import { useActionsList, useActionTypesOptions, useDeleteAction } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { useDocumentedActionsColumns } from './documentedActionsColumns';
import type { TTakeActionDocumentType } from '@purple/shared-types';
import type { TStudentCenteredAction } from '~/services';

const DocumentedActionsTab = () => {
  const { safId } = useParams();

  const navigate = useNavigate();

  const [searchParameters] = useSearchParams();

  const { openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal(ModalType.DELETE_ACTION);

  const { debounceSearch, onClearSearch, onSearchChange, search } = useSearch();

  const [selectedAction, setSelectedAction] = useState<TStudentCenteredAction | null>(null);

  const isGroupAction = useMemo(() => {
    const { group_service } = selectedAction || {};
    return group_service !== null && group_service !== undefined && group_service.length > 0;
  }, [selectedAction]);

  const deleteModalText = useMemo(() => isGroupAction ? 'You\'re going to delete the action that belongs to the group. The deletion will not affect other actions in the group. This action can not be undone. Do you want to continue?' : 'Are you sure you want to delete this action? This action can not be undone.', [isGroupAction]);

  const { data: allActionsList, isLoading } = useActionsList({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    search: debounceSearch,
    ordering: searchParameters.get(SORT_QUERY_NAME),
    type: searchParameters.get('type'),
    saf: safId,
    document_as: searchParameters.get('document_as') as TTakeActionDocumentType || '',
  });

  const { mutate: deleteAction, isPending: isDeleting } = useDeleteAction();

  const { data: filtersData, isLoading: isFiltersLoading } = useActionTypesOptions(safId);

  const filterConfig = useMemo(
    () => {
      const documentFilters = {
        document_as: Object.values(TakeActionDocumentType).map((action) => ({
          value: action,
          label: snakeCaseToCapitalized(action),
        })),
      };

      const fetchedFilters = filtersData || {};

      const filters = {
        ...(fetchedFilters || {}),
        ...documentFilters,
      };

      return convertToFilterConfig(filters, {
        snakeCaseValue: false,
      });
    },
    [filtersData],
  );

  const navigateToActionDetails = useCallback(({ id, group_service }: TStudentCenteredAction) => {
    const isGroupAction = group_service !== null && group_service !== undefined && group_service.length > 0;
    const actionType = isGroupAction ? ActionsDetailsQueryValues.groupAction : ActionsDetailsQueryValues.individualAction;

    navigate(AdminRoutes.App.Actions.Student.Details.makePath({ dynamicParameters: { actionId: id }, queries: {
      tab: ActionsDetailsTabsValues.basicDetails,
      actionType,
    } }));
  }, [navigate]);

  const deleteActionHandler = () => {
    const actionId = selectedAction?.id;

    if (!actionId) {
      showErrorToast('System message', `Unable to delete action with provided Id: ${actionId}`);
      return;
    }
    deleteAction(actionId, {
      onSuccess: () => {
        closeDeleteModal();
      },
    });
  };

  const deleteActionClickHandler = (action: TStudentCenteredAction) => {
    setSelectedAction(action);
    openDeleteModal();
  };

  const { table } = useDataTable({
    name: TableName.SAF_DOCUMENTED_ACTIONS,
    columns: useDocumentedActionsColumns({
      onDetails: navigateToActionDetails,
      onDelete: deleteActionClickHandler,
    }),
    data: allActionsList?.results || [],
    rowCount: allActionsList?.count || 0,
    getRowId: (originalRow) => originalRow.id,
  });

  const actionsCount = useMemo(() => allActionsList?.count || 0, [allActionsList]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-1 px-6 py-4">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Documented Actions</Heading>
        {Boolean(actionsCount) && (
          <Text variant="size-12" type="body-600" className="text-grey-600">
            (
            {actionsCount}
            )
          </Text>
        )}
      </div>
      <Separator className="bg-grey-200" />
      <DataTable table={table} loading={isLoading}>
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between gap-2 p-4">
            <div className="h flex items-center gap-4">
              <AppFilters config={filterConfig} loading={isFiltersLoading} />

            </div>
            <div className="flex items-center gap-3">
              <SearchInput
                value={search}
                onChange={onSearchChange}
                onClear={onClearSearch}
                placeholder="Search"
                className="max-w-[300px]"
              />
              <DataTableViewOptions table={table} />
            </div>
          </div>
          <AppSelectedFiltersList config={filterConfig} className="px-4" />
        </div>
      </DataTable>
      <CallToActionModal
        modalName={ModalType.DELETE_ACTION}
        modalTitle="Delete Action"
        modalDescription="Action modal deletion"
        showModalDescription={false}
        modalTextContent={deleteModalText}
        primaryButtonText="Yes, Delete"
        primaryButtonVariant="destructive_primary"
        onPrimaryButtonClick={deleteActionHandler}
        isLoading={isDeleting}
      />
    </div>
  );
};

export { DocumentedActionsTab };
