import { useCallback, useEffect, useMemo, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  SearchInput,
  Separator,
  Switch,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { useTableLocalStorage } from '~/hooks';
import type { RowData, Table, VisibilityState } from '@tanstack/react-table';

type TDataTableViewOptionsProperties<TData> = {
  table: Table<TData>;
};

export const DataTableViewOptions = <TData extends RowData = RowData>({
  table,
}: TDataTableViewOptionsProperties<TData>): JSX.Element => {
  const tableName = useMemo(() =>
    table.options.meta?.tableName ?? 'unknown-table', [table.options.meta?.tableName]);

  const allColumns = useMemo(() =>
    table.getAllColumns().filter(
      (column) => column.accessorFn !== undefined && column.getCanHide(),
    ), [table.getAllColumns()]); // eslint-disable-line react-hooks/exhaustive-deps

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const initialVisibilityState = useMemo(() =>
    allColumns.reduce<VisibilityState>(
      (acc, column) => ({
        ...acc,
        [column.id]: table.initialState.columnVisibility[column.id] ?? true,
      }),
      {},
    ), [allColumns, table.initialState.columnVisibility]);

  const [isSaveState, setIsSaveState] = useTableLocalStorage<string, boolean>(
    `${tableName}-save-state`,
    false,
  );
  const [storedVisibility, setStoredVisibility] = useTableLocalStorage<string, VisibilityState>(
    tableName,
    initialVisibilityState,
  );
  const [visibilityState, setVisibilityState] = useState<VisibilityState>(storedVisibility);

  const isSameAsInitialState = useMemo(() =>
    allColumns.every(
      (column) => visibilityState[column.id] === (table.initialState.columnVisibility[column.id] ?? true),
    ), [allColumns, visibilityState, table.initialState.columnVisibility]);

  const filteredColumns = useMemo(() =>
    allColumns.filter((column) =>
      (column.columnDef.meta?.label ?? column.id)
        .trim()
        .toLowerCase()
        .includes(search.trim().toLowerCase()),
    ), [allColumns, search]);

  const isEmpty = filteredColumns.length === 0;
  const tableColumnVisibility = table.getState().columnVisibility;

  useEffect(() => {
    if (isSaveState) {
      table.setColumnVisibility(visibilityState);
      setStoredVisibility(visibilityState);
    }
  }, [isSaveState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Track updates from table column headers and update the visibility state.
    const isStateUpdated = Object.entries(tableColumnVisibility).some(
      ([id, value]) => visibilityState[id] !== value,
    );
    if (!isOpen && isStateUpdated) {
      const newVisibility = allColumns.reduce<VisibilityState>(
        (acc, column) => ({
          ...acc,
          [column.id]: tableColumnVisibility[column.id] ?? true,
        }),
        {},
      );
      setVisibilityState(newVisibility);
      setStoredVisibility(newVisibility);
      setSearch('');
    }

    return () => {
      if (!isOpen) {
        setSearch('');
      }
    };
  }, [tableColumnVisibility]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetToDefaultClickHandler = useCallback(() => {
    const newColumnVisibility = allColumns.reduce<VisibilityState>(
      (acc, column) => ({
        ...acc,
        [column.id]: table.initialState.columnVisibility[column.id] ?? true,
      }),
      {},
    );
    setVisibilityState(newColumnVisibility);
    setStoredVisibility(newColumnVisibility);
  }, [allColumns, table.initialState.columnVisibility, setStoredVisibility]);

  const applyClickHandler = useCallback(() => {
    table.setColumnVisibility(visibilityState);
    setStoredVisibility(visibilityState);
    setIsOpen(false);
    setSearch('');
  }, [visibilityState, table, setStoredVisibility]);

  const searchChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    [],
  );

  const openChangeHandler = useCallback((open: boolean) => {
    if (!open) {
      setVisibilityState(
        allColumns.reduce<VisibilityState>(
          (acc, column) => ({
            ...acc,
            [column.id]: table.getState().columnVisibility[column.id] ?? true,
          }),
          {},
        ),
      );
      setSearch('');
    }
    setIsOpen(open);
  }, [allColumns, table]);

  const switchChangeHandler = useCallback((columnId: string, value: boolean) => {
    setVisibilityState((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  }, []);

  return (
    <Popover modal open={isOpen} onOpenChange={openChangeHandler}>
      <Tooltip>
        <TooltipTrigger asChild>
          <PopoverTrigger asChild>
            <Button
              aria-label="Toggle columns"
              variant="secondary"
              size="icon_40"
              iconLeft={<PurpleIcon name="adjustments" className="size-5 rotate-90" />}
              className="ml-auto hidden size-10 md:flex"
              onFocusCapture={(event) => event.stopPropagation()}
            />
          </PopoverTrigger>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>Show or hide columns in the table</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <PopoverContent align="end" className="shadow-custom-medium w-[360px] rounded-lg border-0 p-0">
        <div className="flex w-full flex-col gap-3 p-4">
          <Text tag="strong" className="font-primary text-grey-title text-lg font-semibold">
            Configure Columns
          </Text>
          <SearchInput
            name="column-search"
            placeholder="Search column"
            value={search}
            onChange={searchChangeHandler}
            onClear={() => setSearch('')}
          />
        </div>
        <div className="flex w-full flex-col">
          <div className="flex w-full items-center justify-between gap-2 px-4">
            <Label htmlFor={`${table.options.meta?.tableName}-save-switch`} className="font-primary text-grey-700 text-sm font-medium">
              Save Preferences
            </Label>
            <Switch id={`${table.options.meta?.tableName}-save-switch`} name="save-state" className="cursor-pointer" size="small" checked={isSaveState} onCheckedChange={setIsSaveState} />
          </div>
          <div className="mb-4 mt-1.5 px-4">
            <Separator orientation="horizontal" className="bg-grey-200" />
          </div>
          <Text tag="small" className="font-primary text-grey-700 px-4 text-sm font-medium">
            Available Columns
          </Text>
          <div className="mt-1.5 px-4">
            <Separator orientation="horizontal" className="bg-grey-200" />
          </div>
          <ScrollArea type="auto" className="flex max-h-[224px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
            {isEmpty
              ? (
                  <Text className="text-grey-600 px-4 py-5 text-center text-base font-medium">
                    {search ? 'No matching columns found' : 'No columns to display'}
                  </Text>
                )
              : (
                  <ul className="flex w-full flex-col gap-3 px-4 py-3 pr-[26px]">
                    {filteredColumns.map((column) => (
                      <li key={column.id} className="flex w-full items-center justify-between gap-2">
                        <Label htmlFor={column.id} className="text-grey-950 line-clamp-1 break-all text-base font-normal capitalize">
                          {column.columnDef.meta?.label ?? column.id}
                        </Label>
                        <Switch
                          id={column.id}
                          name={column.id}
                          className="cursor-pointer"
                          checked={visibilityState[column.id] ?? true}
                          onCheckedChange={(value) => switchChangeHandler(column.id, value)}
                        />
                      </li>
                    ))}
                  </ul>
                )}
          </ScrollArea>
        </div>
        <div className="flex w-full flex-col gap-4 px-4 pb-4">
          <Separator orientation="horizontal" className="bg-grey-200" />
          <div className="flex w-full items-center justify-between gap-4">
            <Button
              type="button"
              variant="secondary"
              disabled={isSameAsInitialState}
              className="w-full"
              onClick={resetToDefaultClickHandler}
            >
              Reset to Default
            </Button>
            <Button
              type="button"
              variant="primary"
              disabled={Object.values(visibilityState).every((value) => !value)}
              className="w-full"
              onClick={applyClickHandler}
            >
              Apply
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
