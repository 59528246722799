import { ContactType, ReadableContactType, type ValueOf } from '@purple/shared-types';

export const CONTACTS_TABS_VALUES = {
  BASIC_DETAILS: 'basicDetails',
  CONNECTED_STUDENTS: 'connectedStudents',
} as const;

export type TContactsTabsValues = ValueOf<typeof CONTACTS_TABS_VALUES>;

export const EDIT_CONTACTS_TYPE_OPTIONS: Array<{ label: string; value: string }> = [
  { label: ReadableContactType[ContactType.GENERAL], value: ContactType.GENERAL },
  { label: ReadableContactType[ContactType.DISTRICT_STAFF], value: ContactType.DISTRICT_STAFF },
  { label: ReadableContactType[ContactType.SCHOOL_STAFF], value: ContactType.SCHOOL_STAFF },
];

export const EDIT_CONTACTS_TYPE_OPTIONS_FOR_PARENTS: Array<{ label: string; value: string }> = [
  { label: ReadableContactType[ContactType.GENERAL], value: ContactType.GENERAL },
  { label: ReadableContactType[ContactType.DISTRICT_STAFF], value: ContactType.DISTRICT_STAFF },
  { label: ReadableContactType[ContactType.SCHOOL_STAFF], value: ContactType.SCHOOL_STAFF },
  { label: ReadableContactType[ContactType.PARENT_GUARDIAN], value: ContactType.PARENT_GUARDIAN },
];
