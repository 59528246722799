import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { getStudentsList } from './get-students';
import { STUDENTS_QUERY_KEYS } from './students.const';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TStudentsListResponse, TStudentsRequestParameters } from './students.types';

type TUseStudentsProperties = {
  parameters: TStudentsRequestParameters;
  queryOptions?: TCustomUseQueryOptions<TStudentsListResponse, AxiosError>;
};

export const useStudents = ({ parameters, queryOptions }: TUseStudentsProperties) => {
  const { isError, error, ...rest } = useQuery({
    ...queryOptions,
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENTS_LIST, parameters],
    queryFn: () => getStudentsList(parameters),
  });

  useEffect(() => {
    if (isError && isClientError(error)) {
      showErrorToast('System error', 'Unable to retrieve students list');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
