import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Button, DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import type { TActivityTypeItem } from '~/services';

type TBasicDetailsInfoProperties = {
  data: TActivityTypeItem;
};

export const BasicDetailsInfo: React.FC<TBasicDetailsInfoProperties> = (props) => {
  const { data } = props;

  return (
    <DescriptionList className="gap-2">
      <DescriptionItem className="justify-start">
        <DescriptionTerm className="max-w-64 flex-1">Name</DescriptionTerm>
        <DescriptionDetails className="m-0 flex-1">
          {snakeToCamelWithSpaces(data.name)}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="justify-start">
        <DescriptionTerm className="max-w-64 flex-1">Status</DescriptionTerm>
        <DescriptionDetails className="m-0 flex-1">
          {data.is_active ? 'Active' : 'Inactive'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="justify-start">
        <DescriptionTerm className="max-w-64 flex-1">Usage</DescriptionTerm>
        <DescriptionDetails className="m-0 line-clamp-none flex-1">
          {data.usage || '-'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="justify-start">
        <DescriptionTerm className="max-w-64 flex-1">Last Modified By</DescriptionTerm>
        <DescriptionDetails className="m-0 flex-1">
          {data.last_modified_by
            ? (
                <Button asChild variant="link" size="link" className="text-base">
                  <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: data.last_modified_by.id } })}>
                    {data.last_modified_by?.full_name}
                  </Link>
                </Button>
              )
            : (
                'System'
              )}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="justify-start">
        <DescriptionTerm className="max-w-64 flex-1">Last Updated Date & Time</DescriptionTerm>
        <DescriptionDetails className="m-0 flex-1">
          {format(data.updated_at, 'MMMM dd, yyyy h:mm a')}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="justify-start">
        <DescriptionTerm className="max-w-64 flex-1">Recurrence</DescriptionTerm>
        <DescriptionDetails className="m-0 flex-1">
          {data.is_recurrence ? 'Yes' : 'No'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="justify-start">
        <DescriptionTerm className="max-w-64 flex-1">Shared With</DescriptionTerm>
        <DescriptionDetails className="m-0 flex-1">
          All Districts
        </DescriptionDetails>
      </DescriptionItem>
    </DescriptionList>
  );
};
