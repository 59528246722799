import { formatDateShortMonthWithTime } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { ReadableUserLoginHistoryType, type TUserLoginHistoryItem } from '~/services';
import { StatusColumn } from './StatusColumn';
import type { ColumnDef } from '@tanstack/react-table';

type TLoginHistoryColumns = ColumnDef<TUserLoginHistoryItem>[];

export const loginHistoryColumns: TLoginHistoryColumns = [
  {
    accessorKey: 'timestamp',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date & Time" />,
    cell: ({ row }) => {
      const dateTime = formatDateShortMonthWithTime(row.getValue('timestamp'));
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{dateTime}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {dateTime}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Date & Time' },
  },
  {
    accessorKey: 'browser',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Browser" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="line-clamp-1 break-all text-grey-950">{row.getValue('browser')}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {row.getValue('browser')}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 120,
    meta: { label: 'Browser' },
  },
  {
    accessorKey: 'login_type',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Login Type" />,
    cell: ({ row }) => {
      const typeLabel = ReadableUserLoginHistoryType[row.original.login_type];
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{typeLabel}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {typeLabel}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Login Type' },
    enableSorting: false,
  },
  {
    accessorKey: 'country',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Country" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="line-clamp-1 break-all text-grey-950">{row.getValue('country')}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {row.getValue('country')}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 120,
    meta: { label: 'Country' },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => (
      row.original.status ? (<StatusColumn status={row.original.status} failureReason={row.original.failure_reason} />) : <span className="line-clamp-1 break-all text-grey-950">-</span>
    ),
    size: 120,
    meta: { label: 'Status' },
    enableSorting: false,
  },
  {
    accessorKey: 'ip_address',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Ip Address" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="line-clamp-1 break-all text-grey-950">{row.getValue('ip_address')}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {row.getValue('ip_address')}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 120,
    meta: { label: 'Ip Address' },
    enableSorting: false,
  },
];
