import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useCurrentUser, useModal } from '~/hooks';
import { useContactsPriorityListById, useDeleteContactsPriorityList } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { PriorityListQueryName, PriorityListViewType } from './constants';
import { DetailsForm } from './DetailsForm';
import { SharePriorityListDialog } from './SharePriorityListDialog';
import type { TPriorityListViewType } from './constants';

type TDetailsHeaderProps = {
  isSendEmailAvailable?: boolean;
  onSendEmail?: () => void;
};

export const DetailsHeader: React.FC<TDetailsHeaderProps> = (props) => {
  const { isSendEmailAvailable = false, onSendEmail } = props;

  const { toggleModal: toggleDeleteModal } = useModal(ModalType.DELETE_CONTACTS_PRIORITY_LIST);
  const { toggleModal: toggleShareModal } = useModal(ModalType.SHARE_CONTACTS_PRIORITY_LIST);

  const { user } = useCurrentUser();

  const { onQueryChange } = useQueryParameter<TPriorityListViewType>({
    queryName: PriorityListQueryName.VIEW,
  });
  const { query: listId } = useQueryParameter({
    queryName: PriorityListQueryName.ID,
  });

  const { data: priorityList = null } = useContactsPriorityListById(
    { id: listId as string },
    { enabled: !!listId },
  );
  const { mutate: deletePriorityList, isPending: isDeletePending } = useDeleteContactsPriorityList();

  const deleteSubmitHandler = () => {
    if (!priorityList) {
      return showErrorToast('System Error', 'Selected priority list is not found.');
    }

    if (priorityList.created_by.id !== user.id) {
      toggleDeleteModal(false);
      return showErrorToast('Access Denied', `This is a shared priority list. If you need to delete this priority list, please reach out to the owner [${priorityList.created_by.full_name}].`);
    }

    deletePriorityList(priorityList.id, {
      onSuccess: () => {
        toggleDeleteModal(false);
        onQueryChange(PriorityListViewType.LIST, {
          onSuccess: (urlParameters) => {
            urlParameters.delete(PriorityListQueryName.ID);
          },
        });
      },
    });
  };

  return (
    <div className="flex w-full items-start justify-between gap-4">
      <DetailsForm />
      <div className="flex items-center gap-4">
        <Button type="button" disabled={!isSendEmailAvailable} iconLeft={<PurpleIcon name="mail" />} onClick={onSendEmail}>
          Send Email to All
        </Button>
        <DropdownRoot>
          <Tooltip>
            <DropdownTrigger asChild>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="secondary"
                  size="icon_40"
                  className="size-10 shrink-0"
                  iconRight={<PurpleIcon name="dots-vertical" />}
                  onFocusCapture={(evt) => evt.stopPropagation()}
                />
              </TooltipTrigger>
            </DropdownTrigger>
            <TooltipPortal>
              <TooltipContent>Actions</TooltipContent>
            </TooltipPortal>
          </Tooltip>
          <DropdownContent align="end" className="w-48">
            <DropdownItem
              iconName="share"
              onClick={() => toggleShareModal(true)}
            >
              Share Priority List
            </DropdownItem>
            <DropdownItem
              iconName="trash"
              className="text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main cursor-pointer transition-colors duration-200"
              onClick={() => toggleDeleteModal(true)}
            >
              Delete List
            </DropdownItem>
          </DropdownContent>
        </DropdownRoot>
      </div>
      <SharePriorityListDialog priorityList={priorityList} />
      <CallToActionModal
        modalName={ModalType.DELETE_CONTACTS_PRIORITY_LIST}
        modalTitle="Delete Priority List"
        modalDescription="By deleting this priority list, it will be removed from the system and can't be recovered."
        modalTextContent="Are you sure you want to delete this priority list?"
        primaryButtonText={isDeletePending ? 'Deleting...' : 'Delete'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteSubmitHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
      />
    </div>
  );
};
