import { memo, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { useWavyCursor } from '@purple/hooks';
import { Button, cn, Heading, Text } from '@purple/ui';
import notFoundSvg from '~/assets/images/404/404.svg';
import bgSvg from '~/assets/images/404/bg.svg';
import circle1Svg from '~/assets/images/404/circle-1.svg';
import circle2Svg from '~/assets/images/404/circle-2.svg';
import circle3Svg from '~/assets/images/404/circle-3.svg';
import circle4Svg from '~/assets/images/404/circle-4.svg';
import cloud1Svg from '~/assets/images/404/cloud-1.svg';
import cloud2Svg from '~/assets/images/404/cloud-2.svg';
import cloud3Svg from '~/assets/images/404/cloud-3.svg';
import cloud4Svg from '~/assets/images/404/cloud-4.svg';
import oopsSvg from '~/assets/images/404/oops.svg';
import textSvg from '~/assets/images/404/text.svg';
import { AdminRoutes } from '~/constants';
import { useGoBack } from '~/hooks';
import { useInteractive3DRotation } from './useInteractive3DRotation';

const clouds = [
  cloud1Svg,
  cloud2Svg,
  cloud3Svg,
  cloud4Svg,
] as const;

const circles = [
  circle1Svg,
  circle2Svg,
  circle3Svg,
  circle4Svg,
] as const;

// Extracted animation config
const ANIMATIONS = {
  parallax: { scale: 1 },
  bg: { opacity: 1, duration: 0.25, delay: 0 },
  oops: { top: 54, left: 175, opacity: 1, duration: 0.35, delay: 0 },
  notFound: { top: 95, left: 100, opacity: 1, duration: 0.4, delay: 0 },
  text: { top: 200, left: 138, opacity: 1, duration: 0.35, delay: 0 },
  circles: [
    { id: 'circle-1', top: 54, left: 38, duration: 0.3, delay: 0, width: 28, height: 18 },
    { id: 'circle-2', top: 51, left: 350, duration: 0.35, delay: 0, width: 41, height: 25 },
    { id: 'circle-3', top: 250, left: 42, duration: 0.31, delay: 0, width: 50, height: 28 },
    { id: 'circle-4', top: 282, left: 338, duration: 0.4, delay: 0, width: 59, height: 32 },
  ],
  clouds: [
    { id: 'cloud-1', top: 27, left: 204, duration: 0.4, delay: 0, width: 47, height: 21 },
    { id: 'cloud-2', top: 113, left: 326, duration: 0.35, delay: 0, width: 84, height: 31 },
    { id: 'cloud-3', top: 183, left: 41, duration: 0.4, delay: 0, width: 65, height: 26 },
    { id: 'cloud-4', top: 267, left: 150, duration: 0.5, delay: 0, width: 50, height: 23 },
  ],
};

// Extracted image component
const AnimatedImage = memo(({ id, src, style, width, height, alt, className }: {
  id: string;
  src: string;
  style: React.CSSProperties;
  width: string;
  height: string;
  alt: string;
  className?: string;
}) => (
  <img
    src={src}
    id={id}
    style={style}
    className={cn('absolute opacity-0 transition-all', className)}
    width={width}
    height={height}
    alt={alt}
    loading="eager"
  />
));

export const NotFoundPage: React.FC = memo(() => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<gsap.core.Timeline>();

  useInteractive3DRotation(containerRef);
  useWavyCursor({ canvasRef });

  const { onGoBack } = useGoBack({
    fallbackPath: AdminRoutes.App.Home.Root.path,
  });

  useEffect(() => {
    const timeline = gsap.timeline();
    timelineRef.current = timeline;

    // Main animations
    timeline
      .to('#parallax', ANIMATIONS.parallax)
      .to('#bg', ANIMATIONS.bg, '>-.1')
      .to('#oops', ANIMATIONS.oops, '>-.2')
      .to('#not-found', ANIMATIONS.notFound, '>-.2')
      .to('#text', ANIMATIONS.text, '>-.2');

    // Circles animations
    ANIMATIONS.circles.forEach(({ id, ...props }) => {
      timeline.to(`#${id}`, { ...props, opacity: 1, ease: 'power1.inOut' }, '>-.2');
    });

    // Clouds animations
    ANIMATIONS.clouds.forEach(({ id, ...props }) => {
      timeline.to(`#${id}`, { ...props, opacity: 1, ease: 'power1.inOut' }, '>-.2');
    });

    return () => {
      timeline.kill();
    };
  }, []);

  return (
    <div
      className="relative z-10 flex min-h-screen w-full flex-col items-center justify-center bg-white"
      role="main"
      aria-labelledby="error-title"
    >
      <div className="relative mx-auto flex flex-col items-center justify-center gap-5 py-5">
        <div className="min-h-[314px] w-full max-w-[406px]" />
        <div
          ref={containerRef}
          id="parallax"
          className="absolute left-1/2 top-0 ml-[-203px] flex min-h-[314px] w-full max-w-[406px] select-none flex-col items-center justify-start"
          style={{
            transformStyle: 'preserve-3d',
            transform: 'translateZ(0px) scale(1.2)',
            willChange: 'transform',
          }}
          aria-hidden="true"
        >
          {/* Background */}
          <AnimatedImage
            id="bg"
            src={bgSvg}
            style={{ transform: 'translateZ(0px)', top: 0, left: 0 }}
            width="406"
            height="306"
            alt="Background abstraction"
          />

          {/* Circles */}
          {ANIMATIONS.circles.map((circle, index) => (
            <AnimatedImage
              key={circle.id}
              id={circle.id}
              src={circles[index]!}
              style={{
                transform: `translateZ(${`${index % 2 === 0 ? '-' : ''}`}${(index + 1) * 10}px)`,
                top: circle.top,
                left: circle.left,
              }}
              width={circle.width.toString()}
              height={circle.height.toString()}
              alt={`Circle abstraction ${index + 1}`}
            />
          ))}

          {/* Main elements */}
          <AnimatedImage
            id="oops"
            src={oopsSvg}
            style={{ transform: 'translateZ(40px)', top: 40, left: 170, zIndex: 1 }}
            width="66"
            height="23"
            alt="Oops text"
          />
          <AnimatedImage
            id="not-found"
            src={notFoundSvg}
            style={{ transform: 'translateZ(80px)', top: 70, left: 100, zIndex: 1 }}
            width="223"
            height="88"
            alt="404 text"
          />
          <AnimatedImage
            id="text"
            src={textSvg}
            style={{ transform: 'translateZ(50px)', top: 210, left: 138, zIndex: 10 }}
            width="141"
            height="13"
            alt="Page not found text"
          />

          {/* Clouds */}
          {ANIMATIONS.clouds.map((cloud, index) => (
            <AnimatedImage
              key={cloud.id}
              id={cloud.id}
              src={clouds[index]!}
              style={{
                transform: `translateZ(${(index + 1) * 15}px)`,
                top: cloud.top,
                left: cloud.left - (index % 2 === 0 ? -15 : 20),
              }}
              width={cloud.width.toString()}
              height={cloud.height.toString()}
              alt={`Cloud abstraction ${index + 1}`}
            />
          ))}
        </div>

        <div className="flex w-full max-w-[620px] flex-col items-center justify-center gap-1">
          <Heading
            tag="h1"
            type="heading-500"
            variant="size-18"
            className="text-grey-700"
            id="error-title"
          >
            The page you tried to visit doesn't exist.
          </Heading>
          <Text
            type="body-400"
            variant="size-14"
            className="text-center text-grey-700"
          >
            The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
          </Text>
        </div>

        <div className="flex gap-4">
          <Button
            variant="secondary"
            onClick={onGoBack}
            aria-label="Go back to previous page"
          >
            Go Back
          </Button>
          <Button
            asChild
            aria-label="Go to home page"
          >
            <Link to={AdminRoutes.App.Home.Root.path} replace>Home</Link>
          </Button>
        </div>
      </div>

      <canvas
        ref={canvasRef}
        className="pointer-events-none fixed inset-0 z-0"
        aria-hidden="true"
      />
    </div>
  );
});
