import { format, parseISO } from 'date-fns';
import { cutGradesNames, ReadableDistrictUserStatus } from '@purple/shared-utils';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TUserManagement } from '~/services';

export const userPrintColumns: ColumnDef<TUserManagement>[] = [
  {
    accessorKey: 'full_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
    cell: ({ row: { original } }) => (original.full_name && original.full_name.length > 0 ? <span>{original.full_name}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 200,
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row: { original } }) => (original.status ? <span>{ReadableDistrictUserStatus[original.status]}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 100,
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
    cell: ({ row: { original } }) => (<span>{original.email}</span>),
    enableSorting: false,
    enableHiding: false,
    size: 280,
  },
  {
    accessorKey: 'district',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row: { original } }) => (original.district && original.district.length > 0 ? <span>{original.district.join(', ')}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 300,
  },
  {
    accessorKey: 'schools',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Schools" />,
    cell: ({ row: { original } }) => (original.schools && original.schools.length > 0 ? <span>{original.schools.map((school) => school.name).join(', ')}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'prounitas_roles',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Roles" />,
    cell: ({ row: { original } }) => (original.prounitas_roles && original.prounitas_roles.length > 0 ? <span>{original.prounitas_roles.join(', ')}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 300,
  },
  {
    accessorKey: 'grades',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Grades" />,
    cell: ({ row: { original } }) => (original.grades && original.grades.length > 0 ? <span>{cutGradesNames(original.grades).join(', ')}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 100,
  },
  {
    accessorKey: 'date_joined',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
    cell: ({ row: { original } }) => (<span>{format(parseISO(original.date_joined), 'MMMM dd, yyyy')}</span>),
    enableSorting: false,
    enableHiding: false,
    size: 150,
  },
];
