export const DISTRICTS_QUERY_KEYS = {
  DETAILS: 'district-details',
  PUBLISHED_DISTRICTS: 'published-districts',
  DRAFTED_DISTRICTS: 'drafted-districts',
  FILTER_OPTIONS: 'districts-filter-options',
  USERS_FILTER_OPTIONS: 'district-users-filter-options',
  GET_USERS: 'get-district-users-list',
  GET_USERS_MUTATION: 'get-district-users-list-mutation',
  BULK_DEACTIVATE_USERS: 'bulk-deactivate-district-users',
  UPDATE_BASIC_DETAILS: 'update-district-basic-details',
  UPDATE_STATUS: 'update-district-status',
  UPDATE_BRANDING: 'update-district-branding',
  UPDATE_SAF_PROCESS: 'update-district-saf-process',
  GET_DISTRICT_CUSTOM_PAGES: 'get-district-custom-pages',
  CREATE_DISTRICT_CUSTOM_PAGE: 'create-district-custom-page',
  UPDATE_DISTRICT_CUSTOM_PAGE: 'update-district-custom-page',
  UPDATE_DISTRICT_CUSTOM_PAGE_ORDER: 'update-district-custom-page-order',
  DELETE_DISTRICT_CUSTOM_PAGE: 'delete-district-custom-page',
  CREATE_DRAFT_DISTRICT: 'create-draft-district',
  UPDATE_DRAFT_DISTRICT_BASIC: 'create-draft-district-basic',
  GET_DISTRICT_SIS_MAPPING_LIST: 'get-district-sis-mapping-list',
  GET_DISTRICT_SIS_MAPPING_DETAILS: 'get-district-sis-mapping-details',
  GET_DISTRICT_SIS_MAPPING_EVENTS: 'get-district-sis-mapping-events',
  RESYNC_DISTRICT_MAPPING_EVENTS: 'resync-district-mapping-events',
  CREATE_DISTRICT_CUSTOM_MAPPING: 'create-district-custom-mapping',
  UPLOAD_MAPPING_FILE: 'upload-mapping-file',
  UPDATE_MAPPING_COLUMNS: 'update-mapping-columns',
  DELETE_MAPPING_FILE: 'delete-mapping-file',
  UPDATE_DRAFT_DISTRICT_BRANDING: 'create-draft-district-branding',
  UPDATE_DRAFT_DISTRICT_LEADERS_USERS: 'create-draft-district-leaders-users',
  DRAFTED_DISTRICT_DETAIL: 'drafted-district-detail',
  UPDATE_RESPONSIBLE_FOR_SETUP: 'update-responsible-for-setup',
  DISTRICTS_LIST_BASIC_INFO: 'districts-list-basic-info',
  CREATE_DISTRICT_SSO_SETTING: 'create-district-sso-setting',
  GET_DISTRICT_SSO_SETTINGS: 'get-district-sso-settings',
  UPDATE_DISTRICT_SSO_SETTING: 'update-district-sso-setting',
  DELETE_DISTRICT_SSO_SETTING: 'delete-district-sso-setting',
  PUBLISH_DISTRICT: 'publish-district',
  GET_SAF_PROCESS_OWNER: 'get-saf-process-owner',
} as const;

export const DISTRICT_STATUSES = {
  PUBLISHED: 'published',
  DEACTIVATED: 'deactivated',
  DRAFTED: 'drafted',
} as const;

export const SIS_MAPPING_EVENTS_STATUSES = {
  SUCCESS: 'success',
  PENDING: 'pending',
  FAILED: 'failed',
} as const;

export const SIS_MAPPING_EVENTS_TYPES = {
  SYNC: 'sync',
  UPDATE: 'update',
} as const;

export const READABLE_SIS_MAPPING_EVENTS_TYPES = {
  [SIS_MAPPING_EVENTS_TYPES.SYNC]: 'Synchronize Data',
  [SIS_MAPPING_EVENTS_TYPES.UPDATE]: 'Update Mapping',
} as const;

export const SSO_PROVIDERS = {
  GOOGLE: 'google',
  MICROSOFT: 'microsoft',
  CLASS_LINK: 'classlink',
} as const;

export const SSO_PROVIDERS_SELECT_OPTIONS = [
  { value: SSO_PROVIDERS.GOOGLE, label: 'Google' },
  { value: SSO_PROVIDERS.MICROSOFT, label: 'Microsoft' },
  { value: SSO_PROVIDERS.CLASS_LINK, label: 'Class Link' },
] as const;

export const SSO_PROVIDERS_LABELS = {
  [SSO_PROVIDERS.GOOGLE]: 'Single Sign-On Google',
  [SSO_PROVIDERS.MICROSOFT]: 'Single Sign-On Microsoft',
  [SSO_PROVIDERS.CLASS_LINK]: 'Single Sign-On Class Link',
} as const;

export const DEFAULT_TRANSLATIONS_VALUES = {
  ar: '',
  en: '',
  es: '',
  vi: '',
  zh: '',
} as const;
