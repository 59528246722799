import * as z from 'zod';

export const updateSafDetailsSchema = z.object({
  current_owner: z.string().trim().min(1, { message: 'This field is required' }),
  previous_owner: z.string().trim(),
  priority_assigned_at: z.date({
    message: 'Date is invalid.',
    required_error: 'Date is required.',
  }).optional(),
  status: z.string().trim().min(1, { message: 'This field is required' }),
  priority: z.string().optional(),
  needs: z.string().trim().min(1, { message: 'This field is required' }),
});
