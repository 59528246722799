import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { createContact } from './create-contact';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TContactItem } from './contacts.types';

export const useCreateContact = (queryOptions?: TCustomUseMutationOptions<TContactItem, AxiosError<{ email: string[] }>>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...queryOptions,
    mutationKey: [CONTACTS_QUERY_KEYS.CREATE_CONTACT],
    mutationFn: createContact,
    onSuccess: (_, { first_name, last_name }) => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_LIST] });
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_STATISTICS] });
      showSuccessToast('System message', `Contact ${first_name} ${last_name} has been created successfully`);
    },
    onError: (error) => {
      if (isClientError(error)) {
        if (error.response?.data.email) {
          showErrorToast('System message', `Email: ${error.response.data.email.join(', ')}`);
          return;
        }
        showErrorToast('System message', 'An error occurred while creating the contact');
      }
    },
  });
};
