import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PERMISSIONS_QUERY_KEYS } from '@purple/shared-utils';
import { useAppSelector } from '~/hooks';
import { userSelector } from '~/store/features/user';
import { isClientError } from '~/utils/api-requests';
import { showSuccessToast } from '~/utils/toasts';
import { removeUserDetailsPermission } from './remove-user-details-permission';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useRemoveUserDetailsPermission = (queryOptions?: TCustomUseMutationOptions<AxiosResponse, AxiosError>) => {
  const queryClient = useQueryClient();
  const { id } = useAppSelector(userSelector);

  return useMutation({
    ...queryOptions,
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.REMOVE_USER_DETAILS_PERMISSION],
    mutationFn: removeUserDetailsPermission,
    onSuccess: (_, { userId }) => {
      if (userId === id) {
        queryClient.invalidateQueries({ queryKey: [PERMISSIONS_QUERY_KEYS.GET_USER_PERMISSIONS] });
      }
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_USER_DETAILS_PERMISSIONS] });
      showSuccessToast('System message', 'User permission removed successfully');
    },
    onError: (error) => {
      if (isClientError(error)) {
        showSuccessToast('System message', 'Unable to remove user permission');
      }
    },
  });
};
