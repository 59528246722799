import { PurpleIcon } from '@purple/icons';
import { Button, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { FC } from 'react';

type TPrintDropDownProps = {
  disabled?: boolean;
  addToExistingClick?: () => void;
  createNewClick?: () => void;
};

export const PriorityListDropDown: FC<TPrintDropDownProps> = ({ addToExistingClick, createNewClick, disabled }) => {
  return (
    <DropdownRoot>
      <Tooltip>
        <TooltipTrigger asChild>
          <span>
            <DropdownTrigger asChild className="[&>svg]:data-[state=open]:rotate-180" disabled={disabled}>
              <Button type="button" variant="secondary" iconRight={<PurpleIcon name="chevron-down" className="transition-transform" />}>Priority List</Button>
            </DropdownTrigger>
          </span>
        </TooltipTrigger>
        {disabled && (
          <TooltipPortal>
            <TooltipContent>
              Select contacts from the same district to add them to a priority list.
            </TooltipContent>
          </TooltipPortal>
        )}
      </Tooltip>
      <DropdownContent align="end">
        <DropdownItem iconName="plus" onClick={createNewClick}>
          Create New Priority List
        </DropdownItem>
        <DropdownItem iconName="clipboard-list" onClick={addToExistingClick}>
          Add to Existing Priority List
        </DropdownItem>
      </DropdownContent>
    </DropdownRoot>
  );
};
