import { type FC, memo } from 'react';
import { AppTipBox, cn } from '@purple/ui';
import type { TFileField } from '@purple/shared-types';

type TBulkFileErrorTableCellProps = {
  className?: string;
  tipContentClassName?: string;
  rowData: TFileField;
};

export const BulkFileErrorTableCell: FC<TBulkFileErrorTableCellProps> = memo(({ className, tipContentClassName, rowData }) => {
  return (
    <div className={cn('flex items-center gap-2 justify-between px-2 py-3  rounded-md', { 'bg-error-bg': rowData.errors.length > 0 }, className)}>
      <span className="line-clamp-1 w-full break-all">{rowData.value ?? '—'}</span>
      {rowData.errors.length > 0 && <AppTipBox text={rowData.errors.join('\n')} tooltipClassName={cn('whitespace-pre-wrap', tipContentClassName)} className="text-error-main" />}
    </div>
  );
});
