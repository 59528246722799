import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { SEARCH_QUERY_NAME } from '@purple/shared-types';
import { AppTabs, Button } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { ActivityDetailsView } from '../helpers';
import { ActivityInfo } from './components';
import { ActivityFilesTab, ActivityHistoryTab, BasicDetailsTab } from './tabs';
import type { TAppTab } from '@purple/ui';
import type { TActivityDetailsView } from '../helpers';

export const ActivityDetailsPage: React.FC = () => {
  const activityDetailsTabs: TAppTab<TActivityDetailsView>[] = useMemo(
    () => [
      {
        label: 'Basic Details',
        value: ActivityDetailsView.DETAILS,
        className: 'shadow-none',
        content: <BasicDetailsTab />,
        permissions: [],
      },
      {
        label: 'Files',
        value: ActivityDetailsView.FILES,
        className: 'shadow-none',
        content: <ActivityFilesTab />,
        permissions: [],
      },
      {
        label: 'History / Timeline',
        value: ActivityDetailsView.HISTORY,
        className: 'shadow-none',
        content: <ActivityHistoryTab />,
        permissions: [],
      },
    ],
    [],
  );

  const { isReady, activeTab, tabChangeHandler } = useTabs<TActivityDetailsView>({
    tabs: activityDetailsTabs,
    defaultTab: ActivityDetailsView.DETAILS,
  });

  const onTabChange = (value: TActivityDetailsView) => {
    tabChangeHandler(value, {
      onSuccess: (params) => {
        params.delete(SEARCH_QUERY_NAME);
      },
    });
  };

  return (
    <div className="flex w-full flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700 size-4 shrink-0" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Activities.Root.path}>
          Back to Community Activities
        </Link>
      </Button>
      <ActivityInfo />
      <AppTabs
        tabs={activityDetailsTabs}
        isReady={isReady}
        listClassName="pl-2"
        value={activeTab}
        onValueChange={onTabChange}
      />
    </div>
  );
};
