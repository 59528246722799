import { useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { getContactsPriorityLists } from './get-priority-lists';
import type { InfiniteData } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { TCustomUseInfiniteQueryOptions } from '~/types/query';
import type { TContactsPriorityListsRequestParameters, TContactsPriorityListsResponse } from './contacts.types';

export const useContactsPriorityLists = (
  parameters?: TContactsPriorityListsRequestParameters,
  queryOptions?: TCustomUseInfiniteQueryOptions<TContactsPriorityListsResponse, AxiosError, InfiniteData<TContactsPriorityListsResponse>, unknown[], number | null>,
) => {
  const { limit = 6, search = '', list_type } = parameters ?? {};
  const { isError, error, ...queryData } = useInfiniteQuery({
    queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LISTS, limit, search, list_type],
    queryFn: ({ pageParam = 0 }) => getContactsPriorityLists({ offset: pageParam, limit, search, list_type }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      const nextOffset = lastPage.next ? new URL(lastPage.next).searchParams.get('offset') : null;
      return nextOffset ? Number.parseInt(nextOffset, 10) : null;
    },
    ...queryOptions,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to retrieve priority lists');
    }
  }, [isError, error]);

  return { ...queryData, isError };
};
