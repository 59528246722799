import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMask } from '@react-input/mask';
import { useQueryClient } from '@tanstack/react-query';
import { useDebouncedCallback } from '@purple/hooks';
import { ContactType } from '@purple/shared-types';
import { CONTAIN_DIGIT_REGEX, mergeReferences } from '@purple/shared-utils';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage, Input, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue } from '@purple/ui';
import { PHONE_MASK } from '~/constants';
import { CONTACTS_QUERY_KEYS, useDistrictsListBasicInfo } from '~/services';
import { EDIT_CONTACTS_TYPE_OPTIONS, EDIT_CONTACTS_TYPE_OPTIONS_FOR_PARENTS } from '../../constants';
import type { FC } from 'react';
import type { TContactDetailItem } from '~/services';
import type { TContactDetailsFormSchema } from './DetailsSection';

const DEFAULT_SEARCH_DELAY = 400;

export const DetailsSectionFormContent: FC = () => {
  const { contactId } = useParams();
  const queryClient = useQueryClient();

  const { control } = useFormContext<TContactDetailsFormSchema>();

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string | null>(null);

  const contactDetails = queryClient.getQueryData<TContactDetailItem>([CONTACTS_QUERY_KEYS.GET_CONTACT_DETAILS, contactId]);

  const inputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  const { data: activeDistricts, isFetching } = useDistrictsListBasicInfo({
    search: debouncedSearchValue,
    limit: 50,
    status: 'published',
  });

  const contactTypeSelectOptions = useMemo(() => {
    return contactDetails && contactDetails.contact_type === ContactType.PARENT_GUARDIAN
      ? EDIT_CONTACTS_TYPE_OPTIONS_FOR_PARENTS
      : EDIT_CONTACTS_TYPE_OPTIONS;
  }, [contactDetails]);

  const districtsSelectOptions = useMemo(() => {
    return (
      activeDistricts?.results.map((district) => ({
        ...district,
        label: district.name,
        value: district.id,
      })) ?? []
    );
  }, [activeDistricts]);

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  return (
    <>
      <FormField
        control={control}
        name="first_name"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">First Name</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <FormControl>
                <Input {...field} placeholder="Enter first name" isError={!!fieldState.error} className="h-9" />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="last_name"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">Last Name</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <FormControl>
                <Input {...field} placeholder="Enter last name" isError={!!fieldState.error} className="h-9" />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="contact_type"
        render={({ field }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">Type</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <RadixSelect
                value={field.value}
                onValueChange={field.onChange}
              >
                <FormControl>
                  <RadixSelectTrigger className="h-9">
                    <RadixSelectValue {...field} placeholder="Select type" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {/* Replace the array with values for parent guardian if current contact has this role EDIT_CONTACTS_TYPE_OPTIONS_FOR_PARENTS */}
                  {contactTypeSelectOptions.map((type) => (
                    <RadixSelectItem key={type.value} value={type.value}>
                      {type.label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="district"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">District</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select district"
                    className="h-9"
                    selectedLabel={
                      districtsSelectOptions.find((option) => option.value.toString() === field.value.toString())?.label
                    }
                  />
                </FormControl>
                <ComboBoxContent
                  loading={isFetching}
                  shouldFilter={false}
                  searchPlaceholder="Search district..."
                  emptyContent="District not found."
                  onSearchChange={debouncedSearch}
                >
                  {activeDistricts?.results?.map(({ id, name }) => (
                    <ComboBoxItem
                      key={id}
                      value={id.toString()}
                      selected={field.value.toString() === id.toString()}
                      onSelect={field.onChange}
                    >
                      {name}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="email"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">Email</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <FormControl>
                <Input {...field} placeholder="Enter email" isError={!!fieldState.error} className="h-9" />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="phone"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">Phone Number</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <FormControl>
                <Input
                  {...field}
                  ref={mergeReferences([field.ref, inputReference])}
                  isError={!!fieldState.error}
                  placeholder={PHONE_MASK}
                  type="tel"
                  className="h-9"
                />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
    </>
  );
};
