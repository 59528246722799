import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  formatDateShortMonth,
  formatDateShortMonthWithTime,
  snakeToCamelWithSpaces,
} from '@purple/shared-utils';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentCenteredAction } from '~/services';

type TUseSchoolActionsColumns = () => ColumnDef<TStudentCenteredAction>[];

export const useSchoolActionsColumns: TUseSchoolActionsColumns = () => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'title',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{original.title}</span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {original.title}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 200,
          meta: { label: 'Name' },
        },
        {
          accessorKey: 'record_action_type',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {snakeToCamelWithSpaces(original.record_action_type)}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {snakeToCamelWithSpaces(original.record_action_type)}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Type' },
        },
        {
          accessorKey: 'student__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Student" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {original.student?.full_name || '—'}
                </span>
              </TooltipTrigger>
              {original.student && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.student.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 150,
          meta: { label: 'Student' },
        },
        {
          accessorKey: 'action_group__name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Group Service" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{original.group_service || '—'}</span>
              </TooltipTrigger>
              {original.group_service && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.group_service}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Group Service' },
        },
        {
          accessorKey: 'date_and_time',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Date & Time" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {original.date_and_time ? formatDateShortMonth(original.date_and_time) : '—'}
                </span>
              </TooltipTrigger>
              {original.date_and_time && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(original.date_and_time)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 180,
          meta: { label: 'Date & Time' },
        },
        {
          accessorKey: 'service_categories',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Service Categories" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 break-all">
                  {original.service_categories
                    ?.map((category) => snakeToCamelWithSpaces(category.name))
                    .join(', ') || '—'}
                </span>
              </TooltipTrigger>
              {original.service_categories && original.service_categories.length > 0 && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    <ul>
                      {original.service_categories.map((category) => (
                        <li key={category.id} className="list-inside list-decimal">
                          {snakeToCamelWithSpaces(category.name)}
                        </li>
                      ))}
                    </ul>
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          enableSorting: false,
          size: 140,
          meta: { label: 'Service Categories' },
        },
        {
          accessorKey: 'created_by__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Created By" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                {original.created_by
                  ? (
                      <Link
                        to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: {
                          id: original.created_by.id,
                        } })}
                        onClick={(e) => e.stopPropagation()}
                        className="line-clamp-1 min-w-0 break-all text-brand-blue-700"
                      >
                        {original.created_by.full_name}
                      </Link>
                    )
                  : (
                      <span className="line-clamp-1 min-w-0 break-all">System</span>
                    )}
              </TooltipTrigger>
              {original.created_by && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.created_by.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 130,
          meta: { label: 'Created By' },
        },
      ] satisfies ColumnDef<TStudentCenteredAction>[],
    [],
  );

  return columns;
};
