import { useCallback, useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { covertFullDateToDateWithoutTime } from '@purple/shared-utils';
import { Button, DateRangePicker, SearchInput, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTable, DataTableViewOptions, DistrictMultiSelect, SchoolMultiSelect } from '~/components';
import { AdminRoutes, TableName } from '~/constants';
import { useDataTable } from '~/hooks';
import { DISTRICT_STATUSES, useDocumentedActionsExportMutation, useDocumentedActionsList } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { actionsColumns } from './actionsColumns';
import type { DateRange } from '@purple/ui';

const DISTRICT_QUERY_NAME = 'district';
const SCHOOL_QUERY_NAME = 'school';

const DocumentedActions = () => {
  const [searchParameters] = useSearchParams();

  const { query: districtQuery, onQueryChange: onDistrictQueryChange } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const { query: schoolsQuery, onQueryChange: onSchoolsQueryChange, onClearQuery: onSchoolsQueryClear } = useQueryParameter({ queryName: SCHOOL_QUERY_NAME, resetOffset: true });

  useEffect(() => {
    if (!districtQuery) {
      onSchoolsQueryClear();
    }
  }, [districtQuery, onSchoolsQueryClear]);

  const { query: rangeQuery, onQueryChange: onRangeQueryChange } = useQueryParameter({ queryName: 'range', resetOffset: true });

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const [created_at__gte, created_at__lte] = useMemo(() => {
    const [from, to] = rangeQuery ? rangeQuery.split(',') : [null, null];

    const convertedFrom = from ? covertFullDateToDateWithoutTime(from) : null;
    const convertedTo = to ? covertFullDateToDateWithoutTime(to) : null;

    return [convertedFrom, convertedTo];
  }, [rangeQuery]);

  const { data: actionsData, isFetching } = useDocumentedActionsList({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    district: districtQuery,
    school: schoolsQuery,
    created_at__gte,
    created_at__lte,
  });

  const actions = useMemo(() => actionsData?.results ?? [], [actionsData?.results]);

  const { mutate: exportActions, isPending: isExportingActions } = useDocumentedActionsExportMutation();

  const { table } = useDataTable({
    name: TableName.DOCUMENTED_ACTIONS,
    columns: actionsColumns,
    data: actions,
    rowCount: actionsData?.count,
  });

  const updateDocumentedActionsDataPeriod = useCallback((range: DateRange) => {
    const convertedFrom = covertFullDateToDateWithoutTime(range.from);
    const convertedTo = covertFullDateToDateWithoutTime(range.to || range.from);

    const rangeValue = `${convertedFrom},${convertedTo}`;
    onRangeQueryChange(rangeValue);
  }, [onRangeQueryChange]);

  const selectedSchools = useMemo(() => {
    const array = schoolsQuery?.split(',') || [];
    return array;
  }, [schoolsQuery]);

  const selectedDistricts = useMemo(() => {
    const array = districtQuery?.split(',') || [];
    return array;
  }, [districtQuery]);

  const exportClickHandler = () => {
    if (actionsData) {
      exportActions({
        limit: actionsData?.count,
        offset: 0,
        ordering: searchParameters.get(SORT_QUERY_NAME),
        search: debounceSearch,
        district: districtQuery,
        school: schoolsQuery,
        created_at__gte,
        created_at__lte,
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
        },
      });
    }
  };

  return (
    <div className="flex w-full flex-1 flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700 size-4 shrink-0" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Home.Root.path}>
          Back to Home Page
        </Link>
      </Button>
      <DataTable table={table} loading={isFetching} className="border-grey-200 shadow-custom-heavy rounded-lg border bg-white">
        <div className="flex w-full flex-col gap-4 p-4 pt-6">
          <Text variant="size-16" type="body-600" className="text-grey-title">Number of Documented Action</Text>
          <div className="flex w-full items-center justify-between gap-4">
            <div className="flex w-full items-center gap-4">
              <DateRangePicker initialDateFrom={created_at__gte || ''} initialDateTo={created_at__lte || ''} onUpdate={updateDocumentedActionsDataPeriod} triggerClassNames="h-10" />
              <div className="min-w-[200px] max-w-[200px]">
                <SearchInput
                  value={search}
                  placeholder="Search"
                  className="max-w-[300px]"
                  onChange={onSearchChange}
                  onClear={onClearSearch}
                />
              </div>
              <div className="min-w-[200px]">
                <DistrictMultiSelect values={selectedDistricts || ''} onChange={(newValue) => onDistrictQueryChange(newValue.join(','))} placeholder="Select district" status={DISTRICT_STATUSES.PUBLISHED} />
              </div>
              <div className="min-w-fit">
                <SchoolMultiSelect values={selectedSchools} onChange={(newValue) => onSchoolsQueryChange(newValue.join(','))} onClear={onSchoolsQueryClear} districtId={districtQuery} isDistrictRequired />
              </div>
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="secondary"
                  size="icon_40"
                  className="size-10 shrink-0"
                  iconLeft={<PurpleIcon name="download" />}
                  disabled={actions.length === 0}
                  isLoading={isExportingActions}
                  onClick={exportClickHandler}
                />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>Export Table to Excel</TooltipContent>
              </TooltipPortal>
            </Tooltip>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </DataTable>
    </div>
  );
};

export { DocumentedActions };
