import { DISTRICT_STATUS } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';

export const DISTRICT_FILTERS = convertToFilterConfig({
  status: Object.values(DISTRICT_STATUS)
    .filter((status) => status !== DISTRICT_STATUS.DRAFT)
    .map((status) => ({
      id: status,
      name: status.charAt(0).toUpperCase() + status.slice(1),
    })),
}, { snakeCaseValue: false });
