import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITY_TYPES_QUERY_KEYS } from '../activities.const';
import { getActivityTypes } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActivityTypeListResponse, TActivityTypesRequestParameters } from '../activities.types';

export const useActivityTypes = (
  parameters?: TActivityTypesRequestParameters,
  queryOptions?: TCustomUseQueryOptions<TActivityTypeListResponse, AxiosError>,
) => {
  const queryData = useQuery({
    queryKey: [ACTIVITY_TYPES_QUERY_KEYS.GET_ACTIVITY_TYPES, parameters],
    queryFn: () => getActivityTypes(parameters),
    ...queryOptions,
  });

  useEffect(() => {
    if (queryData.isError && isClientError(queryData.error)) {
      showErrorToast('System message', 'Unable to retrieve activity types');
    }
  }, [queryData.isError, queryData.error]);

  return queryData;
};
