import { useSearchParams } from 'react-router-dom';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { AccessDenied } from '@purple/ui';
import { DataTable } from '~/components';
import { TableName } from '~/constants';
import { useDataTable } from '~/hooks';
import { useContacts } from '~/services';
import { contactsPrintColumns } from './contactsPrintColumns';
import type { FC } from 'react';

export const ContactsPrintView: FC = () => {
  const [searchParameters] = useSearchParams();

  const authToken = searchParameters.get('token');

  const { data, isLoading, isSuccess, isError } = useContacts({
    search: searchParameters.get('search'),
    limit: 999999999,
    offset: 0,
    ordering: searchParameters.get(SORT_QUERY_NAME),
    district: searchParameters.get('district'),
    type: searchParameters.get('type'),
    created_at__gte: searchParameters.get('created_at__gte'),
    created_at__lte: searchParameters.get('created_at__lte'),
    token: authToken,
  }, {
    enabled: !!authToken,
  });

  const { table } = useDataTable({
    name: TableName.PRINT_CONTACTS,
    columns: contactsPrintColumns,
    data: data?.results || [],
    pageCount: 1,
  });

  if (isError || !authToken) {
    return (
      <div className="flex h-screen items-center justify-center">
        <AccessDenied />
      </div>
    );
  }

  return (
    <DataTable table={table} hidePagination loading={isLoading} id={isSuccess ? 'download-ready' : ''} />
  );
};
