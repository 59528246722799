import { useCallback } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  cn,
  Separator,
  Text,
} from '@purple/ui';
import { useModal } from '~/hooks';
import type { MouseEventHandler } from 'react';
import type { TModalName } from '~/constants/modals';

type TCallToActionModalProperties = {
  modalName: TModalName;
  modalTitle: string;
  modalDescription: string;
  showModalDescription?: boolean;
  modalTextContent?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
  primaryButtonVariant?: 'primary' | 'secondary' | 'destructive_secondary' | 'destructive_primary';
  isLoading?: boolean;
  onClose?: () => void;
};

export const CallToActionModal = ({
  modalName,
  modalTitle,
  showModalDescription = false,
  modalTextContent,
  modalDescription,
  primaryButtonText = 'Submit',
  secondaryButtonText = 'Cancel',
  primaryButtonVariant = 'primary',
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onClose,
  isLoading = false,
}: TCallToActionModalProperties) => {
  const { isOpen, toggleModal, closeModal } = useModal(modalName);

  const handleSecondaryButtonClick = useCallback(() => {
    if (onSecondaryButtonClick) return onSecondaryButtonClick();
    closeModal();
  }, [closeModal, onSecondaryButtonClick]);

  const handlePrimaryButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      onPrimaryButtonClick();
    },
    [onPrimaryButtonClick],
  );

  const toggleModalOpenHandler = useCallback((opened: boolean) => {
    if (!opened) {
      onClose?.();
    }
    toggleModal(opened);
  }, [onClose, toggleModal]);

  return (
    <AlertDialog open={isOpen} onOpenChange={toggleModalOpenHandler}>
      <AlertDialogContent className="w-[564px]">
        <AlertDialogHeader className="flex-row items-center justify-between">
          <div className="flex flex-col gap-2">
            <AlertDialogTitle>{modalTitle}</AlertDialogTitle>
            <AlertDialogDescription className={cn({ 'sr-only': !showModalDescription })}>
              {modalDescription}
            </AlertDialogDescription>
          </div>
        </AlertDialogHeader>
        <Separator />
        <div className="p-6">
          <Text variant="size-14" type="body-500">
            {modalTextContent}
          </Text>
        </div>
        <Separator />
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleSecondaryButtonClick}>{secondaryButtonText}</AlertDialogCancel>
          <AlertDialogAction variant={primaryButtonVariant} isLoading={isLoading} onClick={handlePrimaryButtonClick}>
            {primaryButtonText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
