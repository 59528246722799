import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { DistrictUserStatusToBadgeVariant, formatDateShortMonth, getGradeGroups, getGradeLabel, ReadableDistrictUserStatus } from '@purple/shared-utils';
import { HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger, NumberBadge, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader, SelectableCell } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TDistrictUser } from '~/services';

type TDistrictUserColumns = ColumnDef<TDistrictUser>[];

export const districtUserColumns: TDistrictUserColumns = [
  SelectableCell(),
  {
    accessorKey: 'last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: row.original.id } })}>
            <span className="line-clamp-1 break-all text-brand-blue-700">{row.original.full_name}</span>
          </Link>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {row.original.full_name}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 180,
    meta: { label: 'Full Name' },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) =>
      row.original.status
        ? (
            <NumberBadge
              variant={DistrictUserStatusToBadgeVariant[row.original.status]}
              className="rounded-full capitalize"
            >
              {ReadableDistrictUserStatus[row.original.status]}
            </NumberBadge>
          )
        : (
            '-'
          ),
    size: 100,
    meta: { className: 'text-center' },
    enableSorting: false,
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Text type="body-500" variant="size-14" className="line-clamp-1 w-full break-all text-grey-950">
            {row.original.email || '-'}
          </Text>
        </TooltipTrigger>
        {row.original.email && (
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.original.email}
            </TooltipContent>
          </TooltipPortal>
        )}
      </Tooltip>
    ),
    size: 180,
    enableSorting: false,
  },
  {
    accessorKey: 'role',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Text type="body-500" variant="size-14" className="line-clamp-1 break-all capitalize text-grey-950">
            {row.original.prounitas_roles.join(', ') || '-'}
          </Text>
        </TooltipTrigger>
        {row.original.prounitas_roles?.length > 0 && (
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
              {row.original.prounitas_roles.join('\n')}
            </TooltipContent>
          </TooltipPortal>
        )}
      </Tooltip>
    ),
    size: 180,
    enableSorting: false,
  },
  {
    accessorKey: 'grades',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Grades" />,
    cell: ({ row }) => (
      <HoverCard>
        <HoverCardTrigger asChild>
          <NumberBadge variant="info" className="col-span-1 place-self-center text-nowrap rounded-full px-3 text-center text-sm font-medium text-grey-950">
            {getGradeLabel(row.original.grades)}
          </NumberBadge>
        </HoverCardTrigger>
        {row.original.grades?.length > 0 && (
          <HoverCardPortal>
            <HoverCardContent
              onClick={(evt) => evt.stopPropagation()}
              className="inline-flex w-full max-w-64 justify-center whitespace-pre-wrap border border-grey-200 p-2 text-xs font-medium leading-5 text-grey-950"
            >
              {getGradeGroups(row.original.grades)}
            </HoverCardContent>
          </HoverCardPortal>
        )}
      </HoverCard>
    ),
    size: 120,
    enableSorting: false,
  },
  {
    accessorKey: 'date_joined',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Text type="body-500" variant="size-14" className="line-clamp-1 break-all capitalize text-grey-950">
            {formatDateShortMonth(row.original.date_joined) || '-'}
          </Text>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {format(parseISO(row.original.date_joined), 'MMMM dd, yyyy')}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 120,
    meta: { label: 'Date Added' },
  },
];
