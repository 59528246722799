import { useMutation } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { getBulkTemplate } from './get-template';
import { TEMPLATES_QUERY_KEYS } from './templates.const';
import type { AxiosError } from 'axios';
import type { TBulkTemplateResponse, ValueOf } from '@purple/shared-types';
import type { ALLOWED_BULK_TEMPLATES } from '@purple/shared-utils';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useDownloadBulkTemplate = (templateType: ValueOf<typeof ALLOWED_BULK_TEMPLATES>, queryOptions?: TCustomUseMutationOptions<TBulkTemplateResponse, AxiosError>) => {
  return useMutation({
    ...queryOptions,
    mutationKey: [TEMPLATES_QUERY_KEYS.GET_TEMPLATE_FILE, templateType],
    mutationFn: getBulkTemplate,
    onError: (error) => {
      if (isClientError(error)) {
        showErrorToast('System message', 'Unable to download the template. Please try again later.');
      }
    },
  });
};
