import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { updateRecurringActivityDetails } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActivityDetails, TActivityDetailsUpdatePayload } from '../activities.types';

export const useUpdateRecurringActivity = (
  options?: TCustomUseMutationOptions<TActivityDetails, AxiosError, TActivityDetailsUpdatePayload>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [ACTIVITIES_QUERY_KEYS.UPDATE_RECURRING_ACTIVITY_DETAILS],
    mutationFn: updateRecurringActivityDetails,
    onSuccess: (data, ...args) => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_LIST] });
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_BASIC_INFO] });
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_DETAILS] });
      showSuccessToast('System message', 'Activities updated successfully');
      options?.onSuccess?.(data, ...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to update recurring activities');
      options?.onError?.(...args);
    },
  });
};
