import { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { nanoid } from '@reduxjs/toolkit';
import { PurpleIcon } from '@purple/icons';
import { SafSubmitterType } from '@purple/shared-types';
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Separator,
  Text,
  ToggleGroup,
  ToggleGroupItem,
  useStepper,
} from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { ReadableSubmitterOptions, SubmitterOptions, type TSubmitterOptions } from '~/pages/Main/Districts/constants';
import { SafNeedTypeFormItem } from '~/pages/Main/Districts/Details/components/tabs/SafProcess/SafNeedTypeFormItem';
import { useUnsavedChanges } from '~/providers';
import { useUpdateDistrictSafProcess } from '~/services';
import {
  districtIdSelector,
  districtSafProcessSelector,
  maxDistrictStepSelector,
  setMaxStep,
} from '~/store/features/district-set-up';
import { showErrorToast } from '~/utils/toasts';
import { StepCardContainer } from '../../StepCardContainer';
import {
  DEFAULT_ACKNOWLEDGEMENT_TEXT_VALUE,
  DEFAULT_DISCLAIMER_TEXT_VALUE,
  DEFAULT_INTRODUCTION_TEXT_VALUE,
  DEFAULT_SPECIAL_ED_TEXT_VALUE,
} from './constants';
import { RichTextArea } from './RichTextArea';
import { safProcessStepSchema } from './schema';
import type { FieldArrayWithId } from 'react-hook-form';
import type { z } from 'zod';
import type { TSafSubmitterType } from '@purple/shared-types';
import type { TAdminTranslation } from '~/services';

const SafProcessStep = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { nextStep, activeStep } = useStepper();

  // @ts-expect-error - TS doesn't understand that the default values are set in the schema
  const [editableIntroductionText, setEditableIntroductionText] = useState<TAdminTranslation>(DEFAULT_INTRODUCTION_TEXT_VALUE);
  // @ts-expect-error - TS doesn't understand that the default values are set in the schema
  const [editableAcknowledgementText, setEditableAcknowledgementText] = useState<TAdminTranslation>(DEFAULT_ACKNOWLEDGEMENT_TEXT_VALUE);
  // @ts-expect-error - TS doesn't understand that the default values are set in the schema
  const [editableDisclaimerText, setEditableDisclaimerText] = useState<TAdminTranslation>(DEFAULT_DISCLAIMER_TEXT_VALUE);
  // @ts-expect-error - TS doesn't understand that the default values are set in the schema
  const [editableSpecialEdText, setEditableSpecialEdText] = useState<TAdminTranslation>(DEFAULT_SPECIAL_ED_TEXT_VALUE);

  const [selectedOption, setSelectedOption] = useState<TSubmitterOptions>(SubmitterOptions.ALL);

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const formId = useId();

  const districtId = useAppSelector(districtIdSelector);
  const districtSafProcess = useAppSelector(districtSafProcessSelector);
  const maxDistrictStep = useAppSelector(maxDistrictStepSelector);

  const { mutate: updateDistrictSafProcess, isPending } = useUpdateDistrictSafProcess();

  const defaultValues = useMemo(() => {
    return {
      ...districtSafProcess,
      email: districtSafProcess.email ?? '',
    };
  }, [districtSafProcess]);

  const form = useForm<z.infer<typeof safProcessStepSchema>>({
    resolver: zodResolver(safProcessStepSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    if (form.formState.isDirty) {
      setShouldShowUnsaved(true);
    }
  }, [form.formState.isDirty, setShouldShowUnsaved]);

  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: 'needs',
  });

  useEffect(() => {
    form.reset({
      ...defaultValues,
    });
  }, [defaultValues, form, districtId]);

  const submitHandler = (data: z.infer<typeof safProcessStepSchema>, onSaveCallback?: () => void) => {
    if (districtId) {
      const filteredNeeds = data.needs.map((need) => {
        const wasIdExist = defaultValues.needs.some((defaultNeed) => defaultNeed.id === need.id);
        return {
          // set ID only if it was already exist
          // in other case leave it unset and BE will create new one for this need
          ...(wasIdExist ? { id: need.id } : {}),
          name: need.name,
          submitter_types: need.submitter_types as TSafSubmitterType[],
        };
      });

      const payload = {
        ...data,
        introduction_text: JSON.stringify(editableIntroductionText),
        acknowledgement_text: JSON.stringify(editableAcknowledgementText),
        disclaimer_text: JSON.stringify(editableDisclaimerText),
        special_ed_text: JSON.stringify(editableSpecialEdText),
        needs: filteredNeeds,
      };

      updateDistrictSafProcess(
        {
          id: districtId,
          ...payload,
        },
        {
          onSuccess: () => {
            setShouldShowUnsaved(false);
            onSaveCallback?.();
            form.reset(data);
            nextStep();
          },
        },
      );
    } else {
      showErrorToast('System message', 'Could not find district id. Check the provided information and try again');
    }
  };

  const navigateToDistrictList = useCallback(() => {
    navigate(AdminRoutes.App.Districts.Root.path);
  }, [navigate]);

  useEffect(() => {
    if (districtSafProcess) {
      if (districtSafProcess.introduction_text) {
        setEditableIntroductionText(districtSafProcess.introduction_text as TAdminTranslation);
      }
      if (districtSafProcess.acknowledgement_text) {
        setEditableAcknowledgementText(districtSafProcess.acknowledgement_text as TAdminTranslation);
      }
      if (districtSafProcess.disclaimer_text) {
        setEditableDisclaimerText(districtSafProcess.disclaimer_text as TAdminTranslation);
      }
      if (districtSafProcess.special_ed_text) {
        setEditableSpecialEdText(districtSafProcess.special_ed_text as TAdminTranslation);
      }
    }
  }, [districtSafProcess]);

  const optionChangeHandler = (option: string) => {
    if (option === selectedOption || option === '') return;
    setSelectedOption(option as TSubmitterOptions);
  };

  const needsItemSelectChangeHandler = (
    index: number,
    field: FieldArrayWithId<z.infer<typeof safProcessStepSchema>, 'needs'>,
    value: boolean,
  ) => {
    const updatedTypes
      = selectedOption === SubmitterOptions.ALL && value
        ? Object.values(SafSubmitterType)
        : selectedOption === SubmitterOptions.ALL && !value
          ? []
          : selectedOption !== SubmitterOptions.ALL && value
            ? [...field.submitter_types, selectedOption]
            : selectedOption !== SubmitterOptions.ALL && !value
              ? field.submitter_types.filter((type) => type !== selectedOption)
              : field.submitter_types;

    const targetField = form.getValues('needs')[index] ?? field;

    update(index, {
      ...targetField,
      submitter_types: updatedTypes,
    });
  };

  const removeNeedItemHandler = (index: number) => {
    remove(index);
  };

  const increaseMaxStep = () => {
    if (maxDistrictStep === activeStep) {
      dispatch(setMaxStep(maxDistrictStep + 1));
    }
  };

  const editableDataChangeHandler = (textType: string, data: TAdminTranslation) => {
    if (textType === 'introduction_text') {
      setEditableIntroductionText(data);
    }
    if (textType === 'acknowledgement_text') {
      setEditableAcknowledgementText(data);
    }
    if (textType === 'disclaimer_text') {
      setEditableDisclaimerText(data);
    }
    if (textType === 'special_ed_text') {
      setEditableSpecialEdText(data);
    }
  };

  const addNeedItemClickHandler = () => {
    append({
      id: nanoid(),
      name: `Need ${fields.length + 1}`,
      submitter_types: selectedOption === SubmitterOptions.ALL ? Object.values(SafSubmitterType) : [selectedOption],
    });
  };

  return (
    <StepCardContainer
      isStepValid
      onSave={() => submitHandler(form.getValues(), navigateToDistrictList)}
      formId={formId}
      isLoading={isPending}
    >
      <Form
        providerProps={form}
        id={formId}
        className="flex w-full flex-col gap-6"
        onSubmit={form.handleSubmit((data) => submitHandler(data, increaseMaxStep))}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <Heading variant="size-18" type="heading-500" className="text-grey-950">
                  Anonymous SAF submission
                </Heading>
                <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
                  By default each submitter type on the SAF form is able to submit an anonymous SAF. You can update to
                  determine that specific SAF submitter types will not be able to submit anonymously.
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <Text variant="size-16" type="body-500" className="text-grey-950">
                  Who can submit anonymously?
                </Text>
                <FormField
                  control={form.control}
                  name="can_student_submit_anonymously"
                  render={({ field }) => {
                    return (
                      <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            {...field}
                            value={field.value?.toString()}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel className="text-[16px] font-normal text-grey-800">
                          Student (when submitting for another student)
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="can_teacher_submit_anonymously"
                  render={({ field }) => (
                    <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          {...field}
                          value={field.value?.toString()}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="text-[16px] font-normal text-grey-800">Teacher or Staff Member</FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="can_parent_submit_anonymously"
                  render={({ field }) => (
                    <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          {...field}
                          value={field.value?.toString()}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="text-[16px] font-normal text-grey-800">Parent or Caregiver</FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="can_community_member_submit_anonymously"
                  render={({ field }) => (
                    <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          {...field}
                          value={field.value?.toString()}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="text-[16px] font-normal text-grey-800">
                        Community Member or Service Provider
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <Separator />
            <RichTextArea label="Introduction Text" description="This section is situated before the 'My Information' section. You can edit the default message." data={editableIntroductionText} onChange={(newData) => editableDataChangeHandler('introduction_text', newData)} />
            <Separator />
            <RichTextArea label="Acknowledgement Text" description="This is the last page of the SAF. You can edit the default message." data={editableAcknowledgementText} onChange={(newData) => editableDataChangeHandler('acknowledgement_text', newData)} />
            <Separator />
            <RichTextArea label="Disclaimer Text" description="This section contains important information regarding the SAF." data={editableDisclaimerText} onChange={(newData) => editableDataChangeHandler('disclaimer_text', newData)} />
            <Separator />
            <RichTextArea label="Special-Ed Student Text" description="This section contains important information regarding the students who have a special education IEP or is 504." data={editableSpecialEdText} onChange={(newData) => editableDataChangeHandler('special_ed_text', newData)} />
            <Separator />
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <Heading variant="size-18" type="heading-500" className="text-grey-950">
                  Need Types
                </Heading>
                <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
                  Set the types of needs based on the group of users that will submit the SAF.
                </Text>
              </div>
              <ToggleGroup
                type="single"
                className="w-fit items-stretch justify-stretch gap-0 overflow-hidden rounded-lg border border-grey-200"
                value={selectedOption}
                onValueChange={optionChangeHandler}
              >
                {Object.values(SubmitterOptions).map((submitterType) => (
                  <ToggleGroupItem
                    key={submitterType}
                    value={submitterType}
                    variant="default"
                    size="default"
                    className="h-auto min-h-9 shrink grow items-center justify-center break-all rounded-none border-r border-grey-200 px-4 py-2 font-primary text-sm font-medium text-grey-950 last-of-type:border-0 hover:bg-brand-blue-100 hover:text-grey-950 focus:bg-brand-blue-100 focus:text-grey-950 focus-visible:bg-brand-blue-100 focus-visible:text-grey-950 active:bg-brand-blue-50 data-[state='on']:!bg-brand-blue-700 data-[state='on']:font-semibold data-[state='on']:!text-white data-[state='on']:hover:bg-brand-blue-600 data-[state='on']:focus:bg-brand-blue-600 data-[state='on']:focus-visible:bg-brand-blue-600 data-[state='on']:active:bg-brand-blue-500"
                  >
                    {ReadableSubmitterOptions[submitterType]}
                  </ToggleGroupItem>
                ))}
              </ToggleGroup>
              <div className="flex w-full flex-col gap-2">
                <Text tag="strong" type="body-500" variant="size-16" className="text-grey-800">
                  Need Types available for this group of users
                </Text>
                {fields.map((field, index) => (
                  <SafNeedTypeFormItem
                    key={field.id}
                    control={form.control}
                    value={field.name}
                    id={field.id}
                    name={`needs.${index}.name`}
                    selected={
                      selectedOption === SubmitterOptions.ALL
                        ? Object.values(SafSubmitterType).some((type) => field.submitter_types.includes(type))
                        : field.submitter_types.includes(selectedOption)
                    }
                    intermediate={
                      selectedOption === SubmitterOptions.ALL
                      && field.submitter_types.length > 0
                      && !Object.values(SafSubmitterType).every((type) => field.submitter_types.includes(type))
                    }
                    disableRemove={fields.length === 1}
                    onCheckedChange={(value) => needsItemSelectChangeHandler(index, field, value)}
                    onRemove={() => removeNeedItemHandler(index)}
                  />
                ))}
                <FormField name={'needs.root' as 'needs'} control={form.control} render={() => <FormMessage />} />
                <Button
                  type="button"
                  variant="tertiary"
                  size="small"
                  className="w-fit"
                  iconLeft={<PurpleIcon name="plus" />}
                  onClick={addNeedItemClickHandler}
                >
                  Add new need
                </Button>
              </div>
            </div>
            <Separator />
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <Heading variant="size-18" type="heading-500" className="text-grey-950">
                  City or Region
                </Heading>
                <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
                  It is the hierarchy field for the SAF.
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <FormField
                  control={form.control}
                  name="show_city_or_region_hierarchy_field"
                  render={({ field }) => {
                    return (
                      <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            {...field}
                            value={field.value?.toString()}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel className="text-[16px] font-normal text-grey-800">
                          Show city/region hierarchy field
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
            </div>
            <Separator />
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <Heading variant="size-18" type="heading-500" className="text-grey-950">
                  Email Address
                </Heading>
                <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
                  District specific email address will be used across all parts of the system, not just for the SAF
                  process.
                </Text>
              </div>
              <div className="flex max-w-[530px] flex-col gap-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Enter district email address"
                          type="email"
                          isError={!!form.formState.errors.email}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </StepCardContainer>
  );
};

export { SafProcessStep };
