import { Message } from '@purple/ui';
import { DataTable } from '~/components/DataTable';
import { TableName } from '~/constants';
import { useDataTable } from '~/hooks';
import { useActionsErrorsColumns } from './columns';
import type { TBulkUploadResponse, TTakeActionType } from '@purple/shared-types';
import type { TActionBulkUploadResponse } from '~/services';

type TUploadFileStatisticProps<T extends TTakeActionType> = {
  fileStatisticData: TBulkUploadResponse<TActionBulkUploadResponse<T>> | null;
  selectedActionType: T;
};

export const UploadFileStatistic = <T extends TTakeActionType>({ fileStatisticData, selectedActionType }: TUploadFileStatisticProps<T>) => {
  const actionErrorsColumns = useActionsErrorsColumns<T>(selectedActionType);

  const { table } = useDataTable<TActionBulkUploadResponse<T>>({
    name: TableName.BULK_IMPORT_ACTIONS,
    columns: actionErrorsColumns,
    data: fileStatisticData?.fields ?? [],
    pageCount: 1,
    initialState: {
      columnPinning: {
        left: ['row'],
      },
    },
  });

  const successfulUploadedUsers = fileStatisticData?.success_count ?? 0;
  const skippedUsers = fileStatisticData?.skipped_count ?? 0;
  const failedUsers = fileStatisticData?.errors_count ?? 0;

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex flex-col gap-4">
        <Message variant="success" className="px-3 py-2 text-sm">
          <span className="font-medium">
            {successfulUploadedUsers}
          </span>
          {' '}
          actions successfully uploaded
        </Message>
        {skippedUsers > 0 && (
          <Message variant="warning" className="px-3 py-2 text-sm">
            <span className="font-medium">{skippedUsers}</span>
            {' '}
            actions already exist in the system and will not be added to avoid duplicates
          </Message>
        ) }
        {failedUsers > 0 && (
          <Message variant="error" className="px-3 py-2 text-sm">
            <span className="font-medium">{failedUsers}</span>
            {' '}
            rows contain errors
          </Message>
        )}
      </div>
      <DataTable
        table={table}
        hidePagination
        className="max-h-[450px]"
        emptyState={{
          title: 'No errors found',
          message: 'No errors found in the uploaded file',
          iconName: 'check-circle',
        }}
      />
    </div>
  );
};
