import { useEffect, useMemo } from 'react';
import { Placeholder } from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useQueryParameter } from '@purple/hooks';
import { cn, Text } from '@purple/ui';
import { LinkBubbleMenu } from '~/pages/Main/Districts/Details/components/LinkBubbleMenu';
import { SafEditorToolbar } from '~/pages/Main/Districts/Details/components/SafEditorToolbar';
import { SafLinkExtention } from '~/pages/Main/Districts/Details/components/SafLinkExtention';
import { DISTRICT_QUERY_NAME } from '../AllSafs/constants';
import type { JSONContent } from '@tiptap/react';
import type { TAdminTranslation } from '~/services';
import type { TSafLanguages } from '../../constants';

type TTextTranslationEditorProperties = {
  label: string;
  data: TAdminTranslation;
  onChange: (data: TAdminTranslation) => void;
  mode: 'view' | 'edit';
  selectedLanguage: TSafLanguages;
};

const TextTranslationEditor = ({ label, data, onChange, mode, selectedLanguage }: TTextTranslationEditorProperties) => {
  const { query: districtQuery } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const contentData = useMemo(() => {
    return data && districtQuery
      ? data[selectedLanguage]
      : '';
  }, [selectedLanguage, districtQuery, data]);

  const updateEditableData = (value: JSONContent) => {
    if (!data) return;

    const updatedLanguage = {
      ...data,
      [selectedLanguage]: value,
    };
    onChange(updatedLanguage);
  };

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      SafLinkExtention.configure({
        autolink: true,
        defaultProtocol: 'https',
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: contentData,
    editable: mode === 'edit',
    onBlur: ({ editor }) => {
      const jsonValue = editor.getJSON();
      updateEditableData(jsonValue);
    },
  });

  useEffect(() => {
    if (districtQuery) {
      const text = data?.[selectedLanguage];

      if (text === null || text === undefined) {
        editorConfig?.commands.setContent('');
      } else {
        editorConfig?.commands.setContent(text);
      }
    }
  }, [selectedLanguage, districtQuery, data]);

  return (
    <div className="grid grid-cols-2 items-start gap-x-4 px-6">
      <Text variant="size-14" type="body-400" className="py-2.5 text-grey-900">{label}</Text>
      <div className="flex flex-col gap-4 pb-2.5">
        {mode === 'edit' && (
          <div className="pt-2.5">
            <SafEditorToolbar editor={editorConfig} />
          </div>
        )}
        <EditorContent
          editor={editorConfig}
          className={cn(
            'minimal-tiptap-editor flex h-full min-h-[80px] w-full cursor-text flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 focus-within:border-brand-blue-700 hover:border-brand-blue-700 focus:border-brand-blue-700',
            {
              'border-white focus-within:border-white hover:border-white focus:border-white': mode === 'view',
            },
          )}
        />
        {editorConfig && <LinkBubbleMenu editor={editorConfig} />}
      </div>
    </div>

  );
};

export { TextTranslationEditor };
