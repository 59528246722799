import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { DISTRICT_STATUS, DistrictUserStatus, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import {
  AccessDenied,
  AppFilters,
  Button,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Heading,
  SearchInput,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import {
  AddInvitePurpleUserModal,
  BulkInvitePurpleUsersModal,
  CallToActionModal,
  DataTable,
  DataTableSelectedController,
  DataTableViewOptions,
  DistrictFilterComboBox,
} from '~/components';
import { ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { PRINT_OPTIONS } from '~/pages/System';
import { PRINT_QUERY_KEYS, useBulkActivateUsers, useDeleteUser, useDistrictBulkDeactivateUsers, useExportUsers, useGetUsersListMutation, usePrint, useUserManagementFilterOptions, useUsersListManagement } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { useUserManagementColumns } from './userManagementColumns';
import type { TUserManagement, TUsersDeactivateCheck } from '~/services';

const DISTRICT_QUERY_NAME = 'district';

const UsersList = () => {
  const [searchParameters] = useSearchParams();

  const [clickableUser, setClickableUser] = useState<TUserManagement | null>(null);

  const { openModal: openDeactivateModal, closeModal: closeDeactivateModal } = useModal(
    ModalType.BULK_USERS_DEACTIVATE,
  );
  const { openModal: openDeactivateWithSafModal, closeModal: closeDeactivateWithSafModal } = useModal(
    ModalType.BULK_USERS_WITH_SAF_DEACTIVATE,
  );
  const { openModal: openActivateModal, closeModal: closeActivateModal } = useModal(
    ModalType.USER_ACTIVATE_MODAL,
  );
  const { openModal: openBulkActivateModal, closeModal: closeBulkActivateModal } = useModal(
    ModalType.BULK_USERS_ACTIVATE_MODAL,
  );
  const { openModal: openDeleteUserModal, closeModal: closeDeleteUserModal } = useModal(
    ModalType.USER_DELETE,
  );

  const { openModal: openAddInvitePurpleModal } = useModal(ModalType.ADD_INVITE_PURPLE_USER);
  const { openModal: openBulkInvitePurpleModal } = useModal(ModalType.BULK_INVITE_PURPLE_USERS);

  const { query: sortQuery } = useQueryParameter({ queryName: SORT_QUERY_NAME, resetOffset: true });
  const { query: districtQuery, onQueryChange: onDistrictQueryChange, onClearQuery: onDistrictQueryClear } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: options, isFetching: isOptionsLoading } = useUserManagementFilterOptions({
    district: districtQuery ? +districtQuery : undefined,
    enabled: true,
  });

  const { data, isFetching: isUsersFetching } = useUsersListManagement({
    ...(districtQuery && { district: districtQuery }),
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: sortQuery,
    school: searchParameters.get('school') || '',
    status: searchParameters.get('status') || '',
    role: searchParameters.get('role') || '',
  });

  const { mutate: getAllUsers, isPending: isGetAllUsersPending } = useGetUsersListMutation();
  const { mutate: exportUsers, isPending: isExportPending } = useExportUsers({
    ...(districtQuery && { district: districtQuery }),
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: sortQuery,
    school: searchParameters.get('school') || '',
    status: searchParameters.get('status') || '',
    role: searchParameters.get('role') || '',
  });

  const { mutate: bulkUserDeactivate, isPending: isDeactivatePending } = useDistrictBulkDeactivateUsers();

  const { mutate: bulkUserActivate, isPending: isActivatePending } = useBulkActivateUsers();

  const { mutate: userDelete, isPending: isDeletePending } = useDeleteUser();

  const { mutate: printUsersList, isPending: isUsersListPrinting } = usePrint(PRINT_QUERY_KEYS.ADMIN_USERS_LIST);

  const users = useMemo(() => data?.results ?? [], [data?.results]);

  const filters = useMemo(
    () =>
      options
        ? Object.entries(options).reduce(
            (accumulator, [key, value]) =>
              key === 'status' || key === 'role' ? { ...accumulator, [key]: value } : accumulator,
            {
              status: Object.values(DistrictUserStatus).map((status) => ({
                id: status,
                name: status,
              })),
              ...(options?.school?.length && options?.school?.length > 1 && {
                school: options?.school?.map(({ id, name }) => ({ id, name })),
              }),
            },
          )
        : null,
    [options],
  );

  const filterConfig = useMemo(
    () => (filters ? convertToFilterConfig(filters, { snakeCaseValue: false }) : { categories: [] }),
    [filters],
  );

  const deactivateActionClickHandler = (checkResponse: TUsersDeactivateCheck) => {
    const hasRoutingRuleUsers = Object.keys(checkResponse.routing_rule_users).length > 0;
    const hasOpenedSafUsers = checkResponse.opened_saf_users.length > 0;
    const hasValidUsers = checkResponse.valid_users.length > 0;

    if (hasRoutingRuleUsers) {
      const usersIds = Object.keys(checkResponse.routing_rule_users);
      const users = data?.results.filter((user) => usersIds.includes(user.id));

      if (users && users?.length > 0) {
        const schools = Object.values(checkResponse.routing_rule_users).join(', ');
        const errorText = `User is a part of the SAF routing directory for the following schools: ${schools}. If you are sure you want to deactivate this user, user will need to be removed from SAF routing rules first.`;
        showErrorToast('User error', errorText);
        return;
      }
    }

    if (hasOpenedSafUsers) {
      const users = data?.results.filter((user) => checkResponse.opened_saf_users.includes(user.id));
      if (users && users?.length > 0) {
        // We take the first user from the list because we are only checking one user at a time
        setClickableUser(users[0] || null);
        openDeactivateWithSafModal();
        return;
      }
    }

    if (hasValidUsers) {
      const users = data?.results.filter((user) => checkResponse.valid_users.includes(user.id));
      if (users && users?.length > 0) {
        // We take the first user from the list because we are only checking one user at a time
        setClickableUser(users[0] || null);
        openDeactivateModal();
      }
    }
  };

  const deleteActionClickHandler = (user: TUserManagement) => {
    setClickableUser(user);
    openDeleteUserModal();
  };

  const activateActionClickHandler = (user: TUserManagement) => {
    setClickableUser(user);
    openActivateModal();
  };

  const { table } = useDataTable({
    name: TableName.USERS,
    columns: useUserManagementColumns({
      onDeactivate: deactivateActionClickHandler,
      onActivate: activateActionClickHandler,
      onDelete: deleteActionClickHandler,
    }),
    data: users,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
      },
    },
    getRowId: (originalRow) => originalRow.id,
    onSelectionChange: (rows, setSelectedRows) => {
      const selectedIds = Object.entries(rows).flatMap(([id, selected]) => (selected ? [id] : []));
      setSelectedRows(
        (prevRows) => [
          ...prevRows,
          ...users.map(({ id, full_name }) => ({ id, name: full_name })),
        ]
          .filter((activity, index, self) => index === self.findIndex((a) => a.id === activity.id))
          .filter((activity) => selectedIds.includes(activity.id)),
      );
    },
  });

  const deactivateUsersClickHandler = () => {
    if (clickableUser) {
      bulkUserDeactivate(
        {
          users: [clickableUser.id],
        },
        {
          onSuccess: () => {
            closeDeactivateModal();
            closeDeactivateWithSafModal();
            setClickableUser(null);
            table.options.meta?.clearSelectedRows?.();
          },
        },
      );
    }
  };

  const activateUsers = (usersIds: string[]) => {
    if (users.length) {
      bulkUserActivate(
        {
          users: usersIds,
        },
        {
          onSuccess: () => {
            closeActivateModal();
            closeBulkActivateModal();
            setClickableUser(null);
            table.options.meta?.clearSelectedRows?.();
          },
        },
      );
    }
  };

  const activateUserClickHandler = () => {
    if (clickableUser) {
      activateUsers([clickableUser.id]);
    }
  };

  const activateUsersClickHandler = () => {
    const selectedRows = table.options.meta?.selectedRows ?? [];
    const ids = selectedRows.map((row) => row.original.id);
    if (ids.length) {
      activateUsers(ids);
    }
  };

  const deleteUsersClickHandler = () => {
    if (clickableUser) {
      userDelete(clickableUser.id, {
        onSuccess: () => {
          closeDeleteUserModal();
          setClickableUser(null);
        },
      });
    }
  };

  const selectAllFoundHandler = () => {
    getAllUsers({
      ...(districtQuery && { district: districtQuery }),
      search: debounceSearch,
      limit: data?.count || 0,
      offset: 0,
      ordering: sortQuery,
      school: searchParameters.get('school') || '',
      status: searchParameters.get('status') || '',
      role: searchParameters.get('role') || '',
    }, {
      onSuccess: (allUsers) => {
        const selectedRows = allUsers.results.reduce<Record<string, boolean>>((acc, item) => ({
          ...acc,
          [item.id]: true,
        }), {}) ?? {};
        table.setRowSelection(selectedRows);
        table.options.meta?.setSelectedRows?.(allUsers.results.map((user) => ({ id: user.id, name: user.full_name })) ?? []);
      },
    });
  };

  const printUsersListHandler = useCallback(() => {
    printUsersList({
      variant: PRINT_OPTIONS.USERS_LIST,
      landscape: 'true',
      district: districtQuery,
      search: debounceSearch,
      ordering: sortQuery,
      school: searchParameters.get('school'),
      status: searchParameters.get('status'),
      role: searchParameters.get('role'),
    });
  }, [printUsersList, districtQuery, debounceSearch, sortQuery, searchParameters]);

  return (
    <>
      <Guard
        requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_ADMIN_USERS_PERMISSIONS_ROLES]}
        fallback={(
          <AccessDenied
            iconSize={24}
            accessDeniedMessage="You do not have permission to view this page."
            className="border-grey-200 size-full rounded-lg border bg-white p-6 py-[200px]"
          />
        )}
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <Heading variant="size-22" type="heading-600" className="text-grey-title">Users</Heading>
              <Text variant="size-14" type="body-400" className="text-grey-950">List of all invited users to PurpleSense and Purple admin platform</Text>
            </div>
            <DropdownRoot>
              <DropdownTrigger asChild className="[&>svg]:data-[state=open]:rotate-180">
                <Button type="button" variant="primary" iconRight={<PurpleIcon name="chevron-down" className="transition-transform" />}>Add & Invite User</Button>
              </DropdownTrigger>
              <DropdownContent align="end">
                <DropdownItem iconName="user-add" onClick={openAddInvitePurpleModal}>
                  Add & Invite to Purple Sense
                </DropdownItem>
                <DropdownItem iconName="clipboard-copy" onClick={openBulkInvitePurpleModal}>
                  Bulk Import & Invite to Purple Sense
                </DropdownItem>
              </DropdownContent>
            </DropdownRoot>
          </div>
          <div className="border-grey-200 rounded-lg border bg-white">
            <DataTable
              table={table}
              loading={isUsersFetching}
            >
              <div className="flex flex-col gap-4 p-4">
                <div className="flex w-full items-center justify-between gap-4">
                  <div className="flex items-center gap-4">
                    <div className="min-w-[200px]">
                      <DistrictFilterComboBox value={districtQuery || ''} onChange={onDistrictQueryChange} onClear={onDistrictQueryClear} status={DISTRICT_STATUS.PUBLISHED} />
                    </div>
                    <AppFilters config={filterConfig} loading={isOptionsLoading} />
                    <SearchInput
                      value={search}
                      placeholder="Search"
                      className="max-w-[300px]"
                      onChange={onSearchChange}
                      onClear={onClearSearch}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <Button
                      type="button"
                      variant="secondary"
                      size="default"
                      disabled={table.options.meta?.selectedRows?.length === 0}
                      onClick={openBulkActivateModal}
                    >
                      Activate User(s)
                    </Button>
                    <Button
                      type="button"
                      variant="secondary"
                      size="default"
                      iconLeft={<PurpleIcon name="mail" />}
                      disabled={table.options.meta?.selectedRows?.length === 0}
                    >
                      Send Email
                    </Button>
                    <DropdownRoot>
                      <DropdownTrigger disabled asChild>
                        <Button disabled={table.options.meta?.selectedRows?.length === 0} variant="secondary" iconRight={<PurpleIcon name="chevron-down" />}>
                          Priority List
                        </Button>
                      </DropdownTrigger>
                      <DropdownContent align="end" sideOffset={4} className="min-w-[232px]">
                        <DropdownItem iconName="plus" onClick={() => null}>
                          Some item
                        </DropdownItem>
                      </DropdownContent>
                    </DropdownRoot>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          type="button"
                          variant="secondary"
                          size="icon_40"
                          className="size-10 shrink-0"
                          iconLeft={<PurpleIcon name="download" />}
                          disabled={data?.results?.length === 0}
                          isLoading={isExportPending}
                          onClick={() => exportUsers()}
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent>
                          Export Table to Excel
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          type="button"
                          variant="secondary"
                          size="icon_40"
                          className="size-10 shrink-0"
                          isLoading={isUsersListPrinting}
                          onClick={printUsersListHandler}
                          iconLeft={<PurpleIcon name="printer" />}
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent>Print Table</TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                    <DataTableViewOptions table={table} />
                  </div>
                </div>
                <DataTableSelectedController table={table} isSelectAllLoading={isGetAllUsersPending} onSelectAll={selectAllFoundHandler} />
              </div>
            </DataTable>
          </div>
        </div>
      </Guard>
      <CallToActionModal
        modalName={ModalType.USER_DELETE}
        modalTitle="Delete User"
        modalDescription="Are you sure you want to delete User? Before clicking Yes, make sure that the following actions have been performed:
Check if the user is referenced in any SAF routing rules: If the user is found in a routing rule please remove the user from the SAF routing rule and assign another active user to the routing rule in place of the deleted user. Check Lookup Fields: If the user is found in lookup fields, please remove the user from the relevant ones before deleting."
        modalTextContent="Are you sure you want to delete User? Before clicking Yes, make sure that the following actions have been performed:
Check if the user is referenced in any SAF routing rules: If the user is found in a routing rule please remove the user from the SAF routing rule and assign another active user to the routing rule in place of the deleted user. Check Lookup Fields: If the user is found in lookup fields, please remove the user from the relevant ones before deleting."
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={deleteUsersClickHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
      />
      <CallToActionModal
        modalName={ModalType.BULK_USERS_DEACTIVATE}
        modalTitle="Deactivate User"
        modalDescription="By deactivating user, user will no longer have access to the districts."
        modalTextContent="Are you sure you want to deactivate the selected user?"
        primaryButtonText="Deactivate"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deactivateUsersClickHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeactivatePending}
      />
      <CallToActionModal
        modalName={ModalType.BULK_USERS_WITH_SAF_DEACTIVATE}
        modalTitle="The user has open SAFs"
        modalDescription="Have you had a conversation with district leadership to confirm who will be stepping into the vacancy and whether any open SAFs will be re-routed, and how?"
        modalTextContent="Have you had a conversation with district leadership to confirm who will be stepping into the vacancy and whether any open SAFs will be re-routed, and how?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={deactivateUsersClickHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeactivatePending}
      />
      <CallToActionModal
        modalName={ModalType.USER_ACTIVATE_MODAL}
        modalTitle="Activate User"
        modalDescription="Are you sure you want to activate the selected user?"
        modalTextContent="Are you sure you want to activate the selected user?"
        primaryButtonText="Activate"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={activateUserClickHandler}
        primaryButtonVariant="primary"
        isLoading={isActivatePending}
      />
      <CallToActionModal
        modalName={ModalType.BULK_USERS_ACTIVATE_MODAL}
        modalTitle="Activate Users"
        modalDescription="Are you sure you want to activate the selected users?"
        modalTextContent="Are you sure you want to activate the selected users?"
        primaryButtonText="Activate"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={activateUsersClickHandler}
        primaryButtonVariant="primary"
        isLoading={isActivatePending}
      />
      <AddInvitePurpleUserModal />
      <BulkInvitePurpleUsersModal />
    </>
  );
};

export { UsersList };
