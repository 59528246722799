import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { createBulkRoutingRules } from './create-bulk-routing-rules';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TBulkUploadResponse } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TBulkSafRoutingRulesErrorResponse } from './saf.types';

export const useBulkUploadRoutingRules = (queryOptions?: TCustomUseMutationOptions<TBulkUploadResponse<TBulkSafRoutingRulesErrorResponse>, AxiosError<{ uploaded_file: string[]; missing_columns?: string[] }>>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...queryOptions,
    mutationKey: [ADMIN_SAF_QUERY_KEYS.BULK_UPLOAD_SAF_ROUTING_RULES],
    mutationFn: createBulkRoutingRules,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_SAF_QUERY_KEYS.LIST_SAF_ROUTING_RULE] });
    },
    onError: (error) => {
      const { response } = error;
      if (response && isClientError(error)) {
        const { uploaded_file, missing_columns } = response.data;
        if (uploaded_file) {
          showErrorToast('File error', uploaded_file.join('\n'));
          return;
        }
        if (missing_columns) {
          showErrorToast('Missing columns', missing_columns.join('\n'));
          return;
        }
        showErrorToast('System message', 'Unknown error occurred while uploading contacts');
      }
    },
  });
};
