import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { getContactsPriorityListById } from './get-priority-list-by-id';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TContactPriorityListByIdResponse } from './contacts.types';

export const useContactsPriorityListById = (
  parameters: { id: string },
  queryOptions?: TCustomUseQueryOptions<TContactPriorityListByIdResponse, AxiosError>,
) => {
  const { id } = parameters;
  const { isError, error, ...queryData } = useQuery({
    queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LIST_BY_ID, id],
    queryFn: () => getContactsPriorityListById(id),
    retry: (failCount) => failCount < 3,
    ...queryOptions,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to fetch priority list');
    }
  }, [isError, error]);

  return { ...queryData, isError };
};
