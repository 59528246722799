import { useState } from 'react';

/**
 * Custom hook to manage table state with localStorage.
 * @param key - The key to use for the localStorage.
 * @param initialValue - The initial value to use if no value is found in localStorage.
 * @returns A tuple containing the stored value and a function to update the stored value.
 * @example
 * ```tsx
 * const [columns, setColumns] = useTableLocalStorage('columns', { column1: true, column2: false });
 * ```
 */
export const useTableLocalStorage = <TKey extends string = string, T = any>(
  key: TKey,
  initialValue: T,
): [T, (value: T) => void] => {
  const readValue = () => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}":`, error);
    }
  };

  return [storedValue, setValue];
};
