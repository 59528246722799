import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TAdminUsersFrequencyDistributionReportParameters, TAdminUsersFrequencyDistributionReportResponse } from './user.types';

export const getFrequencyDistributionReport = async (parameters: TAdminUsersFrequencyDistributionReportParameters) => {
  const response = await axiosInstance.get<TAdminUsersFrequencyDistributionReportResponse>(ADMIN_USER_MANAGEMENT.FREQUENCY_DISTRIBUTION_REPORT, {
    params: parameters,
  });
  return response.data;
};
