import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import {
  Button,
  DescriptionDetails,
  DescriptionItem,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Switch,
  Textarea,
} from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useActivityTypeDetails, useUpdateActivityType } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ActivityTypeTabContainer } from '../../components';
import { BasicDetailsInfo } from './BasicDetailsInfo';
import { BasicDetailsSkeleton } from './BasicDetailsSkeleton';
import { activityTypeSchema } from './schema';
import type { z } from 'zod';

export const BasicDetailsTab: React.FC = () => {
  const { id: activityTypeId } = useParams();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data: activityType, isFetching } = useActivityTypeDetails(activityTypeId as string);
  const { mutate: updateActivityType, isPending } = useUpdateActivityType();

  const defaultValues: z.infer<typeof activityTypeSchema> = useMemo(
    () => ({
      is_active: activityType?.is_active ?? false,
      is_recurrence: activityType?.is_recurrence ?? false,
      name: activityType?.name ?? '',
      usage: activityType?.usage ?? '',
    }),
    [activityType],
  );

  const form = useForm<z.infer<typeof activityTypeSchema>>({
    resolver: zodResolver(activityTypeSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof activityTypeSchema>) => {
    if (!activityType) {
      return showErrorToast('System Error', 'Activity Type not found');
    }

    updateActivityType({
      id: activityType.id,
      name: formData.name,
      usage: formData.usage,
      is_active: formData.is_active,
      is_recurrence: formData.is_recurrence,
    }, {
      onSuccess: () => {
        setIsEditing(false);
      },
    });
  };

  if (isFetching || !activityType) {
    return <BasicDetailsSkeleton />;
  }

  return (
    <ActivityTypeTabContainer
      title="Basic Details"
      editing={isEditing}
      loading={isPending}
      onEdit={editClickHandler}
      onCancel={cancelClickHandler}
      onSave={form.handleSubmit(saveDetailsClickHandler)}
    >
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              {/* TODO: uncomment when custom activities with builder will be implemented */}
              {/* <FormField
                control={form.control}
                name="name"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row flex-wrap items-center justify-start gap-2 space-y-0">
                    <FormLabel className="max-w-64 flex-1 font-primary text-base font-normal text-grey-600">
                      Name
                    </FormLabel>
                    <div className="flex w-full max-w-[320px] flex-1 flex-col gap-1 xl:max-w-[400px]">
                      <FormControl>
                        <Input
                          {...field}
                          type="text"
                          isError={!!fieldState.error}
                          placeholder="Enter name"
                          className="max-h-9"
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              /> */}
              <DescriptionItem className="justify-start">
                <DescriptionTerm tag="p" className="max-w-64 flex-1">Name</DescriptionTerm>
                <DescriptionDetails tag="p" className="m-0 flex-1">
                  {snakeToCamelWithSpaces(activityType.name)}
                </DescriptionDetails>
              </DescriptionItem>
              <FormField
                control={form.control}
                name="is_active"
                render={({ field }) => (
                  <FormItem className="flex min-h-9 w-full flex-row flex-wrap items-center justify-start gap-2 space-y-0">
                    <FormLabel className="max-w-64 flex-1 font-primary text-base font-normal text-grey-600">
                      Status
                    </FormLabel>
                    <div className="flex w-full max-w-[320px] flex-1 flex-col gap-1 xl:max-w-[400px]">
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={field.onChange} className="cursor-pointer" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="usage"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row flex-wrap items-baseline justify-start gap-2 space-y-0">
                    <FormLabel className="max-w-64 flex-1 font-primary text-base font-normal text-grey-600">
                      Usage
                    </FormLabel>
                    <div className="flex w-full max-w-[320px] flex-1 flex-col gap-1 xl:max-w-[400px]">
                      <FormControl>
                        <FormControl>
                          <Textarea
                            {...field}
                            isError={!!fieldState.error}
                            placeholder="Enter usage"
                            responsiveHeight
                            className="max-h-[150px] min-h-[120px] resize-none"
                          />
                        </FormControl>
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <DescriptionItem className="justify-start">
                <DescriptionTerm tag="p" className="max-w-64 flex-1">Last Modified By</DescriptionTerm>
                <DescriptionDetails tag="p" className="m-0 flex-1">
                  {activityType.last_modified_by
                    ? (
                        <Button asChild variant="link" size="link" className="text-base">
                          <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: activityType.last_modified_by.id } })}>
                            {activityType.last_modified_by?.full_name}
                          </Link>
                        </Button>
                      )
                    : (
                        'System'
                      )}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="justify-start">
                <DescriptionTerm tag="p" className="max-w-64 flex-1">Last Updated Date & Time</DescriptionTerm>
                <DescriptionDetails tag="p" className="m-0 flex-1">
                  {format(activityType.updated_at, 'MMMM dd, yyyy h:mm a')}
                </DescriptionDetails>
              </DescriptionItem>
              <FormField
                control={form.control}
                name="is_recurrence"
                render={({ field }) => (
                  <FormItem className="flex min-h-9 w-full flex-row flex-wrap items-center justify-start gap-2 space-y-0">
                    <FormLabel className="max-w-64 flex-1 font-primary text-base font-normal text-grey-600">
                      Recurrence
                    </FormLabel>
                    <div className="flex w-full max-w-[320px] flex-1 flex-col gap-1 xl:max-w-[400px]">
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={field.onChange} className="cursor-pointer" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <DescriptionItem className="justify-start">
                <DescriptionTerm tag="p" className="max-w-64 flex-1">Shared With</DescriptionTerm>
                <DescriptionDetails tag="p" className="m-0 flex-1">
                  All Districts
                </DescriptionDetails>
              </DescriptionItem>
            </Form>
          )
        : (
            <BasicDetailsInfo data={activityType} />
          )}
    </ActivityTypeTabContainer>
  );
};
