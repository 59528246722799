import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { createPriorityList } from './create-priority-list';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TCreatePriorityListPayload, TCreatePriorityListResponse } from './contacts.types';

export const useCreatePriorityList = (
  options?: TCustomUseMutationOptions<TCreatePriorityListResponse, AxiosError, TCreatePriorityListPayload>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [CONTACTS_QUERY_KEYS.CREATE_CONTACTS_PRIORITY_LIST],
    mutationFn: createPriorityList,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LISTS] });
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_STATISTICS] });
      showSuccessToast('System message', 'Priority list created successfully');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to create priority list');
      options?.onError?.(...args);
    },
  });
};
