import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { convertBooleanToText } from '@purple/shared-utils';
import { Button, Checkbox, Form, FormControl, FormField, FormItem, FormLabel, Text } from '@purple/ui';
import { type TAdminSubmitterTypeDetail, useUpdateSubmitterDetails } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { updateAnonymousSafSubmissionSchema } from './schema';
import type * as z from 'zod';

type TAnonymousSafSubmissionAreaProperties = {
  data: TAdminSubmitterTypeDetail;
};

const AnonymousSafSubmissionArea = ({ data }: TAnonymousSafSubmissionAreaProperties) => {
  const { can_submit_anonymously } = data;

  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSubmitterDetails();

  const defaultValues = useMemo(() => {
    return {
      can_submit_anonymously: can_submit_anonymously || false,
    };
  }, [can_submit_anonymously]);

  const form = useForm<z.infer<typeof updateAnonymousSafSubmissionSchema>>({
    resolver: zodResolver(updateAnonymousSafSubmissionSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const saveClickHandler = (newFormData: z.infer<typeof updateAnonymousSafSubmissionSchema>) => {
    const payload = {
      can_submit_anonymously: newFormData.can_submit_anonymously,
    };

    mutate({
      id: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
        showSuccessToast('System message', 'Submitter Detail was updated successfully');
      },
    });
  };

  return (
    <div className="flex flex-col gap-[22px] px-6 py-5">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-1">
          <Text variant="size-16" type="body-500" className="text-grey-title">Anonymous SAF submission</Text>
          <Text variant="size-16" type="body-400" className="text-grey-600">By default each submitter type on the SAF form is able to submit an anonymous SAF. You can change this setting below.</Text>
        </div>
        <div>
          {mode === 'view' && (
            <div className="flex flex-row gap-[12px]">
              <Button size="link" variant="link" iconLeft={<PurpleIcon name="pencil" />} onClick={() => setMode('edit')}>
                Edit
              </Button>
            </div>
          )}
          {mode === 'edit' && (
            <div className="flex flex-row gap-[12px]">
              <Button size="small" variant="secondary" onClick={() => setMode('view')}>
                Cancel
              </Button>
              <Button size="small" variant="primary" onClick={form.handleSubmit(saveClickHandler)} isLoading={isPending}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
      {mode === 'view' && (
        <div className="flex gap-6">
          <Text variant="size-16" type="body-500" className="text-grey-800">Can this submitter type submit anonymously?</Text>
          <Text variant="size-16" type="body-600" className="text-grey-950">{convertBooleanToText(can_submit_anonymously)}</Text>
        </div>
      )}
      {mode === 'edit' && (
        <Form providerProps={form} className="flex w-full flex-col gap-4">
          <div className="flex w-full gap-2">
            <FormField
              control={form.control}
              name="can_submit_anonymously"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value={field.value.toString()}
                      checked={field.value}
                      className="size-4"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="text-base font-medium text-grey-800">
                    Can this submitter type submit anonymously?
                  </FormLabel>
                </FormItem>
              )}
            />
          </div>
        </Form>
      )}
    </div>
  );
};

export { AnonymousSafSubmissionArea };
