import { useQuery } from '@tanstack/react-query';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { getActivityInstancesCount } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActivityInstancesCount } from '../activities.types';

export const useActivityInstancesCount = (
  queryOptions?: TCustomUseQueryOptions<TActivityInstancesCount, AxiosError>,
) => {
  const { isError, error, ...queryData } = useQuery({
    queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_INSTANCES_COUNT],
    queryFn: getActivityInstancesCount,
    ...queryOptions,
  });

  return { ...queryData, isError };
};
