import axiosInstance from '../axios-config';
import { CONTACTS_ENDPOINTS } from '../endpoints';
import type { TPriorityListContactsRequestParameters, TPriorityListContactsResponse } from './contacts.types';

export const getPriorityListContacts = async ({ id, ...parameters }: TPriorityListContactsRequestParameters) => {
  const { data } = await axiosInstance.get<TPriorityListContactsResponse>(CONTACTS_ENDPOINTS.PRIORITY_LIST_CONTACTS(id), {
    params: parameters,
  });

  return data;
};
