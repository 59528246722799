import { useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { DistrictUserStatus, type TUserOwner } from '@purple/shared-types';
import { getInitialsFromName } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, Text } from '@purple/ui';
import { useUsersList } from '~/services';

const DEFAULT_SEARCH_DELAY = 500;

type TOwnerEditProperties = {
  owner: TUserOwner | null;
  onChange: (owner: TUserOwner) => void;
  selectedDistrict: string | null;
};

const OwnerEdit = ({ owner, onChange, selectedDistrict }: TOwnerEditProperties) => {
  const [usersDebouncedSearchValue, setUsersDebouncedSearchValue] = useState<string>('');

  const usersDebounceSearch = useDebouncedCallback((searchQuery: string) => {
    setUsersDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const { data: users, isLoading: isUserLoading } = useUsersList({
    requestParameters: {
      search: usersDebouncedSearchValue,
      ...(selectedDistrict && { district: selectedDistrict }),
      status: DistrictUserStatus.ACTIVE,
    },
  });

  const usersSelectOptions = useMemo(() => {
    return (
      users?.results.map((user) => ({
        ...user,
        full_name: user.full_name ?? '',
        avatar: user.avatar ?? '',
        label: user.full_name ?? user.email,
        value: user.id,
      })) ?? []
    );
  }, [users]);

  const changeOwnerHandler = (value: string) => {
    const foundOwner = usersSelectOptions.find((option) => option.value === value);
    if (foundOwner) {
      onChange(foundOwner);
    }
  };

  return (
    <div className="min-w-[300px]">
      <ComboBox modal>
        <ComboBoxTrigger
          placeholder="Select owner"
          selectedLabel={usersSelectOptions.find((option) => option.value === owner?.id)?.label}
          className="h-8"
        />
        <ComboBoxContent
          loading={isUserLoading}
          shouldFilter={false}
          searchPlaceholder="Search by name"
          emptyContent="Owner not found."
          onSearchChange={usersDebounceSearch}
        >
          {usersSelectOptions.map(({ value, avatar, email, full_name, label }) => (
            <ComboBoxItem
              key={value}
              value={value}
              selected={value === owner?.id}
              onSelect={changeOwnerHandler}
            >
              <div className="flex items-center gap-3">
                <Avatar size={32}>
                  <AvatarImage src={avatar ?? undefined} />
                  <AvatarFallback className="bg-grey-200">
                    {getInitialsFromName(full_name ?? '—')}
                  </AvatarFallback>
                </Avatar>
                <div className="flex flex-col gap-1">
                  <Text variant="size-12" type="body-500" className="text-grey-950">
                    {label}
                  </Text>
                  <Text variant="size-12" type="body-400" className="text-grey-600">
                    {email}
                  </Text>
                </div>
              </div>
            </ComboBoxItem>
          ))}
        </ComboBoxContent>
      </ComboBox>
    </div>
  );
};

export { OwnerEdit };
