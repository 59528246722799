import type { ValueOf } from '@purple/shared-types';

export const SafTabsValues = {
  all: 'all',
  submitterTypes: 'submitter-types',
  needTypes: 'need-types',
  safRoutingRules: 'saf-routing-rules',
  safTransferProcessOwner: 'saf-transfer-process-owner',
  automatedEmail: 'automated-email',
  textTranslation: 'text-translation',
} as const;

export type TSafTabsValue = ValueOf<typeof SafTabsValues>;
