import { BulkFileErrorTableCell } from '~/components';
import { DataTableColumnHeader } from '~/components/DataTable';
import type { ColumnDef } from '@tanstack/react-table';
import type { TakeActionType } from '@purple/shared-types';
import type { TActionBulkUploadResponse } from '~/services';

export const reminderColumns: ColumnDef<TActionBulkUploadResponse<typeof TakeActionType.REMINDER>>[] = [
  {
    accessorKey: 'row',
    header: ({ column }) => <DataTableColumnHeader column={column} title="#" className="px-2" />,
    cell: ({ row }) => (
      <div className="px-2 py-3">
        <span>{row.original.row}</span>
      </div>
    ),
    size: 50,
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'reminder_type',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Reminder Type" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.reminder_type} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'Subject',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Subject" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.subject} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'notify_before',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Notify Before(Minutes)" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.notify_before} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'date_and_time',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date & Time" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.date_and_time} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'start_date_and_time',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Start Date & Time" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.start_date_and_time} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'end_date_and_time',
    header: ({ column }) => <DataTableColumnHeader column={column} title="End Date & Time" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.end_date_and_time} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'is_all_day',
    header: ({ column }) => <DataTableColumnHeader column={column} title="All Day" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.is_all_day} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'duration',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Duration(Minutes)" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.duration} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'days_of_week',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Days of week" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.days_of_week} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'weekly_interval',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Weekly Interval" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.weekly_interval} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'description',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Description" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.description} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'student',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Connected Students" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.student} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
];
