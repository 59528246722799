import { Link } from 'react-router-dom';
import { ReadableContactType } from '@purple/shared-types';
import { formatDate } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader, SelectableCell } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TContactItem } from '~/services';

export const contactsColumns: ColumnDef<TContactItem>[] = [
  SelectableCell(),
  {
    accessorKey: 'last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Link to={AdminRoutes.App.Contacts.Details.makePath({ dynamicParameters: { contactId: original.id } })} className="line-clamp-1 w-fit break-all text-brand-blue-700 transition-colors hover:text-brand-blue-800">
            {original.full_name ?? 'Unidentified Contact Name'}
          </Link>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent withArrow={false}>
            {original.full_name ?? 'Unidentified User Name'}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    meta: { label: 'Full Name' },
    size: 180,
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
    cell: ({ row: { original } }) => (
      original.email && original.email.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 w-fit break-all">{original.email}</span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {original.email}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : <span>—</span>
    ),
    meta: { label: 'Email' },
    size: 250,
  },
  {
    accessorKey: 'contact_type',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="line-clamp-1 w-fit break-all">{ReadableContactType[original.contact_type]}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent withArrow={false}>
            {ReadableContactType[original.contact_type]}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    meta: { label: 'Type' },
    size: 150,
  },
  {
    accessorKey: 'district',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="line-clamp-1 w-fit break-all">{original.district.name}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent withArrow={false}>
            {original.district.name}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
    cell: ({ row: { original } }) => (
      <span className="text-nowrap">{formatDate(original.created_at)}</span>
    ),
    meta: { label: 'Date Added' },
  },
];
