import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading } from '@purple/ui';
import type { FC } from 'react';
import type { PurpleIconType } from '@purple/icons';

type ContactSectionHeaderProps = {
  title?: string | null;
  editing?: boolean;
  loading?: boolean;
  editButtonLabel?: string;
  editButtonIcon?: PurpleIconType;
  disableEditing?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
};

export const ContactSectionHeader: FC<ContactSectionHeaderProps> = (props) => {
  const {
    title = null,
    editing = false,
    loading = false,
    disableEditing = false,
    editButtonLabel = 'Edit',
    editButtonIcon = 'pencil',
    onEdit,
    onCancel,
    onSave,
  } = props;

  const editingControls = useMemo(
    () =>
      editing
        ? (
            <div className="ml-auto flex flex-row items-center gap-3">
              <Button type="button" variant="secondary" size="small" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" variant="primary" size="small" isLoading={loading} onClick={onSave}>
                Save
              </Button>
            </div>
          )
        : (
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="ml-auto"
              iconLeft={<PurpleIcon name={editButtonIcon} className="size-4 shrink-0" />}
              onClick={onEdit}
            >
              {editButtonLabel}
            </Button>
          ),
    [editing, loading, onCancel, onSave, onEdit, editButtonIcon, editButtonLabel],
  );

  return (
    <div className="flex flex-row items-center justify-between gap-2">
      {title && (
        <Heading tag="h3" className="text-title text-base font-medium">
          {title}
        </Heading>
      )}
      {!disableEditing && editingControls}
    </div>
  );
};
