import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import {
  Button,
  cn,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Heading,
  NumberBadge,
} from '@purple/ui';
import { CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useActivityTypeDetails, useUpdateActivityType } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ActivityTypeInfoSkeleton } from './ActivityTypeInfoSkeleton';

export const ActivityTypeInfo: React.FC = () => {
  const { id } = useParams();

  const { openModal: openStatusModal, closeModal: closeStatusModal } = useModal(ModalType.ACTIVITY_TYPE_STATUS_CHANGE);

  const { data: activityType, isFetching } = useActivityTypeDetails(id as string);
  const { mutate: updateActivityType, isPending: isUpdatePending } = useUpdateActivityType();

  const updateActivityTypeStatusHandler = useCallback(() => {
    if (!activityType) {
      return showErrorToast('Activity type not found', 'Please select an activity type to update its status.');
    }

    updateActivityType({
      id: activityType.id,
      is_active: !activityType.is_active,
    }, {
      onSuccess: () => {
        closeStatusModal();
      },
    });
  }, [activityType, updateActivityType, closeStatusModal]);

  if (isFetching || !activityType) {
    return <ActivityTypeInfoSkeleton />;
  }

  return (
    <section className="flex w-full flex-wrap items-start justify-between gap-3 rounded-lg border border-grey-200 bg-white p-4 md:flex-nowrap">
      <div className="flex flex-col gap-2">
        <Heading tag="h1" variant="size-18" type="heading-600" className="text-base">
          {snakeToCamelWithSpaces(activityType.name)}
        </Heading>
        <DescriptionList className="flex-col items-start gap-6 lg:flex-row">
          <DescriptionItem className="w-auto flex-col items-start gap-1">
            <DescriptionTerm className="whitespace-nowrap text-sm">Status</DescriptionTerm>
            <DescriptionDetails className="m-0">
              <NumberBadge variant={activityType.is_active ? 'success-light' : 'danger'} className="rounded-full">
                {activityType.is_active ? 'Active' : 'Inactive'}
              </NumberBadge>
            </DescriptionDetails>
          </DescriptionItem>
        </DescriptionList>
      </div>
      <div className="flex items-center gap-4">
        <Button type="button" variant="primary" disabled>
          Open in Builder
        </Button>
        <DropdownRoot>
          <DropdownTrigger asChild>
            <Button
              type="button"
              variant="secondary"
              size="icon_40"
              className="size-10 shrink-0"
              iconLeft={<PurpleIcon name="dots-vertical" className="size-5 shrink-0" />}
            />
          </DropdownTrigger>
          <DropdownContent className="max-w-[150px] gap-1" align="end">
            <DropdownItem
              iconName={activityType.is_active ? 'X' : 'check'}
              className={cn(
                'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
                {
                  'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main': activityType.is_active,
                },
              )}
              onSelect={openStatusModal}
              onClick={(event) => event.stopPropagation()}
            >
              {activityType.is_active ? 'Deactivate' : 'Activate'}
            </DropdownItem>
          </DropdownContent>
        </DropdownRoot>
      </div>
      <CallToActionModal
        modalName={ModalType.ACTIVITY_TYPE_STATUS_CHANGE}
        modalTitle={activityType?.is_active ? `Deactivate ${activityType?.name}` : `Activate ${activityType?.name}`}
        modalDescription={`By clicking the button below, you will ${activityType?.is_active ? 'deactivate' : 'activate'} the activity type ${activityType?.name}.`}
        modalTextContent={`Are you sure you want to ${activityType?.is_active ? 'deactivate' : 'activate'} selected activity type?`}
        primaryButtonText={activityType?.is_active ? 'Deactivate' : 'Activate'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={updateActivityTypeStatusHandler}
        primaryButtonVariant={activityType?.is_active ? 'destructive_primary' : 'primary'}
        isLoading={isUpdatePending}
        onClose={closeStatusModal}
      />
    </section>
  );
};
