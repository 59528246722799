import { DayOfWeekSorter, formatBusinessHour } from '@purple/shared-utils';
import {
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { SchoolSectionHeader } from '../../SchoolSectionHeader';
import { BusinessHoursDialog } from './BusinessHoursDialog';
import type React from 'react';
import type { TSchoolBasicDetails } from '~/services';

type TBusinessHoursSectionProperties = {
  data: TSchoolBasicDetails;
};

export const BusinessHoursSection: React.FC<TBusinessHoursSectionProperties> = (props) => {
  const { data } = props;

  const { toggleModal } = useModal(ModalType.UPDATE_SCHOOL_BUSINESS_DAYS);

  const editClickHandler = () => {
    toggleModal(true);
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <SchoolSectionHeader
        title="Business Hours"
        onEdit={editClickHandler}
      />
      <DescriptionList className="gap-2">
        <DescriptionItem className="flex-nowrap items-start">
          <DescriptionTerm className="w-1/2 shrink-0">Business Hours</DescriptionTerm>
          <DescriptionDetails className="inline-flex w-1/2 flex-col gap-0.5">
            {data?.business_days && data?.business_days.length > 0
              ? data.business_days
                  .filter((day) => day.is_active)
                  .sort((a, b) => DayOfWeekSorter[a.day] - DayOfWeekSorter[b.day])
                  .map((day) => (
                    <span key={day.id} className="inline-block min-h-6">
                      {formatBusinessHour(day.day, day.start_time, day.end_time)}
                    </span>
                  ))
              : 'No business hours set'}
          </DescriptionDetails>
        </DescriptionItem>
      </DescriptionList>
      <BusinessHoursDialog data={data} />
    </div>
  );
};
