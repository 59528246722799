import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIVITY_TYPES_QUERY_KEYS } from '../activities.const';
import { updateActivityType } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActivityTypeItem, TActivityTypeUpdatePayload } from '../activities.types';

export const useUpdateActivityType = (
  options?: TCustomUseMutationOptions<TActivityTypeItem, AxiosError, TActivityTypeUpdatePayload>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [ACTIVITY_TYPES_QUERY_KEYS.UPDATE_ACTIVITY_TYPE],
    mutationFn: updateActivityType,
    onSuccess: (data, ...args) => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITY_TYPES_QUERY_KEYS.GET_ACTIVITY_TYPES] });
      queryClient.invalidateQueries({ queryKey: [ACTIVITY_TYPES_QUERY_KEYS.GET_ACTIVITY_TYPE_DETAILS, data.id.toString()] });
      showSuccessToast('System message', 'Activity type updated successfully');
      options?.onSuccess?.(data, ...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to update activity type');
      options?.onError?.(...args);
    },
  });
};
