import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TAdminUsersManagementRequestParameters } from './user.types';

export const getExportUsers = async (params?: TAdminUsersManagementRequestParameters) => {
  const { data } = await axiosInstance.get<Blob>(
    ADMIN_USER_MANAGEMENT.EXPORT,
    {
      params,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
  return data;
};
