import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import { countryOptions, usStates } from '~/components/Modals/Contacts/AddContactModal/constants';
import { CONTACTS_QUERY_KEYS } from '~/services';
import type { FC } from 'react';
import type { TContactDetailItem } from '~/services';

export const AddressSectionContent: FC = () => {
  const { contactId } = useParams();
  const queryClient = useQueryClient();

  const contactDetails = queryClient.getQueryData<TContactDetailItem>([CONTACTS_QUERY_KEYS.GET_CONTACT_DETAILS, contactId]);
  return (
    <DescriptionList className="gap-2">
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">Country</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {countryOptions.find((country) => country.value === contactDetails?.address?.country)?.label ?? '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">State</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {usStates.find((state) => state.value === contactDetails?.address?.state)?.label ?? '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">City</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {contactDetails?.address?.city ?? '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">Street</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {contactDetails?.address?.street ?? '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">ZIP/Postal Code</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {contactDetails?.address?.postal_code ?? '—'}
        </DescriptionDetails>
      </DescriptionItem>
    </DescriptionList>
  );
};
