import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { batchRemoveSharingUsers } from './batch-remove-sharing-users';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TPriorityListBatchManageSharingPayload } from './contacts.types';

export const usePriorityListBatchRemoveSharing = (
  options?: TCustomUseMutationOptions<unknown, AxiosError, TPriorityListBatchManageSharingPayload>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [CONTACTS_QUERY_KEYS.PRIORITY_LIST_BATCH_REMOVE_SHARING_USERS],
    mutationFn: batchRemoveSharingUsers,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LISTS] });
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LIST_CONTACTS] });
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LIST_BY_ID, args[1].id] });
      showSuccessToast('System message', 'Users removed from priority list sharing');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to remove users from priority list sharing');
      options?.onError?.(...args);
    },
  });
};
