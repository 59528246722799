import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TAdminUsersNotLoggedInReportParameters, TAdminUsersNotLoggedInReportResponse } from './user.types';

export const getNotLoggedInUsersReport = async (parameters: TAdminUsersNotLoggedInReportParameters) => {
  const response = await axiosInstance.get<TAdminUsersNotLoggedInReportResponse>(ADMIN_USER_MANAGEMENT.NOT_LOGGED_IN_REPORT, {
    params: parameters,
  });
  return response.data;
};
