import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useKeyPress } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  ColorPicker,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import type { z } from 'zod';
import type { tagsSchema } from '@purple/shared-utils';

type TTagFormItemProps = {
  fieldIndex: number;
  onDelete?: (id: string) => void;
};

export const TagFormItem: React.FC<TTagFormItemProps> = (props) => {
  const { fieldIndex, onDelete } = props;

  const ctx = useFormContext<z.infer<typeof tagsSchema>>();
  const name = ctx.watch(`tags.${fieldIndex}.name`);
  const color = ctx.watch(`tags.${fieldIndex}.color`);
  const id = ctx.watch(`tags.${fieldIndex}.id`);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [savedName, setSavedName] = useState<string>(name);

  const editClickHandler = () => {
    setSavedName(name);
    ctx.trigger(`tags.${fieldIndex}.name`);
    setIsEditing(true);
  };

  const saveClickHandler = async () => {
    const isValid = await ctx.trigger(`tags.${fieldIndex}.name`);
    if (!isValid) return;
    setIsEditing(false);
  };

  const applyColorHandler = (color: string) => {
    ctx.setValue(`tags.${fieldIndex}.color`, color);
    setPopoverOpen(false);
  };

  const cancelClickHandler = () => {
    if (savedName !== name) {
      ctx.setValue(`tags.${fieldIndex}.name`, savedName);
    }
    setIsEditing(false);
  };

  useKeyPress('Escape', cancelClickHandler);
  useKeyPress('Enter', saveClickHandler);

  return (
    <div className="grid min-h-10 w-full grid-cols-6 items-center gap-4">
      <FormField
        control={ctx.control}
        name={`tags.${fieldIndex}.name`}
        render={({ field, fieldState }) => (
          <FormItem className="col-span-3 flex flex-col items-start px-3">
            <HoverCard open={isEditing}>
              <HoverCardTrigger asChild>
                <span className="flex w-fit">
                  {isEditing
                    ? (
                        <FormControl>
                          <Input
                            {...field}
                            type="text"
                            isError={!!fieldState.error}
                            placeholder="Enter name"
                            className="w-full"
                          />
                        </FormControl>
                      )
                    : (
                        <Text tag="span" type="body-500" variant="size-14">
                          {name}
                        </Text>
                      )}
                </span>
              </HoverCardTrigger>
              <HoverCardContent
                align="end"
                side={fieldState.error ? 'top' : 'bottom'}
                sideOffset={fieldState.error ? 8 : 0}
                className="flex w-auto gap-1 border-none bg-transparent p-0 shadow-none"
              >
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      type="button"
                      variant="primary"
                      size="icon_32"
                      className="shadow-custom-medium"
                      disabled={!!fieldState.error}
                      iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                      onClick={saveClickHandler}
                    />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>Save</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      type="button"
                      variant="secondary"
                      size="icon_32"
                      className="border-0 shadow-custom-medium"
                      iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                      onClick={cancelClickHandler}
                    />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>Cancel</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </HoverCardContent>
            </HoverCard>
            <FormMessage />
          </FormItem>
        )}
      />
      {isEditing
        ? (
            <FormField
              control={ctx.control}
              name={`tags.${fieldIndex}.color`}
              render={({ field }) => (
                <FormItem className="col-span-1 flex flex-col items-center justify-center">
                  <Popover open={isPopoverOpen} onOpenChange={setPopoverOpen}>
                    <Tooltip>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <TooltipTrigger asChild>
                            <button
                              type="button"
                              className="flex aspect-square size-6 items-center justify-center rounded outline-none ring-0 ring-grey-500 ring-offset-2 transition-all hover:ring-1 focus:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50"
                              style={{ background: field.value }}
                            />
                          </TooltipTrigger>
                        </FormControl>
                      </PopoverTrigger>
                      <TooltipPortal>
                        <TooltipContent>Choose color</TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                    <PopoverContent align="start" side="right" className="border-none p-0">
                      <ColorPicker
                        allowEyeDropper
                        value={field.value}
                        onClose={() => setPopoverOpen((prev) => !prev)}
                        onApply={applyColorHandler}
                        onEyeDropperPick={applyColorHandler}
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          )
        : (
            <span className="col-span-1 size-6 shrink-0 place-self-center rounded text-center" style={{ backgroundColor: color }} />
          )}
      {isEditing
        ? null
        : (
            <div className="col-span-2 flex shrink-0 items-center justify-end gap-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="tertiary"
                    size="icon_32"
                    iconLeft={<PurpleIcon name="pencil" />}
                    onClick={editClickHandler}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Edit</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="destructive_secondary"
                    size="icon_32"
                    className="border-0"
                    iconLeft={<PurpleIcon name="trash" />}
                    onClick={() => onDelete?.(id)}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Delete</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </div>
          )}
    </div>
  );
};
