import axiosInstance from '../axios-config';
import { ACTIONS_ENDPOINTS } from '../endpoints';
import type { TakeActionType, TBulkUploadResponse } from '@purple/shared-types';
import type { TActionBulkUploadResponse, TActionReminderUploadPayload } from './actions.types';

export const createBulkReminders = async (payload: TActionReminderUploadPayload) => {
  const formData = new FormData();
  formData.append('district', payload.district);
  formData.append('uploaded_file', payload.uploaded_file);
  const response = await axiosInstance.post<TBulkUploadResponse<TActionBulkUploadResponse<typeof TakeActionType.REMINDER>>>(ACTIONS_ENDPOINTS.IMPORT_BULK_ACTION_REMINDERS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
