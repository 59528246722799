import { Fragment } from 'react';
import { convertBooleanToText } from '@purple/shared-utils';
import { Separator, Text } from '@purple/ui';
import { SAF_FIELD_SETTINGS_HEADING } from '~/pages/Main/Saf/constants';
import type { TSafLanguages } from '~/pages/Main/Saf/constants';
import type { TAdminSubmitterTypeFieldView } from '~/services';

type TFieldSettingsTableViewProperties = {
  data: TAdminSubmitterTypeFieldView;
  selectedLanguage: TSafLanguages;
};

const FieldSettingsTableView = ({ data, selectedLanguage }: TFieldSettingsTableViewProperties) => {
  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-3 items-center px-[20px] py-4">
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">FIELD NAME</Text>
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">TRANSLATION</Text>
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">REQUIRED</Text>
      </div>
      <Separator className="bg-grey-200" />
      <div className="px-[20px] py-3">
        <Text variant="size-14" type="body-700" className="text-grey-950">My Information</Text>
      </div>
      {data?.[SAF_FIELD_SETTINGS_HEADING.my_information]?.map((field) => (
        <Fragment key={field.id}>
          <Separator className="bg-grey-200" />
          <div className="grid grid-cols-3 items-center px-[20px] py-4">
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.field_label}</Text>
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.translations[selectedLanguage] || '-'}</Text>
            <Text variant="size-14" type="body-500" className="text-grey-950">{convertBooleanToText(field.is_required)}</Text>
          </div>
        </Fragment>
      ))}
      <Separator className="bg-grey-200" />
      <div className="px-[20px] py-3">
        <Text variant="size-14" type="body-700" className="text-grey-950">Student Information</Text>
      </div>
      {data?.[SAF_FIELD_SETTINGS_HEADING.student_information]?.map((field) => (
        <Fragment key={field.id}>
          <Separator className="bg-grey-200" />
          <div className="grid grid-cols-3 items-center px-[20px] py-4">
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.field_label}</Text>
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.translations[selectedLanguage] || '-'}</Text>
            <Text variant="size-14" type="body-500" className="text-grey-950">{convertBooleanToText(field.is_required)}</Text>
          </div>
        </Fragment>
      ))}
      <Separator className="bg-grey-200" />
      <div className="px-[20px] py-3">
        <Text variant="size-14" type="body-700" className="text-grey-950">Needs Information</Text>
      </div>
      {data?.[SAF_FIELD_SETTINGS_HEADING.needs_information]?.map((field) => (
        <Fragment key={field.id}>
          <Separator className="bg-grey-200" />
          <div className="grid grid-cols-3 items-center px-[20px] py-4">
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.field_label}</Text>
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.translations[selectedLanguage] || '-'}</Text>
            <Text variant="size-14" type="body-500" className="text-grey-950">{convertBooleanToText(field.is_required)}</Text>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export { FieldSettingsTableView };
