import { useCallback, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { ActionNotePreviewModal, DataTable } from '~/components';
import { ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useNotesList } from '~/services';
import { ActionTabContainer } from '../components';
import { actionNotesColumns } from './actionNotesColumns';
import type { TNote } from '@purple/shared-types';

export const Notes = () => {
  const [searchParameters] = useSearchParams();
  const { actionId } = useParams<{ actionId: string }>();

  const { openModal } = useModal(ModalType.ACTION_NOTE_PREVIEW);

  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);

  const { data: notesList, isLoading } = useNotesList({
    parameters: {
      action: actionId,
      limit: searchParameters.get(LIMIT_QUERY_NAME) || undefined,
      offset: searchParameters.get(OFFSET_QUERY_NAME) || undefined,
      ordering: searchParameters.get(SORT_QUERY_NAME) || undefined,
    },
  });

  const { table } = useDataTable({
    name: TableName.ACTION_NOTES,
    columns: actionNotesColumns,
    data: notesList?.results || [],
    rowCount: notesList?.count || 0,
  });

  const noteRowClickHandler = useCallback((note: TNote) => {
    setSelectedNoteId(note.id);
    openModal();
  }, [openModal]);

  return (
    <>
      <ActionTabContainer title="Notes" className="p-0">
        <DataTable table={table} loading={isLoading} onRowClick={noteRowClickHandler} />
      </ActionTabContainer>
      <ActionNotePreviewModal noteId={selectedNoteId} changeNoteId={setSelectedNoteId} />
    </>
  );
};
