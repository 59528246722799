import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import type { TImpersonateUserResource } from '~/services';

const DEFAULT_OPTIONS: TImpersonateUserResource[] = [];

type TImpersonatePlatformChooserDialogProps = {
  purpleSenseOptions?: TImpersonateUserResource[];
  serviceProviderOptions?: TImpersonateUserResource[];
  onPlatformSelect?: (platform: TImpersonateUserResource) => void;
};

export const ImpersonatePlatformChooserDialog: React.FC<TImpersonatePlatformChooserDialogProps> = (props) => {
  const { purpleSenseOptions = DEFAULT_OPTIONS, serviceProviderOptions = DEFAULT_OPTIONS, onPlatformSelect } = props;

  const { isOpen, toggleModal } = useModal(ModalType.IMPERSONATE_PLATFORM_CHOOSER);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle className="leading-7 tracking-normal">Log In as</DialogTitle>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <DialogDescription asChild className="flex p-6 pb-10">
          <Command className="outline-none">
            <CommandList className="max-h-max rounded-lg border border-grey-200 p-4 pb-2">
              {purpleSenseOptions.length > 0 && (
                <CommandGroup heading="Purple Sense">
                  {purpleSenseOptions.map((option) => (
                    <CommandItem
                      key={option.subdomain}
                      value={option.subdomain}
                      onSelect={() => onPlatformSelect?.(option)}
                      className="cursor-pointer px-3 py-2 text-base text-grey-950"
                    >
                      <PurpleIcon name="disctrict" className="mr-2 size-5 shrink-0 text-grey-600" />
                      <span className="line-clamp-1 break-all">{option.name}</span>
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}
              {serviceProviderOptions.length > 0 && (
                <>
                  <CommandSeparator className="mx-auto my-4 w-[calc(100%-32px)]" />
                  <CommandGroup heading="Service Provider">
                    {serviceProviderOptions.map((option) => (
                      <CommandItem
                        key={option.subdomain}
                        value={option.subdomain}
                        onSelect={() => onPlatformSelect?.(option)}
                        className="cursor-pointer px-3 py-2 text-base text-grey-950"
                      >
                        <PurpleIcon name="Program" className="mr-2 size-5 shrink-0 text-grey-600" />
                        <span className="line-clamp-1 break-all">{option.name}</span>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};
