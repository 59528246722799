import axiosInstance from '../axios-config';
import { ACTIONS_ENDPOINTS } from '../endpoints';
import type { TActionDocumentAsCountStats, TActionDocumentAsCountStatsParameters } from './actions.types';

export const getActionsCount = async (parameters?: TActionDocumentAsCountStatsParameters) => {
  const response = await axiosInstance.get<TActionDocumentAsCountStats>(ACTIONS_ENDPOINTS.DOCUMENT_AS_COUNT, {
    params: parameters,
  });
  return response.data;
};
