import { useParams } from 'react-router-dom';
import { Separator } from '@purple/ui';
import { useActivityDetails } from '~/services';
import { ActivityTabContainer } from '../../components';
import { BasicDetailsSkeleton } from './BasicDetailsSkeleton';
import { DateTimeSection } from './DateTimeSection';
import { DescriptionSection } from './DescriptionSection';
import { DetailsSection } from './DetailsSection';
import { TagsSection } from './TagsSection';

export const BasicDetailsTab: React.FC = () => {
  const { id: activityId } = useParams();

  const { data: activityDetails, isFetching } = useActivityDetails(activityId as string);

  if (isFetching || !activityDetails) {
    return <BasicDetailsSkeleton />;
  }

  return (
    <ActivityTabContainer title="Basic Details" className="gap-6 lg:flex-row">
      <DetailsSection data={activityDetails} />
      <Separator orientation="vertical" className="h-px w-full bg-grey-200 lg:h-auto lg:w-px" />
      <div className="flex w-full flex-col gap-4">
        {(activityDetails.details.recurring_group || activityDetails.details.date_and_time) && (
          <>
            <DateTimeSection data={activityDetails} />
            <Separator orientation="horizontal" className="bg-grey-200" />
          </>
        )}
        <DescriptionSection data={activityDetails} />
        <Separator orientation="horizontal" className="bg-grey-200" />
        <TagsSection data={activityDetails} />
      </div>
    </ActivityTabContainer>
  );
};
