import * as z from 'zod';
import { multiSelectOptions, onlyCapitalLetters } from '@purple/shared-utils';
import { ASSIGN_OPTIONS_VALUES } from './new-rule.constants';

export const addNewRuleSchema = z
  .object({
    district: z.string().trim().min(1, { message: 'This field is required' }),
    schools: multiSelectOptions.min(1, { message: 'Please select at least one school' }),
    need: z.string().trim().min(1, { message: 'Please select a SAF type' }),
    grades: z.array(z.string()).optional(),
    student_last_name_ranges: z
      .array(
        z.object({
          start: z
            .string()
            .trim()
            .min(1, { message: 'This field is required' })
            .max(3, { message: 'Maximum 3 characters' })
            .refine((value) => onlyCapitalLetters.test(value), {
              message: 'Only capital letters are allowed',
            }),
          end: z
            .string()
            .trim()
            .min(1, { message: 'This field is required' })
            .max(3, { message: 'Maximum 3 characters' })
            .refine((value) => onlyCapitalLetters.test(value), {
              message: 'Only capital letters are allowed',
            }),
        }),
      )
      .min(1, { message: 'At least one pair should be present' }),
    assign_to: z.nativeEnum(ASSIGN_OPTIONS_VALUES),
    assigned_user: z.string().trim(),
    assigned_role: z.string().trim(),
  })
  .superRefine((data, context) => {
    if (data.assign_to === ASSIGN_OPTIONS_VALUES.USER && data.assigned_user.length === 0) {
      context.addIssue({
        path: ['assigned_user'],
        code: z.ZodIssueCode.custom,
        message: 'Please select a user',
      });
    }
    if (data.assign_to === ASSIGN_OPTIONS_VALUES.ROLE && data.assigned_role.length === 0) {
      context.addIssue({
        path: ['assigned_role'],
        code: z.ZodIssueCode.custom,
        message: 'Please select a role',
      });
    }
  });
