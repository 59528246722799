import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { DISTRICT_STATUS, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Button, SearchInput, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import {
  CallToActionModal,
  DataTable,
  DataTableSelectedController,
  DataTableViewOptions,
  DistrictFilterComboBox,
  TransferSafModal,
} from '~/components';
import { ModalType, SAFS_PRIORITY_OPTIONS, SAFS_STATUS_OPTIONS, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { PRINT_OPTIONS } from '~/pages/System';
import { PRINT_QUERY_KEYS, type TAdminSafItem, usePrint, useSafBulkDelete, useSafListExportMutation, useSafListMutation, useSafsList, useSafsListFilters } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { DISTRICT_QUERY_NAME, OWNER_QUERY_NAME, PRIORITY_QUERY_NAME, SAFS_TOTAL_QUERY_NAME, SCHOOL_QUERY_NAME, STATUS_QUERY_NAME } from './constants';
import { useAllSafsColumns } from './useAllSafsColumns';

export const AllSafs = () => {
  const [searchParameters] = useSearchParams();

  const [clickableSaf, setClickableSaf] = useState<TAdminSafItem | null>(null);

  const { openModal: openDeleteSafModal, closeModal: closeDeleteSafModal } = useModal(
    ModalType.SAF_DELETE,
  );

  const { openModal: openDeleteSafBulkModal, closeModal: closeDeleteSafBulkModal } = useModal(
    ModalType.SAF_DELETE_BULK,
  );

  const { openModal: openTransferSafModal } = useModal(
    ModalType.TRANSFER_SAF,
  );

  const { mutate: bulkDelete, isPending: isSafDeleting } = useSafBulkDelete();
  const { mutate: exportSafs, isPending: isExportingSafs } = useSafListExportMutation();
  const { mutate: printSafList, isPending: isPrintPending } = usePrint(PRINT_QUERY_KEYS.ADMIN_SAF_LIST);

  const { query: priorityQuery } = useQueryParameter({ queryName: PRIORITY_QUERY_NAME, resetOffset: true });
  const { query: statusQuery } = useQueryParameter({ queryName: STATUS_QUERY_NAME, resetOffset: true });
  const { query: districtQuery, onQueryChange: onDistrictQueryChange, onClearQuery: onDistrictQueryClear } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });
  const { onQueryChange: onSafsTotalQueryChange, onClearQuery: onSafsTotalQueryClear } = useQueryParameter({ queryName: SAFS_TOTAL_QUERY_NAME, resetOffset: false });
  const { query: schoolQuery } = useQueryParameter({ queryName: SCHOOL_QUERY_NAME, resetOffset: true });
  const { query: ownerQuery } = useQueryParameter({ queryName: OWNER_QUERY_NAME, resetOffset: true });

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const requestParameters = useMemo(() => ({
    ...(priorityQuery && { priority: priorityQuery }),
    ...(statusQuery && { status: statusQuery }),
    ...(districtQuery && { district: districtQuery }),
    ...(schoolQuery && { school: schoolQuery }),
    ...(ownerQuery && { current_owner: ownerQuery }),
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
  }), [debounceSearch, districtQuery, ownerQuery, priorityQuery, schoolQuery, searchParameters, statusQuery]);

  const { data: safsData, isLoading: isSafsLoading } = useSafsList({ requestParameters });

  const safs = useMemo(() => safsData?.results ?? [], [safsData?.results]);

  const { data: safFilters, isLoading: isSafFiltersLoading } = useSafsListFilters();

  useEffect(() => {
    if (safsData) {
      onSafsTotalQueryChange(safsData.count.toString());
    } else {
      onSafsTotalQueryClear();
    }
  }, [onSafsTotalQueryChange, onSafsTotalQueryClear, safsData]);

  const { mutate: getAllSafs, isPending: isGetAllSafsPending } = useSafListMutation();

  // TODO: It will be implemented in the future sprints
  const singleTransferClickHandler = (item: TAdminSafItem) => {
    console.log('Transfer SAF', item); // eslint-disable-line no-console
  };

  const singleDeleteClickHandler = (item: TAdminSafItem) => {
    setClickableSaf(item);
    openDeleteSafModal();
  };

  const confirmDeleteSafHandler = () => {
    if (clickableSaf) {
      bulkDelete({ safIds: [clickableSaf.id] }, {
        onSuccess: () => {
          closeDeleteSafModal();
        },
      });
    }
  };

  const { table } = useDataTable({
    name: TableName.SAFS,
    columns: useAllSafsColumns({
      onDelete: singleDeleteClickHandler,
      onTransfer: singleTransferClickHandler,
    }),
    data: safs,
    rowCount: safsData?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
      },
    },
    getRowId: (originalRow) => originalRow.id,
    onSelectionChange: (rows, setSelectedRows) => {
      const selectedIds = Object.entries(rows).flatMap(([id, selected]) => (selected ? [id] : []));
      setSelectedRows(
        (prevRows) => [
          ...prevRows,
          ...safs.map(({ id, saf_number, district }) => ({ id, name: saf_number, district })),
        ]
          .filter((activity, index, self) => index === self.findIndex((a) => a.id === activity.id))
          .filter((activity) => selectedIds.includes(activity.id)),
      );
    },
  });

  const confirmBulkDeleteSafHandler = () => {
    const selectedRows = table.options.meta?.selectedRows ?? [];
    const safIds = selectedRows.map((row) => row.original.id);
    if (safIds.length) {
      bulkDelete({ safIds }, {
        onSuccess: () => {
          closeDeleteSafBulkModal();
          table.options.meta?.clearSelectedRows?.();
        },
      });
    }
  };

  const filterConfig = useMemo(
    () => {
      const fetchedFilters = safFilters
        ? {
            [SCHOOL_QUERY_NAME]: safFilters.school.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
            [OWNER_QUERY_NAME]: safFilters.current_owner.map(({ id, full_name }) => ({
              label: full_name,
              value: id,
            })),
          }
        : null;

      const filters = {
        [PRIORITY_QUERY_NAME]: Object.values(SAFS_PRIORITY_OPTIONS).map(({ label, value }) => ({
          label,
          value,
        })),
        [STATUS_QUERY_NAME]: Object.values(SAFS_STATUS_OPTIONS).map(({ label, value }) => ({
          label,
          value,
        })),
        ...(Boolean(fetchedFilters) && { ...fetchedFilters }),
      };

      return convertToFilterConfig(filters, { snakeCaseValue: false });
    },
    [safFilters],
  );

  const selectAllFoundHandler = () => {
    getAllSafs({
      ...(priorityQuery && { priority: priorityQuery }),
      ...(statusQuery && { status: statusQuery }),
      ...(districtQuery && { district: districtQuery }),
      search: debounceSearch,
      limit: safsData?.count || 0,
      offset: 0,
      ordering: searchParameters.get(SORT_QUERY_NAME),
    }, {
      onSuccess: (allSafsResponse) => {
        const selectedRows = allSafsResponse.results.reduce<Record<string, boolean>>((acc, item) => ({
          ...acc,
          [item.id]: true,
        }), {}) ?? {};
        table.setRowSelection(selectedRows);
        table.options.meta?.setSelectedRows?.(allSafsResponse.results.map((saf) => ({ id: saf.id, name: saf.saf_number, district: saf.district })) ?? []);
      },
    });
  };

  const [canTransferSaf, transferSafTooltipMessage] = useMemo(() => {
    const selectedItems = table.options.meta?.selectedRows ?? [];
    if (!selectedItems.length) {
      return [false, 'Please select at least one SAF to transfer.'];
    }

    const firstSelectedItem = selectedItems[0];
    const isSameDistrict = selectedItems.every((item) => item.district.id === firstSelectedItem?.district.id);

    if (!isSameDistrict) {
      return [false, 'You can transfer SAFs only within the same district.'];
    }

    return [true, 'Click to transfer selected SAFs.'];
  }, [table.options.meta?.selectedRows]);

  const exportClickHandler = () => {
    exportSafs({
      priority: searchParameters.get(PRIORITY_QUERY_NAME),
      status: searchParameters.get(STATUS_QUERY_NAME),
      district: searchParameters.get(DISTRICT_QUERY_NAME),
      search: searchParameters.get(SEARCH_QUERY_NAME),
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
    }, {
      onSuccess: () => {
        showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
      },
    });
  };

  const printClickHandler = () => {
    printSafList({
      variant: PRINT_OPTIONS.SAF_LIST,
      scale: 1,
      landscape: 'true',
      ...requestParameters,
    });
  };

  return (
    <div className="flex flex-col">
      <DataTable
        table={table}
        loading={isSafsLoading}
      >
        <div className="flex flex-col gap-4 p-4">
          <div className="flex w-full items-center justify-between gap-4">
            <div className="flex items-center gap-4">
              <div className="min-w-[200px]">
                <DistrictFilterComboBox value={districtQuery || ''} onChange={onDistrictQueryChange} onClear={onDistrictQueryClear} status={DISTRICT_STATUS.PUBLISHED} />
              </div>
              <AppFilters config={filterConfig} loading={isSafFiltersLoading} />
              <SearchInput
                value={search}
                placeholder="Search"
                className="max-w-[300px]"
                onChange={onSearchChange}
                onClear={onClearSearch}
              />
            </div>
            <div className="flex flex-row gap-4">
              <Button
                type="button"
                variant="destructive_secondary"
                size="default"
                disabled={table.options.meta?.selectedRows?.length === 0}
                onClick={openDeleteSafBulkModal}
              >
                Delete
              </Button>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div>
                    <Button
                      type="button"
                      variant="secondary"
                      size="default"
                      disabled={!canTransferSaf}
                      onClick={openTransferSafModal}
                    >
                      Transfer SAF
                    </Button>
                  </div>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>{transferSafTooltipMessage}</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="secondary"
                    size="icon_40"
                    className="size-10 shrink-0"
                    iconLeft={<PurpleIcon name="download" />}
                    disabled={safs.length === 0}
                    isLoading={isExportingSafs}
                    onClick={exportClickHandler}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Export Table to Excel</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="secondary"
                    size="icon_40"
                    className="size-10 shrink-0"
                    isLoading={isPrintPending}
                    onClick={printClickHandler}
                    iconLeft={<PurpleIcon name="printer" />}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Print Table</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <DataTableViewOptions table={table} />
            </div>
          </div>
          <DataTableSelectedController table={table} isSelectAllLoading={isGetAllSafsPending} onSelectAll={selectAllFoundHandler} />
        </div>
      </DataTable>
      <CallToActionModal
        modalName={ModalType.SAF_DELETE}
        modalTitle="Delete SAF"
        modalDescription="Are you sure you want to delete the selected SAF?"
        modalTextContent="Are you sure you want to delete the selected SAF?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={confirmDeleteSafHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isSafDeleting}
      />
      <CallToActionModal
        modalName={ModalType.SAF_DELETE_BULK}
        modalTitle="Delete SAFs"
        modalDescription="Are you sure you want to delete the selected SAFs?"
        modalTextContent="Are you sure you want to delete the selected SAFs?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={confirmBulkDeleteSafHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isSafDeleting}
      />
      <TransferSafModal safIds={table.options.meta?.selectedRows?.map(({ id }) => id) ?? []} />
    </div>
  );
};
