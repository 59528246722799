import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { activateSchool } from './activate-school';
import { SCHOOLS_QUERY_KEYS } from './schools.const';

export const useActivateSchool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [SCHOOLS_QUERY_KEYS.ACTIVATE_SCHOOL],
    mutationFn: activateSchool,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_ALL_SCHOOLS_LIST] });
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_BY_ID] });
      showSuccessToast('System message', 'School was activated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to activate school. Please try again later');
    },
  });
};
