import { useCallback, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { Button, Checkbox, Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, ScrollArea, Separator, Text, Textarea } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useAddInvitePurpleUser } from '~/services/user/useAddInvitePurpleUser';
import { AccessForm } from './AccessForm';
import { GeneralInformationForm } from './GeneralInformationForm';
import { addInvitePurpleUserSchema } from './schema';
import type { TAddInvitePurpleUserFormValues } from './schema';

export const AddInvitePurpleUserModal = () => {
  const formId = useId();

  const { isOpen, closeModal } = useModal(ModalType.ADD_INVITE_PURPLE_USER);

  const defaultValues: TAddInvitePurpleUserFormValues = useMemo(() => ({
    email: '',
    district: '',
    full_name: '',
    phone_number: '',
    department: '',
    title: '',
    responsible_for_grades: [],
    schools: [],
    description: '',
    is_show_on_scorecard: false,
    prounitas_roles: [],
    is_district_crisis_representative: false,
    is_school_crisis_representative: false,
  }), []);

  const { mutate: invitePurpleUser, isPending: isInviting } = useAddInvitePurpleUser();

  const form = useForm<TAddInvitePurpleUserFormValues>({
    mode: 'onChange',
    resolver: zodResolver(addInvitePurpleUserSchema),
    defaultValues,
  });

  const closeModalHandler = useCallback(() => {
    form.reset(defaultValues);
    closeModal();
  }, [form, defaultValues, closeModal]);

  const invitePurpleUserHandler = useCallback((fromData: TAddInvitePurpleUserFormValues) => {
    invitePurpleUser({
      ...fromData,
      schools: fromData.schools.map(({ value }) => value),
    }, {
      onSuccess: () => {
        closeModalHandler();
      },
      onError: (error) => {
        if (error.response?.data?.email) {
          form.setError('email', { message: error.response.data.email[0] });
        }
      },
    });
  }, [invitePurpleUser, closeModalHandler, form]);

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-full max-w-[840px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className="leading-5">Add & Invite User to Purple Sense</DialogTitle>
            <DialogDescription className="sr-only">Modal window to add or invite user to Purple Sense application</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea
          type="auto"
          className="flex size-full max-h-[640px] min-h-[160px] flex-col p-6"
          scrollBarClassName="p-2 w-[22px]"
        >
          <Form providerProps={form} id={formId} className="flex flex-col gap-6" onSubmit={form.handleSubmit(invitePurpleUserHandler)}>
            <GeneralInformationForm />
            <AccessForm />
            <FormField
              control={form.control}
              name="description"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel required>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      isError={!!fieldState.error}
                      placeholder="Type additional information about user"
                      responsiveHeight
                      className="max-h-[100px] min-h-[80px] resize-none"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_show_on_scorecard"
              render={({ field }) => (
                <FormItem className="flex items-start gap-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value={field.value?.toString()}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="size-4"
                    />
                  </FormControl>
                  <div className="flex flex-col">
                    <FormLabel className="text-grey-title text-sm font-medium">Show on Scorecard</FormLabel>
                    <Text variant="size-14" className="text-gray-600">Select to include this information on the scorecard for easy reference and tracking.</Text>
                  </div>
                </FormItem>
              )}
            />
          </Form>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModalHandler}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" form={formId} isLoading={isInviting}>
            {isInviting ? 'Inviting...' : 'Add & Invite'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
