import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useDebounceValue } from 'usehooks-ts';
import { useQueryParameter } from '@purple/hooks';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Heading, Input, MultiSelect, MultiSelectItem, TabsContent, Textarea } from '@purple/ui';
import { PERMISSIONS_QUERY_KEYS, useDistrictsListBasicInfo } from '~/services';
import { ROLE_PARAMETERS_KEY } from '../../constants';
import { useManageRolesTabs } from '../../useManageRolesTabs';
import type { TPermissionStructure } from '~/services';
import type { TFormValues } from '../../manageRolesSchema';

const DEFAULT_SEARCH_DELAY = 500;

export const ManageRolesFormContent = () => {
  const { control, watch } = useFormContext<TFormValues>();
  const queryClient = useQueryClient();

  const { query: roleId } = useQueryParameter({ queryName: ROLE_PARAMETERS_KEY.roleId });

  const permissionStructure = queryClient.getQueryData<TPermissionStructure>([PERMISSIONS_QUERY_KEYS.GET_PERMISSIONS_STRUCTURE]);

  const [districtsSearch, setDistrictsSearch] = useState<string>('');
  const [debouncedDistrictsSearch] = useDebounceValue(districtsSearch, DEFAULT_SEARCH_DELAY);

  const { tabs } = useManageRolesTabs({ permissionStructure });

  const selectedDistrict = watch('districts');

  const { data: districtList, isLoading: isDistrictsLoading } = useDistrictsListBasicInfo({
    search: debouncedDistrictsSearch,
    enabled: true,
    status: DISTRICT_STATUS.PUBLISHED,
    limit: 50,
  });

  const selectDistrictsOptions = useMemo(() => districtList?.results.map((district) => ({
    label: district.name,
    value: district.id.toString(),
  })) || [], [districtList]);

  return (
    <div className="border-grey-200 flex w-full flex-col gap-4 rounded-lg border bg-white p-6">
      <Heading variant="size-18" type="heading-500" className="text-grey-title">
        Basic Information
      </Heading>
      <div className="grid grid-cols-2 gap-x-4 gap-y-3">
        <FormField
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Role Name</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter role name" isError={!!fieldState.error} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {roleId && roleId.length > 0 && (
          <FormItem>
            <FormLabel required>Districts</FormLabel>
            <Input
              value={selectedDistrict[0]?.label || ''}
              placeholder="Enter district name"
              disabled
            />
          </FormItem>
        )}
        {!roleId && (
          <FormField
            control={control}
            name="districts"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Districts</FormLabel>
                <FormControl>
                  <MultiSelect
                    isError={!!fieldState.error}
                    selectedOptions={field.value}
                    showSearch
                    loading={isDistrictsLoading}
                    shouldFilter={false}
                    searchValue={districtsSearch}
                    searchPlaceholder="Search districts"
                    placeholder="Select districts"
                    modalPopover
                    onOptionChange={(_, selected) => field.onChange(selected)}
                    onSearchChange={setDistrictsSearch}
                  >
                    {selectDistrictsOptions.map((option) => (
                      <MultiSelectItem
                        key={option.value}
                        value={option.value}
                        option={option}
                      />
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <FormItem className="col-span-2">
              <FormLabel required>Description</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Explain the responsibilities of this role."
                  responsiveHeight
                  className="max-h-[200px] min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      {tabs.map((tab) => (
        <TabsContent key={tab.value} value={tab.value}>
          {tab.content}
        </TabsContent>
      ))}
    </div>
  );
};
