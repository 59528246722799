import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { getUserDetailsPermissionsCodenames } from './get-user-details-permissions-codenames';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TUserDetailsPermissionsCodeNames } from './user.types';

type TUseUserDetailsPermissionsCodeNamesProps = {
  userId: string;
  queryOptions?: TCustomUseQueryOptions<TUserDetailsPermissionsCodeNames, AxiosError>;
};

export const useUserDetailsPermissionsCodeNames = ({ userId, queryOptions }: TUseUserDetailsPermissionsCodeNamesProps) => {
  const { isError, error, ...rest } = useQuery({
    ...queryOptions,
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_USER_DETAILS_PERMISSIONS_CODE_NAMES, userId],
    queryFn: () => getUserDetailsPermissionsCodenames(userId),
    staleTime: 0,
  });

  useEffect(() => {
    if (isError && isClientError(error)) {
      showErrorToast('System message', 'Unable to retrieve user permissions');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
