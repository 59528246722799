export const ADMIN_CURRENT_USER_QUERY_KEYS = {
  CURRENT_USER: 'admin-current-user',
  LIST: 'admin-users-list',
  LIST_MANAGEMENT: 'admin-users-list-management',
  USERS_FILTER_OPTIONS: 'users-management-filter-options',
};

export const ADMIN_USERS_MANAGEMENT_QUERY_KEYS = {
  LIST_MANAGEMENT: 'admin-users-list-management',
  EXPORT: 'admin-users-export',
  GET_LIST_MANAGEMENT_MUTATION: 'admin-users-get-list-management-mutation',
  USERS_FILTER_OPTIONS: 'users-management-filter-options',
  USERS_DEACTIVATE_CHECK: 'users-deactivate-check',
  ACTIVATE_USERS: 'activate-users',
  DELETE_USER: 'delete-user',
  DELETE_USER_ACCESS_TO_SCHOOL: 'delete-user-access-to-school',
  USER_DETAILS: 'user-details',
  USER_LOGIN_HISTORY: 'user-login-history',
  USER_BASIC_DETAILS: 'user-basic-details',
  USER_BASIC_DETAILS_UPDATE: 'update-user-basic-details',
  ADD_INVITE_PURPLE_USER: 'add-invite-purple-user',
  BULK_UPLOAD_USERS: 'bulk-upload-users',
  GET_USER_DETAILS_PERMISSIONS: 'get-user-details-permissions',
  REMOVE_USER_DETAILS_PERMISSION: 'remove-user-details-permission',
  REMOVE_USER_DETAILS_NEGATIVE_PERMISSION: 'remove-user-details-negative-permission',
  ADD_USER_DETAILS_NEGATIVE_PERMISSION: 'add-user-details-negative-permission',
  UPDATE_USER_DETAILS_PERMISSIONS: 'update-user-details-permissions',
  GET_USER_DETAILS_PERMISSIONS_CODE_NAMES: 'get-user-details-permissions-codenames',
  IMPERSONATE_USER: 'impersonate-user',
  GET_IMPERSONATE_RESOURCES: 'get-impersonate-resources',
  GET_NOT_LOGGED_IN_USERS_REPORT: 'get-not-logged-in-users-report',
  GET_NOT_LOGGED_IN_USERS_REPORT_EXPORT: 'get-not-logged-in-users-report-export',
  GET_FREQUENCY_DISTRIBUTION_REPORT: 'get-frequency-distribution-report',
  GET_FREQUENCY_DISTRIBUTION_REPORT_EXPORT: 'get-frequency-distribution-report-export',
  GET_LOGGED_IN_BY_ROLE_REPORT: 'get-logged-in-by-role-report',
  GET_LOGGED_IN_BY_ROLE_REPORT_EXPORT: 'get-logged-in-by-role-report-export',
} as const;

export const USER_PERMISSION_TYPE = {
  ROLE: 'role',
  PERMISSION: 'permission',
  GROUP: 'group',
  NEGATIVE_PERMISSION: 'negative_permission',
} as const;

export const USER_PERMISSION_TYPE_BADGE_COLORS = {
  [USER_PERMISSION_TYPE.ROLE]: 'warning',
  [USER_PERMISSION_TYPE.PERMISSION]: 'info',
  [USER_PERMISSION_TYPE.GROUP]: 'info-dark',
  [USER_PERMISSION_TYPE.NEGATIVE_PERMISSION]: 'danger',
} as const;
