export const CONTACTS_QUERY_KEYS = {
  GET_CONTACTS_LIST: 'GET_CONTACTS_LIST',
  GET_CONTACTS_PRIORITY_LISTS: 'GET_CONTACTS_PRIORITY_LISTS',
  CREATE_CONTACTS_PRIORITY_LIST: 'CREATE_CONTACTS_PRIORITY_LIST',
  GET_CONTACTS_PRIORITY_LIST_BY_ID: 'GET_CONTACTS_PRIORITY_LIST_BY_ID',
  DELETE_CONTACTS_PRIORITY_LIST: 'DELETE_CONTACTS_PRIORITY_LIST',
  UPDATE_CONTACTS_PRIORITY_LIST: 'UPDATE_CONTACTS_PRIORITY_LIST',
  GET_CONTACTS_PRIORITY_LIST_CONTACTS: 'GET_CONTACTS_PRIORITY_LIST_CONTACTS',
  BATCH_ADD_CONTACTS_TO_PRIORITY_LIST: 'BATCH_ADD_CONTACTS_TO_PRIORITY_LIST',
  BATCH_REMOVE_CONTACTS_TO_PRIORITY_LIST: 'BATCH_REMOVE_CONTACTS_TO_PRIORITY_LIST',
  PRIORITY_LIST_BATCH_ADD_SHARING_USERS: 'PRIORITY_LIST_BATCH_ADD_SHARING_USERS',
  PRIORITY_LIST_BATCH_REMOVE_SHARING_USERS: 'PRIORITY_LIST_BATCH_REMOVE_SHARING_USERS',
  SEND_EMAIL_TO_CONTACTS: 'SEND_EMAIL_TO_CONTACTS',
  GET_CONTACTS_STATISTICS: 'GET_CONTACTS_STATISTICS',
  CREATE_CONTACT: 'CREATE_CONTACT',
  BULK_ADD_CONTACTS: 'BULK_ADD_CONTACTS',
  GET_CONTACTS_ID_LIST: 'GET_CONTACTS_ID_LIST',
  DELETE_CONTACT: 'DELETE_CONTACT',
  GET_CONTACT_DETAILS: 'GET_CONTACT_DETAILS',
  UPDATE_CONTACT_DETAILS: 'UPDATE_CONTACT_DETAILS',
  ADD_CONTACT_STUDENTS: 'ADD_CONTACT_STUDENTS',
} as const;
