import axiosInstance from '../axios-config';
import { PRINT_ENDPOINTS } from '../endpoints';
import type { TAdminPrintRequestParameters } from './print.types';

export const getAdminPrintData = async (parameters: TAdminPrintRequestParameters) => {
  const response = await axiosInstance.get<Blob>(PRINT_ENDPOINTS.ROOT, {
    params: parameters,
    responseType: 'blob',
  });
  return response.data;
};
