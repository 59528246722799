import { useFormContext } from 'react-hook-form';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@purple/ui';
import { countryOptions, usStates } from '~/components/Modals/Contacts/AddContactModal/constants';
import type { FC } from 'react';
import type { TContactAddressDetailsSchema } from './AddressSection';

export const AddressSectionFormContent: FC = () => {
  const { control } = useFormContext<TContactAddressDetailsSchema>();

  return (
    <>
      <FormField
        control={control}
        name="country"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">Country</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select country"
                    className="h-9"
                    disabled
                    selectedLabel={countryOptions.find((country) => country.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent
                  shouldFilter
                  searchPlaceholder="Search..."
                  emptyContent="Country not found"
                >
                  {countryOptions.map((country) => (
                    <ComboBoxItem key={country.value} value={country.value} onSelect={field.onChange}>
                      {country.label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="state"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">State</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select state"
                    className="h-9"
                    selectedLabel={usStates.find((state) => state.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent
                  shouldFilter
                  searchPlaceholder="Search..."
                  emptyContent="State not found"
                >
                  {usStates.map((state) => (
                    <ComboBoxItem key={state.value} value={state.value} keywords={[state.label]} onSelect={field.onChange}>
                      {state.label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="city"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">City</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <FormControl>
                <Input {...field} placeholder="Enter city" isError={!!fieldState.error} className="h-9" />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="street"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">Street</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <FormControl>
                <Input {...field} placeholder="Enter street" isError={!!fieldState.error} className="h-9" />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="postal_code"
        render={({ field, fieldState }) => (
          <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
            <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">ZIP/Postal Code</FormLabel>
            <div className="flex w-1/2 flex-col gap-1">
              <FormControl>
                <Input {...field} placeholder="Enter postal code" isError={!!fieldState.error} className="h-9" />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
    </>
  );
};
