import { Heading, Separator, Skeleton } from '@purple/ui';

const BasicDetailsTabSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="px-6 py-5">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Basic Details</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex flex-col gap-4 px-6 py-[30px]">
        <Skeleton className="h-[248px] w-full" />
      </div>
    </div>
  );
};

export { BasicDetailsTabSkeleton };
