import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { AppTabs, Button } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { ActivityTypeDetailsView, ActivityView } from '../helpers';
import { ActivityTypeInfo } from './components';
import { BasicDetailsTab, CustomTagsTab, GroupsTab, RolesTab } from './tabs';
import type { TAppTab } from '@purple/ui';
import type { TActivityTypeDetailsView } from '../helpers';

export const ActivityTypeDetailsPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const activityTypeDetailsTabs: TAppTab<TActivityTypeDetailsView>[] = useMemo(
    () => [
      {
        label: 'Basic Details',
        value: ActivityTypeDetailsView.DETAILS,
        className: 'shadow-none',
        content: <BasicDetailsTab />,
        permissions: [],
      },
      {
        label: 'Custom Tags',
        value: ActivityTypeDetailsView.TAGS,
        className: 'shadow-none',
        content: <CustomTagsTab />,
        permissions: [],
      },
      {
        label: 'Permissions (Roles)',
        value: ActivityTypeDetailsView.ROLES,
        className: 'shadow-none',
        content: <RolesTab />,
        permissions: [],
      },
      {
        label: 'Permissions (Groups)',
        value: ActivityTypeDetailsView.GROUPS,
        className: 'shadow-none',
        content: <GroupsTab />,
        permissions: [],
      },
    ],
    [],
  );

  const { isReady, activeTab, tabChangeHandler } = useTabs<TActivityTypeDetailsView>({
    tabs: activityTypeDetailsTabs,
    defaultTab: ActivityTypeDetailsView.DETAILS,
  });

  return (
    <div className="flex w-full flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700 size-4 shrink-0" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Activities.Root.makePath({ queries: { tab: ActivityView.FORM, district: searchParams.get('district') } })}>
          Back to Form Layouts
        </Link>
      </Button>
      <ActivityTypeInfo />
      <AppTabs
        tabs={activityTypeDetailsTabs}
        isReady={isReady}
        listClassName="pl-2"
        value={activeTab}
        onValueChange={tabChangeHandler}
      />
    </div>
  );
};
