import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { batchAddContactsToPriorityList } from './batch-add-contacts-to-priority-list';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TPriorityListBatchManageContactsPayload } from './contacts.types';

export const usePriorityListBatchAddContacts = (
  options?: TCustomUseMutationOptions<unknown, AxiosError, TPriorityListBatchManageContactsPayload>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [CONTACTS_QUERY_KEYS.BATCH_ADD_CONTACTS_TO_PRIORITY_LIST],
    mutationFn: batchAddContactsToPriorityList,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LISTS] });
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LIST_CONTACTS] });
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LIST_BY_ID, args[1].id] });
      showSuccessToast('System message', 'Contacts added to priority list');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to add contacts to priority list');
      options?.onError?.(...args);
    },
  });
};
