/* eslint-disable perfectionist/sort-imports */
// Sentry initialization should be imported first!
import './sentry.client.config';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { PermissionsProvider } from '@purple/permissions';
import { AppLoaderScreen } from '@purple/ui';
import type { FC } from 'react';
// layouts
import { AuthLayout, SelfLayout, SiteLayout } from '~/layout';
// pages
import {
  ActionDetailsPage,
  ActionsPage,
  ActionTypesDetailsPage,
  ActivitiesPage,
  ActivityDetailsPage,
  ActivityTypeDetailsPage,
  BannersPage,
  ContactDetailsPage,
  ContactsPage,
  DistrictDetailsPage,
  DistrictSetUpPage,
  DistrictsPage,
  DocumentedActions,
  DummyPage,
  ForgotPasswordPage,
  HomePage,
  LoggedInByRoleReport,
  LoginPage,
  ManagePermissionsGroupsPage,
  ManageRolesPage,
  NotFoundPage,
  NotLoggedInReport,
  PermissionsGroupsPage,
  Print,
  RolesPage,
  Saf,
  SafDetails,
  SafPrioritized,
  SchoolDetailsPage,
  SchoolsPage,
  ServerErrorPage,
  StudentVisualizerPage,
  SubmitterTypeDetails,
  ThisWeeksLoginsReport,
  UserDetails,
  UserFrequencyDistributionReport,
  UserManagePermissions,
  UsersList,
  VisualizerSetupPage,
} from '~/pages';
// constants
import { AdminRoutes } from '~/constants';
import { useAppInit, useAppSelector } from '~/hooks';
// vendor styles
import '~/styles/index.css';
// other
import { isLoadingSelector } from '~/store/features/user';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AdminApplicationRoutes: FC = () => {
  const { isLoading, userPermissions } = useAppInit();

  const isAuthLoading = useAppSelector(isLoadingSelector);

  if (isLoading || isAuthLoading) return <AppLoaderScreen />;

  return (
    <PermissionsProvider userPermissions={userPermissions || []}>
      <SentryRoutes>
        {/* AUTH APP ROUTES */}
        <Route element={<AuthLayout />}>
          <Route index path={AdminRoutes.Auth.Login.Root.path} element={<LoginPage />} />
          <Route path={AdminRoutes.Auth.ForgotPassword.Root.path} element={<ForgotPasswordPage />} />
        </Route>
        {/* MAIN ROUTES WITHOUT LAYOUT */}
        <Route element={<SelfLayout />}>
          <Route path={AdminRoutes.App.Districts.DistrictSetUp.path} element={<DistrictSetUpPage />} />
          <Route path={AdminRoutes.App.Visualizer.StudentSetUp.path} element={<VisualizerSetupPage />} />
          <Route path={AdminRoutes.App.Users.Roles.ManageRoles.path} element={<ManageRolesPage />} />
          <Route path={AdminRoutes.App.Users.Permissions.ManagePermissions.path} element={<ManagePermissionsGroupsPage />} />
          <Route path={AdminRoutes.App.Users.UserDetail.UserManagePermissions.path} element={<UserManagePermissions />} />
        </Route>
        {/* MAIN APP ROUTS */}
        <Route element={<SiteLayout />}>
          <Route index path={AdminRoutes.App.Home.Root.path} element={<HomePage />} />
          <Route index path={AdminRoutes.App.DocumentedActions.Root.path} element={<DocumentedActions />} />
          <Route index path={AdminRoutes.App.SafPrioritized.Root.path} element={<SafPrioritized />} />
          <Route index path={AdminRoutes.App.NotLoggedInReport.Root.path} element={<NotLoggedInReport />} />
          <Route index path={AdminRoutes.App.UserFrequencyDistribution.Root.path} element={<UserFrequencyDistributionReport />} />
          <Route index path={AdminRoutes.App.ThisWeeksLogins.Root.path} element={<ThisWeeksLoginsReport />} />
          <Route index path={AdminRoutes.App.LoggedInByRole.Root.path} element={<LoggedInByRoleReport />} />
          <Route path={AdminRoutes.App.Districts.Root.path} element={<Outlet />}>
            <Route index element={<DistrictsPage />} />
            <Route path={AdminRoutes.App.Districts.Details.path} element={<DistrictDetailsPage />} />
          </Route>
          <Route path={AdminRoutes.App.Schools.Root.path} element={<Outlet />}>
            <Route index element={<SchoolsPage />} />
            <Route path={AdminRoutes.App.Schools.Details.path} element={<SchoolDetailsPage />} />
          </Route>
          <Route path={AdminRoutes.App.Users.Root.path} element={<Outlet />}>
            <Route index path={AdminRoutes.App.Users.Root.path} element={<UsersList />} />
            <Route path={AdminRoutes.App.Users.UserDetail.Root.path} element={<UserDetails />} />
            <Route path={AdminRoutes.App.Users.Permissions.Root.path} element={<PermissionsGroupsPage />} />
            <Route path={AdminRoutes.App.Users.Roles.Root.path} element={<RolesPage />} />
          </Route>
          <Route path={AdminRoutes.App.Contacts.Root.path} element={<Outlet />}>
            <Route index element={<ContactsPage />} />
            <Route path={AdminRoutes.App.Contacts.Details.path} element={<ContactDetailsPage />} />
          </Route>
          <Route path={AdminRoutes.App.Dashboard.Root.path} element={<DummyPage />} />
          <Route path={AdminRoutes.App.Visualizer.Root.path} element={<Outlet />}>
            <Route index element={<Navigate replace to={AdminRoutes.App.Visualizer.Student.path} />} />
            <Route path={AdminRoutes.App.Visualizer.Student.path} element={<StudentVisualizerPage />} />
          </Route>
          <Route path={AdminRoutes.App.Activities.Root.path} element={<Outlet />}>
            <Route index element={<ActivitiesPage />} />
            <Route path={AdminRoutes.App.Activities.Details.path} element={<ActivityDetailsPage />} />
            <Route path={AdminRoutes.App.Activities.Types.Root.path} element={<Navigate replace to={AdminRoutes.App.Activities.Root.path} />} />
            <Route path={AdminRoutes.App.Activities.Types.Details.path} element={<ActivityTypeDetailsPage />} />
          </Route>
          <Route path={AdminRoutes.App.Actions.Root.path} element={<Outlet />}>
            <Route index element={<Navigate replace to={AdminRoutes.App.Actions.Student.Root.path} />} />
            <Route path={AdminRoutes.App.Actions.Student.Root.path} element={<ActionsPage />} />
            <Route path={AdminRoutes.App.Actions.Student.Details.path} element={<ActionDetailsPage />} />
            <Route path={AdminRoutes.App.Actions.Student.ActionTypesDetails.path} element={<ActionTypesDetailsPage />} />
          </Route>
          <Route path={AdminRoutes.App.Safs.Root.path} element={<Outlet />}>
            <Route index path={AdminRoutes.App.Safs.Root.path} element={<Saf />} />
            <Route path={AdminRoutes.App.Safs.SafDetail.path} element={<SafDetails />} />
            <Route path={AdminRoutes.App.Safs.SubmitterTypeDetail.path} element={<SubmitterTypeDetails />} />
          </Route>
          <Route path={AdminRoutes.App.Meetings.Root.path} element={<DummyPage />} />
          <Route path={AdminRoutes.App.ServiceProviders.Root.path} element={<DummyPage />} />
          <Route path={AdminRoutes.App.Surveys.Root.path} element={<DummyPage />} />
          <Route path={AdminRoutes.App.BannerNotifications.Root.path} element={<BannersPage />} />
        </Route>
        {/* SERVER ERROR PAGE */}
        <Route path={AdminRoutes.System.ServerError.Root.path} element={<ServerErrorPage />} />

        {/* NOT FOUND PAGE */}
        <Route path="*" element={<NotFoundPage />} />
      </SentryRoutes>
    </PermissionsProvider>
  );
};

const AdminTechnicalRoutes: FC = () => {
  return (
    <Routes>
      {/* SYSTEM ROUTES */}
      <Route path={AdminRoutes.System.Print.path} element={<Outlet />}>
        <Route index element={<Print />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export const AdminApplication: FC = () => {
  const { pathname } = useLocation();
  return (
    AdminRoutes.System.Print.path === pathname ? <AdminTechnicalRoutes /> : <AdminApplicationRoutes />
  );
};
