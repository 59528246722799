import { useEffect, useMemo, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, Separator, Text } from '@purple/ui';
import { SAF_LANGUAGES, type TSafLanguages } from '~/pages/Main/Saf/constants';
import { type TAdminSubmitterTypeDetail, type TAdminSubmitterTypeFieldView, useSubmitterTypeFieldSettings, useUpdateSubmitterTypeFieldSettings } from '~/services';
import { FieldSettingsTableEdit, FieldSettingsTableView } from './components';
import { convertFieldSettingsToView } from './converter';
import { FieldSettingsTabSkeleton } from './FieldSettingsTabSkeleton';

type TFieldSettingsTabProperties = {
  data?: TAdminSubmitterTypeDetail;
};

const FieldSettingsTab = ({ data }: TFieldSettingsTabProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSubmitterTypeFieldSettings();

  const [selectedLanguage, setSelectedLanguage] = useState<TSafLanguages>('en');

  const [editableData, setEditableData] = useState<TAdminSubmitterTypeFieldView | null>(null);

  const { data: fieldsSettingsData } = useSubmitterTypeFieldSettings({
    typeId: data?.id,
  });

  const languageChangeHandler = (value: TSafLanguages) => {
    setSelectedLanguage(value);
  };

  const convertedFields = useMemo(() => {
    return convertFieldSettingsToView(fieldsSettingsData);
  }, [fieldsSettingsData]);

  useEffect(() => {
    setEditableData(convertedFields);
  }, [convertedFields]);

  if (!fieldsSettingsData) return <FieldSettingsTabSkeleton />;

  const changeItemHandler = (data: TAdminSubmitterTypeFieldView) => {
    setEditableData(data);
  };

  const saveHandler = () => {
    if (!editableData || !fieldsSettingsData || !data?.id) return;

    const changedData = Object.values(editableData).flat().filter((item) => {
      const foundItem = fieldsSettingsData.field_settings.find((field) => field.id === item.id);

      // Check if some required is different
      if (item.is_required !== foundItem?.is_required) {
        return true;
      }

      // Check if some translation is different
      if (Object.keys(foundItem.translations).some((key) => foundItem.translations
        && foundItem.translations[key as keyof typeof foundItem.translations] !== item.translations[key as keyof typeof item.translations])) {
        return true;
      }
      return false;
    });

    mutate({
      id: data?.id || '',
      payload: {
        field_settings: changedData,
      },
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-6 py-5">
        <div className="flex flex-col gap-0">
          <Heading variant="size-18" type="heading-600" className="text-grey-950">Field Settings</Heading>
          <Text variant="size-16" type="body-400" className="text-grey-600">You can change the default value of the fields depending on whether they are required or not</Text>
        </div>
        <div>
          {mode === 'view' && (
            <div className="flex flex-row gap-[12px]">
              <Button size="link" variant="link" iconLeft={<PurpleIcon name="pencil" />} onClick={() => setMode('edit')}>
                Edit
              </Button>
            </div>
          )}
          {mode === 'edit' && (
            <div className="flex flex-row gap-[12px]">
              <Button size="small" variant="secondary" onClick={() => setMode('view')}>
                Cancel
              </Button>
              <Button size="small" variant="primary" onClick={saveHandler} isLoading={isPending}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex items-center justify-between px-6 py-5">
        <RadixSelect defaultValue={selectedLanguage} onValueChange={languageChangeHandler}>
          <RadixSelectTrigger className="w-40">
            <RadixSelectValue placeholder="Select language" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {SAF_LANGUAGES.map((language) => (
              <RadixSelectItem key={language.value} value={language.value}>
                {language.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
      </div>
      {mode === 'view' && (
        <FieldSettingsTableView data={convertedFields} selectedLanguage={selectedLanguage} />
      )}
      {mode === 'edit' && (
        <FieldSettingsTableEdit data={editableData} selectedLanguage={selectedLanguage} onChange={changeItemHandler} />
      )}
    </div>
  );
};

export { FieldSettingsTab };
