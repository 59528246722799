import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebounceValue } from 'usehooks-ts';
import { useDebouncedCallback } from '@purple/hooks';
import { Checkbox, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage, MultiSelect, MultiSelectItem, Text } from '@purple/ui';
import { useDistrictsListBasicInfo, useRoleSearch, useUserSchools } from '~/services';
import type { TAddInvitePurpleUserFormValues } from './schema';

const DEFAULT_SEARCH_DELAY = 500;

export const AccessForm = () => {
  const { control, watch, setValue } = useFormContext<TAddInvitePurpleUserFormValues>();

  const selectedDistrict = watch('district');

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
  const [rolesDebouncedSearchValue, setRolesDebouncedSearchValue] = useState<string>('');
  const [schoolsSearch, setSchoolsSearch] = useState<string>('');
  const [debouncedSchoolsSearch] = useDebounceValue(schoolsSearch, DEFAULT_SEARCH_DELAY);

  const { data: activeDistricts, isFetching } = useDistrictsListBasicInfo({
    search: debouncedSearchValue,
    limit: 50,
    status: 'published',
  });

  const { data: schools, isLoading: isSchoolsLoading } = useUserSchools({
    requestParameters: {
      district: selectedDistrict,
      search: debouncedSchoolsSearch,
    },
    enabled: selectedDistrict.length > 0,
  });

  const { data: roles, isLoading: isRolesLoading } = useRoleSearch({
    requestParameters: {
      search: rolesDebouncedSearchValue,
      district: selectedDistrict,
    },
    enabled: selectedDistrict.length > 0,
  });

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const rolesDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setRolesDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const districtsSelectOptions = useMemo(() => {
    return (
      activeDistricts?.results.map((district) => ({
        ...district,
        label: district.name,
        value: district.id,
      })) ?? []
    );
  }, [activeDistricts]);

  const schoolsSelectOptions = useMemo(() => {
    return (
      schools?.results.map((school) => ({
        ...school,
        label: school.name,
        value: school.id,
      })) ?? []
    );
  }, [schools]);

  const rolesSelectOptions = useMemo(() => {
    return (
      roles?.results.map((role) => ({
        ...role,
        label: role.name,
        value: role.id,
      })) ?? []
    );
  }, [roles]);

  return (
    <div className="grid grid-cols-2 gap-4">
      <Text variant="size-16" type="body-600" className="text-grey-title col-span-2">Access</Text>
      <FormField
        control={control}
        name="district"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>District</FormLabel>
            <FormControl>
              <ComboBox modal>
                <ComboBoxTrigger
                  isError={!!fieldState.error}
                  placeholder="Select district"
                  selectedLabel={
                    districtsSelectOptions.find((option) => option.value.toString() === field.value.toString())?.label
                  }
                />
                <ComboBoxContent
                  loading={isFetching}
                  shouldFilter={false}
                  searchPlaceholder="Search district..."
                  emptyContent="District not found."
                  onSearchChange={debouncedSearch}
                >
                  {activeDistricts?.results?.map(({ id, name }) => (
                    <ComboBoxItem
                      key={id}
                      value={id.toString()}
                      selected={field.value.toString() === id.toString()}
                      onSelect={
                        (value) => {
                          setValue('schools', []);
                          setValue('prounitas_roles', []);
                          field.onChange(value);
                        }
                      }
                    >
                      {name}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="schools"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>School(s)</FormLabel>
            <FormControl>
              <MultiSelect
                isError={!!fieldState.error}
                selectedOptions={field.value}
                modalPopover
                placeholder="Select school(s)"
                showSearch
                shouldFilter={false}
                disabled={!selectedDistrict}
                loading={isSchoolsLoading}
                searchValue={schoolsSearch}
                onSearchChange={setSchoolsSearch}
                onOptionChange={(_, selected) => field.onChange(selected)}
              >
                {schoolsSelectOptions.map((school) => (
                  <MultiSelectItem
                    key={school.value}
                    value={school.value}
                    option={school}
                  />
                ))}
              </MultiSelect>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="flex w-full flex-col gap-1">
        <FormField
          control={control}
          name="is_district_crisis_representative"
          render={({ field }) => (
            <FormItem className="flex items-center gap-2 space-y-0">
              <FormControl>
                <Checkbox
                  {...field}
                  value={field.value?.toString()}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  className="size-4"
                />
              </FormControl>
              <FormLabel className="text-grey-title text-sm font-normal">Mark as District Crisis Representative</FormLabel>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="is_school_crisis_representative"
          render={({ field }) => (
            <FormItem className="flex items-center gap-2 space-y-0">
              <FormControl>
                <Checkbox
                  {...field}
                  value={field.value?.toString()}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  className="size-4"
                />
              </FormControl>
              <FormLabel className="text-grey-title text-sm font-normal">Mark as School Crisis Representative</FormLabel>
            </FormItem>
          )}
        />
      </div>
      <FormField
        control={control}
        name="prounitas_roles"
        render={({ field, fieldState }) => (
          <FormItem className="col-span-2">
            <FormLabel required>Role</FormLabel>
            <ComboBox modal>
              <FormControl>
                <ComboBoxTrigger
                  isError={!!fieldState.error}
                  placeholder="Select role"
                  selectedLabel={
                    rolesSelectOptions.find((option) => option.value.toString() === field.value.toString())?.label
                  }
                  disabled={selectedDistrict.length === 0}
                />
              </FormControl>
              <ComboBoxContent
                loading={isRolesLoading}
                onSearchChange={rolesDebouncedSearch}
                shouldFilter={false}
                searchPlaceholder="Search role name..."
                emptyContent="No roles found."
              >
                {rolesSelectOptions.map(({ label, value }) => (
                  <ComboBoxItem
                    key={value}
                    value={value.toString()}
                    selected={value.toString() === field.value.toString()}
                    onSelect={
                      (value) => {
                        setValue('prounitas_roles', [Number.parseInt(value)], { shouldValidate: true });
                      }
                    }
                  >
                    {label}
                  </ComboBoxItem>
                ))}
              </ComboBoxContent>
            </ComboBox>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
