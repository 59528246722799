import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ADMIN_SUBMITTER_TYPES_QUERY_KEYS } from './submitterTypes.const';
import { updateSubmitterTypeDetails } from './update-submitter-type-details';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TAdminUpdateSubmitterTypeDetailsPayload } from './submitterTypes.types';

export const useUpdateSubmitterDetails = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, { id: string; payload: TAdminUpdateSubmitterTypeDetailsPayload }>({
    mutationKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.UPDATE_DETAILS],
    mutationFn: updateSubmitterTypeDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.DETAIL] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.LIST] });
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not update a Submitter Detail. Check the provided information and try again');
      };
    },
  });
};
