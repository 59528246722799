import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITY_TYPES_QUERY_KEYS } from '../activities.const';
import { getActivityTypeRoles } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActivityTypeRolesRequestParameters, TActivityTypeRolesResponse } from '../activities.types';

export const useActivityTypeRoles = (
  parameters: TActivityTypeRolesRequestParameters,
  queryOptions?: TCustomUseQueryOptions<TActivityTypeRolesResponse, AxiosError>,
) => {
  const queryData = useQuery({
    queryKey: [ACTIVITY_TYPES_QUERY_KEYS.GET_ACTIVITY_TYPE_ROLES, parameters],
    queryFn: () => getActivityTypeRoles(parameters),
    ...queryOptions,
  });

  useEffect(() => {
    if (queryData.isError && isClientError(queryData.error)) {
      showErrorToast('System message', 'Unable to retrieve activity type roles');
    }
  }, [queryData.isError, queryData.error]);

  return queryData;
};
