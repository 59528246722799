import { z } from 'zod';

export const activityTypeSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Name is required' })
    .max(100, { message: 'Name is too long' }),
  usage: z
    .string()
    .trim()
    .min(1, { message: 'Usage is required' })
    .max(255, { message: 'Usage is too long' }),
  is_active: z.boolean(),
  is_recurrence: z.boolean(),
});
