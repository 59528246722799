import { useMutation } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { deleteContact } from './delete-contact';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useDeleteContact = (queryOptions?: TCustomUseMutationOptions<AxiosResponse, AxiosError>) => {
  return useMutation({
    ...queryOptions,
    mutationKey: [CONTACTS_QUERY_KEYS.DELETE_CONTACT],
    mutationFn: deleteContact,
    onError: (error) => {
      if (isClientError(error)) {
        showErrorToast('System message', 'Unable to delete contact');
      }
    },
  });
};
