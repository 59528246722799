import * as z from 'zod';
import { zodAlwaysRefine } from '@purple/shared-utils';
import { GroupPermissionStep } from './stepper';

export type TGroupPermissionBase = z.infer<typeof basePermissionGroupSchema & typeof groupInformationSchema & typeof managePermissionsSchema & typeof addUsersSchema>;

const basePermissionGroupSchema = z.object({
  currentStep: z.nativeEnum(GroupPermissionStep).default(GroupPermissionStep.GROUP_INFORMATION),
});

const groupInformationSchema = z.object({
  name: z.string().trim().min(1, 'Group name is required'),
  description: z.string().trim().min(1, 'Description is required'),
});

const managePermissionsSchema = z.object({
  permissions: z.array(z.string()).nullish(),
});

const managePermissionsSchemaSuperRefine = (data: TGroupPermissionBase, ctx: z.RefinementCtx) => {
  if (data.currentStep !== GroupPermissionStep.MANAGE_PERMISSIONS) return;

  if (data.permissions === undefined || data.permissions === null || data.permissions.length === 0) {
    ctx.addIssue({
      path: ['permissions'],
      code: z.ZodIssueCode.custom,
      message: 'Please select at least one permission',
    });
  }
};

const addUsersSchema = z.object({
  users: z.array(z.string()).nullish(),
});

const addUsersSchemaSuperRefine = (data: TGroupPermissionBase, ctx: z.RefinementCtx) => {
  if (data.currentStep !== GroupPermissionStep.ADD_USERS) return;

  if (data.users === undefined || data.users === null || data.users.length === 0) {
    ctx.addIssue({
      path: ['users'],
      code: z.ZodIssueCode.custom,
      message: 'Please select at least one user',
    });
  }
};

export const permissionGroupSchema = zodAlwaysRefine(
  basePermissionGroupSchema
    .and(groupInformationSchema)
    .and(managePermissionsSchema)
    .superRefine(managePermissionsSchemaSuperRefine)
    .and(addUsersSchema)
    .superRefine(addUsersSchemaSuperRefine),
);
