import { Separator, Skeleton } from '@purple/ui';

export const ContentSkeleton = () => {
  return (
    <section className="flex w-full flex-col items-start justify-between gap-3 rounded-lg border border-grey-200 bg-white">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5">
        <Skeleton className="h-[25px] w-[112px]" />
      </div>
      <div className="flex w-full flex-col gap-6 px-6 py-8 lg:flex-row">
        <div className="grid w-full grid-cols-2 gap-3">
          {Array.from({ length: 8 }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Skeleton key={index} className="h-6 rounded-md " />
          ))}
        </div>
        <Separator orientation="vertical" className="h-px w-full bg-grey-200 lg:h-auto lg:w-px" />
        <div className="grid w-full grid-cols-2 gap-3">
          {Array.from({ length: 8 }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Skeleton key={index} className="h-6 rounded-md" />
          ))}
        </div>
      </div>
    </section>
  );
};
