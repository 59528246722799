import { useMemo } from 'react';
import { useTabs } from '@purple/hooks';
import { APP_PERMISSIONS } from '@purple/permissions';
import { useActionsStatistic } from '~/services/actions/useActionsStatistic';
import { ActionsTabsValues } from '../constants';
import { ActionTypes } from './ActionTypes';
import { AllActions } from './AllActions';
import { ServiceLinks } from './ServiceLinks';
import type { TAppTab } from '@purple/ui';

export const useActionsTabs = () => {
  const { data: actionsCountStatistic, isLoading } = useActionsStatistic();

  const actionsTabs: TAppTab[] = useMemo(() =>
    [
      {
        label: `All Actions (${isLoading ? '...' : (actionsCountStatistic?.actions_count ?? 0)})`,
        value: ActionsTabsValues.allActions,
        content: <AllActions />,
        permissions: [APP_PERMISSIONS.CAN_ACCESS_ADMIN_STUDENT_CENTERED_ACTIONS],
      },
      {
        label: `Action Types (${isLoading ? '...' : (actionsCountStatistic?.action_types_count ?? 0)})`,
        value: ActionsTabsValues.actionTypes,
        content: <ActionTypes />,
        permissions: [],
      },
      {
        label: `Service Links (${isLoading ? '...' : (actionsCountStatistic?.service_links_count ?? 0)})`,
        value: ActionsTabsValues.serviceLinks,
        content: <ServiceLinks />,
        permissions: [],
      },
    ], [actionsCountStatistic?.action_types_count, actionsCountStatistic?.actions_count, actionsCountStatistic?.service_links_count, isLoading]);

  const { activeTab, tabChangeHandler, isReady } = useTabs({
    tabs: actionsTabs,
    defaultTab: ActionsTabsValues.allActions,
  });

  return {
    isTabsReady: isReady,
    activeTab,
    actionsTabs,
    tabChangeHandler,
  };
};
