import axiosInstance from '../../axios-config';
import { ACTIVITIES_ENDPOINTS } from '../../endpoints';
import type { TActivityListQueryParameters, TActivityListResponse } from '../activities.types';

export const getActivityList = async (parameters?: TActivityListQueryParameters) => {
  const response = await axiosInstance.get<TActivityListResponse>(ACTIVITIES_ENDPOINTS.ROOT, {
    params: parameters,
  });

  return response.data;
};
