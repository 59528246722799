import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { markAllAsRead } from './mark-all-as-read';
import { NOTIFICATIONS_QUERY_KEYS } from './notifications.const';

type TMarkAllAsReadOptions = {
  shouldRevalidate?: boolean;
};

export const useMarkAllAsRead = (options?: TMarkAllAsReadOptions) => {
  const { shouldRevalidate = false } = options || {};

  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [NOTIFICATIONS_QUERY_KEYS.MARK_ALL_NOTIFICATIONS_AS_READ],
    mutationFn: markAllAsRead,
    onSuccess: () => {
      if (shouldRevalidate) {
        queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_QUERY_KEYS.GET_NOTIFICATIONS_LIST] });
      }
      showSuccessToast('System message', 'All notifications marked as read');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to mark all notifications as read');
    },
  });
};
