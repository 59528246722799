import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { updatePriorityList } from './update-priority-list';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TUpdatePriorityListPayload } from './contacts.types';

export const useUpdatePriorityList = (
  options?: TCustomUseMutationOptions<TUpdatePriorityListPayload, AxiosError, TUpdatePriorityListPayload>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [CONTACTS_QUERY_KEYS.UPDATE_CONTACTS_PRIORITY_LIST],
    mutationFn: updatePriorityList,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LISTS] });
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LIST_BY_ID, args[1].id] });
      showSuccessToast('System message', 'Priority list updated successfully');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to update priority list');
      options?.onError?.(...args);
    },
  });
};
