import { useMemo } from 'react';
import { useTabs } from '@purple/hooks';
import { AppTabs, Heading, Text } from '@purple/ui';
import { useActivityInstancesCount } from '~/services';
import { ActivityView } from '../helpers';
import { AllActivitiesTab, FormLayoutsTab } from './tabs';
import type { TAppTab } from '@purple/ui';
import type { TActivityView } from '../helpers';

export const ActivitiesPage: React.FC = () => {
  const { data } = useActivityInstancesCount();

  const activityTabs: TAppTab<TActivityView>[] = useMemo(
    () => [
      {
        label: `All Community Activities (${data ? data.community_activity_count : '...'})`,
        value: ActivityView.LIST,
        content: <AllActivitiesTab />,
        permissions: [],
      },
      {
        label: `Form Layouts`,
        value: ActivityView.FORM,
        content: <FormLayoutsTab />,
        permissions: [],
      },
    ],
    [data],
  );

  const { isReady, activeTab, tabChangeHandler } = useTabs<TActivityView>({
    tabs: activityTabs,
    defaultTab: ActivityView.LIST,
  });

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex flex-col gap-1">
          <Heading tag="h1" variant="size-28" type="heading-600" className="text-grey-title">
            Community Centered Activities
          </Heading>
          <Text variant="size-14" type="body-400" className="text-grey-950">
            Manage existing Community Activities and create new community activity forms
          </Text>
        </div>
      </div>
      <AppTabs tabs={activityTabs} listClassName="pl-2" value={activeTab} isReady={isReady} onValueChange={tabChangeHandler} />
    </div>
  );
};
