import axiosInstance from '~/services/axios-config';
import { ACTIVITIES_ENDPOINTS } from '../../endpoints';
import type { TActivityDeleteResponse } from '../activities.types';

export const deleteActivity = async (id: string) => {
  const response = await axiosInstance.delete<TActivityDeleteResponse>(
    ACTIVITIES_ENDPOINTS.BY_ID(id),
  );
  return response.data;
};
