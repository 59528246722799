import axiosInstance from '~/services/axios-config';
import { NOTIFICATIONS_ENDPOINTS } from '../endpoints';
import type { TNotificationListQueryParameters, TNotificationsResponse } from './notifications.types';

export const getNotifications = async (parameters?: TNotificationListQueryParameters) => {
  const response = await axiosInstance.get<TNotificationsResponse>(NOTIFICATIONS_ENDPOINTS.ROOT, {
    params: parameters,
  });
  return response.data;
};
