import { useMutation } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { getAdminPrintData } from './get-admin-print-data';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TUsePrintMutationKeyValues } from './print.types';

export const usePrint = (mutationKey: TUsePrintMutationKeyValues, queryOptions?: TCustomUseMutationOptions<Blob, AxiosError>) => {
  return useMutation({
    ...queryOptions,
    mutationKey: [mutationKey],
    mutationFn: getAdminPrintData,
    onSuccess: (blob) => {
      const blobURL = URL.createObjectURL(blob);
      const newWindow = window.open(blobURL, '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = `${mutationKey}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showErrorToast('System message', 'Could not generate the print preview. Downloading PDF instead');
      }
    },
    onError: (error) => {
      if (isClientError(error)) {
        showErrorToast('System message', 'Could not generate the print preview. Please try again');
      }
    },
  });
};
