import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { Typography } from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { addMinutes, isToday } from 'date-fns';
import { useDebounceValue } from 'usehooks-ts';
import z from 'zod';
import { PurpleIcon } from '@purple/icons';
import { BannerStatus, BannerType, DISTRICT_STATUS } from '@purple/shared-types';
import { isValidURL, multiSelectNumberOptions } from '@purple/shared-utils';
import {
  Button,
  Checkbox,
  cn,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  OfflineController,
  ScrollArea,
  Separator,
  Textarea,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useCreateBanner, useDistrictsListBasicInfo, useGroupList, useRoleSearch, useUpdateBanner } from '~/services';
import { BodyToolbar } from './BodyToolbar';
import { BANNER_TYPE_OPTIONS } from './helpers';
import type { TBannerItem } from '~/services';

const bannerSchema = z
  .object({
    _is_create: z.boolean(),
    is_apply_for_all_districts: z.boolean(),
    district: z.number({ required_error: 'District is required' }).nullish(),
    type: z.nativeEnum(BannerType).nullish(),
    title: z.string().trim().min(1, 'Title is required'),
    body: z.string().trim().optional(),
    start_date_and_time: z
      .date()
      .nullish(),
    end_date_and_time: z
      .date()
      .nullish(),
    roles: multiSelectNumberOptions.optional(),
    groups: multiSelectNumberOptions.optional(),
    is_view_more: z.boolean(),
    view_more_link: z.string().trim().optional(),
  })
  .superRefine((data, context) => {
    if (!data.is_apply_for_all_districts && !data.district) {
      context.addIssue({
        path: ['district'],
        code: z.ZodIssueCode.custom,
        message: 'District is required',
      });
    }

    if (!data.type) {
      context.addIssue({
        path: ['type'],
        code: z.ZodIssueCode.custom,
        message: 'Banner type is required',
      });
    }

    if (!data.start_date_and_time) {
      context.addIssue({
        path: ['start_date_and_time'],
        code: z.ZodIssueCode.custom,
        message: 'Start date & time is required',
      });
    }

    if (data._is_create && data.start_date_and_time && data.start_date_and_time < new Date()) {
      context.addIssue({
        path: ['start_date_and_time'],
        code: z.ZodIssueCode.custom,
        message: 'Start date & time must be in the future',
      });
    }

    if (!data.end_date_and_time) {
      context.addIssue({
        path: ['end_date_and_time'],
        code: z.ZodIssueCode.custom,
        message: 'End date & time is required',
      });
    }

    if (data.end_date_and_time && data.start_date_and_time && data.end_date_and_time <= data.start_date_and_time) {
      context.addIssue({
        path: ['end_date_and_time'],
        code: z.ZodIssueCode.custom,
        message: 'End date & time must be after start date & time',
      });
    }

    if (data.is_view_more && !data.view_more_link) {
      context.addIssue({
        path: ['view_more_link'],
        code: z.ZodIssueCode.custom,
        message: 'View more link is required',
      });
    }

    if (data.is_view_more && data.view_more_link && !isValidURL(data.view_more_link)) {
      context.addIssue({
        path: ['view_more_link'],
        code: z.ZodIssueCode.custom,
        message: 'Invalid URL format',
      });
    }
  });

type TManageBannerDialogProperties = {
  banner?: TBannerItem | null;
  onClose?: () => void;
  onSubmit?: (formData: z.infer<typeof bannerSchema>, isDraft: boolean) => void;
};

export const ManageBannerDialog: React.FC<TManageBannerDialogProperties> = (props) => {
  const { banner = null, onClose, onSubmit } = props;

  const { isOpen, toggleModal } = useModal(ModalType.MANAGE_BANNER);

  const [districtSearch, setDistrictSearch] = useState<string>('');
  const [roleSearch, setRoleSearch] = useState<string>('');
  const [groupSearch, setGroupSearch] = useState<string>('');
  const [debouncedSearchDistrict] = useDebounceValue(districtSearch, 500);
  const [debouncedSearchRole] = useDebounceValue(roleSearch, 500);
  const [debouncedSearchGroup] = useDebounceValue(groupSearch, 500);

  const { mutate: createBanner, isPending: isCreatePending } = useCreateBanner();
  const { mutate: updateBanner, isPending: isUpdatePending } = useUpdateBanner();

  const isLoading = useMemo(() => isCreatePending || isUpdatePending, [isCreatePending, isUpdatePending]);

  const { data: districts, isFetching: isDistrictFetching } = useDistrictsListBasicInfo({
    enabled: isOpen,
    status: DISTRICT_STATUS.PUBLISHED,
    search: debouncedSearchDistrict,
  });

  const districtOptions = useMemo(() => districts?.results.map((district) => ({
    value: district.id,
    label: district.name,
    subdomain: district.subdomain,
  })) ?? [], [districts]);

  const { data: groups, isFetching: isGroupsFetching } = useGroupList({
    enabled: isOpen,
    requestParameters: {
      search: debouncedSearchGroup,
    },
  });

  const groupsOptions = useMemo(() => groups?.results.map((group) => ({
    value: group.id,
    label: group.name,
  })) ?? [], [groups]);

  const defaultValues: Partial<z.infer<typeof bannerSchema>> = useMemo(
    () => ({
      _is_create: banner === null,
      is_apply_for_all_districts: banner?.is_apply_for_all_districts ?? false,
      district: banner?.district?.id ?? null,
      type: banner?.type ?? null,
      title: banner?.title || '',
      body: typeof banner?.body === 'string' ? banner.body : '',
      start_date_and_time: banner?.start_date_and_time ? new Date(banner.start_date_and_time) : null,
      end_date_and_time: banner?.end_date_and_time ? new Date(banner.end_date_and_time) : null,
      roles: banner?.roles?.map(({ id, name }) => ({ label: name, value: id })) || [],
      groups: banner?.groups?.map(({ id, name }) => ({ label: name, value: id })) || [],
      is_view_more: banner?.is_view_more || false,
      view_more_link: banner?.view_more_link || '',
    }),
    [banner],
  );

  const form = useForm<z.infer<typeof bannerSchema>>({
    resolver: zodResolver(bannerSchema),
    mode: 'onChange',
    defaultValues,
  });

  const startDate = form.watch('start_date_and_time');
  const selectedDistrict = form.watch('district');
  const isViewMore = form.watch('is_view_more');
  const isAppliedToAll = form.watch('is_apply_for_all_districts');

  const { data: roles, isFetching: isRolesFetching } = useRoleSearch({
    requestParameters: { district: selectedDistrict, search: debouncedSearchRole },
    enabled: isOpen,
  });

  const rolesOptions = useMemo(() => roles?.results.map((role) => ({
    value: role.id,
    label: role.name,
    district: role.district,
  })) ?? [], [roles]);

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your banner text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content:
      form.getValues('body') === ''
        ? ''
        : (JSON.parse(form.getValues('body') ?? '')),
    editable: isOpen,
    onUpdate: ({ editor }) => {
      const isEmpty = editor.getText().trim().length === 0;
      form.setValue('body', isEmpty ? '' : JSON.stringify(editor.getJSON()), { shouldValidate: true });
    },
  });

  useEffect(() => {
    form.reset(defaultValues);
    editorConfig?.commands.setContent(typeof defaultValues.body === 'string' && defaultValues.body !== '' ? JSON.parse(defaultValues.body) : '');
  }, [defaultValues, form, editorConfig]);

  useEffect(() => {
    if (banner) {
      editorConfig?.commands.setContent(typeof banner.body === 'string' && banner.body !== '' ? JSON.parse(banner.body) : '');
    }
  }, [banner, editorConfig]);

  const toggleModalHandler = (open: boolean) => {
    if (!open) {
      form.reset(defaultValues);
      editorConfig?.commands.setContent('');
      onClose?.();
    }
    toggleModal(open);
  };

  const saveClickHandler = (formData: z.infer<typeof bannerSchema>, isDraft = false) => {
    onSubmit?.(formData, isDraft);

    const body = {
      status: isDraft ? BannerStatus.DRAFT : BannerStatus.PUBLISHED,
      is_apply_for_all_districts: formData.is_apply_for_all_districts,
      ...(!formData.is_apply_for_all_districts && {
        district: formData.district ?? undefined,
      }),
      type: formData.type!,
      title: formData.title,
      body: formData.body,
      start_date_and_time: formData.start_date_and_time!.toISOString(),
      end_date_and_time: formData.end_date_and_time!.toISOString(),
      roles: formData.roles ?? [],
      groups: formData.groups ?? [],
      is_view_more: formData.is_view_more,
      ...(formData.is_view_more && { view_more_link: formData.view_more_link }),
    };

    if (banner?.id) {
      updateBanner({
        id: banner.id,
        ...body,
        roles: body.roles.map(({ value }) => value),
        groups: body.groups.map(({ value }) => value),
      }, {
        onSuccess: () => {
          toggleModalHandler(false);
        },
      });
      return;
    }

    createBanner({
      ...body,
      roles: body.roles.map(({ value }) => value),
      groups: body.groups.map(({ value }) => value),
    }, {
      onSuccess: () => {
        toggleModalHandler(false);
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] max-w-[840px] flex-col">
        <DialogHeader className="flex flex-row items-start justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className="leading-7">
              {banner ? 'Update' : 'New'}
              {' '}
              Banner Notification
            </DialogTitle>
            <DialogDescription className="text-grey-600">
              Banner notification will appear for designated districts and users.
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <div className="w-full p-6 pr-[30px]">
            <Form providerProps={form} className="grid w-full grid-cols-2 gap-4">
              {banner === null && (
                <FormField
                  control={form.control}
                  name="is_apply_for_all_districts"
                  render={({ field }) => (
                    <FormItem className="col-span-2 flex gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          {...field}
                          value={field.value.toString()}
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked);
                            form.setValue('district', null);
                          }}
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="text-sm font-medium">
                          Apply for all districts
                        </FormLabel>
                        <FormDescription className="text-sm font-normal">
                          Check if you want to apply this banner for all districts in the system based on the roles and groups
                        </FormDescription>
                      </div>
                    </FormItem>
                  )}
                />
              )}
              <FormField
                control={form.control}
                name="district"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel required={!isAppliedToAll}>District</FormLabel>
                    <ComboBox modal>
                      <FormControl>
                        <ComboBoxTrigger
                          disabled={isAppliedToAll || banner !== null}
                          isError={!!fieldState.error}
                          placeholder="Select district"
                          selectedLabel={districtOptions.find(({ value }) => value === field.value)?.label}
                        />
                      </FormControl>
                      <ComboBoxContent
                        loading={isDistrictFetching}
                        searchValue={districtSearch}
                        onSearchChange={setDistrictSearch}
                        shouldFilter={false}
                        searchPlaceholder="Search by district name"
                        emptyContent="District not found."
                      >
                        {districtOptions.map(({ value, label, subdomain }) => (
                          <ComboBoxItem
                            key={value}
                            value={value.toString()}
                            selected={value === field.value}
                            onSelect={(selectedValue) => field.onChange(Number(selectedValue))}
                            asChild
                          >
                            <div className="flex items-center gap-2">
                              {field.value === value && (
                                <PurpleIcon
                                  name="check"
                                  className="text-brand-blue-700 absolute left-4 top-1/2 size-4 -translate-y-1/2"
                                />
                              )}
                              <div className="flex flex-col gap-1">
                                <strong className="text-grey-950 line-clamp-1 text-xs font-semibold">
                                  {label}
                                </strong>
                                <span className="text-grey-600 line-clamp-1 text-xs">{subdomain}</span>
                              </div>
                            </div>
                          </ComboBoxItem>
                        ))}
                      </ComboBoxContent>
                    </ComboBox>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="type"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel required>Type</FormLabel>
                    <ComboBox modal>
                      <FormControl>
                        <ComboBoxTrigger
                          isError={!!fieldState.error}
                          placeholder="Select type"
                          selectedLabel={BANNER_TYPE_OPTIONS.find(({ value }) => value === field.value)?.label}
                        />
                      </FormControl>
                      <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                        {BANNER_TYPE_OPTIONS.map(({ value, label }) => (
                          <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                            {label}
                          </ComboBoxItem>
                        ))}
                      </ComboBoxContent>
                    </ComboBox>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="title"
                render={({ field, fieldState }) => (
                  <FormItem className="col-span-2">
                    <FormLabel required>Title</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        isError={!!fieldState.error}
                        placeholder="Enter short title for the banner"
                        responsiveHeight
                        className="min-h-[60px] resize-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="start_date_and_time"
                render={({ field, fieldState }) => (
                  <FormItem className="w-full">
                    <FormLabel required>Start Date & Time</FormLabel>
                    <FormControl>
                      <DatePicker
                        mode="single"
                        placeholder="Select date & time"
                        formatterString="PPP p"
                        isError={!!fieldState.error}
                        triggerDisabled={field.disabled}
                        defaultMonth={field.value ?? undefined}
                        selected={field.value ?? undefined}
                        disabled={banner === null && { before: new Date() }}
                        captionLayout="dropdown"
                        contentClassName="w-80"
                        onDayClick={(date) => field.onChange(isToday(date) ? addMinutes(new Date(), 1) : date)}
                        onTimeChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="end_date_and_time"
                render={({ field, fieldState }) => (
                  <FormItem className="w-full">
                    <FormLabel required>End Date & Time</FormLabel>
                    <FormControl>
                      <DatePicker
                        mode="single"
                        placeholder="Select date & time"
                        formatterString="PPP p"
                        isError={!!fieldState.error}
                        triggerDisabled={field.disabled}
                        defaultMonth={field.value ?? undefined}
                        selected={field.value ?? undefined}
                        disabled={{ before: startDate || new Date() }}
                        contentClassName="w-80"
                        captionLayout="dropdown"
                        onDayClick={(date) => field.onChange(isToday(date) ? addMinutes(new Date(), 1) : date)}
                        onTimeChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="roles"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Target Audience (Roles)</FormLabel>
                    <FormControl>
                      <MultiSelect<number>
                        isError={!!fieldState.error}
                        selectedOptions={field.value}
                        showSearch
                        searchValue={roleSearch}
                        loading={isRolesFetching}
                        shouldFilter={false}
                        searchPlaceholder="Search by role name"
                        placeholder="Select role(s)"
                        modalPopover
                        onOptionChange={(_, selected) => field.onChange(selected)}
                        onSearchChange={setRoleSearch}
                      >
                        {rolesOptions.map((option) => (
                          <MultiSelectItem<number>
                            key={option.value}
                            value={option.value}
                            option={option}
                            customContent
                          >
                            <>
                              {field.value?.some(({ value }) => value === option.value) && (
                                <PurpleIcon
                                  name="check"
                                  className="text-brand-blue-700 absolute left-4 top-1/2 size-4 -translate-y-1/2"
                                />
                              )}
                              <div className="flex flex-col gap-1">
                                <strong className="text-grey-950 line-clamp-1 text-xs font-semibold">
                                  {option.label}
                                </strong>
                                <span className="text-grey-600 line-clamp-1 text-xs">{option.district}</span>
                              </div>
                            </>
                          </MultiSelectItem>
                        ))}
                      </MultiSelect>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="groups"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Target Audience (Groups)</FormLabel>
                    <FormControl>
                      <MultiSelect
                        isError={!!fieldState.error}
                        selectedOptions={field.value}
                        showSearch
                        searchValue={groupSearch}
                        loading={isGroupsFetching}
                        shouldFilter={false}
                        searchPlaceholder="Search by group name"
                        placeholder="Select group(s)"
                        modalPopover
                        onOptionChange={(_, selected) => field.onChange(selected)}
                        onSearchChange={setGroupSearch}
                      >
                        {groupsOptions.map((option) => (
                          <MultiSelectItem
                            key={option.value}
                            value={option.value}
                            option={option}
                          />
                        ))}
                      </MultiSelect>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="body"
                render={({ field, fieldState }) => (
                  <FormItem className="col-span-2 space-y-2">
                    <FormLabel>Body</FormLabel>
                    <BodyToolbar editor={editorConfig} />
                    <FormControl>
                      <EditorContent
                        ref={field.ref}
                        editor={editorConfig}
                        className={cn(
                          'minimal-tiptap-editor flex min-h-[140px] w-full cursor-text flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 focus-within:border-brand-blue-700 hover:border-brand-blue-700 focus:border-brand-blue-700',
                          {
                            'border-error-main': !!fieldState.error,
                          },
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="is_view_more"
                render={({ field }) => (
                  <FormItem className="col-span-2 flex gap-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        {...field}
                        value={field.value.toString()}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-sm font-medium">Show “View more”</FormLabel>
                      <FormDescription className="text-sm font-normal">
                        Check if user should be shown a link to navigate to Notification Page to view more information
                      </FormDescription>
                    </div>
                  </FormItem>
                )}
              />
              {isViewMore && (
                <FormField
                  control={form.control}
                  name="view_more_link"
                  render={({ field, fieldState }) => (
                    <FormItem className="col-span-2 space-y-2">
                      <FormLabel required>
                        View More Link
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          isError={!!fieldState.error}
                          placeholder="Enter URL"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </Form>
          </div>
        </ScrollArea>
        <Separator />
        <DialogFooter className="justify-between">
          <OfflineController>
            <Button
              type="button"
              variant="secondary"
              isLoading={isLoading}
              onClick={form.handleSubmit((data) => saveClickHandler(data, true))}
            >
              {isLoading ? 'Saving...' : 'Save as Draft'}
            </Button>
          </OfflineController>
          <div className="flex items-center gap-4">
            <Button type="button" variant="tertiary" onClick={() => toggleModalHandler(false)}>Cancel</Button>
            <OfflineController>
              <Button
                type="button"
                variant="primary"
                isLoading={isLoading}
                onClick={form.handleSubmit((data) => saveClickHandler(data, false))}
              >
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
            </OfflineController>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
