export const DURATION_FILTERS = [
  {
    id: '0-30',
    name: 'up to 30 minutes',
  },
  {
    id: '30-60',
    name: '30 minutes to 1 hour',
  },
  {
    id: '60+',
    name: '1 hour or more',
  },
] as const;
