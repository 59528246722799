import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { getActivityDetails } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActivityDetails } from '../activities.types';

export const useActivityDetails = (
  activityId: string | number,
  queryOptions?: TCustomUseQueryOptions<TActivityDetails, AxiosError>,
) => {
  const queryData = useQuery({
    queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_DETAILS, activityId.toString()],
    queryFn: () => getActivityDetails(activityId),
    ...queryOptions,
  });

  useEffect(() => {
    if (queryData.isError && isClientError(queryData.error)) {
      showErrorToast('System message', 'Unable to retrieve activity details');
    }
  }, [queryData.isError, queryData.error]);

  return queryData;
};
