import * as z from 'zod';
import { tagSchema, tagsSchema } from '@purple/shared-utils';

export const createCustomTagSchema = z.object({
  name: z.string().trim().min(1, { message: 'Tag name is required' }).max(100, { message: 'Tag name is too long' }),
  color: z.string().trim().min(1, { message: 'Tag color is required' }).max(100, { message: 'Tag color is too long' }),
  apply_to_all: z.boolean().default(false),
  __existing_names: z.array(z.string()).default([]),
}).superRefine((data, ctx) => {
  if (data.__existing_names.some((name) => name.trim().toLowerCase() === data.name.trim().toLowerCase())) {
    ctx.addIssue({
      path: ['name'],
      code: z.ZodIssueCode.custom,
      message: 'Tag name already exists',
    });
  }
});

export const updateCustomTagsSchema = tagsSchema.extend({
  __all_tags: z.array(tagSchema),
}).superRefine((data, ctx) => {
  for (const [index, tag] of data.tags.entries()) {
    if (data.__all_tags.some(({ name, id }) => tag.id !== id && name.trim().toLowerCase() === tag.name.trim().toLowerCase())) {
      ctx.addIssue({
        path: ['tags', index, 'name'],
        code: z.ZodIssueCode.custom,
        message: 'Tag name already exists',
      });
    }
  }
});
