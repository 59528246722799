import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import * as z from 'zod';
import { onlyLettersWithSpacesRegex } from '@purple/shared-utils';
import { Form } from '@purple/ui';
import { CONTACTS_QUERY_KEYS, useUpdateContactDetails } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ContactSectionHeader } from '../../components';
import { AddressSectionContent } from './AddressSectionContent';
import { AddressSectionFormContent } from './AddressSectionFormContent';
import type { TContactDetailItem } from '~/services';

const addressSectionSchema = z.object({
  country: z.string().trim().optional(),
  street: z.string().trim().optional(),
  city: z.string().trim().regex(onlyLettersWithSpacesRegex, { message: 'City can only contain letters' }).optional(),
  state: z.string().trim().optional(),
  postal_code: z.string().trim().optional(),
});

export type TContactAddressDetailsSchema = z.infer<typeof addressSectionSchema>;

export const AddressSection = () => {
  const { contactId } = useParams();
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = useState(false);

  const contactDetails = queryClient.getQueryData<TContactDetailItem>([CONTACTS_QUERY_KEYS.GET_CONTACT_DETAILS, contactId]);

  const { mutate: updateContact, isPending } = useUpdateContactDetails();

  const defaultValues: TContactAddressDetailsSchema = useMemo(() => ({
    country: 'united_states',
    street: contactDetails?.address?.street ?? '',
    city: contactDetails?.address?.city ?? '',
    state: contactDetails?.address?.state ?? '',
    postal_code: contactDetails?.address?.postal_code ?? '',
  }), [contactDetails]);

  const form = useForm<TContactAddressDetailsSchema>({
    mode: 'onChange',
    resolver: zodResolver(addressSectionSchema),
    defaultValues,
  });

  const updateContactAddressDetails = useCallback((formData: TContactAddressDetailsSchema) => {
    if (!contactId) {
      showErrorToast('System message', `Can not update contact with id: ${contactId}`);
      return;
    }

    updateContact({
      id: contactId,
      address: formData,
    }, {
      onSuccess: () => {
        setIsEditing(false);
      },
    });
  }, [contactId, updateContact]);

  const editChangeHandler = useCallback(() => {
    setIsEditing((prev) => !prev);
  }, []);

  useEffect(() => {
    form.reset(defaultValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <div className="flex w-full flex-col gap-4">
      <ContactSectionHeader
        title="Address Details"
        editing={isEditing}
        onEdit={editChangeHandler}
        onCancel={editChangeHandler}
        onSave={form.handleSubmit(updateContactAddressDetails)}
        loading={isPending}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <AddressSectionFormContent />
            </Form>
          )
        : <AddressSectionContent />}
    </div>
  );
};
