import { type FC, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, Button, DateRangePicker, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Heading, SearchInput, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { AddContactModal, BulkImportContactsModal, DataTable, DataTableSelectedController, DataTableViewOptions } from '~/components';
import { ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { PRINT_OPTIONS } from '~/pages/System';
import { PRINT_QUERY_KEYS, useContacts, useContactsId, usePrint } from '~/services';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { AddToExistingListDialog, ManagePriorityListDialog, SendEmailDialog } from '../../components';
import { PriorityListDropDown } from './components';
import { contactsColumns } from './useContactsColumns';
import { useContactsFilterOptions } from './useContactsFilterOptions';
import type { DateRange } from '@purple/ui';

export const ContactsTab: FC = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const { openModal: openAddContactModal } = useModal(ModalType.ADD_CONTACT);
  const { openModal: openBulkContactsImportModal } = useModal(ModalType.BULK_IMPORT_CONTACTS);
  const { toggleModal: toggleEmailModal } = useModal(ModalType.SEND_EMAIL_TO_CONTACTS);
  const { toggleModal: toggleCreateListModal } = useModal(ModalType.MANAGE_CONTACTS_PRIORITY_LIST);
  const { toggleModal: toggleAddToListModal } = useModal(ModalType.ADD_TO_EXISTING_CONTACTS_PRIORITY_LIST);

  const { filterConfig, isFiltersLoading } = useContactsFilterOptions();

  const { onClearSearch, onSearchChange, search, debounceSearch } = useSearch();

  const initialDateFrom = searchParameters.get('created_at__gte') ?? undefined;
  const initialDateTo = searchParameters.get('created_at__lte') ?? undefined;

  const contactsRequestParams = useMemo(() => ({
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    district: searchParameters.get('district'),
    type: searchParameters.get('type'),
    created_at__gte: initialDateFrom,
    created_at__lte: initialDateTo,
  }), [debounceSearch, searchParameters, initialDateFrom, initialDateTo]);

  const { data: contactsList, isLoading } = useContacts(contactsRequestParams);

  const { mutate: selectAllAvailable, isPending } = useContactsId();
  const { mutate, isPending: isPrintPending } = usePrint(PRINT_QUERY_KEYS.ADMIN_CONTACTS_LIST);

  const contacts = useMemo(() => contactsList?.results || [], [contactsList]);

  const { table } = useDataTable({
    name: TableName.CONTACTS,
    columns: contactsColumns,
    data: contacts,
    rowCount: contactsList?.count || 0,
    getRowId: (row) => row.id,
    onSelectionChange: (rows, setSelectedRows) => {
      const selectedIds = Object.entries(rows).flatMap(([id, selected]) => (selected ? [id] : []));
      setSelectedRows(
        (prevRows) => [
          ...prevRows,
          ...contacts.map(({ id, email, district, full_name }) => ({ id, name: full_name ?? email, district })),
        ]
          .filter((activity, index, self) => index === self.findIndex((a) => a.id === activity.id))
          .filter((activity) => selectedIds.includes(activity.id)),
      );
    },
  });

  const selectedRows = useMemo(() => table.options.meta?.selectedRows ?? [], [table.options.meta?.selectedRows]);
  const isSameDistrict = useMemo(() => selectedRows.length > 0 && selectedRows.every((contact) => contact.district.id === selectedRows[0]?.district.id), [selectedRows]);
  const selectedDistrict = useMemo(() => isSameDistrict ? selectedRows[0]?.district?.id ?? null : null, [isSameDistrict, selectedRows]);

  const updateDateRange = useCallback((range: DateRange) => {
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.set('created_at__gte', format(range.from, 'yyyy-MM-dd'));
      range.to && newSearchParameters.set('created_at__lte', format(range.to, 'yyyy-MM-dd'));
      return newSearchParameters;
    });
  }, [setSearchParameters]);

  const removeDateRange = useCallback(() => {
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.delete('created_at__gte');
      newSearchParameters.delete('created_at__lte');
      return newSearchParameters;
    });
  }, [setSearchParameters]);

  const selectAllUsers = useCallback(() => {
    selectAllAvailable({
      limit: contactsList?.count,
      offset: 0,
      search: debounceSearch,
      district: searchParameters.get('district'),
      type: searchParameters.get('type'),
      created_at__gte: initialDateFrom,
      created_at__lte: initialDateTo,
    }, {
      onSuccess: (data) => {
        const selectedRows = data.results.reduce<Record<string, boolean>>((acc, item) => ({
          ...acc,
          [item.id]: true,
        }), {}) ?? {};
        table.setRowSelection(selectedRows);
        table.options.meta?.setSelectedRows?.(data.results.map(({ id, email, district }) => ({ id, name: email, district })));
      },
      onError: (error) => {
        if (isClientError(error)) {
          showErrorToast('System message', 'Failed to select all contacts');
        }
      },
    });
  }, [selectAllAvailable, contactsList?.count, table, debounceSearch, searchParameters, initialDateFrom, initialDateTo]);

  const printContactsHandler = useCallback(() => {
    mutate({
      variant: PRINT_OPTIONS.CONTACTS_LIST,
      scale: 1,
      landscape: 'true',
      ...contactsRequestParams,
    });
  }, [mutate, contactsRequestParams]);

  return (
    <div className="flex flex-col gap-4">
      <div className="border-b-grey-200 flex w-full items-center justify-between gap-4 border-b px-6 py-5">
        <Heading tag="h2" variant="size-18" type="heading-600" className="leading-[25px]">
          Contacts
        </Heading>
        <DropdownRoot>
          <DropdownTrigger asChild className="[&>svg]:data-[state=open]:rotate-180">
            <Button type="button" variant="primary" iconRight={<PurpleIcon name="chevron-down" className="transition-transform" />}>Add Contacts</Button>
          </DropdownTrigger>
          <DropdownContent align="end" className="w-48">
            <DropdownItem iconName="user-add" onClick={openAddContactModal}>
              Add Contact
            </DropdownItem>
            <DropdownItem iconName="clipboard-copy" onClick={openBulkContactsImportModal}>
              Bulk Import
            </DropdownItem>
          </DropdownContent>
        </DropdownRoot>
      </div>
      <DataTable table={table} loading={isLoading}>
        <div className="flex flex-col gap-4 px-4 pb-6">
          <div className="flex flex-wrap items-center justify-between gap-2">
            <div className="flex items-center gap-4">
              <AppFilters config={filterConfig} loading={isFiltersLoading} />
              <DateRangePicker onUpdate={updateDateRange} align="start" triggerClassNames="h-10 w-64" onClear={removeDateRange} hasClearButton initialDateFrom={initialDateFrom} initialDateTo={initialDateTo} />
              <SearchInput
                value={search}
                onChange={onSearchChange}
                onClear={onClearSearch}
                placeholder="Search..."
                className="w-64"
              />
            </div>
            <div className="flex items-center gap-4">
              <Button variant="secondary" iconLeft={<PurpleIcon name="mail" />} onClick={() => toggleEmailModal(true)} disabled={selectedRows.length === 0}>
                Send Email
              </Button>
              <PriorityListDropDown disabled={!isSameDistrict} createNewClick={() => toggleCreateListModal(true)} addToExistingClick={() => toggleAddToListModal(true)} />
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="secondary"
                    size="icon_40"
                    className="size-10 shrink-0"
                    iconLeft={<PurpleIcon name="download" />}
                    disabled={contacts.length === 0}
                    // isLoading={isExportPending}
                    // onClick={() => {}}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Export Table to Excel</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="secondary"
                    size="icon_40"
                    className="size-10 shrink-0"
                    isLoading={isPrintPending}
                    onClick={printContactsHandler}
                    iconLeft={<PurpleIcon name="printer" />}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Print Table</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <DataTableViewOptions table={table} />
            </div>
          </div>
          <DataTableSelectedController table={table} isSelectAllLoading={isPending} onSelectAll={selectAllUsers} />
        </div>
      </DataTable>
      <AddContactModal />
      <BulkImportContactsModal />
      <ManagePriorityListDialog
        contacts={selectedRows.map(({ id }) => id)}
        districtId={selectedDistrict}
        onSuccess={table.options.meta?.clearSelectedRows}
      />
      <AddToExistingListDialog
        contacts={selectedRows.map(({ id }) => id)}
        districtId={selectedDistrict}
        onSuccess={table.options.meta?.clearSelectedRows}
      />
      <SendEmailDialog contacts={Object.keys(table.getState().rowSelection)} />
    </div>
  );
};
