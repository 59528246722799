import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { CallToActionModal } from '~/components';
import { AdminRoutes, ModalType } from '~/constants';
import { useAppDispatch, useAppSelector, useModal } from '~/hooks';
import { useUnsavedChanges } from '~/providers';
import { useUpdateResponsibleForSetup } from '~/services';
import {
  districtIdSelector,
  responsibleForSetupSelector,
  setResponsibleForSetup,
} from '~/store/features/district-set-up';
import { userSelector } from '~/store/features/user';
import { showErrorToast } from '~/utils/toasts';
import { useManagePublishDistrict } from '../../useManagePublishDistrict';

type TSetUpHeaderProperties = {
  onSave: () => void;
  isSaving?: boolean;
  canSaveAsDraft: boolean;
};

const SetUpHeader = ({ canSaveAsDraft, onSave, isSaving = false }: TSetUpHeaderProperties) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { checkUnsaved } = useUnsavedChanges();

  const user = useAppSelector(userSelector);
  const responsibleForSetupId = useAppSelector(responsibleForSetupSelector);
  const districtId = useAppSelector(districtIdSelector);

  const { openModal: openSetUpTakeControlModal, closeModal: closeSetUpTakeControlModal } = useModal(
    ModalType.SET_UP_TAKE_CONTROL,
  );

  const { mutate: updateResponsible, isPending: isResponsiblePending } = useUpdateResponsibleForSetup();

  const { canPublish, publish, isPublishing, publishMessage } = useManagePublishDistrict(districtId);

  // Open take control modal when user is not responsible for setup
  useEffect(() => {
    if (Boolean(districtId) && Boolean(user) && user?.id !== responsibleForSetupId) {
      openSetUpTakeControlModal();
    }
  }, [districtId, openSetUpTakeControlModal, responsibleForSetupId, user]);

  const backToListClickHandler = () => {
    checkUnsaved(() => navigate(AdminRoutes.App.Districts.Root.path));
  };

  const takeControlClickHandler = () => {
    if (districtId) {
      updateResponsible(
        { districtId, responsible_for_setup: user?.id.toString() },
        {
          onSuccess: (responsive) => {
            if (responsive?.responsible_for_setup) {
              dispatch(setResponsibleForSetup(responsive?.responsible_for_setup));
              closeSetUpTakeControlModal();
            }
          },
        },
      );
    } else {
      showErrorToast('System message', 'Could not find district id. Check the provided information and try again');
    }
  };

  const cancelTakeControlClickHandler = () => {
    backToListClickHandler();
  };

  return (
    <div className="absolute left-0 top-0 flex w-full items-center justify-between border-b border-grey-300 bg-white px-8 py-[14px]">
      <CallToActionModal
        modalName={ModalType.SET_UP_TAKE_CONTROL}
        modalTitle="Take control"
        modalDescription="Another user is already editing the district. Do you want to take control of the setup?"
        modalTextContent="Another user is already editing the district. Do you want to take control of the setup?"
        primaryButtonText="Take it over"
        secondaryButtonText="Cancel"
        primaryButtonVariant="primary"
        onPrimaryButtonClick={takeControlClickHandler}
        onSecondaryButtonClick={cancelTakeControlClickHandler}
        isLoading={isResponsiblePending}
      />
      <div className="flex items-center gap-2">
        <Button
          iconLeft={<PurpleIcon name="chevron-left" />}
          size="icon_40"
          variant="tertiary_icon_only"
          onClick={backToListClickHandler}
        />
        <Text variant="size-16" type="body-600" className="text-grey-950">
          New District
        </Text>
      </div>
      <div className="flex gap-4">
        <Button variant="secondary" disabled={!canSaveAsDraft || isSaving} isLoading={isSaving} onClick={onSave}>
          Save as Draft
        </Button>
        <Tooltip>
          <TooltipTrigger asChild>
            <div>
              <Button variant="primary" disabled={!canPublish} onClick={publish} isLoading={isPublishing}>
                Publish
              </Button>
            </div>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>{publishMessage}</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
    </div>
  );
};

export { SetUpHeader };
