import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSubmitterTypeFieldSettings } from './get-submitter-type-field-settings';
import { ADMIN_SUBMITTER_TYPES_QUERY_KEYS } from './submitterTypes.const';
import type { AxiosError } from 'axios';
import type { TAdminSubmitterTypeFieldSettings } from './submitterTypes.types';

type TUseSubmitterTypeFieldSettingsProperties = {
  typeId?: string;
};

export const useSubmitterTypeFieldSettings = ({
  typeId,
}:
TUseSubmitterTypeFieldSettingsProperties) => {
  const id = typeId || '';

  const { isError, error, ...rest } = useQuery<TAdminSubmitterTypeFieldSettings, AxiosError>({
    queryKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.FIELD_SETTINGS, typeId],
    queryFn: () => getSubmitterTypeFieldSettings({ typeId: id }),
    enabled: !!typeId,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch submitter type field settings');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
