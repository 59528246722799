import { Heading, Separator, Skeleton, Text } from '@purple/ui';

const TextTranslationTabSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-6 py-5">
        <div className="flex flex-col gap-0">
          <Heading variant="size-18" type="heading-600" className="text-grey-950">Text Translation</Heading>
          <Text variant="size-16" type="body-400" className="text-grey-600">You can update and manage translations for SAF messages and texts.</Text>
        </div>
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex items-center justify-between px-6 py-5">
        <Skeleton className="h-[300px] w-full" />
      </div>
    </div>
  );
};

export { TextTranslationTabSkeleton };
