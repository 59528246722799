import { useMemo, useState } from 'react';
import { NoDataAvailable } from '@purple/ui';
import { useStudentVisualizerSetupState } from '~/services';
import { VisualizerDistrictSelector, VisualizerHeader, VisualizerSetupList, VisualizerSetupSkeleton } from './components';

export const StudentVisualizerPage: React.FC = () => {
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(true);
  const [selectedDistrict, setSelectedDistrict] = useState<string | null>(null);

  const { data: setupStates, isFetching } = useStudentVisualizerSetupState({ enabled: selectedDistrict !== null, districtId: selectedDistrict });

  const showBanner = useMemo(() =>
    isBannerVisible
    && !!setupStates
    && (
      !setupStates.is_absences_visualizer_configured
      || !setupStates.is_color_trends_configured
      || !setupStates.is_color_trends_pull_dates_configured
      || !setupStates.is_data_table_view_configured
      || !setupStates.is_subpopulation_filters_configured
    ), [isBannerVisible, setupStates]);

  const districtChangeHandler = (value: string) => {
    setSelectedDistrict(value);
    setIsBannerVisible(true);
  };

  const bannerCloseClickHandler = () => {
    setIsBannerVisible(false);
  };

  return (
    <div className="flex w-full flex-1 flex-col gap-6">
      <VisualizerHeader isBannerVisible={showBanner} onBannerClose={bannerCloseClickHandler} />
      <section className="flex size-full flex-col gap-6 rounded-lg border border-grey-200 bg-white p-6">
        <VisualizerDistrictSelector selectedDistrictId={selectedDistrict} onDistrictChange={districtChangeHandler} />
        {isFetching && <VisualizerSetupSkeleton />}
        {!setupStates && !isFetching && (
          <NoDataAvailable iconName="folder-open" title="No Data Found" description="Please select a district to view data" className="min-h-96" />
        )}
        {setupStates && !isFetching && (
          <VisualizerSetupList setupState={setupStates} />
        )}
      </section>
    </div>
  );
};
