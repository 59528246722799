import { APP_PERMISSIONS, type TAppPermissions } from '@purple/permissions';
import { AdminRoutes } from './routes';
import type { PurpleIconType } from '@purple/icons';

export type TNavigationSubmenu = {
  href: string;
  label: string;
  active: boolean;
  /**
   * Permissions required to access the submenu
   */
  permissions?: TAppPermissions[];
};

export type TNavigationMenu = {
  href: string;
  label: string;
  active: boolean;
  iconName: PurpleIconType;
  /**
   * Permissions required to access the menu - should include all permissions required to access submenus
   * Because we need to hide the menu if user doesn't have access to any of the submenus
   */
  permissions?: TAppPermissions[];
  submenus: TNavigationSubmenu[];
};

export type TNavigationGroup = {
  groupLabel: string;
  menus: TNavigationMenu[];
};

export const getNavigationList = (pathname: string): TNavigationGroup[] => [
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Home.Root.path,
        label: 'Home Page',
        active: pathname === AdminRoutes.App.Home.Root.path,
        iconName: 'home',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Districts.Root.path,
        label: 'Districts',
        active: pathname.includes(AdminRoutes.App.Districts.Root.path),
        iconName: 'disctrict',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Schools.Root.path,
        label: 'Schools',
        active: pathname.includes(AdminRoutes.App.Schools.Root.path),
        iconName: 'School',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: '',
        label: 'User Management',
        active: pathname.includes(AdminRoutes.App.Users.Root.path),
        iconName: 'users',
        permissions: [APP_PERMISSIONS.CAN_ACCESS_ADMIN_USERS_PERMISSIONS],
        submenus: [
          {
            href: AdminRoutes.App.Users.Root.path,
            label: 'Users',
            active: pathname === AdminRoutes.App.Users.Root.path,
          },
          {
            href: AdminRoutes.App.Users.Permissions.Root.path,
            label: 'Permissions Groups',
            active: pathname === AdminRoutes.App.Users.Permissions.Root.path,
            permissions: [APP_PERMISSIONS.CAN_ACCESS_ADMIN_USERS_PERMISSIONS],
          },
          {
            href: AdminRoutes.App.Users.Roles.Root.path,
            label: 'Roles',
            active: pathname === AdminRoutes.App.Users.Roles.Root.path,
            permissions: [APP_PERMISSIONS.CAN_ACCESS_ADMIN_USERS_PERMISSIONS],
          },
        ],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Contacts.Root.path,
        label: 'Contacts',
        active: pathname.includes(AdminRoutes.App.Contacts.Root.path),
        iconName: 'contact',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Dashboard.Root.path,
        label: 'Dashboard & Reports',
        active: pathname.includes(AdminRoutes.App.Dashboard.Root.path),
        iconName: 'Dashboard',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Visualizer.Student.path,
        label: 'Student Visualizer',
        active: pathname.includes(AdminRoutes.App.Visualizer.Student.path),
        iconName: 'Student',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Activities.Root.path,
        label: 'Community Centered Activities',
        active: pathname.includes(AdminRoutes.App.Activities.Root.path),
        iconName: 'user-group',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Actions.Student.Root.path,
        label: 'Student Centered Actions',
        active: pathname.includes(AdminRoutes.App.Actions.Student.Root.path),
        iconName: 'lightning-bolt',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Safs.Root.path,
        label: 'SAFs',
        active: pathname.includes(AdminRoutes.App.Safs.Root.path),
        iconName: 'saf',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Meetings.Root.path,
        label: 'Meetings',
        active: pathname.includes(AdminRoutes.App.Meetings.Root.path),
        iconName: 'chat',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.ServiceProviders.Root.path,
        label: 'Service Providers & Programs',
        active: pathname.includes(AdminRoutes.App.ServiceProviders.Root.path),
        iconName: 'Program',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.Surveys.Root.path,
        label: 'Surveys',
        active: pathname.includes(AdminRoutes.App.Surveys.Root.path),
        iconName: 'Survey',
        submenus: [],
      },
    ],
  },
  {
    groupLabel: '',
    menus: [
      {
        href: AdminRoutes.App.BannerNotifications.Root.path,
        label: 'Banner Notifications',
        active: pathname.includes(AdminRoutes.App.BannerNotifications.Root.path),
        iconName: 'notification',
        submenus: [],
      },
    ],
  },
];
