import { useFormContext } from 'react-hook-form';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage, Input, Text } from '@purple/ui';
import { countryOptions, usStates } from './constants';
import type { FC } from 'react';
import type { TAddContactFormValues } from './schema';

export const ContactAddressDetailsForm: FC = () => {
  const { control } = useFormContext<TAddContactFormValues>();
  return (
    <div className="grid grid-cols-4 gap-4">
      <Text variant="size-16" type="body-600" className="col-span-4 text-grey-title">Contact Details</Text>
      <FormField
        control={control}
        name="country"
        render={({ field, fieldState }) => (
          <FormItem className="col-span-2">
            <FormLabel>Country</FormLabel>
            <ComboBox modal>
              <FormControl>
                <ComboBoxTrigger
                  isError={!!fieldState.error}
                  placeholder="Select country"
                  selectedLabel={countryOptions.find((country) => country.value === field.value)?.label}
                  disabled
                />
              </FormControl>
              <ComboBoxContent
                shouldFilter
                searchPlaceholder="Search..."
                emptyContent="Country not found"
              >
                {countryOptions.map((country) => (
                  <ComboBoxItem key={country.value} value={country.value} onSelect={field.onChange}>
                    {country.label}
                  </ComboBoxItem>
                ))}
              </ComboBoxContent>
            </ComboBox>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="state"
        render={({ field, fieldState }) => (
          <FormItem className="col-span-2">
            <FormLabel>State</FormLabel>
            <ComboBox modal>
              <FormControl>
                <ComboBoxTrigger
                  isError={!!fieldState.error}
                  placeholder="Select state"
                  selectedLabel={usStates.find((state) => state.value === field.value)?.label}

                />
              </FormControl>
              <ComboBoxContent
                shouldFilter
                searchPlaceholder="Search..."
                emptyContent="State not found"
              >
                {usStates.map((state) => (
                  <ComboBoxItem key={state.value} value={state.value} keywords={[state.label]} onSelect={field.onChange}>
                    {state.label}
                  </ComboBoxItem>
                ))}
              </ComboBoxContent>
            </ComboBox>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="city"
        render={({ field }) => (
          <FormItem className="col-span-1">
            <FormLabel>City</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter city" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="postal_code"
        render={({ field }) => (
          <FormItem className="col-span-1">
            <FormLabel>Postal Code</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter postal code" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="street"
        render={({ field }) => (
          <FormItem className="col-span-2">
            <FormLabel>Street</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter street" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

    </div>
  );
};
