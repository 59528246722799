import { useEffect, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounceValue } from 'usehooks-ts';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { multiSelectOptions } from '@purple/shared-utils';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useContacts, usePriorityListBatchAddContacts } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import type React from 'react';
import type { TContactPriorityListItem } from '~/services';

const addToListSchema = z
  .object({
    contacts: multiSelectOptions.min(1, 'Contacts are required.'),
  });

type TAddContactsDialogProperties = {
  priorityList?: Pick<TContactPriorityListItem, 'id' | 'district'> | null;
  onSuccess?: () => void;
};

export const AddContactsDialog: React.FC<TAddContactsDialogProperties> = (props) => {
  const { priorityList = null, onSuccess } = props;

  const formId = useId();

  const [contactsSearch, setContactsSearch] = useState<string>('');
  const [debouncedSearchContacts] = useDebounceValue(contactsSearch, 500);

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.ADD_CONTACTS_TO_PRIORITY_LIST);

  const { data: contactsList, isFetching: isContactsFetching } = useContacts({
    search: debouncedSearchContacts,
    limit: 50,
    district: priorityList?.district?.id,
  });
  const { mutate: addContactsToPriorityList, isPending: isAddPending } = usePriorityListBatchAddContacts();

  const contactOptions = useMemo(() => (contactsList?.results || [])
    .map(({ id, full_name }) => ({
      label: full_name,
      value: id,
    })), [contactsList?.results]);

  const defaultValues = useMemo(
    () => ({
      contacts: [],
    }),
    [],
  );

  const form = useForm<z.infer<typeof addToListSchema>>({
    resolver: zodResolver(addToListSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const successCallback = () => {
    closeModal();
    form.reset(defaultValues);
    onSuccess?.();
  };

  const addContactsPriorityListHandler = (data: z.infer<typeof addToListSchema>) => {
    if (!priorityList) {
      return showErrorToast('System Error', 'Priority list not found.');
    }

    addContactsToPriorityList({
      id: priorityList.id,
      contacts: data.contacts.map(({ value }) => value),
    }, {
      onSuccess: successCallback,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>
            Add Contacts to Priority List
          </DialogTitle>
          <DialogDescription className="sr-only">
            Select contacts to add to the priority list and click the Add button.
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          id={formId}
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(addContactsPriorityListHandler)}
        >
          <FormField
            control={form.control}
            name="contacts"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Contacts</FormLabel>
                <FormControl>
                  <MultiSelect
                    isError={!!fieldState.error}
                    selectedOptions={field.value}
                    showSearch
                    searchValue={contactsSearch}
                    loading={isContactsFetching}
                    shouldFilter={false}
                    searchPlaceholder="Search by name"
                    placeholder="Select contact(s)"
                    modalPopover
                    onOptionChange={(_, selectedUsers) => field.onChange(selectedUsers)}
                    onSearchChange={setContactsSearch}
                  >
                    {contactOptions.map((option) => (
                      <MultiSelectItem
                        key={option.value}
                        value={option.value}
                        option={option}
                        isSelected={field.value?.some((item) => item.value === option.value)}
                      />
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} isLoading={isAddPending}>
            {isAddPending ? 'Adding...' : 'Add'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
