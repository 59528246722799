import { formatDateShortMonth, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminSafItem } from '~/services';

export const safsPrintColumns: ColumnDef<TAdminSafItem>[] = [
  {
    accessorKey: 'saf_number',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Saf Number" />,
    cell: ({ row: { original } }) => (original.saf_number ? <span>{original.saf_number}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 120,
  },
  {
    accessorKey: 'subject',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Subject" />,
    cell: ({ row: { original } }) => (original.subject ? <span>{original.subject}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 300,
  },
  {
    accessorKey: 'priority',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Priority" />,
    cell: ({ row: { original } }) => (original.priority ? <span className="capitalize">{original.priority}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 60,
  },
  {
    accessorKey: 'submitter__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Submitted By" />,
    cell: ({ row: { original } }) => (original.created_by && original.created_by.full_name && original.created_by.full_name.length > 0 ? <span>{original.created_by.full_name}</span> : '—'),
    enableSorting: false,
    enableHiding: false,

  },
  {
    accessorKey: 'current_owner__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Owner" />,
    cell: ({ row: { original } }) => (original.current_owner && original.current_owner.full_name && original.current_owner.full_name.length > 0 ? <span>{original.current_owner.full_name}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'school',
    header: ({ column }) => <DataTableColumnHeader column={column} title="School" />,
    cell: ({ row: { original } }) => (original.school && original.school.name.length > 0 ? <span>{original.school.name}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row: { original } }) => (original.status ? <span>{snakeToCamelWithSpaces(original.status)}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 60,
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created On" />,
    cell: ({ row: { original } }) => (original.created_at ? <span>{formatDateShortMonth(original.created_at)}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 120,
  },

];
