import { useMemo } from 'react';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { IMPERSONATE_QUERIES } from '@purple/shared-utils';
import { Button } from '@purple/ui';
import { ModalType } from '~/constants';
import { useCurrentUser, useModal } from '~/hooks';
import { useImpersonateResources, useImpersonateUser } from '~/services';
import { ImpersonatePlatformChooserDialog } from './ImpersonatePlatformChooserDialog';
import type { TImpersonateUserResource } from '~/services';

type TImpersonateButtonProperties = React.PropsWithChildren<{
  userId: string;
  disabled?: boolean;
}>;

export const ImpersonateButton: React.FC<TImpersonateButtonProperties> = (props) => {
  const { userId, disabled = false, children } = props;

  const { toggleModal } = useModal(ModalType.IMPERSONATE_PLATFORM_CHOOSER);

  const { mutate: impersonate, isPending } = useImpersonateUser();
  const { data: resources, isFetching } = useImpersonateResources(userId);

  const { user } = useCurrentUser();

  const allResources = useMemo(() => [...(resources?.districts ?? []), ...(resources?.service_providers ?? [])], [resources]);

  const startImpersonate = (resource: TImpersonateUserResource) => {
    impersonate({ user: userId, district: resource.id }, {
      onSuccess: (data) => {
        const protocol = import.meta.env.VITE_PURPLE_SENSE_DOMAIN.includes('localhost') ? 'http' : 'https';
        const platformUrl = `${protocol}://${data.subdomain}.${import.meta.env.VITE_PURPLE_SENSE_DOMAIN}?${IMPERSONATE_QUERIES.TOKEN}=${data.token}&${IMPERSONATE_QUERIES.REFRESH}=${data.refresh}`;
        window.open(platformUrl, '_blank');
      },
    });
  };

  const impersonateButtonClickHandler = () => {
    const isOnlyOnePlatform = allResources.length === 1;
    const [firstPlatform] = allResources;
    if (isOnlyOnePlatform && firstPlatform) {
      return startImpersonate(firstPlatform);
    }
    toggleModal(true);
  };

  const platformSelectHandler = (platform: TImpersonateUserResource) => {
    toggleModal(false);
    startImpersonate(platform);
  };

  if (user.id === userId) return null;

  return (
    <Guard requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_ADMIN_IMPERSONATE_USER]}>
      <Button
        type="button"
        variant="primary"
        disabled={disabled || isFetching || allResources.length === 0}
        isLoading={isPending}
        onClick={impersonateButtonClickHandler}
      >
        {children}
      </Button>
      <ImpersonatePlatformChooserDialog
        purpleSenseOptions={resources?.districts ?? []}
        serviceProviderOptions={resources?.service_providers ?? []}
        onPlatformSelect={platformSelectHandler}
      />
    </Guard>
  );
};
