import { useMutation } from '@tanstack/react-query';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { getContactsIdList } from './get-contactas-id-list';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TContactsIdListResponse } from './contacts.types';

export const useContactsId = (queryOptions?: TCustomUseMutationOptions<TContactsIdListResponse, AxiosError>) => {
  return useMutation({
    ...queryOptions,
    mutationKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_ID_LIST],
    mutationFn: getContactsIdList,
  });
};
