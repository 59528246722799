import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { deleteContactsPriorityList } from './delete-priority-list';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useDeleteContactsPriorityList = (
  options?: TCustomUseMutationOptions<unknown, AxiosError, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [CONTACTS_QUERY_KEYS.DELETE_CONTACTS_PRIORITY_LIST],
    mutationFn: deleteContactsPriorityList,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LISTS] });
      showSuccessToast('System message', 'Priority list deleted successfully');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to delete priority list');
      options?.onError?.(...args);
    },
  });
};
