import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSchoolsList } from './get-schools-list';
import { SCHOOLS_QUERY_KEYS } from './schools.const';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TSchoolListRequestParameters, TSchoolListResponse } from './schools.types';

export const useSchools = (
  parameters?: TSchoolListRequestParameters,
  queryOptions?: TCustomUseQueryOptions<TSchoolListResponse, AxiosError>,
) => {
  const { isError, ...rest } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_ALL_SCHOOLS_LIST, parameters],
    queryFn: () => getSchoolsList(parameters),
    ...queryOptions,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch list of schools');
    }
  }, [isError]);

  return { isError, ...rest };
};
