import { useId } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { DistrictUserStatus } from '@purple/shared-types';
import { Button, Checkbox, Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, ScrollArea, Separator, Text } from '@purple/ui';
import { UserFilterComboBox } from '~/components/UserFilterComboBox';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useTransferSaf } from '~/services';
import { transferSafSchema } from './schema';
import type { z } from 'zod';

type TTransferSafModalProperties = {
  safIds: string[];
};

const TransferSafModal = ({ safIds }: TTransferSafModalProperties) => {
  const formId = useId();
  const { isOpen, toggleModal } = useModal(ModalType.TRANSFER_SAF);

  const { mutate: transfer, isPending } = useTransferSaf();

  const form = useForm<z.infer<typeof transferSafSchema>>({
    mode: 'onChange',
    resolver: zodResolver(transferSafSchema),
    defaultValues: {
      owner: '',
      send_notification_email: false,
    },
  });

  const closeModalHandler = () => {
    form.reset();
    toggleModal(!isOpen);
  };

  const submitHandler = (data: z.infer<typeof transferSafSchema>) => {
    const payload = {
      safs: safIds,
      owner: data.owner,
      send_notification_email: data.send_notification_email,
    };

    transfer({ payload }, {
      onSuccess: () => {
        closeModalHandler();
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[564px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>Transfer SAF</DialogTitle>
            <DialogDescription>We will store initial owner for the notes & activities</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <Form
            providerProps={form}
            id={formId}
            className="flex w-full flex-col gap-6 p-6"
            onSubmit={form.handleSubmit(submitHandler)}
          >
            {safIds.length > 1 && (
              <Text variant="size-14" type="body-600" className="tet-grey-title">
                {safIds.length}
                {' '}
                records selected
              </Text>
            )}
            <FormField
              control={form.control}
              name="owner"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>New Owner</FormLabel>
                  <FormControl>
                    <UserFilterComboBox value={field.value} onChange={field.onChange} hasClearButton={false} status={DistrictUserStatus.ACTIVE} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="send_notification_email"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value={field.value.toString()}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="text-sm font-medium text-grey-950">
                    Send notification email
                  </FormLabel>
                </FormItem>
              )}
            />
          </Form>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModalHandler}>
            Cancel
          </Button>
          <Button type="submit" form={formId} isLoading={isPending} disabled={isPending}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { TransferSafModal };
