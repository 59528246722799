import axiosInstance from '../axios-config';
import { CONTACTS_ENDPOINTS } from '../endpoints';
import type { TContactsSendEmailPayload } from './contacts.types';

export const sendEmailToContacts = async (payload: TContactsSendEmailPayload) => {
  const { contact_priority_list, contacts, subject, message_html, message_json, attachments } = payload;

  const formData = new FormData();
  formData.append('subject', subject);
  formData.append('message_html', message_html);
  formData.append('message_json', message_json);

  for (const file of attachments) {
    formData.append('attachments', file);
  }

  if (contacts) {
    for (const contact of contacts) {
      formData.append('contacts', contact);
    }
  }

  if (contact_priority_list) {
    formData.append('contact_priority_list', contact_priority_list);
  }

  const { data } = await axiosInstance.post<unknown>(
    CONTACTS_ENDPOINTS.SEND_EMAIL,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return data;
};
