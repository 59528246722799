import { Link } from 'react-router-dom';
import { DistrictDetailsView } from '@purple/shared-types';
import { formatDateToLocalTime, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { NumberBadge, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import { ACTION_TYPES_TABS_VALUES } from '../../ActionTypesDetails/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TActionTypeItem } from '~/services';

export const actionTypesColumns: ColumnDef<TActionTypeItem>[] = [
  {
    accessorKey: 'type',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
    cell: ({ row: { original } }) => {
      return (
        original.type && original.type.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <Link
                    to={AdminRoutes.App.Actions.Student.ActionTypesDetails.makePath({ dynamicParameters: { id: original.id }, queries: {
                      tab: ACTION_TYPES_TABS_VALUES.basicDetails,
                    } })}
                    className="line-clamp-1 break-all text-brand-blue-700 transition-colors hover:text-brand-blue-900"
                  >
                    {snakeToCamelWithSpaces(original.type)}
                  </Link>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {snakeToCamelWithSpaces(original.type)}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    size: 230,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: 'is_active',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row: { original } }) => (
      <NumberBadge variant={original.is_active ? 'success-light' : 'danger'} className="rounded-full">
        {original.is_active ? 'Active' : 'Inactive'}
      </NumberBadge>
    ),
    size: 100,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: 'district',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row: { original } }) => {
      return (
        original.district && original.district.name.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <Link to={AdminRoutes.App.Districts.Details.makePath({ dynamicParameters: { id: original.district.id }, queries: { tab: DistrictDetailsView.DETAILS } })} className="line-clamp-1 break-all text-brand-blue-700 transition-colors hover:text-brand-blue-900">
                    {original.district.name}
                  </Link>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.district.name}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: 'usage',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Usage" />,
    cell: ({ row: { original } }) => {
      return (
        original.usage && original.usage.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.usage}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.usage}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    size: 400,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: 'last_modified_by',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Last Modified By" />,
    cell: ({ row: { original } }) => {
      return (
        original.last_modified_by && original.last_modified_by.full_name && original.last_modified_by.full_name.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <Link
                    to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: {
                      id: original.last_modified_by.id,
                    } })}
                    className="line-clamp-1 break-all text-brand-blue-700 transition-colors hover:text-brand-blue-800"
                  >
                    {original.last_modified_by.full_name}
                  </Link>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.last_modified_by.full_name}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: 'updated_at',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date Updated" />,
    cell: ({ row: { original } }) => (
      <span className="text-nowrap">{formatDateToLocalTime(original.updated_at)}</span>
    ),
    enableHiding: false,
    enableSorting: false,
  },
];
