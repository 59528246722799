import axiosInstance from '../axios-config';
import { SAF_ENDPOINTS } from '../endpoints';
import type { TAdminSafsListRequestParameters, TAdminSafsListResponse } from './saf.types';

export const getSafsList = async ({ token, ...parameters }: TAdminSafsListRequestParameters) => {
  const response = await axiosInstance.get<TAdminSafsListResponse>(SAF_ENDPOINTS.LIST, {
    params: parameters,
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
  });
  return response.data;
};
