import { useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { formatDateShortMonth, snakeToCamelWithSpaces } from '@purple/shared-utils';
import {
  Button,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Heading,
  NumberBadge,
} from '@purple/ui';
import { CallToActionModal } from '~/components';
import { AdminRoutes, ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useActivityDetails, useDeleteActivity } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ActivityInfoSkeleton } from './ActivityInfoSkeleton';
import { ActivityTags } from './ActivityTags';

export const ActivityInfo: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal(ModalType.DELETE_ACTIVITY);

  const { data: activity, isFetching } = useActivityDetails(id as string);
  const { mutate: deleteActivity, isPending: isDeletePending } = useDeleteActivity();

  const deleteActivitySubmitHandler = useCallback(() => {
    if (!activity) {
      return showErrorToast('Activity not found', 'Please select an activity to delete');
    }

    deleteActivity(activity.id, {
      onSuccess: () => {
        closeDeleteModal();
        navigate(AdminRoutes.App.Activities.Root.path, { replace: true });
      },
    });
  }, [activity, deleteActivity, closeDeleteModal, navigate]);

  if (isFetching || !activity) {
    return <ActivityInfoSkeleton />;
  }

  return (
    <section className="flex w-full flex-wrap items-start gap-3 rounded-lg border border-grey-200 bg-white p-4 md:flex-nowrap">
      <div className="flex size-[60px] shrink-0 items-center justify-center rounded-lg bg-grey-100">
        <PurpleIcon name="user-group" className="size-6 text-brand-blue-700" />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Heading tag="h1" variant="size-18" type="heading-600" className="text-base">
            {activity.name}
          </Heading>
          <ActivityTags />
        </div>
        <DescriptionList className="flex-col items-start gap-6 lg:flex-row">
          <DescriptionItem className="w-auto flex-col items-start gap-1">
            <DescriptionTerm className="whitespace-nowrap text-sm">Record Type</DescriptionTerm>
            <DescriptionDetails className="m-0">
              <NumberBadge variant="success-light" className="rounded-full">
                {snakeToCamelWithSpaces(activity.details.community_activity_type.name)}
              </NumberBadge>
            </DescriptionDetails>
          </DescriptionItem>
          {activity.details.type && (
            <DescriptionItem className="w-auto flex-col items-start gap-1">
              <DescriptionTerm className="text-nowrap text-sm">Type</DescriptionTerm>
              <DescriptionDetails className="m-0">
                <NumberBadge variant="info" className="rounded-full capitalize">
                  {snakeToCamelWithSpaces(activity.details.type)}
                </NumberBadge>
              </DescriptionDetails>
            </DescriptionItem>
          )}
          <DescriptionItem className="w-auto flex-col items-start gap-1">
            <DescriptionTerm className="text-sm">School</DescriptionTerm>
            <DescriptionDetails className="m-0 whitespace-nowrap text-sm">
              <Button asChild variant="link" size="link">
                <Link to={AdminRoutes.App.Schools.Details.makePath({ dynamicParameters: { schoolId: activity.details.school.id } })}>
                  {activity.details.school.name}
                </Link>
              </Button>
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem className="w-auto flex-col items-start gap-1">
            <DescriptionTerm className="text-sm">Date & Time</DescriptionTerm>
            <DescriptionDetails className="m-0 whitespace-nowrap text-sm">
              {activity.details.date_and_time ? formatDateShortMonth(activity.details.date_and_time) : '-'}
            </DescriptionDetails>
          </DescriptionItem>
        </DescriptionList>
      </div>
      <DropdownRoot>
        <DropdownTrigger asChild>
          <Button
            type="button"
            variant="secondary"
            size="icon_40"
            className="ml-auto size-10"
            iconLeft={<PurpleIcon name="dots-vertical" className="size-5 shrink-0" />}
          />
        </DropdownTrigger>
        <DropdownContent className="max-w-[150px] gap-1" align="end">
          <DropdownItem
            iconName="trash"
            className="cursor-pointer text-error-main transition-colors duration-200 hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main focus-visible:outline-none [&>svg]:text-error-main"
            onSelect={openDeleteModal}
          >
            Delete
          </DropdownItem>
        </DropdownContent>
      </DropdownRoot>
      <CallToActionModal
        modalName={ModalType.DELETE_ACTIVITY}
        modalTitle="Delete Activity"
        modalDescription="By deleting this activity, you will remove it from the system. This action cannot be undone."
        modalTextContent="Are you sure you want to delete this activity?"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteActivitySubmitHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
      />
    </section>
  );
};
