import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { getActivityList } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActivityListQueryParameters, TActivityListResponse } from '../activities.types';

export const useActivityList = (
  parameters?: TActivityListQueryParameters,
  queryOptions?: TCustomUseQueryOptions<TActivityListResponse, AxiosError> & {
    onSuccess?: (data: TActivityListResponse) => void;
  },
) => {
  const onSuccessRef = useRef(queryOptions?.onSuccess);

  const queryData = useQuery({
    queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_LIST, parameters],
    queryFn: () => getActivityList(parameters),
    ...queryOptions,
  });

  useEffect(() => {
    if (queryData.isError && isClientError(queryData.error)) {
      showErrorToast('System message', 'Unable to retrieve activities');
    }
  }, [queryData.isError, queryData.error]);

  useEffect(() => {
    onSuccessRef.current = queryOptions?.onSuccess;
  }, [queryOptions?.onSuccess]);

  useEffect(() => {
    if (queryData.data) {
      onSuccessRef.current?.(queryData.data);
    }
  }, [queryData.data]);

  return queryData;
};
