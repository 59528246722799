import { useCallback, useEffect, useId, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Separator,
  Text,
  TipBox,
  useStepper,
} from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { useUnsavedChanges } from '~/providers';
import { useUpdateDraftDistrictLeadersUsers } from '~/services';
import {
  districtIdSelector,
  districtLeaderUsersStepSelector,
  maxDistrictStepSelector,
  setMaxStep,
} from '~/store/features/district-set-up';
import { showErrorToast } from '~/utils/toasts';
import { StepCardContainer } from '../../StepCardContainer';
import { DistrictLeaderRow } from './DistrictLeaderRow';
import { leaderUsersSchema } from './schema';
import type { z } from 'zod';

const DistrictUsersStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { nextStep, activeStep } = useStepper();

  const formId = useId();

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const districtId = useAppSelector(districtIdSelector);
  const leaderUsersStep = useAppSelector(districtLeaderUsersStepSelector);
  const maxDistrictStep = useAppSelector(maxDistrictStepSelector);

  const { mutate: updateDraft, isPending: isUpdating } = useUpdateDraftDistrictLeadersUsers();

  const defaultValues = useMemo(() => {
    const { users, no_district_leader_available } = leaderUsersStep;
    return {
      users: users ? [...users] : [],
      no_district_leader_available,
    };
  }, [leaderUsersStep]);

  const form = useForm<z.infer<typeof leaderUsersSchema>>({
    resolver: zodResolver(leaderUsersSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  const formNoDistrictLeaderAvailable = form.watch('no_district_leader_available');

  useEffect(() => {
    if (formNoDistrictLeaderAvailable) {
      form.setValue('users', []);
      form.clearErrors('users');
    }
  }, [form, formNoDistrictLeaderAvailable]);

  useEffect(() => {
    if (form.formState.isDirty) {
      setShouldShowUnsaved(true);
    }
  }, [form.formState.isDirty, setShouldShowUnsaved]);

  const {
    fields: usersFields,
    remove: removeUser,
    append: appendUser,
  } = useFieldArray({
    name: 'users',
    control: form.control,
  });

  useEffect(() => {
    if (usersFields.length === 0 && !formNoDistrictLeaderAvailable) {
      appendUser({
        email: '',
        prounitas_roles: '',
      });
    }
  }, [usersFields, appendUser, formNoDistrictLeaderAvailable]);

  const submitHandler = (data: z.infer<typeof leaderUsersSchema>, onSaveCallback?: () => void) => {
    if (districtId) {
      const convertedUsers = data.no_district_leader_available
        ? []
        : data.users.map((user) => {
            const defaultUser = defaultValues.users.find((currentUser) => currentUser.id === user.id);

            // NOTE: send user ID if it exists in defaultValues.
            // Otherwise, we know that user is new and BE will create it.
            const userId = defaultUser ? defaultUser?.id : null;

            const oldRoles = defaultUser?.prounitas_roles ? [defaultUser.prounitas_roles.toString()] : [];

            return {
              ...(userId ? { id: userId } : {}),
              email: user.email || '',
              roles: user.prounitas_roles ? [+user.prounitas_roles] : [0],
              old_roles: oldRoles,
            };
          });

      const body = {
        users: convertedUsers,
        no_district_leader_available: data.no_district_leader_available,
      };

      updateDraft(
        {
          districtId,
          parameters: body,
        },
        {
          onSuccess: () => {
            setShouldShowUnsaved(false);
            onSaveCallback?.();
            form.reset(data);
            nextStep();
          },
        },
      );
    } else {
      showErrorToast('System message', 'Could not find district id. Check the provided information and try again');
    }
  };

  const navigateToDistrictList = useCallback(() => {
    navigate(AdminRoutes.App.Districts.Root.path);
  }, [navigate]);

  const increaseMaxStep = () => {
    if (maxDistrictStep === activeStep) {
      dispatch(setMaxStep(maxDistrictStep + 1));
    }
  };

  const addMoreLeaderUserHandler = () => {
    appendUser({
      email: '',
      prounitas_roles: '',
    });
  };

  return (
    <StepCardContainer
      isStepValid
      onSave={() => submitHandler(form.getValues(), navigateToDistrictList)}
      formId={formId}
      isLoading={isUpdating}
    >
      <Form
        providerProps={form}
        id={formId}
        className="flex w-full flex-col gap-6"
        onSubmit={form.handleSubmit((data) => submitHandler(data, increaseMaxStep))}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <Heading variant="size-18" type="heading-500" className="text-grey-950">
                District Leader
              </Heading>
              <Text variant="size-14" type="body-400" className="text-grey-700">
                Invite a District Leader who will oversee and manage district operations.
              </Text>
            </div>
            <FormField
              control={form.control}
              name="no_district_leader_available"
              render={({ field }) => (
                <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value={field.value.toString()}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="flex flex-nowrap items-center gap-2 text-base text-grey-800">
                    No District Leader available
                    <TipBox
                      text="If no District Leader is available, select the checkbox to skip this step. The Pronunitas Team will manage the district, and you can add a leader later."
                      tooltipClassName="text-center"
                    />
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-1">
              {
                usersFields.map((user, index) => {
                  return (
                    <DistrictLeaderRow key={user.id} form={form} index={index} user={user} usersCount={usersFields.length} onRemove={removeUser} />
                  );
                })
              }
              <div>
                <Button
                  variant="link"
                  size="link"
                  type="button"
                  onClick={addMoreLeaderUserHandler}
                  disabled={formNoDistrictLeaderAvailable}
                >
                  + Add Additional District Leader
                </Button>
              </div>
            </div>
          </div>
          <Separator />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <Heading variant="size-18" type="heading-500" className="text-grey-950">
                Users
              </Heading>
              <Text variant="size-14" type="body-400" className="text-grey-700">
                Add and invite users to the district platform.
              </Text>
            </div>
            {/* NOTE: this is dummy. This section will be done in next sprints */}
            <div className="h-[100px] w-full" />
          </div>
        </div>
      </Form>
    </StepCardContainer>
  );
};

export { DistrictUsersStep };
