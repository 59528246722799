import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import {
  DISTRICT_STATUS,
  LIMIT_QUERY_NAME,
  OFFSET_QUERY_NAME,
  SORT_QUERY_NAME,
} from '@purple/shared-types';
import { AppFilters, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions } from '~/components';
import { AdminRoutes, ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { usePublishedDistricts, useUpdateDistrictStatus } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICT_FILTERS } from './helpers';
import { usePublishedDistrictColumns } from './usePublishedDistrictColumns';
import type { TPublishedDistrict } from '~/services';

export const PublishedDistricts: React.FC = () => {
  const [searchParameters] = useSearchParams();
  const navigate = useNavigate();

  const { openModal: openStatusModal, closeModal: closeStatusModal } = useModal(ModalType.CHANGE_DISTRICT_STATUS);

  const [selectedDistrict, setSelectedDistrict] = useState<TPublishedDistrict | null>(null);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = usePublishedDistricts({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    grade: searchParameters.get('grade'),
    status: searchParameters.get('status'),
  });
  const { mutate: updateStatus, isPending: isStatusPending } = useUpdateDistrictStatus();

  const publishedDistricts = useMemo(() => data?.results ?? [], [data?.results]);

  const districtStatusUpdateHandler = useCallback(() => {
    if (!selectedDistrict) {
      return showErrorToast('District not found', 'Please select a district to update its status');
    }

    if (selectedDistrict.status === DISTRICT_STATUS.DRAFT) {
      return showErrorToast('District status error', 'Draft districts cannot be activated');
    }

    updateStatus(
      {
        id: selectedDistrict.id,
        status:
          selectedDistrict.status === DISTRICT_STATUS.PUBLISHED
            ? DISTRICT_STATUS.DEACTIVATED
            : selectedDistrict.status === DISTRICT_STATUS.DEACTIVATED
              ? DISTRICT_STATUS.PUBLISHED
              : selectedDistrict.status,
      },
      {
        onSuccess: () => {
          closeStatusModal();
        },
      },
    );
  }, [selectedDistrict, updateStatus, closeStatusModal]);

  const districtStatusChangeHandler = useCallback(
    (district: TPublishedDistrict) => {
      setSelectedDistrict(district);
      openStatusModal();
    },
    [openStatusModal],
  );

  const columns = usePublishedDistrictColumns({
    onDeactivate: districtStatusChangeHandler,
  });

  const { table } = useDataTable({
    name: TableName.DISTRICT_PUBLISHED,
    columns,
    data: publishedDistricts,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['name'],
        right: ['actions'],
      },
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  const districtRowClickHandler = useCallback(
    (district: TPublishedDistrict) => {
      navigate(AdminRoutes.App.Districts.Details.makePath({ dynamicParameters: { id: district.id } }));
    },
    [navigate],
  );

  return (
    <DataTable table={table} loading={isFetching} onRowClick={districtRowClickHandler}>
      <div className="flex w-full items-center gap-4 p-4 pt-6">
        <AppFilters config={DISTRICT_FILTERS} />
        <SearchInput
          value={search}
          placeholder="Search"
          className="max-w-[300px]"
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
        <DataTableViewOptions table={table} />
      </div>
      <CallToActionModal
        modalName={ModalType.CHANGE_DISTRICT_STATUS}
        modalTitle={`${selectedDistrict?.status === DISTRICT_STATUS.PUBLISHED ? 'Deactivate' : 'Activate'} District`}
        modalDescription="By changing the status of the district, you will change its access on the Purple Sense platform."
        modalTextContent={`Are you sure you want to ${
          selectedDistrict?.status === DISTRICT_STATUS.PUBLISHED ? 'deactivate' : 'activate'
        } ${selectedDistrict?.name} district?`}
        primaryButtonText={selectedDistrict?.status === DISTRICT_STATUS.PUBLISHED ? 'Deactivate' : 'Activate'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={districtStatusUpdateHandler}
        primaryButtonVariant={
          selectedDistrict?.status === DISTRICT_STATUS.PUBLISHED ? 'destructive_primary' : 'primary'
        }
        isLoading={isStatusPending}
      />
    </DataTable>
  );
};
