import type { ValueOf } from '@purple/shared-types';

export const APP_NAME = 'Purple Admin';

export const TIME_PERIODS = {
  last_7_days: 'last_7_days',
  last_30_days: 'last_30_days',
  last_3_month: 'last_3_month',
  last_6_month: 'last_6_month',
  this_academic_year: 'this_academic_year',
  last_academic_year: 'last_academic_year',
} as const;

export type TTimePeriod = ValueOf<typeof TIME_PERIODS>;

export const PERIOD_SELECT_OPTIONS = [
  {
    label: 'Last 7 Days',
    value: TIME_PERIODS.last_7_days,
  },
  {
    label: 'Last 30 Days',
    value: TIME_PERIODS.last_30_days,
  },
  {
    label: 'Last 3 Months',
    value: TIME_PERIODS.last_3_month,
  },
  {
    label: 'Last 6 months',
    value: TIME_PERIODS.last_6_month,
  },
  {
    label: 'This Academic Year',
    value: TIME_PERIODS.this_academic_year,
  },
  {
    label: 'Last Academic Year',
    value: TIME_PERIODS.last_academic_year,
  },
] as const;

export const ReadableTimePeriods = {
  [TIME_PERIODS.last_7_days]: 'Last 7 Days',
  [TIME_PERIODS.last_30_days]: 'Last 30 Days',
  [TIME_PERIODS.last_3_month]: 'Last 3 Months',
  [TIME_PERIODS.last_6_month]: 'Last 6 months',
  [TIME_PERIODS.this_academic_year]: 'This Academic Year',
  [TIME_PERIODS.last_academic_year]: 'Last Academic Year',
} as const;
