import { useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { SAF_AREA_SUBCATEGORIES_LABELS } from '@purple/shared-types';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { getActivityChoices } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActivityChoices, TActivityChoicesPayload } from '../activities.types';

export const useActivityChoices = (
  payload: TActivityChoicesPayload,
  mutationOptions?: TCustomUseMutationOptions<TActivityChoices, AxiosError>,
) => {
  const [data, setData] = useState<TActivityChoices | null>(null);

  const mutationData = useMutation({
    mutationKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_CHOICES, payload],
    mutationFn: () => getActivityChoices(payload),
    onSuccess: setData,
    onError: () => {
      showErrorToast('Failed to fetch activity choices', 'Please try again later');
    },
    ...mutationOptions,
  });

  const typeOptions = useMemo(
    () => (data?.type ? Object.entries(data.type).map(([value, label]) => ({ value, label })) : []),
    [data?.type],
  );

  const serviceCategoriesOptions = useMemo(
    () =>
      data?.service_categories?.map((category) => ({
        label: SAF_AREA_SUBCATEGORIES_LABELS[category.name],
        value: category.id,
      })) ?? [],
    [data?.service_categories],
  );

  const stakeholdersServedOptions = useMemo(
    () =>
      data?.stakeholders_served
        ? Object.entries(data.stakeholders_served).map(([value, label]) => ({ value, label }))
        : [],
    [data?.stakeholders_served],
  );

  const gradeLevelsOptions = useMemo(
    () => (data?.grade_levels ? Object.entries(data.grade_levels).map(([value, label]) => ({ value, label })) : []),
    [data?.grade_levels],
  );

  const campusContactTitlesOptions = useMemo(
    () =>
      data?.campus_contact_titles
        ? Object.entries(data.campus_contact_titles).map(([value, label]) => ({ value, label }))
        : [],
    [data?.campus_contact_titles],
  );

  const crisisTypesOptions = useMemo(
    () => (data?.crisis_types ? Object.entries(data.crisis_types).map(([value, label]) => ({ value, label })) : []),
    [data?.crisis_types],
  );

  const crisisGradesOptions = useMemo(
    () => (data?.crisis_grades ? Object.entries(data.crisis_grades).map(([value, label]) => ({ value, label })) : []),
    [data?.crisis_grades],
  );

  const topicsOptions = useMemo(
    () => (data?.topics ? Object.entries(data.topics).map(([value, label]) => ({ value, label })) : []),
    [data?.topics],
  );

  return {
    ...mutationData,
    typeOptions,
    serviceCategoriesOptions,
    stakeholdersServedOptions,
    gradeLevelsOptions,
    campusContactTitlesOptions,
    crisisTypesOptions,
    crisisGradesOptions,
    topicsOptions,
    data,
  };
};
