import { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { cn, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger } from '@purple/ui';
import { useUsersList } from '~/services';
import type React from 'react';
import type { TDistrictUserStatus } from '@purple/shared-types';

type TUserFilterComboBoxProperties = {
  /**
   * The selected value of the combobox.
   */
  value: string;
  /**
   * Whether the combobox is in an error state.
   * @default false
   */
  isError?: boolean;
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange: (value: string) => void;
  /**
   * Callback that is called when the combobox is cleared.
   */
  onClear?: () => void;
  /**
   * Placeholder text for the combobox.
   */
  placeholder?: string;
  /**
   * The maximum number of districts to fetch.
   * @default 10
   */
  limit?: number;
  /**
   * Additional class name for the combobox.
   * @default ''
   */
  className?: string;
  /**
   * The list of schools to filter the users.
   */
  schools?: string[];
  /**
   * Whether the combobox has a clear button.
   */
  hasClearButton?: boolean;
  /**
   * The status of the district user.
   */
  status?: TDistrictUserStatus;
};

export const UserFilterComboBox: React.FC<TUserFilterComboBoxProperties> = (props) => {
  const { value, isError = false, onChange, onClear, placeholder = 'Select user', schools, className, limit = 10, hasClearButton = false, status } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data: usersData, isFetching: isUserLoading } = useUsersList({
    requestParameters: {
      search: debouncedSearchValue,
      ...(schools ? { schools } : {}),
      limit,
      status,
    },
    enabled: true,
  });

  const users = useMemo(() => usersData?.results ?? [], [usersData?.results]);
  const selectedList = useMemo(() => users ? users.find((user) => user.id.toString() === value.toString()) : null, [value, users]);

  const selectValueHandler = useCallback(
    (newValue: string) => {
      onChange(newValue);
    },
    [onChange],
  );

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  return (
    <ComboBox modal>
      <ComboBoxTrigger isError={isError} placeholder={placeholder} selectedLabel={selectedList?.full_name} hasClearButton={hasClearButton && Boolean(selectedList)} onClear={hasClearButton ? onClear : () => {}} className={cn(className)} />
      <ComboBoxContent
        loading={isUserLoading}
        shouldFilter={false}
        searchPlaceholder="Search user..."
        emptyContent="User not found."
        onSearchChange={debouncedSearch}
      >
        {users.map(({ id, full_name }) => (
          <ComboBoxItem key={id} value={id.toString()} selected={value.toString() === id.toString()} onSelect={selectValueHandler}>
            {full_name}
          </ComboBoxItem>
        ))}
      </ComboBoxContent>
    </ComboBox>
  );
};
