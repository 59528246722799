import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { formatDateShortMonth, formatDateShortMonthWithTime, snakeToCamelWithSpaces } from '@purple/shared-utils';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TActivityHistory } from '@purple/shared-types';

type TUseActivityHistoryColumns = () => ColumnDef<TActivityHistory>[];

export const useActivityHistoryColumns: TUseActivityHistoryColumns = () => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'user__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="User" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: original.user.id } })} className="text-brand-blue-700 hover:text-brand-blue-800 line-clamp-1 w-fit break-all transition-colors">
                  {original.user.full_name || 'System'}
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {original.user.full_name || 'System'}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'User' },
        },
        {
          accessorKey: 'field',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Field" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {snakeToCamelWithSpaces(original.field)}
                </span>
              </TooltipTrigger>
              {original.field && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {snakeToCamelWithSpaces(original.field)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Field' },
        },
        {
          accessorKey: 'original_value',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Original Value" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {original.original_value || '–'}
                </span>
              </TooltipTrigger>
              {original.original_value && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.original_value}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Original Value' },
        },
        {
          accessorKey: 'new_value',
          header: ({ column }) => <DataTableColumnHeader column={column} title="New Value" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {original.new_value || '–'}
                </span>
              </TooltipTrigger>
              {original.new_value && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.new_value}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'New Value' },
        },
        {
          accessorKey: 'datetime',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Date Created" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {original.datetime ? formatDateShortMonth(original.datetime) : '—'}
                </span>
              </TooltipTrigger>
              {original.datetime && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(original.datetime)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Date Created' },
        },
        {
          accessorKey: 'updated_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Date Updated" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {original.datetime ? formatDateShortMonth(original.datetime) : '—'}
                </span>
              </TooltipTrigger>
              {original.datetime && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(original.datetime)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Date Updated' },
        },
      ] satisfies ColumnDef<TActivityHistory>[],
    [],
  );

  return columns;
};
