import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { getContactDetails } from './get-contact-details';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TContactDetailItem } from './contacts.types';

type TUseContactDetails = {
  parameters: {
    contactId: string;
  };
  queryOptions?: TCustomUseQueryOptions<TContactDetailItem, AxiosError>;
};

export const useContactDetails = ({ parameters: { contactId }, queryOptions }: TUseContactDetails) => {
  const { isError, error, ...rest } = useQuery({
    ...queryOptions,
    queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACT_DETAILS, contactId],
    queryFn: () => getContactDetails(contactId),
    enabled: !!contactId && queryOptions?.enabled,
  });

  useEffect(() => {
    if (isError && isClientError(error)) {
      showErrorToast('System message', 'Unable to fetch contact details');
    }
  }, [error, isError]);

  return { isError, error, ...rest };
};
