import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormField,
  FormMessage,
  ScrollArea,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useUpdateSchoolDetails } from '~/services';
import { HolidayFieldItem } from './HolidayFieldItem';
import type { TSchoolBasicDetails } from '~/services';

const holidaysSchema = z
  .object({
    holidays: z.array(
      z.object({
        id: z.number(),
        name: z.string().trim().min(1, {
          message: 'Holiday name is required.',
        }),
        is_recurring: z.boolean(),
        start_datetime: z.date(),
        end_datetime: z.date(),
      }),
    ),
  })
  .superRefine((data, context) => {
    for (const [index, holiday] of data.holidays.entries()) {
      if (holiday.end_datetime < holiday.start_datetime) {
        context.addIssue({
          path: ['holidays', index, 'end_datetime'],
          code: z.ZodIssueCode.custom,
          message: 'End date cannot be earlier than start date.',
        });
      }
    }
  });

type THolidaysDialogProperties = {
  data: TSchoolBasicDetails;
  onSuccess?: () => void;
};

export const HolidaysDialog: React.FC<THolidaysDialogProperties> = (props) => {
  const { data, onSuccess } = props;
  const { isOpen, toggleModal } = useModal(ModalType.UPDATE_SCHOOL_HOLIDAYS);

  const { mutate: updateSchool, isPending } = useUpdateSchoolDetails();

  const defaultValues: z.infer<typeof holidaysSchema> = useMemo(
    () => ({
      holidays: Array.isArray(data.holidays)
        ? data.holidays.map((item) => ({
            ...item,
            end_datetime: new Date(item.end_datetime),
            start_datetime: new Date(item.start_datetime),
          }))
        : [],
    }),
    [data.holidays],
  );

  const form = useForm<z.infer<typeof holidaysSchema>>({
    resolver: zodResolver(holidaysSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: 'holidays',
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const cancelClickHandler = () => {
    toggleModal(false);
    form.reset(defaultValues);
  };

  const removeHolidayClickHandler = (index: number) => {
    remove(index);
  };

  const addHolidayClickHandler = () => {
    append({
      id: Date.now(),
      name: '',
      is_recurring: false,
      start_datetime: new Date(),
      end_datetime: new Date(new Date().setDate(new Date().getDate() + 1)),
    });
  };

  const saveClickHandler = (formData: z.infer<typeof holidaysSchema>) => {
    const updatedHolidays = formData.holidays.map((item) => {
      const targetHoliday = data.holidays?.find((holiday) => holiday.id === item.id);
      const { id: _id, ...itemWithoutId } = item;
      const result = targetHoliday ? item : itemWithoutId;
      return {
        ...result,
        start_datetime: result.start_datetime.toISOString(),
        end_datetime: result.end_datetime.toISOString(),
      };
    });
    updateSchool(
      {
        id: data.id,
        holidays: updatedHolidays,
      },
      {
        onSuccess: () => {
          onSuccess?.();
          toggleModal(false);
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] max-w-[564px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>Edit Holidays & School Closures</DialogTitle>
            <DialogDescription className="sr-only">
              By editing the holidays and school closures, you can set the start and end dates for each holiday or school closure.
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <div className="w-full p-6 pr-[30px]">
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              {fields.map((field, index) => (
                <HolidayFieldItem<z.infer<typeof holidaysSchema>>
                  key={field.id}
                  sequence={index + 1}
                  control={form.control}
                  name={`holidays.${index}.name`}
                  startDateName={`holidays.${index}.start_datetime`}
                  endDateName={`holidays.${index}.end_datetime`}
                  isRecurringName={`holidays.${index}.is_recurring`}
                  disableRemove={fields.length === 1}
                  onRemove={() => removeHolidayClickHandler(index)}
                />
              ))}
              <FormField name={'holidays.root' as 'holidays'} control={form.control} render={() => <FormMessage />} />
              <Button
                type="button"
                variant="tertiary"
                size="small"
                className="w-fit"
                iconLeft={<PurpleIcon name="plus" />}
                onClick={addHolidayClickHandler}
              >
                Add more Holidays / School Closures
              </Button>
            </Form>
          </div>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={cancelClickHandler}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            onClick={form.handleSubmit(saveClickHandler)}
            isLoading={isPending}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
