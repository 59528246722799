import { Heading, Separator, Skeleton } from '@purple/ui';

const SafSettingsTabSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="px-6 py-5">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">SAF Settings</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex flex-col gap-[22px] px-6 py-5">
        <Skeleton className="h-[98px] w-full" />
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex flex-col gap-[22px] px-6 py-5">
        <Skeleton className="h-[98px] w-full" />
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex flex-col gap-[22px] px-6 py-5">
        <Skeleton className="h-[194px] w-full" />
      </div>
    </div>
  );
};

export { SafSettingsTabSkeleton };
