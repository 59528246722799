import { Link } from 'react-router-dom';
import { formatDateShortMonth } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import { PriorityColumn } from '../Saf/SafDetails/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminSafItem } from '~/services';

type TSafsColumns = ColumnDef<TAdminSafItem>[];

export const safsColumns: TSafsColumns = [
  {
    accessorKey: 'saf_number',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Saf Number" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Link to={AdminRoutes.App.Safs.SafDetail.makePath({
            dynamicParameters: { id: row.original.id },
          })}
          >
            <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{row.getValue('saf_number')}</span>
          </Link>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {row.getValue('saf_number')}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 100,
    meta: { label: 'Saf Number' },
  },
  {
    accessorKey: 'subject',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Subject" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="line-clamp-1 break-all text-grey-950">{row.getValue('subject')}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {row.getValue('subject')}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 180,
    meta: { label: 'Subject' },
    enableSorting: false,
  },
  {
    accessorKey: 'priority',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Priority" />,
    cell: ({ row }) => (
      row.original.priority ? (<PriorityColumn priority={row.original.priority} />) : <span className="line-clamp-1 break-all text-grey-950">-</span>
    ),
    size: 120,
    meta: { label: 'Priority' },
    enableSorting: false,
  },
  {
    accessorKey: 'submitter__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Submitted By" />,
    cell: ({ row }) => {
      const submittedBy = row.original.created_by;

      if (!submittedBy) {
        return <span className="line-clamp-1 break-all text-grey-950">-</span>;
      }

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({
              dynamicParameters: { id: submittedBy.id },
            })}
            >
              <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{submittedBy?.full_name}</span>
            </Link>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {submittedBy?.full_name}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Submitted By' },
    enableSorting: false,
  },
  {
    accessorKey: 'student__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Student" />,
    cell: ({ row }) => {
      const student = row.original.student;

      if (!student) {
        return <span className="line-clamp-1 break-all text-grey-950">-</span>;
      }

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{student?.full_name}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {student?.full_name}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Student' },
  },
  {
    accessorKey: 'district',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row }) => {
      const districtName = row.original.district?.name;
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{districtName}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {districtName}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 180,
    meta: { label: 'District' },
    enableSorting: false,
  },
  {
    accessorKey: 'school',
    header: ({ column }) => <DataTableColumnHeader column={column} title="School" />,
    cell: ({ row }) => {
      const school = row.original.school;

      if (!school) {
        return <span className="line-clamp-1 break-all text-grey-950">-</span>;
      }

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to={AdminRoutes.App.Schools.Details.makePath({
              dynamicParameters: { schoolId: school.id },
            })}
            >
              <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{school?.name}</span>
            </Link>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {school?.name}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'School' },
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created On" />,
    cell: ({ row }) => {
      const created_at = row.original.created_at;
      const dateString = created_at ? formatDateShortMonth(created_at) : '-';
      return (
        <span className="line-clamp-1 break-all text-grey-950">{dateString}</span>
      );
    },
    size: 120,
    meta: { label: 'Created On' },
  },
];
