import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch, useTimePeriod } from '@purple/hooks';
import { DISTRICT_STATUS, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import {
  Heading,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
  Text,
} from '@purple/ui';
import { DataTable, DataTableViewOptions, DistrictMultiSelect } from '~/components';
import { PERIOD_SELECT_OPTIONS, TableName, TIME_PERIODS } from '~/constants';
import { useCurrentUser, useDataTable } from '~/hooks';
import { useDashboardLatestUpdates, useDashboardTopStatistic } from '~/services';
import { CardStatisticSection, ChartSection } from './components';
import { SkeletonCardStatistic } from './components/CardStatisticSection';
import { homeLastUpdatesColumns } from './homeLastUpdatesColumns';

export const HomePage: React.FC = () => {
  const { user } = useCurrentUser();

  const [searchParameters] = useSearchParams();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { timePeriod, onPeriodChange, onPeriodClear } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });

  const [selectedDistrict, setSelectedDistrict] = useState<string[]>([]);

  const { data: topStatisticData, isLoading: isTopStatisticLoading } = useDashboardTopStatistic({
    districts: selectedDistrict,
  });

  const { data: lastUpdates, isLoading: isLastUpdatesLoading } = useDashboardLatestUpdates({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    districts: selectedDistrict,
    time_period: timePeriod,
    search: debounceSearch,
    ordering: searchParameters.get(SORT_QUERY_NAME),
  });

  const { table } = useDataTable({
    name: TableName.HOME_UPDATES,
    columns: homeLastUpdatesColumns,
    data: lastUpdates?.results ?? [],
    rowCount: lastUpdates?.count,
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between gap-2">
        <Heading tag="h1" variant="size-22" type="heading-600">
          Hi,
          {' '}
          {user?.full_name}
          !
        </Heading>
        <DistrictMultiSelect
          onChange={setSelectedDistrict}
          values={selectedDistrict}
          className="max-w-[250px]"
          placeholder="All Districts"
          disabled={!selectedDistrict}
          status={DISTRICT_STATUS.PUBLISHED}
        />
      </div>
      <div className="flex flex-col gap-4">
        {/* DASHBOARD TOP STATISTIC */}
        {isTopStatisticLoading && !topStatisticData && <SkeletonCardStatistic />}
        {topStatisticData && !isTopStatisticLoading && <CardStatisticSection statisticData={topStatisticData} selectedDistrict={selectedDistrict} />}
        <ChartSection selectedDistrict={selectedDistrict} />
      </div>

      <div className="border-grey-200 shadow-custom-heavy w-full gap-4 rounded-lg border bg-white">
        <DataTable table={table} loading={isLastUpdatesLoading}>
          <div className="flex flex-col gap-4 px-4 pb-4 pt-6">
            <Text variant="size-16" type="body-600">
              Latest Updates
            </Text>
            <div className="flex items-center justify-between gap-2">
              <div className="flex items-center gap-4">
                <RadixSelect value={timePeriod} onValueChange={onPeriodChange}>
                  <RadixSelectTrigger className="w-[230px]" hasClearButton onClearCallback={onPeriodClear}>
                    <RadixSelectValue placeholder="All time" />
                  </RadixSelectTrigger>
                  <RadixSelectContent>
                    {PERIOD_SELECT_OPTIONS.map((period) => (
                      <RadixSelectItem key={period.value} value={period.value}>
                        {period.label}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
                <SearchInput
                  value={search}
                  placeholder="Search by user name..."
                  className="max-w-[300px]"
                  onChange={onSearchChange}
                  onClear={onClearSearch}
                />
              </div>
              <DataTableViewOptions table={table} />
            </div>
          </div>
        </DataTable>
      </div>
    </div>
  );
};
