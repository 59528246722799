import { useCallback } from 'react';
import { PurpleIcon } from '@purple/icons';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Button, NumberBadge, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { USER_PERMISSION_TYPE, USER_PERMISSION_TYPE_BADGE_COLORS } from '~/services';
import type { ColumnDef } from '@tanstack/react-table';
import type { TUserPermissionItem } from '~/services';

type TUseUserPermissionsColumnsOptions = {
  onPermissionDelete: (item: TUserPermissionItem | null) => void;
};

type TUseUserPermissionsColumnsParams = (options: TUseUserPermissionsColumnsOptions) => {
  columns: ColumnDef<TUserPermissionItem>[];
};

export const useUserPermissionsColumns: TUseUserPermissionsColumnsParams = (options) => {
  const { onPermissionDelete } = options;

  const { openModal: openDeletePermissionModal } = useModal(ModalType.DELETE_USER_PERMISSION);
  const { openModal: openRemoveNegativePermissionModal } = useModal(ModalType.REMOVE_NEGATIVE_PERMISSION);

  const deletePermission = useCallback((item: TUserPermissionItem) => {
    onPermissionDelete(item);
    openDeletePermissionModal();
  }, [onPermissionDelete, openDeletePermissionModal]);

  const deleteNegativePermission = useCallback((item: TUserPermissionItem) => {
    onPermissionDelete(item);
    openRemoveNegativePermissionModal();
  }, [onPermissionDelete, openRemoveNegativePermissionModal]);

  const columns: ColumnDef<TUserPermissionItem>[] = [
    {
      accessorKey: 'category',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Section/Resources" />,
      cell: ({ row: { original } }) => (
        original.category && original.category.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{snakeToCamelWithSpaces(original.category)}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {snakeToCamelWithSpaces(original.category)}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      ),
      size: 180,
      enableSorting: false,
      meta: { label: 'Section/Resources' },
    },
    {
      accessorKey: 'from',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Constructed From" />,
      cell: ({ row: { original } }) => (
        <NumberBadge variant={USER_PERMISSION_TYPE_BADGE_COLORS[original.constructed_from]} className="rounded-full capitalize">{snakeToCamelWithSpaces(original.constructed_from)}</NumberBadge>
      ),
      size: 100,
      enableSorting: false,
      meta: { label: 'Constructed From' },
    },
    {
      accessorKey: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Role/Group Name" />,
      cell: ({ row: { original } }) => (
        original.name && original.name.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.name}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.name}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      ),
      size: 300,
      enableSorting: false,
      meta: { label: 'Role/Group Name' },
    },
    {
      accessorKey: 'description',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Description" />,
      cell: ({ row: { original } }) => (
        original.description && original.description.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.description}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.description}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      ),
      size: 450,
      enableSorting: false,
      meta: { label: 'Description' },
    },
    {
      id: 'actions',
      cell: ({ row: { original } }) => (
        original.constructed_from === USER_PERMISSION_TYPE.NEGATIVE_PERMISSION
          ? (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="secondary" className="h-auto p-2" onClick={() => deleteNegativePermission(original)}>
                    <PurpleIcon name="plus" className="size-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>
                    Remove Negative Permission
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="tertiary" className="h-auto p-2 hover:bg-error-bg active:bg-error-bg/85" onClick={() => deletePermission(original)}>
                    <PurpleIcon name="trash" className="size-5 text-error-main" />
                  </Button>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>
                    Remove permission
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )

      ),
      size: 50,
      meta: { className: 'text-right' },
    },
  ];

  return {
    columns,
  };
};
