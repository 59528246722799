import type { ValueOf } from '@purple/shared-types';

export const PriorityListQueryName = {
  VIEW: 'view',
  ID: 'id',
  LIST_TYPE: 'list_type',
} as const;
export type TPriorityListQueryName = ValueOf<typeof PriorityListQueryName>;

export const PriorityListViewType = {
  LIST: 'list',
  DETAILS: 'details',
} as const;
export type TPriorityListViewType = ValueOf<typeof PriorityListViewType>;

export const LIST_TYPE_OPTIONS = [
  {
    label: 'Owned by Me',
    value: 'owned',
  },
  {
    label: 'Shared with Me',
    value: 'shared',
  },
] as const;
