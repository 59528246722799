import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { TAGS_TYPES } from '@purple/shared-types';
import { tagsSchema } from '@purple/shared-utils';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Tag,
  TagAction,
  TagList,
  TagSelect,
} from '@purple/ui';
import { CreateTagModal, UpdateTagModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useActivityTypeDetails, useApplyCustomTags, useCustomTags } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ActivityTypeTabContainer } from '../../components';
import { CustomTagsTabSkeleton } from './CustomTagsTabSkeleton';
import type { z } from 'zod';

export const CustomTagsTab: React.FC = () => {
  const { id: activityTypeId } = useParams();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { openModal: openTagModal } = useModal(ModalType.CREATE_CUSTOM_TAG);
  const { openModal: openUpdateTagModal } = useModal(ModalType.UPDATE_CUSTOM_TAG);

  const { data: activityType, isFetching: isFetchingType } = useActivityTypeDetails(
    activityTypeId as string,
  );
  const { data: tags, isFetching: isFetchingAll } = useCustomTags({
    content_type: TAGS_TYPES.COMMUNITY_ACTIVITY_TYPE,
    district: activityType?.district.id as number,
  }, {
    enabled: activityType?.district.id !== undefined,
  });
  const { data: objectTags, isFetching: isFetchingSelected } = useCustomTags({
    content_type: TAGS_TYPES.COMMUNITY_ACTIVITY_TYPE,
    object_id: activityTypeId as string,
    district: activityType?.district.id as number,
  }, {
    enabled: activityType?.district.id !== undefined,
  });
  const { mutate: applyTags, isPending } = useApplyCustomTags();

  const tagOptions = useMemo(() => tags?.results ?? [], [tags]);
  const selectedTags = useMemo(() => objectTags?.results ?? [], [objectTags]);
  const isFetching = useMemo(() => isFetchingAll || isFetchingSelected || isFetchingType, [isFetchingAll, isFetchingSelected, isFetchingType]);

  const defaultValues: z.infer<typeof tagsSchema> = useMemo(
    () => ({
      tags: selectedTags ?? [],
    }),
    [selectedTags],
  );

  const form = useForm<z.infer<typeof tagsSchema>>({
    resolver: zodResolver(tagsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const saveTagsClickHandler = (formData: z.infer<typeof tagsSchema>) => {
    if (!activityType) {
      return showErrorToast('System Error', 'Activity type not found');
    }
    applyTags(
      {
        district_id: activityType.district.id,
        object_id: activityType.id,
        content_type: TAGS_TYPES.COMMUNITY_ACTIVITY_TYPE,
        tags: formData.tags.map((tag) => tag.id),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  if (isFetching || !activityType) return <CustomTagsTabSkeleton />;

  return (
    <ActivityTypeTabContainer
      title="Custom Tags"
      editing={isEditing}
      loading={isPending}
      onEdit={editClickHandler}
      onCancel={cancelClickHandler}
      onSave={form.handleSubmit(saveTagsClickHandler)}
    >
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="tags"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full max-w-[320px] flex-1 flex-col gap-1 space-y-0 xl:max-w-[400px]">
                    <FormControl>
                      <TagSelect
                        {...field}
                        isError={!!fieldState.error}
                        options={tagOptions}
                        onActionClick={openTagModal}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <TagList>
              {selectedTags.map((tag) => (
                <Tag key={tag.id} onClick={openUpdateTagModal}>
                  {tag.name}
                </Tag>
              ))}
              <TagAction onClick={openTagModal}>Create New Tag</TagAction>
            </TagList>
          )}
      <CreateTagModal
        relatedDistrictId={activityType.district.id}
        relatedEntityId={activityType.id}
        tagsType={TAGS_TYPES.COMMUNITY_ACTIVITY_TYPE}
        existingNames={tagOptions.map((tag) => tag.name)}
      />
      <UpdateTagModal
        tags={selectedTags}
        relatedDistrictId={activityType.district.id}
        relatedEntityId={activityType.id}
        tagsType={TAGS_TYPES.COMMUNITY_ACTIVITY_TYPE}
        allTags={tagOptions}
      />
    </ActivityTypeTabContainer>
  );
};
