import { useSearchParams } from 'react-router-dom';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { AccessDenied } from '@purple/ui';
import { DataTable } from '~/components';
import { TableName } from '~/constants';
import { useDataTable } from '~/hooks';
import { DISTRICT_QUERY_NAME, OWNER_QUERY_NAME, PRIORITY_QUERY_NAME, SCHOOL_QUERY_NAME, STATUS_QUERY_NAME } from '~/pages/Main/Saf/Tabs/AllSafs/constants';
import { useSafsList } from '~/services';
import { safsPrintColumns } from './safsPrintColumns';
import type { FC } from 'react';

export const SafPrintView: FC = () => {
  const [searchParameters] = useSearchParams();

  const authToken = searchParameters.get('token');

  const { data, isLoading, isSuccess, isError } = useSafsList({
    requestParameters: {
      priority: searchParameters.get(PRIORITY_QUERY_NAME),
      status: searchParameters.get(STATUS_QUERY_NAME),
      district: searchParameters.get(DISTRICT_QUERY_NAME),
      school: searchParameters.get(SCHOOL_QUERY_NAME),
      current_owner: searchParameters.get(OWNER_QUERY_NAME),
      search: searchParameters.get('search'),
      limit: 999999999,
      offset: 0,
      ordering: searchParameters.get(SORT_QUERY_NAME),
      token: authToken,
    },
    enabled: !!authToken,
  });

  const { table } = useDataTable({
    name: TableName.PRINT_SAFS,
    columns: safsPrintColumns,
    data: data?.results || [],
    pageCount: 1,
  });

  if (isError || !authToken) {
    return (
      <div className="flex h-screen items-center justify-center">
        <AccessDenied />
      </div>
    );
  }

  return (
    <DataTable table={table} hidePagination loading={isLoading} id={isSuccess ? 'download-ready' : ''} />
  );
};
