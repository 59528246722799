import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Checkbox, Form, Label, NoDataAvailable, SearchInput } from '@purple/ui';
import { useActivityTypeRoles, useUpdateActivityTypeRoles } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ActivityTypeTabContainer } from '../../components';
import { RoleItem } from './RoleItem';
import { RoleTabSkeleton } from './RoleTabSkeleton';
import { roleSchema } from './schema';
import type { z } from 'zod';

export const RolesTab: React.FC = () => {
  const { id: activityTypeId } = useParams();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const { data: roles, isFetching } = useActivityTypeRoles({
    id: activityTypeId as string,
  });
  const { mutate: updateRoles, isPending } = useUpdateActivityTypeRoles();

  const defaultValues: z.infer<typeof roleSchema> = useMemo(
    () => ({
      roles: roles ?? [],
    }),
    [roles],
  );

  const form = useForm<z.infer<typeof roleSchema>>({
    resolver: zodResolver(roleSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields } = useFieldArray({
    control: form.control,
    name: 'roles',
    keyName: 'key',
  });
  const indexes = useMemo(() => new Map(fields.map((field, index) => [field.key, index])), [fields]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const filteredPermissions = useMemo(
    () => fields.filter((field) =>
      field.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
    ),
    [fields, search],
  );
  const formRoles = form.watch('roles');
  const isSomeRolesSelected = formRoles.some((field) => field.is_available);
  const isAllRolesSelected = formRoles.every((field) => field.is_available);

  const saveDetailsClickHandler = (formData: z.infer<typeof roleSchema>) => {
    if (!roles) {
      return showErrorToast('System Error', 'Roles not found');
    }
    updateRoles(
      {
        id: activityTypeId as string,
        disabled: formData.roles.filter((field) => !field.is_available).map((field) => field.id),
        enabled: formData.roles.filter((field) => field.is_available).map((field) => field.id),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  const selectAllRolesClickHandler = (value: boolean) => {
    form.setValue('roles', fields.map(
      (field) =>
        filteredPermissions.some((item) => item.id === field.id)
          ? ({ ...field, is_available: value })
          : field,
    ));
  };

  const searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  if (isFetching) return <RoleTabSkeleton />;

  return (
    <ActivityTypeTabContainer
      title="Permissions"
      className="p-0 pt-6"
      editing={isEditing}
      loading={isPending}
      onEdit={editClickHandler}
      onCancel={cancelClickHandler}
      onSave={form.handleSubmit(saveDetailsClickHandler)}
    >
      <div className="w-full max-w-[300px] px-6 pb-4">
        <SearchInput
          type="text"
          placeholder="Search"
          value={search}
          onChange={searchChangeHandler}
        />
      </div>
      <div className="grid h-12 w-full grid-cols-12 items-center border-b border-grey-300 py-3">
        <strong className="col-span-6 w-full px-3 text-xs font-semibold uppercase text-grey-600">
          Roles
        </strong>
        <div className="col-span-6 flex w-full items-center gap-2 px-3">
          {isEditing && filteredPermissions.length > 0 && (
            <Checkbox
              id="select-all-roles"
              variant={isAllRolesSelected ? 'default' : 'checkedAll'}
              checked={isSomeRolesSelected}
              onCheckedChange={selectAllRolesClickHandler}
            />
          )}
          <Label htmlFor="select-all-roles" className="text-xs font-semibold uppercase text-grey-600">
            Available
          </Label>
        </div>
      </div>
      <Form providerProps={form} className="flex w-full flex-col">
        {filteredPermissions.map((field) => (
          <RoleItem key={field.key} fieldIndex={indexes.get(field.key)!} editing={isEditing} />
        ))}
        {filteredPermissions.length === 0 && (
          <NoDataAvailable
            iconName="folder-open"
            className="col-span-12 my-auto px-4 py-12"
            title="No roles found"
            description="No roles found for the search criteria. Please try again with a different search term."
          />
        )}
      </Form>
    </ActivityTypeTabContainer>
  );
};
