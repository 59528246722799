import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { getActivityFilterChoices } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActivityFilterChoices } from '../activities.types';

export const useActivityFilterChoices = (
  queryOptions?: TCustomUseQueryOptions<TActivityFilterChoices, AxiosError>,
) => {
  const { isError, error, ...queryData } = useQuery({
    queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_FILTER_CHOICES],
    queryFn: getActivityFilterChoices,
    ...queryOptions,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Failed to retrieve activity filter choices');
    }
  }, [isError, error]);

  return { ...queryData, isError };
};
