import { Skeleton } from '@purple/ui';

export const DetailsFormSkeleton: React.FC = () => {
  return (
    <ul className="flex w-full flex-col gap-2.5">
      <li className="flex min-h-9 w-full max-w-80 flex-nowrap items-center justify-stretch gap-2">
        <strong className="min-w-24 font-primary text-sm font-medium text-grey-title">
          Name:
        </strong>
        <Skeleton className="h-8 w-full max-w-[140px] rounded-lg" />
      </li>
      <li className="flex min-h-9 w-full max-w-80 flex-nowrap items-center justify-stretch gap-2">
        <strong className="min-w-24 font-primary text-sm font-medium text-grey-title">
          District:
        </strong>
        <Skeleton className="h-8 w-full max-w-[130px] rounded-lg" />
      </li>
      <li className="flex min-h-9 w-full max-w-80 flex-nowrap items-start justify-stretch gap-2">
        <strong className="mt-2 min-w-24 font-primary text-sm font-medium text-grey-title">
          Description:
        </strong>
        <Skeleton className="h-8 w-full max-w-[190px] rounded-lg" />
      </li>
    </ul>
  );
};
