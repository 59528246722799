import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { DistrictStatusToBadgeVariant } from '@purple/shared-utils';
import {
  Button,
  cn,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  NumberBadge,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { DateAndUserCell } from './DateAndUserCell';
import type { ColumnDef } from '@tanstack/react-table';
import type { TPublishedDistrict } from '~/services';

type TUsePublishedDistrictColumns = (options?: {
  onDeactivate?: (district: TPublishedDistrict) => void;
}) => ColumnDef<TPublishedDistrict>[];

export const usePublishedDistrictColumns: TUsePublishedDistrictColumns = (options) => {
  const { onDeactivate } = options ?? {};

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="District Name" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 break-all">{row.getValue('name')}</span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {row.getValue('name')}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 350,
        },
        {
          accessorKey: 'status',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Set Up Status" />,
          cell: ({ row }) => (
            <NumberBadge
              variant={DistrictStatusToBadgeVariant[row.original.status]}
              className="rounded-full capitalize"
            >
              {row.getValue('status')}
            </NumberBadge>
          ),
          size: 180,
          meta: { className: 'text-center' },
        },
        {
          accessorKey: 'created_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Create Date/User" />,
          cell: ({ row }) => (
            <DateAndUserCell date={row.original.created_at} name={row.original.created_by?.full_name} />
          ),
          size: 200,
          meta: { label: 'Create Date/User' },
        },
        {
          accessorKey: 'updated_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Last Updated" />,
          cell: ({ row }) => (
            <DateAndUserCell date={row.original.updated_at} name={row.original.last_modified_by?.full_name} />
          ),
          size: 200,
          meta: { label: 'Last Updated' },
        },
        {
          accessorKey: 'published_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Published" />,
          cell: ({ row }) => (
            <DateAndUserCell date={row.original.published_at} name={row.original.published_by?.full_name} />
          ),
          size: 200,
          meta: { label: 'Published' },
        },
        {
          accessorKey: 'actions',
          header: () => null,
          cell: ({ row }) => (
            <DropdownRoot>
              <Tooltip>
                <TooltipTrigger asChild>
                  <DropdownTrigger asChild>
                    <Button
                      type="button"
                      variant="tertiary"
                      size="icon_32"
                      className="size-8 shrink-0 active:bg-grey-100"
                      iconLeft={<PurpleIcon name="dots-vertical" className="shrink-0 text-grey-600" />}
                      onFocusCapture={(event) => event.stopPropagation()}
                    />
                  </DropdownTrigger>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent withArrow={false}>Actions</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <DropdownContent className="max-w-[150px] gap-1" align="end">
                {row.original.status !== DISTRICT_STATUS.DRAFT && (
                  <DropdownItem
                    iconName={row.original.status === DISTRICT_STATUS.PUBLISHED ? 'X' : 'check'}
                    className={cn(
                      'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
                      {
                        'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main':
                          row.original.status === DISTRICT_STATUS.PUBLISHED,
                      },
                    )}
                    onSelect={() => onDeactivate?.(row.original)}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {row.original.status === DISTRICT_STATUS.PUBLISHED ? 'Deactivate' : 'Activate'}
                  </DropdownItem>
                )}
              </DropdownContent>
            </DropdownRoot>
          ),
          size: 64,
          enableHiding: false,
          enableSorting: false,
          meta: { className: 'text-right' },
        },
      ] satisfies ColumnDef<TPublishedDistrict>[],
    [onDeactivate],
  );

  return columns;
};
