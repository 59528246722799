/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { gsap } from 'gsap';

type Vector2D = {
  x: number;
  y: number;
};

type RotationValues = {
  tiltX: number;
  tiltY: number;
  degree: number;
};

type UseInteractive3DRotationOptions = {
  animationDuration?: number;
  ease?: string;
  throttleDelay?: number;
  debounceDelay?: number;
  maxRotation?: number; // New option
  perspective?: number; // New option
};

function createThrottle<T extends (...args: any[]) => void>(
  func: T,
  limit: number,
): ((...args: Parameters<T>) => void) & { cancel: () => void } {
  let lastRun = 0;
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const throttled = (...args: Parameters<T>) => {
    const now = Date.now();

    if (lastRun && now < lastRun + limit) {
      // Handle last call
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        lastRun = now;
        func(...args);
      }, limit);
    } else {
      lastRun = now;
      func(...args);
    }
  };

  throttled.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return throttled;
}

function createDebounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number,
): ((...args: Parameters<T>) => void) & { cancel: () => void } {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let cancelled = false;

  const debounced = (...args: Parameters<T>) => {
    if (cancelled) return;

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (!cancelled) func(...args);
    }, wait);
  };

  debounced.cancel = () => {
    cancelled = true;
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return debounced;
}

export const useInteractive3DRotation = (
  ref: React.RefObject<HTMLElement>,
  {
    animationDuration = 1,
    ease = 'power2.out',
    throttleDelay = 16,
    debounceDelay = 150,
    maxRotation = 20,
    perspective = 1000,
  }: UseInteractive3DRotationOptions = {},
) => {
  const requestRef = useRef<number | null>(null);
  const mouseRef = useRef<Vector2D>({ x: 0, y: 0 });
  const centerRef = useRef<Vector2D>({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });
  const tweenRef = useRef<gsap.core.Tween>();
  const isEnabled = useRef(true);

  // Memoized calculation with bounds checking
  const calculateRotation = useMemo((): ((x: number, y: number) => RotationValues) => {
    return (mouseX: number, mouseY: number) => {
      const dx = mouseX - centerRef.current.x;
      const dy = mouseY - centerRef.current.y;
      const tiltX = Math.max(-1, Math.min(1, dy / centerRef.current.y));
      const tiltY = Math.max(-1, Math.min(1, -(dx / centerRef.current.x)));
      const radius = Math.min(1, Math.sqrt(tiltX ** 2 + tiltY ** 2));

      return {
        tiltX,
        tiltY,
        degree: radius * maxRotation,
      };
    };
  }, [maxRotation]);

  // Optimized transform update
  const updateTransform = useCallback(() => {
    if (!ref.current || !isEnabled.current) return;

    const { tiltX, tiltY, degree } = calculateRotation(
      mouseRef.current.x,
      mouseRef.current.y,
    );

    // Kill previous tween to prevent animation queue
    if (tweenRef.current) {
      tweenRef.current.kill();
    }

    tweenRef.current = gsap.to(ref.current, {
      duration: animationDuration,
      transform: `rotate3d(${tiltX}, ${tiltY}, 0, ${degree}deg)`,
      ease,
      overwrite: 'auto',
    });
  }, [animationDuration, ease, calculateRotation, ref]);

  // Event handlers with improved cleanup
  const mouseMoveHandler = useCallback(
    createThrottle((event: MouseEvent) => {
      if (!isEnabled.current) return;

      mouseRef.current = { x: event.pageX, y: event.pageY };

      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
      requestRef.current = requestAnimationFrame(updateTransform);
    }, throttleDelay),
    [updateTransform, throttleDelay],
  );

  const windowResizeHandler = useCallback(
    createDebounce(() => {
      if (!isEnabled.current) return;

      centerRef.current = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      };
      updateTransform();
    }, debounceDelay),
    [updateTransform, debounceDelay],
  );

  // Setup and cleanup
  useEffect(() => {
    if (!ref.current) return;

    document.body.style.perspective = `${perspective}px`;
    isEnabled.current = true;

    const element = ref.current;
    element.style.willChange = 'transform';

    window.addEventListener('mousemove', mouseMoveHandler, { passive: true });
    window.addEventListener('resize', windowResizeHandler, { passive: true });

    return () => {
      isEnabled.current = false;

      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('resize', windowResizeHandler);

      document.body.style.perspective = 'none';
      if (element) {
        element.style.willChange = 'auto';
      }

      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
        requestRef.current = null;
      }

      mouseMoveHandler.cancel();
      windowResizeHandler.cancel();

      if (tweenRef.current) {
        tweenRef.current.kill();
      }
    };
  }, [mouseMoveHandler, windowResizeHandler, perspective, ref]);
};
