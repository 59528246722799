import { useMemo } from 'react';
import { ContactType, DISTRICT_STATUS, ReadableContactType } from '@purple/shared-types';
import { useDistrictsListBasicInfo } from '~/services';
import type { TFiltersConfig } from '@purple/ui';

const DEFAULT_DISTRICT_LIMIT = 99999;
const DEFAULT_DISTRICT_OFFSET = 0;

export const useContactsFilterOptions = () => {
  const { data: activeDistricts, isLoading } = useDistrictsListBasicInfo({
    offset: DEFAULT_DISTRICT_OFFSET,
    limit: DEFAULT_DISTRICT_LIMIT,
    status: `${DISTRICT_STATUS.PUBLISHED},${DISTRICT_STATUS.DEACTIVATED}`,
  });

  const filterConfig: TFiltersConfig = useMemo(() => ({
    categories: [
      {
        label: 'District',
        value: 'district',
        filters: activeDistricts?.results.map((district) => ({
          label: district.name || '',
          value: district.id.toString(),
        })) || [],
      },
      {
        label: 'Contact Type',
        value: 'type',
        filters: Object.values(ContactType).map((type) => ({
          label: ReadableContactType[type],
          value: type,
        })),
      },
    ],
  }), [
    activeDistricts?.results,
  ]);

  return { filterConfig, isFiltersLoading: isLoading };
};
