import { BulkFileErrorTableCell } from '~/components/BulkFileErrorTableCell';
import { DataTableColumnHeader } from '~/components/DataTable';
import type { ColumnDef } from '@tanstack/react-table';
import type { TBulkSafRoutingRulesErrorResponse } from '~/services';

export const fileErrorsColumns: ColumnDef<TBulkSafRoutingRulesErrorResponse>[] = [
  {
    accessorKey: 'row',
    header: ({ column }) => <DataTableColumnHeader column={column} title="#" className="px-2" />,
    cell: ({ row }) => (
      <div className="px-2 py-3">
        <span>{row.original.row}</span>
      </div>
    ),
    size: 50,
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'need',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Need" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.saf_type_value} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'grades',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Grades" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.grades} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'student_last_name_ranges',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Last Name Ranges" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.student_last_name_between} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'saf_route_to',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Route To" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.saf_route_to} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'assigned_user',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Assigned User" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.person_to_assign} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'assigned_role',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Assigned Role" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.role_to_assign} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
];
