import { useCallback } from 'react';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  cn,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  NumberBadge,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import type { RowData, Table } from '@tanstack/react-table';
import type { TReactTableSelectedRow } from './types';

const MAX_VISIBLE_ITEMS_COUNT = 12;
const MAX_VISIBLE_CHARACTERS_PER_ITEM = 20;

type TDataTableSelectedControllerProperties<TData extends RowData = RowData> = {
  table: Table<TData>;
  isSelectAllLoading?: boolean;
  displayMode?: 'always' | 'selected';
  maxVisibleItems?: number;
  maxVisibleCharactersPerItem?: number;
  className?: string;
  onSelectAll?: () => void;
  onDeselect?: (row: TReactTableSelectedRow) => void;
  onClearAll?: () => void;
};

export const DataTableSelectedController = <TData extends RowData = RowData>(
  props: TDataTableSelectedControllerProperties<TData>,
): JSX.Element | null => {
  const {
    table,
    maxVisibleItems = MAX_VISIBLE_ITEMS_COUNT,
    maxVisibleCharactersPerItem = MAX_VISIBLE_CHARACTERS_PER_ITEM,
    isSelectAllLoading = false,
    displayMode = 'always',
    onSelectAll,
    onClearAll,
    onDeselect,
    className,
  } = props;

  const rowsCount = table.getRowCount();
  const currentSelectedRowsAmount = Object.values(table.getState().rowSelection).filter((selected) => selected).length;

  const selectedItems = table.options.meta?.selectedRows ?? [];
  const moreCount = selectedItems.length - maxVisibleItems;

  const clearAllSelectedHandler = useCallback(() => {
    table.options.meta?.clearSelectedRows?.();
    onClearAll?.();
  }, [table, onClearAll]);

  const deselectRowHandler = useCallback((row: TReactTableSelectedRow) => {
    table.setRowSelection({
      ...table.getState().rowSelection,
      [row.id]: false,
    });
    table.options.meta?.setSelectedRows?.((prevSelectedRows) => prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id));
    onDeselect?.(row);
  }, [table, onDeselect]);

  if (displayMode === 'selected' && currentSelectedRowsAmount === 0) {
    return null;
  };

  return (
    <div className={cn('flex w-full flex-col gap-4', className)}>
      <div className="flex items-center gap-2">
        {currentSelectedRowsAmount > 0 && (
          <Text variant="size-14" type="body-500" className="text-brand-blue-700">
            <span className="text-base font-semibold">{currentSelectedRowsAmount}</span>
            {' '}
            row(s) selected
          </Text>
        )}
        <div className="flex items-center gap-3">
          {currentSelectedRowsAmount > 0 && (
            <Button
              variant="tertiary"
              className="h-7 rounded-full bg-brand-blue-100 px-3 py-1 text-brand-blue-700"
              size="small"
              onClick={clearAllSelectedHandler}
            >
              Clear All
            </Button>
          )}
          {onSelectAll && currentSelectedRowsAmount !== rowsCount && rowsCount > 0 && (
            <Button
              variant="tertiary"
              isLoading={isSelectAllLoading}
              className="h-7 rounded-full bg-brand-blue-100 px-3 py-1 text-brand-blue-700"
              size="small"
              onClick={onSelectAll}
            >
              {isSelectAllLoading ? 'Selecting All...' : `Select All Found (${rowsCount})`}
            </Button>
          )}
        </div>
      </div>
      {currentSelectedRowsAmount > 0 && (
        <div className="flex flex-row flex-wrap items-center gap-2">
          {table.options.meta?.selectedRows?.slice(0, maxVisibleItems).map((item) => (
            <p key={item.id} className="inline-flex h-7 items-center justify-start gap-1 rounded-full bg-brand-blue-100 text-brand-blue-700">
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="ml-3 text-sm font-medium text-brand-blue-700">
                    {item.name.length > maxVisibleCharactersPerItem ? `${item.name.slice(0, maxVisibleCharactersPerItem)}...` : item.name}
                  </span>
                </TooltipTrigger>
                {item.name.length > maxVisibleCharactersPerItem && (
                  <TooltipPortal>
                    <TooltipContent>
                      {item.name}
                    </TooltipContent>
                  </TooltipPortal>
                )}
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    type="button"
                    className="mr-1 inline-flex size-5 shrink-0 items-center justify-center rounded-full bg-brand-blue-100 text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-50 focus:bg-brand-blue-50 focus-visible:bg-brand-blue-50 active:opacity-70 active:transition-none"
                    onClick={() => deselectRowHandler(item)}
                  >
                    <PurpleIcon name="X" className="size-4 shrink-0 text-brand-blue-700" />
                  </button>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>
                    Deselect row
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </p>
          ))}
          {moreCount > 0 && (
            <HoverCard>
              <HoverCardTrigger asChild>
                <NumberBadge variant="info" className="cursor-default rounded-full font-medium">
                  +
                  {moreCount}
                  {' '}
                  More
                </NumberBadge>
              </HoverCardTrigger>
              <HoverCardContent align="start" className="max-h-64 w-fit max-w-80 space-y-1.5 overflow-auto px-3 py-2">
                {selectedItems.slice(maxVisibleItems).map((item) => (
                  <Text key={item.id} className="line-clamp-1 break-all text-sm font-normal text-grey-950">
                    {item.name}
                  </Text>
                ))}
              </HoverCardContent>
            </HoverCard>
          )}
        </div>
      )}
    </div>
  );
};
