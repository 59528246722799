import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, SearchInput } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { SAFS_PRIORITY_OPTIONS, SAFS_STATUS_OPTIONS, TableName } from '~/constants';
import { useDataTable } from '~/hooks';
import { useSafsList } from '~/services';
import { SchoolTabContainer } from '../../SchoolTabContainer';
import { useSchoolSafsColumns } from './useSchoolSafsColumns';
import type { TFiltersConfig } from '@purple/ui';

export const SchoolSafsTab: React.FC = () => {
  const { id: schoolId } = useParams();
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data: safsData, isLoading: isSafsLoading } = useSafsList({
    requestParameters: {
      school: schoolId,
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      priority: searchParameters.get('priority'),
      status: searchParameters.get('status'),
    },
  });

  const filterConfig: TFiltersConfig = useMemo(() => ({
    categories: [
      {
        label: 'Priority',
        value: 'priority',
        filters: SAFS_PRIORITY_OPTIONS,
      },
      {
        label: 'Status',
        value: 'status',
        filters: SAFS_STATUS_OPTIONS,
      },
    ],
  }), []);

  const safs = useMemo(() => safsData?.results ?? [], [safsData?.results]);

  const columns = useSchoolSafsColumns();

  const { table } = useDataTable({
    name: TableName.SCHOOL_SAFS,
    columns,
    data: safs,
    rowCount: safsData?.count,
    initialState: {
      columnPinning: {
        left: ['saf_number'],
      },
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  return (
    <SchoolTabContainer title="SAFs" className="p-0">
      <DataTable table={table} loading={isSafsLoading}>
        <div className="flex w-full items-center gap-4 p-4">
          <AppFilters config={filterConfig} />
          <SearchInput
            value={search}
            placeholder="Search"
            className="max-w-[300px]"
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
          <DataTableViewOptions table={table} />
        </div>
      </DataTable>
    </SchoolTabContainer>
  );
};
