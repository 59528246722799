import { getRandomIntegerInRange } from '@purple/shared-utils';
import { Separator, Skeleton } from '@purple/ui';

export const BasicDetailsSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5">
        <Skeleton className="h-[25px] w-[112px]" />
      </div>
      <div className="flex w-full flex-col gap-6 px-6 py-8 lg:flex-row">

        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full flex-col gap-4">
            <div className="flex h-8 flex-row items-center justify-between gap-2">
              <Skeleton className="h-[24px] w-[98px]" />
              <Skeleton className="h-[24px] w-[54px]" />
            </div>
            <div className="flex w-full flex-col gap-4">
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
            </div>
          </div>
        </div>

        <Separator orientation="vertical" className="h-px w-full bg-grey-200 lg:h-auto lg:w-px" />

        <div className="flex w-full flex-col gap-6">

          <div className="flex w-full flex-col gap-4">
            <div className="flex h-8 flex-row items-center justify-between gap-2">
              <Skeleton className="h-[24px] w-[98px]" />
              <Skeleton className="h-[24px] w-[54px]" />
            </div>
            <div className="flex w-full flex-col gap-4">
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2">
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
                <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              </div>
            </div>
          </div>

          <Separator orientation="horizontal" className="bg-grey-200" />

          <div className="flex w-full flex-col gap-4">
            <div className="flex h-8 flex-row items-center justify-between gap-2">
              <Skeleton className="h-[24px] w-[98px]" />
              <Skeleton className="h-[24px] w-[54px]" />
            </div>
            <div className="flex w-full flex-col gap-2">
              <Skeleton className="h-6 w-full" />
              <Skeleton className="h-6 w-full" />
              <Skeleton className="h-6 w-full" />
              <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 340) }} />
            </div>
          </div>

          <Separator orientation="horizontal" className="bg-grey-200" />

          <div className="flex w-full flex-col gap-4">
            <div className="flex h-8 flex-row items-center justify-between gap-2">
              <Skeleton className="h-[24px] w-[98px]" />
              <Skeleton className="h-[24px] w-[54px]" />
            </div>
            <div className="flex w-full flex-row gap-4">
              <Skeleton className="h-6 w-full rounded-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              <Skeleton className="h-6 w-full rounded-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
              <Skeleton className="h-6 w-full rounded-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
