import { Skeleton } from '@purple/ui';

const PriorityCardSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 p-4">
      <div className="flex w-full gap-4">
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="h-5 w-full max-w-[120px]" />
          <Skeleton className="h-4 w-full max-w-[180px]" />
        </div>
        <Skeleton className="size-8 shrink-0" />
      </div>
      <div className="flex w-full flex-col gap-2">
        <div className="flex flex-col gap-1">
          <Skeleton className="h-5 w-full max-w-[34px]" />
          <Skeleton className="h-4 w-full max-w-[44px]" />
        </div>
        <div className="flex flex-col gap-1">
          <Skeleton className="h-5 w-full max-w-[50px]" />
          <Skeleton className="h-4 w-full max-w-[60px]" />
        </div>
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex w-full flex-col gap-1">
            <Skeleton className="h-5 w-full max-w-[47px]" />
            <Skeleton className="h-4 w-full max-w-[20px]" />
          </div>
          <Skeleton className="h-5 w-full max-w-[62px]" />
        </div>
      </div>
      <Skeleton className="h-10 w-full" />
    </div>
  );
};

export const PriorityListSkeleton: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
      <PriorityCardSkeleton />
      <PriorityCardSkeleton />
      <PriorityCardSkeleton />
      <PriorityCardSkeleton />
      <PriorityCardSkeleton />
      <PriorityCardSkeleton />
    </div>
  );
};
