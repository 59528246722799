import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AdminRoutes } from '~/constants';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getIndividualActionDetails } from './get-individual-action-details';
import type { AxiosError } from 'axios';
import type { TActionDetails } from '@purple/shared-types';

type TUseIndividualActionDetailsProperties = {
  actionId: string;
  enabled?: boolean;
};

export const useIndividualActionDetails = ({ actionId, enabled = true }: TUseIndividualActionDetailsProperties) => {
  const navigate = useNavigate();
  const { isError, error, ...queryData } = useQuery<TActionDetails, AxiosError>({
    queryKey: [ACTIONS_QUERY_KEYS.GET_INDIVIDUAL_ACTION_DETAILS, actionId],
    queryFn: () => getIndividualActionDetails(actionId),
    enabled: !!actionId && enabled,
  });

  useEffect(() => {
    if (isError && isClientError(error)) {
      if (error.status === 404) {
        showErrorToast('System message', 'Action with provided ID not found');
        navigate(AdminRoutes.App.Actions.Root.path);
        return;
      }
      showErrorToast('System message', 'Unable to retrieve action details');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error]);

  return { ...queryData, isError, error };
};
