import axiosInstance from '../axios-config';
import { FILE_ENDPOINTS } from '../endpoints';
import type { TFile, TFileUpdatePayload } from '@purple/shared-types';

export const updateFile = async (payload: TFileUpdatePayload) => {
  const { is_private, viewers, file, title, fileId } = payload;

  const isFormDAta = file instanceof File;

  const requestData = isFormDAta ? new FormData() : { is_private, viewers, title };
  let headers = { 'Content-Type': 'application/json' };

  if (isFormDAta && requestData instanceof FormData) {
    if (file instanceof File) requestData.append('file', file);
    if (title) requestData.append('title', title);
    if (typeof is_private === 'boolean') requestData.append('is_private', String(is_private));
    if (Array.isArray(viewers)) {
      for (const viewer of viewers) {
        requestData.append('viewers', viewer);
      }
    }
    headers = { 'Content-Type': 'multipart/form-data' };
  }

  const response = await axiosInstance.patch<TFile>(FILE_ENDPOINTS.BY_ID(fileId), requestData, {
    headers,
  });

  return response.data;
};
