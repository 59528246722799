import { useMutation } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { deleteStudentGuardian } from './delete-student-guardian';
import { STUDENTS_QUERY_KEYS } from './students.const';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useDeleteStudentGuardian = (queryOptions?: TCustomUseMutationOptions<AxiosResponse, AxiosError>) => {
  return useMutation({
    ...queryOptions,
    mutationKey: [STUDENTS_QUERY_KEYS.DELETE_STUDENT_GUARDIAN],
    mutationFn: deleteStudentGuardian,
    onError: (error) => {
      if (isClientError(error)) {
        showErrorToast('System error', 'Unable to delete student guardian');
      }
    },
  });
};
