import { useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { DistrictUserStatus } from '@purple/shared-types';
import { Button, cn, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger } from '@purple/ui';
import { useUserDeactivateCheck } from '~/services';
import type { TUserManagement, TUsersDeactivateCheck } from '~/services';

type TListItemOptionsProperties = {
  user: TUserManagement;
  onRemove: () => void;
  onDeactivate: (checkResponse: TUsersDeactivateCheck) => void;
  onActivate: () => void;
};

const ListItemOptions = ({
  user,
  onRemove,
  onDeactivate,
  onActivate,
}: TListItemOptionsProperties) => {
  const { id, status } = user;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isDeactivateChecking, setIsDeactivateChecking] = useState(false);

  const { mutate: checkUsersDeactivation } = useUserDeactivateCheck();

  const deactivateClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDeactivateChecking(true);

    checkUsersDeactivation({ usersIds: [id] }, {
      onSuccess: (response) => {
        setIsDropdownOpen(false);
        onDeactivate(response);
        setIsDeactivateChecking(false);
      },
      onError: () => {
        setIsDeactivateChecking(false);
        setIsDropdownOpen(false);
      },
    });
  };

  const removeClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);
    onRemove();
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger asChild>
        <Button
          iconLeft={<PurpleIcon name="dots-vertical" />}
          variant="tertiary_icon_only"
          size="icon_40"
        />
      </DropdownTrigger>
      <DropdownContent className="max-w-[260px] gap-1" align="end">
        {status !== DistrictUserStatus.INACTIVE && (
          <DropdownItem
            iconName="X"
            className={cn(
              'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
              {
                'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main': true,
              },
            )}
            onSelect={() => deactivateClickHandler()}
            onClick={(event) => event.stopPropagation()}
            isLoading={isDeactivateChecking}
          >
            Deactivate
          </DropdownItem>
        )}
        {status === DistrictUserStatus.INACTIVE && (
          <DropdownItem
            iconName="check"
            className={cn(
              'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
              {
                'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main': false,
              },
            )}
            onSelect={() => onActivate()}
            onClick={(event) => event.stopPropagation()}
          >
            Activate
          </DropdownItem>
        )}
        <Guard requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_ADMIN_DELETE_USERS]}>
          <DropdownItem
            iconName="trash"
            className={cn(
              'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
              {
                'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main': true,
              },
            )}
            onSelect={() => removeClickHandler()}
            onClick={(event) => event.stopPropagation()}
          >
            Delete
          </DropdownItem>
        </Guard>
      </DropdownContent>
    </DropdownRoot>
  );
};

export { ListItemOptions };
