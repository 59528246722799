import { useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { SafSubmitterType } from '@purple/shared-types';
import { AccessDenied, AppTabs, type TAppTab, Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useSubmitterTypeDetails } from '~/services';
import { SafTabsValues } from '../Tabs/constants';
import { BasicDetailsTab, DetailHeader, DetailHeaderSkeleton, FieldSettingsTab, SafSettingsTab, TextTranslationTab } from './components';
import { SUBMITTER_DETAILS_TABS_VALUE } from './constants';

const SubmitterTypeDetails = () => {
  const [searchParameters] = useSearchParams();
  const originalDistrict = searchParameters.get('original_district') || '';

  const { typeId } = useParams();
  const navigate = useNavigate();

  const { data: detailsData, isLoading: isDetailsDataLoading } = useSubmitterTypeDetails({ typeId });

  const goBackHandler = () => {
    navigate(AdminRoutes.App.Safs.Root.makePath({
      queries: {
        tab: SafTabsValues.submitterTypes,
        district: originalDistrict,
      },
    }));
  };

  const tabs: TAppTab[] = useMemo(
    () => {
      const commonTabs = [{
        label: 'Basic Details',
        value: SUBMITTER_DETAILS_TABS_VALUE.BASIC_DETAILS,
        content: (<BasicDetailsTab data={detailsData} />),
        permissions: [],
      }, {
        label: 'SAF Settings',
        value: SUBMITTER_DETAILS_TABS_VALUE.SAF_SETTINGS,
        content: (<SafSettingsTab data={detailsData} />),
        permissions: [],
      }];
      const additionalTabs = [
        {
          label: 'Field Settings',
          value: SUBMITTER_DETAILS_TABS_VALUE.FIELD_SETTINGS,
          content: (<FieldSettingsTab data={detailsData} />),
          permissions: [],
        },
        {
          label: 'Text Translation',
          value: SUBMITTER_DETAILS_TABS_VALUE.TEXT_TRANSLATION,
          content: (<TextTranslationTab data={detailsData} />),
          permissions: [],
        },
      ];

      if (detailsData?.type === SafSubmitterType.PURPLE_USER) {
        return commonTabs;
      } else {
        return [...commonTabs, ...additionalTabs];
      }
    },
    [detailsData],
  );

  const { activeTab, tabChangeHandler } = useTabs({
    tabs,
  });

  const showSkeleton = useMemo(() => isDetailsDataLoading || !detailsData, [detailsData, isDetailsDataLoading]);

  const handleTabChange = (value: string) => {
    tabChangeHandler(value);
  };

  return (
    <div className="flex flex-col gap-4">
      <button type="button" className="flex flex-row items-center gap-2" onClick={goBackHandler}>
        <PurpleIcon name="chevron-left" className="size-4 text-brand-blue-700" />
        <Text variant="size-14" type="body-600" className="text-brand-blue-700">Back to Submitter Types</Text>
      </button>
      {showSkeleton ? <DetailHeaderSkeleton /> : <DetailHeader data={detailsData} />}
      {activeTab === null
        ? (
            <AccessDenied />
          )
        : (
            <AppTabs tabs={tabs} listClassName="pl-2" value={activeTab} onValueChange={handleTabChange} />
          )}
    </div>
  );
};

export { SubmitterTypeDetails };
