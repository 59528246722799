import { useCallback, useEffect, useId, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { US_REGION_READABLE_NAMES, US_STATES_REGIONS_MAP } from '@purple/shared-types';
import { getGMTOffset } from '@purple/shared-utils';
import {
  Button,
  Checkbox,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Message,
  MultiSelect,
  MultiSelectItem,
  Separator,
  Switch,
  Text,
  TimePicker,
  useStepper,
} from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { useUnsavedChanges } from '~/providers';
import { useCreateDraftDistrict, useUpdateDraftDistrictBasic } from '~/services';
import { districtBasicSelector, maxDistrictStepSelector, setMaxStep } from '~/store/features/district-set-up';
import { DISTRICT_ID_QUERY_NAME } from '../../../constants';
import { StepCardContainer } from '../../StepCardContainer';
import { STATE_OPTIONS } from './constants';
import { convertBasicFormToRequest, convertBasicStateToForm } from './converter';
import { basicStepSchema } from './schema';
import { filterExistedRegionByState } from './utils';
import type { z } from 'zod';

const BasicStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { nextStep, activeStep } = useStepper();

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const [searchParameters, setSearchParameters] = useSearchParams();
  const districtId = searchParameters.get(DISTRICT_ID_QUERY_NAME);

  const formId = useId();

  const basicInfo = useAppSelector(districtBasicSelector);
  const maxDistrictStep = useAppSelector(maxDistrictStepSelector);

  const { mutate: createDraft, isPending: isCreating } = useCreateDraftDistrict();
  const { mutate: updateDraft, isPending: isUpdating } = useUpdateDraftDistrictBasic();

  const defaultValues = useMemo(() => {
    return convertBasicStateToForm(basicInfo);
  }, [basicInfo]);

  const form = useForm<z.infer<typeof basicStepSchema>>({
    resolver: zodResolver(basicStepSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    if (form.formState.isDirty) {
      setShouldShowUnsaved(true);
    }
  }, [form.formState.isDirty, setShouldShowUnsaved]);

  const formSchoolYearStartDatetime = form.watch('school_year_start_datetime');
  const formName = form.watch('name');
  const formStates = form.watch('states');
  const formRegions = form.watch('regions');
  const formBusinessDays = form.watch('business_days');

  const {
    fields: holidaysFields,
    remove: removeHoliday,
    append: appendHoliday,
  } = useFieldArray({
    name: 'holidays',
    control: form.control,
  });

  // Reset regions in case if states were changed
  useEffect(() => {
    if (formStates.length > 0 || (formRegions && formRegions.length > 0)) {
      const filteredRegions = filterExistedRegionByState(formStates, formRegions);
      form.setValue('regions', filteredRegions || []);
    } else {
      form.setValue('regions', []);
    }
  }, [formStates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.reset({
      ...defaultValues,
    });
  }, [defaultValues, form, districtId]);

  const regionsOptions = useMemo(() => {
    return formStates.flatMap((state) => {
      const regions = US_STATES_REGIONS_MAP[state as keyof typeof US_STATES_REGIONS_MAP];

      if (!regions) return [];
      return Object.entries(regions).map(([_label, value]) => {
        const readableLabel = US_REGION_READABLE_NAMES[value] as string;
        return {
          value,
          label: readableLabel,
        };
      });
    });
  }, [formStates]);

  const timezoneOptions = useMemo(() => {
    const timezones = Intl.supportedValuesOf('timeZone');

    const options = timezones
      .map((tz) => {
        const gmtOffset = getGMTOffset(tz);
        return {
          label: `(${gmtOffset}) ${tz}`,
          value: tz,
          offset: gmtOffset,
        };
      })
      .sort((a, b) => a.offset.localeCompare(b.offset));

    return options;
  }, []);

  const checkBusinessDays = () => {
    if (formBusinessDays.every((day) => !day.is_active)) {
      form.setError('business_days', {
        type: 'manual',
        message: 'At least one business day must be active',
      });
      return false;
    }
    return true;
  };

  const submitHandler = (data: z.infer<typeof basicStepSchema>, onSaveCallback?: () => void) => {
    const isBusinessDaysValid = checkBusinessDays();
    if (!isBusinessDaysValid) return;

    const body = convertBasicFormToRequest(data);

    if (districtId) {
      updateDraft(
        {
          districtId: +districtId,
          parameters: body,
        },
        {
          onSuccess: () => {
            setShouldShowUnsaved(false);
            onSaveCallback?.();
            nextStep();
          },
        },
      );
    } else {
      // First create district
      // Initial process
      createDraft(body, {
        onSuccess: (response) => {
          if (response.id) {
            setShouldShowUnsaved(false);

            // Set districtId to search parameters
            // To have possibility to track that district was created
            // And we can call update method instead of create
            setSearchParameters(
              (previous) => {
                const newSearchParameters = new URLSearchParams(previous);
                newSearchParameters.set(DISTRICT_ID_QUERY_NAME, response?.id?.toString() || '');
                return newSearchParameters;
              },
              {
                replace: true,
              },
            );
            onSaveCallback?.();
            nextStep();
          }
        },
      });
    }
  };

  const applyBusinessHoursToAllDaysHandler = () => {
    const firstActiveDay = formBusinessDays.find((day) => day.is_active);

    if (!firstActiveDay) return;

    const newDays = formBusinessDays.map((day) => {
      if (!day.is_active) return { ...day };

      return {
        ...day,
        start_time: firstActiveDay.start_time,
        end_time: firstActiveDay.end_time,
      };
    });

    form.reset({ ...form.getValues(), business_days: newDays });
  };

  const addMoreHolidaysHandler = () => {
    appendHoliday({
      name: '',
      start_datetime: new Date(),
      end_datetime: new Date(),
      is_recurring: false,
    });
  };

  const canSaveAsDraft = useMemo(() => formName !== '', [formName]);

  const navigateToDistrictList = useCallback(() => {
    navigate(AdminRoutes.App.Districts.Root.path);
  }, [navigate]);

  const increaseMaxStep = () => {
    if (maxDistrictStep === activeStep) {
      dispatch(setMaxStep(maxDistrictStep + 1));
    }
  };

  return (
    <StepCardContainer
      isStepValid={canSaveAsDraft}
      onSave={() => submitHandler(form.getValues(), navigateToDistrictList)}
      formId={formId}
      isLoading={isCreating || isUpdating}
    >
      <Form
        providerProps={form}
        id={formId}
        className="flex w-full flex-col gap-6"
        onSubmit={form.handleSubmit((data) => submitHandler(data, increaseMaxStep))}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Heading variant="size-18" type="heading-500" className="text-grey-950">
              District Information
            </Heading>
            <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
              Please provide all information relevant to the district. It will apply to all schools within this
              district. You can then adjust the information as needed for each individual school.
            </Text>
          </div>
          <div className="grid w-full grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>District Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      isError={!!form.formState.errors.name}
                      placeholder="Enter district name"
                      type="text"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="states"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel required>State</FormLabel>
                  <FormControl>
                    <MultiSelect
                      {...field}
                      isError={!!fieldState.error}
                      selectedOptions={STATE_OPTIONS.filter((tag) => field.value.includes(tag.value))}
                      placeholder="Select the state where your district is located"
                      modalPopover
                      onOptionChange={field.onChange}
                    >
                      {STATE_OPTIONS.map((option) => (
                        <MultiSelectItem
                          key={option.value}
                          value={option.value}
                          option={option}
                          isSelected={field.value.includes(option.value)}
                        />
                      ))}
                    </MultiSelect>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="regions"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>Region(s) Served</FormLabel>
                  <FormControl>
                    <MultiSelect
                      {...field}
                      isError={!!fieldState.error}
                      selectedOptions={regionsOptions.filter((region) => {
                        return field?.value?.includes(region.value);
                      })}
                      placeholder="Select the region where you serve"
                      modalPopover
                      onOptionChange={field.onChange}
                    >
                      {regionsOptions.map((option) => (
                        <MultiSelectItem
                          key={option.value}
                          value={option.value}
                          option={option}
                          isSelected={field?.value?.includes(option.value)}
                        />
                      ))}
                    </MultiSelect>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="subdomain"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Subdomain Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      isError={!!form.formState.errors.subdomain}
                      placeholder="Enter subdomain name"
                      type="text"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Separator />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Heading variant="size-18" type="heading-500" className="text-grey-950">
              Fiscal year (school year)
            </Heading>
          </div>
          <div className="grid w-full grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="school_year_start_datetime"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel required>Start Date</FormLabel>
                  <FormControl>
                    <DatePicker
                      mode="single"
                      placeholder="Select date"
                      formatterString="PPP"
                      isError={!!fieldState.error}
                      triggerDisabled={field.disabled}
                      defaultMonth={field.value}
                      selected={field.value}
                      captionLayout="dropdown"
                      onDayClick={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="school_year_end_datetime"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel required>End Date</FormLabel>
                  <FormControl>
                    <DatePicker
                      mode="single"
                      placeholder="Select date"
                      formatterString="PPP"
                      isError={!!fieldState.error}
                      triggerDisabled={field.disabled}
                      defaultMonth={field.value}
                      selected={field.value}
                      captionLayout="dropdown"
                      onDayClick={field.onChange}
                      disabled={formSchoolYearStartDatetime ? { before: formSchoolYearStartDatetime } : false}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Separator />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Heading variant="size-18" type="heading-500" className="text-grey-950">
              Business Hours
            </Heading>
          </div>
          <FormField
            control={form.control}
            name="timezone"
            render={({ field, fieldState }) => (
              <FormItem className="flex flex-col">
                <FormLabel required className="w-fit">Time Zone</FormLabel>
                <ComboBox modal>
                  <FormControl>
                    <ComboBoxTrigger
                      isError={!!fieldState.error}
                      placeholder="Select timezone"
                      selectedLabel={timezoneOptions.find((option) => option.value === field.value)?.label}
                      className="max-w-[530px]"
                    />
                  </FormControl>
                  <ComboBoxContent searchPlaceholder="Search timezone..." emptyContent="Timezone not found.">
                    {timezoneOptions.map(({ label, value }) => (
                      <ComboBoxItem
                        key={value}
                        value={value}
                        selected={value === field.value}
                        onSelect={field.onChange}
                      >
                        {label}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-1">
            {formBusinessDays.map((businessDayField, index) => {
              const { is_active, day } = businessDayField;
              return (
                <div key={day} className="flex flex-row items-start gap-6">
                  <div className="flex w-[150px] items-center gap-4 pt-[6px]">
                    <FormField
                      control={form.control}
                      name={`business_days.${index}.is_active`}
                      render={({ field }) => {
                        return (
                          <FormItem className="flex items-center">
                            <FormControl>
                              <Switch
                                defaultChecked={field.value}
                                value={field.value.toString()}
                                checked={field.value}
                                onClick={() => {
                                  field.onChange(!field.value);
                                }}
                              />
                            </FormControl>
                          </FormItem>
                        );
                      }}
                    />

                    <Text variant="size-16" type="body-500" className="capitalize text-grey-950">
                      {day}
                    </Text>
                  </div>
                  <div className="w-[170px]">
                    <FormField
                      control={form.control}
                      name={`business_days.${index}.start_time`}
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormControl>
                              <TimePicker date={field.value} setDate={field.onChange} disabled={!is_active} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  <div className="w-[170px]">
                    <FormField
                      control={form.control}
                      name={`business_days.${index}.end_time`}
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormControl>
                              <TimePicker date={field.value} setDate={field.onChange} disabled={!is_active} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  {index === 0 && (
                    <div className="pt-[6px]">
                      <Button variant="link" size="link" type="button" onClick={applyBusinessHoursToAllDaysHandler}>
                        Apply to all days
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
            <FormField
              control={form.control}
              name="business_days"
              render={({ field: _field, fieldState }) => {
                const message = form.formState.errors.business_days?.message || fieldState.error?.root?.message;

                if (!message) return <div />;
                return (
                  <Message variant="error" className="max-w-[540px]">
                    {message}
                  </Message>
                );
              }}
            />
          </div>
        </div>
        <Separator />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Heading variant="size-18" type="heading-500" className="text-grey-950">
              Holiday / School Closure
            </Heading>
          </div>
          {holidaysFields.map((holidayField, index) => {
            return (
              <div key={holidayField.id} className="flex flex-col gap-3 rounded-lg border border-grey-200 px-6 py-4">
                <div className="flex items-center gap-1">
                  <Text variant="size-16" type="body-600" className="text-grey-950">
                    Holiday / School Closure #
                    {index + 1}
                  </Text>
                  {holidaysFields.length > 1 && (
                    <Button
                      variant="link"
                      size="icon_32"
                      iconLeft={<PurpleIcon name="trash" className="text-error-main" />}
                      onClick={() => removeHoliday(index)}
                    />
                  )}
                </div>
                <div className="grid w-full grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name={`holidays.${index}.name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel required>School Closure Name</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            isError={!!form.formState.errors.holidays?.[index]?.name}
                            placeholder="Enter name"
                            type="text"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div />
                  <FormField
                    control={form.control}
                    name={`holidays.${index}.start_datetime`}
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel required>Start Date</FormLabel>
                        <FormControl>
                          <DatePicker
                            mode="single"
                            placeholder="Select date & time"
                            formatterString="PPP p"
                            isError={!!fieldState.error}
                            triggerDisabled={field.disabled}
                            defaultMonth={field.value}
                            selected={field.value}
                            captionLayout="dropdown"
                            onDayClick={field.onChange}
                            onTimeChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`holidays.${index}.end_datetime`}
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel required>End Date</FormLabel>
                        <FormControl>
                          <DatePicker
                            mode="single"
                            placeholder="Select date & time"
                            formatterString="PPP p"
                            isError={!!fieldState.error}
                            triggerDisabled={field.disabled}
                            defaultMonth={field.value}
                            selected={field.value}
                            captionLayout="dropdown"
                            onDayClick={field.onChange}
                            onTimeChange={field.onChange}
                            disabled={{
                              before: holidayField.start_datetime,
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`holidays.${index}.is_recurring`}
                    render={({ field }) => (
                      <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            {...field}
                            value={field.value.toString()}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel>Repeat each year</FormLabel>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            );
          })}
          <div>
            <Button variant="link" size="link" type="button" onClick={addMoreHolidaysHandler}>
              + Add more Holidays / School Closures
            </Button>
          </div>
        </div>
      </Form>
    </StepCardContainer>
  );
};

export { BasicStep };
