import axiosInstance from '../../axios-config';
import { ACTIVITIES_ENDPOINTS } from '../../endpoints';
import type { TActivityHistoryRequestParameters, TActivityHistoryResponse } from '../activities.types';

export const getActivityHistory = async ({ id, ...parameters }: TActivityHistoryRequestParameters) => {
  const response = await axiosInstance.get<TActivityHistoryResponse>(ACTIVITIES_ENDPOINTS.HISTORY(id), {
    params: parameters,
  });

  return response.data;
};
