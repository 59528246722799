import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getNotLoggedInUsersReportExport } from './get-not-logged-in-users-report-export';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TAdminUsersNotLoggedInReportParameters } from './user.types';

export const useNotLoggedInUsersReportExportMutation = () => {
  // NOTE: we use useMutation hook to export by clicking on the button (or another trigger)
  return useMutation<Blob, AxiosError, TAdminUsersNotLoggedInReportParameters>({
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_NOT_LOGGED_IN_USERS_REPORT_EXPORT],
    mutationFn: getNotLoggedInUsersReportExport,

    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not export users report. Check the provided information and try again');
      }
    },
    onSuccess: (data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      link.href = url;
      link.download = 'not-logged-in-users-report.xlsx';
      document.body.append(link);
      link.click();
      link.remove();

      // Clean up the URL object after the download is complete to avoid memory leaks
      window.URL.revokeObjectURL(url);
    },
  });
};
