import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminLoggedInByRoleReport } from '~/services';

type TReportColumns = ColumnDef<TAdminLoggedInByRoleReport>[];

export const reportColumns: TReportColumns = [
  {
    accessorKey: 'role_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
    cell: ({ row }) => {
      const { role } = row.original;
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{role}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {role}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 100,
    meta: { label: 'Role' },
  },
  {
    accessorKey: 'login_count',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Number of Logins" />,
    cell: ({ row }) => {
      const login_count = row.original.login_count;
      return (
        <span className="line-clamp-1 break-all text-grey-950">{login_count}</span>
      );
    },
    size: 100,
    meta: { label: 'Number of Logins', className: 'text-center' },
    enableSorting: false,
  },
  {
    accessorKey: 'district__name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row }) => {
      const district = row.original.district;
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{district || '-'}</span>
          </TooltipTrigger>
          <TooltipPortal>
            {Boolean(district) && (
              <TooltipContent align="start" withArrow={false}>
                {district}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 100,
    meta: { label: 'District' },
  },
];
