import { DISTRICT_SETUP_STEP, type TAdminTranslation, type TDistrictDraftDetail } from '@purple/shared-types';
import { timeStringToDate } from '@purple/shared-utils';
import { DEFAULT_ACKNOWLEDGEMENT_TEXT_VALUE, DEFAULT_DISCLAIMER_TEXT_VALUE, DEFAULT_INTRODUCTION_TEXT_VALUE, DEFAULT_SPECIAL_ED_TEXT_VALUE } from '~/pages/Main/Districts/DistrictSetUp/components/steps/SafProcessStep/constants';
import { SET_UP_STEPS } from '~/pages/Main/Districts/DistrictSetUp/constants';
import { DEFAULT_TRANSLATIONS_VALUES } from './districts.const';
import type { TDistrictSetUpState } from '~/store/features/district-set-up';

const getTextValue = (type: string) => {
  switch (type) {
    case 'introduction_text':
      return DEFAULT_INTRODUCTION_TEXT_VALUE;
    case 'acknowledgement_text':
      return DEFAULT_ACKNOWLEDGEMENT_TEXT_VALUE;
    case 'disclaimer_text':
      return DEFAULT_DISCLAIMER_TEXT_VALUE;
    case 'special_ed_text':
      return DEFAULT_SPECIAL_ED_TEXT_VALUE;
    default:
      return '';
  }
};

// eslint-disable-next-line ts/no-empty-object-type
export const convertIntoText = (translations: {} | TAdminTranslation, type: string) => {
  const isEmptyObject = Object.keys(translations).length === 0 && translations.constructor === Object;

  const parsedText = typeof translations === 'string' ? JSON.parse(translations as string) : DEFAULT_TRANSLATIONS_VALUES;

  const newEditableText = isEmptyObject
    ? getTextValue(type)
    : {
        ar: parsedText.ar,
        en: parsedText.en,
        es: parsedText.es,
        vi: parsedText.vi,
        zh: parsedText.zh,
      };

  return newEditableText;
};

export const convertDraftedDetailToState = (response: TDistrictDraftDetail): TDistrictSetUpState => {
  let setUpMaxStep = 0;
  if (response.current_setup_step === DISTRICT_SETUP_STEP.INITIAL) {
    setUpMaxStep = 0;
  } else {
    // response.current_setup_step - it is the last completed step
    const foundUpMaxStepIndex = SET_UP_STEPS.findIndex((step) => step.id === response.current_setup_step) || 0;

    // If we have next step, we should set it to the next step (to render next step by default)
    setUpMaxStep = SET_UP_STEPS.length - 1 > foundUpMaxStepIndex ? foundUpMaxStepIndex + 1 : SET_UP_STEPS.length - 1;
  }

  return {
    maxStep: setUpMaxStep,
    districtId: response.id,
    status: response.status,
    current_setup_step: response.current_setup_step,
    responsible_for_setup: response.responsible_for_setup,
    basic: {
      name: response.name,
      states: response.states,
      regions: response.regions,
      subdomain: response.subdomain,
      school_year_start_datetime: response.school_year_start_datetime,
      school_year_end_datetime: response.school_year_end_datetime,
      timezone: response.timezone,
      business_days: response.business_days.map((businessDay) => ({
        ...businessDay,
        start_time: timeStringToDate(businessDay.start_time).toISOString(),
        end_time: timeStringToDate(businessDay.end_time).toISOString(),
      })),
      holidays: response.holidays,
    },
    branding: {
      logo: response.logo,
      branding_color: response.branding_color,
    },
    safProcess: {
      can_student_submit_anonymously: response.can_student_submit_anonymously,
      can_teacher_submit_anonymously: response.can_teacher_submit_anonymously,
      can_parent_submit_anonymously: response.can_parent_submit_anonymously,
      can_community_member_submit_anonymously: response.can_community_member_submit_anonymously,
      introduction_text: convertIntoText(response.introduction_text, 'introduction_text'),
      acknowledgement_text: convertIntoText(response.acknowledgement_text, 'acknowledgement_text'),
      disclaimer_text: convertIntoText(response.disclaimer_text, 'disclaimer_text'),
      special_ed_text: convertIntoText(response.special_ed_text, 'special_ed_text'),
      needs: response.needs,
      show_city_or_region_hierarchy_field: response.show_city_or_region_hierarchy_field,
      email: response.email || '',
    },
    leaderUsersStep: {
      no_district_leader_available: response.no_district_leader_available,
      users: response.users.map((user) => {
        // NOTE: we take first role from the list of roles
        // Because we have only one role for the user during the district setup
        // But BE have their serializer where roles is array
        const role = user.prounitas_roles?.[0]?.id || null;
        return {
          id: user.id || '',
          email: user.email,
          prounitas_roles: role,
        };
      }),
    },
  };
};
