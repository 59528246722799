import { ReadableContactType } from '@purple/shared-types';
import { formatDate } from '@purple/shared-utils';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TContactItem } from '~/services';

export const contactsPrintColumns: ColumnDef<TContactItem>[] = [
  {
    accessorKey: 'last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
    cell: ({ row: { original } }) => (original.full_name && original.full_name.length > 0 ? <span>{original.full_name}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 300,
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
    cell: ({ row: { original } }) => (original.email && original.email.length > 0 ? <span>{original.email}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'contact_type',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
    cell: ({ row: { original } }) => (original.contact_type && original.contact_type.length > 0 ? <span>{ReadableContactType[original.contact_type]}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'district',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row: { original } }) => (original.district && original.district.name.length > 0 ? <span>{original.district.name}</span> : '—'),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
    cell: ({ row: { original } }) => (original.created_at ? formatDate(original.created_at) : '—'),
    enableSorting: false,
    enableHiding: false,
    size: 150,
  },
];
