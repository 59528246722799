import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { getImpersonateResources } from './get-impersonate-resources';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TImpersonateUserResourcesResponse } from './user.types';

export const useImpersonateResources = (
  userId: string,
  options?: TCustomUseQueryOptions<TImpersonateUserResourcesResponse, AxiosError>,
) => {
  const query = useQuery({
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_IMPERSONATE_RESOURCES, userId],
    queryFn: () => getImpersonateResources(userId),
    ...options,
  });

  useEffect(() => {
    if (query.isError && isClientError(query.error)) {
      showErrorToast('System message', 'Unable to fetch contact details');
    }
  }, [query]);

  return query;
};
