import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader, Text } from '@purple/ui';
import { type TUserBasicInfo, useUpdateUserBasicDetails } from '~/services';
import { BasicGroupsEdit } from './BasicGroupsEdit';
import { BasicGroupsView } from './BasicGroupsView';
import { updateBasicGroupsSchema } from './updateBasicGroupsSchema';
import type * as z from 'zod';

type TBasicGroupsAreaProperties = {
  data: TUserBasicInfo;
};

const BasicGroupsArea = ({ data }: TBasicGroupsAreaProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateUserBasicDetails();

  const defaultValues = useMemo(() => ({
    groups: data.groups.map((group) => ({ label: group.name, value: group.id.toString() })),
  }), [data]);

  const form = useForm<z.infer<typeof updateBasicGroupsSchema>>({
    resolver: zodResolver(updateBasicGroupsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const handleSave = (newFormData: z.infer<typeof updateBasicGroupsSchema>) => {
    const payload = {
      prounitas_groups: newFormData?.groups?.map((group) => Number(group.value)) || [],
    };

    mutate({
      userId: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
        title={<Text variant="size-16" type="body-500" className="text-grey-title">Groups</Text>}
      />
      {mode === 'view' ? <BasicGroupsView data={data} /> : <BasicGroupsEdit form={form} />}
    </div>
  );
};

export { BasicGroupsArea };
