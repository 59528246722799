export const DEFAULT_INTRODUCTION_TEXT_VALUE = {
  ar: '',
  en: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        attrs: {
          textAlign: 'left',
        },
        content: [
          {
            type: 'text',
            text: 'The Student Assistance Form is meant to notify your School-Based Support staff of a non-emergency concern that requires attention and support. Most SAFs are responded to within 2 business days.',
          },
        ],
      },
    ],
  },
  es: '',
  vi: '',
  zh: '',
};

export const DEFAULT_ACKNOWLEDGEMENT_TEXT_VALUE = {
  ar: '',
  en: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        attrs: {
          textAlign: 'left',
        },
        content: [
          {
            type: 'text',
            text: 'Thank you for submitting the Student Assistance Form. The members of the Student Support Team will start to process your SAF shortly. If you are in crisis and need immediate emotional/mental health support, dial ',
          },
          {
            type: 'text',
            marks: [
              {
                type: 'link',
                attrs: {
                  href: 'tel:988',
                  target: '_blank',
                  class: 'link',
                },
              },
            ],
            text: '988',
          },
          {
            type: 'text',
            text: ' to speak to a trained mental health professional.',
          },
        ],
      },
    ],
  },
  es: '',
  vi: '',
  zh: '',
};

export const DEFAULT_DISCLAIMER_TEXT_VALUE = {
  ar: '',
  en: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        attrs: {
          textAlign: 'left',
        },
        content: [
          {
            type: 'text',
            text: 'If this is a student emergency please contact the proper authorities (911, CPS – if you are a mandated reporter, etc.) along with submitting this referral. If this SAF is concerning suicidal thoughts or suicidal ideation, completing this form should NOT be your primary method of contact and make sure to immediately contact appropriate personnel at your campus. The National Suicide Lifeline provides free and confidential support 24/7 and can be contacted at ',
          },
          {
            type: 'text',
            marks: [
              {
                type: 'link',
                attrs: {
                  href: 'tel:18002738255 ',
                  target: '_blank',
                  class: 'link',
                },
              },
            ],
            text: '1-800-273-8255 ',
          },
          {
            type: 'text',
            text: 'and ',
          },
          {
            type: 'text',
            marks: [
              {
                type: 'link',
                attrs: {
                  href: 'https://www.suicidepreventionlifeline.org',
                  target: '_blank',
                  class: 'link',
                },
              },
            ],
            text: 'www.suicidepreventionlifeline.org',
          },
          {
            type: 'text',
            text: ' .',
          },
        ],
      },
    ],
  },
  es: '',
  vi: '',
  zh: '',
};

export const DEFAULT_SPECIAL_ED_TEXT_VALUE = {
  ar: '',
  en: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        attrs: {
          textAlign: 'left',
        },
        content: [
          {
            type: 'text',
            text: 'If the referred student has a Special Education IEP or is 504, please consult with their case manager before submitting a referral (not applicable to service provider).',
          },
        ],
      },
    ],
  },
  es: '',
  vi: '',
  zh: '',
};
