import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { MultiSelect, MultiSelectItem } from '@purple/ui';
import { useDistrictsListBasicInfo } from '~/services';
import type React from 'react';
import type { TDistrictStatus } from '@purple/shared-types';
import type { TMultiSelectOption } from '@purple/ui';

type TDistrictMultiSelectProperties = {
  /**
   * The selected value of the combobox.
   */
  values: string[];
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange: (value: string[]) => void;
  /**
   * Placeholder text for the combobox.
   */
  placeholder?: string;
  /**
   * The maximum number of districts to fetch.
   * @default 10
   */
  limit?: number;
  /**
   * The status of the districts to fetch.
   */
  status?: TDistrictStatus;
  /**
   * The class name to apply to the combobox.
   */
  className?: string;
  /**
   * Whether the combobox is disabled.
   */
  disabled?: boolean;
};

export const DistrictMultiSelect: React.FC<TDistrictMultiSelectProperties> = (props) => {
  const {
    values,
    onChange,
    placeholder = 'All districts',
    limit = 10,
    status,
    className,
    disabled = false,
  } = props;
  const [search, setSearch] = useState<string>('');
  const [debouncedSearchValue] = useDebounceValue(search, 500);
  const [selectedDistricts, setSelectedDistricts] = useState<TMultiSelectOption[]>([]);

  const { data: allDistrictData, isFetching } = useDistrictsListBasicInfo({
    search: debouncedSearchValue,
    limit,
    status,
  });

  const districts = useMemo(() => allDistrictData?.results ?? [], [allDistrictData?.results]);

  const districtSelectOptions = useMemo(() => districts.map((district) => ({
    label: district.name,
    value: district.id.toString(),
  })), [districts]);

  useEffect(() => {
    if (selectedDistricts.length !== values.length || selectedDistricts.some((district) => !values.includes(district.value))) {
      setSelectedDistricts(
        (prevSelectedDistricts) => [...prevSelectedDistricts, ...districtSelectOptions]
          .filter((item, index, self) => index === self.findIndex((o) => o.value === item.value))
          .filter((item) => values.includes(item.value)),
      );
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectValueHandler = useCallback(
    (newValue: string[]) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <MultiSelect
      selectedOptions={selectedDistricts}
      modalPopover
      placeholder={placeholder}
      showSearch
      searchValue={search}
      shouldFilter={false}
      loading={isFetching}
      onSearchChange={setSearch}
      onOptionChange={selectValueHandler}
      maxCount={1}
      disabled={disabled}
      className={className}
    >
      {districtSelectOptions.map((district) => (
        <MultiSelectItem
          key={district.value}
          value={district.value}
          option={district}
          isSelected={values.includes(district.value.toString())}
        />
      ))}
    </MultiSelect>
  );
};
