import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Card,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import type { TContactPriorityListItem } from '~/services';

type TPriorityListCardProps = {
  item: TContactPriorityListItem;
  onDelete?: (list: TContactPriorityListItem) => void;
  onShare?: (list: TContactPriorityListItem) => void;
  onEdit?: (list: TContactPriorityListItem) => void;
  onSendEmail?: (list: TContactPriorityListItem) => void;
  onViewAll?: (list: TContactPriorityListItem) => void;
};

export const PriorityListCard: React.FC<TPriorityListCardProps> = (props) => {
  const { item, onDelete, onEdit, onSendEmail, onShare, onViewAll } = props;

  return (
    <Card tag="li" className="gap-4 p-4 transition-all duration-200 hover:border-brand-blue-500 hover:shadow-custom-medium">
      <div className="flex w-full gap-4">
        <div className="flex w-full flex-col gap-1">
          <Text tag="strong" type="body-600" variant="size-14" className="text-grey-title">
            Priority List Name
          </Text>
          <Text tag="p" type="body-400" variant="size-14" className="text-grey-950">
            {item.name}
          </Text>
        </div>
        <DropdownRoot>
          <Tooltip>
            <DropdownTrigger asChild>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="tertiary"
                  size="icon_32"
                  className="size-8 shrink-0"
                  iconRight={<PurpleIcon name="dots-vertical" />}
                  onFocusCapture={(evt) => evt.stopPropagation()}
                />
              </TooltipTrigger>
            </DropdownTrigger>
            <TooltipPortal>
              <TooltipContent>Actions</TooltipContent>
            </TooltipPortal>
          </Tooltip>
          <DropdownContent align="end" className="w-48">
            <DropdownItem
              iconName="pencil"
              onClick={() => onEdit?.(item)}
            >
              Edit Priority List
            </DropdownItem>
            <DropdownItem
              iconName="share"
              onClick={() => onShare?.(item)}
            >
              Share Priority List
            </DropdownItem>
            <DropdownItem
              iconName="trash"
              className="cursor-pointer text-error-main transition-colors duration-200 hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main"
              onClick={() => onDelete?.(item)}
            >
              Delete List
            </DropdownItem>
          </DropdownContent>
        </DropdownRoot>
      </div>
      <div className="flex w-full flex-col gap-2">
        <div className="flex flex-col gap-1">
          <Text tag="strong" type="body-600" variant="size-14" className="text-grey-title">
            District
          </Text>
          <Text tag="p" type="body-400" variant="size-14" className="text-grey-950">
            {item.district.name ?? 'Unknown'}
          </Text>
        </div>
        <div className="flex flex-col gap-1">
          <Text tag="strong" type="body-600" variant="size-14" className="text-grey-title">
            Description
          </Text>
          <Text tag="p" type="body-400" variant="size-14" className="text-grey-950">
            {item.description || 'No description'}
          </Text>
        </div>
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex flex-col gap-1">
            <Text tag="strong" type="body-600" variant="size-14" className="text-grey-title">
              People
            </Text>
            <Text tag="p" type="body-400" variant="size-14" className="text-grey-950">
              {item.total_contacts}
            </Text>
          </div>
          <Button type="button" variant="link" size="small" className="h-auto p-0" onClick={() => onViewAll?.(item)}>
            View All
          </Button>
        </div>
      </div>
      <Button type="button" disabled={item.total_contacts === 0} onClick={() => onSendEmail?.(item)}>Send Email to All</Button>
    </Card>
  );
};
