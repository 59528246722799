import { useMutation } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { getActivitiesIdList } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActivityIdListResponse } from '../activities.types';

export const useActivityIdList = (
  mutationOptions?: TCustomUseMutationOptions<TActivityIdListResponse, AxiosError>,
) => {
  return useMutation({
    mutationKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_ID_LIST],
    mutationFn: getActivitiesIdList,
    onError: (error) => {
      if (isClientError(error)) {
        showErrorToast('System message', 'Failed to select all activities');
      }
    },
    ...mutationOptions,
  });
};
