import { Fragment } from 'react';
import { Checkbox, Input, Separator, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { SAF_FIELD_SETTINGS_HEADING } from '~/pages/Main/Saf/constants';
import type { TSafLanguages } from '~/pages/Main/Saf/constants';
import type { TAdminSubmitterTypeFieldView } from '~/services';

type TFieldSettingsTableEditProperties = {
  data: TAdminSubmitterTypeFieldView | null;
  selectedLanguage: TSafLanguages;
  onChange: (data: TAdminSubmitterTypeFieldView) => void;
};

const FieldSettingsTableEdit = ({ data, selectedLanguage, onChange }: TFieldSettingsTableEditProperties) => {
  if (!data) return null;

  const translationChangeHandler = (sectionName: string, itemId: string, value: string) => {
    const updatedData = {
      ...data,
      [sectionName]: data[sectionName]?.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            translations: {
              ...item.translations,
              [selectedLanguage]: value,
            },
          };
        }
        return item;
      }) || [],
    };

    onChange(updatedData);
  };

  const requiredChangeHandler = (sectionName: string, itemId: string, value: boolean) => {
    const updatedData = {
      ...data,
      [sectionName]: data[sectionName]?.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            is_required: value,
          };
        }
        return item;
      }) || [],
    };

    onChange(updatedData);
  };

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-3 items-center gap-x-4 px-[20px] py-4">
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">FIELD NAME</Text>
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">TRANSLATION</Text>
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">REQUIRED</Text>
      </div>
      <Separator className="bg-grey-200" />
      <div className="px-[20px] py-3">
        <Text variant="size-14" type="body-700" className="text-grey-950">My Information</Text>
      </div>
      {data?.[SAF_FIELD_SETTINGS_HEADING.my_information]?.map((field) => (
        <Fragment key={field.id}>
          <Separator className="bg-grey-200" />
          <div className="grid grid-cols-3 items-center gap-x-4 px-[20px] py-4">
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.field_label}</Text>
            <div>
              <Input value={field.translations[selectedLanguage]} onChange={(event) => translationChangeHandler(SAF_FIELD_SETTINGS_HEADING.my_information, field.id, event.target.value)} sizeVariant="small" />
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex w-fit">
                  <Checkbox
                    checked={field.is_required}
                    onCheckedChange={(value) => requiredChangeHandler(SAF_FIELD_SETTINGS_HEADING.my_information, field.id, Boolean(value))}
                    disabled={field.is_always_required}
                  />
                </div>
              </TooltipTrigger>
              {field.is_always_required && (
                <TooltipPortal>
                  <TooltipContent>This field can not be changed</TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          </div>
        </Fragment>
      ))}
      <Separator className="bg-grey-200" />
      <div className="px-[20px] py-3">
        <Text variant="size-14" type="body-700" className="text-grey-950">Student Information</Text>
      </div>
      {data?.[SAF_FIELD_SETTINGS_HEADING.student_information]?.map((field) => (
        <Fragment key={field.id}>
          <Separator className="bg-grey-200" />
          <div className="grid grid-cols-3 items-center gap-x-4 px-[20px] py-4">
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.field_label}</Text>
            <div>
              <Input value={field.translations[selectedLanguage]} onChange={(event) => translationChangeHandler(SAF_FIELD_SETTINGS_HEADING.student_information, field.id, event.target.value)} sizeVariant="small" />
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex w-fit">
                  <Checkbox
                    checked={field.is_required}
                    onCheckedChange={(value) => requiredChangeHandler(SAF_FIELD_SETTINGS_HEADING.student_information, field.id, Boolean(value))}
                    disabled={field.is_always_required}
                  />
                </div>
              </TooltipTrigger>
              {field.is_always_required && (
                <TooltipPortal>
                  <TooltipContent>This field can not be changed</TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          </div>
        </Fragment>
      ))}
      <Separator className="bg-grey-200" />
      <div className="px-[20px] py-3">
        <Text variant="size-14" type="body-700" className="text-grey-950">Needs Information</Text>
      </div>
      {data?.[SAF_FIELD_SETTINGS_HEADING.needs_information]?.map((field) => (
        <Fragment key={field.id}>
          <Separator className="bg-grey-200" />
          <div className="grid grid-cols-3 items-center gap-x-4 px-[20px] py-4">
            <Text variant="size-14" type="body-500" className="text-grey-950">{field.field_label}</Text>
            <div>
              <Input value={field.translations[selectedLanguage]} onChange={(event) => translationChangeHandler(SAF_FIELD_SETTINGS_HEADING.needs_information, field.id, event.target.value)} sizeVariant="small" />
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex w-fit">
                  <Checkbox
                    checked={field.is_required}
                    onCheckedChange={(value) => requiredChangeHandler(SAF_FIELD_SETTINGS_HEADING.needs_information, field.id, Boolean(value))}
                    disabled={field.is_always_required}
                  />
                </div>
              </TooltipTrigger>
              {field.is_always_required && (
                <TooltipPortal>
                  <TooltipContent>This field can not be changed</TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export { FieldSettingsTableEdit };
