import { useQueryParameter } from '@purple/hooks';
import { PriorityListQueryName, PriorityListViewType } from './constants';
import { DetailsView } from './DetailsView';
import { ListView } from './ListView';
import type { TPriorityListViewType } from './constants';

export const PriorityListsTab: React.FC = () => {
  const { query: viewQuery } = useQueryParameter<TPriorityListViewType>({
    queryName: PriorityListQueryName.VIEW,
    defaultValue: PriorityListViewType.LIST,
  });
  const { query: listId } = useQueryParameter({
    queryName: PriorityListQueryName.ID,
  });

  if (viewQuery === PriorityListViewType.DETAILS && listId !== undefined) {
    return <DetailsView />;
  }

  return <ListView />;
};
