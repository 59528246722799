import { type FC, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, Button, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { type TAdminSafRuleItem, useEditRoutingRules, useUsersList } from '~/services';
import { showSuccessToast } from '~/utils/toasts';

const DEFAULT_SEARCH_DELAY = 400;

type TAssignContactCellProps = {
  rowData: TAdminSafRuleItem;
};

export const AssignContactCell: FC<TAssignContactCellProps> = ({ rowData }) => {
  const [searchParameters] = useSearchParams();

  const [isEditing, setIsEditing] = useState(false);
  const [assignedUser, setAssignedUser] = useState(rowData.assigned_user?.id);

  const [usersDebouncedSearchValue, setUsersDebouncedSearchValue] = useState<string>('');

  const selectedSchools = searchParameters.get('schools');

  const { data: users, isLoading: isUserLoading } = useUsersList({
    requestParameters: {
      search: usersDebouncedSearchValue,
      school: selectedSchools?.split(','),
    },
    enabled: !!selectedSchools && selectedSchools.length > 0,
  });

  const { mutate, isPending } = useEditRoutingRules();

  const usersSelectOptions = useMemo(() => {
    return (
      users?.results.map((user) => ({
        ...user,
        full_name: user.full_name ?? '',
        avatar: user.avatar ?? '',
        label: user.full_name ?? user.email,
        value: user.id,
      })) ?? []
    );
  }, [users]);

  const usersDebounceSearch = useDebouncedCallback((searchQuery: string) => {
    setUsersDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const updateAssignedUser = () => {
    if (assignedUser) {
      mutate({
        id: rowData.id,
        payload: {
          assigned_user: assignedUser,
        },
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'Assigned contact updated successfully');
          setIsEditing(false);
        },
      });
    }
  };

  if (isEditing) {
    return (
      <div className="flex items-center gap-2">
        <ComboBox modal>
          <ComboBoxTrigger
            placeholder="Select contact"
            selectedLabel={usersSelectOptions.find((option) => option.value === assignedUser)?.label}
            disabled={selectedSchools?.length === 0}
          />
          <ComboBoxContent
            loading={isUserLoading}
            shouldFilter={false}
            searchPlaceholder="Search by name"
            emptyContent="User not found."
            onSearchChange={usersDebounceSearch}
            className="w-96"
            align="start"
            useTriggerWidth={false}
          >
            {usersSelectOptions.map(({ value, avatar, email, full_name, label }) => (
              <ComboBoxItem
                key={value}
                value={value}
                selected={value === assignedUser}
                onSelect={(value) => setAssignedUser(value)}
              >
                <div className="flex items-center gap-3">
                  <Avatar size={32}>
                    <AvatarImage src={avatar ?? undefined} />
                    <AvatarFallback className="bg-grey-200">
                      {getInitialsFromName(full_name ?? '—')}
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex flex-col gap-1">
                    <Text variant="size-12" type="body-500" className="text-grey-950">
                      {label}
                    </Text>
                    <Text variant="size-12" type="body-400" className="text-grey-600">
                      {email}
                    </Text>
                  </div>
                </div>
              </ComboBoxItem>
            ))}
          </ComboBoxContent>
        </ComboBox>
        <div className="flex items-center gap-1">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="primary"
                size="icon_32"
                className="shadow-custom-medium"
                isLoading={isPending}
                iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                onClick={updateAssignedUser}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>Save</TooltipContent>
            </TooltipPortal>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="secondary"
                size="icon_32"
                className="border-0 shadow-custom-medium"
                iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                onClick={() => setIsEditing(false)}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>Cancel</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </div>
      </div>
    );
  }
  return (
    rowData.assigned_user && rowData.assigned_user.full_name && rowData.assigned_user.full_name.length > 0
      ? (
          <div className="flex items-center gap-2">
            <Tooltip>
              <TooltipTrigger asChild>
                <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({
                  dynamicParameters: { id: rowData.assigned_user.id },
                })}
                >
                  <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{rowData.assigned_user.full_name}</span>
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {rowData.assigned_user.full_name}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
            {selectedSchools && selectedSchools.length > 0 && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="tertiary" className="h-auto p-2" onClick={() => setIsEditing(true)}>
                    <PurpleIcon name="pencil" className="size-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Edit assigned contact</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )}

          </div>
        )
      : (
          <span>—</span>
        )

  );
};
