import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { DISTRICT_STATUS, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import {
  AppFilters,
  Button,
  SearchInput,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { CallToActionModal, DataTable, DataTableSelectedController, DataTableViewOptions, DistrictFilterComboBox } from '~/components';
import { ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useActivitiesBulkDelete, useActivityFilterChoices, useActivityIdList, useActivityList, useDeleteActivity, useExportActivities } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { DURATION_FILTERS } from './helpers';
import { useActivityColumns } from './useActivityColumns';
import type { TActivityFilterChoices, TActivityListItem } from '~/services';

export const AllActivitiesTab: React.FC = () => {
  const [searchParameters] = useSearchParams();

  const { query: selectedDistrict, onQueryChange, onClearQuery } = useQueryParameter({ queryName: 'district', resetOffset: true });

  const { openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal(ModalType.DELETE_ACTIVITY);
  const { openModal: openBulkDeleteModal, closeModal: closeBulkDeleteModal } = useModal(ModalType.BULK_DELETE_ACTIVITIES);

  const [selectedActivity, setSelectedActivity] = useState<TActivityListItem | null>(null);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = useActivityList({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    district: selectedDistrict,
    school: searchParameters.get('school'),
    creator: searchParameters.get('creator'),
    duration: searchParameters.get('duration'),
    community_activity_type: searchParameters.get('type'),
  });
  const { data: options, isFetching: isOptionsLoading } = useActivityFilterChoices({
    select: useCallback((data: TActivityFilterChoices) => ({
      school: data.school,
      type: data.type,
      creator: data.creator.map((creator) => ({ ...creator, name: creator.full_name })),
      duration: DURATION_FILTERS,
    }), []),
  });
  const { mutate: selectAllAvailable, isPending: isIdListPending } = useActivityIdList();
  const { mutate: deleteActivity, isPending: isDeletePending } = useDeleteActivity();
  const { mutate: deleteBulkActivities, isPending: isBulkDeletePending } = useActivitiesBulkDelete();
  const { mutate: exportActivities, isPending: isExportPending } = useExportActivities({
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    district: selectedDistrict,
    school: searchParameters.get('school'),
    creator: searchParameters.get('creator'),
    duration: searchParameters.get('duration'),
    community_activity_type: searchParameters.get('type'),
  });

  const activities = useMemo(() => data?.results ?? [], [data?.results]);
  const filterConfig = useMemo(
    () => (options
      ? convertToFilterConfig(
          Object.entries(options).reduce(
            (accumulator, [key, value]) => ({ ...accumulator, [key]: value }),
            {},
          ),
          { snakeCaseValue: false },
        )
      : { categories: [] }),
    [options],
  );

  const deleteActivitySubmitHandler = useCallback(() => {
    if (!selectedActivity) {
      return showErrorToast('Activity not found', 'Please select an activity to delete');
    }

    deleteActivity(selectedActivity.id, {
      onSuccess: () => {
        closeDeleteModal();
        setSelectedActivity(null);
      },
    });
  }, [deleteActivity, closeDeleteModal, selectedActivity]);

  const activityDeleteChangeHandler = useCallback(
    (activity: TActivityListItem) => {
      setSelectedActivity(activity);
      openDeleteModal();
    },
    [openDeleteModal],
  );

  const columns = useActivityColumns({
    onDelete: activityDeleteChangeHandler,
  });

  const { table } = useDataTable({
    name: TableName.ACTIVITIES,
    columns,
    data: activities,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
        right: ['actions'],
      },
      columnVisibility: {
        campus: false,
        updated_at: false,
        description: false,
        service_categories__name: false,
      },
    },
    getRowId: (originalRow) => originalRow.id,
    onSelectionChange: (rows, setSelectedRows) => {
      const selectedIds = Object.entries(rows).flatMap(([id, selected]) => (selected ? [id] : []));
      setSelectedRows(
        (prevRows) => [
          ...prevRows,
          ...activities.map(({ id, name }) => ({ id, name })),
        ]
          .filter((activity, index, self) => index === self.findIndex((a) => a.id === activity.id))
          .filter((activity) => selectedIds.includes(activity.id)),
      );
    },
  });

  const districtChangeHandler = useCallback(
    (districtId: string | null) => {
      if (districtId === null) {
        onClearQuery();
      } else {
        onQueryChange(districtId);
      }
    },
    [onQueryChange, onClearQuery],
  );

  const closeModalHandler = useCallback(() => {
    setSelectedActivity(null);
  }, []);

  const deleteSelectedActivitiesClickHandler = useCallback(() => {
    openBulkDeleteModal();
  }, [openBulkDeleteModal]);

  const deleteSelectedActivitiesConfirmHandler = useCallback(() => {
    if (table.options.meta?.selectedRows?.length === 0) {
      return showErrorToast('Activities not found', 'Please select activities to delete');
    }
    const selectedRows = table.options.meta?.selectedRows ?? [];
    const ids = selectedRows.map((row) => row.original.id);
    deleteBulkActivities({
      activities: ids,
    }, {
      onSuccess: () => {
        closeBulkDeleteModal();
        setSelectedActivity(null);
        table.options.meta?.clearSelectedRows?.();
      },
    });
  }, [table, deleteBulkActivities, closeBulkDeleteModal]);

  const selectAllUsers = useCallback(() => {
    selectAllAvailable({
      limit: data?.count,
      offset: 0,
      ordering: searchParameters.get(SORT_QUERY_NAME),
      search: debounceSearch,
      district: selectedDistrict,
      school: searchParameters.get('school'),
      creator: searchParameters.get('creator'),
      duration: searchParameters.get('duration'),
      community_activity_type: searchParameters.get('type'),
    }, {
      onSuccess: (data) => {
        const selectedRows = data.results.reduce<Record<string, boolean>>((acc, item) => ({
          ...acc,
          [item.id]: true,
        }), {}) ?? {};
        table.setRowSelection(selectedRows);
        table.options.meta?.setSelectedRows?.(data.results);
      },
    });
  }, [selectAllAvailable, table, data?.count, searchParameters, debounceSearch, selectedDistrict]);

  return (
    <DataTable table={table} loading={isFetching}>
      <div className="flex flex-col gap-4 px-4 pb-8 pt-6">
        <div className="flex w-full flex-wrap items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <DistrictFilterComboBox
              value={selectedDistrict}
              status={DISTRICT_STATUS.PUBLISHED}
              align="start"
              className="w-fit min-w-40 max-w-72 gap-8"
              contentClassName="min-w-80"
              onChange={districtChangeHandler}
              onClear={onClearQuery}
            />
            <AppFilters config={filterConfig} loading={isOptionsLoading} />
            <SearchInput
              value={search}
              placeholder="Search"
              className="max-w-[300px]"
              onChange={onSearchChange}
              onClear={onClearSearch}
            />
          </div>
          <div className="flex items-center gap-4">
            {table.options.meta?.selectedRows && table.options.meta?.selectedRows.length > 0 && (
              <Button type="button" variant="destructive_secondary" onClick={deleteSelectedActivitiesClickHandler}>
                Delete activities
              </Button>
            )}
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="secondary"
                  size="icon_40"
                  className="size-10 shrink-0"
                  iconLeft={<PurpleIcon name="download" />}
                  disabled={activities.length === 0}
                  isLoading={isExportPending}
                  onClick={exportActivities}
                />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>Export Table to Excel</TooltipContent>
              </TooltipPortal>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="secondary"
                  size="icon_40"
                  className="size-10 shrink-0"
                  // isLoading={}
                  // onClick={}
                  iconLeft={<PurpleIcon name="printer" />}
                />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>Print Table</TooltipContent>
              </TooltipPortal>
            </Tooltip>
            <DataTableViewOptions table={table} />
          </div>
        </div>
        <DataTableSelectedController table={table} isSelectAllLoading={isIdListPending} onSelectAll={selectAllUsers} />
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_ACTIVITY}
        modalTitle="Delete Activity"
        modalDescription="By deleting this activity, it will be removed from the system and can't be recovered."
        modalTextContent="Are you sure you want to delete this activity?"
        primaryButtonText={isDeletePending ? 'Deleting' : 'Delete'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteActivitySubmitHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
        onClose={closeModalHandler}
      />
      <CallToActionModal
        modalName={ModalType.BULK_DELETE_ACTIVITIES}
        modalTitle="Delete all selected activities"
        modalDescription="By deleting selected activities, it will be removed from the system and can't be recovered."
        modalTextContent="Are you sure you want to delete all selected activities?"
        primaryButtonText={isBulkDeletePending ? 'Deleting' : 'Delete'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteSelectedActivitiesConfirmHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isBulkDeletePending}
      />
    </DataTable>
  );
};
