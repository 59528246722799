import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSubmitterTypeTextTranslations } from './get-submitter-type-text-translations';
import { ADMIN_SUBMITTER_TYPES_QUERY_KEYS } from './submitterTypes.const';
import type { AxiosError } from 'axios';
import type { TAdminSubmitterTypeTextTranslation } from './submitterTypes.types';

type TUseSubmitterTypeTextTranslationProperties = {
  typeId?: string;
};

export const useSubmitterTypeTextTranslation = ({
  typeId,
}:
TUseSubmitterTypeTextTranslationProperties) => {
  const id = typeId || '';

  const { isError, error, ...rest } = useQuery<TAdminSubmitterTypeTextTranslation, AxiosError>({
    queryKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.TEXT_TRANSLATION, typeId],
    queryFn: () => getSubmitterTypeTextTranslations({ typeId: id }),
    enabled: !!typeId,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch submitter type text translations');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
