import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { FILE_QUERY_KEYS } from './files.const';
import { updateFile } from './update-file';
import type { AxiosError } from 'axios';
import type { TFile, TFileUpdatePayload } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useUpdateFile = (
  mutationOptions?: TCustomUseMutationOptions<TFile, AxiosError<Partial<Record<string, string[]>>>, TFileUpdatePayload>,
) => {
  const queryClient = useQueryClient();
  return useMutation<TFile, AxiosError<Partial<Record<string, string[]>>>, TFileUpdatePayload>({
    mutationKey: [FILE_QUERY_KEYS.UPDATE_FILE],
    mutationFn: updateFile,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEYS.GET_FILES_LIST] });
      queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEYS.GET_FILE_BY_ID, data.id] });
      showSuccessToast('System message', 'File updated successfully');
    },
    onError: (error) => {
      if (error?.response?.data.file) {
        showErrorToast('System Message', error.response.data.file.join(', '));
        return;
      }
      showErrorToast('System Message', 'Failed to update the file');
    },
    ...mutationOptions,
  });
};
