import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { MultiSelect, MultiSelectItem } from '@purple/ui';
import { useUserSchools } from '~/services';
import type { TMultiSelectOption } from '@purple/ui';

const LIMIT = 5;

type TSchoolMultiSelectProperties = {
  /*
   * The name of the property that the combobox is filtering.
  */
  districtId?: string;
  /*
   * Whether the district is required.
  */
  isDistrictRequired?: boolean;
  /**
   * The selected value of the combobox.
   */
  values: string[];
  /**
   * Whether the combobox is in an error state.
   * @default false
   */
  isError?: boolean;
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange: (value: string[]) => void;
  /*
  * Callback that is called when the combobox is cleared
  */
  onClear: () => void;
  /**
   * Placeholder text for the combobox.
   */
  placeholder?: string;
};

const SchoolMultiSelect: React.FC<TSchoolMultiSelectProperties> = (props) => {
  const { values, onChange, placeholder = 'Select school(s)', districtId, isDistrictRequired = false } = props;

  const [search, setSearch] = useState<string>('');
  const [debouncedSearchValue] = useDebounceValue(search, 500);
  const [selectedSchools, setSelectedSchools] = useState<TMultiSelectOption[]>([]);

  const { data: schoolsData, isLoading: isSchoolsLoading } = useUserSchools({
    requestParameters: {
      district: districtId,
      search: debouncedSearchValue,
      limit: LIMIT,
    },
    enabled: isDistrictRequired && Boolean(districtId),
  });

  const schoolsSelectOptions = useMemo(() => schoolsData?.results.map((school) => ({
    ...school,
    label: school.name,
    value: school.id,
  })) ?? [], [schoolsData]);

  useEffect(() => {
    if (selectedSchools.length !== values.length || selectedSchools.some((district) => !values.includes(district.value))) {
      setSelectedSchools(
        (prevSelectedDistricts) => [...prevSelectedDistricts, ...schoolsSelectOptions]
          .filter((item, index, self) => index === self.findIndex((o) => o.value === item.value))
          .filter((item) => values.includes(item.value)),
      );
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectValueHandler = useCallback(
    (newValue: string[]) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <MultiSelect
      selectedOptions={schoolsSelectOptions.filter((item) => values.includes(item.value))}
      modalPopover
      placeholder={placeholder}
      showSearch
      searchValue={search}
      shouldFilter={false}
      disabled={isDistrictRequired && !districtId}
      loading={isSchoolsLoading}
      onSearchChange={setSearch}
      onOptionChange={selectValueHandler}
      maxCount={1}
    >
      {schoolsSelectOptions.map((school) => (
        <MultiSelectItem
          key={school.value}
          value={school.value}
          option={school}
          isSelected={values.includes(school.value)}
        />
      ))}
    </MultiSelect>
  );
};

export { SchoolMultiSelect };
