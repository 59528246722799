import { BulkFileErrorTableCell } from '~/components';
import { DataTableColumnHeader } from '~/components/DataTable';
import type { ColumnDef } from '@tanstack/react-table';
import type { TakeActionType } from '@purple/shared-types';
import type { TActionBulkUploadResponse } from '~/services';

export const districtInitiativeColumns: ColumnDef<TActionBulkUploadResponse<typeof TakeActionType.DISTRICT_INITIATIVE>>[] = [
  {
    accessorKey: 'row',
    header: ({ column }) => <DataTableColumnHeader column={column} title="#" className="px-2" />,
    cell: ({ row }) => (
      <div className="px-2 py-3">
        <span>{row.original.row}</span>
      </div>
    ),
    size: 50,
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'document_as',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Document As" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.document_as} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'date_and_time',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date & Time" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.date_and_time} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'number_of_resources_selected',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Number of Resources" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.number_of_resources_selected} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'service_categories',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Service Categories" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.service_categories} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'students',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Connected Students" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.students} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
];
