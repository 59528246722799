import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITY_TYPES_QUERY_KEYS } from '../activities.const';
import { getActivityTypeDetails } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActivityTypeItem } from '../activities.types';

export const useActivityTypeDetails = (
  id: string,
  queryOptions?: TCustomUseQueryOptions<TActivityTypeItem, AxiosError>,
) => {
  const queryData = useQuery({
    queryKey: [ACTIVITY_TYPES_QUERY_KEYS.GET_ACTIVITY_TYPE_DETAILS, id],
    queryFn: () => getActivityTypeDetails(id),
    ...queryOptions,
  });

  useEffect(() => {
    if (queryData.isError && isClientError(queryData.error)) {
      showErrorToast('System message', 'Unable to retrieve activity type details');
    }
  }, [queryData.isError, queryData.error]);

  return queryData;
};
