import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { SAF_AREA_SUBCATEGORIES_LABELS, type TActionChoicesResponse } from '@purple/shared-types';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getActionChoices } from './get-action-choices';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActionChoicesPayload } from './actions.types';

type TActionChoicesRequestParameters = {
  shouldFetch?: boolean;
  payload: TActionChoicesPayload;
  mutationOptions?: TCustomUseMutationOptions<TActionChoicesResponse, AxiosError, TActionChoicesPayload >;
};

export const useActionChoices = (parameters: TActionChoicesRequestParameters) => {
  const { shouldFetch = false, mutationOptions, payload } = parameters;

  const wasRendered = useRef(false);

  const [actionChoices, setActionChoices] = useState<TActionChoicesResponse | null>(null);

  const { mutate: retrieveActionChoices, isPending } = useMutation({
    ...mutationOptions,
    mutationKey: [ACTIONS_QUERY_KEYS.GET_ACTION_CHOICES, parameters],
    mutationFn: getActionChoices,
    onSuccess: (response) => {
      setActionChoices(response);
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to retrieve action choices');
      }
    },
  });

  useEffect(() => {
    if (shouldFetch && !wasRendered.current) {
      wasRendered.current = true;
      retrieveActionChoices({
        actionId: payload.actionId,
        limit: payload.limit,
        offset: payload.offset,
        record_action_type: payload.record_action_type,
        students: payload.students,
      });
    }
  }, [shouldFetch, retrieveActionChoices, payload.actionId, payload.limit, payload.offset, payload.record_action_type, payload.students]);

  const typeOptions = useMemo(
    () => (actionChoices?.type ? Object.entries(actionChoices.type).map(([value, label]) => ({ value, label })) : []),
    [actionChoices?.type],
  );

  const subTypeOptions = useMemo(
    () => (actionChoices?.subtype ? Object.entries(actionChoices.subtype).map(([value, label]) => ({ value, label })) : []),
    [actionChoices?.subtype],
  );

  const serviceOptions = useMemo(
    () =>
      actionChoices?.service_categories?.map((service) => ({
        label: SAF_AREA_SUBCATEGORIES_LABELS[service.name],
        value: service.id,
      })) ?? [],
    [actionChoices?.service_categories],
  );

  const focusAreaOptions = useMemo(
    () => actionChoices?.focus_areas?.map((focusArea) => ({ label: focusArea.name, value: focusArea.id.toString() })) ?? [],
    [actionChoices?.focus_areas],
  );

  const levelOfDistressOptions = useMemo(
    () =>
      actionChoices?.level_of_distress ? Object.entries(actionChoices.level_of_distress).map(([value, label]) => ({ value, label })) : [],
    [actionChoices?.level_of_distress],
  );

  const suicideScreeningAssessmentResultOptions = useMemo(
    () =>
      actionChoices?.suicide_screening_assessment_result
        ? Object.entries(actionChoices?.suicide_screening_assessment_result).map(([value, label]) => ({ value, label }))
        : [],
    [actionChoices?.suicide_screening_assessment_result],
  );

  const sessionParticipantsOptions = useMemo(
    () =>
      actionChoices?.session_participants
        ? Object.entries(actionChoices.session_participants).map(([value, label]) => ({ value, label }))
        : [],
    [actionChoices?.session_participants],
  );

  const treatAssessmentResultOptions = useMemo(
    () =>
      actionChoices?.threat_assessment_result
        ? Object.entries(actionChoices.threat_assessment_result).map(([value, label]) => ({ value, label }))
        : [],
    [actionChoices?.threat_assessment_result],
  );

  const crisisTypeOptions = useMemo(
    () => (actionChoices?.crisis_types ? Object.entries(actionChoices.crisis_types).map(([value, label]) => ({ value, label })) : []),
    [actionChoices?.crisis_types],
  );
  return {
    actionChoices,
    isPending,
    typeOptions,
    subTypeOptions,
    serviceOptions,
    focusAreaOptions,
    levelOfDistressOptions,
    suicideScreeningAssessmentResultOptions,
    sessionParticipantsOptions,
    treatAssessmentResultOptions,
    crisisTypeOptions,
  };
};
