import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIVITY_TYPES_QUERY_KEYS } from '../activities.const';
import { updateActivityTypeRoles } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActivityTypePermissionsUpdatePayload } from '../activities.types';

export const useUpdateActivityTypeRoles = (
  options?: TCustomUseMutationOptions<unknown, AxiosError, TActivityTypePermissionsUpdatePayload>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [ACTIVITY_TYPES_QUERY_KEYS.UPDATE_ACTIVITY_TYPE_ROLES],
    mutationFn: updateActivityTypeRoles,
    onSuccess: (data, variables, ctx) => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITY_TYPES_QUERY_KEYS.GET_ACTIVITY_TYPE_ROLES, { id: variables.id }] });
      queryClient.invalidateQueries({ queryKey: [ACTIVITY_TYPES_QUERY_KEYS.GET_ACTIVITY_TYPE_DETAILS, variables.id] });
      showSuccessToast('System message', 'Activity type roles updated successfully');
      options?.onSuccess?.(data, variables, ctx);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to update activity type roles');
      options?.onError?.(...args);
    },
  });
};
