import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { formatDateShortMonth, formatDateShortMonthWithTime, snakeToCamelWithSpaces } from '@purple/shared-utils';
import {
  Button,
  cn,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  NumberBadge,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TActivityTypeItem } from '~/services';

type TUseActivityColumns = (options?: {
  onStatusChange?: (activityType: TActivityTypeItem) => void;
}) => ColumnDef<TActivityTypeItem>[];

export const useActivityTypeColumns: TUseActivityColumns = (options) => {
  const { onStatusChange } = options ?? {};

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to={AdminRoutes.App.Activities.Types.Details.makePath({ dynamicParameters: { id: row.original.id }, queries: { district: row.original.district.id.toString() } })}
                  className="text-brand-blue-700 line-clamp-1 min-w-0 break-all"
                >
                  {snakeToCamelWithSpaces(row.original.name)}
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {snakeToCamelWithSpaces(row.original.name)}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 240,
        },
        {
          accessorKey: 'usage',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Usage" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.usage || '-'}
                </span>
              </TooltipTrigger>
              {row.original.usage && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.usage || '-'}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 300,
          meta: { label: 'Usage' },
        },
        {
          accessorKey: 'is_active',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
          cell: ({ row }) => (
            <NumberBadge
              variant={row.original.is_active ? 'success-light' : 'danger'}
              className="rounded-full capitalize"
            >
              {row.original.is_active ? 'Active' : 'Inactive'}
            </NumberBadge>
          ),
          size: 120,
          meta: { className: 'text-center', label: 'Status' },
        },
        {
          accessorKey: 'is_recurrence',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Recurrence" />,
          cell: ({ row }) => (
            <NumberBadge
              variant={row.original.is_recurrence ? 'info' : 'neutral'}
              className="rounded-full capitalize"
            >
              {row.original.is_recurrence ? 'Enabled' : 'Disabled'}
            </NumberBadge>
          ),
          size: 120,
          enableSorting: false,
          meta: { className: 'text-center', label: 'Recurrence' },
        },
        {
          accessorKey: 'last_modified_by__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Last Modified By" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                {row.original.last_modified_by
                  ? (
                      <Link
                        to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: row.original.last_modified_by?.id } })}
                        className="text-brand-blue-700 line-clamp-1 min-w-0 break-all"
                      >
                        {row.original.last_modified_by?.full_name ?? '-'}
                      </Link>
                    )
                  : (
                      <span className="line-clamp-1 min-w-0 break-all">System</span>
                    )}
              </TooltipTrigger>
              {row.original.last_modified_by?.full_name && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.last_modified_by.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Last Modified By' },
        },
        {
          accessorKey: 'updated_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Last Modified Date" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.updated_at ? formatDateShortMonth(row.original.updated_at) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.updated_at && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(row.original.updated_at)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Last Modified Date' },
        },
        {
          accessorKey: 'created_by__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Created By" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                {row.original.created_by
                  ? (
                      <Link
                        to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: row.original.created_by?.id } })}
                        className="text-brand-blue-700 line-clamp-1 min-w-0 break-all"
                      >
                        {row.original.created_by?.full_name ?? '-'}
                      </Link>
                    )
                  : (
                      <span className="line-clamp-1 min-w-0 break-all">System</span>
                    )}
              </TooltipTrigger>
              {row.original.created_by?.full_name && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.created_by.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Created By' },
        },
        {
          accessorKey: 'created_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Created Date" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.created_at ? formatDateShortMonth(row.original.created_at) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.created_at && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(row.original.created_at)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Created Date' },
        },
        {
          accessorKey: 'district',
          header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to={AdminRoutes.App.Districts.Details.makePath({ dynamicParameters: { id: row.original.district.id } })}
                  className="text-brand-blue-700 line-clamp-1 min-w-0 break-all"
                >
                  {row.original.district.name ?? '-'}
                </Link>
              </TooltipTrigger>
              {row.original.district && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.district.name || '-'}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 160,
          enableSorting: false,
        },
        {
          accessorKey: 'actions',
          header: () => null,
          cell: ({ row }) => !row.getIsSelected() && (
            <DropdownRoot>
              <Tooltip>
                <TooltipTrigger asChild>
                  <DropdownTrigger asChild>
                    <Button
                      type="button"
                      variant="tertiary"
                      size="icon_32"
                      className="active:bg-grey-100 size-8 shrink-0"
                      iconLeft={<PurpleIcon name="dots-vertical" className="text-grey-600 shrink-0" />}
                      onFocusCapture={(event) => event.stopPropagation()}
                    />
                  </DropdownTrigger>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent withArrow={false}>Actions</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <DropdownContent className="max-w-[150px] gap-1" align="end">
                <DropdownItem
                  iconName={row.original.is_active ? 'X' : 'check'}
                  className={cn(
                    'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
                    {
                      'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main': row.original.is_active,
                    },
                  )}
                  onSelect={() => onStatusChange?.(row.original)}
                  onClick={(event) => event.stopPropagation()}
                >
                  {row.original.is_active ? 'Deactivate' : 'Activate'}
                </DropdownItem>
              </DropdownContent>
            </DropdownRoot>
          ),
          size: 60,
          enableHiding: false,
          enableSorting: false,
          meta: { className: 'text-right' },
        },
      ] satisfies ColumnDef<TActivityTypeItem>[],
    [onStatusChange],
  );

  return columns;
};
