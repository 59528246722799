import { useEffect, useMemo, useState } from 'react';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { Typography } from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { isFieldDefined } from '@purple/shared-utils';
import { cn, Text } from '@purple/ui';
import { useUpdateDistrictSafProcess } from '~/services';
import { DistrictSectionHeader } from '../../DistrictSectionHeader';
import { LinkBubbleMenu } from '../../LinkBubbleMenu';
import { SafEditorToolbar } from '../../SafEditorToolbar';
import { SafLinkExtention } from '../../SafLinkExtention';
import type { JSONContent } from '@tiptap/react';
import type React from 'react';
import type { TAdminTranslation, TDistrictDetails } from '~/services';

type TSafAcknowledgementSectionProperties = {
  district: TDistrictDetails;
};

export const SafAcknowledgementSection: React.FC<TSafAcknowledgementSectionProperties> = (props) => {
  const {
    district: { id, acknowledgement_text },
  } = props;

  const defaultText = useMemo(() => {
    return acknowledgement_text ? JSON.parse(acknowledgement_text as string) : '';
  }, [acknowledgement_text]);

  const [editableAcknowledgementText, setEditableAcknowledgementText] = useState<TAdminTranslation>(defaultText);

  useEffect(() => {
    setEditableAcknowledgementText(defaultText);
  }, [defaultText]);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateDistrictSafProcess, isPending } = useUpdateDistrictSafProcess();

  const contentData = useMemo(() => {
    const data = JSON.parse(acknowledgement_text as string);
    return data?.en;
  }, [acknowledgement_text]);

  const updateEditableData = (value: JSONContent) => {
    if (!defaultText) return;

    const updatedLanguage = {
      ...defaultText,
      en: value as unknown as string,
    };
    setEditableAcknowledgementText(updatedLanguage);
  };

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      SafLinkExtention.configure({
        autolink: true,
        defaultProtocol: 'https',
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your acknowledgement text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: contentData,
    editable: isEditing,
    onUpdate: ({ editor }) => {
      const jsonValue = editor.getJSON();
      updateEditableData(jsonValue);
    },
  });

  const editClickHandler = () => {
    setIsEditing(true);
    editorConfig?.setEditable(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    editorConfig?.setEditable(false);
    if (isFieldDefined(acknowledgement_text)) {
      setEditableAcknowledgementText(defaultText);
      editorConfig?.commands.setContent(contentData);
    }
  };

  const saveHandler = () => {
    const payload = {
      acknowledgement_text: JSON.stringify(editableAcknowledgementText),
    };
    updateDistrictSafProcess(
      {
        id,
        ...payload,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          editorConfig?.setEditable(false);
        },
      },
    );
  };

  return (
    <section className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-1">
        <DistrictSectionHeader
          title="Acknowledgement Text"
          editing={isEditing}
          loading={isPending}
          onCancel={cancelClickHandler}
          onEdit={editClickHandler}
          onSave={saveHandler}
        />
        <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
          This is the last page of the SAF. You can edit the default message.
        </Text>
      </div>
      <div className="flex w-full flex-col gap-2">
        {isEditing
          ? (
              <div className="flex w-full flex-col gap-2">
                <div className="space-y-2">
                  <SafEditorToolbar editor={editorConfig} />
                  <EditorContent
                    editor={editorConfig}
                    className={cn(
                      'minimal-tiptap-editor flex h-full min-h-[80px] w-full cursor-text flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 focus-within:border-brand-blue-700 hover:border-brand-blue-700 focus:border-brand-blue-700',

                    )}
                  />
                  {editorConfig && <LinkBubbleMenu editor={editorConfig} />}
                </div>
              </div>
            )
          : (
              <EditorContent
                editor={editorConfig}
                disabled
                className="minimal-tiptap-editor flex size-full cursor-text flex-col p-0 font-primary text-sm font-medium text-grey-950"
              />
            )}
      </div>
    </section>
  );
};
