import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { FILE_QUERY_KEYS } from './files.const';
import { getFileById } from './get-file-by-id';
import type { AxiosError } from 'axios';
import type { TFile } from '@purple/shared-types';
import type { TCustomUseQueryOptions } from '~/types/query';

export const useFileById = (
  fileId?: string | null,
  queryOptions?: TCustomUseQueryOptions<TFile, AxiosError>,
) => {
  const { isError, ...rest } = useQuery({
    queryKey: [FILE_QUERY_KEYS.GET_FILE_BY_ID, fileId],
    queryFn: () => getFileById(fileId as string),
    enabled: !!fileId,
    ...queryOptions,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch the file');
    }
  }, [isError]);

  return { isError, ...rest };
};
