import { useEffect, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounceValue } from 'usehooks-ts';
import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useFileById, useUpdateFile, useUploadFile, useUsersList } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { uploadFileSchema } from './schema';
import type React from 'react';
import type z from 'zod';
import type { TObjectContentType } from '@purple/shared-types';

type TFormValues = z.infer<typeof uploadFileSchema>;

type TAccessSettingsDialogProperties = {
  contentType: TObjectContentType;
  objectId?: string | null;
  uploadedFile: File | null;
  selectedFileId: string | null;
  setUploadedFile: (file: File | null) => void;
  setSelectedFileId: (fileId: string | null) => void;
};

export const AccessSettingsDialog: React.FC<TAccessSettingsDialogProperties> = (props) => {
  const { contentType, objectId, uploadedFile, selectedFileId, setUploadedFile, setSelectedFileId } = props;

  const formId = useId();

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.FILES_ACCESS_SETTINGS);
  const { closeModal: closeFilePreview } = useModal(ModalType.UPLOAD_FILE_PREVIEW);

  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounceValue(searchValue, 500);

  const { data: file } = useFileById(selectedFileId as string);
  const { data: sharedUsers, isFetching: isUserFetching } = useUsersList({
    enabled: isOpen,
    requestParameters: {
      search: debouncedSearchValue,
    },
  });
  const { mutate: uploadFile, isPending: isUploading } = useUploadFile();
  const { mutate: updateFile, isPending: isUpdating } = useUpdateFile();

  const defaultValues = useMemo(
    () =>
      selectedFileId
        ? {
            file: uploadedFile ?? undefined,
            is_private: file?.is_private ?? false,
            viewers: file?.viewers.map(({ id, full_name, email }) => ({ label: full_name || email, value: id })) ?? [],
          }
        : {
            file: uploadedFile ?? undefined,
            is_private: false,
            viewers: [],
          },
    [uploadedFile, selectedFileId, file],
  );

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(uploadFileSchema),
    defaultValues,
  });

  const isPrivateFile = form.watch('is_private');

  const isFormSubmitting = useMemo(() => isUploading || isUpdating, [isUploading, isUpdating]);
  const multiSelectOptions = useMemo(
    () =>
      sharedUsers?.results.map((user) => ({
        ...user,
        label: user.full_name ?? user.email,
        value: user.id,
      })) ?? [],
    [sharedUsers],
  );

  const createFileHandler = (formData: TFormValues) => {
    if (!objectId || !uploadedFile) {
      showErrorToast('System Message', 'No file or object id found');
      return;
    }
    uploadFile(
      {
        content_type: contentType,
        object_id: objectId,
        title: uploadedFile.name,
        ...formData,
        viewers: formData.viewers.map(({ value }) => value),
      },
      {
        onSuccess: () => {
          setUploadedFile(null);
          setSelectedFileId(null);
          closeModal();
          closeFilePreview();
        },
      },
    );
  };

  const updateFileHandler = (formData: TFormValues) => {
    if (!uploadedFile) {
      showErrorToast('System Message', 'Unexpected error occurred. Please try again.');
      return;
    }
    updateFile(
      {
        fileId: selectedFileId as string,
        title: uploadedFile.name,
        ...formData,
        viewers: formData.viewers.map(({ value }) => value),
      },
      {
        onSuccess: () => {
          setUploadedFile(null);
          setSelectedFileId(null);
          closeModal();
          closeFilePreview();
        },
      },
    );
  };

  const submitFormHandler = selectedFileId ? updateFileHandler : createFileHandler;

  useEffect(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="max-w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className="font-primary font-semibold">Access Settings</DialogTitle>
            <DialogDescription className="className='font-primary' text-grey-600">
              Select users who will have access to the uploaded file.
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          providerProps={form}
          className="space-y-4 p-6"
          onSubmit={form.handleSubmit(submitFormHandler)}
          id={formId}
        >
          <FormField
            control={form.control}
            name="is_private"
            render={({ field }) => (
              <FormItem className="flex gap-2 space-y-0">
                <FormControl>
                  <Checkbox
                    {...field}
                    value={field.value.toString()}
                    checked={field.value}
                    onCheckedChange={(value) => {
                      field.onChange(value);
                      form.setValue('viewers', []);
                    }}
                    className="relative top-0.5 size-4"
                  />
                </FormControl>
                <div className="flex flex-col">
                  <FormLabel className="text-sm font-medium">Mark as My File</FormLabel>
                  <FormDescription className="text-sm font-normal">
                    Will be visible to you in Purple and designated district leaders with specific access for coaching,
                    subpoena and disclosure requests.
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
          {isPrivateFile && (
            <FormField
              control={form.control}
              name="viewers"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>Share with</FormLabel>
                  <FormControl>
                    <MultiSelect
                      isError={!!fieldState.error}
                      selectedOptions={field.value}
                      showSearch
                      loading={isUserFetching}
                      shouldFilter={false}
                      searchPlaceholder="Search by user name or email"
                      placeholder="Select users"
                      searchValue={searchValue}
                      modalPopover
                      onOptionChange={(_, selected) => field.onChange(selected)}
                      onSearchChange={setSearchValue}
                    >
                      {multiSelectOptions.map((option) => (
                        <MultiSelectItem
                          key={option.value}
                          value={option.value}
                          option={option}
                          customContent
                        >
                          <>
                            {field.value.some((item) => item.value === option.value) && (
                              <PurpleIcon
                                name="check"
                                className="text-brand-blue-700 absolute left-4 top-1/2 size-4 -translate-y-1/2"
                              />
                            )}
                            <div className="flex items-center gap-2">
                              <Avatar size={32}>
                                <AvatarImage src={option.avatar ?? undefined} alt={option.full_name ?? option.email} />
                                <AvatarFallback className="bg-grey-200">
                                  {getInitialsFromName(option.full_name ?? '- -')}
                                </AvatarFallback>
                              </Avatar>
                              <div className="flex flex-col gap-1">
                                <strong className="text-grey-950 line-clamp-1 text-xs font-medium">
                                  {option.full_name ?? option.email}
                                </strong>
                                <span className="text-grey-600 line-clamp-1 text-xs">{option.email}</span>
                              </div>
                            </div>
                          </>
                        </MultiSelectItem>
                      ))}
                    </MultiSelect>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} isLoading={isFormSubmitting} disabled={isFormSubmitting}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
