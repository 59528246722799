import axiosInstance from '../axios-config';
import { CONTACTS_ENDPOINTS } from '../endpoints';
import type { TBulkUploadResponse } from '@purple/shared-types';
import type { TContactsBulkFileErrorResponse, TCreateBulkContactsPayload } from './contacts.types';

export const createBulkContacts = async (payload: TCreateBulkContactsPayload) => {
  const formData = new FormData();
  formData.append('district', payload.district);
  formData.append('contact_type', payload.contact_type);
  formData.append('uploaded_file', payload.uploaded_file);
  const response = await axiosInstance.post<TBulkUploadResponse<TContactsBulkFileErrorResponse>>(CONTACTS_ENDPOINTS.BULK_ADD_CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
