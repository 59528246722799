import { useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormField, FormItem } from '@purple/ui';
import type { z } from 'zod';
import type { groupSchema } from './schema';

type TGroupItemProps = {
  editing?: boolean;
  fieldIndex: number;
};

export const GroupItem: React.FC<TGroupItemProps> = (props) => {
  const { editing = false, fieldIndex } = props;

  const ctx = useFormContext<z.infer<typeof groupSchema>>();

  return (
    <div className="grid min-h-14 w-full grid-cols-12 items-center border-b border-grey-300 py-1.5 last-of-type:border-none">
      <span className="col-span-6 px-3 text-sm font-medium text-grey-950">
        {ctx.watch(`groups.${fieldIndex}.name`)}
      </span>
      {editing
        ? (
            <FormField
              control={ctx.control}
              name={`groups.${fieldIndex}.is_available`}
              render={({ field }) => (
                <FormItem className="col-span-6 px-3">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value={field.value.toString()}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          )
        : (
            <span className="col-span-6 px-3 text-sm font-medium text-grey-950">
              {ctx.watch(`groups.${fieldIndex}.is_available`) ? 'Yes' : 'No'}
            </span>
          )}
    </div>
  );
};
