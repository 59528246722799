import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities';
import { deleteFile } from './delete-file';
import { FILE_QUERY_KEYS } from './files.const';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useDeleteFile = (
  mutationOptions?: TCustomUseMutationOptions<void, AxiosError>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [FILE_QUERY_KEYS.DELETE_FILE],
    mutationFn: deleteFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEYS.GET_FILES_LIST] });
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_FILTER_CHOICES_BY_ID] });
      showSuccessToast('System Message', 'File deleted successfully');
    },
    onError: () => {
      showErrorToast('System Message', 'Failed to delete file');
    },
    ...mutationOptions,
  });
};
