import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { Button, Heading, NoDataAvailable, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, Separator, Text } from '@purple/ui';
import { DistrictFilterComboBox } from '~/components';
import { ADMIN_SAF_QUERY_KEYS, useSafTextTranslations, useUpdateDistrictSafProcess } from '~/services';
import { SAF_LANGUAGES, type TSafLanguages } from '../../constants';
import { DISTRICT_QUERY_NAME } from '../AllSafs/constants';
import { TextTranslationEditor } from './TextTranslationEditor';
import type { TAdminTranslation } from '~/services';

const DEFAULT_TRANSLATIONS_VALUES = {
  ar: '',
  en: '',
  es: '',
  vi: '',
  zh: '',
} as const;

const TextTranslation = () => {
  const queryClient = useQueryClient();

  const { mutate: updateDistrictSafProcess, isPending } = useUpdateDistrictSafProcess();

  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const [editableIntroductionText, setEditableIntroductionText] = useState<TAdminTranslation>(DEFAULT_TRANSLATIONS_VALUES);
  const [editableAcknowledgementText, setEditableAcknowledgementText] = useState<TAdminTranslation>(DEFAULT_TRANSLATIONS_VALUES);
  const [editableDisclaimerText, setEditableDisclaimerText] = useState<TAdminTranslation>(DEFAULT_TRANSLATIONS_VALUES);
  const [editableSpecialEdText, setEditableSpecialEdText] = useState<TAdminTranslation>(DEFAULT_TRANSLATIONS_VALUES);

  const { query: districtQuery, onQueryChange: onDistrictQueryChange, onClearQuery: onDistrictQueryClear } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const { data: textData } = useSafTextTranslations({
    districtId: districtQuery || '',
  });

  useEffect(() => {
    setMode('view');
  }, [districtQuery]);

  const canShowTable = useMemo(() => Boolean(districtQuery), [districtQuery]);

  const [selectedLanguage, setSelectedLanguage] = useState<TSafLanguages>('en');

  const languageChangeHandler = (value: TSafLanguages) => {
    setSelectedLanguage(value);
  };

  useEffect(() => {
    if (textData) {
      const { introduction_text, acknowledgement_text, disclaimer_text, special_ed_text } = textData;

      const introductionText = typeof introduction_text === 'string' ? JSON.parse(textData?.introduction_text as string) : DEFAULT_TRANSLATIONS_VALUES;

      const newEditableIntroductionText = {
        ar: introductionText.ar,
        en: introductionText.en,
        es: introductionText.es,
        vi: introductionText.vi,
        zh: introductionText.zh,
      };
      setEditableIntroductionText(newEditableIntroductionText);

      const acknowledgementText = typeof acknowledgement_text === 'string' ? JSON.parse(textData?.acknowledgement_text as string) : DEFAULT_TRANSLATIONS_VALUES;
      const newEditableAcknowledgementText = {
        ar: acknowledgementText.ar,
        en: acknowledgementText.en,
        es: acknowledgementText.es,
        vi: acknowledgementText.vi,
        zh: acknowledgementText.zh,
      };
      setEditableAcknowledgementText(newEditableAcknowledgementText);

      const disclaimerText = typeof disclaimer_text === 'string' ? JSON.parse(textData?.disclaimer_text as string) : DEFAULT_TRANSLATIONS_VALUES;
      const newEditableDisclaimerText = {
        ar: disclaimerText.ar,
        en: disclaimerText.en,
        es: disclaimerText.es,
        vi: disclaimerText.vi,
        zh: disclaimerText.zh,
      };
      setEditableDisclaimerText(newEditableDisclaimerText);

      const specialEdText = typeof special_ed_text === 'string' ? JSON.parse(textData?.special_ed_text as string) : DEFAULT_TRANSLATIONS_VALUES;
      const newEditableSpecialEdText = {
        ar: specialEdText.ar,
        en: specialEdText.en,
        es: specialEdText.es,
        vi: specialEdText.vi,
        zh: specialEdText.zh,
      };
      setEditableSpecialEdText(newEditableSpecialEdText);
    }
  }, [textData]);

  const editableDataChangeHandler = (textType: string, data: TAdminTranslation) => {
    if (textType === 'introduction_text') {
      setEditableIntroductionText(data);
    }
    if (textType === 'acknowledgement_text') {
      setEditableAcknowledgementText(data);
    }
    if (textType === 'disclaimer_text') {
      setEditableDisclaimerText(data);
    }
    if (textType === 'special_ed_text') {
      setEditableSpecialEdText(data);
    }
  };

  const saveHandler = () => {
    if (districtQuery) {
      const payload = {
        introduction_text: JSON.stringify(editableIntroductionText),
        acknowledgement_text: JSON.stringify(editableAcknowledgementText),
        disclaimer_text: JSON.stringify(editableDisclaimerText),
        special_ed_text: JSON.stringify(editableSpecialEdText),
      };

      updateDistrictSafProcess({
        id: +districtQuery,
        ...payload,
      }, {
        onSuccess: () => {
          setMode('view');
          queryClient.invalidateQueries({ queryKey: [ADMIN_SAF_QUERY_KEYS.SAF_TEXT_TRANSLATIONS] });
        },
      });
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-6 py-5">
        <div className="flex flex-col gap-0">
          <Heading variant="size-18" type="heading-600" className="text-grey-950">Text Translation</Heading>
          <Text variant="size-16" type="body-400" className="text-grey-600">You can update and manage translations for SAF messages and texts.</Text>
        </div>
        <div>
          {mode === 'view' && canShowTable && (
            <div className="flex flex-row gap-[12px]">
              <Button size="link" variant="link" iconLeft={<PurpleIcon name="pencil" />} onClick={() => setMode('edit')}>
                Edit
              </Button>
            </div>
          )}
          {mode === 'edit' && canShowTable && (
            <div className="flex flex-row gap-[12px]">
              <Button size="small" variant="secondary" onClick={() => setMode('view')}>
                Cancel
              </Button>
              <Button size="small" variant="primary" onClick={saveHandler} isLoading={isPending}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex items-center gap-4 px-6 py-5">
        <div className="min-w-[200px]">
          <DistrictFilterComboBox status={DISTRICT_STATUS.PUBLISHED} value={districtQuery || ''} onChange={onDistrictQueryChange} onClear={onDistrictQueryClear} placeholder="Select district" />
        </div>
        <RadixSelect defaultValue={selectedLanguage} onValueChange={languageChangeHandler}>
          <RadixSelectTrigger className="w-40">
            <RadixSelectValue placeholder="Select language" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {SAF_LANGUAGES.map((language) => (
              <RadixSelectItem key={language.value} value={language.value}>
                {language.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
      </div>
      {canShowTable && (
        <div className="flex flex-col">
          <div className="grid grid-cols-2 items-start gap-x-4 px-6 py-4">
            <Text variant="size-12" type="body-600" className="uppercase text-grey-600">FIELD NAME</Text>
            <Text variant="size-12" type="body-600" className="px-3 uppercase text-grey-600">TRANSLATION</Text>
          </div>
          <Separator className="bg-grey-200" />
          <TextTranslationEditor label="Introduction Text" data={editableIntroductionText} mode={mode} selectedLanguage={selectedLanguage} onChange={(newData) => editableDataChangeHandler('introduction_text', newData)} />
          <Separator className="bg-grey-200" />
          <TextTranslationEditor label="Acknowledgement Text" data={editableAcknowledgementText} mode={mode} selectedLanguage={selectedLanguage} onChange={(newData) => editableDataChangeHandler('acknowledgement_text', newData)} />
          <Separator className="bg-grey-200" />
          <TextTranslationEditor label="Disclaimer Text" data={editableDisclaimerText} mode={mode} selectedLanguage={selectedLanguage} onChange={(newData) => editableDataChangeHandler('disclaimer_text', newData)} />
          <Separator className="bg-grey-200" />
          <TextTranslationEditor label="Special-Ed Student Text" data={editableSpecialEdText} mode={mode} selectedLanguage={selectedLanguage} onChange={(newData) => editableDataChangeHandler('special_ed_text', newData)} />
        </div>
      )}
      {!canShowTable && (<NoDataAvailable iconName="folder-open" title="No Data Found" description="Please select a district to view data" className="min-h-96" />)}
    </div>
  );
};

export { TextTranslation };
