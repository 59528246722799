import { useMemo } from 'react';
import { formatDistanceStrict } from 'date-fns';
import { PurpleIcon } from '@purple/icons';
import { NotificationContentType } from '@purple/shared-types';
import { formatDateWithTime } from '@purple/shared-utils';
import { cn, Heading, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { PurpleIconType } from '@purple/icons';
import type { TNotificationItem } from '~/services';

type TNotificationItemProps = {
  notification: TNotificationItem;
  onClick?: (notification: TNotificationItem) => void;
};

export const NotificationItem: React.FC<TNotificationItemProps> = (props) => {
  const { notification, onClick } = props;

  const iconName: PurpleIconType = useMemo(() => {
    switch (notification.content_type) {
      case NotificationContentType.SCHOOL:
        return 'School';

      case NotificationContentType.USER:
        return 'user';

      case NotificationContentType.DISTRICT:
        return 'disctrict';

      case NotificationContentType.COMMUNITY_ACTIVITY:
        return 'user-group';

      case NotificationContentType.PROGRAM:
        return 'Program';

      default:
        return 'School';
    }
  }, [notification.content_type]);

  return (
    <li className={cn('relative flex w-full items-start justify-between gap-4 pl-4', {
      'before:absolute before:size-2 before:rounded-full before:bg-error-main before:top-5 before:left-1.5': !notification.is_marked_as_read,
    })}
    >
      <button type="button" className="flex w-full rounded-md p-2 text-left outline-none transition-colors duration-200 hover:bg-brand-blue-100 focus:bg-brand-blue-100 focus-visible:bg-brand-blue-100 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-0 focus-visible:outline-brand-blue-500 active:opacity-60 active:transition-none" onClick={() => onClick?.(notification)}>
        <div className="flex w-full flex-1 gap-2">
          <div className="flex size-8 shrink-0 items-center justify-center rounded-lg bg-grey-100">
            <PurpleIcon name={iconName} className="size-4 shrink-0 text-grey-700" />
          </div>
          <div className="flex w-full flex-col justify-start gap-1">
            <Heading tag="h5" type="heading-500" variant="size-18" className="line-clamp-1 min-w-0 break-all text-sm text-grey-title">
              {notification.title}
            </Heading>
            <Text variant="size-12" type="body-400" className="line-clamp-3 min-w-0 break-all text-grey-600">
              {notification.body}
            </Text>
          </div>
        </div>
        <Tooltip>
          <TooltipTrigger asChild>
            <Text variant="size-12" type="body-600" className="text-nowrap text-grey-600">
              {formatDistanceStrict(new Date(notification.created_at), new Date(), { addSuffix: true })}
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>{formatDateWithTime(notification.created_at)}</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </button>
    </li>
  );
};
