import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '~/hooks';
import { setAllRequiredSisDataUploaded } from '~/store/features/district-set-up';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getSisMappingList } from './get-sis-mapping-list';
import type { AxiosError } from 'axios';
import type { TDistrictMapping } from './districts.types';

export const useSisMappingList = (districtId: string) => {
  const dispatch = useAppDispatch();
  const { isError, isSuccess, data, error, ...rest } = useQuery<TDistrictMapping[], AxiosError>({
    queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_LIST, districtId],
    queryFn: () => getSisMappingList(districtId),
    enabled: !!districtId,
  });
  useEffect(() => {
    if (isError && isClientError(error)) {
      showErrorToast('System message', 'Failed to fetch SIS mapping list');
    }
    if (isSuccess && data) {
      const isAllRequiredStepsCompleted = data.filter((item) => item.is_required && !item.is_mapping_completed).length === 0;
      if (!isAllRequiredStepsCompleted) {
        dispatch(setAllRequiredSisDataUploaded(false));
      }
      if (isAllRequiredStepsCompleted) {
        dispatch(setAllRequiredSisDataUploaded(true));
      }
    }
  }, [isError, error, isSuccess, data, dispatch]);

  return { isError, error, isSuccess, data, ...rest };
};
