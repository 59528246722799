import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { TAGS_TYPES, type TTagType } from '@purple/shared-types';
import {
  Button,
  Checkbox,
  cn,
  ColorGroupItem,
  ColorPicker,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  Separator,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useCreateCustomTag } from '~/services';
import { createCustomTagSchema } from './schema';
import type * as z from 'zod';

type TFromValues = z.infer<typeof createCustomTagSchema>;

const DEFAULT_HEX_COLORS = [
  '#7653A1',
  '#4574AA',
  '#4C5C67',
  '#799436',
  '#F89B3F',
];

type TCreateTagModalProperties = {
  /**
   * List of existing tag names. Used to validate the tag name.
   */
  existingNames?: string[];
  /**
   * The type of tag to which the tags will be created.
   */
  tagsType: TTagType;
  /**
   * The id of the related item. If the tagsType is `recordactiontype`, this is the id of the action type.
   */
  relatedEntityId: string;
  /**
   * The id of the related district. The district the related id belongs to.
   */
  relatedDistrictId: number;
  /**
   * A callback function that is called when the tag is successfully created.
   */
  onSuccess?: () => void;
};

export const CreateTagModal: React.FC<TCreateTagModalProperties> = (props) => {
  const { tagsType, relatedDistrictId, relatedEntityId, existingNames, onSuccess } = props;

  const { isOpen, toggleModal } = useModal(ModalType.CREATE_CUSTOM_TAG);

  const [customColor, setCustomColor] = useState<string>('');
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);

  const { mutate: createCustomTag, isPending } = useCreateCustomTag();

  const form = useForm<TFromValues>({
    mode: 'onChange',
    resolver: zodResolver(createCustomTagSchema),
    defaultValues: {
      name: '',
      color: DEFAULT_HEX_COLORS[0],
      apply_to_all: false,
      __existing_names: existingNames ?? [],
    },
  });

  const closeModalHandler = useCallback(() => {
    toggleModal(!isOpen);
    setCustomColor('');
    form.reset();
  }, [form, isOpen, toggleModal]);

  const togglePopover = useCallback(() => {
    setPopoverOpen((prev) => !prev);
  }, []);

  const createCustomTagHandler = (values: TFromValues) => {
    createCustomTag({
      name: values.name,
      color: values.color,
      object_id: relatedEntityId,
      content_type: tagsType,
      district_id: relatedDistrictId,
      is_applied_to_all_instances: values.apply_to_all,
    }, {
      onSuccess: () => {
        onSuccess?.();
        closeModalHandler();
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="max-w-[564px]">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>Create New Tag</DialogTitle>
            <DialogDescription className="sr-only">
              Modal window for creating custom tags
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form providerProps={form} className="flex flex-col gap-4 p-6">
          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Tag name</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter tag name" isError={!!fieldState.error} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="color"
            render={({ field }) => (
              <FormItem className="space-y-2">
                <div className="flex flex-col gap-2">
                  <FormLabel>Tag Colors</FormLabel>
                  <Text variant="size-14" type="body-400" className="leading-[18px] text-grey-700">
                    Select branding school color from the list or add custom color
                  </Text>
                </div>
                <FormControl>
                  <div className="flex items-center gap-4">
                    <RadioGroup className="flex items-center gap-4" onValueChange={field.onChange} value={field.value}>
                      {DEFAULT_HEX_COLORS.map((color) => (
                        <ColorGroupItem
                          key={color}
                          value={color}
                          className="size-8 rounded outline-none data-[state=checked]:ring-1 data-[state=checked]:ring-grey-500 data-[state=checked]:ring-offset-2"
                          style={{ backgroundColor: color }}
                        />
                      ))}
                    </RadioGroup>
                    <Popover open={isPopoverOpen} onOpenChange={setPopoverOpen}>
                      <PopoverTrigger asChild>
                        <button
                          type="button"
                          className={cn(
                            'border-grey-300 outline-none ring-grey-500 aspect-square size-8 rounded border transition-all hover:scale-105 focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-center',
                            {
                              'ring-1 ring-offset-2 ring-grey-500': !DEFAULT_HEX_COLORS.includes(field.value),
                            },
                          )}
                          style={{ background: customColor }}
                        >
                          {!customColor && <PurpleIcon name="eye-dropper" className="size-4" />}
                        </button>
                      </PopoverTrigger>
                      <PopoverContent align="start" side="right" className="border-none p-0">
                        <ColorPicker
                          allowEyeDropper
                          value={field.value}
                          onClose={togglePopover}
                          onApply={(color) => {
                            field.onChange(color);
                            setCustomColor(color);
                            togglePopover();
                          }}
                          onEyeDropperPick={(color) => {
                            field.onChange(color);
                            setCustomColor(color);
                            togglePopover();
                          }}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {tagsType === TAGS_TYPES.RECORD_ACTION_TYPE && (
            <FormField
              control={form.control}
              name="apply_to_all"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value={field.value.toString()}
                      checked={field.value}
                      className="size-4"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="text-sm font-normal text-grey-800">
                    Apply to All Action Types
                  </FormLabel>
                </FormItem>
              )}
            />
          )}
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModalHandler}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" onClick={form.handleSubmit(createCustomTagHandler)} isLoading={isPending}>
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
