import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities';
import { FILE_QUERY_KEYS } from './files.const';
import { uploadFile } from './upload-file';
import type { AxiosError } from 'axios';
import type { TFile, TFileCreatePayload } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useUploadFile = (
  mutationOptions?: TCustomUseMutationOptions<TFile, AxiosError<Partial<Record<string, string[]>>>, TFileCreatePayload>,
) => {
  const queryClient = useQueryClient();
  return useMutation<TFile, AxiosError<Partial<Record<string, string[]>>>, TFileCreatePayload>({
    mutationKey: [FILE_QUERY_KEYS.UPDATE_FILE],
    mutationFn: uploadFile,
    onSuccess: () => {
      showSuccessToast('System Message', 'File uploaded successfully');
      queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEYS.GET_FILES_LIST] });
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_FILTER_CHOICES_BY_ID] });
    },
    onError: (error) => {
      if (error?.response?.data.file) {
        showErrorToast('System Message', error.response.data.file.join(', '));
        return;
      }
      showErrorToast('System Error', 'Failed to upload file');
    },
    ...mutationOptions,
  });
};
