import { useMemo, useRef, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { ALLOWED_ACTIVITY_FILE_EXTENSIONS, MAX_ACTIVITY_FILE_SIZE } from '@purple/shared-utils';
import {
  Button,
  cn,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useCurrentUser, useModal } from '~/hooks';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import type React from 'react';
import type { TFileListItem } from '@purple/shared-types';

type TFileActionProperties = {
  item: TFileListItem;
  onSelect?: (fileId: string) => void;
  onReplace?: (file: File | null) => void;
};

export const FileAction: React.FC<TFileActionProperties> = (props) => {
  const { item, onSelect, onReplace } = props;

  const inputReference = useRef<HTMLInputElement>(null);

  const { user } = useCurrentUser();

  const { openModal: openDeleteModal } = useModal(ModalType.DELETE_FILE);
  const { openModal: openEditModal } = useModal(ModalType.EDIT_FILE);
  const { openModal: openUploadModal } = useModal(ModalType.UPLOAD_FILE_PREVIEW);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const isFileOwner = useMemo(() => item.created_by?.id === user.id, [user.id, item.created_by?.id]);

  const fileUploadHandler = (event: Event) => {
    event.stopPropagation();
    inputReference.current?.click();
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const [file] = files ?? [];
    if (!file) return;

    if (file.size > MAX_ACTIVITY_FILE_SIZE) {
      showErrorToast('System message', `File size exceeds the limit of ${MAX_ACTIVITY_FILE_SIZE / 1024 / 1024}MB`);
      inputReference.current!.value = '';
      return;
    }
    if (file.name.length >= 100) {
      showErrorToast('System message', 'File name has to be less than 100 characters');
      inputReference.current!.value = '';
      return;
    }

    onSelect?.(item.id);
    onReplace?.(file);
    openUploadModal();
    inputReference.current!.value = '';
  };

  const downloadFileHandler = async () => {
    setIsDownloading(true);
    try {
      const response = await fetch(item.file);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', item.title);
      document.body.append(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      setIsDropdownOpen(false);
      showSuccessToast('System message', 'File downloaded successfully');
    } catch {
      showErrorToast('System message', 'There was an error downloading the file');
    } finally {
      setIsDownloading(false);
    }
  };

  const editFileHandler = () => {
    onSelect?.(item.id);
    openEditModal();
  };

  const deleteFileHandler = () => {
    onSelect?.(item.id);
    openDeleteModal();
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="inline-block">
            <DropdownTrigger asChild>
              <Button
                type="button"
                variant="tertiary"
                size="icon_32"
                className="max-w-8 active:bg-grey-100"
                iconLeft={<PurpleIcon name="dots-vertical" className="shrink-0 text-grey-600" />}
                onFocusCapture={(evt) => evt.stopPropagation()}
              />
            </DropdownTrigger>
          </span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>File actions</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <DropdownContent className="max-w-[150px] gap-1" align="end">
        <DropdownItem
          iconName="download"
          isLoading={isDownloading}
          className={cn(
            'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
          )}
          onSelect={downloadFileHandler}
          onClick={(event) => event.stopPropagation()}
        >
          Download
        </DropdownItem>
        {isFileOwner && (
          <>
            <DropdownItem
              iconName="pencil"
              className={cn(
                'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
              )}
              onSelect={editFileHandler}
              onClick={(event) => event.stopPropagation()}
            >
              Edit
            </DropdownItem>
            <DropdownItem
              iconName="upload"
              className={cn(
                'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
              )}
              onSelect={fileUploadHandler}
              onClick={(event) => event.stopPropagation()}
            >
              Replace File
            </DropdownItem>
            <DropdownItem
              iconName="trash"
              className={cn(
                'cursor-pointer transition-colors duration-200 focus-visible:outline-none text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main',
              )}
              onSelect={deleteFileHandler}
              onClick={(event) => event.stopPropagation()}
            >
              Delete
            </DropdownItem>
          </>
        )}
      </DropdownContent>
      <input
        type="file"
        className="hidden"
        accept={ALLOWED_ACTIVITY_FILE_EXTENSIONS}
        ref={inputReference}
        onChange={fileChangeHandler}
      />
    </DropdownRoot>
  );
};
