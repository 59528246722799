import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, cn, Heading } from '@purple/ui';
import type React from 'react';

type TActivityTabContainerProperties = React.PropsWithChildren<
  React.ComponentPropsWithoutRef<'div'> & {
    title: string;
    headerClassName?: string;
    editing?: boolean;
    loading?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
    onEdit?: () => void;
  }
>;

export const ActivityTypeTabContainer: React.FC<TActivityTabContainerProperties> = (props) => {
  const { children, title, headerClassName, className, editing = false, loading = false, onSave, onEdit, onCancel, ...rest } = props;

  const editingControls = useMemo(
    () =>
      editing
        ? (
            <div className="ml-auto flex flex-row items-center gap-3">
              <Button type="button" variant="secondary" size="small" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="button" variant="primary" size="small" isLoading={loading} onClick={onSave}>
                Save
              </Button>
            </div>
          )
        : (
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="ml-auto"
              iconLeft={<PurpleIcon name="pencil" className="size-4 shrink-0" />}
              onClick={onEdit}
            >
              Edit
            </Button>
          ),
    [editing, loading, onCancel, onSave, onEdit],
  );

  return (
    <div className="flex w-full flex-col">
      <div
        className={cn(
          'flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-3',
          headerClassName,
        )}
      >
        <Heading tag="h2" variant="size-18" type="heading-600" className="leading-[25px]">
          {title}
        </Heading>
        {editingControls}
      </div>
      <div className={cn('flex w-full flex-col p-6', className)} {...rest}>
        {children}
      </div>
    </div>
  );
};
