import axiosInstance from '../../axios-config';
import { ACTIVITY_TYPES_ENDPOINTS } from '../../endpoints';
import type { TActivityTypeRolesRequestParameters, TActivityTypeRolesResponse } from '../activities.types';

export const getActivityTypeRoles = async ({ id, ...parameters }: TActivityTypeRolesRequestParameters) => {
  const response = await axiosInstance.get<TActivityTypeRolesResponse>(ACTIVITY_TYPES_ENDPOINTS.ROLES_LIST(id), {
    params: parameters,
  });

  return response.data;
};
