import { convertBooleanToText, formatDateShortMonthWithTime } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { TAdminSafBasicDetailsView } from '~/services';

type TTimelineViewProperties = {
  data: TAdminSafBasicDetailsView;
};

const TimelineView = ({ data }: TTimelineViewProperties) => {
  const { status_changed_at, created_at, on_hold_at, closed_at, days_open_until_action, documented_action_count, days_in_current_status, time_until_prioritized_days, holidays_between_opened_or_prioritized, time_until_closed_days, days_open, holidays_between_prioritized_closed, is_closed_on_time } = data;

  const showValue = (value: number | string | null) => {
    return typeof value === 'number' || typeof value === 'string' ? value : '-';
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Date/Time Status Changed</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{status_changed_at ? formatDateShortMonthWithTime(status_changed_at) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Date/Time Opened</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{created_at ? formatDateShortMonthWithTime(created_at) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Date/Time On Hold</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{on_hold_at ? formatDateShortMonthWithTime(on_hold_at) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Date/Time Closed</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{closed_at ? formatDateShortMonthWithTime(closed_at) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Open Days Until Action</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{showValue(days_open_until_action)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600"># of Actions Documented/Taken</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{showValue(documented_action_count)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Time In Current Status (Days)</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{showValue(days_in_current_status)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Time Until Prioritized (Days)</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{showValue(time_until_prioritized_days)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Holidays Between Opened/Prioritized</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{showValue(holidays_between_opened_or_prioritized)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Holidays Between Prioritized/Closed</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{showValue(holidays_between_prioritized_closed)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Closed On Time</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{convertBooleanToText(is_closed_on_time)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Time Until Closed (Days)</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{showValue(time_until_closed_days)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Days Since Opened</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{showValue(days_open)}</Text>
      </div>
    </div>
  );
};

export { TimelineView };
