import { useTabs } from '@purple/hooks';
import { AppTabs } from '@purple/ui';
import { CONTACTS_TABS_VALUES } from '../constants';
import { BasicDetails } from '../Tabs/BasicDetails';
import { ConnectedStudents } from '../Tabs/ConnectedStudents';
import type { FC } from 'react';
import type { TAppTab } from '@purple/ui';

export const ParentGuardianView: FC = () => {
  const tabs: TAppTab[] = [
    {
      label: 'Basic Details',
      value: CONTACTS_TABS_VALUES.BASIC_DETAILS,
      content: <BasicDetails />,
      permissions: [],
    },
    {
      label: 'Connected Students',
      value: CONTACTS_TABS_VALUES.CONNECTED_STUDENTS,
      content: <ConnectedStudents />,
      permissions: [],
    },
  ];

  const { activeTab, isReady, tabChangeHandler } = useTabs({
    tabs,
    defaultTab: CONTACTS_TABS_VALUES.BASIC_DETAILS,
  });

  return (
    <AppTabs tabs={tabs} listClassName="pl-2" value={activeTab} onValueChange={tabChangeHandler} isReady={isReady} />
  );
};
