import { PurpleIcon } from '@purple/icons';
import { Button, Heading, Text } from '@purple/ui';

type TPriorityListEmptyProps = {
  onCreate?: () => void;
};

export const PriorityListEmpty: React.FC<TPriorityListEmptyProps> = (props) => {
  const { onCreate } = props;

  return (
    <section className="flex w-full flex-col items-center gap-12 p-8">
      <div className="flex w-full flex-col items-center gap-6">
        <div className="flex size-16 items-center justify-center rounded-xl bg-grey-100">
          <PurpleIcon name="folder-open" className="size-9 text-grey-600" />
        </div>
        <div className="flex w-full flex-col items-center gap-5">
          <Heading tag="h2" type="heading-600" variant="size-18" className="text-grey-700">
            Your Priority List is empty
          </Heading>
          <Text
            type="body-400"
            variant="size-14"
            className="whitespace-pre-wrap px-32 text-center text-grey-600"
          >
            Add contacts to your priority list to target your outreach efforts more effectively. By creating a priority list, you can organize contacts based on their importance or relevance to your goals.
          </Text>
        </div>
      </div>
      <Button type="button" variant="primary" iconLeft={<PurpleIcon name="plus" className="shrink-0" />} onClick={onCreate}>
        Create Priority List
      </Button>
    </section>
  );
};
