import { useMemo } from 'react';
import {
  Pagination,
  PaginationButton,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { TABLE_DEFAULT_PAGE_SIZE, TABLE_DEFAULT_PAGE_SIZE_OPTIONS, TABLE_PAGINATION } from './constants';
import type { RowData, Table } from '@tanstack/react-table';

type TDataTablePaginationProperties<TData> = {
  table: Table<TData>;
  pageSizeOptions?: number[];
};

export const DataTablePagination = <TData extends RowData>({
  table,
  pageSizeOptions = TABLE_DEFAULT_PAGE_SIZE_OPTIONS,
}: TDataTablePaginationProperties<TData>): JSX.Element => {
  const pageCount = table.getPageCount();
  const rowsCount = table.getRowCount();
  const { pageIndex, pageSize } = table.getState().pagination;
  const isDisabled = useMemo(() => rowsCount <= TABLE_DEFAULT_PAGE_SIZE, [rowsCount]);

  const PaginationItems = useMemo(() => {
    const pages: React.ReactNode[] = [];

    pages.push(
      <PaginationItem key={0}>
        <PaginationButton isActive={pageIndex === 0} onClick={() => table.setPageIndex(0)}>
          1
        </PaginationButton>
      </PaginationItem>,
    );

    if (pageCount <= TABLE_PAGINATION.MAX_WITHOUT_ELLIPSIS) {
      for (let index = 1; index < pageCount; index += 1) {
        pages.push(
          <PaginationItem key={index}>
            <PaginationButton isActive={index === pageIndex} onClick={() => table.setPageIndex(index)}>
              {index + 1}
            </PaginationButton>
          </PaginationItem>,
        );
      }
    } else {
      if (pageIndex > 2) {
        const betweenIndex = Math.max(1, pageIndex - 3);
        pages.push(
          <PaginationItem key="start-ellipsis">
            <PaginationButton onClick={() => table.setPageIndex(betweenIndex)}>
              <PaginationEllipsis />
            </PaginationButton>
          </PaginationItem>,
        );
      }

      const startPage = Math.max(1, pageIndex - 1);
      const endPage = Math.min(pageCount - TABLE_PAGINATION.END_WITHOUT_ELLIPSIS, pageIndex + 1);

      for (let index = startPage; index <= endPage; index += 1) {
        pages.push(
          <PaginationItem key={index}>
            <PaginationButton isActive={index === pageIndex} onClick={() => table.setPageIndex(index)}>
              {index + 1}
            </PaginationButton>
          </PaginationItem>,
        );
      }

      if (pageIndex < pageCount - TABLE_PAGINATION.END_WITH_ELLIPSIS) {
        const index = Math.min(pageCount - 2, pageIndex + 3);
        pages.push(
          <PaginationItem key="end-ellipsis">
            <PaginationButton onClick={() => table.setPageIndex(index)}>
              <PaginationEllipsis />
            </PaginationButton>
          </PaginationItem>,
        );
      }

      pages.push(
        <PaginationItem key={pageCount - 1}>
          <PaginationButton isActive={pageIndex === pageCount - 1} onClick={() => table.setPageIndex(pageCount - 1)}>
            {pageCount}
          </PaginationButton>
        </PaginationItem>,
      );
    }

    return pages;
  }, [pageCount, pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex w-full flex-col flex-wrap items-center justify-center gap-4 overflow-auto p-4 sm:flex-row sm:justify-between">
      <div className="flex flex-row items-center gap-2" pw-id="rows-per-page">
        <Text variant="size-14" type="body-400" className="text-grey-600">
          Rows per page
        </Text>
        <RadixSelect
          defaultValue={TABLE_DEFAULT_PAGE_SIZE.toString()}
          value={`${pageSize}`}
          onValueChange={(value) => table.setPageSize(Number(value))}
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <RadixSelectTrigger
                pw-id="ded"
                className="h-8 min-w-16 gap-1 px-2 py-1 font-medium text-grey-600"
                disabled={isDisabled}
                onFocusCapture={(event) => event.stopPropagation()}
              >
                <RadixSelectValue className="text-grey-600" placeholder={pageSize} />
              </RadixSelectTrigger>
            </TooltipTrigger>
            {!isDisabled && (
              <TooltipPortal>
                <TooltipContent>Number of rows to display per page</TooltipContent>
              </TooltipPortal>
            )}
          </Tooltip>
          <RadixSelectContent align="start" className="min-w-24" viewportClassName="max-h-fit">
            {pageSizeOptions.map((option) => (
              <RadixSelectItem key={option} value={`${option}`}>
                {option}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        {rowsCount > 0 && (
          <Text variant="size-14" type="body-500" className="text-grey-950" pw-id="rows-per-page-text">
            {pageIndex * pageSize + 1}
            -
            {Math.min((pageIndex + 1) * pageSize, rowsCount)}
            {' '}
            <span className="font-normal text-grey-600">of</span>
            {' '}
            {rowsCount}
          </Text>
        )}
      </div>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
          </PaginationItem>
          {PaginationItems}
          <PaginationItem>
            <PaginationNext onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  );
};
