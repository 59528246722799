import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { getPriorityListContacts } from './get-priority-list-contacts';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TPriorityListContactsRequestParameters, TPriorityListContactsResponse } from './contacts.types';

export const usePriorityListContacts = (
  parameters: TPriorityListContactsRequestParameters,
  queryOptions?: TCustomUseQueryOptions<TPriorityListContactsResponse, AxiosError>,
) => {
  const { isError, error, ...queryData } = useQuery({
    queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_PRIORITY_LIST_CONTACTS, parameters],
    queryFn: () => getPriorityListContacts(parameters),
    retry: (failCount) => failCount < 3,
    ...queryOptions,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to fetch priority list contacts');
    }
  }, [isError, error]);

  return { ...queryData, isError };
};
