import { Link } from 'react-router-dom';
import { SAF_AREA_SUBCATEGORIES_LABELS } from '@purple/shared-types';
import { formatDateToLocalTime, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentCenteredAction } from '~/services/actions';

export const allActionsColumns: ColumnDef<TStudentCenteredAction>[] = [
  {
    accessorKey: 'title',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild className="w-fit">
          <span className="line-clamp-1 break-all">{original.title}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {original.title}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 180,
    meta: { label: 'Name' },
  },
  {
    accessorKey: 'record_action_type',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild className="w-fit">
          <span className="line-clamp-1 break-all">{snakeToCamelWithSpaces(original.record_action_type)}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {snakeToCamelWithSpaces(original.record_action_type)}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 120,
    meta: { label: 'Type' },
  },
  {
    accessorKey: 'student__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Student" />,
    cell: ({ row: { original } }) => {
      return (
        original.student
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  {/* TODO: Add link to the Student Details page when it will be ready */}
                  <Link to="#" className="line-clamp-1 break-all text-brand-blue-700" onClick={(event) => event.stopPropagation()}>{original.student.full_name}</Link>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.student.full_name}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    size: 150,
    meta: { label: 'Student' },
  },
  {
    accessorKey: 'school',
    header: ({ column }) => <DataTableColumnHeader column={column} title="School" />,
    cell: ({ row: { original } }) => {
      return (
        original.school
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.school.name}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.school.name}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    enableSorting: false,
    meta: { label: 'School' },
  },
  {
    accessorKey: 'action_group__name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Group Service" />,
    cell: ({ row: { original } }) => {
      return (
        original.group_service
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.group_service}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.group_service}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    meta: { label: 'Group Service' },
  },
  {
    accessorKey: 'date_and_time',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date & Time" />,
    cell: ({ row: { original } }) => (
      <span>{formatDateToLocalTime(original.date_and_time)}</span>
    ),
    size: 180,
    meta: { label: 'Date & Time' },
  },
  {
    accessorKey: 'service_categories',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Service Categories" />,
    cell: ({ row: { original } }) => {
      return (
        original.service_categories && original.service_categories.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.service_categories.map((category) => SAF_AREA_SUBCATEGORIES_LABELS[category.name]).join(', ')}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    <ul>
                      {original.service_categories.map((category) => (
                        <li key={category.id} className="list-inside list-decimal">{SAF_AREA_SUBCATEGORIES_LABELS[category.name]}</li>
                      ))}
                    </ul>
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    enableSorting: false,
    size: 150,
    meta: { label: 'Service Categories' },
  },
  {
    accessorKey: 'created_by__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created By" />,
    cell: ({ row: { original } }) => {
      return (
        original.created_by
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <Link
                    to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: {
                      id: original.created_by.id,
                    } })}
                    className="line-clamp-1 break-all text-brand-blue-700"
                    onClick={(event) => event.stopPropagation()}
                  >
                    {original.created_by.full_name}
                  </Link>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.created_by.full_name}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    size: 150,
    meta: { label: 'Created By' },
  },
];
