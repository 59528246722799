import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TBulkUploadResponse } from '@purple/shared-types';
import type { TUserBulkFileErrorResponse, TUserBulkInvitePayload } from '../user';

export const createBulkUsers = async (payload: TUserBulkInvitePayload) => {
  const formData = new FormData();
  formData.append('district', payload.district);
  formData.append('role', payload.role);
  formData.append('uploaded_file', payload.uploaded_file);
  const response = await axiosInstance.post<TBulkUploadResponse<TUserBulkFileErrorResponse>>(ADMIN_USER_MANAGEMENT.BULK_INVITE_USERS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
