import axiosInstance from '../axios-config';
import { SUBMITTER_TYPES_ENDPOINTS } from '../endpoints';
import type { TAdminSubmitterTypeListResponse, TAdminSubmitterTypesListRequestParameters } from './submitterTypes.types';

export const getSubmitterTypesList = async (parameters: TAdminSubmitterTypesListRequestParameters) => {
  const response = await axiosInstance.get<TAdminSubmitterTypeListResponse>(SUBMITTER_TYPES_ENDPOINTS.LIST, {
    params: parameters,
  });
  return response.data;
};
