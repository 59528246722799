import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { SAF_AREA_SUBCATEGORIES_LABELS } from '@purple/shared-types';
import { formatDateShortMonth, formatDateShortMonthWithTime, snakeToCamelWithSpaces } from '@purple/shared-utils';
import {
  Button,
  cn,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader, SelectableCell } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TActivityListItem } from '~/services';

type TUseActivityColumns = (options?: {
  onDelete?: (activity: TActivityListItem) => void;
}) => ColumnDef<TActivityListItem>[];

export const useActivityColumns: TUseActivityColumns = (options) => {
  const { onDelete } = options ?? {};

  const columns = useMemo(
    () =>
      [
        SelectableCell(),
        {
          accessorKey: 'name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to={AdminRoutes.App.Activities.Details.makePath({ dynamicParameters: { id: row.original.id } })}
                  className="line-clamp-1 min-w-0 break-all text-brand-blue-700"
                >
                  {row.original.name}
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {row.original.name}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 160,
        },
        {
          accessorKey: 'community_activity_type__name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {snakeToCamelWithSpaces(row.original.community_activity_type.name)}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {snakeToCamelWithSpaces(row.original.community_activity_type.name)}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 160,
          meta: { label: 'Type' },
        },
        {
          accessorKey: 'date_and_time',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Date&Time" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.date_and_time ? formatDateShortMonth(row.original.date_and_time) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.date_and_time && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(row.original.date_and_time)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Date&Time' },
        },
        {
          accessorKey: 'duration',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Duration" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.duration ?? '-'}</span>
              </TooltipTrigger>
              {typeof row.original.duration === 'number' && (
                <TooltipPortal>
                  <TooltipContent align="center" withArrow={false}>
                    {row.original.duration}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 80,
          enableSorting: false,
          meta: { className: 'text-center', label: 'Duration' },
        },
        {
          accessorKey: 'school__name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="School" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                {row.original.school
                  ? (
                      <Link
                        to={AdminRoutes.App.Schools.Details.makePath({ dynamicParameters: { schoolId: row.original.school.id } })}
                        className="line-clamp-1 min-w-0 break-all text-brand-blue-700"
                      >
                        {row.original.school.name}
                      </Link>
                    )
                  : (
                      <span className="line-clamp-1 min-w-0 break-all">-</span>
                    )}
              </TooltipTrigger>
              {row.original.school?.name && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.school.name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 160,
          meta: { label: 'School' },
        },
        {
          accessorKey: 'created_by__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Created By" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                {row.original.created_by
                  ? (
                      <Link
                        to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: row.original.created_by?.id } })}
                        className="line-clamp-1 min-w-0 break-all text-brand-blue-700"
                      >
                        {row.original.created_by?.full_name ?? '-'}
                      </Link>
                    )
                  : (
                      <span className="line-clamp-1 min-w-0 break-all">-</span>
                    )}
              </TooltipTrigger>
              {row.original.created_by?.full_name && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.created_by.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 120,
          meta: { label: 'Created By' },
        },
        {
          accessorKey: 'created_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Created Date" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.created_at ? formatDateShortMonth(row.original.created_at) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.created_at && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(row.original.created_at)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Created Date' },
        },
        {
          accessorKey: 'campus',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Campus" />,
          cell: () => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  -
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  -
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Campus' },
        },
        {
          accessorKey: 'updated_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Last Updated" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.updated_at ? formatDateShortMonth(row.original.updated_at) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.updated_at && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formatDateShortMonthWithTime(row.original.updated_at)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Last Updated' },
        },
        {
          accessorKey: 'description',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Description" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.description ?? '-'}</span>
              </TooltipTrigger>
              {row.original.description && (
                <TooltipPortal>
                  <TooltipContent align="center" withArrow={false}>
                    {row.original.description}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 160,
          meta: { label: 'Description' },
        },
        {
          accessorKey: 'service_categories__name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Service Categories" />,
          cell: ({ row }) => (
            <HoverCard>
              <HoverCardTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.service_categories?.map(({ name }) => SAF_AREA_SUBCATEGORIES_LABELS[name]).join(', ') || '-'}
                </span>
              </HoverCardTrigger>
              {row.original.service_categories && row.original.service_categories.length > 0 && (
                <HoverCardPortal>
                  <HoverCardContent align="start" className="inline-flex w-full justify-start whitespace-pre-wrap border border-grey-200 p-2 text-xs font-medium leading-5 text-grey-950">
                    {row.original.service_categories.map(({ name }) => SAF_AREA_SUBCATEGORIES_LABELS[name]).join('\n') || '-'}
                  </HoverCardContent>
                </HoverCardPortal>
              )}
            </HoverCard>
          ),
          size: 160,
          meta: { label: 'Service Categories' },
        },
        {
          accessorKey: 'actions',
          header: () => null,
          cell: ({ row }) => !row.getIsSelected() && (
            <DropdownRoot>
              <Tooltip>
                <TooltipTrigger asChild>
                  <DropdownTrigger asChild>
                    <Button
                      type="button"
                      variant="tertiary"
                      size="icon_32"
                      className="size-8 shrink-0 active:bg-grey-100"
                      iconLeft={<PurpleIcon name="dots-vertical" className="shrink-0 text-grey-600" />}
                      onFocusCapture={(event) => event.stopPropagation()}
                    />
                  </DropdownTrigger>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent withArrow={false}>Actions</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <DropdownContent className="max-w-[150px] gap-1" align="end">
                <DropdownItem
                  iconName="trash"
                  className={cn(
                    'cursor-pointer transition-colors duration-200 focus-visible:outline-none text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main',
                  )}
                  onSelect={() => onDelete?.(row.original)}
                  onClick={(event) => event.stopPropagation()}
                >
                  Delete
                </DropdownItem>
              </DropdownContent>
            </DropdownRoot>
          ),
          size: 60,
          enableHiding: false,
          enableSorting: false,
          meta: { className: 'text-right' },
        },
      ] satisfies ColumnDef<TActivityListItem>[],
    [onDelete],
  );

  return columns;
};
