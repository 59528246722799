import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { TAGS_QUERY_KEY } from './tags.const';
import { updateCustomTag } from './update-custom-tag';
import type { AxiosError } from 'axios';
import type { TUpdateCustomTagPayload, TUpdateCustomTagResponse } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useUpdateCustomTag = (
  options?: TCustomUseMutationOptions<TUpdateCustomTagResponse, AxiosError, TUpdateCustomTagPayload>,
) => {
  return useMutation({
    ...options,
    mutationKey: [TAGS_QUERY_KEY.UPDATE_TAGS],
    mutationFn: updateCustomTag,
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
    },
    onError: (error, ...args) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to update custom tag');
      }
      options?.onError?.(error, ...args);
    },
  });
};
