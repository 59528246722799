import { Link } from 'react-router-dom';
import { formatDateToLocalTime } from '@purple/shared-utils';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TDashboardLastUpdatesItem, TDashboardUpdatesEventValues } from '~/services';

type THomeLastUpdatesColumns = ColumnDef<TDashboardLastUpdatesItem>[];

export const homeLastUpdatesColumns: THomeLastUpdatesColumns = [
  {
    accessorKey: 'created_at',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date" />,
    cell: ({ row }) => <span>{formatDateToLocalTime(row.getValue('created_at'))}</span>,
    size: 240,
    meta: { label: 'Date' },
  },
  {
    accessorKey: 'created_by__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="User" />,
    cell: ({ row }) => (
      <Link
        to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: row.original.created_by.id } })}
        className="line-clamp-1 w-fit break-all font-semibold text-brand-blue-700 transition-colors hover:text-brand-blue-500"
      >
        {row.original.created_by.full_name}
      </Link>
    ),
    meta: { label: 'User' },
  },
  {
    accessorKey: 'event',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Event" />,
    cell: ({
      row: {
        original: { event, user },
      },
    }) => {
      const getEventText = (eventText: TDashboardUpdatesEventValues) => {
        switch (eventText) {
          case 'activated': {
            return 'Activated User';
          }
          case 'deactivated': {
            return 'Deactivated User';
          }
          case 'permissions_updated': {
            return 'Permissions Updated For User';
          }
          default: {
            return 'Unknown Event';
          }
        }
      };

      return (
        <span className="line-clamp-1 inline break-all font-semibold">
          {getEventText(event)}
          {' '}
          <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: user.id } })} className="w-fit text-brand-blue-700 transition-colors hover:text-brand-blue-500">
            {user.full_name}
          </Link>
        </span>
      );
    },
  },
];
