import { ensureUniqueValues } from '@purple/shared-utils';

export const TableName = ensureUniqueValues({
  BULK_IMPORT_ACTIONS: '[Table]::BULK_IMPORT_ACTIONS',
  BULK_IMPORT_CONTACTS: '[Table]::BULK_IMPORT_CONTACTS',
  BULK_IMPORT_RULES: '[Table]::BULK_IMPORT_RULES',
  BULK_IMPORT_PURPLE_USERS: '[Table]::BULK_IMPORT_PURPLE_USERS',
  ACTION_NOTES: '[Table]::ACTION_NOTES',
  ACTION_TYPES: '[Table]::ACTION_TYPES',
  ACTIONS: '[Table]::ACTIONS',
  ACTIVITY_FILES: '[Table]::ACTIVITY_FILES',
  ACTIVITY_HISTORY: '[Table]::ACTIVITY_HISTORY',
  ACTIVITIES: '[Table]::ACTIVITIES',
  ACTIVITY_TYPES: '[Table]::ACTIVITY_TYPES',
  BANNERS_PUBLISHED: '[Table]::BANNERS_PUBLISHED',
  BANNERS_DRAFTED: '[Table]::BANNERS_DRAFTED',
  CONTACTS_STUDENTS: '[Table]::CONTACTS_STUDENTS',
  CONTACTS: '[Table]::CONTACTS',
  CONTACTS_PRIORITY_LIST: '[Table]::CONTACTS_PRIORITY_LIST',
  DISTRICT_SIS_DATA: '[Table]::DISTRICT_SIS_DATA',
  DISTRICT_USERS: '[Table]::DISTRICT_USERS',
  DISTRICT_DRAFTED: '[Table]::DISTRICT_DRAFTED',
  DISTRICT_PUBLISHED: '[Table]::DISTRICT_PUBLISHED',
  DOCUMENTED_ACTIONS: '[Table]::DOCUMENTED_ACTIONS',
  HOME_UPDATES: '[Table]::HOME_UPDATES',
  REPORT_LOGGED_IN_BY_ROLE: '[Table]::REPORT_LOGGED_IN_BY_ROLE',
  REPORT_NOT_LOGGED_IN: '[Table]::REPORT_NOT_LOGGED_IN',
  REPORT_THIS_WEEK_LOGINS: '[Table]::REPORT_THIS_WEEK_LOGINS',
  REPORT_USER_FREQUENCY_DISTRIBUTION: '[Table]::REPORT_USER_FREQUENCY_DISTRIBUTION',
  SAF_DOCUMENTED_ACTIONS: '[Table]::SAF_DOCUMENTED_ACTIONS',
  SAF_EMAILS: '[Table]::SAF_EMAILS',
  SAF_HISTORY: '[Table]::SAF_HISTORY',
  SAFS: '[Table]::SAFS',
  SAF_ROUTING_RULES: '[Table]::SAF_ROUTING_RULES',
  SAF_SUBMITTER_TYPES: '[Table]::SAF_SUBMITTER_TYPES',
  SAF_PRIORITIZED: '[Table]::SAF_PRIORITIZED',
  SCHOOL_ACTIONS: '[Table]::SCHOOL_ACTIONS',
  SCHOOL_CONTACTS: '[Table]::SCHOOL_CONTACTS',
  SCHOOL_SAFS: '[Table]::SCHOOL_SAFS',
  SCHOOL_USERS: '[Table]::SCHOOL_USERS',
  SCHOOLS: '[Table]::SCHOOLS',
  USER_PERMISSION_GROUPS: '[Table]::USER_PERMISSION_GROUPS',
  USER_GROUP_ADD: '[Table]::USER_GROUP_ADD',
  USER_PERMISSION_ROLES: '[Table]::USER_PERMISSION_ROLES',
  USER_HISTORY: '[Table] USER_HISTORY',
  USER_PERMISSIONS: '[Table] USER_PERMISSIONS',
  USER_SCHOOLS: '[Table] USER_SCHOOLS',
  USER_SAFS: '[Table] USER_SAFS',
  USERS: '[Table] USERS',
  PRINT_CONTACTS: '[Table] PRINT_CONTACTS',
  PRINT_SAFS: '[Table] PRINT_SAFS',
  PRINT_USERS: '[Table] PRINT_USERS',
});
