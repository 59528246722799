import { Heading, Separator } from '@purple/ui';
import { AnonymousSafSubmissionArea, CityOrRegionArea, NeedTypesArea } from './components';
import { SafSettingsTabSkeleton } from './SafSettingsTabSkeleton';
import type { TAdminSubmitterTypeDetail } from '~/services';

type TSafSettingsTabProperties = {
  data?: TAdminSubmitterTypeDetail;
};

const SafSettingsTab = ({ data }: TSafSettingsTabProperties) => {
  if (!data) return <SafSettingsTabSkeleton />;

  return (
    <div className="flex flex-col">
      <div className="px-6 py-5">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">SAF Settings</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <AnonymousSafSubmissionArea data={data} />
      <Separator className="bg-grey-200" />
      <CityOrRegionArea data={data} />
      <Separator className="bg-grey-200" />
      <NeedTypesArea data={data} />
    </div>
  );
};

export { SafSettingsTab };
