import { showErrorToast } from './toasts';
import type { AxiosError } from 'axios';

// Improve this function latter if needed

/**
 * Handles Axios errors by returning user-friendly error messages.
 *
 * @param {AxiosError} error - The Axios error object to handle.
 * @returns {void} - A toast notification with message in it.
 */
export const defaultErrorHandling = ({ request, response }: AxiosError) => {
  if (response) {
    // Handle specific HTTP status codes
    switch (response.status) {
      case 500: {
        return showErrorToast('Server error', 'An error occurred on the server. Please try again later.');
      }
      case 502: {
        return showErrorToast('Server error', 'The server is down or being upgraded. Please try again later.');
      }
      case 403: {
        return showErrorToast('Unauthorized', 'You do not have permission to perform this action.');
      }
      case 401 : {
        return showErrorToast('Unauthorized', 'Your session has expired. Please log in again.');
      }
      default: {
        return;
      }
    }
  }

  // Handle cases where the response is not available (network issues)
  if (request) {
    return showErrorToast('Server error', 'The server is down or being upgraded. Please try again later.');
  }

  return showErrorToast('Network error', 'A network error occurred. Please check your internet connection.');
};
/**
 * Checks if the error status is less than 500 and not equal to 401 or 403.
 *
 * @param {AxiosError} error - The Axios error object to check.
 * @returns {boolean} True if the error status is less than 500 and not equal to 401 or 403; otherwise, false.
 */
export const isClientError = ({ response }: AxiosError): boolean => {
  if (!response) {
    return false;
  }
  const { status } = response;
  return status < 500 && status !== 401 && status !== 403;
};

/**
 * Helper function to determine if a query should be retried based on the failure count and response status.
 *
 * @param failCount - The number of times the query has failed.
 * @param error - The Axios error object containing the response.
 * @param retryLimit - The maximum number of retry attempts allowed (default is 3).
 * @returns A boolean indicating whether the query should be retried.
 */
export const queryRetryHelper = (failCount: number, error: AxiosError, retryLimit = 3) => {
  const { status } = error.response || {};
  return failCount < retryLimit && status !== 401 && status !== 403 && status !== 404;
};
