import axiosInstance from '../../axios-config';
import { ACTIVITY_TYPES_ENDPOINTS } from '../../endpoints';
import type { TActivityTypeGroupsRequestParameters, TActivityTypeGroupsResponse } from '../activities.types';

export const getActivityTypeGroups = async ({ id, ...parameters }: TActivityTypeGroupsRequestParameters) => {
  const response = await axiosInstance.get<TActivityTypeGroupsResponse>(ACTIVITY_TYPES_ENDPOINTS.GROUPS_LIST(id), {
    params: parameters,
  });

  return response.data;
};
