import axiosInstance from '../axios-config';
import { ACTIONS_ENDPOINTS } from '../endpoints';
import type { TBulkUploadResponse } from '@purple/shared-types';
import type { TActionBulkUploadPayload, TActionBulkUploadResponse } from './actions.types';

export const createBulkActions = async (payload: TActionBulkUploadPayload) => {
  const formData = new FormData();
  formData.append('district', payload.district);
  formData.append('record_action_type', payload.record_action_type);
  formData.append('uploaded_file', payload.uploaded_file);
  const response = await axiosInstance.post<TBulkUploadResponse<TActionBulkUploadResponse<typeof payload.record_action_type>>>(ACTIONS_ENDPOINTS.IMPORT_BULK_ACTIONS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
