import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { Toaster, TooltipProvider } from '@purple/ui';
import { queryClient } from '~/constants';
import { RouteHistoryProvider, ThemeProvider, UnsavedChangesProvider } from '~/providers';

import { AdminApplication } from './App';

import { store } from './store';
import '@purple/ui/main.css';

const rootElement = document.querySelector('#root')!;
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <RouteHistoryProvider>
            <ThemeProvider>
              <TooltipProvider>
                <UnsavedChangesProvider>
                  <AdminApplication />
                  <Toaster />
                  <ReactQueryDevtools initialIsOpen={false} />
                </UnsavedChangesProvider>
              </TooltipProvider>
            </ThemeProvider>
          </RouteHistoryProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);
