/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@purple/ui';

export const RoleTabSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-4">
        <Skeleton className="h-[25px] w-[112px]" />
        <Skeleton className="h-[25px] w-[60px]" />
      </div>
      <div className="flex w-full flex-col">
        <div className="px-6 py-4 pt-6">
          <Skeleton className="h-10 w-full max-w-[252px]" />
        </div>
        <div className="grid h-12 w-full grid-cols-12 items-center border-b border-grey-300 p-3">
          <Skeleton className="col-span-6 h-5 w-full max-w-[50px]" />
          <Skeleton className="col-span-6 h-5 w-full max-w-[136px]" />
        </div>
        {Array.from({ length: 6 }).map((_, index) => (
          <div className="grid h-14 w-full grid-cols-12 items-center border-b border-grey-300 p-3 last-of-type:border-none" key={index}>
            <Skeleton className="col-span-6 h-5 w-full max-w-[120px]" />
            <Skeleton className="col-span-6 size-5" />
          </div>
        ))}
      </div>
    </div>
  );
};
