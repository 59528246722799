import type { ValueOf } from '../utils';

export const NotificationContentType = {
  SCHOOL: 'school',
  USER: 'user',
  DISTRICT: 'district',
  COMMUNITY_ACTIVITY: 'community_activity',
  PROGRAM: 'program',
} as const;
export type TNotificationContentType = ValueOf<typeof NotificationContentType>;

export type TNotification = {
  id: string;
  created_at: string;
  title: string;
  body: string;
  content_type: TNotificationContentType;
  object_id: string;
  is_marked_as_read: boolean;
};
