import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { applyCustomTags } from './apply-custom-tags';
import { TAGS_QUERY_KEY } from './tags.const';
import type { AxiosError } from 'axios';
import type { TApplyCustomTagsPayload, TApplyCustomTagsResponse } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useApplyCustomTags = (
  options?: TCustomUseMutationOptions<TApplyCustomTagsResponse, AxiosError, TApplyCustomTagsPayload>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationKey: [TAGS_QUERY_KEY.APPLY_TAGS],
    mutationFn: applyCustomTags,
    onSuccess: (data, vars, ...args) => {
      const { object_id, content_type, district_id } = vars;
      queryClient.invalidateQueries({ queryKey: [TAGS_QUERY_KEY.GET_CUSTOM_TAGS, { object_id, content_type, district: district_id }], exact: true });
      queryClient.invalidateQueries({ queryKey: [TAGS_QUERY_KEY.GET_CUSTOM_TAGS, { content_type, district: district_id }], exact: true });
      showSuccessToast('System message', 'Tags applied successfully');
      options?.onSuccess?.(data, vars, ...args);
    },
    onError: (error, ...args) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to apply tags');
      }
      options?.onError?.(error, ...args);
    },
  });
};
