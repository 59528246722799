import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TAGS_TYPES } from '@purple/shared-types';
import { tagsSchema } from '@purple/shared-utils';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Tag,
  TagAction,
  TagList,
  TagSelect,
} from '@purple/ui';
import { CreateTagModal, UpdateTagModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useApplyCustomTags, useCustomTags } from '~/services';
import { SchoolSectionHeader } from '../../SchoolSectionHeader';
import { TagsSectionSkeleton } from './TagSectionSkeleton';
import type { z } from 'zod';
import type { TSchoolBasicDetails } from '~/services';

type TTagsSectionProps = {
  data: TSchoolBasicDetails;
};

export const TagsSection: React.FC<TTagsSectionProps> = (props) => {
  const { data } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { openModal: openTagModal } = useModal(ModalType.CREATE_CUSTOM_TAG);
  const { openModal: openUpdateTagModal } = useModal(ModalType.UPDATE_CUSTOM_TAG);

  const { data: tags, isFetching: isFetchingAll } = useCustomTags({
    content_type: TAGS_TYPES.SCHOOL,
    district: data.district,
  });
  const { data: objectTags, isFetching: isFetchingSelected } = useCustomTags({
    content_type: TAGS_TYPES.SCHOOL,
    object_id: data.id,
    district: data.district,
  });
  const { mutate: applyTags, isPending } = useApplyCustomTags();

  const tagOptions = useMemo(() => tags?.results ?? [], [tags]);
  const selectedTags = useMemo(() => objectTags?.results ?? [], [objectTags]);
  const isFetching = useMemo(() => isFetchingAll || isFetchingSelected, [isFetchingAll, isFetchingSelected]);

  const defaultValues: z.infer<typeof tagsSchema> = useMemo(
    () => ({
      tags: selectedTags ?? [],
    }),
    [selectedTags],
  );

  const form = useForm<z.infer<typeof tagsSchema>>({
    resolver: zodResolver(tagsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const saveTagsClickHandler = (formData: z.infer<typeof tagsSchema>) => {
    applyTags(
      {
        district_id: data.district,
        object_id: data.id,
        content_type: TAGS_TYPES.SCHOOL,
        tags: formData.tags.map((tag) => tag.id),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  if (isFetching) return <TagsSectionSkeleton />;

  return (
    <div className="flex w-full flex-col gap-4">
      <SchoolSectionHeader
        title="Tags"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveTagsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="tags"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full max-w-[320px] flex-1 flex-col gap-1 space-y-0 xl:max-w-[400px]">
                    <FormControl>
                      <TagSelect
                        {...field}
                        isError={!!fieldState.error}
                        options={tagOptions}
                        onActionClick={openTagModal}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <TagList>
              {selectedTags.map((tag) => (
                <Tag key={tag.id} onClick={openUpdateTagModal}>
                  {tag.name}
                </Tag>
              ))}
              <TagAction onClick={openTagModal}>Apply Tag</TagAction>
            </TagList>
          )}
      <CreateTagModal
        relatedDistrictId={data.district}
        relatedEntityId={data.id}
        tagsType={TAGS_TYPES.SCHOOL}
        existingNames={tagOptions.map((tag) => tag.name)}
      />
      <UpdateTagModal
        tags={selectedTags}
        relatedDistrictId={data.district}
        relatedEntityId={data.id}
        tagsType={TAGS_TYPES.SCHOOL}
        allTags={tagOptions}
      />
    </div>
  );
};
