import { z } from 'zod';

export const roleSchema = z.object({
  roles: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      is_available: z.boolean(),
    }),
  ),
});
