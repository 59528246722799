import { useCallback, useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { PurpleIcon } from '@purple/icons';
import { Button, Form, Message, Tabs, TabsContent, Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useGoBack } from '~/hooks';
import { usePermissionStructure, useUpdateUserDetailsPermissions, useUserDetails, useUserDetailsPermissionsCodeNames } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ManageRolesFormSideBar, useManageRolesTabs } from '../Roles';
import { USER_DETAILS_TABS_VALUE } from './constants';

const assignUserPermissionSchema = z.object({
  permissions: z.array(z.string()).min(1, { message: 'Please select at least one permission' }),
});

type TFormValues = z.infer<typeof assignUserPermissionSchema>;

export const UserManagePermissions = () => {
  const { userId } = useParams();
  const formId = useId();

  const { onGoBack } = useGoBack({
    fallbackPath: AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: userId as string }, queries: { tab: USER_DETAILS_TABS_VALUE.PERMISSIONS } }),
  });

  const { data, isLoading } = useUserDetails({ userId, enabled: !!userId });
  const { data: defaultUserPermissions, isLoading: isDefaultPermissionsLoading } = useUserDetailsPermissionsCodeNames({ userId: userId!, queryOptions: { enabled: !!userId } });
  const { first_name, last_name } = data || {};
  const { data: permissionStructure, isLoading: isStructureLoading } = usePermissionStructure();
  const { mutate: updateUserPermissions, isPending: isUpdating } = useUpdateUserDetailsPermissions();

  const { tabs } = useManageRolesTabs({ permissionStructure });

  const defaultValues = useMemo(() => ({
    permissions: defaultUserPermissions?.permission_codenames,
  }), [defaultUserPermissions]);

  const form = useForm<TFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(assignUserPermissionSchema),
    defaultValues,
  });

  const assignUserPermission = useCallback((formValues: TFormValues) => {
    if (!userId) {
      return showErrorToast('System message', `Unable to update permissions for user with id ${userId}`);
    }
    updateUserPermissions({ userId, prounitas_permissions: formValues.permissions }, {
      onSuccess: () => {
        onGoBack();
      },
    });
  }, [updateUserPermissions, userId, onGoBack]);

  useEffect(() => {
    form.reset(defaultValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  if (isLoading || isStructureLoading || isDefaultPermissionsLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <PurpleIcon name="loader" className="size-9 animate-spin" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center justify-between gap-2 border-b border-b-grey-300 bg-white px-8 py-3.5">
        <div className="flex items-center gap-2">
          <Button variant="tertiary_icon_only" size="icon_32" onClick={onGoBack} iconLeft={<PurpleIcon name="chevron-left" />} />
          <Text variant="size-16" type="body-600">{`Manage Permissions for ${first_name} ${last_name}`}</Text>
        </div>
        <Button type="submit" variant="primary" form={formId} isLoading={isUpdating}>
          {isUpdating ? 'Saving...' : 'Save & Assign'}
        </Button>
      </div>
      <Form providerProps={form} id={formId} onSubmit={form.handleSubmit(assignUserPermission)}>
        <Tabs className="flex gap-7 px-7" defaultValue={tabs[0]?.value}>
          <ManageRolesFormSideBar />
          <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white p-6">
            <Message variant="warning">
              <Text variant="size-14" type="body-600">This is a list of permissions directly assigned to the user. Permissions from groups and roles are not displayed here. Negative permissions, which can affect the user's final permission list by overriding other permissions, are also not displayed here.</Text>
            </Message>
            {tabs.map((tab) => (
              <TabsContent key={tab.value} value={tab.value}>
                {tab.content}
              </TabsContent>
            ))}
          </div>
        </Tabs>
      </Form>
    </div>
  );
};
