import { memo, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { ALLOWED_BULK_TEMPLATES } from '@purple/shared-utils';
import { AppFileDropZone, Button, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage, Text, UploadedFilesPreview } from '@purple/ui';
import { useDistrictsListBasicInfo, useDownloadBulkTemplate, useRoleSearch } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { BULK_DROPZONE_ALLOWED_FILE_TYPES, MAX_FILE_SIZE, type TBulkUploadUsersSchema } from './schema';
import type { FC } from 'react';

const DEFAULT_SEARCH_DELAY = 500;

type TUploadFormContentProps = {
  isReuploading: boolean;
};

export const UploadFormContent: FC<TUploadFormContentProps> = memo(({ isReuploading }) => {
  const { control, setValue, watch } = useFormContext<TBulkUploadUsersSchema>();

  const selectedDistrict = watch('district');
  const uploadedFile = watch('file');

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
  const [rolesDebouncedSearchValue, setRolesDebouncedSearchValue] = useState<string>('');

  const { data: activeDistricts, isFetching } = useDistrictsListBasicInfo({
    search: debouncedSearchValue,
    limit: 50,
    status: 'published',
  });

  const { data: roles, isLoading: isRolesLoading } = useRoleSearch({
    requestParameters: {
      search: rolesDebouncedSearchValue,
      district: selectedDistrict,
    },
    enabled: selectedDistrict.length > 0,
  });

  const { mutate, isPending } = useDownloadBulkTemplate(ALLOWED_BULK_TEMPLATES.IMPORT_USERS);

  const districtsSelectOptions = useMemo(() => {
    return (
      activeDistricts?.results.map((district) => ({
        ...district,
        label: district.name,
        value: district.id,
      })) ?? []
    );
  }, [activeDistricts]);

  const rolesSelectOptions = useMemo(() => {
    return (
      roles?.results.filter((role) => role.name !== 'Super Admin' && role.name !== 'District Success Admin').map((role) => ({
        ...role,
        label: role.name,
        value: role.id,
      })) ?? []
    );
  }, [roles]);

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const rolesDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setRolesDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const downloadTemplateHandler = useCallback(() => {
    mutate(ALLOWED_BULK_TEMPLATES.IMPORT_USERS, {
      onSuccess: (response) => {
        const link = document.createElement('a');
        link.href = response.file;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(response.file);
        showSuccessToast('System message', 'The template has been downloaded successfully to your default download location.');
      },
    });
  }, [mutate]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-4">
        <FormField
          control={control}
          name="district"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>District</FormLabel>
              <FormControl>
                <ComboBox modal>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select district"
                    disabled={isReuploading}
                    selectedLabel={
                      districtsSelectOptions.find((option) => option.value.toString() === field.value.toString())?.label
                    }
                  />
                  <ComboBoxContent
                    loading={isFetching}
                    shouldFilter={false}
                    searchPlaceholder="Search district..."
                    emptyContent="District not found."
                    onSearchChange={debouncedSearch}
                  >
                    {activeDistricts?.results?.map(({ id, name }) => (
                      <ComboBoxItem
                        key={id}
                        value={id.toString()}
                        selected={field.value.toString() === id.toString()}
                        onSelect={
                          (value) => {
                            setValue('role', '');
                            field.onChange(value);
                          }
                        }
                      >
                        {name}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="role"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Role</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select role"
                    selectedLabel={
                      rolesSelectOptions.find((option) => option.value.toString() === field.value)?.label
                    }
                    disabled={selectedDistrict.length === 0 || isReuploading}
                  />
                </FormControl>
                <ComboBoxContent
                  loading={isRolesLoading}
                  onSearchChange={rolesDebouncedSearch}
                  shouldFilter={false}
                  searchPlaceholder="Search role name..."
                  emptyContent="No roles found."
                >
                  {rolesSelectOptions.map(({ label, value }) => (
                    <ComboBoxItem
                      key={value}
                      value={value.toString()}
                      selected={value.toString() === field.value}
                      onSelect={field.onChange}
                    >
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-col items-center gap-2">
          <Text variant="size-16" type="body-600">Download Template</Text>
          <Text variant="size-14" type="body-400" className="text-grey-600">Download the template and effortlessly upload all your users information with just one click.</Text>
        </div>
        <Button type="button" iconLeft={isPending ? <PurpleIcon name="loader" className="animate-spin" /> : <PurpleIcon name="download" />} variant="tertiary" size="small" onClick={downloadTemplateHandler}>
          {isPending ? 'Downloading...' : 'Download Template'}
        </Button>
      </div>
      <div>
        {uploadedFile && uploadedFile.length > 0
          ? (
              <UploadedFilesPreview
                fileName={uploadedFile[0]?.name}
                fileType={uploadedFile[0]?.type}
                onRemove={() => setValue('file', [])}
              />
            )
          : (
              <FormField
                control={control}
                name="file"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <AppFileDropZone
                        value={field.value}
                        onValueChange={(files) => files && setValue('file', files, { shouldValidate: true })}
                        accept={BULK_DROPZONE_ALLOWED_FILE_TYPES}
                        maxSize={MAX_FILE_SIZE}
                        description="The uploaded file should be in XLSX or CSV format and less than 10MB."
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
      </div>
    </div>
  );
});
