import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Heading, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions } from '~/components';
import { AdminRoutes, ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useActivateSchool, useDeactivateSchool, useSchoolFilterChoices, useSchools } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { DistrictSelector } from './DistrictSelector';
import { useSchoolColumns } from './useSchoolColumns';
import type { TSchoolItem } from '~/services';

export const SchoolsPage: React.FC = () => {
  const [searchParameters] = useSearchParams();
  const navigate = useNavigate();

  const { openModal: openStatusModal, closeModal: closeStatusModal } = useModal(ModalType.CHANGE_SCHOOL_STATUS);

  const [selectedSchool, setSelectedSchool] = useState<TSchoolItem | null>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<string | null>(null);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = useSchools({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    district: selectedDistrict,
    city: searchParameters.get('city'),
    grades: searchParameters.get('grades'),
    is_active: searchParameters.get('status'),
    state: searchParameters.get('state'),
    school_level: searchParameters.get('school_level'),
  });
  const { data: options, isFetching: isOptionsLoading } = useSchoolFilterChoices();
  const { mutate: deactivateSchool, isPending: isDeactivatePending } = useDeactivateSchool();
  const { mutate: activateSchool, isPending: isActivatePending } = useActivateSchool();

  const isStatusPending = useMemo(() => isDeactivatePending || isActivatePending, [isDeactivatePending, isActivatePending]);
  const schools = useMemo(() => data?.results ?? [], [data?.results]);
  const filters = useMemo(
    () =>
      options
        ? Object.entries(options).reduce(
            (accumulator, [key, value]) => ({ ...accumulator, [key]: value }),
            { status: [{ id: 'true', name: 'Active' }, { id: 'false', name: 'Inactive' }] },
          )
        : null,
    [options],
  );
  const filterConfig = useMemo(
    () => (filters ? convertToFilterConfig(filters, { snakeCaseValue: false }) : { categories: [] }),
    [filters],
  );

  const schoolStatusUpdateHandler = useCallback(() => {
    if (!selectedSchool) {
      return showErrorToast('School not found', 'Please select a school to update its status');
    }

    const updateStatus = selectedSchool.is_active ? deactivateSchool : activateSchool;

    updateStatus(selectedSchool.id, {
      onSuccess: () => {
        closeStatusModal();
        setSelectedSchool(null);
      },
    });
  }, [selectedSchool, activateSchool, closeStatusModal, deactivateSchool]);

  const schoolStatusChangeHandler = useCallback(
    (school: TSchoolItem) => {
      setSelectedSchool(school);
      openStatusModal();
    },
    [openStatusModal],
  );

  const columns = useSchoolColumns({
    onDeactivate: schoolStatusChangeHandler,
  });

  const { table } = useDataTable({
    name: TableName.SCHOOLS,
    columns,
    data: schools,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['name'],
        right: ['actions'],
      },
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  const schoolRowClickHandler = useCallback(
    (school: TSchoolItem) => {
      navigate(AdminRoutes.App.Schools.Details.makePath({ dynamicParameters: { schoolId: school.id } }));
    },
    [navigate],
  );

  const districtChangeHandler = useCallback(
    (districtId: string | null) => {
      setSelectedDistrict(districtId);
    },
    [setSelectedDistrict],
  );

  return (
    <div className="flex w-full flex-col gap-6">
      <Heading tag="h1" variant="size-28" type="heading-600" className="text-grey-title">
        Schools
      </Heading>
      <DataTable table={table} loading={isFetching} onRowClick={schoolRowClickHandler} className="border-grey-200 shadow-custom-heavy rounded-lg border bg-white">
        <div className="flex w-full items-center gap-4 p-4 pt-6">
          <DistrictSelector selectedDistrictId={selectedDistrict} onDistrictChange={districtChangeHandler} />
          <AppFilters config={filterConfig} loading={isOptionsLoading} />
          <SearchInput
            value={search}
            placeholder="Search"
            className="max-w-[300px]"
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
          <DataTableViewOptions table={table} />
        </div>
        <CallToActionModal
          modalName={ModalType.CHANGE_SCHOOL_STATUS}
          modalTitle={`${selectedSchool?.is_active ? 'Deactivate' : 'Activate'} School`}
          modalDescription="By changing the status of the school, you will change its access on the Purple Sense platform."
          modalTextContent={`Are you sure you want to ${selectedSchool?.is_active ? 'deactivate' : 'activate'} ${selectedSchool?.name} school?`}
          primaryButtonText={selectedSchool?.is_active ? 'Deactivate' : 'Activate'}
          secondaryButtonText="Cancel"
          onPrimaryButtonClick={schoolStatusUpdateHandler}
          primaryButtonVariant={selectedSchool?.is_active ? 'destructive_primary' : 'primary'}
          isLoading={isStatusPending}
        />
      </DataTable>
    </div>
  );
};
