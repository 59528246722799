import axiosInstance from '../axios-config';
import { SAF_ENDPOINTS } from '../endpoints';
import type { TAdminSafListFiltersResponse } from './saf.types';

export const getSafsListFilters = async ({ user }: { user?: string }) => {
  const response = await axiosInstance.get<TAdminSafListFiltersResponse>(SAF_ENDPOINTS.LIST_FILTERS, {
    params: {
      user,
    },
  });
  return response.data;
};
