import axiosInstance from '../axios-config';
import { ACTIONS_ENDPOINTS } from '../endpoints';
import type { TDocumentedActionsRequestParameters } from './actions.types';

export const exportDocumentedActionsList = async (requestParameters: TDocumentedActionsRequestParameters) => {
  const response = await axiosInstance.get<Blob>(ACTIONS_ENDPOINTS.DOCUMENTED_ACTIONS_LIST_EXPORT, {
    params: requestParameters,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response.data;
};
