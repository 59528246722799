import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Badge, cn, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { UserLoginHistoryStatus } from '~/services';
import type { TUserLoginHistoryStatus } from '~/services';

const STATUS_LABEL_MAP = {
  [UserLoginHistoryStatus.SUCCESSFUL]: 'Successful',
  [UserLoginHistoryStatus.ERROR]: 'Error',
};

type TStatusColumnProperties = {
  status: string;
  failureReason: string;
};

const StatusColumn = ({ status, failureReason }: TStatusColumnProperties) => {
  if (status) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Badge
            label={STATUS_LABEL_MAP[status as TUserLoginHistoryStatus]}
            className={cn('cursor-default', {
              'bg-success-bg text-success-main': status === UserLoginHistoryStatus.SUCCESSFUL,
              'bg-error-bg text-error-main': status === UserLoginHistoryStatus.ERROR,
            })}
          />
        </TooltipTrigger>
        {Boolean(failureReason) && (
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {snakeToCamelWithSpaces(failureReason)}
            </TooltipContent>
          </TooltipPortal>
        )}
      </Tooltip>
    );
  }
  return (
    <Text variant="size-14" type="body-500" className="text-grey-960">
      -
    </Text>
  );
};

export { StatusColumn };
