import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { REVERSED_US_STATES, US_REGION_READABLE_NAMES, US_STATES, US_STATES_REGIONS_MAP } from '@purple/shared-types';
import { formatDateShortMonth, getGMTOffset, snakeCaseToCapitalized } from '@purple/shared-utils';
import {
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
} from '@purple/ui';
import { useUpdateDistrictBasicDetails } from '~/services';
import { DistrictSectionHeader } from '../../DistrictSectionHeader';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

const SET_DISTRICT_STATES = {
  [REVERSED_US_STATES.Texas]: US_STATES.TX,
  [REVERSED_US_STATES.Illinois]: US_STATES.IL,
  [REVERSED_US_STATES.Florida]: US_STATES.FL,
  [REVERSED_US_STATES.Louisiana]: US_STATES.LA,
  [REVERSED_US_STATES.Ohio]: US_STATES.OH,
};

const STATE_OPTIONS = Object.entries(SET_DISTRICT_STATES).map(([value, label]) => ({
  value,
  label,
}));

const timezoneOptions = Intl.supportedValuesOf('timeZone')
  .map((tz) => {
    const gmtOffset = getGMTOffset(tz);
    return {
      label: `(${gmtOffset}) ${tz}`,
      value: tz,
      offset: gmtOffset,
    };
  })
  .sort((a, b) => a.offset.localeCompare(b.offset, 'en'));

const basicDetailsSchema = z
  .object({
    name: z.string().trim().min(1, 'District name is required.'),
    states: z.array(z.string()).min(1, 'At least one state is required'),
    regions: z.array(z.string()).optional(),
    school_year_start_datetime: z.date().optional(),
    school_year_end_datetime: z.date().optional(),
    timezone: z.string().optional(),
  })
  .superRefine((data, context) => {
    if (
      data.school_year_start_datetime
      && data.school_year_end_datetime
      && data.school_year_end_datetime <= data.school_year_start_datetime
    ) {
      context.addIssue({
        path: ['school_year_start_datetime'],
        code: z.ZodIssueCode.custom,
        message: 'Start of fiscal year must be before end of fiscal year.',
      });
    }

    if (
      data.school_year_start_datetime
      && data.school_year_end_datetime
      && data.school_year_end_datetime <= data.school_year_start_datetime
    ) {
      context.addIssue({
        path: ['school_year_end_datetime'],
        code: z.ZodIssueCode.custom,
        message: 'End of fiscal year must be after start of fiscal year.',
      });
    }
  });

type TDistrictBasicDetailsSectionProperties = {
  district: TDistrictDetails;
};

export const DistrictBasicDetailsSection: React.FC<TDistrictBasicDetailsSectionProperties> = (props) => {
  const {
    district: { id, name, states, regions, school_year_start_datetime, school_year_end_datetime, timezone },
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateDistrict, isPending } = useUpdateDistrictBasicDetails();

  const defaultValues: z.infer<typeof basicDetailsSchema> = useMemo(
    () => ({
      name,
      states,
      regions,
      school_year_start_datetime: school_year_start_datetime ? new Date(school_year_start_datetime) : undefined,
      school_year_end_datetime: school_year_end_datetime ? new Date(school_year_end_datetime) : undefined,
      timezone,
    }),
    [name, school_year_start_datetime, school_year_end_datetime, timezone, states, regions],
  );

  const form = useForm<z.infer<typeof basicDetailsSchema>>({
    resolver: zodResolver(basicDetailsSchema),
    mode: 'onChange',
    defaultValues,
  });
  const startDate = form.watch('school_year_start_datetime');
  const formStates = form.watch('states');

  const regionsOptions = useMemo(
    () =>
      formStates.flatMap((state) => {
        const availableRegions = US_STATES_REGIONS_MAP[state as keyof typeof US_STATES_REGIONS_MAP];

        return availableRegions
          ? Object.entries(availableRegions).map(([_label, value]) => {
              const readableLabel = US_REGION_READABLE_NAMES[value] as string;
              return {
                value,
                label: readableLabel,
              };
            })
          : [];
      }),
    [formStates],
  );

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const stateChangeHandler = (newStates: string[]) => {
    const formRegions = form.getValues('regions') ?? [];
    const filteredRegions = formRegions.filter((region) =>
      newStates.some((state) =>
        Object.values(US_STATES_REGIONS_MAP[state as keyof typeof US_STATES_REGIONS_MAP]).includes(region),
      ),
    );
    form.setValue('regions', filteredRegions);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof basicDetailsSchema>) => {
    updateDistrict(
      {
        id,
        ...(formData.name !== name && { name: formData.name }),
        ...(formData.timezone !== timezone && { timezone: formData.timezone }),
        ...(formData.school_year_start_datetime && {
          school_year_start_datetime: formData.school_year_start_datetime.toISOString(),
        }),
        ...(formData.school_year_end_datetime && {
          school_year_end_datetime: formData.school_year_end_datetime.toISOString(),
        }),
        states: formData.states,
        regions: formData.regions,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <DistrictSectionHeader
        title="Basic Details"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">District name</FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <Input {...field} isError={!!fieldState.error} placeholder="Enter name" className="h-9" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="states"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">State(s)</FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <MultiSelect
                          {...field}
                          isError={!!fieldState.error}
                          selectedOptions={STATE_OPTIONS.filter((tag) => field.value.includes(tag.value))}
                          placeholder="Select state(s)"
                          classNames={{ trigger: 'min-h-9 py-1' }}
                          modalPopover
                          onOptionChange={(value) => {
                            field.onChange(value);
                            stateChangeHandler(value);
                          }}
                        >
                          {STATE_OPTIONS.map((option) => (
                            <MultiSelectItem
                              key={option.value}
                              value={option.value}
                              option={option}
                              isSelected={field.value.includes(option.value)}
                            />
                          ))}
                        </MultiSelect>
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="regions"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Region(s) Served
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <MultiSelect
                          {...field}
                          isError={!!fieldState.error}
                          selectedOptions={regionsOptions.filter((region) => field?.value?.includes(region.value))}
                          placeholder="Select region(s)"
                          classNames={{ trigger: 'min-h-9 py-1' }}
                          modalPopover
                          onOptionChange={field.onChange}
                        >
                          {regionsOptions.map((option) => (
                            <MultiSelectItem
                              key={option.value}
                              value={option.value}
                              option={option}
                              isSelected={field.value?.includes(option.value)}
                            />
                          ))}
                        </MultiSelect>
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="school_year_start_datetime"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Start of fiscal year (school year)
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <DatePicker
                          mode="single"
                          placeholder="Select date & time"
                          formatterString="PPP"
                          isError={!!fieldState.error}
                          triggerDisabled={field.disabled}
                          selected={field.value}
                          defaultMonth={field.value}
                          captionLayout="dropdown"
                          align="end"
                          onDayClick={field.onChange}
                          triggerClassName="max-h-9"
                          contentClassName="min-w-[320px]"
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="school_year_end_datetime"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      End of fiscal year (school year)
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <DatePicker
                          mode="single"
                          placeholder="Select date & time"
                          formatterString="PPP"
                          isError={!!fieldState.error}
                          triggerDisabled={field.disabled}
                          selected={field.value}
                          defaultMonth={field.value}
                          captionLayout="dropdown"
                          align="end"
                          disabled={startDate ? { before: startDate } : false}
                          onDayClick={field.onChange}
                          triggerClassName="max-h-9"
                          contentClassName="min-w-[320px]"
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="timezone"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">Time Zone</FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <ComboBox modal>
                        <FormControl>
                          <ComboBoxTrigger
                            isError={!!fieldState.error}
                            placeholder="Select timezone"
                            selectedLabel={timezoneOptions.find((option) => option.value === field.value)?.label}
                            className="max-h-8"
                          />
                        </FormControl>
                        <ComboBoxContent
                          className="min-w-80"
                          align="end"
                          searchPlaceholder="Search timezone..."
                          emptyContent="Timezone not found."
                        >
                          {timezoneOptions.map(({ label, value, offset }) => (
                            <ComboBoxItem
                              key={value}
                              keywords={[label, offset]}
                              value={value}
                              selected={value === field.value}
                              onSelect={field.onChange}
                            >
                              {label}
                            </ComboBoxItem>
                          ))}
                        </ComboBoxContent>
                      </ComboBox>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <DescriptionList className="gap-2">
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">District name</DescriptionTerm>
                <DescriptionDetails className="w-1/2">{name}</DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">State(s)</DescriptionTerm>
                <DescriptionDetails className="w-1/2">{states.join(', ') || '-'}</DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Region(s) Served</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {regions.map((item) => snakeCaseToCapitalized(item)).join(', ') || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Fiscal year (school year)</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {school_year_start_datetime ? formatDateShortMonth(school_year_start_datetime) : '-'}
                  {' '}
                  -
                  {' '}
                  {school_year_end_datetime ? formatDateShortMonth(school_year_end_datetime) : '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Time Zone</DescriptionTerm>
                <DescriptionDetails className="w-1/2">{timezone ?? '-'}</DescriptionDetails>
              </DescriptionItem>
            </DescriptionList>
          )}
    </div>
  );
};
