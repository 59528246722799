import { useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { ReadableSafSubmitterType } from '@purple/shared-types';
import { Badge, Button, cn, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Text } from '@purple/ui';
import { CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { type TAdminSubmitterTypeDetail, useUpdateSubmitterDetails } from '~/services';
import { showSuccessToast } from '~/utils/toasts';

type TDetailHeaderProperties = {
  data?: TAdminSubmitterTypeDetail;
};

const DetailHeader = ({ data }: TDetailHeaderProperties) => {
  const { type, is_active, id } = data || {};

  const { mutate, isPending } = useUpdateSubmitterDetails();

  const { openModal: openDeactivateModal, closeModal: closeDeactivateModal } = useModal(
    ModalType.DEACTIVATE_SUBMITTER_TYPE,
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const typeLabel = type ? ReadableSafSubmitterType[type] : '-';

  const deactivateClick = () => {
    openDeactivateModal();
  };

  const deactivateConfirmHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    if (id) {
      mutate({
        id,
        payload: {
          is_active: false,
        },
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'Submitter Detail was deactivated successfully');
          setIsDropdownOpen(false);
          closeDeactivateModal();
        },
      });
    }
  };

  const activateConfirmHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    if (id) {
      mutate({
        id,
        payload: {
          is_active: true,
        },
      }, {
        onSuccess: () => {
          const typeLabel = type ? ReadableSafSubmitterType[type] : '-';
          const message = `Submitter type ${typeLabel} was successfully activated`;
          showSuccessToast('System message', message);
          setIsDropdownOpen(false);
          // closeActivateModal();
        },
      });
    }
  };

  return (
    <div className="flex w-full flex-row items-start justify-between rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex flex-col gap-2">
        <Text variant="size-16" type="body-600" className="text-grey-950">{typeLabel}</Text>
        <div className="flex flex-col gap-1">
          <Text variant="size-14" type="body-400" className="text-grey-600">Status</Text>
          <div>
            <Badge
              label={is_active ? 'Active' : 'Inactive'}
              className={cn({
                'text-success-main bg-success-bg': is_active,
                'text-grey-900 bg-grey-100': !is_active,
              })}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
          <DropdownTrigger asChild>
            <Button
              iconLeft={<PurpleIcon name="dots-vertical" />}
              variant="secondary"
              size="icon_40"
            />
          </DropdownTrigger>
          <DropdownContent className="max-w-[260px] gap-1" align="end">
            <DropdownItem
              iconName={is_active ? 'X' : 'check'}
              className={cn(
                'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
                {
                  'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main': is_active,
                },
              )}
              onSelect={() => is_active ? deactivateClick() : activateConfirmHandler()}
              onClick={(event) => event.stopPropagation()}
              isLoading={isPending}
            >
              {is_active ? 'Deactivate' : 'Activate'}
            </DropdownItem>
          </DropdownContent>
        </DropdownRoot>
      </div>
      <CallToActionModal
        modalName={ModalType.DEACTIVATE_SUBMITTER_TYPE}
        modalTitle="Deactivate Type"
        modalDescription="Are you sure you want to deactivate this user type? Inactive type will not be available to users in the SAF form"
        modalTextContent="Are you sure you want to deactivate this user type? Inactive type will not be available to users in the SAF form"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={deactivateConfirmHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isPending}
      />
    </div>
  );
};

export { DetailHeader };
