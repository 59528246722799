import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  NoDataAvailable,
  ScrollArea,
  Separator,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { TAGS_QUERY_KEY, useDeleteTag, useUpdateCustomTag } from '~/services';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { CallToActionModal } from '../CallToActionModal';
import { updateCustomTagsSchema } from './schema';
import { TagFormItem } from './TagFormItem';
import type * as z from 'zod';
import type { TCustomTag, TTagType } from '@purple/shared-types';

type TFromValues = z.infer<typeof updateCustomTagsSchema>;

type TUpdateTagModalProperties = {
  /**
   * A list of tags that will be updated.
   */
  tags: TCustomTag[];
  /**
   * The type of tag to which the tags will be created.
   */
  tagsType: TTagType;
  /**
   * The id of the related item. If the tagsType is `recordactiontype`, this is the id of the action type.
   */
  relatedEntityId: string;
  /**
   * The id of the related district. The district the related id belongs to.
   */
  relatedDistrictId: number;
  /**
   * List of all available tag names for specific contenttype and district. Used to validate the tag name.
   */
  allTags?: TCustomTag[];
  /**
   * A callback function that is called when the tag is successfully created.
   */
  onSuccess?: () => void;
};

export const UpdateTagModal: React.FC<TUpdateTagModalProperties> = (props) => {
  const { tags, tagsType, relatedDistrictId, relatedEntityId, allTags, onSuccess } = props;

  const queryClient = useQueryClient();

  const { isOpen, toggleModal } = useModal(ModalType.UPDATE_CUSTOM_TAG);
  const { toggleModal: toggleDeleteModal } = useModal(ModalType.DELETE_CUSTOM_TAG);

  const [selectedTagId, setSelectedTagId] = useState<string | null>(null);

  const { mutateAsync: updateCustomTag, isPending } = useUpdateCustomTag();
  const { mutate: deleteCustomTag, isPending: isDeletePending } = useDeleteTag();

  const defaultValues: Partial<TFromValues> = useMemo(() => ({
    tags: tags.map((tag) => ({
      id: tag.id,
      name: tag.name,
      color: tag.color,
    })),
    __all_tags: allTags ?? [],
  }), [tags, allTags]);

  const form = useForm<TFromValues>({
    mode: 'onChange',
    resolver: zodResolver(updateCustomTagsSchema),
    defaultValues,
  });

  const { fields: formTags, replace } = useFieldArray({
    name: 'tags',
    keyName: 'key',
    control: form.control,
  });
  const indexes = useMemo(() => new Map(formTags.map((field, index) => [field.key, index])), [formTags]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const revalidateTags = useCallback(() => {
    const hasTagsChanged = tags.some((tag) => {
      const formTag = form.getValues().tags.find((t) => t.id === tag.id);
      return formTag && (tag.name !== formTag.name || tag.color !== formTag.color);
    });
    if (hasTagsChanged || tags.length !== form.getValues().tags.length) {
      queryClient.invalidateQueries({ queryKey: [TAGS_QUERY_KEY.GET_CUSTOM_TAGS, { object_id: relatedEntityId, content_type: tagsType, district: relatedDistrictId }], exact: true });
      queryClient.invalidateQueries({ queryKey: [TAGS_QUERY_KEY.GET_CUSTOM_TAGS, { content_type: tagsType, district: relatedDistrictId }], exact: true });
    }
  }, [queryClient, relatedEntityId, tagsType, tags, form, relatedDistrictId]);

  const cancelDeleteTagHandler = useCallback(() => {
    toggleDeleteModal(false);
    setSelectedTagId(null);
  }, [toggleDeleteModal]);

  const closeModalHandler = useCallback(() => {
    revalidateTags();
    toggleModal(false);
    form.reset();
  }, [form, toggleModal, revalidateTags]);

  const updateCustomTagsHandler = (values: TFromValues) => {
    const changedTags = tags.filter((tag) => {
      const formTag = values.tags.find((t) => t.id === tag.id);
      return formTag && (tag.name !== formTag.name || tag.color !== formTag.color);
    });
    const updates = changedTags.map((tag) => updateCustomTag({
      id: tag.id,
      name: values.tags.find((t) => t.id === tag.id)?.name,
      color: values.tags.find((t) => t.id === tag.id)?.color,
    }));

    Promise.all(updates).then(() => {
      onSuccess?.();
      showSuccessToast('System message', 'Custom tag(s) updated successfully');
      closeModalHandler();
    });
  };

  const deleteTagClickHandler = useCallback((tagId: string) => {
    setSelectedTagId(tagId);
    toggleDeleteModal(true);
  }, [toggleDeleteModal]);

  const deleteTagSubmitHandler = () => {
    if (!selectedTagId) {
      return showErrorToast('System message', 'Unable to delete tag with the provided ID');
    }
    deleteCustomTag({
      id: selectedTagId,
      content_type: tagsType,
      object_id: relatedEntityId,
      district_id: relatedDistrictId,
    }, {
      onSuccess: () => {
        replace(formTags.filter((tag) => tag.id !== selectedTagId));
        setSelectedTagId(null);
        toggleDeleteModal(false);
        if (form.getValues().tags.length === 0) {
          closeModalHandler();
        }
      },
    });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={closeModalHandler}>
        <DialogContent className="max-w-[564px]">
          <DialogHeader className="flex flex-row items-center justify-between">
            <div className="flex flex-col gap-1">
              <DialogTitle>Edit Tags</DialogTitle>
              <DialogDescription className="sr-only">
                Use this form to update the tags for the selected item.
              </DialogDescription>
            </div>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
            <div className="w-full p-6 pr-[30px]">
              <Form providerProps={form} className="flex w-full flex-col gap-1">
                <div className="grid w-full grid-cols-6 items-center gap-4">
                  <Text tag="strong" type="body-600" variant="size-12" className="text-grey-600 col-span-3 inline-flex min-h-12 items-center justify-start px-3 uppercase">
                    Tag Name
                  </Text>
                  <Text tag="strong" type="body-600" variant="size-12" className="text-grey-600 col-span-1 inline-flex min-h-12 items-center justify-center px-3 text-center uppercase">
                    Color
                  </Text>
                  <span className="col-span-2" />
                </div>
                {formTags.map((tag) => (
                  <TagFormItem key={tag.key} fieldIndex={indexes.get(tag.key)!} onDelete={deleteTagClickHandler} />
                ))}
                {formTags.length === 0 && (
                  <NoDataAvailable
                    iconName="folder-open"
                    iconSize={24}
                    title="No Tags"
                    description="There are no tags to display."
                  />
                )}
              </Form>
            </div>
          </ScrollArea>
          <Separator />
          <DialogFooter>
            <Button variant="tertiary" onClick={closeModalHandler}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" onClick={form.handleSubmit(updateCustomTagsHandler)} isLoading={isPending}>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <CallToActionModal
        modalName={ModalType.DELETE_CUSTOM_TAG}
        modalTitle="Delete Tag"
        modalDescription="By deleting this tag, it will be removed from this content type related items."
        modalTextContent="Are you sure you want to delete this tag? This action will remove the tag from all related items."
        onPrimaryButtonClick={deleteTagSubmitHandler}
        primaryButtonText="Delete"
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={cancelDeleteTagHandler}
      />
    </>
  );
};
