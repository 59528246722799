import { useCallback, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useDebounceValue } from 'usehooks-ts';
import * as z from 'zod';
import { PurpleIcon } from '@purple/icons';
import { multiSelectOptions } from '@purple/shared-utils';
import { Button, Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, MultiSelect, MultiSelectItem, Separator, Text } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { STUDENTS_QUERY_KEYS, useAddContactStudents, useStudents } from '~/services';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import type { FC } from 'react';

const DEFAULT_STUDENTS_LIMIT = 100;
const DEFAULT_SEARCH_DELAY = 400;

const addContactStudentsSchema = z.object({
  students: multiSelectOptions.min(1, { message: 'Please select at least one student' }),
});

type TFormValues = z.infer<typeof addContactStudentsSchema>;

type TAddContactStudentsModalProps = {
  distractId: string;
  contactId: string;
};

export const AddContactStudentsModal: FC<TAddContactStudentsModalProps> = ({ distractId, contactId }) => {
  const formId = useId();
  const queryClient = useQueryClient();

  const { isOpen, closeModal } = useModal(ModalType.ADD_CONTACT_STUDENTS);

  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounceValue(searchValue, DEFAULT_SEARCH_DELAY);

  const { data: students, isLoading: isStudentsLoading } = useStudents({
    parameters: {
      search: debouncedSearchValue,
      limit: DEFAULT_STUDENTS_LIMIT,
      district: distractId!,
      offset: 0,
      exclude_connected: contactId,
    },
    queryOptions: {
      enabled: isOpen && distractId !== null && distractId.length > 0 && contactId.length > 0,
    },
  });

  const { mutate: addContactStudents, isPending } = useAddContactStudents();

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(addContactStudentsSchema),
    defaultValues: {
      students: [],
    },
  });

  const studentsOptions = useMemo(
    () =>
      students?.results.map((student) => ({
        ...student,
        label: student.full_name ?? 'Unidentified student',
        value: student.id,
      })) ?? [],
    [students?.results],
  );
  const closeModalHandler = useCallback(() => {
    form.reset();
    closeModal();
  }, [form, closeModal]);

  const addStudentsHandler = useCallback((formValues: TFormValues) => {
    if (contactId.length === 0) {
      showErrorToast('System error', `Can not add students to contact with id: ${contactId}`);
      return;
    }
    addContactStudents({ contact_id: contactId, students: formValues.students.map(({ value }) => value) }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENTS_LIST, { guardian: contactId }] });
        showSuccessToast('System message', 'Students have been added to the contact');
        closeModalHandler();
      },
    });
  }, [addContactStudents, queryClient, contactId, closeModalHandler]);

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="max-w-[564px]">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className="leading-5">Add Students</DialogTitle>
            <DialogDescription className="sr-only">Modal window for adding additional students to the contact</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form providerProps={form} className="p-6" id={formId} onSubmit={form.handleSubmit(addStudentsHandler)}>
          <FormField
            control={form.control}
            name="students"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Students</FormLabel>
                <FormControl>
                  <MultiSelect
                    onChange={field.onChange}
                    placeholder="Select students"
                    loading={isStudentsLoading}
                    isError={!!fieldState.error}
                    modalPopover
                    disabled={distractId === null || distractId.length === 0}
                    shouldFilter={false}
                    showSearch
                    searchValue={searchValue}
                    searchPlaceholder="Search..."
                    onSearchChange={setSearchValue}
                    onOptionChange={(_, selected) => field.onChange(selected)}
                    selectedOptions={field.value}
                  >
                    {studentsOptions.map((student) => (
                      <MultiSelectItem key={student.id} value={student.value} option={student} customContent>
                        <div className="flex flex-col">
                          <Text variant="size-14" type="body-600" className="text-grey-title">
                            {student.full_name ?? 'Unidentified student'}
                          </Text>
                          <Text variant="size-12" type="body-400" className="text-grey-600">
                            {`Student ID: ${student.student_id}`}
                          </Text>
                        </div>
                      </MultiSelectItem>
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="secondary" onClick={closeModalHandler}>Cancel</Button>
          <Button type="submit" form={formId} variant="primary" isLoading={isPending}>Submit</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
