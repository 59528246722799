import { useMutation } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { impersonateUser } from './impersonate-user';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TImpersonateUserPayload, TImpersonateUserResponse } from './user.types';

export const useImpersonateUser = (
  options?: TCustomUseMutationOptions<TImpersonateUserResponse, AxiosError, TImpersonateUserPayload>,
) => {
  return useMutation({
    ...options,
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.IMPERSONATE_USER],
    mutationFn: impersonateUser,
    onSuccess: (...args) => {
      showSuccessToast('System message', 'Logged in as user successfully, redirecting...');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to log in as user');
      options?.onError?.(...args);
    },
  });
};
