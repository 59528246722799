import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, AppSelectedFiltersList, Button, Heading, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions } from '~/components';
import { AdminRoutes, ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useDeleteRole, useDistrictsListBasicInfo, useListRoles } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { useRolesColumns } from './useRolesColumns';
import { useRolesFiltersOptions } from './useRolesFiltersOptions';

const DISTRICT_DEFAULT_OFFSET = 0;
const DISTRICT_DEFAULT_LIMIT = 9999;

export const RolesPage = () => {
  const [searchParameters] = useSearchParams();

  const { closeModal } = useModal(ModalType.DELETE_ROLE);

  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { hasPermissions } = usePermissions();

  const { data: roleList, isLoading: isRolesLoading } = useListRoles({
    requestParameters: {
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      district: searchParameters.get('district'),
      is_custom: searchParameters.get('is_custom'),
    },
  });
  const { data: districts, isLoading: isDistrictsLoading } = useDistrictsListBasicInfo({
    offset: DISTRICT_DEFAULT_OFFSET,
    limit: DISTRICT_DEFAULT_LIMIT,
  });
  const { mutate: deleteRole, isPending: isRoleDeleting } = useDeleteRole();

  const { filterConfig } = useRolesFiltersOptions({
    districtList: districts?.results,
  });

  const rolesColumns = useRolesColumns({ onSelectRole: setSelectedRoleId });

  const { table } = useDataTable({
    name: TableName.USER_PERMISSION_ROLES,
    columns: rolesColumns,
    data: roleList?.results || [],
    rowCount: roleList?.count || 0,
    initialState: {
      columnPinning: {
        left: ['name'],
        right: ['actions'],
      },
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  const closeDeleteModalHandler = () => {
    setSelectedRoleId(null);
    closeModal();
  };

  const deleteRoleHandler = () => {
    if (!selectedRoleId) {
      return showErrorToast('System message', 'Role with provided ID does not exist. Please select another role.');
    }
    deleteRole(selectedRoleId, {
      onSuccess: () => {
        closeDeleteModalHandler();
      },
    });
  };

  return (
    <>
      <div className="flex w-full flex-col gap-6">
        <div className="flex w-full items-center justify-between gap-4">
          <Heading tag="h1" variant="size-22" type="heading-600" className="text-grey-title">
            Roles
          </Heading>
          {hasPermissions(APP_PERMISSIONS.CAN_ACCESS_ADMIN_MANAGE_PERMISSIONS) && (
            <Button type="button" asChild>
              <Link to={AdminRoutes.App.Users.Roles.ManageRoles.path} target="_blank">Create New Role</Link>
            </Button>
          )}
        </div>
        <div className="border-grey-200 shadow-custom-heavy w-full gap-4 rounded-lg border bg-white">
          <DataTable table={table} loading={isRolesLoading}>
            <div className="flex w-full items-center justify-between gap-4 p-4">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-4">
                  <AppFilters config={filterConfig} loading={isDistrictsLoading} />
                  <SearchInput
                    value={search}
                    placeholder="Search by name "
                    className="max-w-[300px]"
                    onChange={onSearchChange}
                    onClear={onClearSearch}
                  />
                </div>
                <AppSelectedFiltersList config={filterConfig} />
              </div>
              <DataTableViewOptions table={table} />
            </div>
          </DataTable>
        </div>
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_ROLE}
        modalTitle="Delete Custom Role"
        modalDescription="Delete custom role modal"
        showModalDescription={false}
        modalTextContent="Are you sure you want to delete this role? This action can not be undone."
        onPrimaryButtonClick={deleteRoleHandler}
        primaryButtonText="Yes, delete"
        primaryButtonVariant="destructive_primary"
        isLoading={isRoleDeleting}
        onSecondaryButtonClick={closeDeleteModalHandler}
      />
    </>
  );
};
