import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getNotLoggedInUsersReport } from './get-not-logged-in-users-report';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TAdminUsersNotLoggedInReportParameters, TAdminUsersNotLoggedInReportResponse } from './user.types';

export const useNotLoggedInUsersReport = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TAdminUsersNotLoggedInReportParameters;
  enabled?: boolean;
}) => {
  const { isError, error, ...rest } = useQuery<TAdminUsersNotLoggedInReportResponse, AxiosError>({
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_NOT_LOGGED_IN_USERS_REPORT, requestParameters],
    queryFn: () => getNotLoggedInUsersReport(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch list of not logged in users');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
