import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { BannerStatus, DISTRICT_STATUS, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Button, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableSelectedController, DataTableViewOptions, DistrictFilterComboBox } from '~/components';
import { ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useBannersBulkDelete, useDraftedBanners, usePublishBanner } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { BannerStaticFilters } from './helpers';
import { ManageBannerDialog } from './ManageBannerDialog';
import { useDraftedBannerColumns } from './useDraftedBannerColumns';
import type { TDraftedBanner } from '~/services';

export const DraftedBanners: React.FC = () => {
  const [searchParameters] = useSearchParams();

  const [selectedBanner, setSelectedBanner] = useState<TDraftedBanner | null>(null);

  const { query: selectedDistrict, onQueryChange, onClearQuery } = useQueryParameter({ queryName: 'district' });

  const { openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal(ModalType.DELETE_BANNER);
  const { openModal: openBulkDeleteModal, closeModal: closeBulkDeleteModal } = useModal(ModalType.BULK_DELETE_BANNERS);
  const { openModal: openEditModal } = useModal(ModalType.MANAGE_BANNER);
  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data, isFetching } = useDraftedBanners({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    district: selectedDistrict,
    type: searchParameters.get('type'),
  });
  const { mutate: deleteBanners, isPending: isDeletePending } = useBannersBulkDelete();
  const { mutate: publishBanner, isPending: isPublishPending, variables } = usePublishBanner();

  const draftedBanners = useMemo(() => data?.results ?? [], [data?.results]);

  const publishBannerClickHandler = useCallback((banner: TDraftedBanner) => {
    publishBanner({ id: banner.id, status: BannerStatus.PUBLISHED });
  }, [publishBanner]);

  const editBannerClickHandler = useCallback((banner: TDraftedBanner) => {
    setSelectedBanner(banner);
    openEditModal();
  }, [openEditModal]);

  const deleteBannerClickHandler = useCallback((banner: TDraftedBanner) => {
    setSelectedBanner(banner);
    openDeleteModal();
  }, [openDeleteModal]);

  const columns = useDraftedBannerColumns({
    onPublish: publishBannerClickHandler,
    onEdit: editBannerClickHandler,
    onDelete: deleteBannerClickHandler,
  });

  const { table } = useDataTable<TDraftedBanner>({
    name: TableName.BANNERS_DRAFTED,
    columns,
    data: draftedBanners,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
        right: ['actions'],
      },
      columnVisibility: {
        district: false,
        roles: false,
        groups: false,
        is_view_more: false,
      },
    },
    getRowId: (originalRow) => originalRow.id,
    meta: {
      publishingId: isPublishPending && variables ? variables.id : null,
    },
    onSelectionChange: (rows, setSelectedRows) => {
      const selectedIds = Object.entries(rows).flatMap(([id, selected]) => (selected ? [id] : []));
      setSelectedRows(
        (prevRows) => [
          ...prevRows,
          ...draftedBanners.map(({ id, title }) => ({ id, name: title })),
        ]
          .filter((activity, index, self) => index === self.findIndex((a) => a.id === activity.id))
          .filter((activity) => selectedIds.includes(activity.id)),
      );
    },
  });

  const selectedIds = Object.entries(table.getState().rowSelection).flatMap(([id, isSelected]) => (isSelected ? [id] : []));

  const districtChangeHandler = useCallback(
    (districtId: string | null) => {
      if (districtId === null) {
        onClearQuery();
      } else {
        onQueryChange(districtId);
      }
    },
    [onQueryChange, onClearQuery],
  );

  const deleteBannersConfirmHandler = useCallback(() => {
    if (!selectedBanner) {
      return showErrorToast('Banner not found', 'Please select a banner to delete');
    }
    deleteBanners({ ids: [selectedBanner.id] }, {
      onSuccess: () => {
        closeDeleteModal();
      },
    });
  }, [selectedBanner, deleteBanners, closeDeleteModal]);

  const deleteSelectedBannersClickHandler = useCallback(() => {
    openBulkDeleteModal();
  }, [openBulkDeleteModal]);

  const deleteSelectedBannersConfirmHandler = useCallback(() => {
    if (!table || selectedIds.length === 0) {
      return showErrorToast('Banners not found', 'Please select banners to delete');
    }
    deleteBanners({ ids: selectedIds }, {
      onSuccess: () => {
        closeBulkDeleteModal();
      },
    });
  }, [table, deleteBanners, closeBulkDeleteModal, selectedIds]);

  const closeModalHandler = useCallback(() => {
    setSelectedBanner(null);
  }, []);

  return (
    <DataTable table={table} loading={isFetching}>
      <div className="flex w-full flex-col gap-4 px-4 py-6">
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <DistrictFilterComboBox
              value={selectedDistrict}
              status={DISTRICT_STATUS.PUBLISHED}
              align="start"
              className="w-fit min-w-40 max-w-72 gap-8"
              contentClassName="min-w-80"
              onChange={districtChangeHandler}
              onClear={onClearQuery}
            />
            <AppFilters config={convertToFilterConfig(BannerStaticFilters, { snakeCaseValue: false })} />
            <SearchInput
              value={search}
              placeholder="Search"
              className="max-w-[300px]"
              onChange={onSearchChange}
              onClear={onClearSearch}
            />
          </div>
          <div className="flex items-center gap-4">
            {selectedIds.length > 0 && (
              <Button type="button" variant="destructive_secondary" onClick={deleteSelectedBannersClickHandler}>
                Delete banner(s)
              </Button>
            )}
            <DataTableViewOptions table={table} />
          </div>
        </div>
        <DataTableSelectedController table={table} displayMode="selected" />
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_BANNER}
        modalTitle="Delete Banner"
        modalDescription="By deleting this banner, it will be removed from the system and can't be recovered."
        modalTextContent="Are you sure you want to delete this banner?"
        primaryButtonText={isDeletePending ? 'Deleting' : 'Delete'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteBannersConfirmHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
        onClose={closeModalHandler}
      />
      <CallToActionModal
        modalName={ModalType.BULK_DELETE_BANNERS}
        modalTitle="Delete all selected banner(s)"
        modalDescription="By deleting selected banner(s), it will be removed from the system and can't be recovered."
        modalTextContent="Are you sure you want to delete all selected banner(s)?"
        primaryButtonText={isDeletePending ? 'Deleting' : 'Delete'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteSelectedBannersConfirmHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
      />
      <ManageBannerDialog banner={selectedBanner} onClose={closeModalHandler} />
    </DataTable>
  );
};
