import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getActionsCount } from './get-actions-count';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActionDocumentAsCountStats, TActionDocumentAsCountStatsParameters } from './actions.types';

// 15 minutes
const DEFAULT_STALE_TIME = 1000 * 60 * 15;

export const useActionsDocumentStats = (
  parameters?: TActionDocumentAsCountStatsParameters,
  queryOptions?: TCustomUseQueryOptions<TActionDocumentAsCountStats, AxiosError>,
) => {
  const { isError, error, ...rest } = useQuery({
    staleTime: DEFAULT_STALE_TIME,
    ...queryOptions,
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_STATISTICS, parameters],
    queryFn: () => getActionsCount(parameters),
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to retrieve actions statistics');
    }
  }, [isError, error?.response]);

  return { isError, error, ...rest };
};
