import type { ValueOf } from '@purple/shared-types';

export const ActivityView = {
  LIST: 'list',
  FORM: 'form',
} as const;
export type TActivityView = ValueOf<typeof ActivityView>;

export const ActivityDetailsView = {
  DETAILS: 'details',
  FILES: 'files',
  HISTORY: 'history',
} as const;
export type TActivityDetailsView = ValueOf<typeof ActivityDetailsView>;

export const ActivityTypeDetailsView = {
  DETAILS: 'details',
  TAGS: 'tags',
  ROLES: 'roles',
  GROUPS: 'groups',
} as const;
export type TActivityTypeDetailsView = ValueOf<typeof ActivityTypeDetailsView>;
