import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PERMISSIONS_QUERY_KEYS } from '@purple/shared-utils';
import { useAppSelector } from '~/hooks';
import { userSelector } from '~/store/features/user';
import { isClientError } from '~/utils/api-requests';
import { showSuccessToast } from '~/utils/toasts';
import { updateUserDetailsPermissions } from './update-user-details-permissions';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TUpdateUserDetailsPermissionsResponse } from './user.types';

export const useUpdateUserDetailsPermissions = (queryOptions?: TCustomUseMutationOptions<TUpdateUserDetailsPermissionsResponse, AxiosError>) => {
  const queryClient = useQueryClient();
  const { id } = useAppSelector(userSelector);

  return useMutation({
    ...queryOptions,
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.UPDATE_USER_DETAILS_PERMISSIONS],
    mutationFn: updateUserDetailsPermissions,
    onSuccess: (_, { userId }) => {
      if (userId === id) {
        queryClient.invalidateQueries({ queryKey: [PERMISSIONS_QUERY_KEYS.GET_USER_PERMISSIONS] });
      }
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_USER_DETAILS_PERMISSIONS] });
      showSuccessToast('System message', 'User permission updated successfully');
    },
    onError: (error) => {
      if (isClientError(error)) {
        showSuccessToast('System message', 'Unable to update user permission');
      }
    },
  });
};
