import { Skeleton } from '@purple/ui';

export const HeaderSkeleton = () => {
  return (
    <section className="flex w-full items-start justify-between gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex items-start gap-3">
        <Skeleton className="size-[60px] rounded-full" />
        <div className="flex flex-col gap-4">
          <Skeleton className="h-6 w-52" />
          <div className="flex items-center gap-6">
            <div className="flex flex-col gap-1">
              <Skeleton className="h-4 w-20" />
              <Skeleton className="h-4 w-32 rounded-lg" />
            </div>
            <div className="flex flex-col gap-1">
              <Skeleton className="h-4 w-20" />
              <Skeleton className="h-4 w-32 rounded-lg" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Skeleton className="size-10" />
        <Skeleton className="size-10" />
      </div>
    </section>
  );
};
