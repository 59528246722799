import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getFrequencyDistributionReportExport } from './get-frequency-distribution-report-export';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TAdminUsersFrequencyDistributionReportParameters } from './user.types';

export const useFrequencyDistributionReportExport = ({ fileName }: { fileName: string }) => {
  // NOTE: we use useMutation hook to export safs by clicking on the button (or another trigger)
  return useMutation<Blob, AxiosError, TAdminUsersFrequencyDistributionReportParameters>({
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_FREQUENCY_DISTRIBUTION_REPORT_EXPORT],
    mutationFn: getFrequencyDistributionReportExport,

    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not export users report. Check the provided information and try again');
      }
    },
    onSuccess: (data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      link.href = url;
      link.download = `${fileName}.xlsx`;
      document.body.append(link);
      link.click();
      link.remove();

      // Clean up the URL object after the download is complete to avoid memory leaks
      window.URL.revokeObjectURL(url);
    },
  });
};
