import { type FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { ReadableContactType } from '@purple/shared-types';
import { formatDateToLocalTime } from '@purple/shared-utils';
import { Button, DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm, NumberBadge, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { CallToActionModal } from '~/components';
import { AdminRoutes, ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { CONTACTS_QUERY_KEYS, useDeleteContact } from '~/services';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import type { TContactDetailItem } from '~/services';

export const ContactDetailsHeader: FC = () => {
  const { contactId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { openModal, closeModal } = useModal(ModalType.DELETE_CONTACT);

  const contactDetails = queryClient.getQueryData<TContactDetailItem>([CONTACTS_QUERY_KEYS.GET_CONTACT_DETAILS, contactId]);

  const { mutate, isPending } = useDeleteContact();

  const deleteContactHandler = useCallback(() => {
    if (!contactId) {
      return showErrorToast('System message', `Unable to delete contact with id: ${contactId}`);
    }
    mutate(contactId, {
      onSuccess: () => {
        showSuccessToast('System message', 'Contact has been deleted successfully');
        queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_LIST] });
        closeModal();
        navigate(AdminRoutes.App.Contacts.Root.makePath({ queries: { tab: 'contacts' } }));
      },
    });
  }, [contactId, closeModal, mutate, navigate, queryClient]);

  return (
    <section className="flex w-full items-start justify-between gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex items-start gap-3">
        <div className="flex size-[60px] shrink-0 items-center justify-center rounded-full bg-grey-100">
          <PurpleIcon name="user" className="size-6 text-brand-blue-700" />
        </div>
        <div className="flex w-full flex-col gap-4">
          {/* Contact Name */}
          <Text variant="size-16" type="body-600" className="text-grey-title">{`${contactDetails?.first_name} ${contactDetails?.last_name}`}</Text>
          <DescriptionList className="flex flex-row items-start gap-6">
            <DescriptionItem className="flex-col items-start gap-1">
              <DescriptionTerm className="whitespace-nowrap text-sm">Type</DescriptionTerm>
              <DescriptionDetails className="m-0">
                <NumberBadge variant="warning" className="w-max rounded-full">
                  {contactDetails && contactDetails.contact_type ? ReadableContactType[contactDetails.contact_type] : 'Unknown'}
                </NumberBadge>
              </DescriptionDetails>
            </DescriptionItem>
            <DescriptionItem className="flex-col items-start gap-1">
              <DescriptionTerm className="whitespace-nowrap text-sm">Date Added</DescriptionTerm>
              <DescriptionDetails className="m-0">
                <Text variant="size-14" type="body-500" className="text-nowrap">{formatDateToLocalTime(contactDetails?.created_at)}</Text>
              </DescriptionDetails>
            </DescriptionItem>
          </DescriptionList>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="tertiary" className="h-auto p-2.5 hover:bg-brand-blue-100"><PurpleIcon name="mail" className="size-5" /></Button>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="center">
              Send Email
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="tertiary" className="h-auto p-2.5 hover:bg-error-bg active:bg-error-bg" onClick={openModal}>
              <PurpleIcon name="trash" className="size-5 text-error-main" />
            </Button>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="end">
              Delete Contact
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_CONTACT}
        modalTitle="Delete Contact"
        modalDescription="Modal window for deleting a contact"
        showModalDescription={false}
        modalTextContent="Are you sure you want to delete this contact? This action cannot be undone."
        primaryButtonText="Yes, Delete"
        primaryButtonVariant="destructive_primary"
        isLoading={isPending}
        onPrimaryButtonClick={deleteContactHandler}
      />
    </section>
  );
};
