import * as z from 'zod';
import { multiSelectOptions } from '@purple/shared-utils';

export const manageRolesSchema = z.object({
  name: z.string().trim().min(1, { message: 'Role Name is required' }),
  districts: multiSelectOptions.min(1, { message: 'At least one district is required' }),
  description: z.string().trim().min(1, { message: 'Description is required' }),
  permissions: z.array(z.string()).min(1, { message: 'Please select at least one permission' }),
});

export type TFormValues = z.infer<typeof manageRolesSchema>;
