import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryParameter } from '@purple/hooks';
import { DISTRICT_STATUS, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, ReadableSafSubmitterType } from '@purple/shared-types';
import { NoDataAvailable } from '@purple/ui';
import { CallToActionModal, DataTable, DistrictFilterComboBox } from '~/components';
import { AdminRoutes, ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useSubmitterTypesList, useUpdateSubmitterDetails } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { DISTRICT_QUERY_NAME } from './constants';
import { useSubmitterTypesColumns } from './useSubmitterTypesColumns';
import type { TAdminSubmitterType } from '~/services';

export const SubmitterTypes = () => {
  const navigate = useNavigate();

  const [searchParameters] = useSearchParams();

  const { mutate, isPending } = useUpdateSubmitterDetails();

  const { openModal: openDeactivateModal, closeModal: closeDeactivateModal } = useModal(
    ModalType.DEACTIVATE_SUBMITTER_TYPE,
  );

  const [clickableSaf, setClickableSaf] = useState<TAdminSubmitterType | null>(null);

  const { query: districtQuery, onQueryChange: onDistrictQueryChange, onClearQuery: onDistrictQueryClear } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const canShowTable = useMemo(() => Boolean(districtQuery), [districtQuery]);

  const { data: typesData, isLoading: isTypesLoading } = useSubmitterTypesList({
    requestParameters: {
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      district: districtQuery || '',
    },
    enabled: Boolean(districtQuery),
  });

  const types = useMemo(() => typesData?.results ?? [], [typesData?.results]);

  const deactivateClickHandler = (item: TAdminSubmitterType) => {
    setClickableSaf(item);
    openDeactivateModal();
  };

  const deactivateConfirmHandler = () => {
    if (clickableSaf) {
      mutate({
        id: clickableSaf.id,
        payload: {
          is_active: false,
        },
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'Submitter Detail was deactivated successfully');
          closeDeactivateModal();
          setClickableSaf(null);
        },
      });
    }
  };

  const activateConfirmHandler = (item: TAdminSubmitterType) => {
    if (item) {
      mutate({
        id: item.id,
        payload: {
          is_active: true,
        },
      }, {
        onSuccess: () => {
          const type = item.type;
          const typeLabel = type ? ReadableSafSubmitterType[type] : '-';
          const message = `Submitter type ${typeLabel} was successfully activated`;
          showSuccessToast('System message', message);
        },
      });
    }
  };

  const { table } = useDataTable({
    name: TableName.SAF_SUBMITTER_TYPES,
    columns: useSubmitterTypesColumns({
      onDeactivate: deactivateClickHandler,
      onActivate: activateConfirmHandler,
    }),
    data: types,
    rowCount: typesData?.count,
  });

  const typeRowClickHandler = (type: TAdminSubmitterType) => {
    navigate(AdminRoutes.App.Safs.SubmitterTypeDetail.makePath({ dynamicParameters: { id: type.id }, queries: {
      original_district: districtQuery,
    } }));
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1">
        <div className="flex w-full items-center justify-between gap-4 p-4">
          <div className="flex items-center gap-4">
            <div className="min-w-[200px]">
              <DistrictFilterComboBox status={DISTRICT_STATUS.PUBLISHED} value={districtQuery || ''} onChange={onDistrictQueryChange} onClear={onDistrictQueryClear} placeholder="Select district" />
            </div>
          </div>
        </div>
      </div>
      {canShowTable
        ? (
            <DataTable
              table={table}
              loading={isTypesLoading}
              onRowClick={typeRowClickHandler}
            >
            </DataTable>
          )
        : (<NoDataAvailable iconName="folder-open" title="No Data Found" description="Please select a district to view data" className="min-h-96" />)}
      <CallToActionModal
        modalName={ModalType.DEACTIVATE_SUBMITTER_TYPE}
        modalTitle="Deactivate Type"
        modalDescription="Are you sure you want to deactivate this user type? Inactive type will not be available to users in the SAF form"
        modalTextContent="Are you sure you want to deactivate this user type? Inactive type will not be available to users in the SAF form"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={deactivateConfirmHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isPending}
      />
    </div>
  );
};
