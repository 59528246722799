import { useFieldArray, type UseFormReturn } from 'react-hook-form';
import { SAF_AREA_SUBCATEGORIES_LABELS } from '@purple/shared-types';
import { snakeCaseToCapitalized } from '@purple/shared-utils';
import { Form, FormControl, FormField, FormItem, FormMessage, MultiSelect, MultiSelectItem, Text } from '@purple/ui';
import type * as z from 'zod';
import type { TAdminSafSubCategoryView } from '~/services';
import type { serviceCategoriesSchema } from './serviceCategoriesSchema';

type TServiceCategoriesEditProperties = {
  categories: TAdminSafSubCategoryView[];
  form: UseFormReturn<z.infer<typeof serviceCategoriesSchema>>;
};

const ServiceCategoriesEdit = ({ form, categories }: TServiceCategoriesEditProperties) => {
  const { fields } = useFieldArray({
    control: form.control,
    name: 'service_area_subcategories',
  });

  const handleSelectChange = (index: number, value: string[]) => {
    form.setValue(`service_area_subcategories.${index}.sub_categories`, value);
  };

  return (
    <Form providerProps={form} className="flex w-full flex-col gap-4">
      {fields.map((category, index) => {
        const categoryLabel = SAF_AREA_SUBCATEGORIES_LABELS[category.name as keyof typeof SAF_AREA_SUBCATEGORIES_LABELS] || snakeCaseToCapitalized(category.name);
        const foundFetchedCategory = categories?.find((item) => item.id === category.custom_id);

        return (
          <div key={category.id} className="flex w-full gap-2">
            <Text variant="size-16" type="body-400" className="w-full text-grey-600">{categoryLabel}</Text>
            <FormField
              control={form.control}
              name={`service_area_subcategories.${index}.sub_categories`}
              render={({ field, fieldState }) => {
                const allOptions = foundFetchedCategory?.sub_categories.map((item) => ({
                  label: snakeCaseToCapitalized(item.name),
                  value: item.id,
                })) || [];

                const selectedCategoriesOptions = allOptions.filter((item) => field.value.includes(item.value));

                return (
                  <FormItem className="w-full">
                    <FormControl>
                      <MultiSelect
                        shouldFilter={false}
                        isError={!!fieldState.error}
                        selectedOptions={selectedCategoriesOptions}
                        modalPopover
                        placeholder="Select subcategories"
                        onOptionChange={(newValue) => handleSelectChange(index, newValue)}
                      >
                        {allOptions.map((option) => {
                          const isSelectedOption = field.value.includes(option.value);
                          return (
                            <MultiSelectItem
                              key={`${option.value} ${category.custom_id}`}
                              value={option.value}
                              option={option}
                              isSelected={isSelectedOption}
                            />
                          );
                        })}
                      </MultiSelect>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
        );
      })}
    </Form>
  );
};

export { ServiceCategoriesEdit };
