import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getFrequencyDistributionReport } from './get-frequency-distribution-report';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TAdminUsersFrequencyDistributionReportParameters, TAdminUsersFrequencyDistributionReportResponse } from './user.types';

export const useFrequencyDistributionReport = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TAdminUsersFrequencyDistributionReportParameters;
  enabled?: boolean;
}) => {
  const { isError, error, ...rest } = useQuery<TAdminUsersFrequencyDistributionReportResponse, AxiosError>({
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_FREQUENCY_DISTRIBUTION_REPORT, requestParameters],
    queryFn: () => getFrequencyDistributionReport(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch list of frequency distribution users');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
