import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Button, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, SearchInput, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTable, DataTableViewOptions, DistrictMultiSelect } from '~/components';
import { AdminRoutes, PERIOD_SELECT_OPTIONS, TableName, TIME_PERIODS } from '~/constants';
import { useDataTable } from '~/hooks';
import { DISTRICT_STATUSES, useLoggedInByRoleReport, useLoggedInByRoleReportExportMutation } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { reportColumns } from './reportColumns';

const DISTRICT_QUERY_NAME = 'district';
const PERIOD_QUERY_NAME = 'time_period';

const LoggedInByRoleReport = () => {
  const [searchParameters] = useSearchParams();

  const { query: districtQuery, onQueryChange: onDistrictQueryChange } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const { query: periodQuery, onQueryChange: onPeriodQueryChange } = useQueryParameter({ queryName: PERIOD_QUERY_NAME, resetOffset: true, defaultValue: TIME_PERIODS.last_7_days });

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: reportData, isLoading: isReportLoading } = useLoggedInByRoleReport({
    requestParameters: {
      ...(districtQuery && { district: districtQuery }),
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      ...(periodQuery && { time_period: periodQuery }),
    },
  });

  const { mutate: exportReport, isPending: isExportingReport } = useLoggedInByRoleReportExportMutation();

  const users = useMemo(() => reportData?.results ?? [], [reportData?.results]);

  const { table } = useDataTable({
    name: TableName.REPORT_LOGGED_IN_BY_ROLE,
    columns: reportColumns,
    data: users,
    rowCount: reportData?.count,
  });

  const selectedDistricts = useMemo(() => {
    const array = districtQuery?.split(',') || [];
    return array;
  }, [districtQuery]);

  const exportClickHandler = () => {
    if (reportData) {
      exportReport({
        ...(districtQuery && { district: districtQuery }),
        search: debounceSearch,
        limit: reportData.count,
        offset: searchParameters.get(OFFSET_QUERY_NAME),
        ordering: searchParameters.get(SORT_QUERY_NAME),
        time_period: null,
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
        },
      });
    }
  };

  const districtChangeHandler = (newValue: string[]) => {
    onDistrictQueryChange(newValue.join(','));
  };

  return (
    <div className="flex w-full flex-1 flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700 size-4 shrink-0" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Home.Root.path}>
          Back to Home Page
        </Link>
      </Button>
      <DataTable table={table} loading={isReportLoading} className="border-grey-200 shadow-custom-heavy rounded-lg border bg-white">
        <div className="flex w-full flex-col gap-4 p-4 pt-6">
          <Text variant="size-16" type="body-600" className="text-grey-title">Logged In Users by Role</Text>
          <div className="flex w-full items-center justify-between gap-4">
            <div className="flex w-full items-center gap-4">
              <div className="min-w-[200px] max-w-[200px]">
                <SearchInput
                  value={search}
                  placeholder="Search"
                  className="max-w-[300px]"
                  onChange={onSearchChange}
                  onClear={onClearSearch}
                />
              </div>
              <div className="min-w-[200px]">
                <DistrictMultiSelect values={selectedDistricts || ''} onChange={districtChangeHandler} placeholder="Select district" status={DISTRICT_STATUSES.PUBLISHED} />
              </div>
              <RadixSelect onValueChange={onPeriodQueryChange} value={periodQuery}>
                <RadixSelectTrigger size="md" className="w-[220px] gap-2">
                  <RadixSelectValue placeholder="All time" />
                </RadixSelectTrigger>
                <RadixSelectContent align="end">
                  {PERIOD_SELECT_OPTIONS.map((period) => (
                    <RadixSelectItem key={period.value} value={period.value} className="text-nowrap">
                      {period.label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="secondary"
                  size="icon_40"
                  className="size-10 shrink-0"
                  iconLeft={<PurpleIcon name="download" />}
                  disabled={users.length === 0}
                  isLoading={isExportingReport}
                  onClick={exportClickHandler}
                />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>Export Table to Excel</TooltipContent>
              </TooltipPortal>
            </Tooltip>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </DataTable>
    </div>
  );
};

export { LoggedInByRoleReport };
