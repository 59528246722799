import * as z from 'zod';
import { GRADE_CHOICES } from '@purple/shared-types';
import { multiSelectOptions } from '@purple/shared-utils';

export const addInvitePurpleUserSchema = z.object({
  email: z.string().email({ message: 'Please provide a valid email address' }),
  district: z.string().trim().min(1, { message: 'District is required.' }),
  full_name: z.string().trim().min(1, { message: 'Full name is required.' }),
  phone_number: z.string().trim().optional(),
  department: z.string().trim().min(1, { message: 'Department is required.' }),
  title: z.string().trim().min(1, { message: 'Title is required.' }),
  responsible_for_grades: z.array(z.nativeEnum(GRADE_CHOICES), { message: 'Please select at least one grade.' }),
  schools: multiSelectOptions.min(1, { message: 'Please select at least one school.' }),
  description: z.string().trim().min(1, { message: 'Description is required.' }),
  is_show_on_scorecard: z.boolean().optional(),
  prounitas_roles: z.array(z.number()).min(1, { message: 'Please select at least one role.' }),
  is_district_crisis_representative: z.boolean().optional(),
  is_school_crisis_representative: z.boolean().optional(),
});

export type TAddInvitePurpleUserFormValues = z.infer<typeof addInvitePurpleUserSchema>;
