import { type FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { AdminRoutes, TIME_PERIODS } from '~/constants';
import { useDocumentedActions, useLoginFrequency, useRoleLogins } from '~/services';
import { actionsLineChartConfig, barChartConfig, loggedLineChartConfig } from '../../home.constants';
import { ChartSkeleton } from './ChartSkeleton';
import { HomeBarChart } from './HomeBarChart';
import { HomeLineChart } from './HomeLineChart';
import type { DateRange } from '@purple/ui';

type TChartSectionProperties = {
  selectedDistrict: string[];
};

export const ChartSection: FC<TChartSectionProperties> = ({ selectedDistrict }) => {
  const navigate = useNavigate();

  const [usersByRoleDataPeriod, setUsersByRoleDataPeriod] = useState<string>('');
  const [usersLoggedInDataPeriod, setUsersLoggedInDataPeriod] = useState<DateRange>({
    from: startOfWeek(new Date()),
    to: endOfWeek(new Date()),
  });
  const [documentedActionsDataPeriod, setDocumentedActionsDataPeriod] = useState<DateRange>({
    from: startOfWeek(new Date()),
    to: endOfWeek(new Date()),
  });

  const { data: roleLogins, isLoading: isRoleLoginsLoading } = useRoleLogins({
    districts: selectedDistrict,
    time_period: usersByRoleDataPeriod,
  });

  const { data: loginFrequency, isLoading: isLoginFrequencyLoading } = useLoginFrequency({
    districts: selectedDistrict,
    start_date: format(usersLoggedInDataPeriod.from, 'yyyy-MM-dd'),
    end_date: usersLoggedInDataPeriod.to ? format(usersLoggedInDataPeriod.to, 'yyyy-MM-dd') : '',
  });

  const { data: documentedActions, isLoading: isDocumentedLoading } = useDocumentedActions({
    districts: selectedDistrict,
    start_date: format(documentedActionsDataPeriod.from, 'yyyy-MM-dd'),
    end_date: documentedActionsDataPeriod.to ? format(documentedActionsDataPeriod.to, 'yyyy-MM-dd') : '',
  });

  const barChartData = useMemo(
    () =>
      Object.entries(roleLogins?.logged_in_by_role ?? {}).map(([name, value]) => ({
        role: name,
        users: value,
      })),
    [roleLogins],
  );

  const loggedLineChartData = useMemo(
    () =>
      loginFrequency?.login_frequency.map((item) => ({
        label: `${format(item.start, 'MM/dd/yy')} - ${format(item.end, 'MM/dd/yy')}`,
        value: item.users_logged_in_3_times,
        percentage: item.percentage,
      })) ?? [],
    [loginFrequency],
  );

  const actionsLineChartData = useMemo(
    () =>
      documentedActions?.documented_actions.map((item) => ({
        label: `${format(item.start, 'MM/dd/yy')} - ${format(item.end, 'MM/dd/yy')}`,
        value: item.actions_count,
      })) ?? [],
    [documentedActions],
  );

  const updateDocumentedActionsDataPeriod = useCallback((range: DateRange) => {
    setDocumentedActionsDataPeriod(range);
  }, []);

  const updateLoginFrequencyDataPeriod = useCallback((range: DateRange) => {
    setUsersLoggedInDataPeriod(range);
  }, []);

  const goToDocumentedActionsClickHandler = () => {
    const districtQueryValue = selectedDistrict.join(',');

    navigate(AdminRoutes.App.DocumentedActions.Root.makePath({ queries: { district: districtQueryValue } }), {
      replace: true,
    });
  };

  const goToFrequencyDistributionReportClickHandler = () => {
    const districtQueryValue = selectedDistrict.join(',');

    navigate(AdminRoutes.App.UserFrequencyDistribution.Root.makePath({ queries: { district: districtQueryValue } }), {
      replace: true,
    });
  };

  const goToLoggedInByRoleReportClickHandler = () => {
    const districtQueryValue = selectedDistrict.join(',');

    navigate(AdminRoutes.App.LoggedInByRole.Root.makePath({ queries: { district: districtQueryValue, time_period: usersByRoleDataPeriod || TIME_PERIODS.last_7_days } }), {
      replace: true,
    });
  };

  return (
    <section className="grid grid-cols-1 gap-4 lg:grid-cols-2">
      {isRoleLoginsLoading
        ? (
            <ChartSkeleton className="lg:col-span-2" />
          )
        : (
            <HomeBarChart
              barChartData={barChartData}
              barChartConfig={barChartConfig}
              currentSelectedTimePeriod={usersByRoleDataPeriod}
              onTimePeriodChange={setUsersByRoleDataPeriod}
              onReportView={goToLoggedInByRoleReportClickHandler}
            />
          )}
      {isLoginFrequencyLoading
        ? (
            <ChartSkeleton />
          )
        : (
            <HomeLineChart
              lineChartData={loggedLineChartData}
              lineChartConfig={loggedLineChartConfig}
              dateFrom={usersLoggedInDataPeriod.from}
              dateTo={usersLoggedInDataPeriod.to}
              onDateRangeChange={updateLoginFrequencyDataPeriod}
              title="Users Logged In 3 Times"
              onReportView={goToFrequencyDistributionReportClickHandler}
            />
          )}
      {isDocumentedLoading
        ? (
            <ChartSkeleton />
          )
        : (
            <HomeLineChart
              lineChartData={actionsLineChartData}
              lineChartConfig={actionsLineChartConfig}
              dateFrom={documentedActionsDataPeriod.from}
              dateTo={documentedActionsDataPeriod.to}
              onDateRangeChange={updateDocumentedActionsDataPeriod}
              title="Number of Documented Actions"
              onReportView={goToDocumentedActionsClickHandler}
            />
          )}
    </section>
  );
};
