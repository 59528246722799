import { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { cutGradeName, getInitialsFromName } from '@purple/shared-utils';
import {
  AppTipBox,
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  MultiSelect,
  MultiSelectItem,
  RadioGroup,
  RadioGroupItem,
  ScrollArea,
  Separator,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { type TAdminSafRoutingRulesPayload, useEditRoutingRules, useNeeds, usePublishedDistricts, useRoleSearch, useSafRuleDetail, useSchools, useUsersList } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { editNewRuleSchema } from './edit-new-rule-schema';
import { EditRuleModalSkeleton } from './EditRuleModalSkeleton';
import { ASSIGN_OPTIONS_VALUES, TEST_ROUTE_TO_OPTIONS } from './new-rule.constants';
import type * as z from 'zod';
import type { TMultiSelectOption } from '@purple/ui';

const DEFAULT_SEARCH_DELAY = 500;

type TFormValues = z.infer<typeof editNewRuleSchema>;

type TEditNewRuleModalProperties = {
  id?: string;
};

export const EditRuleModal = ({ id }: TEditNewRuleModalProperties) => {
  const formId = useId();
  const { isOpen, toggleModal } = useModal(ModalType.EDIT_RULE);

  const { data: safRuleData, isLoading: isSafRuleLoading } = useSafRuleDetail({
    id,
    enabled: isOpen && !!id,
  });

  const { mutate, isPending } = useEditRoutingRules();

  const [currentSchoolsSelectGradeOptions, setCurrentSchoolsSelectGradeOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [districtsDebouncedSearchValue, setDistrictsDebouncedSearchValue] = useState<string>('');
  const [schoolsDebouncedSearchValue, setSchoolsDebouncedSearchValue] = useState<string>('');
  const [needsDebouncedSearchValue, setNeedsDebouncedSearchValue] = useState<string>('');
  const [usersDebouncedSearchValue, setUsersDebouncedSearchValue] = useState<string>('');
  const [rolesDebouncedSearchValue, setRolesDebouncedSearchValue] = useState<string>('');

  const defaultValues = useMemo(() => {
    const { district, schools, need, grades, student_last_name_ranges, assigned_user, assigned_role } = safRuleData || {};

    return {
      district: district?.id.toString() ?? '',
      schools: schools?.map(({ id, name }) => ({ label: name, value: id })) ?? [],
      need: need?.id ?? '',
      grades: grades ?? [],
      student_last_name_ranges: student_last_name_ranges ?? [{ start: '', end: '' }],
      assign_to: assigned_user ? ASSIGN_OPTIONS_VALUES.CONTACT : ASSIGN_OPTIONS_VALUES.ROLE,
      assigned_user: assigned_user?.id ?? '',
      assigned_role: assigned_role?.id.toString() ?? '',
    };
  }, [safRuleData]);

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(editNewRuleSchema),
    defaultValues,
  });

  useEffect(() => {
    if (safRuleData) {
      const newGradesOptions = safRuleData.schools.flatMap((school) => school.grades).map((grade) => ({
        label: cutGradeName(grade),
        value: grade,
      }));

      setCurrentSchoolsSelectGradeOptions(newGradesOptions);
    }
  }, [safRuleData]);

  useEffect(() => {
    form.reset(defaultValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'student_last_name_ranges',
  });

  const selectedDistrict = form.watch('district');
  const selectedSchools = form.watch('schools');
  const selectedAssignTo = form.watch('assign_to');

  const { data: districts, isLoading: isDistrictsLoading } = usePublishedDistricts({
    search: districtsDebouncedSearchValue,
  });

  const { data: schools, isLoading: isSchoolsLoading } = useSchools({
    district: selectedDistrict,
    search: schoolsDebouncedSearchValue,
  });

  const { data: needs, isLoading: isNeedsLoading } = useNeeds({
    requestParameters: {
      search: needsDebouncedSearchValue,
      district: selectedDistrict,
    },
    enabled: selectedDistrict.length > 0,
  });

  const { data: users, isLoading: isUserLoading } = useUsersList({
    requestParameters: {
      search: usersDebouncedSearchValue,
      school: selectedSchools.map((school) => school.value),
    },
    enabled: selectedSchools.length > 0 && selectedAssignTo === ASSIGN_OPTIONS_VALUES.CONTACT,
  });

  const { data: roles, isLoading: isRolesLoading } = useRoleSearch({
    requestParameters: {
      search: rolesDebouncedSearchValue,
      district: selectedDistrict,
    },
    enabled: selectedDistrict.length > 0 && selectedAssignTo === ASSIGN_OPTIONS_VALUES.ROLE,
  });

  const districtsSelectOptions = useMemo(() => {
    return (
      districts?.results.map((district) => ({
        ...district,
        label: district.name,
        value: district.id,
      })) ?? []
    );
  }, [districts]);

  const schoolsSelectOptions = useMemo(() => {
    return (
      schools?.results.map((school) => ({
        ...school,
        label: school.name,
        value: school.id,
      })) ?? []
    );
  }, [schools]);

  const needsSelectOptions = useMemo(() => {
    return (
      needs?.results.map((need) => ({
        ...need,
        label: need.name,
        value: need.id,
      })) ?? []
    );
  }, [needs]);

  const usersSelectOptions = useMemo(() => {
    return (
      users?.results.map((user) => ({
        ...user,
        full_name: user.full_name ?? '',
        avatar: user.avatar ?? '',
        label: user.full_name ?? user.email,
        value: user.id,
      })) ?? []
    );
  }, [users]);

  const rolesSelectOptions = useMemo(() => {
    return (
      roles?.results.map((role) => ({
        ...role,
        label: role.name,
        value: role.id,
      })) ?? []
    );
  }, [roles]);

  const schoolsDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setSchoolsDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const usersDebounceSearch = useDebouncedCallback((searchQuery: string) => {
    setUsersDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const districtsDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDistrictsDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const needsDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setNeedsDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const rolesDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setRolesDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const closeModalHandler = () => {
    form.reset();
    toggleModal(!isOpen);
  };

  const submitFormHandler = (data: TFormValues) => {
    if (id) {
      const payload: TAdminSafRoutingRulesPayload = {
        district: data.district,
        schools: data.schools.map(({ value }) => value),
        need: data.need,
        grades: data.grades,
        student_last_name_ranges: data.student_last_name_ranges,
        ...(data.assign_to === ASSIGN_OPTIONS_VALUES.CONTACT && { assigned_user: data.assigned_user }),
        ...(data.assign_to === ASSIGN_OPTIONS_VALUES.ROLE && { assigned_role: data.assigned_role }),
      };
      mutate({
        id,
        payload,
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'SAF routing rule edited successfully');
          closeModalHandler();
        },
      });
    }
  };

  const handleSchoolOptionChange = useCallback(
    (schoolsSelectValue: TMultiSelectOption<string>[]) => {
      const currentSelected = schoolsSelectOptions.filter((item) => schoolsSelectValue.some((school) => school.value === item.value));
      const selectedSchoolsGrades = currentSelected.flatMap((item) => item.grades);
      const gradesSet = new Set([...currentSchoolsSelectGradeOptions.map(({ value }) => value), ...selectedSchoolsGrades]);
      setCurrentSchoolsSelectGradeOptions(
        [...gradesSet].map((grade) => ({
          label: grade,
          value: grade,
        })),
      );
      form.setValue('grades', []);
      form.setValue('assigned_user', '');
      form.setValue('schools', schoolsSelectValue);
    },
    [schoolsSelectOptions, form, currentSchoolsSelectGradeOptions],
  );

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>Edit SAF Routing Rule</DialogTitle>
            <DialogDescription>Edit SAF routing rule by filling the form below</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          {isSafRuleLoading
            ? <EditRuleModalSkeleton />
            : (
                <Form
                  providerProps={form}
                  className="flex flex-col gap-5 p-6"
                  onSubmit={form.handleSubmit(submitFormHandler)}
                  id={formId}
                >
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <FormField
                      name="district"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <FormItem className="relative">
                          <FormLabel required>District</FormLabel>
                          <ComboBox modal>
                            <FormControl>
                              <ComboBoxTrigger
                                isError={!!fieldState.error}
                                placeholder="Select district"
                                selectedLabel={
                                  districtsSelectOptions.find((option) => option.value.toString() === field.value.toString())
                                    ?.label
                                }
                              />
                            </FormControl>
                            <ComboBoxContent
                              loading={isDistrictsLoading}
                              onSearchChange={districtsDebouncedSearch}
                              shouldFilter={false}
                              searchPlaceholder="Search district name..."
                              emptyContent="No districts found."
                            >
                              {districtsSelectOptions.map(({ label, value }) => (
                                <ComboBoxItem
                                  key={value}
                                  value={value.toString()}
                                  selected={value.toString() === field.value}
                                  onSelect={(districtSelectValue) => {
                                    form.setValue('schools', []);
                                    setCurrentSchoolsSelectGradeOptions([]);
                                    field.onChange(districtSelectValue);
                                  }}
                                >
                                  {label}
                                </ComboBoxItem>
                              ))}
                            </ComboBoxContent>
                          </ComboBox>
                          <FormMessage className="absolute" />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="schools"
                      render={({ field, fieldState }) => (
                        <FormItem className="relative">
                          <div className="flex items-center gap-1">
                            <FormLabel required>School(s)</FormLabel>
                            {!selectedDistrict && (
                              <AppTipBox
                                iconName="exclamation-circle"
                                text="Please select the district first"
                                className="text-error-main hover:text-error-main"
                              />
                            )}
                          </div>

                          <FormControl>
                            <MultiSelect
                              isError={!!fieldState.error}
                              selectedOptions={field.value}
                              modalPopover
                              placeholder="Select school(s)"
                              showSearch
                              shouldFilter={false}
                              disabled={!selectedDistrict}
                              loading={isSchoolsLoading}
                              onSearchChange={schoolsDebouncedSearch}
                              onOptionChange={(_, selected) => handleSchoolOptionChange(selected)}
                            >
                              {schoolsSelectOptions.map((school) => (
                                <MultiSelectItem
                                  key={school.value}
                                  value={school.value}
                                  option={school}
                                />
                              ))}
                            </MultiSelect>
                          </FormControl>
                          <FormMessage className="absolute" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <FormField
                      control={form.control}
                      name="need"
                      render={({ field, fieldState }) => (
                        <FormItem className="relative">
                          <div className="flex items-center gap-1">
                            <FormLabel required>SAF Need type</FormLabel>
                            {selectedDistrict.length === 0 && (
                              <AppTipBox
                                iconName="exclamation-circle"
                                text="Please select district first"
                                className="text-error-main hover:text-error-main"
                              />
                            )}
                          </div>

                          <ComboBox modal>
                            <FormControl>
                              <ComboBoxTrigger
                                isError={!!fieldState.error}
                                placeholder="Select SAF Need Type"
                                selectedLabel={needsSelectOptions.find((option) => option.value === field.value)?.label}
                                disabled={selectedDistrict.length === 0}
                              />
                            </FormControl>
                            <ComboBoxContent
                              loading={isNeedsLoading}
                              shouldFilter={false}
                              searchPlaceholder="Search for needs..."
                              emptyContent="Needs not found."
                              onSearchChange={needsDebouncedSearch}
                            >
                              {needsSelectOptions.map(({ value, label }) => (
                                <ComboBoxItem
                                  key={value}
                                  value={value}
                                  selected={value === field.value}
                                  onSelect={field.onChange}
                                >
                                  {label}
                                </ComboBoxItem>
                              ))}
                            </ComboBoxContent>
                          </ComboBox>
                          <FormMessage className="absolute" />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="grades"
                      render={({ field, fieldState }) => (
                        <FormItem className="relative">
                          <FormLabel>Grade(s)</FormLabel>
                          <FormControl>
                            <MultiSelect
                              {...field}
                              isError={!!fieldState.error}
                              selectedOptions={currentSchoolsSelectGradeOptions.filter((item) =>
                                (field.value as string[]).includes(item.value),
                              )}
                              modalPopover
                              placeholder="Select grade(s)"
                              onOptionChange={field.onChange}
                            >
                              {currentSchoolsSelectGradeOptions.map((grade) => (
                                <MultiSelectItem
                                  key={grade.value}
                                  value={grade.value}
                                  option={grade}
                                  isSelected={(field.value as string[])?.includes(grade.value)}
                                />
                              ))}
                            </MultiSelect>
                          </FormControl>
                          <FormMessage className="absolute" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <div className="flex items-center gap-1">
                      <Label>
                        Student Last Name is between
                        <span className="text-error-main">*</span>
                      </Label>
                      <AppTipBox text="You can enter up to 3 letters" />
                    </div>

                    <div className="flex flex-col gap-6">
                      {fields.map((item, index) => (
                        <div key={item.id} className="flex items-center gap-3">
                          <div className="flex items-center gap-2">
                            <FormField
                              control={form.control}
                              name={`student_last_name_ranges.${index}.start`}
                              render={({ field, fieldState }) => (
                                <FormItem className="relative">
                                  <FormControl>
                                    <Input
                                      {...field}
                                      isError={!!fieldState.error}
                                      value={field.value}
                                      onChange={field.onChange}
                                      placeholder="A"
                                      max={3}
                                    />
                                  </FormControl>
                                  <FormMessage className="absolute" />
                                </FormItem>
                              )}
                            />
                            <span className="font-primary text-grey-200">—</span>
                            <FormField
                              control={form.control}
                              name={`student_last_name_ranges.${index}.end`}
                              render={({ field, fieldState }) => (
                                <FormItem className="relative">
                                  <FormControl>
                                    <Input
                                      {...field}
                                      isError={!!fieldState.error}
                                      value={field.value}
                                      onChange={field.onChange}
                                      placeholder="Z"
                                      max={3}
                                    />
                                  </FormControl>
                                  <FormMessage className="absolute" />
                                </FormItem>
                              )}
                            />
                          </div>
                          {index === fields.length - 1 && (
                            <Button
                              variant="tertiary"
                              size="small"
                              className="p-1.5"
                              iconLeft={<PurpleIcon name="plus" />}
                              onClick={() => append({ start: '', end: '' })}
                            >
                              Add Split
                            </Button>
                          )}
                          {index !== fields.length - 1 && (
                            <Button
                              variant="tertiary"
                              className="p-1.5"
                              size="small"
                              iconLeft={<PurpleIcon name="X" className="text-error-main" />}
                              onClick={() => remove(index)}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <FormField
                      control={form.control}
                      name="assign_to"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>User</FormLabel>
                          <FormControl>
                            <RadioGroup
                              onValueChange={(value) => {
                                form.setValue('assigned_user', '');
                                form.setValue('assigned_role', '');
                                field.onChange(value);
                              }}
                              defaultValue={field.value}
                              className="flex flex-row items-center gap-4"
                            >
                              {TEST_ROUTE_TO_OPTIONS.map(({ label, value }) => (
                                <FormItem key={value} className="flex items-center gap-x-2">
                                  <FormControl>
                                    <RadioGroupItem value={value} className="size-4" />
                                  </FormControl>
                                  <FormLabel>{label}</FormLabel>
                                </FormItem>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    {selectedAssignTo === ASSIGN_OPTIONS_VALUES.CONTACT && (
                      <FormField
                        control={form.control}
                        name="assigned_user"
                        render={({ field, fieldState }) => (
                          <FormItem className="relative w-full md:w-1/2">
                            <div className="flex items-center gap-1">
                              <FormLabel required>Person to Assign</FormLabel>
                              {selectedSchools.length === 0 && (
                                <AppTipBox
                                  iconName="exclamation-circle"
                                  text="Please select at least one school first"
                                  className="text-error-main hover:text-error-main"
                                />
                              )}
                            </div>

                            <ComboBox modal>
                              <FormControl>
                                <ComboBoxTrigger
                                  isError={!!fieldState.error}
                                  placeholder="Select contact"
                                  selectedLabel={usersSelectOptions.find((option) => option.value === field.value)?.label}
                                  disabled={selectedSchools.length === 0}
                                />
                              </FormControl>
                              <ComboBoxContent
                                loading={isUserLoading}
                                shouldFilter={false}
                                searchPlaceholder="Search by name"
                                emptyContent="User not found."
                                onSearchChange={usersDebounceSearch}
                              >
                                {usersSelectOptions.map(({ value, avatar, email, full_name, label }) => (
                                  <ComboBoxItem
                                    key={value}
                                    value={value}
                                    selected={value === field.value}
                                    onSelect={field.onChange}
                                  >
                                    <div className="flex items-center gap-3">
                                      <Avatar size={32}>
                                        <AvatarImage src={avatar ?? undefined} />
                                        <AvatarFallback className="bg-grey-200">
                                          {getInitialsFromName(full_name ?? '—')}
                                        </AvatarFallback>
                                      </Avatar>
                                      <div className="flex flex-col gap-1">
                                        <Text variant="size-12" type="body-500" className="text-grey-950">
                                          {label}
                                        </Text>
                                        <Text variant="size-12" type="body-400" className="text-grey-600">
                                          {email}
                                        </Text>
                                      </div>
                                    </div>
                                  </ComboBoxItem>
                                ))}
                              </ComboBoxContent>
                            </ComboBox>
                            <FormMessage className="absolute" />
                          </FormItem>
                        )}
                      />
                    )}
                    {selectedAssignTo === ASSIGN_OPTIONS_VALUES.ROLE && (
                      <FormField
                        control={form.control}
                        name="assigned_role"
                        render={({ field, fieldState }) => (
                          <FormItem className="relative w-full md:w-1/2">
                            <FormLabel required>Role</FormLabel>
                            <ComboBox modal>
                              <FormControl>
                                <ComboBoxTrigger
                                  isError={!!fieldState.error}
                                  placeholder="Select role"
                                  selectedLabel={
                                    rolesSelectOptions.find((option) => option.value.toString() === field.value)?.label
                                  }
                                  disabled={selectedDistrict.length === 0}
                                />
                              </FormControl>
                              <ComboBoxContent
                                loading={isRolesLoading}
                                onSearchChange={rolesDebouncedSearch}
                                shouldFilter={false}
                                searchPlaceholder="Search role name..."
                                emptyContent="No roles found."
                              >
                                {rolesSelectOptions.map(({ label, value }) => (
                                  <ComboBoxItem
                                    key={value}
                                    value={value.toString()}
                                    selected={value.toString() === field.value}
                                    onSelect={field.onChange}
                                  >
                                    {label}
                                  </ComboBoxItem>
                                ))}
                              </ComboBoxContent>
                            </ComboBox>
                            <FormMessage className="absolute" />
                          </FormItem>
                        )}
                      />
                    )}
                  </div>
                </Form>
              )}

        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModalHandler}>
            Cancel
          </Button>
          <Button type="submit" form={formId} isLoading={isPending} disabled={isSafRuleLoading}>
            Edit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
