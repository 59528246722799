import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { DISTRICT_STATUS } from '@purple/shared-types';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
  Textarea,
} from '@purple/ui';
import { DistrictFilterComboBox } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useCreatePriorityList, useUpdatePriorityList } from '~/services';
import type React from 'react';
import type { TContactPriorityListItem } from '~/services';

const priorityListSchema = z
  .object({
    name: z.string().trim().min(1, 'Name is required').max(255, 'Name is too long'),
    district: z.string(),
    description: z.string().trim().optional(),
  })
  .superRefine((data, context) => {
    if (data.district === '') {
      context.addIssue({
        path: ['district'],
        code: z.ZodIssueCode.custom,
        message: 'District is required',
      });
    }
  });

const DEFAULT_CONTACTS: string[] = [];

type TManagePriorityListDialogProperties = {
  priorityList?: TContactPriorityListItem | null;
  contacts?: string[];
  districtId?: number | null;
  onSuccess?: () => void;
};

export const ManagePriorityListDialog: React.FC<TManagePriorityListDialogProperties> = (props) => {
  const { priorityList = null, districtId = null, contacts = DEFAULT_CONTACTS, onSuccess } = props;

  const formId = useId();

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.MANAGE_CONTACTS_PRIORITY_LIST);

  const { mutate: createPriorityList, isPending: isCreatePending } = useCreatePriorityList();
  const { mutate: updatePriorityList, isPending: isUpdatePending } = useUpdatePriorityList();
  const isLoading = useMemo(() => isCreatePending || isUpdatePending, [isCreatePending, isUpdatePending]);

  const defaultValues = useMemo(
    () => ({
      name: priorityList?.name || '',
      description: priorityList?.description || '',
      district: priorityList?.district?.id.toString() || districtId?.toString() || '',
    }),
    [priorityList, districtId],
  );

  const form = useForm<z.infer<typeof priorityListSchema>>({
    resolver: zodResolver(priorityListSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const successCallback = () => {
    closeModal();
    form.reset(defaultValues);
    onSuccess?.();
  };

  const submitPriorityListHandler = (data: z.infer<typeof priorityListSchema>) => {
    if (priorityList === null) {
      createPriorityList({
        name: data.name,
        description: data.description,
        district: data.district,
        contacts,
      }, { onSuccess: successCallback });
    } else {
      updatePriorityList({
        id: priorityList.id,
        name: data.name,
        description: data.description,
      }, {
        onSuccess: successCallback,
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>
            {priorityList === null ? 'New Priority List' : 'Edit Priority List'}
          </DialogTitle>
          <DialogDescription className="sr-only">
            By creating a priority list, you can group contacts and assign a priority to them.
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          id={formId}
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(submitPriorityListHandler)}
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Priority List Name</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    isError={!!fieldState.error}
                    placeholder="Enter name here"
                    type="text"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="district"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>District</FormLabel>
                <FormControl>
                  <DistrictFilterComboBox
                    disabled={priorityList !== null || districtId !== null}
                    value={field.value}
                    isError={!!fieldState.error}
                    status={DISTRICT_STATUS.PUBLISHED}
                    align="start"
                    placeholder="Select district"
                    contentClassName="min-w-80"
                    onChange={field.onChange}
                    onClear={() => field.onChange('')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field, fieldState }) => (
              <FormItem className="col-span-2">
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    isError={!!fieldState.error}
                    placeholder="Enter description here"
                    responsiveHeight
                    className="min-h-[100px] resize-none"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} isLoading={isLoading}>
            {priorityList === null ? 'Create' : 'Save'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
