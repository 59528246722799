import axiosInstance from '../axios-config';
import { ACTIONS_ENDPOINTS } from '../endpoints';
import type { TActionFiltersRequestParameters, TStudentCenteredActionsFilterOptions } from './actions.types';

export const getActionsListFilterOptions = async (parameters?: TActionFiltersRequestParameters) => {
  const response = await axiosInstance.get<TStudentCenteredActionsFilterOptions>(ACTIONS_ENDPOINTS.STUDENT_CENTERED_ACTIONS_FILTER_OPTIONS, {
    params: parameters,
  });
  return response.data;
};
