import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TAGS_TYPES } from '@purple/shared-types';
import { NumberBadge, Skeleton } from '@purple/ui';
import { useCustomTags, useSchoolBasicDetails } from '~/services';

const DEFAULT_AMOUNT_OF_VISIBLE_TAGS = 4;

export const SchoolTags: React.FC = () => {
  const { id } = useParams();

  const [visibleTagsAmount, setVisibleTagsAmount] = useState<number>(DEFAULT_AMOUNT_OF_VISIBLE_TAGS);

  const { data: school } = useSchoolBasicDetails(id as string);

  const { data: tags, isFetching: isTagsFetching } = useCustomTags({
    content_type: TAGS_TYPES.SCHOOL,
    object_id: id as string,
    district: school?.district as number,
  }, {
    enabled: school?.district !== undefined,
  });
  const schoolTags = useMemo(() => tags?.results ?? [], [tags]);

  const tagViewClickHandler = () => {
    setVisibleTagsAmount(
      (prev) => (prev === DEFAULT_AMOUNT_OF_VISIBLE_TAGS
        ? schoolTags.length
        : DEFAULT_AMOUNT_OF_VISIBLE_TAGS),
    );
  };

  if (isTagsFetching) {
    return (
      <div className="flex flex-wrap items-center justify-start gap-2">
        <Skeleton className="h-7 w-20 rounded-full" />
        <Skeleton className="h-7 w-20 rounded-full" />
        <Skeleton className="h-7 w-20 rounded-full" />
      </div>
    );
  }

  if (schoolTags.length === 0) {
    return null;
  }

  return (
    <ul className="flex flex-wrap items-center justify-start gap-2">
      {schoolTags
        .slice(0, visibleTagsAmount)
        .map((tag) => (
          <li key={tag.name}>
            <NumberBadge variant="info" className="line-clamp-1 h-auto min-h-7 min-w-0 break-all rounded-full py-0.5">
              {tag.name}
            </NumberBadge>
          </li>
        ))}
      {schoolTags.length > DEFAULT_AMOUNT_OF_VISIBLE_TAGS && (
        <li>
          <button
            type="button"
            className="inline-flex h-7 cursor-pointer items-center justify-center px-2 py-0.5 text-sm font-semibold text-brand-blue-700 outline-none transition-colors duration-200 hover:text-brand-blue-600 focus:text-brand-blue-600 focus-visible:text-brand-blue-600 active:text-brand-blue-800 active:transition-none"
            onClick={tagViewClickHandler}
          >
            {visibleTagsAmount === DEFAULT_AMOUNT_OF_VISIBLE_TAGS
              ? 'View All'
              : 'Show Less'}
          </button>
        </li>
      )}
    </ul>
  );
};
