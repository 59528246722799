import { useEffect, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, Separator, Text } from '@purple/ui';
import { SAF_LANGUAGES, type TSafLanguages } from '~/pages/Main/Saf/constants';
import { type TAdminSubmitterTypeDetail, type TAdminSubmitterTypeTextTranslationItem, useSubmitterTypeTextTranslation, useUpdateSubmitterTypeTextTranslations } from '~/services';
import { TextTranslationTableEdit, TextTranslationTableView } from './components';
import { TextTranslationTabSkeleton } from './TextTranslationTabSkeleton';

type TTextTranslationTabProperties = {
  data?: TAdminSubmitterTypeDetail;
};

const TextTranslationTab = ({ data }: TTextTranslationTabProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSubmitterTypeTextTranslations();

  const [selectedLanguage, setSelectedLanguage] = useState<TSafLanguages>('en');

  const [editableData, setEditableData] = useState<TAdminSubmitterTypeTextTranslationItem[] | null>(null);

  const { data: textData } = useSubmitterTypeTextTranslation({
    typeId: data?.id,
  });

  const languageChangeHandler = (value: TSafLanguages) => {
    setSelectedLanguage(value);
  };

  useEffect(() => {
    setEditableData(textData?.needs || null);
  }, [textData]);

  if (!textData) return <TextTranslationTabSkeleton />;

  const changeItemHandler = (data: TAdminSubmitterTypeTextTranslationItem[]) => {
    setEditableData(data);
  };

  const saveHandler = () => {
    if (!editableData || !textData || !data?.id) return;

    const changedData = editableData.filter((item) => {
      const foundItem = textData.needs.find((field) => field.id === item.id);

      if (!foundItem) return false;

      // Check if some translation is different
      if (Object.keys(foundItem.translations).some((translation) => foundItem.translations && foundItem.translations[translation as keyof typeof foundItem.translations] !== item.translations[translation as keyof typeof item.translations])) {
        return true;
      }

      return false;
    });

    mutate({
      id: data?.id || '',
      payload: {
        needs: changedData,
      },
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-6 py-5">
        <div className="flex flex-col gap-0">
          <Heading variant="size-18" type="heading-600" className="text-grey-950">Text Translation</Heading>
          <Text variant="size-16" type="body-400" className="text-grey-600">You can update and manage translations for SAF messages and texts.</Text>
        </div>
        <div>
          {mode === 'view' && (
            <div className="flex flex-row gap-[12px]">
              <Button size="link" variant="link" iconLeft={<PurpleIcon name="pencil" />} onClick={() => setMode('edit')}>
                Edit
              </Button>
            </div>
          )}
          {mode === 'edit' && (
            <div className="flex flex-row gap-[12px]">
              <Button size="small" variant="secondary" onClick={() => setMode('view')}>
                Cancel
              </Button>
              <Button size="small" variant="primary" onClick={saveHandler} isLoading={isPending}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex items-center justify-between px-6 py-5">
        <RadixSelect defaultValue={selectedLanguage} onValueChange={languageChangeHandler}>
          <RadixSelectTrigger className="w-40">
            <RadixSelectValue placeholder="Select language" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {SAF_LANGUAGES.map((language) => (
              <RadixSelectItem key={language.value} value={language.value}>
                {language.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
      </div>
      {mode === 'view' && (
        <TextTranslationTableView data={textData} selectedLanguage={selectedLanguage} />
      )}
      {mode === 'edit' && (
        <TextTranslationTableEdit data={editableData} selectedLanguage={selectedLanguage} onChange={changeItemHandler} />
      )}
    </div>
  );
};

export { TextTranslationTab };
