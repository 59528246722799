import axiosInstance from '../../axios-config';
import { ACTIVITY_TYPES_ENDPOINTS } from '../../endpoints';
import type { TActivityTypeListResponse, TActivityTypesRequestParameters } from '../activities.types';

export const getActivityTypes = async (parameters?: TActivityTypesRequestParameters) => {
  const response = await axiosInstance.get<TActivityTypeListResponse>(ACTIVITY_TYPES_ENDPOINTS.ROOT, {
    params: parameters,
  });

  return response.data;
};
