import { type FC, memo, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { ACTION_TYPES_SELECT_OPTIONS } from '@purple/shared-utils';
import { AppFileDropZone, Button, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage, Text, UploadedFilesPreview } from '@purple/ui';
import { BULK_TEMPLATE_TO_ACTION_TYPE_MAP, useDistrictsListBasicInfo, useDownloadBulkTemplate } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { BULK_DROPZONE_ALLOWED_FILE_TYPES, MAX_FILE_SIZE, type TBulkUploadActionsSchema } from './schema';

const DEFAULT_SEARCH_DELAY = 400;

type TUploadFormContentProps = {
  isReuploading: boolean;
};

export const UploadFormContent: FC<TUploadFormContentProps> = memo(({ isReuploading }) => {
  const { control, setValue, watch } = useFormContext<TBulkUploadActionsSchema>();

  const uploadedFile = watch('file');
  const selectedActionType = watch('record_action_type');

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string | null>(null);

  const { data: activeDistricts, isFetching } = useDistrictsListBasicInfo({
    search: debouncedSearchValue,
    limit: 50,
    status: 'published',
  });
  const { mutate, isPending } = useDownloadBulkTemplate(BULK_TEMPLATE_TO_ACTION_TYPE_MAP[selectedActionType]);

  const districtsSelectOptions = useMemo(() => {
    return (
      activeDistricts?.results.map((district) => ({
        ...district,
        label: district.name,
        value: district.id,
      })) ?? []
    );
  }, [activeDistricts]);

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const downloadTemplateHandler = useCallback(() => {
    mutate(BULK_TEMPLATE_TO_ACTION_TYPE_MAP[selectedActionType], {
      onSuccess: (response) => {
        const link = document.createElement('a');
        link.href = response.file;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(response.file);
        showSuccessToast('System message', 'The template has been downloaded successfully to your default download location.');
      },
    });
  }, [mutate, selectedActionType]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-4">
        <FormField
          control={control}
          name="district"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>District</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select district"
                    disabled={isReuploading}
                    selectedLabel={
                      districtsSelectOptions.find((option) => option.value.toString() === field.value.toString())?.label
                    }
                  />
                </FormControl>
                <ComboBoxContent
                  loading={isFetching}
                  shouldFilter={false}
                  searchPlaceholder="Search..."
                  emptyContent="District not found."
                  onSearchChange={debouncedSearch}
                >
                  {activeDistricts?.results?.map(({ id, name }) => (
                    <ComboBoxItem
                      key={id}
                      value={id.toString()}
                      selected={field.value.toString() === id.toString()}
                      onSelect={field.onChange}
                    >
                      {name}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="record_action_type"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Action type</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select action type"
                    disabled={isReuploading}
                    selectedLabel={
                      ACTION_TYPES_SELECT_OPTIONS.find((option) => option.value.toString() === field.value.toString())?.label
                    }
                  />
                </FormControl>
                <ComboBoxContent
                  searchPlaceholder="Search..."
                  emptyContent="Action type not found."
                >
                  {ACTION_TYPES_SELECT_OPTIONS.map(({ label, value }) => (
                    <ComboBoxItem
                      key={value}
                      value={value}
                      keywords={[label]}
                      selected={field.value === value}
                      onSelect={field.onChange}
                    >
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col items-center gap-4">
          <div className="flex flex-col items-center gap-2">
            <Text variant="size-16" type="body-600">Download Template</Text>
            <Text variant="size-14" type="body-400" className="text-grey-600">Download the template and effortlessly upload all your actions information with just one click.</Text>
          </div>
          <Button type="button" iconLeft={isPending ? <PurpleIcon name="loader" className="animate-spin" /> : <PurpleIcon name="download" />} variant="tertiary" size="small" onClick={downloadTemplateHandler}>
            {isPending ? 'Downloading...' : 'Download Template'}
          </Button>
        </div>
        <div>
          {uploadedFile && uploadedFile.length > 0
            ? (
                <UploadedFilesPreview
                  fileName={uploadedFile[0]?.name}
                  fileType={uploadedFile[0]?.type}
                  onRemove={() => setValue('file', [])}
                />
              )
            : (
                <FormField
                  control={control}
                  name="file"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <AppFileDropZone
                          value={field.value}
                          onValueChange={(files) => files && setValue('file', files, { shouldValidate: true })}
                          accept={BULK_DROPZONE_ALLOWED_FILE_TYPES}
                          maxSize={MAX_FILE_SIZE}
                          description="The uploaded file should be in XLSX or CSV format and less than 10MB."
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
        </div>
      </div>
    </div>
  );
});
