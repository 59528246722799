import { useMemo } from 'react';
import { TakeActionType } from '@purple/shared-types';
import { academicCareerPlanningColumns } from './academicCareerPlanningColumns';
import { checkInColumns } from './checkInColumns';
import { counselingColumns } from './counselingColumns';
import { crisisResponseColumns } from './crisisResponseColumns';
import { districtInitiativeColumns } from './districtInitiativeColumns';
import { guidanceCounselingLessonColumns } from './guidanceCounselingLessonColumns';
import { observationColumns } from './observationColumns';
import { reminderColumns } from './reminderColumns';
import { resourceColumns } from './resourceColumns';
import { serviceLinkColumns } from './serviceLinkColumns';
import { stakeholderCollaborativeMeetingColumns } from './stakeholderCollaborativeMeetingColumns';
import type { ColumnDef } from '@tanstack/react-table';
import type { TTakeActionType } from '@purple/shared-types';
import type { TActionBulkUploadResponse } from '~/services';

export const useActionsErrorsColumns = <T extends TTakeActionType>(actionType: T): ColumnDef<TActionBulkUploadResponse<T>>[] => {
  const columns = useMemo(() => {
    const columnMap = {
      [TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION]: academicCareerPlanningColumns,
      [TakeActionType.CHECK_IN]: checkInColumns,
      [TakeActionType.COUNSELING_SESSION]: counselingColumns,
      [TakeActionType.CRISIS_RESPONSE_SESSION]: crisisResponseColumns,
      [TakeActionType.DISTRICT_INITIATIVE]: districtInitiativeColumns,
      [TakeActionType.GUIDANCE_COUNSELING_LESSON]: guidanceCounselingLessonColumns,
      [TakeActionType.OBSERVATION]: observationColumns,
      [TakeActionType.REMINDER]: reminderColumns,
      [TakeActionType.RESOURCE]: resourceColumns,
      [TakeActionType.SERVICE_LINK]: serviceLinkColumns,
      [TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING]: stakeholderCollaborativeMeetingColumns,
    };

    return columnMap[actionType];
  }, [actionType]);

  return columns as ColumnDef<TActionBulkUploadResponse<T>>[];
};
