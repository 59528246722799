import { useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getNotifications } from './get-notifications';
import { NOTIFICATIONS_QUERY_KEYS } from './notifications.const';
import type { InfiniteData } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { TCustomUseInfiniteQueryOptions } from '~/types/query';
import type { TNotificationListQueryParameters, TNotificationsResponse } from './notifications.types';

export const useNotifications = (
  parameters?: TNotificationListQueryParameters,
  queryOptions?: TCustomUseInfiniteQueryOptions<TNotificationsResponse, AxiosError, InfiniteData<TNotificationsResponse>, unknown[], number | null>,
) => {
  const { limit = 10, search = '' } = parameters ?? {};
  const { isError, error, ...queryData } = useInfiniteQuery({
    queryKey: [NOTIFICATIONS_QUERY_KEYS.GET_NOTIFICATIONS_LIST, limit, search],
    queryFn: ({ pageParam = 0 }) => getNotifications({ offset: pageParam, limit, search }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      const nextOffset = lastPage.next ? new URL(lastPage.next).searchParams.get('offset') : null;
      return nextOffset ? Number.parseInt(nextOffset, 10) : null;
    },
    ...queryOptions,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to retrieve notifications');
    }
  }, [isError, error]);

  return { ...queryData, isError };
};
