import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TAdminUsersManagementRequestParameters, TUsersManagementResponse } from './user.types';

export const getUsersListManagement = async ({ token, ...parameters }: TAdminUsersManagementRequestParameters) => {
  const response = await axiosInstance.get<TUsersManagementResponse>(ADMIN_USER_MANAGEMENT.LIST, {
    params: parameters,
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
  });
  return response.data;
};
