import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import * as z from 'zod';
import { ContactType } from '@purple/shared-types';
import { onlyLettersWithSpacesRegex } from '@purple/shared-utils';
import { Form } from '@purple/ui';
import { CONTACTS_QUERY_KEYS, useUpdateContactDetails } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ContactSectionHeader } from '../../components';
import { DetailsSectionContent } from './DetailsSectionContent';
import { DetailsSectionFormContent } from './DetailsSectionFormContent';
import type { FC } from 'react';
import type { TContactDetailItem } from '~/services';

const contactDetailsSchema = z.object({
  contact_type: z.nativeEnum(ContactType, { message: 'Please select a contact type' }),
  first_name: z.string().trim().min(1, { message: 'Please enter a first name' }).regex(onlyLettersWithSpacesRegex, { message: 'First name can only contain letters' }),
  last_name: z.string().trim().min(1, { message: 'Please enter a last name' }).regex(onlyLettersWithSpacesRegex, { message: 'Last name can only contain letters' }),
  email: z.string().trim().min(1, { message: 'Please enter an email' }).email({ message: 'Please enter a valid email' }),
  phone: z.string().trim().optional(),
  district: z.string().trim().min(1, { message: 'Please select a district' }),
});

export type TContactDetailsFormSchema = z.infer<typeof contactDetailsSchema>;

export const DetailsSection: FC = () => {
  const { contactId } = useParams();
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);

  const contactDetails = queryClient.getQueryData<TContactDetailItem>([CONTACTS_QUERY_KEYS.GET_CONTACT_DETAILS, contactId]);

  const { mutate: updateContact, isPending } = useUpdateContactDetails();

  const defaultValues: TContactDetailsFormSchema = useMemo(() => ({
    first_name: contactDetails?.first_name ?? '',
    last_name: contactDetails?.last_name ?? '',
    email: contactDetails?.email ?? '',
    contact_type: contactDetails?.contact_type ?? ContactType.GENERAL,
    phone: contactDetails?.phone ?? '',
    district: contactDetails?.district?.id.toString() ?? '',
  }), [contactDetails]);

  const form = useForm<TContactDetailsFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(contactDetailsSchema),
    defaultValues,
  });

  const editChangeHandler = useCallback(() => {
    setIsEditing((prev) => !prev);
  }, []);

  const updateContactDetails = useCallback((formData: TContactDetailsFormSchema) => {
    if (!contactId) {
      showErrorToast('System message', `Can not update contact with id: ${contactId}`);
      return;
    }
    updateContact({
      id: contactId,
      ...formData,
    }, {
      onSuccess: () => {
        setIsEditing(false);
      },
    });
  }, [contactId, updateContact]);

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <div className="flex w-full flex-col gap-4">
      <ContactSectionHeader
        title="Basic Details"
        editing={isEditing}
        onEdit={editChangeHandler}
        onCancel={editChangeHandler}
        onSave={form.handleSubmit(updateContactDetails)}
        loading={isPending}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2" onSubmit={form.handleSubmit(updateContactDetails)}>
              <DetailsSectionFormContent />
            </Form>
          )
        : <DetailsSectionContent />}
    </div>
  );
};
