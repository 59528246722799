import { useCallback, useState } from 'react';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { Heading, NoDataAvailable, Text } from '@purple/ui';
import { DistrictFilterComboBox } from '~/components';
import { SafNeedTypesSection } from '~/pages/Main/Districts/Details/components/tabs/SafProcess/SafNeedTypesSection';
import { useDistrict } from '~/services';
import { NeedTypesSkeleton } from './NeedTypesSkeleton';

export const NeedTypes = () => {
  const [selectedDistrictId, setSelectedDistrictId] = useState<string>('');

  const { data: district, isLoading } = useDistrict({ id: selectedDistrictId, enabled: selectedDistrictId.length > 0 });

  const clearSelectedDistrictHandlers = useCallback(() => {
    setSelectedDistrictId('');
  }, []);

  return (
    <section className="flex flex-col gap-6 px-4 py-6">
      <div className="flex items-center justify-between gap-2">
        <DistrictFilterComboBox
          status={DISTRICT_STATUS.PUBLISHED}
          value={selectedDistrictId}
          onChange={setSelectedDistrictId}
          onClear={clearSelectedDistrictHandlers}
          className="w-60"
          placeholder="Select a district"
          limit={25}
        />
      </div>
      {!district && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Heading tag="h3" className="text-title text-base font-medium">Need Types</Heading>
            <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
              Types of needs based on the group of users that will submit the SAF.
            </Text>
          </div>
          {!isLoading && (<NoDataAvailable iconName="folder" iconSize={32} description="Please select a district to manage need types." className="h-96" />)}
          {isLoading && (<NeedTypesSkeleton />)}
        </div>
      )}
      {district && !isLoading && <SafNeedTypesSection district={district} />}
    </section>
  );
};
