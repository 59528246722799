import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { getUserDetailsPermissions } from './get-user-details-permissions';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TUserPermissionListResponse, TUserPermissionRequestParameters } from './user.types';

export const useUserDetailsPermissions = (parameters: TUserPermissionRequestParameters & { userId: string }, queryOptions?: TCustomUseQueryOptions<TUserPermissionListResponse, AxiosError>) => {
  const { isError, error, ...rest } = useQuery({
    ...queryOptions,
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_USER_DETAILS_PERMISSIONS, parameters],
    queryFn: () => getUserDetailsPermissions(parameters),
  });
  useEffect(() => {
    if (isError && isClientError(error)) {
      showErrorToast('System message', 'Unable to retrieve user permissions list');
    }
  }, [isError, error]);

  return { ...rest, isError, error };
};
