import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ADMIN_SUBMITTER_TYPES_QUERY_KEYS } from './submitterTypes.const';
import { updateSubmitterTypeFieldSettings } from './update-submitter-type-field-settings';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TAdminSubmitterTypeFieldSettingsPayload } from './submitterTypes.types';

export const useUpdateSubmitterTypeFieldSettings = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, { id: string; payload: TAdminSubmitterTypeFieldSettingsPayload }>({
    mutationKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.UPDATE_FIELD_SETTINGS],
    mutationFn: updateSubmitterTypeFieldSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.FIELD_SETTINGS] });
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not update a Submitter Field Settings. Check the provided information and try again');
      };
    },
  });
};
