import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ADMIN_SUBMITTER_TYPES_QUERY_KEYS } from './submitterTypes.const';
import { updateSubmitterTypeTextTranslations } from './update-submitter-type-text-translations';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TAdminSubmitterTypeTextTranslationPayload } from './submitterTypes.types';

export const useUpdateSubmitterTypeTextTranslations = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, { id: string; payload: TAdminSubmitterTypeTextTranslationPayload }>({
    mutationKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.UPDATE_TEXT_TRANSLATION],
    mutationFn: updateSubmitterTypeTextTranslations,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_SUBMITTER_TYPES_QUERY_KEYS.TEXT_TRANSLATION] });
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not update a Text Translations. Check the provided information and try again');
      };
    },
  });
};
