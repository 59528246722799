import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardData,
  CardDataCompareValue,
  CardDataDescription,
  CardDataIndicator,
  CardDataValue,
  CardFooter,
  CardTitle,
} from '@purple/ui';
import { AdminRoutes } from '~/constants';
import type { FC } from 'react';
import type { TCardDataFormatterContext } from '@purple/ui';
import type { TDashboardTopStatisticResponse } from '~/services';

type TCardStatisticSectionProperties = {
  statisticData: TDashboardTopStatisticResponse;
  selectedDistrict: string[];
};

export const CardStatisticSection: FC<TCardStatisticSectionProperties> = ({ statisticData, selectedDistrict }) => {
  const { not_logged_in_over_two_weeks, safs_prioritized, this_week_logins } = statisticData;

  const navigate = useNavigate();

  const formatSafsDescription = ({ isIncrease, isDecrease }: TCardDataFormatterContext): string => {
    if (isIncrease) {
      return 'Increase compared to last month';
    }
    if (isDecrease) {
      return 'Decrease compared to last month';
    }
    return 'Stable compared to last month';
  };

  const formatLoginDescription = ({ isIncrease, isDecrease }: TCardDataFormatterContext): string => {
    if (isIncrease) {
      return 'Increase compared to last week';
    }
    if (isDecrease) {
      return 'Decrease compared to last week';
    }
    return '';
  };

  const safPrioritizedReportClickHandler = () => {
    const districtQueryValue = selectedDistrict.join(',');

    navigate(AdminRoutes.App.SafPrioritized.Root.makePath({ queries: { district: districtQueryValue } }), {
      replace: true,
    });
  };

  const notLoggedInReportClickHandler = () => {
    const districtQueryValue = selectedDistrict.join(',');

    navigate(AdminRoutes.App.NotLoggedInReport.Root.makePath({ queries: { district: districtQueryValue } }), {
      replace: true,
    });
  };

  const thisWeeksLoginReportClickHandler = () => {
    const districtQueryValue = selectedDistrict.join(',');

    navigate(AdminRoutes.App.ThisWeeksLogins.Root.makePath({ queries: { district: districtQueryValue } }), {
      replace: true,
    });
  };

  return (
    <ul className="grid grid-cols-1 gap-4 lg:grid-cols-3">
      <Card tag="li">
        <CardTitle>SAFs Prioritized (Under 48 hrs)</CardTitle>
        <CardContent>
          <CardData value={safs_prioritized.current / 100} compareValue={safs_prioritized.past / 100}>
            <CardDataValue applyTrendingColor formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }} />
            <CardDataCompareValue
              applyTrendingColor
              formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
            />
            <CardDataIndicator />
            <CardFooter className="flex items-center justify-between gap-2">
              <CardDataDescription className="w-full" formatter={formatSafsDescription} />
              <Button variant="tertiary" size="small" onClick={safPrioritizedReportClickHandler}>
                View Report
              </Button>
            </CardFooter>
          </CardData>
        </CardContent>
      </Card>
      <Card tag="li">
        <CardTitle>This Week&apos;s Logins (3+)</CardTitle>
        <CardContent>
          <CardData value={this_week_logins.current / 100} compareValue={this_week_logins.past / 100}>
            <CardDataValue applyTrendingColor formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }} />
            <CardDataCompareValue
              applyTrendingColor
              formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
            />
            <CardDataIndicator />
            <CardFooter className="flex items-center justify-between gap-2">
              <CardDataDescription className="w-full" formatter={formatLoginDescription} />
              <Button variant="tertiary" size="small" onClick={thisWeeksLoginReportClickHandler}>
                View Report
              </Button>
            </CardFooter>
          </CardData>
        </CardContent>
      </Card>
      <Card tag="li">
        <CardTitle>Not Logged In Users (over past 2 weeks)</CardTitle>
        <CardContent>
          <CardData value={not_logged_in_over_two_weeks}>
            <CardDataValue formatterOptions={{ maximumFractionDigits: 0 }} />
            <CardFooter className="flex items-center justify-between gap-2">
              <CardDataDescription className="w-full" formatter={formatLoginDescription} />
              <Button variant="tertiary" size="small" onClick={notLoggedInReportClickHandler}>
                View Report
              </Button>
            </CardFooter>
          </CardData>
        </CardContent>
      </Card>
    </ul>
  );
};
