import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { USER_PERMISSION_TYPE } from '~/services';
import type { TFiltersConfig } from '@purple/ui';

export const usePermissionsFilterOptions = () => {
  const filterConfig: TFiltersConfig = {
    categories: [
      {
        label: 'Constructed From',
        value: 'constructed_from',
        filters: Object.values(USER_PERMISSION_TYPE).map((type) => ({
          label: snakeToCamelWithSpaces(type),
          value: type,
        })),
      },
    ],
  };

  return {
    filterConfig,
  };
};
