import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TAdminUsersFrequencyDistributionReportParameters } from './user.types';

export const getFrequencyDistributionReportExport = async (requestParameters: TAdminUsersFrequencyDistributionReportParameters) => {
  const response = await axiosInstance.get<Blob>(ADMIN_USER_MANAGEMENT.FREQUENCY_DISTRIBUTION_REPORT_EXPORT, {
    params: requestParameters,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response.data;
};
