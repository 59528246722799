import axiosInstance from '../axios-config';
import { SAF_ENDPOINTS } from '../endpoints';
import type { TBulkUploadResponse } from '@purple/shared-types';
import type { TBulkSafRoutingRulesErrorResponse, TBulkSafRoutingRulesRPayload } from './saf.types';

export const createBulkRoutingRules = async (payload: TBulkSafRoutingRulesRPayload) => {
  const formData = new FormData();
  formData.append('uploaded_file', payload.uploaded_file);
  formData.append('district', payload.district);
  for (const school of payload.schools) {
    formData.append('schools', school);
  };
  const response = await axiosInstance.post<TBulkUploadResponse<TBulkSafRoutingRulesErrorResponse>>(SAF_ENDPOINTS.BULK_IMPORT_SAF_ROUTING_RULES, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
