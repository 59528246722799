import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { getActivityHistory } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActivityHistoryRequestParameters, TActivityHistoryResponse } from '../activities.types';

export const useActivityHistory = (
  parameters: TActivityHistoryRequestParameters,
  queryOptions?: TCustomUseQueryOptions<TActivityHistoryResponse, AxiosError>,
) => {
  const queryData = useQuery({
    queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_HISTORY, parameters],
    queryFn: () => getActivityHistory(parameters),
    ...queryOptions,
  });

  useEffect(() => {
    if (queryData.isError && isClientError(queryData.error)) {
      showErrorToast('System message', 'Unable to retrieve activity history');
    }
  }, [queryData.isError, queryData.error]);

  return queryData;
};
