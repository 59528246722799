import { useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { Typography } from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { ACCEPTED_EMAIL_FILE_TYPES, MAX_EMAIL_FILES_SIZE } from '@purple/shared-utils';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  FileItem,
  FileList,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ScrollArea,
  Separator,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useSendEmailToContacts } from '~/services';
import { EditorToolbar } from './EditorToolbar';
import type React from 'react';

const sendEmailSchema = z.object({
  subject: z
    .string()
    .trim()
    .min(1, 'Email subject is required')
    .max(78, 'Email subject should not exceed 78 characters'),
  text: z.string().trim().min(1, 'Email text is required'),
  files: z
    .array(z.custom<File>((value) => value instanceof File, { message: 'Invalid file type' }))
    .refine((files) => files.reduce((accumulator, file) => accumulator + file.size, 0) <= MAX_EMAIL_FILES_SIZE, {
      message: `Total file size should not exceed ${MAX_EMAIL_FILES_SIZE / 1024 / 1024} MB`,
    })
    .refine((files) => files.every((file) => ACCEPTED_EMAIL_FILE_TYPES.has(file.type)), {
      message: 'Only images, .pdf, .doc, .docx, .xlsx, .xls, .csv, .ppt and .pptx formats are supported',
    })
    .optional(),
});

type TFormSchema = z.infer<typeof sendEmailSchema>;

type TSendEmailDialogProps = {
  /**
   * List of contact emails to send the email to
   * Provide either `contacts` or `priorityListId`
   */
  contacts?: string[];
  /**
   * Priority list ID to send the email to
   * Provide either `contacts` or `priorityListId`
   */
  priorityListId?: string | null;
};

export const SendEmailDialog: React.FC<TSendEmailDialogProps> = (props) => {
  const { contacts, priorityListId = null } = props;

  const formId = useId();

  const { isOpen, toggleModal } = useModal(ModalType.SEND_EMAIL_TO_CONTACTS);

  const { mutate: sendEmail, isPending: isSendingPending } = useSendEmailToContacts();

  const defaultValues = useMemo(
    () => ({
      subject: '',
      text: '',
      files: [],
    }),
    [],
  );

  const form = useForm<TFormSchema>({
    resolver: zodResolver(sendEmailSchema),
    mode: 'onChange',
    defaultValues,
  });
  const files = form.watch('files') ?? [];

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your message here...',
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: form.getValues('text'),
    onUpdate: ({ editor }) => {
      const isEmpty = editor.getText().trim().length === 0;
      form.setValue('text', isEmpty ? '' : JSON.stringify(editor.getJSON()), { shouldValidate: true });
    },
  });

  const closeEmailDialog = () => {
    toggleModal(false);
    form.reset(defaultValues);
    editorConfig?.commands.setContent('');
  };

  const dialogOpenChangeHandler = (open: boolean) => {
    if (open) {
      toggleModal(true);
    } else {
      closeEmailDialog();
    }
  };

  const sendEmailClickHandler = (data: TFormSchema) => {
    sendEmail(
      {
        subject: data.subject,
        message_html: editorConfig?.getHTML() ?? '',
        message_json: JSON.stringify(editorConfig?.getJSON()),
        attachments: data.files ?? [],
        ...(priorityListId && { contact_priority_list: priorityListId }),
        ...(contacts && contacts.length > 0 && priorityListId === null && { contacts }),
      },
      {
        onSuccess: () => {
          closeEmailDialog();
        },
      },
    );
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      form.setValue('files', [...event.target.files]);
    }
  };

  const removeFileClickHandler = (filename: string) => {
    form.setValue(
      'files',
      files.filter((file) => file.name !== filename),
      { shouldValidate: true },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={dialogOpenChangeHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-full max-w-[840px] flex-col">
        <DialogHeader className="flex-row items-center justify-between gap-2">
          <div className="flex w-full flex-col gap-1">
            <DialogTitle className="text-left text-lg font-semibold text-grey-title">
              {priorityListId ? 'Send Email to All Priority List Contacts' : 'Send Email to Selected Contacts'}
            </DialogTitle>
            <DialogDescription className="sr-only">
              This dialog allows you to send an email to selected contacts or all contacts in a priority list.
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea
          type="auto"
          className="flex size-full max-h-[640px] min-h-[160px] flex-col px-6 py-4"
          scrollBarClassName="p-2 w-[22px]"
        >
          <Form
            providerProps={form}
            id={formId}
            className="flex w-full flex-col gap-4"
            onSubmit={form.handleSubmit(sendEmailClickHandler)}
          >
            <FormField
              control={form.control}
              name="subject"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel required>Subject</FormLabel>
                  <FormControl>
                    <Input {...field} isError={!!fieldState.error} placeholder="Enter subject here" type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="text"
              render={({ field, fieldState }) => (
                <FormItem className="space-y-2">
                  <FormLabel required>Email Body</FormLabel>
                  <EditorToolbar editor={editorConfig} onFilesChange={fileChangeHandler} />
                  {files.length > 0 && (
                    <FileList>
                      {files.map((file) => (
                        <FileItem
                          key={file.name}
                          filename={file.name}
                          fileSize={file.size}
                          onRemove={removeFileClickHandler}
                        />
                      ))}
                    </FileList>
                  )}
                  {form.formState.errors.files && (
                    <Text className="font-primary text-xs font-normal text-error-main">
                      {form.formState.errors.files.message}
                    </Text>
                  )}
                  <FormControl>
                    <EditorContent
                      ref={field.ref}
                      editor={editorConfig}
                      className={cn(
                        'minimal-tiptap-editor flex h-full min-h-[260px] w-full cursor-text flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 focus-within:border-brand-blue-700 hover:border-brand-blue-700 focus:border-brand-blue-700',
                        {
                          'border-error-main': !!fieldState.error,
                        },
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </Form>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button type="button" variant="tertiary" onClick={closeEmailDialog}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" form={formId} isLoading={isSendingPending}>
            {isSendingPending ? 'Sending...' : 'Send'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
