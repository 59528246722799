import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader, Text } from '@purple/ui';
import { type TUserBasicInfo, useUpdateUserBasicDetails } from '~/services';
import { BasicDetailEdit } from './BasicDetailEdit';
import { BasicDetailView } from './BasicDetailView';
import { updateBasicDetailSchema } from './updateBasicDetailSchema';
import type * as z from 'zod';

type TBasicDetailAreaProperties = {
  data: TUserBasicInfo;
};

const BasicDetailArea = ({ data }: TBasicDetailAreaProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateUserBasicDetails();

  const defaultValues = useMemo(() => {
    const { first_name, last_name, roles, phone_number, title, department, responsible_for_grades, is_show_on_scorecard } = data;

    return {
      first_name,
      last_name,
      roles: roles.map((role) => ({ label: role.name, value: role.id.toString() })),
      phone_number,
      title: title || '',
      department,
      responsible_for_grades,
      is_show_on_scorecard,
    };
  }, [data]);

  const form = useForm<z.infer<typeof updateBasicDetailSchema>>({
    resolver: zodResolver(updateBasicDetailSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const handleSave = (newFormData: z.infer<typeof updateBasicDetailSchema>) => {
    const payload = {
      first_name: newFormData.first_name,
      last_name: newFormData.last_name,
      phone_number: newFormData.phone_number,
      title: newFormData.title,
      department: newFormData.department,
      responsible_for_grades: newFormData.responsible_for_grades,
      is_show_on_scorecard: newFormData.is_show_on_scorecard,
      prounitas_roles: newFormData?.roles?.map((role) => Number(role.value)) || [],
    };

    mutate({
      userId: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
        title={<Text variant="size-16" type="body-500" className="text-grey-title">Basic Details</Text>}
      />
      {mode === 'view' ? <BasicDetailView data={data} /> : <BasicDetailEdit data={data} form={form} />}
    </div>
  );
};

export { BasicDetailArea };
