import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { deleteActivity } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useDeleteActivity = (
  options?: TCustomUseMutationOptions<unknown, AxiosError, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [ACTIVITIES_QUERY_KEYS.DELETE_ACTIVITY],
    mutationFn: deleteActivity,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_LIST] });
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_INSTANCES_COUNT] });
      showSuccessToast('System message', 'Activity deleted successfully');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to delete activity');
      options?.onError?.(...args);
    },
  });
};
