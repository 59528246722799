import { useFormContext } from 'react-hook-form';
import { useMask } from '@react-input/mask';
import { GRADE_CHOICES } from '@purple/shared-types';
import { CONTAIN_DIGIT_REGEX, cutGradeName, mergeReferences } from '@purple/shared-utils';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Input, MultiSelect, MultiSelectItem, Text } from '@purple/ui';
import { PHONE_MASK } from '~/constants';
import type { TAddInvitePurpleUserFormValues } from './schema';

export const GeneralInformationForm = () => {
  const { control } = useFormContext<TAddInvitePurpleUserFormValues>();

  const inputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  return (
    <div className="grid grid-cols-2 gap-4">
      <Text variant="size-16" type="body-600" className="col-span-2 text-grey-title">General Information</Text>
      <FormField
        control={control}
        name="full_name"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Full Name</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter full name " isError={!!fieldState.error} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="email"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Email</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter email" isError={!!fieldState.error} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="phone_number"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel>Phone</FormLabel>
            <FormControl>
              <Input
                {...field}
                ref={mergeReferences([field.ref, inputReference])}
                isError={!!fieldState.error}
                placeholder={PHONE_MASK}
                type="tel"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="department"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Department</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter department" isError={!!fieldState.error} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="title"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Title/Position</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter Title/Position" isError={!!fieldState.error} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="responsible_for_grades"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Grade</FormLabel>
            <FormControl>
              <MultiSelect
                {...field}
                isError={!!fieldState.error}
                selectedOptions={Object.values(GRADE_CHOICES).map((grade) => ({ label: grade, value: grade })).filter((option) => field?.value?.includes(option.value))}
                placeholder="Select grades"
                classNames={{ trigger: 'min-h-9 py-1' }}
                modalPopover
                onOptionChange={field.onChange}
                maxCount={2}
              >
                {Object.values(GRADE_CHOICES).map((value) => (
                  <MultiSelectItem
                    key={value}
                    value={value}
                    option={{ label: cutGradeName(value), value }}
                    isSelected={field.value?.includes(value)}
                  />
                ))}
              </MultiSelect>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
