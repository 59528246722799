import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { formatHolidayDate } from '@purple/shared-utils';
import {
  Button,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormField,
  FormMessage,
} from '@purple/ui';
import { useUpdateDistrictBasicDetails } from '~/services';
import { DistrictSectionHeader } from '../../DistrictSectionHeader';
import { HolidayFieldItem } from './HolidayFieldItem';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

const holidaysSchema = z
  .object({
    holidays: z.array(
      z.object({
        id: z.number(),
        name: z.string().trim().min(1, {
          message: 'Holiday name is required.',
        }),
        is_recurring: z.boolean(),
        start_datetime: z.date(),
        end_datetime: z.date(),
      }),
    ),
  })
  .superRefine((data, context) => {
    for (const [index, holiday] of data.holidays.entries()) {
      if (holiday.end_datetime < holiday.start_datetime) {
        context.addIssue({
          path: ['holidays', index, 'end_datetime'],
          code: z.ZodIssueCode.custom,
          message: 'End date cannot be earlier than start date.',
        });
      }
    }
  });

type TDistrictHolidaysSectionProperties = {
  district: TDistrictDetails;
};

export const DistrictHolidaysSection: React.FC<TDistrictHolidaysSectionProperties> = (props) => {
  const {
    district: { id, holidays = [] },
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateDistrict, isPending } = useUpdateDistrictBasicDetails();

  const defaultValues: z.infer<typeof holidaysSchema> = useMemo(
    () => ({
      holidays: Array.isArray(holidays)
        ? holidays.map((item) => ({
            ...item,
            end_datetime: new Date(item.end_datetime),
            start_datetime: new Date(item.start_datetime),
          }))
        : [],
    }),
    [holidays],
  );

  const form = useForm<z.infer<typeof holidaysSchema>>({
    resolver: zodResolver(holidaysSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: 'holidays',
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const removeHolidayClickHandler = (index: number) => {
    remove(index);
  };

  const addHolidayClickHandler = () => {
    append({
      id: Date.now(),
      name: '',
      is_recurring: false,
      start_datetime: new Date(),
      end_datetime: new Date(new Date().setDate(new Date().getDate() + 1)),
    });
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof holidaysSchema>) => {
    const updatedHolidays = formData.holidays.map((item) => {
      const targetHoliday = holidays?.find((holiday) => holiday.id === item.id);
      const { id: _id, ...itemWithoutId } = item;
      const result = targetHoliday ? item : itemWithoutId;
      return {
        ...result,
        start_datetime: result.start_datetime.toISOString(),
        end_datetime: result.end_datetime.toISOString(),
      };
    });
    updateDistrict(
      {
        id,
        holidays: updatedHolidays,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <DistrictSectionHeader
        title="Holidays"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-3">
              {fields.map((field, index) => (
                <HolidayFieldItem<z.infer<typeof holidaysSchema>>
                  key={field.id}
                  sequence={index + 1}
                  control={form.control}
                  name={`holidays.${index}.name`}
                  startDateName={`holidays.${index}.start_datetime`}
                  endDateName={`holidays.${index}.end_datetime`}
                  isRecurringName={`holidays.${index}.is_recurring`}
                  disableRemove={fields.length === 1}
                  onRemove={() => removeHolidayClickHandler(index)}
                />
              ))}
              <FormField name={'holidays.root' as 'holidays'} control={form.control} render={() => <FormMessage />} />
              <Button
                type="button"
                variant="tertiary"
                size="small"
                className="w-fit"
                iconLeft={<PurpleIcon name="plus" />}
                onClick={addHolidayClickHandler}
              >
                Add more Holidays / School Closures
              </Button>
            </Form>
          )
        : (
            <DescriptionList className="gap-2">
              <DescriptionItem className="flex-nowrap items-start">
                <DescriptionTerm className="w-1/2 shrink-0">Holidays & School Closures</DescriptionTerm>
                <DescriptionDetails className="inline-flex w-1/2 flex-col gap-0.5">
                  {holidays.map((item) => (
                    <span key={item.id} className="inline-block min-h-6">
                      {formatHolidayDate(item.name, item.start_datetime, item.end_datetime)}
                    </span>
                  ))}
                </DescriptionDetails>
              </DescriptionItem>
            </DescriptionList>
          )}
    </div>
  );
};
