import { useQueryParameter } from '@purple/hooks';
import { AccessDenied } from '@purple/ui';
import { UsersPrintView } from './components';
import { ContactsPrintView } from './components/ContactsPrintView';
import { SafPrintView } from './components/SafPrintView';
import { PRINT_OPTIONS } from './constants';
import type { FC } from 'react';
import type { TPrintOptions } from './constants';

export const Print: FC = () => {
  const { query: variant } = useQueryParameter<TPrintOptions>({
    queryName: 'variant',
  });

  if (variant === PRINT_OPTIONS.USERS_LIST) return <UsersPrintView />;

  if (variant === PRINT_OPTIONS.SAF_LIST) return <SafPrintView />;

  if (variant === PRINT_OPTIONS.CONTACTS_LIST) return <ContactsPrintView />;

  return (
    <div className="flex h-screen items-center justify-center">
      <AccessDenied />
    </div>
  );
};
