import { BulkFileErrorTableCell, DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TakeActionType } from '@purple/shared-types';
import type { TActionBulkUploadResponse } from '~/services';

export const crisisResponseColumns: ColumnDef<TActionBulkUploadResponse<typeof TakeActionType.CRISIS_RESPONSE_SESSION>>[] = [
  {
    accessorKey: 'row',
    header: ({ column }) => <DataTableColumnHeader column={column} title="#" className="px-2" />,
    cell: ({ row }) => (
      <div className="px-2 py-3">
        <span>{row.original.row}</span>
      </div>
    ),
    size: 50,
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'document_as',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Document As" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.document_as} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'type',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Type" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.type} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'date_and_time',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date & Time" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.date_and_time} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'duration',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Duration(Minutes)" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.duration} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'crisis_types',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Crisis Types" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.crisis_types} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'is_law_enforcement_notified',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Law Enforcement Notified" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.is_law_enforcement_notified} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'is_CPS_notified',
    header: ({ column }) => <DataTableColumnHeader column={column} title="CPS Notified" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.is_CPS_notified} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'is_parent_caregiver_notified',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Parent/Caregiver Notified" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.is_parent_caregiver_notified} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'parent_caregiver_conversation_summary',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Parent/Caregiver Conversation Summary" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.parent_caregiver_conversation_summary} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'is_administration_notified',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Administration Notified" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.is_administration_notified} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'your_consultants',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Your Consultants" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.your_consultants} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'who_was_consulted',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Who was Consulted" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.who_was_consulted} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'is_threat_assessment_administered',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Threat Assessment Administered" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.is_threat_assessment_administered} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'threat_assessment_results',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Threat Assessment Result" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.threat_assessment_results} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'risk_level_result_explanation',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Risk Level Result Explanation" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.risk_level_result_explanation} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'is_parent_caregiver_notified_of_assessment',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Parent/Caregiver Notified of Assessment" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.is_parent_caregiver_notified_of_assessment} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'is_weapons_accessible_to_the_individual',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Weapons Accessible to this Individual" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.is_weapons_accessible_to_the_individual} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'follow_up_plan',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Follow Up Plan" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.follow_up_plan} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'students',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Connected Students" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.students} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
];
