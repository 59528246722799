import { useEffect, useMemo, useState } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { cn, Heading, Text } from '@purple/ui';
import { LinkBubbleMenu } from '~/pages/Main/Districts/Details/components/LinkBubbleMenu';
import { SafEditorToolbar } from '~/pages/Main/Districts/Details/components/SafEditorToolbar';
import { SafLinkExtention } from '~/pages/Main/Districts/Details/components/SafLinkExtention';
import type { JSONContent } from '@tiptap/core';
import type { TAdminTranslation } from '@purple/shared-types';

const DEFAULT_EXTENSIONS = [
  StarterKit.configure({
    horizontalRule: false,
    codeBlock: false,
    paragraph: {
      HTMLAttributes: {
        class: 'text-node',
      },
    },
    bulletList: {
      HTMLAttributes: {
        class: 'list-node',
      },
    },
    orderedList: {
      HTMLAttributes: {
        class: 'list-node',
      },
    },
    dropcursor: {
      width: 2,
      class: 'ProseMirror-dropcursor border',
    },
  }),
  Underline,
  Typography,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  SafLinkExtention.configure({
    autolink: true,
    defaultProtocol: 'https',
  }),
  Placeholder.configure({
    placeholder: () => 'Enter your text here...',
  }),
];

const EDITOR_PROPS = {
  attributes: {
    role: 'textbox',
    class: 'focus:outline-none h-full',
  },
};

type TRichTextAreaProperties = {
  label: string;
  description: string;
  data: TAdminTranslation;
  onChange: (data: TAdminTranslation) => void;
};

const RichTextArea = ({ label, description, data, onChange }: TRichTextAreaProperties) => {
  const [editableText, setEditableText] = useState<TAdminTranslation>(data);

  useEffect(() => {
    setEditableText(data);
  }, [data]);

  const contentData = useMemo(() => {
    return data.en;
  }, [data]);

  const updateEditableData = (value: JSONContent) => {
    if (!data) return;

    const updatedLanguage = {
      ...data,
      en: value as unknown as string,
    };
    setEditableText(updatedLanguage);
  };

  const editorConfig = useEditor({
    extensions: DEFAULT_EXTENSIONS,
    editorProps: EDITOR_PROPS,
    content: contentData,
    editable: true,
    onUpdate: ({ editor }) => {
      const jsonValue = editor.getJSON();
      updateEditableData(jsonValue);
    },
    onBlur: () => {
      onChange(editableText);
    },
  });

  useEffect(() => {
    const text = data?.en;

    if (text === null || text === undefined) {
      editorConfig?.commands.setContent('');
    } else {
      editorConfig?.commands.setContent(text);
    }
  }, [data]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <Heading variant="size-18" type="heading-500" className="text-grey-950">
          {label}
        </Heading>
        <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
          {description}
        </Text>
      </div>

      <div className="flex flex-col space-y-2">
        <SafEditorToolbar editor={editorConfig} />
        <EditorContent
          editor={editorConfig}
          className={cn(
            'minimal-tiptap-editor flex h-full min-h-[80px] w-full cursor-text flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 focus-within:border-brand-blue-700 hover:border-brand-blue-700 focus:border-brand-blue-700',
          )}
        />
        {editorConfig && <LinkBubbleMenu editor={editorConfig} />}
      </div>
    </div>
  );
};

export { RichTextArea };
