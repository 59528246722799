import { useMemo } from 'react';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { useActionsListFilterOptions } from '~/services/actions';
import type { TFiltersConfig } from '@purple/ui';

export const useAllActionsFilterOptions = (districtId?: string) => {
  const { data: filterOptions, isLoading } = useActionsListFilterOptions(
    { district: districtId },
  );

  const filterConfig: TFiltersConfig = useMemo(() => ({
    categories: [
      {
        label: 'Type',
        value: 'type',
        filters: filterOptions?.type.map((type) => ({
          label: snakeToCamelWithSpaces(type),
          value: type,
        })) || [],
      },
      {
        label: 'Creator',
        value: 'creator',
        filters: filterOptions?.creator.map((creator) => ({
          label: creator.full_name || '',
          value: creator.id,
        })) || [],
      },
      {
        label: 'School',
        value: 'school',
        filters: filterOptions?.school.map((school) => ({
          label: school.name || '',
          value: school.id,
        })) || [],
      },
    ],
  }), [filterOptions?.creator, filterOptions?.school, filterOptions?.type]);

  return { filterConfig, isLoading };
};
