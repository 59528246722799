import { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { SAF_AREA_SUBCATEGORIES_LABELS } from '@purple/shared-types';
import { isFieldExist } from '@purple/shared-utils';
import { Button, DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { type TActivityDetails, useActivityChoices } from '~/services';
import type React from 'react';
import type { TCrisisActivityType } from '@purple/shared-types';

type TActivityDetailsInfoProperties = {
  data: TActivityDetails;
};

export const BasicDetailsInfo: React.FC<TActivityDetailsInfoProperties> = (props) => {
  const { data } = props;
  const { id, details } = data;

  const hasChoicesFired = useRef<boolean>(false);

  const {
    isIdle,
    mutate: fetchActivityChoices,
    typeOptions,
    stakeholdersServedOptions,
    gradeLevelsOptions,
    campusContactTitlesOptions,
    crisisGradesOptions,
    crisisTypesOptions,
  } = useActivityChoices({
    id,
    community_activity_type: details.community_activity_type.name,
  });
  const [readableType] = useMemo(
    () => typeOptions.filter((option) => option.value === details.type).map((option) => option.label),
    [details.type, typeOptions],
  );

  useEffect(() => {
    if (isIdle && !hasChoicesFired.current) {
      fetchActivityChoices({});
      hasChoicesFired.current = true;
    }
  }, [isIdle, fetchActivityChoices]);

  return (
    <DescriptionList className="gap-2">
      {isFieldExist(details.type) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Type</DescriptionTerm>
          <DescriptionDetails className="m-0">{readableType}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.topic) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Topic</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.topic ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.crisis_type) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Crisis Types</DescriptionTerm>
          <DescriptionDetails className="m-0">
            {crisisTypesOptions
              .filter((item) => details.crisis_type?.includes(item.value as TCrisisActivityType))
              .map((item) => item.label)
              .join(', ')}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.school) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>School</DescriptionTerm>
          <DescriptionDetails className="m-0 w-fit">
            <Button asChild variant="link" size="link" className="line-clamp-1 size-auto min-w-0 break-all text-base">
              <Link to={AdminRoutes.App.Schools.Details.makePath({ dynamicParameters: { schoolId: details.school.id } })}>
                {details.school.name}
              </Link>
            </Button>
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.campus_contact_title) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Campus Contact Title</DescriptionTerm>
          <DescriptionDetails className="m-0">
            {campusContactTitlesOptions.find((option) => option.value === details.campus_contact_title)?.label
            ?? details.campus_contact_title}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.campus_contact_name) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Campus Contact Name</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.campus_contact_name}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.contact_email) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Contact Email</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.contact_email}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.contact_phone_number) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Contact Phone Number</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.contact_phone_number}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.crisis_grade_level) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Crisis Grade Level</DescriptionTerm>
          <DescriptionDetails className="m-0">
            {crisisGradesOptions
              .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))
              .find((option) => option.value === details.crisis_grade_level)
              ?.label ?? details.crisis_grade_level}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.assigned_to) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Assigned to</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.assigned_to?.full_name ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.stakeholders_served) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Stakeholders Served</DescriptionTerm>
          <DescriptionDetails className="m-0">
            {stakeholdersServedOptions
              .filter((item) => details.stakeholders_served?.includes(item.value))
              .map((item) => item.label)
              .join(', ')}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.service_categories) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Service Categories</DescriptionTerm>
          <DescriptionDetails className="m-0">
            {details.service_categories.map((service) => SAF_AREA_SUBCATEGORIES_LABELS[service.name]).join(', ')}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.number_of_people_served) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Number of People Served</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.number_of_people_served}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.quantity_of_resources_provided) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Quantity of Resources Provided</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.quantity_of_resources_provided ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.bags_provided_count) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Quantity of Bags Provided</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.bags_provided_count}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.supported_school) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Supported School</DescriptionTerm>
          <DescriptionDetails className="m-0 w-fit">
            {details.supported_school === null
              ? (
                  '-'
                )
              : (
                  <Button asChild variant="link" size="link" className="line-clamp-1 size-auto min-w-0 break-all text-base">
                    <Link to={AdminRoutes.App.Schools.Details.makePath({ dynamicParameters: { schoolId: details.supported_school.id } })}>
                      {details.supported_school.name}
                    </Link>
                  </Button>
                )}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.grade_level) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Grade Level</DescriptionTerm>
          <DescriptionDetails className="m-0">
            {gradeLevelsOptions
              .filter((item) => details.grade_level?.includes(item.value))
              .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))
              .map((grade) => grade.label)
              .join(', ') || '-'}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.participants_count) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Number of Participants</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.participants_count ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.session_participant) && (
        <DescriptionItem className="grid grid-cols-2">
          <DescriptionTerm>Session Participant</DescriptionTerm>
          <DescriptionDetails className="m-0">{details.session_participant?.full_name ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
    </DescriptionList>
  );
};
