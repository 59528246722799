import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { FILE_QUERY_KEYS } from './files.const';
import { getFiles } from './get-files';
import type { AxiosError } from 'axios';
import type { TFileListQueryParameters, TFileListResponse } from '@purple/shared-types';
import type { TCustomUseQueryOptions } from '~/types/query';

export const useFiles = (
  parameters?: TFileListQueryParameters,
  queryOptions?: TCustomUseQueryOptions<TFileListResponse, AxiosError>,
) => {
  const { isError, ...rest } = useQuery({
    queryKey: [FILE_QUERY_KEYS.GET_FILES_LIST, parameters],
    queryFn: () => getFiles(parameters),
    ...queryOptions,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'An error occurred while fetching list of files');
    }
  }, [isError]);

  return { isError, ...rest };
};
