export const ADMIN_SAF_QUERY_KEYS = {
  CREATE_SAF_ROUTING_RULE: 'create-saf-routing-rule',
  EDIT_SAF_ROUTING_RULE: 'edit-saf-routing-rule',
  LIST_SAF_ROUTING_RULE: 'list-saf-routing-rule',
  SAF_SERVICE_CATEGORIES: 'saf-service-categories',
  UPDATE_SAF_DETAILS: 'update-saf-details',
  SAF_ROUTING_RULE_DETAIL: 'saf-routing-rule-detail',
  LIST_SAF_ROUTING_RULE_MUTATION: 'list-saf-routing-rule-mutation',
  LIST: 'list-safs',
  LIST_FILTERS: 'list-safs-filters',
  LIST_EXPORT: 'list-safs-export',
  LIST_MUTATION: 'list-safs-mutation',
  DETAIL: 'saf-detail',
  BASIC_DETAILS: 'saf-basic-details',
  HISTORY: 'saf-history',
  BULK_DELETE: 'bulk-delete-safs',
  BULK_RULE_DELETE: 'bulk-rule-delete-safs',
  SAF_EMAILS_LIST: 'saf-emails-list',
  SAF_EMAIL_DETAIL: 'saf-email-detail',
  SAF_RULE_LIST_FILTERS: 'saf-rule-list-filters',
  SAF_TEXT_TRANSLATIONS: 'saf-text-translations',
  BULK_UPLOAD_SAF_ROUTING_RULES: 'bulk-upload-saf-routing-rules',
} as const;
