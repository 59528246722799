import * as z from 'zod';
import { TakeActionType } from '@purple/shared-types';

export const ALLOWED_FILE_TYPES = new Set([
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
]);

export const BULK_DROPZONE_ALLOWED_FILE_TYPES = {
  'text/csv': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

export const bulkUploadActions = z.object({
  district: z.string().trim().min(1, { message: 'Please select a district' }),
  record_action_type: z.nativeEnum(TakeActionType, { message: 'Please action type' }),
  file: z
    .array(
      z.instanceof(File),
    )
    .length(1, { message: 'Only one file in xlsx or csv format with a maximum size of 10MB is allowed' }),
});

export type TBulkUploadActionsSchema = z.infer<typeof bulkUploadActions>;
