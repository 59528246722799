import * as z from 'zod';
import { ContactType } from '@purple/shared-types';
import { multiSelectOptions, onlyLettersWithSpacesRegex } from '@purple/shared-utils';

export const addContactSchema = z.object({
  contact_type: z.nativeEnum(ContactType, { message: 'Please select a contact type' }),
  first_name: z.string().trim().min(1, { message: 'Please enter a first name' }).regex(onlyLettersWithSpacesRegex, { message: 'First name can only contain letters' }),
  last_name: z.string().trim().min(1, { message: 'Please enter a last name' }).regex(onlyLettersWithSpacesRegex, { message: 'Last name can only contain letters' }),
  email: z.string().trim().min(1, { message: 'Please enter an email' }).email({ message: 'Please enter a valid email' }),
  phone: z.string().trim().optional(),
  district: z.string().trim().min(1, { message: 'Please select a district' }),
  country: z.string().trim().optional(),
  street: z.string().trim().optional(),
  city: z.string().trim().regex(onlyLettersWithSpacesRegex, { message: 'City can only contain letters' }).optional(),
  state: z.string().trim().optional(),
  postal_code: z.string().trim().optional(),
  students: multiSelectOptions.optional(),
}).superRefine((data, context) => {
  if (data.contact_type === ContactType.PARENT_GUARDIAN && !data.students?.length) {
    context.addIssue({
      path: ['students'],
      code: z.ZodIssueCode.custom,
      message: 'Please select at least one student',
    });
  }
});

export type TAddContactFormValues = z.infer<typeof addContactSchema>;
