import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { nanoid } from '@reduxjs/toolkit';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { SafSubmitterType } from '@purple/shared-types';
import { Button, Form, FormField, FormMessage, Text, ToggleGroup, ToggleGroupItem } from '@purple/ui';
import { ReadableSubmitterOptions, SubmitterOptions, type TSubmitterOptions } from '~/pages/Main/Districts/constants';
import { useUpdateDistrictSafProcess } from '~/services';
import { DistrictSectionHeader } from '../../DistrictSectionHeader';
import { SafNeedTypeFormItem } from './SafNeedTypeFormItem';
import type React from 'react';
import type { FieldArrayWithId } from 'react-hook-form';
import type { TDistrictDetails } from '~/services';

const sectionSchema = z
  .object({
    needs: z.array(
      z.object({
        id: z.string(),
        name: z.string().trim().min(1, {
          message: 'Need name is required.',
        }),
        submitter_types: z.array(z.nativeEnum(SafSubmitterType)),
      }),
    ),
  })
  .superRefine((data, context) => {
    if (data.needs.length === 0) {
      context.addIssue({
        path: ['needs'],
        code: z.ZodIssueCode.custom,
        message: 'At least one need type is required.',
      });
    }
    if (
      Object.values(SafSubmitterType).some((type) => data.needs.every((need) => !need.submitter_types.includes(type)))
    ) {
      context.addIssue({
        path: ['needs'],
        code: z.ZodIssueCode.custom,
        message: 'At least one submitter type is required for each need type.',
      });
    }
  });

type TSafNeedTypesSectionProperties = {
  district: TDistrictDetails;
};

export const SafNeedTypesSection: React.FC<TSafNeedTypesSectionProperties> = (props) => {
  const {
    district: { id, needs },
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState<TSubmitterOptions>(SubmitterOptions.ALL);
  const { mutate: updateDistrictSafProcess, isPending } = useUpdateDistrictSafProcess();

  const defaultValues: z.infer<typeof sectionSchema> = useMemo(
    () => ({
      needs: Array.isArray(needs) ? needs : [],
    }),
    [needs],
  );

  const form = useForm<z.infer<typeof sectionSchema>>({
    resolver: zodResolver(sectionSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: 'needs',
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof sectionSchema>) => {
    const updatedNeeds = formData.needs.map((item) => {
      const targetNeed = needs?.find((need) => need.id === item.id);
      const { id: _id, ...itemWithoutId } = item;
      return targetNeed ? item : itemWithoutId;
    });
    updateDistrictSafProcess(
      {
        id,
        needs: updatedNeeds,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  const optionChangeHandler = (option: string) => {
    if (option === selectedOption || option === '') return;
    setSelectedOption(option as TSubmitterOptions);
  };

  const needsItemSelectChangeHandler = (
    index: number,
    field: FieldArrayWithId<z.infer<typeof sectionSchema>, 'needs'>,
    value: boolean,
  ) => {
    const updatedTypes
      = selectedOption === SubmitterOptions.ALL && value
        ? Object.values(SafSubmitterType)
        : selectedOption === SubmitterOptions.ALL && !value
          ? []
          : selectedOption !== SubmitterOptions.ALL && value
            ? [...field.submitter_types, selectedOption]
            : selectedOption !== SubmitterOptions.ALL && !value
              ? field.submitter_types.filter((type) => type !== selectedOption)
              : field.submitter_types;

    const targetField = form.getValues('needs')[index] ?? field;

    update(index, {
      ...targetField,
      submitter_types: updatedTypes,
    });
  };

  const removeNeedItemHandler = (index: number) => {
    remove(index);
  };

  const addNeedItemClickHandler = () => {
    append({
      id: nanoid(),
      name: `Need ${fields.length + 1}`,
      submitter_types: selectedOption === SubmitterOptions.ALL ? Object.values(SafSubmitterType) : [selectedOption],
    });
  };

  return (
    <section className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-1">
        <DistrictSectionHeader
          title="Need Types"
          editing={isEditing}
          loading={isPending}
          onCancel={cancelClickHandler}
          onEdit={editClickHandler}
          onSave={form.handleSubmit(saveDetailsClickHandler)}
        />
        <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
          Types of needs based on the group of users that will submit the SAF.
        </Text>
      </div>
      <ToggleGroup
        type="single"
        className="w-fit items-stretch justify-stretch gap-0 overflow-hidden rounded-lg border border-grey-200"
        value={selectedOption}
        onValueChange={optionChangeHandler}
      >
        {Object.values(SubmitterOptions).map((submitterType) => (
          <ToggleGroupItem
            key={submitterType}
            value={submitterType}
            variant="default"
            size="default"
            className="h-auto min-h-9 shrink grow items-center justify-center break-all rounded-none border-r border-grey-200 px-4 py-2 font-primary text-sm font-medium text-grey-950 last-of-type:border-0 hover:bg-brand-blue-100 hover:text-grey-950 focus:bg-brand-blue-100 focus:text-grey-950 focus-visible:bg-brand-blue-100 focus-visible:text-grey-950 active:bg-brand-blue-50 data-[state='on']:!bg-brand-blue-700 data-[state='on']:font-semibold data-[state='on']:!text-white data-[state='on']:hover:bg-brand-blue-600 data-[state='on']:focus:bg-brand-blue-600 data-[state='on']:focus-visible:bg-brand-blue-600 data-[state='on']:active:bg-brand-blue-500"
          >
            {ReadableSubmitterOptions[submitterType]}
          </ToggleGroupItem>
        ))}
      </ToggleGroup>
      <div className="flex w-full flex-col gap-2">
        <Text tag="strong" type="body-500" variant="size-16" className="text-grey-800">
          Need Types available for this group of users
        </Text>
        {isEditing
          ? (
              <Form providerProps={form} className="flex w-full flex-col gap-2">
                {fields.map((field, index) => (
                  <SafNeedTypeFormItem
                    key={field.id}
                    control={form.control}
                    value={field.name}
                    id={field.id}
                    name={`needs.${index}.name`}
                    selected={
                      selectedOption === SubmitterOptions.ALL
                        ? Object.values(SafSubmitterType).some((type) => field.submitter_types.includes(type))
                        : field.submitter_types.includes(selectedOption)
                    }
                    intermediate={
                      selectedOption === SubmitterOptions.ALL
                      && field.submitter_types.length > 0
                      && !Object.values(SafSubmitterType).every((type) => field.submitter_types.includes(type))
                    }
                    disableRemove={fields.length === 1}
                    onCheckedChange={(value) => needsItemSelectChangeHandler(index, field, value)}
                    onRemove={() => removeNeedItemHandler(index)}
                  />
                ))}
                <FormField name={'needs.root' as 'needs'} control={form.control} render={() => <FormMessage />} />
                <Button
                  type="button"
                  variant="tertiary"
                  size="small"
                  className="w-fit"
                  iconLeft={<PurpleIcon name="plus" />}
                  onClick={addNeedItemClickHandler}
                >
                  Add new need
                </Button>
              </Form>
            )
          : (
              <ul className="flex w-full flex-col gap-2">
                {needs
                  ?.filter(
                    (item) => selectedOption === SubmitterOptions.ALL
                      ? item.submitter_types.length > 0
                      : item.submitter_types.includes(selectedOption),
                  )
                  .map((item) => (
                    <li key={item.id}>
                      <Text variant="size-16" type="body-400" className="text-grey-800">
                        {item.name}
                      </Text>
                    </li>
                  ))}
              </ul>
            )}
      </div>
    </section>
  );
};
