import type { ValueOf } from '@purple/shared-types';
import type { ALLOWED_BULK_TEMPLATES } from '@purple/shared-utils';

export const ADMIN_USER_ENDPOINTS = {
  ME: '/users/me/',

  LIST: '/admin/users/',

  LOGIN: '/admin/users/token/',
  LOGOUT: '/users/token/blacklist/',

  RESET_PASSWORD: '/users/password/reset/',
  RESET_PASSWORD_CONFIRM: '/users/password/reset/confirm/',

  REFRESH: '/users/token/refresh/',
  VERIFY: '/users/token/verify/',

  USER_PERMISSIONS: '/admin/users/me/permissions/',
  USER_LOGIN_HISTORY: (userId: string) => `/admin/users/${userId}/login-history/`,
} as const;

export const ADMIN_USER_MANAGEMENT = {
  LIST: 'admin/users/users-list/',
  USERS_FILTER_OPTIONS: '/admin/users/users-list/filter-options/',
  CHECK_DEACTIVATION: '/admin/users/bulk-deactivate/validate/',
  USERS_ACTIVATION: '/admin/users/bulk-activate/',
  ADD_INVITE_USER_MANUAL: '/admin/users/invite/',
  BULK_INVITE_USERS: '/admin/users/manual-invite/import/',
  EXPORT: '/admin/users/users-list/export/',
  IMPERSONATE: '/admin/users/impersonate/',
  IMPERSONATE_RESOURCES: (id: string) => `/admin/users/impersonate/${id}/resources/`,
  USER_DELETE: (id: string) => `/admin/users/${id}/`,
  USER_DELETE_FROM_SCHOOL: (id: string) => `/admin/users/${id}/remove-school-access/`,
  USER_DETAILS: (id: string) => `/admin/users/${id}/base-info/`,
  USER_BASIC_DETAILS: (id: string) => `/admin/users/${id}/`,
  UPDATE_USER_BASIC_DETAILS: (id: string) => `/admin/users/${id}/`,
  USER_DETAILS_PERMISSIONS: (id: string) => `/admin/users/${id}/permissions/`,
  USER_DETAILS_PERMISSIONS_LIST: (id: string) => `/admin/users/${id}/permissions/all/`,
  USER_DETAILS_PERMISSIONS_REMOVE: (id: string) => `/admin/users/${id}/permissions/remove/`,
  USER_DETAILS_PERMISSIONS_ADD_NEGATIVE: (id: string) => `/admin/users/${id}/permissions/add-negative/`,
  USER_DETAILS_PERMISSIONS_REMOVE_NEGATIVE: (id: string) => `/admin/users/${id}/permissions/remove-negative/`,
  USER_DETAILS_PERMISSIONS_CODE_NAMES: (id: string) => `/admin/users/${id}/permissions/codenames/`,
  NOT_LOGGED_IN_REPORT: '/admin/users/not-logged-in/',
  NOT_LOGGED_IN_REPORT_EXPORT: '/admin/users/not-logged-in/export/',
  FREQUENCY_DISTRIBUTION_REPORT: '/admin/users/login-frequency/',
  FREQUENCY_DISTRIBUTION_REPORT_EXPORT: '/admin/users/login-frequency/export/',
  LOGGED_IN_BY_ROLE_REPORT: '/admin/users/logged-in-by-role/',
  LOGGED_IN_BY_ROLE_REPORT_EXPORT: '/admin/users/logged-in-by-role/export/',
} as const;

export const DISTRICTS_ENDPOINTS = {
  LIST_BASIC_INFO: '/admin/districts/basic-info/',
  BY_ID: (id: string | number) => `/admin/districts/${id}/`,
  BASIC_DETAILS: (id: string | number) => `/admin/districts/${id}/basic-details/`,
  BRANDING: (id: string | number) => `/admin/districts/${id}/branding/`,
  SAF_PROCESS: (id: string | number) => `/admin/districts/${id}/saf-process/`,
  STATUS: (id: string | number) => `/admin/districts/${id}/status/`,
  CUSTOM_PAGES: '/admin/custom-pages/',
  CUSTOM_PAGES_BY_ID: (id: string | number) => `/admin/custom-pages/${id}/`,
  CUSTOM_PAGES_BY_DISTRICT: (id: string | number) => `/admin/districts/${id}/custom-pages-list/`,
  PUBLISHED_LIST: '/admin/districts/published-list/',
  DRAFTED_LIST: '/admin/districts/draft-list/',
  FILTER_OPTIONS: '/admin/districts/filter-options/',
  USERS: `/admin/users/district-users-list/`,
  USERS_FILTER_OPTIONS: `/admin/users/district-users-list/filter-options/`,
  USERS_BULK_DEACTIVATE: `/admin/users/bulk-deactivate/`,
  CREATE_DRAFT: '/admin/districts/',
  DRAFTED_DETAIL: '/admin/districts/{districtId}/',
  UPDATE_DRAFT_BASIC: '/admin/districts/{districtId}/basic-details/',
  MAPPINGS_BY_ID: (districtId: string) => `/admin/districts/${districtId}/sis-mappings/`,
  MAPPINGS_DETAILS: (districtId: string, mappingId: string) =>
    `/admin/districts/${districtId}/sis-mappings/${mappingId}/`,
  MAPPINGS_EVENTS: (districtId: string, mappingId: string) =>
    `/admin/districts/${districtId}/sis-mappings/${mappingId}/events/`,
  DELETE_MAPPING_FILE: (districtId: string, mappingId: string) =>
    `/admin/districts/${districtId}/sis-mappings/${mappingId}/delete-mapping-file/`,
  UPLOAD_MAPPING_FILE: (districtId: string, mappingId: string) =>
    `/admin/districts/${districtId}/sis-mappings/${mappingId}/upload-mapping-file/`,
  UPDATE_DRAFT_BRANDING: '/admin/districts/{districtId}/branding/',
  UPDATE_DRAFT_LEADERS_USERS: '/admin/districts/{districtId}/users-and-permissions/',
  UPDATE_RESPONSIBLE_FOR_SETUP: '/admin/districts/{districtId}/responsible-for-setup/',
  SSO_SETTINGS: (districtId: string) => `/admin/districts/${districtId}/sso-settings/`,
  PUBLISH_DISTRICT: (districtId: string) => `/admin/districts/${districtId}/publish-district/`,
  GET_SAF_PROCESS_OWNER: (districtId: string) => `/admin/districts/${districtId}/saf-process-owner/`,
  UPDATE_SAFE_PROCESS_OWNER: (districtId: string) => `/admin/districts/${districtId}/saf-process-owner/`,
} as const;

export const DASHBOARD_ENDPOINTS = {
  TOP_STATISTIC: '/admin/dashboard-statistic/top-section/',
  LOGINS_BY_ROLE: '/admin/dashboard-statistic/role-logins/',
  LOGIN_FREQUENCY: '/admin/dashboard-statistic/login-frequency/',
  DOCUMENTED_ACTIONS: '/admin/dashboard-statistic/documented-actions/',
  LATEST_UPDATES: '/admin/dashboard-statistic/latest-updates/',
} as const;

export const SAF_ENDPOINTS = {
  CREATE_ROUTING_RULES: '/admin/saf-routing-rules/',
  EDIT_ROUTING_RULES: (id: string) => `/admin/saf-routing-rules/${id}/`,
  LIST_ROUTING_RULES: '/admin/saf-routing-rules/',
  SERVICE_CATEGORIES: (id: string) => `/admin/safs/${id}/service-area-subcategories/`,
  ROUTING_RULE_DETAIL: (id: string) => `/admin/saf-routing-rules/${id}/`,
  LIST: '/admin/safs/',
  LIST_FILTERS: '/admin/safs/filter-choices/',
  EXPORT: '/admin/safs/export/',
  DELETE: (safId: number) => `/admin/safs/${safId}/`,
  DETAIL: (safId: string) => `/admin/safs/${safId}/base-info/`,
  BASIC_DETAIL: (safId: string) => `/admin/safs/${safId}/`,
  HISTORY: (safId: string) => `/admin/safs/${safId}/history/`,
  BULK_IMPORT_SAF_ROUTING_RULES: '/admin/saf-routing-rules/import/',
  BULK_DELETE: '/admin/safs/bulk-delete/',
  BULK_RULE_DELETE: '/admin/saf-routing-rules/bulk-delete/',
  SAF_EMAILS: (safId: string) => `/admin/saf/${safId}/correspondence/`,
  SAF_EMAIL_DETAIL: (safId: string, emailId: string) => `/admin/saf/${safId}/correspondence/${emailId}/`,
  LIST_ROUTING_RULES_FILTERS: '/admin/saf-routing-rules/filter-options/',
  UPDATE_SAF_DETAILS: (safId: string) => `/admin/safs/${safId}/`,
  TEXT_TRANSLATIONS: (safId: string) => `/admin/districts/${safId}/disclaimers/`,
  TRANSFER_SAF: '/admin/safs/bulk-transfer-safs/',
} as const;

export const SCHOOLS_ENDPOINTS = {
  ROOT: '/admin/schools/',
  BY_ID: (id: string | number) => `/admin/schools/${id}/`,
  BASIC_DETAILS: (id: string | number) => `/admin/schools/${id}/basic-details/`,
  UPDATE_CHOICES: '/admin/schools/update-choices/',
  FILTER_CHOICES: '/admin/schools/filter-choices/',
  DEACTIVATE: (id: string | number) => `/admin/schools/${id}/deactivate/`,
  ACTIVATE: (id: string | number) => `/admin/schools/${id}/activate/`,
  USER: '/admin/schools/user/',
  HIERARCHY: (id: string | number) => `/admin/schools/${id}/hierarchy/`,
  HIERARCHY_CHOICES: (id: string | number) => `/admin/schools/${id}/hierarchy-node-choices/`,
  SAF_PLOT: (id: string | number) => `/admin/schools/${id}/students-with-safs-plot/`,
  SAF_INSIGHTS: (id: string | number) => `/admin/schools/${id}/saf-insights/`,
} as const;

export const NEEDS_ENDPOINTS = {
  ROOT: '/admin/needs/',
} as const;

export const ROLES_ENDPOINTS = {
  SEARCH_LIST: '/admin/roles/dropdown-search/',
  ROOT: '/admin/roles/',
  BY_ID: (id: string | number) => `/admin/roles/${id}/`,
} as const;

export const VISUALIZER_ENDPOINTS = {
  SETUP_STATE: (districtId: number | string) => `/admin/student-data-setup/districts/${districtId}/state/`,
  ABSENCES_THRESHOLDS: (districtId: number | string) => `/admin/student-data-setup/districts/${districtId}/absence-thresholds/`,
  PULL_DATES: (districtId: number | string) => `/admin/student-data-setup/districts/${districtId}/pull-dates/`,
  SUBPOPULATION_FILTERS: (districtId: number | string) => `/admin/student-data-setup/districts/${districtId}/subpopulation-filters/`,
  DATA_TABLE_VIEW: (districtId: number | string) => `/admin/student-data-setup/districts/${districtId}/data-points/`,
  FLAG_CALCULATION: (districtId: number | string) => `/admin/student-data-setup/districts/${districtId}/flag-calculations/`,
} as const;

export const FLAG_CALCULATION_ENDPOINTS = {
  ROOT: '/admin/flag-calculations/',
  BY_ID: (flagId: string | number) => `/admin/flag-calculations/${flagId}/`,
} as const;

export const GROUPS_ENDPOINTS = {
  ROOT: '/admin/groups/',
  BY_ID: (id: string | number) => `/admin/groups/${id}/`,
} as const;

export const PERMISSIONS_ENDPOINTS = {
  PERMISSIONS_STRUCTURE: '/admin/permissions/structure/',
} as const;

export const ACTIONS_ENDPOINTS = {
  ROOT: '/admin/actions/',
  ACTION_TYPES: '/admin/action-types/',
  ACTIONS_STATISTICS: '/admin/actions/instances-count/',
  DOCUMENT_AS_COUNT: '/admin/actions/document-as-count/',
  IMPORT_BULK_ACTIONS: '/admin/actions/import/',
  IMPORT_BULK_ACTION_REMINDERS: '/admin/reminders/import/',
  ACTION_TYPE_BY_ID: (id: string | number) => `/admin/action-types/${id}/`,
  BY_ID: (id: string | number) => `/admin/actions/${id}/`,
  DELETE: (id: string | number) => `/admin/actions/${id}/`,
  ACTION_CHOICES: (id: string | number) => `/admin/actions/${id}/action-choices/`,
  STUDENT_CENTERED_ACTIONS_FILTER_OPTIONS: '/admin/actions/filter-options/',
  ACTION_TYPES_OPTIONS: '/admin/actions/type-options/',
  DOCUMENTED_ACTIONS_LIST: '/admin/actions/reports/',
  DOCUMENTED_ACTIONS_LIST_EXPORT: '/admin/actions/reports/export/',
} as const;

export const NOTES_ENDPOINTS = {
  ROOT: '/admin/notes/',
  BY_ID: (id: string | number) => `/admin/notes/${id}/`,
} as const;

export const STUDENTS_ENDPOINTS = {
  ROOT: '/admin/students/',
  BY_ID: (id: string | number) => `/admin/students/${id}/`,
  DELETE_STUDENT_GUARDIAN: (studentId: string | number) => `/admin/students/${studentId}/remove-guardian/`,
} as const;

export const TAGS_ENDPOINTS = {
  ROOT: '/admin/tags/',
  BY_ID: (id: string | number) => `/admin/tags/${id}/`,
  APPLY_TAGS: '/admin/tags/set-object-tags/',
} as const;

export const CONTACTS_ENDPOINTS = {
  ROOT: '/admin/contacts/',
  PRIORITY_LISTS: '/admin/contacts/priority-lists/',
  PRIORITY_LIST_BY_ID: (id: string) => `/admin/contacts/priority-lists/${id}/`,
  PRIORITY_LIST_CONTACTS: (id: string) => `/admin/contacts/priority-lists/${id}/paginated-contacts/`,
  PRIORITY_LIST_BATCH_ADD_CONTACTS: (id: string) => `/admin/contacts/priority-lists/${id}/batch-add-contacts/`,
  PRIORITY_LIST_BATCH_REMOVE_CONTACTS: (id: string) => `/admin/contacts/priority-lists/${id}/batch-remove-contacts/`,
  PRIORITY_LIST_BATCH_ADD_SHARING: (id: string) => `/admin/contacts/priority-lists/${id}/batch-add-list-sharing/`,
  PRIORITY_LIST_BATCH_REMOVE_SHARING: (id: string) => `/admin/contacts/priority-lists/${id}/batch-remove-list-sharing/`,
  SEND_EMAIL: '/admin/contacts/send-email/',
  CONTACTS_STATISTICS: '/admin/contacts/instances-count/',
  BY_ID: (id: string | number) => `/admin/contacts/${id}/`,
  BULK_ADD_CONTACTS: '/admin/contacts/import/',
  CONTACTS_ID_LIST: '/admin/contacts/id-list/',
  ADD_CONTACT_STUDENTS: (contactId: string | number) => `/admin/contacts/${contactId}/bulk-add-students/`,
} as const;

export const BANNERS_ENDPOINTS = {
  ROOT: '/admin/banner-notifications/',
  PUBLISHED: '/admin/banner-notifications/published-list/',
  DRAFT: '/admin/banner-notifications/draft-list/',
  BY_ID: (id: string | number) => `/admin/banner-notifications/${id}/`,
  PUBLISH: (id: string | number) => `/admin/banner-notifications/${id}/publish/`,
  BULK_DELETE: '/admin/banner-notifications/bulk-delete/',
} as const;

export const NOTIFICATIONS_ENDPOINTS = {
  ROOT: '/admin/in-app-notifications/',
  MARK_ALL_AS_READ: '/admin/in-app-notifications/mark-all-as-read/',
  MARK_AS_READ: (id: string | number) => `/admin/in-app-notifications/${id}/mark-as-read/`,
} as const;

export const SUBMITTER_TYPES_ENDPOINTS = {
  LIST: '/admin/submitter-types/',
  DETAIL: (id: string) => `/admin/submitter-types/${id}/`,
  UPDATE_DETAILS: (id: string) => `/admin/submitter-types/${id}/`,
  FIELD_SETTINGS: (id: string) => `/admin/submitter-types/${id}/field-settings/`,
  UPDATE_FIELD_SETTINGS: (id: string) => `/admin/submitter-types/${id}/field-settings/`,
  TEXT_TRANSLATION: (id: string) => `/admin/submitter-types/${id}/needs-translation/`,
  UPDATE_TEXT_TRANSLATION: (id: string) => `/admin/submitter-types/${id}/needs-translation/`,
};

export const ACTIVITIES_ENDPOINTS = {
  ROOT: '/admin/community-activities/',
  BY_ID: (id: string | number) => `/admin/community-activities/${id}/`,
  RECURRING_BY_ID: (id: string | number) => `/admin/recurring-community-activities/${id}/`,
  FILTER_CHOICES: '/admin/community-activities/filter-choices/',
  FILTER_CHOICES_BY_ID: (id: string | number) => `/admin/community-activities/${id}/instance-filter-choices/`,
  BULK_DELETE: '/admin/community-activities/bulk-delete/',
  INSTANCES_COUNT: '/admin/community-activities/instances-count/',
  ID_LIST: '/admin/community-activities/id-list/',
  EXPORT: '/admin/community-activities/export/',
  BASIC_INFO: (id: string | number) => `/admin/community-activities/${id}/basic-info/`,
  HISTORY: (id: string | number) => `/admin/community-activities/${id}/history/`,
  CHOICES: (id: string | number) => `/admin/community-activities/${id}/activity-choices/`,
} as const;

export const ACTIVITY_TYPES_ENDPOINTS = {
  ROOT: '/admin/community-activity-types/',
  BY_ID: (id: string | number) => `/admin/community-activity-types/${id}/`,
  ROLES_LIST: (id: string | number) => `/admin/community-activity-types/${id}/roles-list/`,
  ROLES_UPDATE: (id: string | number) => `/admin/community-activity-types/${id}/update-roles/`,
  GROUPS_LIST: (id: string | number) => `/admin/community-activity-types/${id}/groups-list/`,
  GROUPS_UPDATE: (id: string | number) => `/admin/community-activity-types/${id}/update-groups/`,
} as const;

export const FILE_ENDPOINTS = {
  ROOT: '/admin/files/',
  BY_ID: (id: string | number) => `/admin/files/${id}/`,
} as const;

export const PRINT_ENDPOINTS = {
  ROOT: '/admin/pdf/',
} as const;

export const BULK_TEMPLATES = {
  ROOT: (type: ValueOf<typeof ALLOWED_BULK_TEMPLATES>) => `/file-templates/${type}/`,
} as const;

export const PUBLIC_ENDPOINTS = [
  ADMIN_USER_ENDPOINTS.LOGIN,
  ADMIN_USER_ENDPOINTS.LOGOUT,
  ADMIN_USER_ENDPOINTS.RESET_PASSWORD,
  ADMIN_USER_ENDPOINTS.RESET_PASSWORD_CONFIRM,
  ADMIN_USER_ENDPOINTS.REFRESH,
  ADMIN_USER_ENDPOINTS.VERIFY,
] as string[];
