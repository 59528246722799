import { formatDateShortMonth } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TDocumentedAction } from '~/services';

type TActionsColumns = ColumnDef<TDocumentedAction>[];

export const actionsColumns: TActionsColumns = [
  {
    accessorKey: 'date',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date" />,
    cell: ({ row }) => {
      const dateTime = formatDateShortMonth(row.getValue('date'));

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{dateTime}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {dateTime}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Date' },
    enableSorting: true,
  },
  {
    accessorKey: 'number_of_documented_actions',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Number of documented actions" />,
    cell: ({ row }) => {
      const value = row.original.number_of_documented_actions;

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{value}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {value}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Number of documented actions', className: 'text-center' },
    enableSorting: false,
  },
  {
    accessorKey: 'district__name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row }) => {
      const value = row.original.district;

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{value}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {value}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'District' },
    enableSorting: true,
  },
  {
    accessorKey: 'school__name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="School" />,
    cell: ({ row }) => {
      const value = row.original.school;

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{value}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {value}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'School' },
    enableSorting: true,
  },
];
