import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { PurpleIcon } from '@purple/icons';
import { NotificationContentType } from '@purple/shared-types';
import {
  Button,
  cn,
  NoDataAvailable,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  SearchInput,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useMarkAllAsRead, useMarkAsRead, useNotifications } from '~/services';
import { queryRetryHelper } from '~/utils/api-requests';
import { NotificationItem } from './NotificationItem';
import type { TNotificationItem } from '~/services';

const getNotificationRoute = (notification: TNotificationItem): string => {
  switch (notification.content_type) {
    case NotificationContentType.SCHOOL:
      return AdminRoutes.App.Schools.Details.makePath({ dynamicParameters: { schoolId: notification.object_id } });

    case NotificationContentType.USER:
      return AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: notification.object_id } });

    case NotificationContentType.DISTRICT:
      return AdminRoutes.App.Districts.Details.makePath({ dynamicParameters: { id: notification.object_id } });

    case NotificationContentType.COMMUNITY_ACTIVITY:
      // TODO: Add community activity route
      return AdminRoutes.System.NotFound.Root.path;

    case NotificationContentType.PROGRAM:
      // TODO: Add program route
      return AdminRoutes.System.NotFound.Root.path;

    default:
      return AdminRoutes.System.NotFound.Root.path;
  }
};

export const InAppNotifications: React.FC = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const [debouncedSearch] = useDebounceValue(searchValue, 500);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useNotifications({ search: debouncedSearch }, {
    retry: queryRetryHelper,
    refetchInterval: 30 * 1000, // 30 seconds
  });
  const { mutate: markAsRead } = useMarkAsRead({ shouldRevalidate: true });
  const { mutate: markAllAsRead, isPending: isMarkAllPending } = useMarkAllAsRead({ shouldRevalidate: true });

  const notifications = useMemo(() => data?.pages.map((page) => page.results).flat() || [], [data]);
  const isEmpty = useMemo(() => notifications.length === 0, [notifications]);
  const hasUnreadNotifications = useMemo(() => (data?.pages.reduce((acc, item) => acc + item.unread_count, 0) ?? 0) > 0, [data]);

  const togglePopoverHandler = useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  const notificationClickHandler = useCallback((notification: TNotificationItem) => {
    if (!notification.is_marked_as_read) {
      markAsRead({ id: notification.id });
    }
    navigate(getNotificationRoute(notification));
    togglePopoverHandler(false);
  }, [markAsRead, togglePopoverHandler, navigate]);

  return (
    <Popover open={isOpen} onOpenChange={togglePopoverHandler} modal>
      <Tooltip>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              aria-label="Notifications"
              className={cn('size-9 relative', {
                'before:absolute before:size-2 before:rounded-full before:bg-warning-main before:top-1 before:right-1': hasUnreadNotifications,
              })}
              iconLeft={<PurpleIcon name="bell" className="size-5 shrink-0" />}
              onFocusCapture={(evt) => evt.stopPropagation()}
            />
          </TooltipTrigger>
        </PopoverTrigger>
        <TooltipPortal>
          <TooltipContent>Notifications</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <PopoverContent align="end" className="flex w-[400px] flex-col rounded-lg border border-grey-300 p-0 shadow-custom-medium" onFocusCapture={(evt) => evt.stopPropagation()}>
        <div className="flex w-full items-center justify-between gap-4 px-6 py-3">
          <strong className="text-lg font-semibold text-grey-title">Notifications</strong>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="tertiary"
                size="icon_32"
                className="size-9"
                iconLeft={<PurpleIcon name="X" className="size-5 shrink-0" />}
                onClick={() => togglePopoverHandler(false)}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>
                Close notifications
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </div>
        <Separator orientation="horizontal" className="bg-grey-300" />
        <div className="flex w-full items-center justify-center px-6 py-4">
          <SearchInput
            type="text"
            name="notifications-search"
            placeholder="Search"
            className="h-9 [&_input]:h-9"
            value={searchValue}
            onChange={(evt) => setSearchValue(evt.target.value)}
            onClear={() => setSearchValue('')}
          />
        </div>
        <ScrollArea type="auto" className="flex max-h-[340px] w-full flex-col p-0" scrollBarClassName="px-2 w-[22px]">
          {isLoading && !isFetchingNextPage && (
            <div role="status" className="flex w-full items-center justify-center p-6" aria-label="Loading notifications">
              <PurpleIcon name="loader" className="size-8 animate-spin" />
            </div>
          )}
          {!isEmpty && (
            <ul className="flex w-full flex-col gap-1 pr-[22px] last:pb-2">
              {notifications.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} onClick={notificationClickHandler} />
              ))}
            </ul>
          )}
          {isEmpty && !isFetching && (
            <NoDataAvailable
              iconName="X-Circle"
              title={debouncedSearch ? 'No notifications found' : 'You have no notifications'}
              description={debouncedSearch ? 'Try searching for something else' : 'You will see all your notifications here'}
              iconSize={24}
              className="p-6"
            />
          )}
          {hasNextPage && (
            <div className="flex w-full items-center justify-center px-6 py-2">
              <Button
                type="button"
                variant="tertiary"
                size="small"
                className="w-full"
                isLoading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage}
              >
                {isFetchingNextPage ? 'Loading more...' : 'Load more'}
              </Button>
            </div>
          )}
        </ScrollArea>
        <Separator orientation="horizontal" className="bg-grey-300" />
        <div className="flex w-full items-center justify-end px-6 py-4">
          <Button type="button" variant="primary" isLoading={isMarkAllPending} disabled={!hasUnreadNotifications} onClick={() => markAllAsRead()}>
            {isMarkAllPending ? 'Marking all as read...' : 'Mark All as Read'}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
