import { Skeleton } from '@purple/ui';

export const ActivityTypeInfoSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-wrap items-start justify-between gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex flex-1 flex-col gap-2">
        <Skeleton className="h-6 w-full max-w-[190px]" />
        <div className="flex flex-1 flex-col items-start justify-start gap-6 lg:flex-row">
          <div className="flex w-full max-w-[86px] flex-1 flex-col gap-1">
            <Skeleton className="h-5 w-full max-w-[44px]" />
            <Skeleton className="h-7 w-full max-w-[86px] rounded-full" />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <Skeleton className="h-10 w-[135px]" />
        <Skeleton className="ml-auto size-10 shrink-0" />
      </div>
    </div>
  );
};
