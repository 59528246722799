import { Link, useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { ContactType } from '@purple/shared-types';
import { Button } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useContactDetails } from '~/services';
import { ContactDetailsHeader, ContentSkeleton, HeaderSkeleton, ParentGuardianView } from './components';
import { BasicDetails } from './Tabs/BasicDetails';

export const ContactDetailsPage = () => {
  const { contactId } = useParams();
  const { data, isLoading } = useContactDetails({ parameters: { contactId: contactId! } });

  return (
    <div className="flex w-full flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="size-4 shrink-0 text-brand-blue-700" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Contacts.Root.makePath({ queries: { tab: 'contacts' } })}>
          Back to Contacts
        </Link>
      </Button>
      {isLoading && !data && (
        <>
          <HeaderSkeleton />
          <ContentSkeleton />
        </>
      )}
      {data && !isLoading && (
        <>
          <ContactDetailsHeader />
          {data.contact_type === ContactType.PARENT_GUARDIAN
            ? (
                <ParentGuardianView />
              )
            : (
                <section className="flex w-full items-start justify-between gap-3 rounded-lg border border-grey-200 bg-white">
                  <BasicDetails />
                </section>
              )}
        </>
      )}
    </div>
  );
};
