import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TAdminLoggedInByRoleReportParameters, TAdminLoggedInByRoleReportResponse } from './user.types';

export const getLoggedInByRoleReport = async (parameters: TAdminLoggedInByRoleReportParameters) => {
  const response = await axiosInstance.get<TAdminLoggedInByRoleReportResponse>(ADMIN_USER_MANAGEMENT.LOGGED_IN_BY_ROLE_REPORT, {
    params: parameters,
  });
  return response.data;
};
