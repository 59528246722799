import * as z from 'zod';
import { ACCEPTED_ACTIVITY_FILE_TYPES, MAX_ACTIVITY_FILE_SIZE, multiSelectOptions } from '@purple/shared-utils';

export const uploadFileSchema = z.object({
  file: z
    .custom<File>((value) => value instanceof File, {
      message: 'Invalid file type.',
    })
    .refine(
      (file) => ACCEPTED_ACTIVITY_FILE_TYPES.has(file?.type),
      'Only .jpg, .jpeg, .png and .pdf formats are supported.',
    )
    .refine((file) => file?.size <= MAX_ACTIVITY_FILE_SIZE, `Unable to upload a file that is more than ${MAX_ACTIVITY_FILE_SIZE / (1024 * 1024)}MB.`),
  is_private: z.boolean().default(false),
  viewers: multiSelectOptions.default([]),
});

export const editFileSchema = z.object({
  title: z.string().trim().min(1, 'Title is required'),
  is_private: z.boolean().default(false),
  viewers: multiSelectOptions.default([]),
});
