import { useEffect, useState } from 'react';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Input,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { useContactsPriorityListById, useUpdatePriorityList } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { PriorityListQueryName } from './constants';
import { DetailsFormSkeleton } from './DetailsFormSkeleton';

export const DetailsForm: React.FC = () => {
  const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const [isDescriptionEditing, setIsDescriptionEditing] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');

  const { query: listId } = useQueryParameter({
    queryName: PriorityListQueryName.ID,
  });

  const { data: priorityList = null, isFetching } = useContactsPriorityListById(
    { id: listId as string },
    { enabled: !!listId },
  );
  const { mutate: updatePriorityList, isPending: isUpdatePending } = useUpdatePriorityList();

  useEffect(() => {
    if (priorityList) {
      setName(priorityList.name);
      setDescription(priorityList.description || '');
    }
  }, [priorityList]);

  const editNameClickHandler = () => {
    setIsNameEditing(true);
  };

  const saveNameClickHandler = () => {
    const isValid = name !== '' && name.length <= 255;
    if (!isValid) {
      return showErrorToast('Invalid Name', 'Name must be between 1 and 255 characters.');
    }
    updatePriorityList({
      id: listId as string,
      name,
    }, {
      onSuccess: () => {
        setIsNameEditing(false);
      },
    });
  };

  const cancelNameClickHandler = () => {
    setName(priorityList?.name || '');
    setIsNameEditing(false);
  };

  const editDescriptionClickHandler = () => {
    setIsDescriptionEditing(true);
  };

  const saveDescriptionClickHandler = () => {
    updatePriorityList({
      id: listId as string,
      description,
    }, {
      onSuccess: () => {
        setIsDescriptionEditing(false);
      },
    });
  };

  const cancelDescriptionClickHandler = () => {
    setDescription(priorityList?.description || '');
    setIsDescriptionEditing(false);
  };

  if (isFetching) {
    return <DetailsFormSkeleton />;
  }

  return (
    <DescriptionList tag="ul" className="flex flex-col gap-2.5">
      <DescriptionItem tag="li" className="w-full max-w-[440px] flex-nowrap items-center justify-stretch gap-2">
        <DescriptionTerm tag="strong" className="min-w-24 text-sm font-medium text-grey-title">
          Name:
        </DescriptionTerm>
        {isNameEditing
          ? (
              <HoverCard open>
                <HoverCardTrigger asChild>
                  <span className="flex w-fit">
                    <Input
                      value={name}
                      type="text"
                      isError={name === '' || name.length > 255}
                      placeholder="Enter name"
                      onChange={(e) => setName(e.target.value)}
                      className="h-8 w-full"
                    />
                  </span>
                </HoverCardTrigger>
                <HoverCardContent
                  align="end"
                  className="flex w-auto gap-1 border-none bg-transparent p-0 shadow-none"
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        type="button"
                        variant="primary"
                        size="icon_32"
                        className="shadow-custom-medium"
                        disabled={name === '' || name.length > 255}
                        isLoading={isUpdatePending}
                        iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                        onClick={saveNameClickHandler}
                      />
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent>Save</TooltipContent>
                    </TooltipPortal>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        type="button"
                        variant="secondary"
                        size="icon_32"
                        className="border-0 shadow-custom-medium"
                        iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                        onClick={cancelNameClickHandler}
                      />
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent>Cancel</TooltipContent>
                    </TooltipPortal>
                  </Tooltip>
                </HoverCardContent>
              </HoverCard>
            )
          : (
              <button
                type="button"
                className="group inline-flex min-h-8 items-center gap-2"
                onClick={editNameClickHandler}
              >
                <span className="inline-flex rounded-lg px-3 py-1.5 text-sm font-normal transition-colors duration-200 group-hover:bg-grey-100 group-focus:bg-grey-100 group-focus-visible:bg-grey-100 group-active:bg-grey-50 group-active:transition-none">
                  {priorityList?.name}
                </span>
                <PurpleIcon name="pencil" className="size-4 shrink-0 text-grey-600 group-hover:text-brand-blue-700" />
              </button>
            )}
      </DescriptionItem>

      <DescriptionItem tag="li" className="w-full max-w-80 flex-nowrap items-center justify-stretch gap-2">
        <DescriptionTerm tag="strong" className="min-w-24 text-sm font-medium text-grey-title">
          District:
        </DescriptionTerm>
        <DescriptionDetails tag="span" className="m-0 inline-flex items-center px-3 py-1.5 text-sm font-normal text-grey-950">
          {priorityList?.district.name}
        </DescriptionDetails>
      </DescriptionItem>

      <DescriptionItem tag="li" className="w-full max-w-80 flex-nowrap items-start justify-stretch gap-2">
        <DescriptionTerm tag="strong" className="mt-2 min-w-24 text-sm font-medium text-grey-title">
          Description:
        </DescriptionTerm>
        {isDescriptionEditing
          ? (
              <HoverCard open>
                <HoverCardTrigger asChild>
                  <span className="flex w-fit">
                    <Textarea
                      value={description}
                      placeholder="Enter description"
                      onChange={(e) => setDescription(e.target.value)}
                      responsiveHeight
                      className="min-h-[100px] max-w-56 resize-none"
                    />
                  </span>
                </HoverCardTrigger>
                <HoverCardContent
                  align="end"
                  className="flex w-auto gap-1 border-none bg-transparent p-0 shadow-none"
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        type="button"
                        variant="primary"
                        size="icon_32"
                        className="shadow-custom-medium"
                        isLoading={isUpdatePending}
                        iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                        onClick={saveDescriptionClickHandler}
                      />
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent>Save</TooltipContent>
                    </TooltipPortal>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        type="button"
                        variant="secondary"
                        size="icon_32"
                        className="border-0 shadow-custom-medium"
                        iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                        onClick={cancelDescriptionClickHandler}
                      />
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent>Cancel</TooltipContent>
                    </TooltipPortal>
                  </Tooltip>
                </HoverCardContent>
              </HoverCard>
            )
          : (
              <button
                type="button"
                className="group inline-flex min-h-8 items-center gap-2 self-center"
                onClick={editDescriptionClickHandler}
              >
                <span className="inline-flex whitespace-pre-wrap rounded-lg px-3 py-1.5 text-left text-sm font-normal transition-colors duration-200 group-hover:bg-grey-100 group-focus:bg-grey-100 group-focus-visible:bg-grey-100 group-active:bg-grey-50 group-active:transition-none">
                  {priorityList?.description || 'No description'}
                </span>
                <PurpleIcon name="pencil" className="size-4 shrink-0 text-grey-600 group-hover:text-brand-blue-700" />
              </button>
            )}
      </DescriptionItem>
    </DescriptionList>
  );
};
