import { BulkFileErrorTableCell } from '~/components/BulkFileErrorTableCell';
import { DataTableColumnHeader } from '~/components/DataTable';
import type { ColumnDef } from '@tanstack/react-table';
import type { TContactsBulkFileErrorResponse } from '~/services';

export const fileErrorsColumns: ColumnDef<TContactsBulkFileErrorResponse>[] = [
  {
    accessorKey: 'row',
    header: ({ column }) => <DataTableColumnHeader column={column} title="#" className="px-2" />,
    cell: ({ row }) => (
      <div className="px-2 py-3">
        <span>{row.original.row}</span>
      </div>
    ),
    size: 50,
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'first_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="First Name" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.first_name} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="First Name" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.last_name} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Email" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.email} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'phone_number',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Phone" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.phone_number} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'connected_students',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Connected Students" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.connected_students} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
];
