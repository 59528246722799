import axiosInstance from '../axios-config';
import { CONTACTS_ENDPOINTS } from '../endpoints';
import type { TContactsPriorityListsRequestParameters, TContactsPriorityListsResponse } from './contacts.types';

export const getContactsPriorityLists = async (parameters: TContactsPriorityListsRequestParameters) => {
  const { data } = await axiosInstance.get<TContactsPriorityListsResponse>(CONTACTS_ENDPOINTS.PRIORITY_LISTS, {
    params: parameters,
  });

  return data;
};
