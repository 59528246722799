import { useMemo, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { DistrictUserStatus } from '@purple/shared-types';
import { DistrictUserStatusToBadgeVariant, formatDateShortMonth, getInitialsFromName, snakeCaseToCapitalized } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, Badge, Button, DropdownContent, DropdownRoot, DropdownTrigger, NumberBadge, Text } from '@purple/ui';
import { CallToActionModal, ImpersonateButton, UserRolesModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { type TUserDetailsInfo, type TUsersDeactivateCheck, useBulkActivateUsers, useDeleteUser, useDistrictBulkDeactivateUsers, useUserDeactivateCheck } from '~/services';
import { showErrorToast } from '~/utils/toasts';

const MAX_ROLE_DISPLAY_LIMIT = 4;

type TDetailHeaderProperties = {
  data: TUserDetailsInfo;
};

const DetailHeader = ({ data }: TDetailHeaderProperties) => {
  const { id, avatar, current_status, roles, date_joined, invited_by, first_name, last_name } = data;

  const { openModal: openDeactivateModal, closeModal: closeDeactivateModal } = useModal(
    ModalType.BULK_USERS_DEACTIVATE,
  );
  const { openModal: openDeactivateWithSafModal, closeModal: closeDeactivateWithSafModal } = useModal(
    ModalType.BULK_USERS_WITH_SAF_DEACTIVATE,
  );
  const { openModal: openActivateModal, closeModal: closeActivateModal } = useModal(
    ModalType.BULK_USERS_ACTIVATE_MODAL,
  );
  const { openModal: openDeleteUserModal, closeModal: closeDeleteUserModal } = useModal(
    ModalType.USER_DELETE,
  );
  const { openModal: openUserRolesModal } = useModal(
    ModalType.USER_ROLES,
  );

  const { mutate: bulkUserDeactivate, isPending: isDeactivatePending } = useDistrictBulkDeactivateUsers();
  const { mutate: bulkUserActivate, isPending: isActivatePending } = useBulkActivateUsers();
  const { mutate: userDelete, isPending: isDeletePending } = useDeleteUser();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isDeactivateChecking, setIsDeactivateChecking] = useState(false);

  const { mutate: checkUsersDeactivation } = useUserDeactivateCheck();

  const fullName = useMemo(() => `${first_name} ${last_name}`, [first_name, last_name]);
  const invitedBy = useMemo(() => invited_by?.full_name ?? '-', [invited_by]);

  const deactivateActionClickHandler = (checkResponse: TUsersDeactivateCheck) => {
    const hasRoutingRuleUsers = Object.keys(checkResponse.routing_rule_users).length > 0;
    const hasOpenedSafUsers = checkResponse.opened_saf_users.length > 0;
    const hasValidUsers = checkResponse.valid_users.length > 0;

    if (hasRoutingRuleUsers) {
      const schools = Object.values(checkResponse.routing_rule_users).join(', ');
      const errorText = `User is a part of the SAF routing directory for the following schools: ${schools}. If you are sure you want to deactivate this user, user will need to be removed from SAF routing rules first.`;
      showErrorToast('User error', errorText);
      return;
    }

    if (hasOpenedSafUsers) {
      openDeactivateWithSafModal();
      return;
    }

    if (hasValidUsers) {
      openDeactivateModal();
    }
  };

  const deactivateClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDeactivateChecking(true);

    checkUsersDeactivation({ usersIds: [id] }, {
      onSuccess: (response) => {
        setIsDropdownOpen(false);
        deactivateActionClickHandler(response);
        setIsDeactivateChecking(false);
      },
      onError: () => {
        setIsDeactivateChecking(false);
        setIsDropdownOpen(false);
      },
    });
  };

  const removeClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);
    openDeleteUserModal();
  };

  const deleteUsersClickHandler = () => {
    userDelete(id, {
      onSuccess: () => {
        closeDeleteUserModal();
      },
    });
  };

  const deactivateUsersClickHandler = () => {
    bulkUserDeactivate(
      {
        users: [id],
      },
      {
        onSuccess: () => {
          closeDeactivateModal();
          closeDeactivateWithSafModal();
        },
      },
    );
  };

  const activateUsersClickHandler = () => {
    bulkUserActivate(
      {
        users: [id],
      },
      {
        onSuccess: () => {
          closeActivateModal();
        },
      },
    );
  };

  const allRolesNames = useMemo(() => roles.map((role) => snakeCaseToCapitalized(role.name)), [roles]);

  const rolesDisplay = useMemo(() => {
    return allRolesNames.slice(0, MAX_ROLE_DISPLAY_LIMIT);
  }, [allRolesNames]);

  const showMoreRoles = useMemo(() => roles.length > MAX_ROLE_DISPLAY_LIMIT, [roles]);
  const countMoreRoles = useMemo(() => {
    const countLeft = roles.length - rolesDisplay.length;
    return countLeft > 0 ? countLeft : '-';
  }, [roles, rolesDisplay]);

  return (
    <div className="flex w-full flex-row items-start justify-between rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex gap-3">
        <Avatar size={60} variant="square">
          <AvatarImage src={avatar ?? undefined} alt={fullName} />
          <AvatarFallback className="bg-grey-200">{getInitialsFromName(fullName ?? '- -')}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col gap-4">
          <Text variant="size-16" type="body-600" className="text-grey-950">{fullName}</Text>
          <div className="flex flex-row gap-6">
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">Status</Text>
              {current_status
                ? (
                    <NumberBadge
                      variant={DistrictUserStatusToBadgeVariant[current_status]}
                      className="whitespace-nowrap rounded-full capitalize"
                    >
                      {snakeCaseToCapitalized(current_status)}
                    </NumberBadge>
                  )
                : (
                    '-'
                  )}
            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">Role</Text>
              <div className="flex flex-row gap-2">
                {rolesDisplay.map((roleName) => {
                  return (
                    <Badge key={`${roleName}-${Math.random()}`} label={roleName} className="whitespace-nowrap rounded-full bg-warning-bg capitalize text-warning-main" />
                  );
                })}
                {showMoreRoles && (
                  <Badge onClick={openUserRolesModal} label={`+${countMoreRoles} more`} className="cursor-pointer whitespace-nowrap rounded-full bg-warning-bg capitalize text-warning-main" />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">User from</Text>
              <Text variant="size-14" type="body-500" className="text-grey-950">{date_joined ? formatDateShortMonth(date_joined) : '-'}</Text>
            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">Invited by</Text>
              <Text variant="size-14" type="body-500" className="text-grey-950">{invitedBy}</Text>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <ImpersonateButton userId={id} disabled={current_status !== DistrictUserStatus.ACTIVE}>
          Log In as User
        </ImpersonateButton>
        <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
          <DropdownTrigger asChild>
            <Button
              iconLeft={<PurpleIcon name="dots-vertical" />}
              variant="secondary"
              size="icon_40"
            />
          </DropdownTrigger>
          <DropdownContent className="max-w-[196px] gap-1" align="end" sideOffset={-2}>
            {current_status !== DistrictUserStatus.INACTIVE && (
              <Button
                variant="link_destructive"
                onClick={deactivateClickHandler}
                className="w-full justify-start"
                iconLeft={<PurpleIcon name="X" />}
                isLoading={isDeactivateChecking}
              >
                Deactivate
              </Button>
            )}
            {current_status === DistrictUserStatus.INACTIVE && (
              <Button
                variant="link"
                onClick={openActivateModal}
                className="w-full justify-start text-grey-700 hover:text-grey-700 active:text-grey-700"
                iconLeft={<PurpleIcon name="check" />}
              >
                Activate
              </Button>
            )}
            <Button
              variant="link_destructive"
              onClick={removeClickHandler}
              className="w-full justify-start"
              iconLeft={<PurpleIcon name="trash" />}
            >
              Delete
            </Button>
          </DropdownContent>
        </DropdownRoot>
      </div>
      <CallToActionModal
        modalName={ModalType.USER_DELETE}
        modalTitle="Delete User"
        modalDescription="Are you sure you want to delete the selected user?"
        modalTextContent="Are you sure you want to delete the selected user?"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteUsersClickHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
      />
      <CallToActionModal
        modalName={ModalType.BULK_USERS_DEACTIVATE}
        modalTitle="Deactivate User"
        modalDescription="By deactivating user, user will no longer have access to the districts."
        modalTextContent="Are you sure you want to deactivate the selected user?"
        primaryButtonText="Deactivate"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deactivateUsersClickHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeactivatePending}
      />
      <CallToActionModal
        modalName={ModalType.BULK_USERS_WITH_SAF_DEACTIVATE}
        modalTitle="The user has open SAFs"
        modalDescription="Have you had a conversation with district leadership to confirm who will be stepping into the vacancy and whether any open SAFs will be re-routed, and how?"
        modalTextContent="Have you had a conversation with district leadership to confirm who will be stepping into the vacancy and whether any open SAFs will be re-routed, and how?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={deactivateUsersClickHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeactivatePending}
      />
      <CallToActionModal
        modalName={ModalType.BULK_USERS_ACTIVATE_MODAL}
        modalTitle="Activate User"
        modalDescription="Are you sure you want to activate the selected user?"
        modalTextContent="Are you sure you want to activate the selected user?"
        primaryButtonText="Activate"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={activateUsersClickHandler}
        primaryButtonVariant="primary"
        isLoading={isActivatePending}
      />
      <CallToActionModal
        modalName={ModalType.USER_ROLES}
        modalTitle="Activate User"
        modalDescription="Are you sure you want to activate the selected user?"
        modalTextContent="Are you sure you want to activate the selected user?"
        primaryButtonText="Activate"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={activateUsersClickHandler}
        primaryButtonVariant="primary"
        isLoading={isActivatePending}
      />
      <UserRolesModal userName={fullName} roles={allRolesNames} />
    </div>
  );
};

export { DetailHeader };
