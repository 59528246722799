import { useParams } from 'react-router-dom';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { AccessDenied, AppTabs, Button } from '@purple/ui';
import { CallToActionModal } from '~/components';
import { AdminRoutes, ModalType } from '~/constants';
import { useGoBack, useModal } from '~/hooks';
import { useDeleteAction } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ActionsTabsValues } from '../Root/constants';
import { ActionDetailsHeader } from './components';
import { ActionsDetailsQueryKeys, ActionsDetailsQueryValues } from './constants';
import { useActionsDetailsTabs } from './useActionsDetailsTabs';

export const ActionDetailsPage = () => {
  const { actionId } = useParams();

  const { onGoBack } = useGoBack({ fallbackPath: AdminRoutes.App.Actions.Student.Root.makePath({ queries: { tab: ActionsTabsValues.allActions } }) });
  const { closeModal } = useModal(ModalType.DELETE_ACTION);

  const { query: actionType } = useQueryParameter({
    queryName: ActionsDetailsQueryKeys.actionType,
  });
  const { activeTab, actionsDetailsTabs, tabChangeHandler } = useActionsDetailsTabs();

  const isGroupAction = actionType === ActionsDetailsQueryValues.groupAction;

  const deleteModalText = isGroupAction ? 'You\'re going to delete the action that belongs to the group. The deletion will not affect other actions in the group. This action can not be undone. Do you want to continue?' : 'Are you sure you want to delete this action? This action can not be undone.';

  const { mutate: deleteAction, isPending: isDeleting } = useDeleteAction();

  const deleteActionHandler = () => {
    if (!actionId) {
      showErrorToast('System message', `Unable to delete action with provided Id: ${actionId}`);
      return;
    }
    deleteAction(actionId, {
      onSuccess: () => {
        closeModal();
        onGoBack();
      },
    });
  };

  return (
    <>
      <div className="flex w-full flex-col items-start gap-4">
        <Button
          variant="link"
          size="small"
          iconLeft={<PurpleIcon name="chevron-left" className="size-4 shrink-0 text-brand-blue-700" />}
          className="h-auto p-1 font-semibold"
          onClick={onGoBack}
        >
          Back to Action List
        </Button>
        <ActionDetailsHeader />
        {activeTab === null
          ? (
              <AccessDenied />
            )
          : (
              <AppTabs tabs={actionsDetailsTabs} listClassName="pl-2" value={activeTab} onValueChange={tabChangeHandler} />
            )}
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_ACTION}
        modalTitle="Delete Action"
        modalDescription="Action modal deletion"
        showModalDescription={false}
        modalTextContent={deleteModalText}
        primaryButtonText="Yes, Delete"
        primaryButtonVariant="destructive_primary"
        onPrimaryButtonClick={deleteActionHandler}
        isLoading={isDeleting}
      />
    </>
  );
};
