import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Button, Heading, Separator } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableSelectedController } from '~/components';
import { ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useContactsPriorityListById, usePriorityListBatchRemoveContacts, usePriorityListContacts } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { SendEmailDialog } from '../../components';
import { AddContactsDialog } from './AddContactsDialog';
import { PriorityListQueryName, PriorityListViewType } from './constants';
import { DetailsHeader } from './DetailsHeader';
import { useContactColumns } from './useContactColumns';
import type { TPriorityListViewType } from './constants';

export const DetailsView: React.FC = () => {
  const [searchParameters] = useSearchParams();

  const [selectedEntity, setSelectedEntity] = useState<string[] | string | null>(null);

  const { toggleModal: toggleRemoveModal } = useModal(ModalType.REMOVE_PRIORITY_LIST_CONTACTS);
  const { toggleModal: toggleEmailModal } = useModal(ModalType.SEND_EMAIL_TO_CONTACTS);
  const { toggleModal: toggleAddModal } = useModal(ModalType.ADD_CONTACTS_TO_PRIORITY_LIST);

  const { onQueryChange: onViewChange } = useQueryParameter<TPriorityListViewType>({
    queryName: PriorityListQueryName.VIEW,
  });
  const { query: listId } = useQueryParameter({
    queryName: PriorityListQueryName.ID,
  });

  const { data: priorityList = null, isFetching: isDetailsFetching } = useContactsPriorityListById(
    { id: listId as string },
    { enabled: !!listId },
  );
  const { data, isFetching } = usePriorityListContacts({
    id: listId as string,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
  });
  const { mutate: removeContacts, isPending: isRemoveFetching } = usePriorityListBatchRemoveContacts();

  const contacts = useMemo(() => data?.results || [], [data]);

  const columns = useContactColumns();

  const { table } = useDataTable({
    name: TableName.CONTACTS_PRIORITY_LIST,
    columns,
    data: contacts,
    rowCount: data?.count,
    getRowId: (originalRow) => originalRow.id,
    onSelectionChange: (rows, setSelectedRows) => {
      const selectedIds = Object.entries(rows).flatMap(([id, selected]) => (selected ? [id] : []));
      setSelectedRows(
        (prevRows) => [
          ...prevRows,
          ...contacts.map(({ id, email, full_name }) => ({ id, name: full_name ?? email })),
        ]
          .filter((activity, index, self) => index === self.findIndex((a) => a.id === activity.id))
          .filter((activity) => selectedIds.includes(activity.id)),
      );
    },
  });

  const backToListClickHandler = useCallback(() => {
    onViewChange(PriorityListViewType.LIST, {
      onSuccess: (urlParameters) => {
        urlParameters.delete(PriorityListQueryName.ID);
      },
    });
  }, [onViewChange]);

  const removeContactsSubmitHandler = useCallback(() => {
    const selectedRowIds = Object.keys(table.getState().rowSelection);

    if (selectedRowIds.length === 0) {
      return showErrorToast('System Error', 'No contacts selected.');
    }

    removeContacts({
      id: listId as string,
      contacts: selectedRowIds,
    }, {
      onSuccess: () => {
        toggleRemoveModal(false);
        table.options.meta?.clearSelectedRows?.();
      },
    });
  }, [listId, removeContacts, table, toggleRemoveModal]);

  const sendEmailToSelectedHandler = useCallback(() => {
    setSelectedEntity(Object.keys(table.getState().rowSelection));
    toggleEmailModal(true);
  }, [table, toggleEmailModal]);

  const sendEmailToAllHandler = useCallback(() => {
    setSelectedEntity(listId ?? null);
    toggleEmailModal(true);
  }, [toggleEmailModal, listId]);

  return (
    <section className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col items-start gap-4 px-4 pt-4">
        <Button
          type="button"
          variant="link"
          size="link"
          iconLeft={<PurpleIcon name="chevron-left" />}
          className="min-h-7"
          onClick={backToListClickHandler}
        >
          Back to My Priority Lists
        </Button>
        <DetailsHeader isSendEmailAvailable={(data?.count ?? 0) > 0} onSendEmail={sendEmailToAllHandler} />
      </div>
      <div className="flex w-full flex-wrap items-center justify-between gap-4 px-4">
        <Heading tag="h3" type="heading-600" variant="size-18" className="text-grey-title">
          Added Contacts
          {typeof data?.count === 'number'
            ? (
                <span className="text-grey-600 ml-1 text-xs font-semibold">
                  (
                  {data.count}
                  )
                </span>
              )
            : ''}
        </Heading>
        <div className="flex items-center gap-4">
          {Object.keys(table.getState().rowSelection).length > 0 && (
            <Button
              type="button"
              variant="destructive_secondary"
              iconLeft={<PurpleIcon name="trash" />}
              onClick={() => toggleRemoveModal(true)}
            >
              Remove Contacts
            </Button>
          )}
          {Object.keys(table.getState().rowSelection).length > 0 && (
            <Button
              type="button"
              variant="secondary"
              iconLeft={<PurpleIcon name="mail" />}
              onClick={sendEmailToSelectedHandler}
            >
              Send Email to Selected
            </Button>
          )}
          <Button
            type="button"
            variant="secondary"
            disabled={isDetailsFetching}
            iconLeft={<PurpleIcon name="plus" />}
            onClick={() => toggleAddModal(true)}
          >
            Add Contacts
          </Button>
        </div>
      </div>
      <Separator orientation="horizontal" className="bg-grey-200" />
      <DataTable table={table} loading={isFetching}>
        <DataTableSelectedController table={table} displayMode="selected" className="px-4 pb-4" />
      </DataTable>
      <SendEmailDialog
        contacts={Array.isArray(selectedEntity) ? selectedEntity : []}
        priorityListId={typeof selectedEntity === 'string' ? selectedEntity : null}
      />
      <AddContactsDialog priorityList={priorityList} />
      <CallToActionModal
        modalName={ModalType.REMOVE_PRIORITY_LIST_CONTACTS}
        modalTitle="Remove Selected Contacts"
        modalDescription="By removing selected contacts, they will be removed from the priority list."
        modalTextContent="Are you sure you want to remove selected contacts from the priority list?"
        primaryButtonText={isRemoveFetching ? 'Removing...' : 'Remove'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={removeContactsSubmitHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isRemoveFetching}
      />
    </section>
  );
};
