import { BulkFileErrorTableCell, DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TakeActionType } from '@purple/shared-types';
import type { TActionBulkUploadResponse } from '~/services';

export const stakeholderCollaborativeMeetingColumns: ColumnDef<TActionBulkUploadResponse<typeof TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING>>[] = [
  {
    accessorKey: 'row',
    header: ({ column }) => <DataTableColumnHeader column={column} title="#" className="px-2" />,
    cell: ({ row }) => (
      <div className="px-2 py-3">
        <span>{row.original.row}</span>
      </div>
    ),
    size: 50,
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'session_participants',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Session Participants" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.session_participants} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'purple_user_participants',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Purple Users Participants" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.purple_user_participants} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'type',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Type" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.type} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'service_categories',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Service Categories" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.service_categories} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'date_and_time',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date & Time" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.date_and_time} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'duration',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Duration(Minutes)" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.duration} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'student_goals',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Student Goal(s)" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.student_goals} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
  {
    accessorKey: 'students',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Connected Students" className="px-2" />,
    cell: ({ row: { original } }) => (
      <BulkFileErrorTableCell rowData={original.students} tipContentClassName="text-xs" />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: { className: ' px-2 py-0' },
  },
];
