import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Button, Heading, SearchInput, Text } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions } from '~/components';
import { AdminRoutes, ModalType, TableName } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useDeleteGroup, useGroupList } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { useGroupsColumns } from './useGroupsColumns';

export const PermissionsGroupsPage = () => {
  const [searchParameters] = useSearchParams();
  const navigate = useNavigate();
  const { hasPermissions } = usePermissions();

  const { closeModal } = useModal(ModalType.DELETE_PERMISSION_GROUP);

  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: groupList, isLoading: isGroupsLoading } = useGroupList({
    requestParameters: {
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
    },
  });
  const { mutate: deleteGroup, isPending: isGroupDeleting } = useDeleteGroup();

  const groupsColumns = useGroupsColumns({ onSelectGroup: setSelectedGroupId });

  const { table } = useDataTable({
    name: TableName.USER_PERMISSION_GROUPS,
    columns: groupsColumns,
    data: groupList?.results || [],
    rowCount: groupList?.count || 0,
    initialState: {
      columnPinning: {
        left: ['name'],
        right: ['actions'],
      },
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  const closeDeleteModalHandler = () => {
    setSelectedGroupId(null);
    closeModal();
  };

  const deleteGroupHandler = () => {
    if (!selectedGroupId) {
      return showErrorToast('System message', 'Permission Group with provided ID does not exist. Please select another group.');
    }
    deleteGroup(selectedGroupId, {
      onSuccess: () => {
        closeDeleteModalHandler();
      },
    });
  };

  return (
    <>
      <div className="flex w-full flex-col gap-6">
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex flex-col gap-1">
            <Heading tag="h1" variant="size-22" type="heading-600" className="text-grey-title">
              Permissions Groups
            </Heading>
            <Text variant="size-14" type="body-400" className="text-grey-950">
              List of Permissions Group of users for quick permissions managing.
            </Text>
          </div>
          {hasPermissions(APP_PERMISSIONS.CAN_ACCESS_ADMIN_MANAGE_PERMISSIONS) && (
            <Button onClick={() => navigate(AdminRoutes.App.Users.Permissions.ManagePermissions.path)}>
              Create New Group
            </Button>
          )}

        </div>
        <div className="border-grey-200 shadow-custom-heavy w-full gap-4 rounded-lg border bg-white">
          <DataTable table={table} loading={isGroupsLoading}>
            <div className="flex w-full items-center justify-between gap-4 p-4">
              <div className="flex items-center gap-4">
                <SearchInput
                  value={search}
                  placeholder="Search by name "
                  className="max-w-[300px]"
                  onChange={onSearchChange}
                  onClear={onClearSearch}
                />
              </div>
              <DataTableViewOptions table={table} />
            </div>
          </DataTable>
        </div>
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_PERMISSION_GROUP}
        modalTitle="Delete Permission Group"
        modalDescription="Delete Permission Group modal window"
        showModalDescription={false}
        modalTextContent="Are you sure you want to delete this Permission Group? This action can not be undone."
        onPrimaryButtonClick={deleteGroupHandler}
        primaryButtonText="Yes, delete"
        primaryButtonVariant="destructive_primary"
        isLoading={isGroupDeleting}
        onSecondaryButtonClick={closeDeleteModalHandler}
      />
    </>

  );
};
