import axiosInstance from '../axios-config';
import { FILE_ENDPOINTS } from '../endpoints';
import type { TFile, TFileCreatePayload } from '@purple/shared-types';

export const uploadFile = async (payload: TFileCreatePayload) => {
  const { file, title, viewers, is_private, content_type, object_id } = payload;

  const formData = new FormData();
  formData.append('content_type', content_type);
  formData.append('object_id', object_id);
  formData.append('file', file);
  formData.append('title', title);
  formData.append('is_private', String(is_private));

  for (const viewer of viewers) {
    formData.append('viewers', viewer);
  }

  const response = await axiosInstance.post<TFile>(FILE_ENDPOINTS.ROOT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
