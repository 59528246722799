import { useEffect, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounceValue } from 'usehooks-ts';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { DistrictUserStatus } from '@purple/shared-types';
import { multiSelectOptions } from '@purple/shared-utils';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useCurrentUser, useModal } from '~/hooks';
import { usePriorityListBatchAddSharing, usePriorityListBatchRemoveSharing, useUsersListManagement } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import type React from 'react';
import type { TContactPriorityListItem } from '~/services';

const shareWithSchema = z
  .object({
    shared_with: multiSelectOptions,
  });

type TSharePriorityListDialogProperties = {
  priorityList?: Pick<TContactPriorityListItem, 'id' | 'district' | 'shared_with'> | null;
};

export const SharePriorityListDialog: React.FC<TSharePriorityListDialogProperties> = (props) => {
  const { priorityList = null } = props;

  const formId = useId();

  const { user } = useCurrentUser();

  const [userSearch, setUserSearch] = useState<string>('');
  const [debouncedSearchUser] = useDebounceValue(userSearch, 500);

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.SHARE_CONTACTS_PRIORITY_LIST);

  const { mutate: sharePriorityList, isPending: isAddPending } = usePriorityListBatchAddSharing();
  const { mutate: removeSharePriorityList, isPending: isRemovePending } = usePriorityListBatchRemoveSharing();
  const isPending = useMemo(() => isAddPending || isRemovePending, [isAddPending, isRemovePending]);
  const { data, isFetching: isUserFetching } = useUsersListManagement({
    search: debouncedSearchUser,
    limit: 50,
    district: priorityList?.district?.id,
    status: DistrictUserStatus.ACTIVE,
  });

  const userOptions = useMemo(() => (data?.results || [])
    .filter(({ id }) => id !== user?.id)
    .map(({ id, full_name }) => ({
      value: id,
      label: full_name ?? 'Unnamed User',
    })), [data, user]);

  const defaultValues = useMemo(
    () => ({
      shared_with: priorityList?.shared_with?.map(({ id, full_name }) => ({
        value: id,
        label: full_name,
      })) || [],
    }),
    [priorityList],
  );

  const form = useForm<z.infer<typeof shareWithSchema>>({
    resolver: zodResolver(shareWithSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const successCallback = () => {
    closeModal();
    form.reset(defaultValues);
  };

  const sharePriorityListHandler = (data: z.infer<typeof shareWithSchema>) => {
    if (!priorityList) {
      return showErrorToast('System Error', 'Selected priority list is not found.');
    }
    const removedUsers = priorityList.shared_with
      ?.filter(({ id }) => !data.shared_with.some((user) => user.value === id))
      .map(({ id }) => id) || [];
    const addedUsers = data.shared_with
      .filter((item) => !priorityList.shared_with?.some((user) => user.id === item.value))
      .map(({ value }) => value) || [];

    if (addedUsers.length === 0 && removedUsers.length === 0) {
      form.setError('shared_with', {
        type: 'manual',
        message: 'No changes detected.',
      });
    }

    if (removedUsers.length > 0) {
      removeSharePriorityList({
        id: priorityList.id,
        users: removedUsers,
      }, {
        onSuccess: successCallback,
      });
    }
    if (addedUsers.length > 0) {
      sharePriorityList({
        id: priorityList.id,
        users: addedUsers,
      }, {
        onSuccess: successCallback,
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>
            Share Priority List
          </DialogTitle>
          <DialogDescription className="sr-only">
            By sharing a priority list, you allow other users to view and manage the list.
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          id={formId}
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(sharePriorityListHandler)}
        >
          <FormField
            control={form.control}
            name="shared_with"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Share With</FormLabel>
                <FormControl>
                  <MultiSelect
                    isError={!!fieldState.error}
                    selectedOptions={field.value}
                    showSearch
                    searchValue={userSearch}
                    loading={isUserFetching}
                    shouldFilter={false}
                    searchPlaceholder="Search by name"
                    placeholder="Select user(s)"
                    modalPopover
                    onOptionChange={(_, selectedUsers) => field.onChange(selectedUsers)}
                    onSearchChange={setUserSearch}
                  >
                    {userOptions.map((option) => (
                      <MultiSelectItem
                        key={option.value}
                        value={option.value}
                        option={option}
                        isSelected={field.value?.some((item) => item.value === option.value)}
                      />
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} isLoading={isPending}>
            {isPending ? 'Sharing...' : 'Share'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
