import { Link } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminUsersFrequencyDistribution } from '~/services';

type TReportColumns = ColumnDef<TAdminUsersFrequencyDistribution>[];

export const reportColumns: TReportColumns = [
  {
    accessorKey: 'user__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
    cell: ({ row }) => {
      const { id, name } = row.original;
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({
              dynamicParameters: { id },
            })}
            >
              <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{name}</span>
            </Link>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {name}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 100,
    meta: { label: 'Name' },
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
    cell: ({ row }) => {
      const email = row.original.email;
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{email}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {email}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 180,
    meta: { label: 'Email' },
    enableSorting: false,
  },
  {
    accessorKey: 'roles',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
    cell: ({ row }) => {
      const role = row.original.roles?.join(', ');
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{role || '-'}</span>
          </TooltipTrigger>
          <TooltipPortal>
            {Boolean(role) && (
              <TooltipContent align="start" withArrow={false}>
                {role}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 100,
    meta: { label: 'Role' },
    enableSorting: false,
  },
  {
    accessorKey: 'login_count',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Number of logins" />,
    cell: ({ row }) => {
      const login_count = row.original.login_count;
      return (
        <span className="line-clamp-1 break-all text-grey-950">{login_count || '-'}</span>
      );
    },
    size: 50,
    meta: { label: 'Number of logins', className: 'text-center' },
    enableSorting: false,
  },
  {
    accessorKey: 'district__name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row }) => {
      const district = row.original.district;
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{district || '-'}</span>
          </TooltipTrigger>
          <TooltipPortal>
            {Boolean(district) && (
              <TooltipContent align="start" withArrow={false}>
                {district}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 100,
    meta: { label: 'District' },
  },
];
