import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMask } from '@react-input/mask';
import { useDebounceValue } from 'usehooks-ts';
import { PurpleIcon } from '@purple/icons';
import { ActivityType } from '@purple/shared-types';
import { CONTAIN_DIGIT_REGEX, isFieldExist, mergeReferences } from '@purple/shared-utils';
import {
  cn,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
} from '@purple/ui';
import { PHONE_MASK } from '~/constants';
import { useActivityChoices, useSchools, useUpdateActivity, useUpdateRecurringActivity, useUserSchools, useUsersListManagement } from '~/services';
import { ActivitySectionHeader } from '../../components';
import { BasicDetailsInfo } from './BasicDetailsInfo';
import { ALLOWED_TOPIC_TYPES } from './helpers';
import { basicDetailsSchema } from './schema';
import type { z } from 'zod';
import type { TActivityDetails } from '~/services';

type TDetailsSectionProps = {
  data: TActivityDetails;
};

export const DetailsSection: React.FC<TDetailsSectionProps> = (props) => {
  const { data } = props;
  const { id, details } = data;

  const hasChoicesFired = useRef<boolean>(false);

  const inputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [schoolSearch, setSchoolSearch] = useState<string>('');
  const [debouncedSchoolSearch] = useDebounceValue(schoolSearch, 500);
  const [allSchoolSearch, setAllSchoolSearch] = useState<string>('');
  const [debouncedAllSchoolSearch] = useDebounceValue(allSchoolSearch, 500);
  const [userSearch, setUserSearchValue] = useState<string>('');
  const [debouncedUserSearch] = useDebounceValue(userSearch, 500);
  const [participantSearch, setParticipantSearchValue] = useState<string>('');
  const [debouncedParticipantSearch] = useDebounceValue(participantSearch, 500);

  const {
    isIdle,
    mutate: fetchActivityChoices,
    typeOptions,
    serviceCategoriesOptions,
    stakeholdersServedOptions,
    gradeLevelsOptions,
    campusContactTitlesOptions,
    crisisGradesOptions,
    crisisTypesOptions,
    topicsOptions,
  } = useActivityChoices({
    id,
    community_activity_type: details.community_activity_type.name,
  });
  useEffect(() => {
    if (isIdle && !hasChoicesFired.current) {
      fetchActivityChoices({});
      hasChoicesFired.current = true;
    }
  }, [isIdle, fetchActivityChoices]);

  const { mutate: updateActivity, isPending } = useUpdateActivity();
  const { mutate: updateRecurringActivity, isPending: isRecurringPending } = useUpdateRecurringActivity();

  const { data: schoolData, isFetching: isSchoolsFetching } = useUserSchools({
    requestParameters: {
      search: debouncedSchoolSearch,
      user: null, // TODO: Add user id
    },
  });
  const schoolsOptions = useMemo(
    () =>
      schoolData?.results.map((school) => ({
        label: school.name,
        value: school.id,
      })) ?? [],
    [schoolData?.results],
  );
  const { data: allSchoolData, isFetching: isAllSchoolsFetching } = useSchools({
    search: debouncedAllSchoolSearch,
  }, {
    enabled: isFieldExist(details.supported_school),
  });
  const supportedSchoolsOptions = useMemo(
    () =>
      allSchoolData?.results.map((school) => ({
        label: school.name,
        value: school.id,
      })) ?? [],
    [allSchoolData?.results],
  );
  const { data: purpleUsers, isFetching: isUsersFetching } = useUsersListManagement({
    search: debouncedUserSearch,
    school: details.school.id,
    enabled: details.community_activity_type.name === ActivityType.CRISIS_CALL_TRACKER,
  });
  const usersOptions = useMemo(
    () =>
      purpleUsers?.results.map((item) => ({
        ...item,
        label: item.full_name ?? item.email,
        value: item.id,
      })) ?? [],
    [purpleUsers],
  );
  const { data: participants, isFetching: isParticipantsFetching } = useUsersListManagement({
    search: debouncedParticipantSearch,
    school: details.school.id,
    enabled: details.community_activity_type.name === ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT,
  });
  const participantsOptions = useMemo(
    () =>
      participants?.results.map((item) => ({
        ...item,
        label: item.full_name ?? item.email,
        value: item.id,
      })) ?? [],
    [participants],
  );

  const defaultValues: z.infer<typeof basicDetailsSchema> = useMemo(
    () => ({
      isRecurring: details.recurring_group !== null,
      type: details.type,
      schoolId: details.school.id,
      supportedSchoolId: details.supported_school?.id,
      duration: details.duration,
      services: details.service_categories?.map((service) => service.id),
      stakeholders: details.stakeholders_served,
      numberOfPeopleServed: details.number_of_people_served,
      quantityOfResourcesProvided: details.quantity_of_resources_provided,
      bagsProvidedCount: details.bags_provided_count,
      participantsCount: details.participants_count,
      gradeLevel: details.grade_level,
      crisisTypes: details.crisis_type,
      campusContactTitle: details.campus_contact_title,
      campusContactName: details.campus_contact_name,
      contactEmail: details.contact_email,
      contactPhoneNumber: details.contact_phone_number,
      crisisGradeLevel: details.crisis_grade_level,
      assignedTo: details.assigned_to?.id,
      sessionParticipants: details.session_participant?.id,
      topic: details.topic,
    }),
    [details],
  );

  const form = useForm<z.infer<typeof basicDetailsSchema>>({
    resolver: zodResolver(basicDetailsSchema),
    mode: 'onChange',
    defaultValues,
  });
  const formType = form.watch('type');
  const isTopicDisabled = useMemo(() => typeof formType === 'string' && !ALLOWED_TOPIC_TYPES.has(formType), [formType]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof basicDetailsSchema>, recurringId?: number) => {
    const updateCallback = recurringId ? updateRecurringActivity : updateActivity;
    const entityId = recurringId ?? id;

    updateCallback(
      {
        id: entityId,
        name: data.name,
        ...(details.community_activity_type.name !== ActivityType.CRISIS_CALL_TRACKER && {
          description: details.description_of_crisis,
        }),
        details: {
          school: formData.schoolId ?? details.school.id,
          duration: details.duration,
          date_and_time: details.date_and_time,
          ...(isFieldExist(details.type) && { type: formData.type }),
          ...(isFieldExist(details.service_categories) && { service_categories: formData.services }),
          ...(isFieldExist(details.stakeholders_served) && { stakeholders_served: formData.stakeholders }),
          ...(isFieldExist(details.number_of_people_served) && {
            number_of_people_served: formData.numberOfPeopleServed,
          }),
          ...(isFieldExist(details.quantity_of_resources_provided) && {
            quantity_of_resources_provided: formData.quantityOfResourcesProvided,
          }),
          ...(isFieldExist(details.bags_provided_count) && { bags_provided_count: formData.bagsProvidedCount }),
          ...(isFieldExist(details.participants_count) && { participants_count: formData.participantsCount }),
          ...(isFieldExist(details.grade_level) && { grade_level: formData.gradeLevel }),
          ...(isFieldExist(details.crisis_type) && { crisis_type: formData.crisisTypes }),
          ...(isFieldExist(details.campus_contact_title) && { campus_contact_title: formData.campusContactTitle }),
          ...(isFieldExist(details.campus_contact_name) && { campus_contact_name: formData.campusContactName }),
          ...(isFieldExist(details.contact_email) && { contact_email: formData.contactEmail }),
          ...(isFieldExist(details.contact_phone_number) && { contact_phone_number: formData.contactPhoneNumber }),
          ...(isFieldExist(details.crisis_grade_level) && { crisis_grade_level: formData.crisisGradeLevel }),
          ...(isFieldExist(details.assigned_to) && { assigned_to: formData.assignedTo }),
          ...(isFieldExist(details.session_participant) && { session_participant: formData.sessionParticipant }),
          ...(isFieldExist(details.topic) && { topic: formData.topic }),
        },
        ...(data.details.recurring_group !== null && typeof recurringId === 'number' && {
          weekly_interval: data.details.recurring_group?.weekly_interval,
          days_of_week: data.details.recurring_group?.days_of_week,
          start_date_and_time: data.details.recurring_group?.start_date_and_time,
          end_date_and_time: data.details.recurring_group?.end_date_and_time,
        }),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <ActivitySectionHeader
        title="Details"
        editing={isEditing}
        loading={isPending}
        recurringLoading={isRecurringPending}
        allowRecurring={details.recurring_group !== null}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit((data) => saveDetailsClickHandler(data))}
        onRecurringSave={form.handleSubmit((formData) => saveDetailsClickHandler(formData, details.recurring_group?.id))}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              {isFieldExist(details.type) && (
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Type</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select type"
                              selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                            {typeOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={(typeValue) => {
                                  if (!ALLOWED_TOPIC_TYPES.has(typeValue)) {
                                    form.setValue('topic', null);
                                  }
                                  field.onChange(typeValue);
                                }}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.topic) && (
                <FormField
                  control={form.control}
                  name="topic"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Topic</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select topic"
                              selectedLabel={topicsOptions.find((option) => option.value === field.value)?.label}
                              disabled={isTopicDisabled}
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search topic..." emptyContent="Topic not found.">
                            {topicsOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.crisis_type) && (
                <FormField
                  control={form.control}
                  name="crisisTypes"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Crisis Types</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!fieldState.error}
                            selectedOptions={crisisTypesOptions.filter((item) => field.value?.includes(item.value))}
                            placeholder="Select crisis types"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            modalPopover
                            showSearch
                            onOptionChange={field.onChange}
                          >
                            {crisisTypesOptions.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.school) && (
                <FormField
                  control={form.control}
                  name="schoolId"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">School</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select school"
                              selectedLabel={
                                schoolsOptions.find((option) => option.value === field.value)?.label ?? details.school?.name
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isSchoolsFetching}
                            searchPlaceholder="Search school..."
                            emptyContent="School not found."
                            searchValue={schoolSearch}
                            onSearchChange={setSchoolSearch}
                          >
                            {schoolsOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.campus_contact_title) && (
                <FormField
                  control={form.control}
                  name="campusContactTitle"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Campus Contact Title
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select title"
                              selectedLabel={
                                campusContactTitlesOptions.find((option) => option.value === field.value)?.label
                                ?? details.campus_contact_title
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search title..." emptyContent="Title not found.">
                            {campusContactTitlesOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                keywords={[label, value]}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.campus_contact_name) && (
                <FormField
                  control={form.control}
                  name="campusContactName"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Campus Contact Name
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <Input
                            {...field}
                            type="text"
                            isError={!!fieldState.error}
                            placeholder="Enter name"
                            className="max-h-9"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.contact_email) && (
                <FormField
                  control={form.control}
                  name="contactEmail"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Contact Email</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <Input
                            {...field}
                            type="email"
                            isError={!!fieldState.error}
                            placeholder="Enter email"
                            className="max-h-9"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.contact_phone_number) && (
                <FormField
                  control={form.control}
                  name="contactPhoneNumber"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Contact Phone Number
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <Input
                            {...field}
                            ref={mergeReferences([field.ref, inputReference])}
                            isError={!!fieldState.error}
                            placeholder={PHONE_MASK}
                            type="tel"
                            className="max-h-9"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.crisis_grade_level) && (
                <FormField
                  control={form.control}
                  name="crisisGradeLevel"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Crisis Grade Level</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select grade"
                              selectedLabel={
                                crisisGradesOptions.find((option) => option.value === field.value)?.label
                                ?? details.crisis_grade_level
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search grade..." emptyContent="Grade not found.">
                            {crisisGradesOptions
                              .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))
                              .map(({ label, value }) => (
                                <ComboBoxItem
                                  key={value}
                                  value={value}
                                  selected={value === field.value}
                                  keywords={[label, value]}
                                  onSelect={field.onChange}
                                >
                                  {label}
                                </ComboBoxItem>
                              ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.assigned_to) && (
                <FormField
                  control={form.control}
                  name="assignedTo"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Assigned to</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select user"
                              selectedLabel={
                                usersOptions.find((option) => option.value === field.value)?.label
                                ?? details.campus_contact_title
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isUsersFetching}
                            onSearchChange={setUserSearchValue}
                            searchValue={userSearch}
                            searchPlaceholder="Search user..."
                            emptyContent="User not found."
                          >
                            {usersOptions.map(({ value, full_name, email }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                                asChild
                              >
                                <div className="flex items-center gap-1">
                                  {field.value?.includes(value) && (
                                    <PurpleIcon
                                      name="check"
                                      className="absolute left-4 top-1/2 size-4 -translate-y-1/2 text-brand-blue-700"
                                    />
                                  )}
                                  <strong className="line-clamp-1 text-xs font-medium text-grey-950">
                                    {full_name ?? email}
                                  </strong>
                                </div>
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.stakeholders_served) && (
                <FormField
                  control={form.control}
                  name="stakeholders"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Stakeholders Served
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!fieldState.error}
                            selectedOptions={stakeholdersServedOptions.filter((item) => field.value?.includes(item.value))}
                            placeholder="Select stakeholders"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            modalPopover
                            showSearch
                            onOptionChange={field.onChange}
                          >
                            {stakeholdersServedOptions.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.service_categories) && (
                <FormField
                  control={form.control}
                  name="services"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Service Categories</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!fieldState.error}
                            selectedOptions={serviceCategoriesOptions.filter((service) =>
                              field.value?.includes(service.value),
                            )}
                            placeholder="Select categories"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            modalPopover
                            showSearch
                            onOptionChange={field.onChange}
                          >
                            {serviceCategoriesOptions.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.number_of_people_served) && (
                <FormField
                  control={form.control}
                  name="numberOfPeopleServed"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Number of People Served
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!fieldState.error}
                            placeholder="Enter number of people served"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.quantity_of_resources_provided) && (
                <FormField
                  control={form.control}
                  name="quantityOfResourcesProvided"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Quantity of Resources Provided
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!fieldState.error}
                            placeholder="Enter quantity of resources provided"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.bags_provided_count) && (
                <FormField
                  control={form.control}
                  name="bagsProvidedCount"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Quantity of Bags Provided
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!fieldState.error}
                            placeholder="Enter quantity of bags provided"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.supported_school) && (
                <FormField
                  control={form.control}
                  name="supportedSchoolId"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Supported School</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select school"
                              selectedLabel={supportedSchoolsOptions.find((option) => option.value === field.value)?.label}
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isAllSchoolsFetching}
                            searchPlaceholder="Search school..."
                            emptyContent="School not found."
                            searchValue={allSchoolSearch}
                            onSearchChange={setAllSchoolSearch}
                          >
                            {supportedSchoolsOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={(schoolId) =>
                                  schoolId === field.value ? field.onChange(null) : field.onChange(schoolId)}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.grade_level) && (
                <FormField
                  control={form.control}
                  name="gradeLevel"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Grade Level</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!fieldState.error}
                            selectedOptions={gradeLevelsOptions.filter((item) => field.value?.includes(item.value))}
                            placeholder="Select grades"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            modalPopover
                            showSearch
                            onOptionChange={field.onChange}
                          >
                            {gradeLevelsOptions
                              .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))
                              .map((option) => (
                                <MultiSelectItem
                                  key={option.value}
                                  value={option.value}
                                  option={option}
                                  isSelected={field.value?.includes(option.value)}
                                />
                              ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.participants_count) && (
                <FormField
                  control={form.control}
                  name="participantsCount"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Number of Participants
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!fieldState.error}
                            placeholder="Enter number of participants"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.session_participant) && (
                <FormField
                  control={form.control}
                  name="sessionParticipant"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Session Participant
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select participant"
                              selectedLabel={
                                participantsOptions.find((option) => option.value === field.value)?.label
                                ?? details.session_participant?.full_name
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isParticipantsFetching}
                            onSearchChange={setParticipantSearchValue}
                            searchValue={participantSearch}
                            searchPlaceholder="Search user..."
                            emptyContent="User not found."
                          >
                            {participantsOptions.map(({ value, full_name, email }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                                asChild
                              >
                                <div className="flex items-center gap-1">
                                  {field.value?.includes(value) && (
                                    <PurpleIcon
                                      name="check"
                                      className="absolute left-4 top-1/2 size-4 -translate-y-1/2 text-brand-blue-700"
                                    />
                                  )}
                                  <strong className="line-clamp-1 text-xs font-medium text-grey-950">
                                    {full_name ?? email}
                                  </strong>
                                </div>
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
            </Form>
          )
        : (
            <BasicDetailsInfo data={data} />
          )}
    </div>
  );
};
