import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { PurpleIcon } from '@purple/icons';
import { TakeActionDocumentType, TakeActionLinkType } from '@purple/shared-types';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Button, DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm, Form, FormControl, FormField, FormItem, FormMessage, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, Textarea } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useActionType } from '~/services/actions/useActionType';
import { useUpdateActionType } from '~/services/actions/useUpdateActionType';
import { showErrorToast } from '~/utils/toasts';
import { ActionsTabContainer } from '../../components';
import { TypeBasicDetailsSkeleton } from '../components/TypeBasicDetailsSkeleton';
import { ACTION_TYPE_STATUS_OPTIONS, DEFAULT_DOCUMENT_AS_OPTIONS, DEFAULT_LINKED_AS_OPTIONS } from '../constants';

const updateActionTypeSchema = z.object({
  is_active: z.boolean({ message: 'Please select the status of the action type' }),
  usage: z.string().trim().min(1, { message: 'Please enter the usage' }),
  default_document_as: z.nativeEnum(TakeActionDocumentType, { message: 'Please select the default document as' }),
  default_linked_as: z.nativeEnum(TakeActionLinkType, { message: 'Please select the default linked as' }),
});

type TFormValues = z.infer<typeof updateActionTypeSchema>;

export const BasicDetails = () => {
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  const { data, isLoading } = useActionType({ id: id!, queryOptions: { enabled: !!id } });
  const { type, is_active, usage, default_document_as, default_linked_as, created_by, last_modified_by } = data || {};

  const { mutate: updateActionDetails, isPending } = useUpdateActionType();

  const defaultValues: TFormValues = useMemo(() => ({
    is_active: is_active ?? false,
    usage: usage ?? '',
    default_document_as: default_document_as ?? TakeActionDocumentType.GENERAL,
    default_linked_as: default_linked_as ?? TakeActionLinkType.INDIVIDUAL,
  }), [is_active, usage, default_document_as, default_linked_as]);

  const form = useForm<TFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(updateActionTypeSchema),
    defaultValues,
  });

  const toggleEditing = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  const formSubmitHandler = (formData: TFormValues) => {
    if (!id) {
      return showErrorToast('System message', `Unable to update action type with provided id:${id}`);
    }
    updateActionDetails({ id, ...formData }, {
      onSuccess: () => {
        toggleEditing();
      },
    });
  };

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <ActionsTabContainer
      title="Basic Details"
      actions={
        isLoading
          ? null
          : (
              isEditing
                ? (
                    <div className="flex items-center gap-4">
                      <Button variant="secondary" size="small" onClick={toggleEditing}>Cancel</Button>
                      <Button type="submit" variant="primary" size="small" onClick={form.handleSubmit(formSubmitHandler)} disabled={!form.formState.isValid} isLoading={isPending}>Save</Button>
                    </div>
                  )
                : <Button variant="tertiary" size="small" iconLeft={<PurpleIcon name="pencil" className="size-4" />} onClick={toggleEditing}>Edit</Button>
            )
      }
    >
      {isLoading && !data && <TypeBasicDetailsSkeleton />}
      {data && !isLoading && (
        <Form providerProps={form} className="grid grid-cols-2">
          <DescriptionList className="flex flex-col items-start gap-2">
            <DescriptionItem>
              <DescriptionTerm>Status</DescriptionTerm>
              {isEditing
                ? (
                    <FormField
                      control={form.control}
                      name="is_active"
                      render={({ field }) => (
                        <FormItem className="w-1/2">
                          <RadixSelect value={field.value ? 'true' : 'false'} onValueChange={(value) => field.onChange(value === 'true')}>
                            <FormControl>
                              <RadixSelectTrigger className="max-h-9">
                                <RadixSelectValue placeholder="Select provider type" />
                              </RadixSelectTrigger>
                            </FormControl>
                            <RadixSelectContent>
                              {ACTION_TYPE_STATUS_OPTIONS.map((option) => (
                                <RadixSelectItem key={option.label} value={option.value} onSelect={field.onChange}>
                                  {option.label}
                                </RadixSelectItem>
                              ))}
                            </RadixSelectContent>
                          </RadixSelect>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )
                : (<DescriptionDetails>{is_active ? 'Active' : 'Inactive'}</DescriptionDetails>)}
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionTerm>District</DescriptionTerm>
              <DescriptionDetails>{data?.district.name}</DescriptionDetails>
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionTerm>Action Type</DescriptionTerm>
              <DescriptionDetails>{type ? snakeToCamelWithSpaces(type) : 'Unidentified Action Type'}</DescriptionDetails>
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionTerm>Usage</DescriptionTerm>
              {isEditing
                ? (
                    <FormField
                      control={form.control}
                      name="usage"
                      render={({ field, fieldState }) => (
                        <FormItem className="w-1/2">
                          <FormControl>
                            <Textarea {...field} placeholder="Enter usage" className="max-h-32 min-h-20 resize-none" isError={!!fieldState.error} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )
                : (<DescriptionDetails>{usage && usage.length > 0 ? usage : '—'}</DescriptionDetails>)}
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionTerm>Default “Document As”</DescriptionTerm>
              {isEditing
                ? (
                    <FormField
                      control={form.control}
                      name="default_document_as"
                      render={({ field }) => (
                        <FormItem className="w-1/2">
                          <RadixSelect value={field.value} onValueChange={field.onChange}>
                            <FormControl>
                              <RadixSelectTrigger className="max-h-9">
                                <RadixSelectValue placeholder="Select default document as" />
                              </RadixSelectTrigger>
                            </FormControl>
                            <RadixSelectContent>
                              {DEFAULT_DOCUMENT_AS_OPTIONS.map((option) => (
                                <RadixSelectItem key={option.value} value={option.value} onSelect={field.onChange}>
                                  {option.label}
                                </RadixSelectItem>
                              ))}
                            </RadixSelectContent>
                          </RadixSelect>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )
                : (<DescriptionDetails>{default_document_as && default_document_as.length > 0 ? snakeToCamelWithSpaces(default_document_as) : '—' }</DescriptionDetails>)}
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionTerm>Default “Linked As”</DescriptionTerm>
              {isEditing
                ? (
                    <FormField
                      control={form.control}
                      name="default_linked_as"
                      render={({ field }) => (
                        <FormItem className="w-1/2">
                          <RadixSelect value={field.value} onValueChange={field.onChange}>
                            <FormControl>
                              <RadixSelectTrigger className="max-h-9">
                                <RadixSelectValue placeholder="Select default linked as" />
                              </RadixSelectTrigger>
                            </FormControl>
                            <RadixSelectContent>
                              {DEFAULT_LINKED_AS_OPTIONS.map((option) => (
                                <RadixSelectItem key={option.value} value={option.value} onSelect={field.onChange}>
                                  {option.label}
                                </RadixSelectItem>
                              ))}
                            </RadixSelectContent>
                          </RadixSelect>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )
                : (<DescriptionDetails>{default_linked_as && default_linked_as.length > 0 ? snakeToCamelWithSpaces(default_linked_as) : '—' }</DescriptionDetails>)}
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionTerm>Created By</DescriptionTerm>
              <DescriptionDetails>
                {created_by && created_by.full_name && created_by.full_name.length > 0
                  ? (
                      <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: created_by.id } })} className="text-brand-blue-700 transition-colors hover:to-brand-blue-800">
                        {created_by.full_name}
                      </Link>
                    )
                  : 'System' }
              </DescriptionDetails>
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionTerm>Last Modified By</DescriptionTerm>
              <DescriptionDetails>
                {last_modified_by && last_modified_by.full_name && last_modified_by.full_name.length > 0
                  ? (
                      <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({ dynamicParameters: { id: last_modified_by.id } })} className="text-brand-blue-700 transition-colors hover:to-brand-blue-800">
                        {last_modified_by.full_name}
                      </Link>
                    )
                  : '—'}
              </DescriptionDetails>
            </DescriptionItem>
          </DescriptionList>
        </Form>
      )}

    </ActionsTabContainer>
  );
};
