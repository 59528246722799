import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TAdminUsersNotLoggedInReportParameters } from './user.types';

export const getNotLoggedInUsersReportExport = async (requestParameters: TAdminUsersNotLoggedInReportParameters) => {
  const response = await axiosInstance.get<Blob>(ADMIN_USER_MANAGEMENT.NOT_LOGGED_IN_REPORT_EXPORT, {
    params: requestParameters,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response.data;
};
