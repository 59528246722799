import { z } from 'zod';
import { SafSubmitterType } from '@purple/shared-types';

export const sectionSchema = z
  .object({
    needs: z.array(
      z.object({
        id: z.string(),
        name: z.string().trim().min(1, {
          message: 'Need name is required.',
        }),
        submitter_types: z.array(z.nativeEnum(SafSubmitterType)),
      }),
    ),
  })
  .superRefine((data, context) => {
    if (data.needs.length === 0) {
      context.addIssue({
        path: ['needs'],
        code: z.ZodIssueCode.custom,
        message: 'At least one need type is required.',
      });
    }
    if (
      Object.values(SafSubmitterType).some((type) => data.needs.every((need) => !need.submitter_types.includes(type)))
    ) {
      context.addIssue({
        path: ['needs'],
        code: z.ZodIssueCode.custom,
        message: 'At least one submitter type is required for each need type.',
      });
    }
  });
