import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSafsListFilters } from './get-saf-list-filters';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TAdminSafListFiltersResponse } from './saf.types';

export const useSafsListFilters = (params?: { user?: string }) => {
  const { user } = params || {};
  const { isError, error, ...rest } = useQuery<TAdminSafListFiltersResponse, AxiosError>({
    queryKey: [ADMIN_SAF_QUERY_KEYS.LIST_FILTERS, user],
    queryFn: () => getSafsListFilters({ user }),
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to fetch safs list filters');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
