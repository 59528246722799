import { useCallback, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounceValue } from 'usehooks-ts';
import { PurpleIcon } from '@purple/icons';
import { ContactType, ReadableContactType } from '@purple/shared-types';
import { Button, Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, MultiSelect, MultiSelectItem, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, ScrollArea, Separator, Text } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useCreateContact, useStudents } from '~/services';
import { ContactAddressDetailsForm } from './ContactAddressDetailsForm';
import { ContactDetailsForm } from './ContactDetailsForm';
import { addContactSchema } from './schema';
import type { TAddContactFormValues } from './schema';

const DEFAULT_STUDENTS_LIMIT = 100;

export const AddContactModal = () => {
  const { isOpen, closeModal } = useModal(ModalType.ADD_CONTACT);
  const formId = useId();

  const [studentSearch, setSearchValue] = useState<string>('');
  const [debouncedStudentSearch] = useDebounceValue(studentSearch, 500);

  const form = useForm<TAddContactFormValues>({
    mode: 'onChange',
    resolver: zodResolver(addContactSchema),
    defaultValues: {
      contact_type: ContactType.GENERAL,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      district: '',
      country: 'united_states',
      street: '',
      city: '',
      state: '',
      postal_code: '',
      students: [],
    },
  });

  const contactTypeValue = form.watch('contact_type');
  const districtValue = form.watch('district');

  const { data: students, isLoading: isStudentsLoading } = useStudents({
    parameters: {
      search: debouncedStudentSearch,
      limit: DEFAULT_STUDENTS_LIMIT,
      district: districtValue,
      offset: 0,
    },
    queryOptions: {
      enabled: contactTypeValue === ContactType.PARENT_GUARDIAN && districtValue.length > 0,
    },
  });

  const { mutate: createContact, isPending } = useCreateContact();

  const studentsOptions = useMemo(
    () =>
      students?.results.map((student) => ({
        ...student,
        label: student.full_name ?? 'Unidentified student',
        value: student.student_id,
      })) ?? [],
    [students?.results],
  );

  const closeModalHandler = useCallback(() => {
    form.reset();
    closeModal();
  }, [form, closeModal]);

  const addContactHandler = (formData: TAddContactFormValues) => {
    const { students, ...data } = formData;
    createContact({
      ...data,
      address: {
        country: data.country,
        street: data.street,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
      },
      // Conditionally add the students property if the contact type is PARENT_GUARDIAN
      ...(contactTypeValue === ContactType.PARENT_GUARDIAN && { students: students?.map((student) => student.value) }),
    }, {
      onSuccess: () => {
        closeModalHandler();
      },
      onError: (error) => {
        if (error.response?.data.email) {
          form.setError('email', { message: error.response.data.email[0] });
        }
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-full max-w-[calc(100vh-120px)] flex-col xl:max-w-[840px]">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className="leading-5">Add Contact</DialogTitle>
            <DialogDescription className="sr-only">Modal window for adding new contact</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea
          type="auto"
          className="flex size-full max-h-[640px] min-h-[160px] flex-col px-6 py-4"
          scrollBarClassName="p-2 w-[22px]"
        >
          <Form providerProps={form} id={formId} onSubmit={form.handleSubmit(addContactHandler)} className="flex flex-col gap-6">
            <FormField
              control={form.control}
              name="contact_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Contact Type</FormLabel>
                  <RadixSelect
                    value={field.value}
                    onValueChange={(value) => {
                      field.onChange(value);
                      form.setValue('students', []);
                    }}
                  >
                    <FormControl>
                      <RadixSelectTrigger>
                        <RadixSelectValue {...field} placeholder="Select type" />
                      </RadixSelectTrigger>
                    </FormControl>
                    <RadixSelectContent>
                      {Object.values(ContactType).map((type) => (
                        <RadixSelectItem key={type} value={type}>
                          {ReadableContactType[type]}
                        </RadixSelectItem>
                      ))}
                    </RadixSelectContent>
                  </RadixSelect>
                  <FormMessage />
                </FormItem>
              )}
            />
            <ContactDetailsForm />
            <ContactAddressDetailsForm />
            {contactTypeValue === ContactType.PARENT_GUARDIAN && (
              <FormField
                control={form.control}
                name="students"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel required>Students</FormLabel>
                    <FormControl>
                      <MultiSelect
                        onChange={field.onChange}
                        placeholder="Select students"
                        loading={isStudentsLoading}
                        isError={!!fieldState.error}
                        modalPopover
                        disabled={districtValue.length === 0}
                        shouldFilter={false}
                        showSearch
                        searchPlaceholder="Search..."
                        searchValue={studentSearch}
                        onSearchChange={setSearchValue}
                        onOptionChange={(_, options) => field.onChange(options)}
                        selectedOptions={field.value}
                      >
                        {studentsOptions.map((student) => (
                          <MultiSelectItem key={student.id} value={student.value} option={student} customContent>
                            <div className="flex flex-col">
                              <Text variant="size-14" type="body-600" className="text-grey-title">
                                {student.full_name ?? 'Unidentified student'}
                              </Text>
                              <Text variant="size-12" type="body-400" className="text-grey-600">
                                {`Student ID: ${student.student_id}`}
                              </Text>
                            </div>
                          </MultiSelectItem>
                        ))}
                      </MultiSelect>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </Form>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button variant="secondary" onClick={closeModalHandler}>Cancel</Button>
          <Button type="submit" variant="primary" form={formId} isLoading={isPending}>{isPending ? 'Submitting...' : 'Submit'}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
