import { type FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMask } from '@react-input/mask';
import { useDebouncedCallback } from '@purple/hooks';
import { CONTAIN_DIGIT_REGEX, mergeReferences } from '@purple/shared-utils';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage, Input, Text } from '@purple/ui';
import { PHONE_MASK } from '~/constants';
import { useDistrictsListBasicInfo } from '~/services';
import type { TAddContactFormValues } from './schema';

const DEFAULT_SEARCH_DELAY = 400;

export const ContactDetailsForm: FC = () => {
  const { control, setValue } = useFormContext<TAddContactFormValues>();

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string | null>(null);

  const { data: activeDistricts, isFetching } = useDistrictsListBasicInfo({
    search: debouncedSearchValue,
    limit: 50,
    status: 'published',
  });

  const inputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  const districtsSelectOptions = useMemo(() => {
    return (
      activeDistricts?.results.map((district) => ({
        ...district,
        label: district.name,
        value: district.id,
      })) ?? []
    );
  }, [activeDistricts]);

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  return (
    <div className="grid grid-cols-2 gap-4">
      <Text variant="size-16" type="body-600" className="col-span-2 text-grey-title">Contact Details</Text>
      <FormField
        control={control}
        name="first_name"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>First Name</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter first name" isError={!!fieldState.error} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="last_name"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Last Name</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter last name" isError={!!fieldState.error} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="email"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Email</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter email address" isError={!!fieldState.error} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="phone"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel>Phone</FormLabel>
            <FormControl>
              <Input
                {...field}
                ref={mergeReferences([field.ref, inputReference])}
                isError={!!fieldState.error}
                placeholder={PHONE_MASK}
                type="tel"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="district"
        render={({ field, fieldState }) => (
          <FormItem className="col-span-2">
            <FormLabel required>District</FormLabel>
            <ComboBox modal>
              <FormControl>
                <ComboBoxTrigger
                  isError={!!fieldState.error}
                  placeholder="Select district"
                  selectedLabel={
                    districtsSelectOptions.find((option) => option.value.toString() === field.value.toString())?.label
                  }
                />
              </FormControl>
              <ComboBoxContent
                loading={isFetching}
                shouldFilter={false}
                searchPlaceholder="Search district..."
                emptyContent="District not found."
                onSearchChange={debouncedSearch}
              >
                {activeDistricts?.results?.map(({ id, name }) => (
                  <ComboBoxItem
                    key={id}
                    value={id.toString()}
                    selected={field.value.toString() === id.toString()}
                    onSelect={(value) => {
                      field.onChange(value);
                      setValue('students', []);
                    }}
                  >
                    {name}
                  </ComboBoxItem>
                ))}
              </ComboBoxContent>
            </ComboBox>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
