import { SAF_FIELD_SETTINGS_HEADING, SAF_FIELD_SETTINGS_MAPPING } from '~/pages/Main/Saf/constants';
import type { TAdminSubmitterTypeFieldSettingItem, TAdminSubmitterTypeFieldSettings, TAdminSubmitterTypeFieldView } from '~/services';

export const convertFieldSettingsToView = (data: TAdminSubmitterTypeFieldSettings | undefined): TAdminSubmitterTypeFieldView => {
  const { field_settings } = data || {};

  // Initialize field arrays
  const myInformationFields: TAdminSubmitterTypeFieldSettingItem[] = [];
  const studentInformationFields: TAdminSubmitterTypeFieldSettingItem[] = [];
  const needsInformationFields: TAdminSubmitterTypeFieldSettingItem[] = [];

  // Iterate through field_settings and categorize fields
  if (!field_settings || !field_settings.length) {
    return {
      [SAF_FIELD_SETTINGS_HEADING.my_information]: myInformationFields,
      [SAF_FIELD_SETTINGS_HEADING.student_information]: studentInformationFields,
      [SAF_FIELD_SETTINGS_HEADING.needs_information]: needsInformationFields,
    };
  }

  field_settings.forEach((field) => {
    if (SAF_FIELD_SETTINGS_MAPPING[SAF_FIELD_SETTINGS_HEADING.my_information]?.includes(field.field_name)) {
      myInformationFields.push(field);
    } else if (SAF_FIELD_SETTINGS_MAPPING[SAF_FIELD_SETTINGS_HEADING.student_information]?.includes(field.field_name)) {
      studentInformationFields.push(field);
    } else if (SAF_FIELD_SETTINGS_MAPPING[SAF_FIELD_SETTINGS_HEADING.needs_information]?.includes(field.field_name)) {
      needsInformationFields.push(field);
    }
  });

  // Return categorized fields
  return {
    [SAF_FIELD_SETTINGS_HEADING.my_information]: myInformationFields,
    [SAF_FIELD_SETTINGS_HEADING.student_information]: studentInformationFields,
    [SAF_FIELD_SETTINGS_HEADING.needs_information]: needsInformationFields,
  };
};
