import axiosInstance from '../axios-config';
import { CONTACTS_ENDPOINTS } from '../endpoints';
import type { TContactListResponse, TContactsListRequestParameters } from './contacts.types';

export const getContactsList = async ({ token, ...parameters }: TContactsListRequestParameters) => {
  const response = await axiosInstance.get<TContactListResponse>(CONTACTS_ENDPOINTS.ROOT, {
    params: parameters,
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
  });
  return response.data;
};
