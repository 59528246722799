import { useMemo } from 'react';
import { formatDateShortMonth, formatDateShortMonthWithTime } from '@purple/shared-utils';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { FileAction } from './FileAction';
import type { ColumnDef } from '@tanstack/react-table';
import type { TFileListItem } from '@purple/shared-types';

type TUseActivityFileColumns = (options?: {
  onSelectFileId: (fileId: string) => void;
  onSelectFile: (file: File | null) => void;
}) => ColumnDef<TFileListItem>[];

export const useActivityFileColumns: TUseActivityFileColumns = (options) => {
  const { onSelectFile, onSelectFileId } = options ?? {};

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'title',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Title" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <a href={original.file} target="_blank" rel="noreferrer" className="line-clamp-1 w-fit break-all text-brand-blue-700 transition-colors hover:text-brand-blue-800">
                  {original.title}
                </a>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {original.title}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 220,
          meta: { label: 'Title' },
        },
        {
          accessorKey: 'created_by__last_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Owner" />,
          cell: ({ row: { original: { created_by } } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {created_by?.full_name || 'System'}
                </span>
              </TooltipTrigger>
              {created_by && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {created_by?.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 160,
          meta: { label: 'Owner' },
        },
        {
          accessorKey: 'update_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Last Modified" />,
          cell: ({ row: { original: { updated_at } } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {formatDateShortMonth(updated_at)}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {formatDateShortMonthWithTime(updated_at)}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 160,
          meta: { label: 'Last Modified' },
        },
        {
          accessorKey: 'size',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Size" />,
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {original.size.toFixed(2)}
                  {' '}
                  MB
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {(original.size * 1024).toFixed(1)}
                  {' '}
                  KB
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 130,
          meta: { label: 'Size' },
        },
        {
          accessorKey: 'actions',
          header: () => null,
          cell: ({ row }) => (
            <FileAction item={row.original} onSelect={onSelectFileId} onReplace={onSelectFile} />
          ),
          size: 60,
          enableHiding: false,
          enableSorting: false,
          meta: { className: 'text-right' },
        },
      ] satisfies ColumnDef<TFileListItem>[],
    [onSelectFileId, onSelectFile],
  );

  return columns;
};
