import axiosInstance from '~/services/axios-config';
import { ACTIVITIES_ENDPOINTS } from '../../endpoints';
import type { TActivityBulkDeletePayload, TActivityDeleteResponse } from '../activities.types';

export const bulkActivitiesDelete = async (payload: TActivityBulkDeletePayload) => {
  const response = await axiosInstance.post<TActivityDeleteResponse>(
    ACTIVITIES_ENDPOINTS.BULK_DELETE,
    payload,
  );
  return response.data;
};
