import { useNavigate } from 'react-router-dom';
import { ReadableSafSubmitterType } from '@purple/shared-types';
import { formatDateShortMonth } from '@purple/shared-utils';
import { Badge, Button, cn, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import { ListItemOptions } from './components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminSubmitterType } from '~/services';

type TSubmitterTypesColumns = ColumnDef<TAdminSubmitterType>[];

type TUseSubmitterTypesColumnsProperties = {
  onDeactivate: (type: TAdminSubmitterType) => void;
  onActivate: (type: TAdminSubmitterType) => void;
};

export const useSubmitterTypesColumns = ({ onDeactivate, onActivate }: TUseSubmitterTypesColumnsProperties): TSubmitterTypesColumns => {
  const navigate = useNavigate();

  const redirectToUserDetails = (event: React.MouseEvent<HTMLButtonElement>, id: string | undefined) => {
    event.stopPropagation();
    event.preventDefault();

    if (id) {
      navigate(AdminRoutes.App.Users.UserDetail.Root.makePath({
        dynamicParameters: { id },
      }));
    }
  };

  return [
    {
      accessorKey: 'type',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Submitter" />,
      cell: ({ row }) => {
        const typeLabel = ReadableSafSubmitterType[row.original.type];
        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="line-clamp-1 break-all text-grey-950">{typeLabel}</span>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                {typeLabel}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 100,
      meta: { label: 'Submitter' },
      enableSorting: false,
    },
    {
      accessorKey: 'needs',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Available need types" />,
      cell: ({ row }) => {
        const type = row.original.type;
        const needs = row.original.needs.filter((need) => need.submitter_types.includes(type));
        const needsNames = needs.map((need) => need.name);
        const needsNamesLabel = needsNames.length ? needsNames.join(', ') : '-';

        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="line-clamp-1 break-all text-grey-950">{needsNamesLabel}</span>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                <div className="flex flex-col gap-1">
                  {
                    needsNames?.map((need) => (
                      <span key={need}>{need}</span>
                    ))
                  }
                </div>
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 180,
      meta: { label: 'Available need types' },
      enableSorting: false,
    },
    {
      accessorKey: 'is_active',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
      cell: ({ row }) => {
        const isActive = row.original.is_active;
        const label = isActive ? 'Active' : 'Inactive';
        return (
          <Badge
            label={label}
            className={cn({
              'text-success-main bg-success-bg': isActive,
              'text-grey-900 bg-grey-100': !isActive,
            })}
          />
        );
      },
      size: 100,
      meta: { label: 'Status' },
      enableSorting: false,
    },
    {
      accessorKey: 'last_modified_by',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Last modified by" />,
      cell: ({ row }) => {
        if (!row.original.last_modified_by) {
          return <span className="line-clamp-1 break-all text-grey-950">-</span>;
        }

        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="link" size="link" onClick={(event) => redirectToUserDetails(event, row.original.last_modified_by?.id)}>
                <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{row.original.last_modified_by.full_name}</span>
              </Button>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                {row.original.last_modified_by.full_name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 100,
      meta: { label: 'Last modified by' },
      enableSorting: false,
    },
    {
      accessorKey: 'updated_at',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Date & time" />,
      cell: ({ row }) => {
        const updated_at = row.original.updated_at;
        const dateString = updated_at ? formatDateShortMonth(updated_at) : '-';
        return (
          <span className="line-clamp-1 break-all text-grey-950">{dateString}</span>
        );
      },
      size: 100,
      meta: { label: 'Date & time' },
      enableSorting: false,
    },
    {
      id: 'options',
      cell: ({ row }) => (
        <div>
          <ListItemOptions isActive={row.original.is_active} onDeactivate={() => onDeactivate(row.original)} onActivate={() => onActivate(row.original)} />
        </div>
      ),
      size: 10,
      meta: { className: 'text-right' },
    },
  ];
};
