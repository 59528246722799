export const constructNavigationUrl = (
  baseUrl: string,
  parameters?: Record<string, string | number | boolean | undefined | null>,
): string => {
  const urlSearchParameters = new URLSearchParams();
  if (!parameters) {
    return baseUrl;
  }

  for (const [key, value] of Object.entries(parameters)) {
    if (value !== undefined && value !== null) {
      urlSearchParameters.append(key, value.toString());
    }
  }

  return `${baseUrl}?${urlSearchParameters.toString()}`;
};

/**
 * Checks if the given string is a valid URL.
 *
 * @param url - The string to be validated as a URL.
 * @returns `true` if the string is a valid URL, otherwise `false`.
 */
export const isValidURL = (url: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch {
    return false;
  }
};
