import { Fragment } from 'react';
import { Separator, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { TSafLanguages } from '~/pages/Main/Saf/constants';
import type { TAdminSubmitterTypeTextTranslation } from '~/services';

type TTextTranslationTableViewProperties = {
  data: TAdminSubmitterTypeTextTranslation;
  selectedLanguage: TSafLanguages;
};

const TextTranslationTableView = ({ data, selectedLanguage }: TTextTranslationTableViewProperties) => {
  const { needs } = data;
  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-2 items-center px-[20px] py-4">
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">FIELD NAME</Text>
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">TRANSLATION</Text>
      </div>
      <Separator className="bg-grey-200" />
      <div className="px-[20px] py-3">
        <Text variant="size-14" type="body-700" className="text-grey-950">SAF Needs</Text>
      </div>
      {needs.map((field) => (
        <Fragment key={field.id}>
          <Separator className="bg-grey-200" />
          <div className="grid grid-cols-2 items-center px-[20px] py-4">
            <Tooltip>
              <TooltipTrigger asChild className="w-fit">
                <span className="line-clamp-1 break-all text-sm font-medium text-grey-950">{field.name}</span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {field.name}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild className="w-fit">
                <span className="line-clamp-1 break-all text-sm font-medium text-grey-950">{field.translations[selectedLanguage] || '-'}</span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {field.translations[selectedLanguage] || '-'}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export { TextTranslationTableView };
