import { useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { cn, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@purple/ui';
import { useAppSelector } from '~/hooks';
import { type TDistrictLeaderUserStep, useRoleSearch } from '~/services';
import { districtIdSelector } from '~/store/features/district-set-up';
import { DEFAULT_SEARCH_DELAY } from './constants';
import type { FieldArrayWithId, UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';
import type { leaderUsersSchema } from './schema';

type TDistrictLeaderRowProperties = {
  form: UseFormReturn<z.infer<typeof leaderUsersSchema>>;
  index: number;
  user: FieldArrayWithId<TDistrictLeaderUserStep, 'users', 'id'>;
  onRemove: (index: number) => void;
  usersCount: number;
};

const DistrictLeaderRow = ({ form, user, index, onRemove, usersCount }: TDistrictLeaderRowProperties) => {
  const districtId = useAppSelector(districtIdSelector);

  const [rolesDebouncedSearchValue, setRolesDebouncedSearchValue] = useState<string>('');

  const formNoDistrictLeaderAvailable = form.watch('no_district_leader_available');

  const { data: allRoles, isLoading: isRolesLoading } = useRoleSearch(
    {
      requestParameters: {
        search: rolesDebouncedSearchValue,
        district: districtId?.toString(),
      },
    },
  );

  const rolesDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setRolesDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const rolesSelectOptions = useMemo(() => {
    return (
      allRoles?.results.map((role) => ({
        label: role.name,
        value: role.id.toString(),
      })) ?? []
    );
  }, [allRoles]);

  return (
    <div key={user.id} className="grid w-full grid-cols-2 gap-4">
      <FormField
        control={form.control}
        name={`users.${index}.email`}
        render={({ field }) => (
          <FormItem>
            <FormLabel required>Email</FormLabel>
            <FormControl>
              <Input
                {...field}
                isError={!!form.formState.errors.users?.[index]?.email}
                placeholder="Enter district leader mail address"
                type="text"
                disabled={formNoDistrictLeaderAvailable}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="flex flex-row flex-nowrap gap-2">
        <FormField
          control={form.control}
          name={`users.${index}.prounitas_roles`}
          render={({ field, fieldState }) => (
            <FormItem className="flex-1">
              <FormLabel required>Choose Role</FormLabel>
              <div className="flex flex-row items-center gap-2">
                <ComboBox modal>
                  <FormControl>
                    <ComboBoxTrigger
                      isError={!!fieldState.error}
                      placeholder="Select role"
                      selectedLabel={
                        rolesSelectOptions.find(
                          (option) => option.value.toString() === field.value?.toString(),
                        )?.label
                      }
                      className="max-w-[530px]"
                      disabled={formNoDistrictLeaderAvailable || isRolesLoading}
                    />
                  </FormControl>
                  <ComboBoxContent shouldFilter={false} searchPlaceholder="Search role..." emptyContent="Role not found." onSearchChange={rolesDebouncedSearch} loading={isRolesLoading}>
                    {rolesSelectOptions.map(({ label, value }) => (
                      <ComboBoxItem
                        key={value}
                        value={value}
                        selected={value.toString() === field.value?.toString()}
                        onSelect={field.onChange}
                      >
                        {label}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
                <div className="size-[20px]">
                  <button
                    type="button"
                    onClick={() => onRemove(index)}
                    className={cn('flex', {
                      hidden: usersCount === 1 || formNoDistrictLeaderAvailable,
                    })}
                  >
                    <PurpleIcon name="trash" className="size-[20px] text-error-main" />
                  </button>
                </div>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export { DistrictLeaderRow };
