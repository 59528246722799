import { nativeEnum, z } from 'zod';
import { ActivityWeeklyOccurrence, ReminderDaysOfWeek } from '@purple/shared-types';
import { isFieldExist, zPhoneNumber } from '@purple/shared-utils';

export const dateAndTimeSchema = z.object({
  isRecurring: z.boolean(),
  date: z
    .date({
      message: 'Date is invalid.',
    })
    .optional(),
  startDate: z
    .date({
      message: 'Start Date is invalid.',
    })
    .optional(),
  endDate: z
    .date({
      message: 'End Date is invalid.',
    })
    .optional(),
  duration: z
    .number({
      message: 'Duration must be an integer.',
    })
    .int()
    .gt(0, {
      message: 'Duration must be greater than zero.',
    })
    .max(720, { message: 'Duration must be less than 720 minutes.' })
    .optional(),
  interval: z.nativeEnum(ActivityWeeklyOccurrence).optional(),
  daysOfWeek: z.array(nativeEnum(ReminderDaysOfWeek)).optional(),
}).superRefine((data, context) => {
  if (isFieldExist(data.date) && !data.date) {
    context.addIssue({
      path: ['date'],
      code: z.ZodIssueCode.custom,
      message: 'Date and time is required.',
    });
  }
  if (isFieldExist(data.duration) && !data.duration) {
    context.addIssue({
      path: ['duration'],
      code: z.ZodIssueCode.custom,
      message: 'Duration is required.',
    });
  }
  if (data.isRecurring && !data.startDate) {
    context.addIssue({
      path: ['startDate'],
      code: z.ZodIssueCode.custom,
      message: 'Start date and time is required.',
    });
  }
  if (data.isRecurring && !data.endDate) {
    context.addIssue({
      path: ['endDate'],
      code: z.ZodIssueCode.custom,
      message: 'End date and time is required.',
    });
  }
  if (data.isRecurring && !data.interval) {
    context.addIssue({
      path: ['interval'],
      code: z.ZodIssueCode.custom,
      message: 'Weekly occurrence is required.',
    });
  }
  if (data.isRecurring && (!data.daysOfWeek || data.daysOfWeek.length === 0)) {
    context.addIssue({
      path: ['daysOfWeek'],
      code: z.ZodIssueCode.custom,
      message: 'Days of week is required.',
    });
  }
});

export const basicDetailsSchema = z
  .object({
    isRecurring: z.boolean(),
    type: z
      .string({
        invalid_type_error: 'Type name must be a string.',
      })
      .trim()
      .optional(),
    schoolId: z.string().optional(),
    supportedSchoolId: z.string().nullish(),
    numberOfPeopleServed: z
      .number({
        message: 'Number of people served must be an integer.',
      })
      .int()
      .gt(0, {
        message: 'Number of people served must be greater than zero.',
      })
      .optional(),
    quantityOfResourcesProvided: z
      .number({ message: 'Quantity of resources provided must be an integer.' })
      .gt(0, { message: 'Quantity of resources provided must be greater than zero.' })
      .nullish(),
    bagsProvidedCount: z
      .number({ message: 'Bags provided count must be an integer.' })
      .int()
      .gt(0, { message: 'Bags provided count must be greater than zero.' })
      .optional(),
    participantsCount: z
      .number({ invalid_type_error: 'Participants count must be an integer.' })
      .gt(0, { message: 'Participants count must be greater than zero.' })
      .nullish(),
    services: z.array(z.string()).optional(),
    crisisTypes: z.array(z.string()).optional(),
    stakeholders: z.array(z.string()).optional(),
    gradeLevel: z.array(z.string()).optional(),
    campusContactTitle: z.string().optional(),
    campusContactName: z.string().optional(),
    contactEmail: z
      .string()
      .email({
        message: 'Contact email is invalid.',
      })
      .optional(),
    contactPhoneNumber: z.string().optional(),
    crisisGradeLevel: z.string().optional(),
    assignedTo: z.string().nullish(),
    sessionParticipant: z.string().optional(),
    topic: z.string().nullish(),
  })
  .superRefine(async (data, context) => {
    if (isFieldExist(data.type) && data.type.length === 0) {
      context.addIssue({
        path: ['type'],
        code: z.ZodIssueCode.custom,
        message: 'Type is required.',
      });
    }
    if (isFieldExist(data.schoolId) && !data.schoolId) {
      context.addIssue({
        path: ['schoolId'],
        code: z.ZodIssueCode.custom,
        message: 'School is required.',
      });
    }
    if (isFieldExist(data.services) && data.services.length === 0) {
      context.addIssue({
        path: ['services'],
        code: z.ZodIssueCode.custom,
        message: 'At least one service is required.',
      });
    }
    if (isFieldExist(data.crisisTypes) && data.crisisTypes.length === 0) {
      context.addIssue({
        path: ['crisisTypes'],
        code: z.ZodIssueCode.custom,
        message: 'At least one crisis type is required.',
      });
    }
    if (isFieldExist(data.stakeholders) && data.stakeholders.length === 0) {
      context.addIssue({
        path: ['stakeholders'],
        code: z.ZodIssueCode.custom,
        message: 'At least one stakeholder is required.',
      });
    }
    if (isFieldExist(data.numberOfPeopleServed) && !data.numberOfPeopleServed) {
      context.addIssue({
        path: ['numberOfPeopleServed'],
        code: z.ZodIssueCode.custom,
        message: 'Number of people served is required.',
      });
    }
    if (isFieldExist(data.sessionParticipant) && !data.sessionParticipant) {
      context.addIssue({
        path: ['sessionParticipant'],
        code: z.ZodIssueCode.custom,
        message: 'Session participant is required.',
      });
    }
    if (isFieldExist(data.bagsProvidedCount) && !data.bagsProvidedCount) {
      context.addIssue({
        path: ['bagsProvidedCount'],
        code: z.ZodIssueCode.custom,
        message: 'Bags provided count is required.',
      });
    }
    if (isFieldExist(data.campusContactTitle) && !data.campusContactTitle) {
      context.addIssue({
        path: ['campusContactTitle'],
        code: z.ZodIssueCode.custom,
        message: 'Campus contact title is required.',
      });
    }
    if (isFieldExist(data.campusContactName) && !data.campusContactName) {
      context.addIssue({
        path: ['campusContactName'],
        code: z.ZodIssueCode.custom,
        message: 'Campus contact name is required.',
      });
    }
    if (isFieldExist(data.contactEmail) && !data.contactEmail) {
      context.addIssue({
        path: ['contactEmail'],
        code: z.ZodIssueCode.custom,
        message: 'Contact email is required.',
      });
    }
    if (isFieldExist(data.contactPhoneNumber) && !data.contactPhoneNumber) {
      context.addIssue({
        path: ['contactPhoneNumber'],
        code: z.ZodIssueCode.custom,
        message: 'Contact phone number is required.',
      });
    }
    if (isFieldExist(data.contactPhoneNumber) && data.contactPhoneNumber.length > 0) {
      const phone = await zPhoneNumber.parseAsync(data.contactPhoneNumber, { path: ['phone'] });
      if (phone === null) {
        context.addIssue({
          path: ['phone'],
          code: z.ZodIssueCode.custom,
          message: 'Invalid phone number.',
        });
      }
    }
    if (isFieldExist(data.crisisGradeLevel) && !data.crisisGradeLevel) {
      context.addIssue({
        path: ['crisisGradeLevel'],
        code: z.ZodIssueCode.custom,
        message: 'Crisis grade level is required.',
      });
    }
  });
