import * as z from 'zod';

export const updateSubmissionDetailsSchema = z.object({
  submitter_first_name: z.string().trim(),
  submitter_last_name: z.string().trim(),
  submitter_email: z.string().trim(),
  submitter_phone: z.string().trim(),

  submitter_type: z.string().trim().min(1, {
    message: 'Type is required.',
  }),
  submitted_school: z.string().trim().min(1, {
    message: 'School is required.',
  }),
  submitted_student_grade: z.string(),
  is_submitted_anonymously: z.boolean(),
  submitted_student_first_name: z.string().trim().min(1, {
    message: 'First Name is required.',
  }),
  submitted_student_middle_name: z.string().optional(),
  submitted_student_last_name: z.string().trim().min(1, {
    message: 'Last Name is required.',
  }),
}).superRefine((data, context) => {
  if (!data.is_submitted_anonymously && data.submitter_first_name === '') {
    context.addIssue({
      path: ['submitter_first_name'],
      code: z.ZodIssueCode.custom,
      message: 'First Name is required.',
    });
  }
  if (!data.is_submitted_anonymously && data.submitter_last_name === '') {
    context.addIssue({
      path: ['submitter_last_name'],
      code: z.ZodIssueCode.custom,
      message: 'Last Name is required.',
    });
  }
  if (!data.is_submitted_anonymously && data.submitter_email === '') {
    context.addIssue({
      path: ['submitter_email'],
      code: z.ZodIssueCode.custom,
      message: 'Email is required.',
    });
  }
  if (!data.is_submitted_anonymously && data.submitter_phone === '') {
    context.addIssue({
      path: ['submitter_phone'],
      code: z.ZodIssueCode.custom,
      message: 'Phone is required.',
    });
  }
});
