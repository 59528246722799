import { useEffect, useMemo, useState } from 'react';
import { useMask } from '@react-input/mask';
import { useDebouncedCallback } from '@purple/hooks';
import { ReadableSafSubmitterType, SafSubmitterType } from '@purple/shared-types';
import { CONTAIN_DIGIT_REGEX, cutGradeName, mergeReferences } from '@purple/shared-utils';
import { Checkbox, cn, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, Form, FormControl, FormField, FormItem, FormMessage, Input, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, Text } from '@purple/ui';
import { type TAdminSafBasicDetailsView, useUserSchools } from '~/services';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { updateSubmissionDetailsSchema } from './schema';

export const SAF_PHONE_MASK = '+1 (___) ___-____';

type TSubmissionDetailsEditProperties = {
  data: TAdminSafBasicDetailsView;
  form: UseFormReturn<z.infer<typeof updateSubmissionDetailsSchema>>;
};

const SubmissionDetailsEdit = ({ data, form }: TSubmissionDetailsEditProperties) => {
  const { district } = data;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data: schoolsData, isLoading: isSchoolsLoading } = useUserSchools({
    requestParameters: {
      district,
      search: debouncedSearchValue,
      limit: 10,
    },
    enabled: Boolean(district),
  });

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  const selectedSchoolId = form.watch('submitted_school');
  const selectedGrade = form.watch('submitted_student_grade');

  const schools = useMemo(() => schoolsData?.results ?? [], [schoolsData?.results]);
  const selectedList = useMemo(() => schools ? schools.find((school) => school.id.toString() === selectedSchoolId.toString()) : null, [selectedSchoolId, schools]);

  useEffect(() => {
    if (schools.length > 0 && selectedSchoolId && selectedGrade) {
      const foundSchool = schools.find((school) => school.id.toString() === selectedSchoolId.toString());

      if (!foundSchool || !foundSchool.grades.includes(selectedGrade)) {
        form.setValue('submitted_student_grade', '');
      }
    }
  }, [form, selectedSchoolId, schools, selectedGrade]);

  const gradesOptions = useMemo(() => {
    if (!selectedList) return [];

    return selectedList.grades.map((grade) => ({
      label: cutGradeName(grade),
      value: grade,
    }));
  }, [selectedList]);

  const inputReference = useMask({
    mask: SAF_PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  const typeOptions = useMemo(() => {
    return Object.values(SafSubmitterType).map((value) => ({
      label: ReadableSafSubmitterType[value],
      value,
    }));
  }, []);

  return (
    <Form providerProps={form} className="flex w-full flex-col gap-[22px] pb-2">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitter First Name</Text>
        <FormField
          control={form.control}
          name="submitter_first_name"
          render={({ field, fieldState }) => (
            <FormItem className="relative w-full">
              <FormControl>
                <Input
                  {...field}
                  isError={!!fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter first name"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitter Last Name</Text>
        <FormField
          control={form.control}
          name="submitter_last_name"
          render={({ field, fieldState }) => (
            <FormItem className="relative w-full">
              <FormControl>
                <Input
                  {...field}
                  isError={!!fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter last name"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitter Type</Text>

        <FormField
          control={form.control}
          name="submitter_type"
          render={({ field, fieldState }) => (
            <FormItem className="w-full">
              <RadixSelect
                onValueChange={field.onChange}
                value={field.value ?? undefined}
              >
                <FormControl>
                  <RadixSelectTrigger
                    className={cn('h-8 min-w-48 gap-1 px-3 py-1', {
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select type" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {typeOptions.map(({ label, value }) => (
                    <RadixSelectItem key={value} value={value}>
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitter Phone</Text>
        <FormField
          control={form.control}
          name="submitter_phone"
          render={({ field }) => (
            <FormItem className="relative w-full">
              <FormControl>
                <Input
                  {...field}
                  ref={mergeReferences([field.ref, inputReference])}
                  isError={!!form.formState.errors.submitter_phone}
                  placeholder={SAF_PHONE_MASK}
                  type="tel"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitter Email</Text>
        <FormField
          control={form.control}
          name="submitter_email"
          render={({ field }) => (
            <FormItem className="relative w-full">
              <FormControl>
                <Input
                  {...field}
                  placeholder="Enter email"
                  type="email"
                  isError={!!form.formState.errors.submitter_email}
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted School</Text>
        <FormField
          control={form.control}
          name="submitted_school"
          render={({ field, formState }) => (
            <FormItem className="w-full">
              <FormControl>
                <ComboBox modal>
                  <ComboBoxTrigger isError={!!formState.errors.submitted_school?.message} placeholder="Select school" selectedLabel={selectedList?.name} className="h-[34px]" />
                  <ComboBoxContent
                    loading={isSchoolsLoading}
                    shouldFilter={false}
                    searchPlaceholder="Search school..."
                    emptyContent="School not found."
                    onSearchChange={debouncedSearch}
                  >
                    {schools.map(({ id, name }) => (
                      <ComboBoxItem key={id} value={id.toString()} selected={field.value.toString() === id.toString()} onSelect={field.onChange}>
                        {name}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Grade</Text>
        <FormField
          control={form.control}
          name="submitted_student_grade"
          render={({ field }) => (
            <FormItem className="w-full">
              <RadixSelect value={field.value} onValueChange={field.onChange} disabled={!selectedSchoolId || gradesOptions.length === 0}>
                <FormControl>
                  <RadixSelectTrigger className="h-[34px]" disabled={!selectedSchoolId || gradesOptions.length === 0}>
                    <RadixSelectValue placeholder="Select grade" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {gradesOptions.map((option) => (
                    <RadixSelectItem key={option.value} value={option.value} onSelect={field.onChange}>
                      {option.label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Anonymously</Text>
        <FormField
          control={form.control}
          name="is_submitted_anonymously"
          render={({ field }) => (
            <FormItem className="flex w-full items-center gap-2 space-y-0">
              <FormControl>
                <Checkbox
                  {...field}
                  value={field.value?.toString()}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Student First Name</Text>
        <FormField
          control={form.control}
          name="submitted_student_first_name"
          render={({ field, fieldState }) => (
            <FormItem className="relative w-full">
              <FormControl>
                <Input
                  {...field}
                  isError={!!fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter first name"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Student Middle Name</Text>
        <FormField
          control={form.control}
          name="submitted_student_middle_name"
          render={({ field, fieldState }) => (
            <FormItem className="relative w-full">
              <FormControl>
                <Input
                  {...field}
                  isError={!!fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter middle name"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Student Last Name</Text>
        <FormField
          control={form.control}
          name="submitted_student_last_name"
          render={({ field, fieldState }) => (
            <FormItem className="relative w-full">
              <FormControl>
                <Input
                  {...field}
                  isError={!!fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter last name"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
      </div>
    </Form>
  );
};

export { SubmissionDetailsEdit };
