import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { exportDocumentedActionsList } from './export-documented-actions-list';
import type { AxiosError } from 'axios';
import type { TDocumentedActionsRequestParameters } from './actions.types';

export const useDocumentedActionsExportMutation = () => {
  // NOTE: we use useMutation hook to export safs by clicking on the button (or another trigger)
  return useMutation<Blob, AxiosError, TDocumentedActionsRequestParameters>({
    mutationKey: [ACTIONS_QUERY_KEYS.DOCUMENTED_ACTIONS_EXPORT],
    mutationFn: exportDocumentedActionsList,

    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not export actions. Check the provided information and try again');
      }
    },
    onSuccess: (data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      link.href = url;
      link.download = 'documented-actions-list.xlsx';
      document.body.append(link);
      link.click();
      link.remove();

      // Clean up the URL object after the download is complete to avoid memory leaks
      window.URL.revokeObjectURL(url);
    },
  });
};
