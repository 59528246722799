import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { ActivityWeeklyOccurrence } from '@purple/shared-types';
import { isFieldExist } from '@purple/shared-utils';
import {
  cn,
  DatePicker,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { type TActivityDetails, useUpdateActivity, useUpdateRecurringActivity } from '~/services';
import { ActivitySectionHeader } from '../../components';
import { DAYS_OF_WEEK_OPTIONS } from './helpers';
import { dateAndTimeSchema } from './schema';
import type { FC } from 'react';
import type { z } from 'zod';

type TDateTimeSectionProps = {
  data: TActivityDetails;
};
export const DateTimeSection: FC<TDateTimeSectionProps> = memo((props) => {
  const { data } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateActivity, isPending } = useUpdateActivity();
  const { mutate: updateRecurringActivity, isPending: isRecurringPending } = useUpdateRecurringActivity();

  const defaultValues: z.infer<typeof dateAndTimeSchema> = useMemo(
    () => ({
      isRecurring: data.details.recurring_group !== null,
      date: data.details.date_and_time
        ? new Date(data.details.date_and_time)
        : undefined,
      startDate: data.details.recurring_group?.start_date_and_time
        ? new Date(data.details.recurring_group.start_date_and_time)
        : undefined,
      endDate: data.details.recurring_group?.end_date_and_time
        ? new Date(data.details.recurring_group.end_date_and_time)
        : undefined,
      duration: data.details.duration,
      daysOfWeek: data.details.recurring_group?.days_of_week,
      interval: data.details.recurring_group?.weekly_interval,
    }),
    [data],
  );

  const form = useForm<z.infer<typeof dateAndTimeSchema>>({
    resolver: zodResolver(dateAndTimeSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof dateAndTimeSchema>, recurringId?: number) => {
    const updateCallback = recurringId ? updateRecurringActivity : updateActivity;
    const entityId = recurringId ?? data.id;

    updateCallback(
      {
        id: entityId,
        name: data.name,
        details: {
          school: data.details.school.id,
          ...(isFieldExist(data.details.type) && { type: data.details.type }),
          ...(isFieldExist(data.details.date_and_time) && formData.date && { date_and_time: formData.date.toISOString() }),
          ...(isFieldExist(data.details.duration) && { duration: formData.duration }),
        },
        ...(data.details.recurring_group !== null && typeof recurringId === 'number' && {
          ...(formData.interval && { weekly_interval: formData.interval }),
          ...(formData.daysOfWeek && { days_of_week: formData.daysOfWeek }),
          ...(formData.startDate && { start_date_and_time: formData.startDate.toISOString() }),
          ...(formData.endDate && { end_date_and_time: formData.endDate.toISOString() }),
        }),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <ActivitySectionHeader
        title="Date & Time Details"
        editing={isEditing}
        loading={isPending}
        recurringLoading={isRecurringPending}
        allowRecurring={data.details.recurring_group !== null}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit((data) => saveDetailsClickHandler(data))}
        onRecurringSave={form.handleSubmit((formData) => saveDetailsClickHandler(formData, data.details.recurring_group?.id))}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              {(isFieldExist(data.details.date_and_time)) && (
                <FormField
                  control={form.control}
                  name="date"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Date & Time
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <DatePicker
                            mode="single"
                            placeholder="Select date & time"
                            formatterString="MMM dd, yyyy, h:mm a"
                            isError={!!fieldState.error}
                            triggerDisabled={field.disabled}
                            selected={field.value}
                            defaultMonth={field.value}
                            captionLayout="dropdown"
                            onDayClick={field.onChange}
                            onTimeChange={field.onChange}
                            triggerClassName="max-h-9"
                            contentClassName="min-w-[320px]"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(data.details.duration) && (
                <FormField
                  control={form.control}
                  name="duration"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Duration (mins)
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!form.formState.errors.duration}
                            placeholder="Enter duration"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(data.details.recurring_group?.start_date_and_time) && (
                <FormField
                  control={form.control}
                  name="startDate"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Start Date & Time
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <DatePicker
                            mode="single"
                            placeholder="Select date & time"
                            formatterString="MMM dd, yyyy, h:mm a"
                            isError={!!fieldState.error}
                            triggerDisabled={field.disabled}
                            selected={field.value}
                            defaultMonth={field.value}
                            captionLayout="dropdown"
                            onDayClick={field.onChange}
                            onTimeChange={field.onChange}
                            triggerClassName="max-h-9"
                            contentClassName="min-w-[320px]"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(data.details.recurring_group?.end_date_and_time) && (
                <FormField
                  control={form.control}
                  name="endDate"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        End Date & Time
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <DatePicker
                            mode="single"
                            placeholder="Select date & time"
                            formatterString="MMM dd, yyyy, h:mm a"
                            isError={!!fieldState.error}
                            triggerDisabled={field.disabled}
                            selected={field.value}
                            defaultMonth={field.value}
                            captionLayout="dropdown"
                            onDayClick={field.onChange}
                            onTimeChange={field.onChange}
                            triggerClassName="max-h-9"
                            contentClassName="min-w-[320px]"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(data.details.recurring_group?.weekly_interval) && (
                <FormField
                  control={form.control}
                  name="interval"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Weekly Occurrence</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <RadixSelect onValueChange={field.onChange} value={field.value}>
                          <FormControl>
                            <RadixSelectTrigger
                              className={cn('max-h-9 capitalize', {
                                'border-error-main': !!fieldState.error,
                              })}
                            >
                              <RadixSelectValue placeholder="Select weekly occurrence" />
                            </RadixSelectTrigger>
                          </FormControl>
                          <RadixSelectContent>
                            {Object.values(ActivityWeeklyOccurrence).map((value) => (
                              <RadixSelectItem key={value} value={value} className="capitalize">
                                {value}
                              </RadixSelectItem>
                            ))}
                          </RadixSelectContent>
                        </RadixSelect>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(data.details.recurring_group?.days_of_week) && (
                <FormField
                  control={form.control}
                  name="daysOfWeek"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Days of Week</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!fieldState.error}
                            modalPopover
                            showSearch
                            selectedOptions={DAYS_OF_WEEK_OPTIONS.filter((option) => field.value?.includes(option.value))}
                            placeholder="Select days"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            onOptionChange={field.onChange}
                          >
                            {DAYS_OF_WEEK_OPTIONS.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
            </Form>
          )
        : (
            <DescriptionList className="gap-2">
              {isFieldExist(data.details.date_and_time) && (
                <DescriptionItem className="grid grid-cols-2">
                  <DescriptionTerm>Date & Time</DescriptionTerm>
                  <DescriptionDetails className="m-0">{format(new Date(data.details.date_and_time), 'MMMM d, yyyy, h:mm a')}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(data.details.duration) && (
                <DescriptionItem className="grid grid-cols-2">
                  <DescriptionTerm>Duration (mins)</DescriptionTerm>
                  <DescriptionDetails className="m-0">{data.details.duration ?? 0}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(data.details.recurring_group?.end_date_and_time) && (
                <DescriptionItem className="grid grid-cols-2">
                  <DescriptionTerm>Start Date & Time</DescriptionTerm>
                  <DescriptionDetails className="m-0">
                    {format(new Date(data.details.recurring_group.start_date_and_time), 'MMMM d, yyyy, h:mm a')}
                  </DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(data.details.recurring_group?.end_date_and_time) && (
                <DescriptionItem className="grid grid-cols-2">
                  <DescriptionTerm>End Date & Time</DescriptionTerm>
                  <DescriptionDetails className="m-0">
                    {format(new Date(data.details.recurring_group.end_date_and_time), 'MMMM d, yyyy, h:mm a')}
                  </DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(data.details.recurring_group?.weekly_interval) && (
                <DescriptionItem className="grid grid-cols-2">
                  <DescriptionTerm>Weekly Occurrence</DescriptionTerm>
                  <DescriptionDetails className="m-0 capitalize">{data.details.recurring_group.weekly_interval}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(data.details.recurring_group?.days_of_week) && (
                <DescriptionItem className="grid grid-cols-2">
                  <DescriptionTerm>Days of Week</DescriptionTerm>
                  <DescriptionDetails className="m-0 capitalize">
                    {data.details.recurring_group.days_of_week.join(', ')}
                  </DescriptionDetails>
                </DescriptionItem>
              )}
            </DescriptionList>
          )}
    </div>
  );
});
