import { useMutation } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { deleteTag } from './delete-tag';
import { TAGS_QUERY_KEY } from './tags.const';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TDeleteTagPayload } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useDeleteTag = (options?: TCustomUseMutationOptions<AxiosResponse, AxiosError, TDeleteTagPayload>) => {
  return useMutation({
    ...options,
    mutationKey: [TAGS_QUERY_KEY.DELETE_TAG],
    mutationFn: deleteTag,
    onSuccess: (...args) => {
      showSuccessToast('System message', 'Tag deleted successfully');
      options?.onSuccess?.(...args);
    },
    onError: (error, ...args) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to delete tag');
      }
      options?.onError?.(error, ...args);
    },
  });
};
