import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { formatDate, formatDateWithTime } from '@purple/shared-utils';
import { Heading, Separator, Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { BasicDetailsTabSkeleton } from './BasicDetailsTabSkeleton';
import type { TAdminSubmitterTypeDetail } from '~/services';

type TBasicDetailsTabProperties = {
  data?: TAdminSubmitterTypeDetail;
};

const BasicDetailsTab = ({ data }: TBasicDetailsTabProperties) => {
  const { is_active, needs, type, created_by, created_at, last_modified_by, updated_at } = data || {};

  const status = useMemo(() => is_active ? 'Active' : 'Inactive', [is_active]);

  const needTypes = useMemo(() => {
    const filteredNeeds = type ? needs?.filter((need) => need.submitter_types.includes(type)) : [];
    return filteredNeeds?.map((need) => need.name).join(', ') || '-';
  }, [needs, type]);

  if (!data) return <BasicDetailsTabSkeleton />;

  return (
    <div className="flex flex-col">
      <div className="px-6 py-5">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Basic Details</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex flex-col gap-4 px-6 py-[30px]">
        <div className="flex flex-row gap-6">
          <Text variant="size-16" type="body-400" className="min-w-[260px] whitespace-nowrap text-grey-600">Status</Text>
          <Text variant="size-16" type="body-500" className="text-grey-950">{status}</Text>
        </div>
        <div className="flex flex-row gap-6">
          <Text variant="size-16" type="body-400" className="min-w-[260px] whitespace-nowrap text-grey-600">Available need types</Text>
          <Text variant="size-16" type="body-500" className="text-grey-950">{needTypes}</Text>
        </div>
        <div className="flex flex-row gap-6">
          <Text variant="size-16" type="body-400" className="min-w-[260px] whitespace-nowrap text-grey-600">Created By</Text>
          {created_by
            ? (
                <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({
                  dynamicParameters: { id: created_by.id },
                })}
                >
                  <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{created_by.full_name || '-'}</span>
                </Link>
              )
            : (<Text variant="size-16" type="body-500" className="text-grey-950">-</Text>)}
        </div>
        <div className="flex flex-row gap-6">
          <Text variant="size-16" type="body-400" className="min-w-[260px] whitespace-nowrap text-grey-600">Created Date</Text>
          <Text variant="size-16" type="body-500" className="text-grey-950">{created_at ? formatDate(created_at) : '-'}</Text>
        </div>
        <div className="flex flex-row gap-6">
          <Text variant="size-16" type="body-400" className="min-w-[260px] whitespace-nowrap text-grey-600">Last Modified By</Text>
          {last_modified_by
            ? (
                <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({
                  dynamicParameters: { id: last_modified_by.id },
                })}
                >
                  <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{last_modified_by.full_name || '-'}</span>
                </Link>
              )
            : (<Text variant="size-16" type="body-500" className="text-grey-950">-</Text>)}
        </div>
        <div className="flex flex-row gap-6">
          <Text variant="size-16" type="body-400" className="min-w-[260px] whitespace-nowrap text-grey-600">Last Updated Date & Time</Text>
          <Text variant="size-16" type="body-500" className="text-grey-950">{updated_at ? formatDateWithTime(updated_at) : '-'}</Text>
        </div>
      </div>
    </div>
  );
};

export { BasicDetailsTab };
