import { getRandomIntegerInRange } from '@purple/shared-utils';
import { Skeleton } from '@purple/ui';

export const BasicDetailsSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-4">
        <Skeleton className="h-[25px] w-[112px]" />
        <Skeleton className="h-[25px] w-[60px]" />
      </div>
      <div className="flex w-full flex-col gap-6 px-6 py-8 lg:flex-row">
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full items-center justify-start gap-4">
            <div className="flex w-full max-w-64 flex-1">
              <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
            </div>
            <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(100, 240) }} />
          </div>
          <div className="flex w-full items-center justify-start gap-4">
            <div className="flex w-full max-w-64 flex-1">
              <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
            </div>
            <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(100, 240) }} />
          </div>
          <div className="flex w-full items-center justify-start gap-4">
            <div className="flex w-full max-w-64 flex-1">
              <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
            </div>
            <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(100, 440) }} />
          </div>
          <div className="flex w-full items-center justify-start gap-4">
            <div className="flex w-full max-w-64 flex-1">
              <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
            </div>
            <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(100, 240) }} />
          </div>
          <div className="flex w-full items-center justify-start gap-4">
            <div className="flex w-full max-w-64 flex-1">
              <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
            </div>
            <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(100, 240) }} />
          </div>
          <div className="flex w-full items-center justify-start gap-4">
            <div className="flex w-full max-w-64 flex-1">
              <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
            </div>
            <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(100, 240) }} />
          </div>
          <div className="flex w-full items-center justify-start gap-4">
            <div className="flex w-full max-w-64 flex-1">
              <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
            </div>
            <Skeleton className="h-6 w-full" style={{ maxWidth: getRandomIntegerInRange(100, 240) }} />
          </div>
        </div>
      </div>
    </div>
  );
};
