import { constructNavigationUrl } from '@purple/shared-utils';
import type { TAdminAppRoutes } from './types';

export const AdminRoutes: TAdminAppRoutes = {
  Auth: {
    Login: {
      Root: {
        path: '/login',
        name: 'Login',
        key: 'login',
        makePath: () => '/login',
      },
    },
    ForgotPassword: {
      Root: {
        path: '/forgot-password',
        name: 'Forgot Password',
        key: 'forgot-password',
        makePath: () => '/forgot-password',
      },
    },
  },
  App: {
    Home: {
      Root: {
        path: '/',
        name: 'Home',
        key: 'home',
        makePath: () => '/',
      },
    },
    DocumentedActions: {
      Root: {
        path: '/documented-actions',
        name: 'Documented Actions',
        key: 'documented-actions',
        makePath: (options) => constructNavigationUrl('/documented-actions', options?.queries),
      },
    },
    SafPrioritized: {
      Root: {
        path: '/saf-prioritized',
        name: 'SAF Prioritized',
        key: 'saf-prioritized',
        makePath: (options) => constructNavigationUrl('/saf-prioritized', options?.queries),
      },
    },
    NotLoggedInReport: {
      Root: {
        path: '/not-logged-in-report',
        name: 'Not Logged In Report',
        key: 'not-logged-in-report',
        makePath: (options) => constructNavigationUrl('/not-logged-in-report', options?.queries),
      },
    },
    ThisWeeksLogins: {
      Root: {
        path: '/this-weeks-logins-report',
        name: 'This Weeks Logins',
        key: 'this-weeks-logins-report',
        makePath: (options) => constructNavigationUrl('/this-weeks-logins-report', options?.queries),
      },
    },
    UserFrequencyDistribution: {
      Root: {
        path: '/frequency-distribution-report',
        name: 'User Frequency Distribution',
        key: 'frequency-distribution-report',
        makePath: (options) => constructNavigationUrl('/frequency-distribution-report', options?.queries),
      },
    },
    LoggedInByRole: {
      Root: {
        path: '/logged-in-by-role-report',
        name: 'Logged In By Role',
        key: 'logged-in-by-role-report',
        makePath: (options) => constructNavigationUrl('/logged-in-by-role-report', options?.queries),
      },
    },
    Profile: {
      Root: {
        path: '/profile',
        name: 'Profile',
        key: 'profile',
        makePath: () => '/profile',
      },
    },
    Districts: {
      Root: {
        path: '/districts',
        name: 'Districts',
        key: 'districts',
        makePath: (options) => constructNavigationUrl('/districts', options?.queries),
      },
      Details: {
        path: '/districts/:id',
        name: 'District Details',
        key: 'district-details',
        makePath: (options) => constructNavigationUrl(`/districts/${options.dynamicParameters.id}`, options?.queries),
      },
      DistrictSetUp: {
        path: '/districts/set-up',
        name: 'District Set Up',
        key: 'district-set-up',
        makePath: (options) => constructNavigationUrl('/districts/set-up', options?.queries),
      },
    },
    Schools: {
      Root: {
        path: '/schools',
        name: 'Schools',
        key: 'schools',
        makePath: () => '/schools',
      },
      Details: {
        path: '/schools/:id',
        name: 'School Details',
        key: 'school-details',
        makePath: (options) => constructNavigationUrl(`/schools/${options.dynamicParameters.schoolId}`, options?.queries),
      },
    },
    Users: {
      Root: {
        path: '/users',
        name: 'Users',
        key: 'users',
        makePath: () => '/users',
      },
      UserDetail: {
        Root: {
          path: '/users/:userId',
          name: 'User Details',
          key: 'users-detail',
          makePath: (options) => constructNavigationUrl(`/users/${options.dynamicParameters.id}`, options?.queries),
        },
        UserManagePermissions: {
          path: '/users/:userId/manage-permissions',
          name: 'User Manage Permissions',
          key: 'user-manage-permissions',
          makePath: (options) => `/users/${options.dynamicParameters.id}/manage-permissions`,
        },
      },
      Permissions: {
        Root: {
          path: '/users/permissions',
          name: 'Permissions',
          key: 'permissions',
          makePath: () => '/users/permissions',
        },
        ManagePermissions: {
          path: '/users/permissions/manage',
          name: 'Manage Permissions',
          key: 'manage-permissions',
          makePath: (options) => constructNavigationUrl('/users/permissions/manage', options?.queries),
        },
      },
      Roles: {
        Root: {
          path: '/users/roles',
          name: 'Roles',
          key: 'roles',
          makePath: () => '/users/roles',
        },
        ManageRoles: {
          path: '/users/roles/manage',
          name: 'Manage Roles',
          key: 'manage-roles',
          makePath: (options) => constructNavigationUrl('/users/roles/manage', options?.queries),
        },
      },
    },
    Contacts: {
      Root: {
        path: '/contacts',
        name: 'Contacts',
        key: 'contacts',
        makePath: (options) => constructNavigationUrl('/contacts', options?.queries),
      },
      Details: {
        path: '/contacts/:contactId',
        name: 'Contact Details',
        key: 'contact-details',
        makePath: (options) => `/contacts/${options.dynamicParameters.contactId}`,
      },
    },
    Dashboard: {
      Root: {
        path: '/dashboard',
        name: 'Dashboard',
        key: 'dashboard',
        makePath: () => '/dashboard',
      },
    },
    Visualizer: {
      Root: {
        path: '/visualizer',
        name: 'Visualizer',
        key: 'visualizer',
        makePath: () => '/visualizer',
      },
      Student: {
        path: '/visualizer/student',
        name: 'Student Visualizer',
        key: 'student-visualizer',
        makePath: () => '/visualizer/student',
      },
      StudentSetUp: {
        path: '/visualizer/student/setup',
        name: 'Student Setup',
        key: 'student-setup',
        makePath: (options) => constructNavigationUrl('/visualizer/student/setup', options?.queries),
      },
    },
    Activities: {
      Root: {
        path: '/activities',
        name: 'Community Activities',
        key: 'community-activities',
        makePath: (options) => constructNavigationUrl('/activities', options?.queries),
      },
      Details: {
        path: '/activities/:id',
        name: 'Community Activity Details',
        key: 'community-activity-details',
        makePath: (options) => constructNavigationUrl(`/activities/${options.dynamicParameters.id}`, options?.queries),
      },
      Types: {
        Root: {
          path: '/activities/types',
          name: 'Activity Types',
          key: 'activity-types',
          makePath: () => '/activities/types',
        },
        Details: {
          path: '/activities/types/:id',
          name: 'Activity Type Details',
          key: 'activity-type-details',
          makePath: (options) => constructNavigationUrl(`/activities/types/${options.dynamicParameters.id}`, options?.queries),
        },
      },
    },
    Actions: {
      Root: {
        path: '/actions',
        name: 'Actions',
        key: 'actions',
        makePath: () => '/actions',
      },
      Student: {
        Root: {
          path: '/actions/student',
          name: 'Student Actions',
          key: 'student-actions',
          makePath: (options) => constructNavigationUrl('/actions/student', options?.queries),
        },
        Details: {
          path: '/actions/student/:actionId',
          name: 'Action Details',
          key: 'action-details',
          makePath: (options) => constructNavigationUrl(`/actions/student/${options.dynamicParameters.actionId}`, options?.queries),
        },
        ActionTypesDetails: {
          path: '/actions/student/types/:id',
          name: 'Action Types Details',
          key: 'action-types-details',
          makePath: (options) => constructNavigationUrl(`/actions/student/types/${options.dynamicParameters.id}`, options?.queries),
        },
      },
    },
    Safs: {
      Root: {
        path: '/safs',
        name: 'SAFs',
        key: 'safs',
        makePath: (options) => constructNavigationUrl('/safs', options?.queries),
      },
      SafDetail: {
        path: '/safs/:safId',
        name: 'Saf Details',
        key: 'safs-detail',
        makePath: (options) => constructNavigationUrl(`/safs/${options.dynamicParameters.id}`, options?.queries),
      },
      SubmitterTypeDetail: {
        path: '/safs/submitter-type/:typeId',
        name: 'Submitter Type Details',
        key: 'submitter-type-detail',
        makePath: (options) => constructNavigationUrl(`/safs/submitter-type/${options.dynamicParameters.id}`, options?.queries),
      },
    },
    Meetings: {
      Root: {
        path: '/meetings',
        name: 'Meetings',
        key: 'meetings',
        makePath: () => '/meetings',
      },
    },
    ServiceProviders: {
      Root: {
        path: '/service-providers',
        name: 'Service Providers',
        key: 'service-providers',
        makePath: () => '/service-providers',
      },
    },
    Surveys: {
      Root: {
        path: '/surveys',
        name: 'Surveys',
        key: 'surveys',
        makePath: () => '/surveys',
      },
    },
    BannerNotifications: {
      Root: {
        path: '/banner-notifications',
        name: 'Banner Notifications',
        key: 'banner-notifications',
        makePath: () => '/banner-notifications',
      },
    },
  },

  System: {
    NotFound: {
      Root: {
        path: '/404',
        name: 'Not Found',
        key: 'not-found',
        makePath: () => '/404',
      },
    },
    ServerError: {
      Root: {
        path: '/500',
        name: 'Server Error',
        key: 'server-error',
        makePath: () => '/500',
      },
    },
    Print: {
      path: '/print',
      name: 'Print',
      key: 'print',
      makePath: (options) => constructNavigationUrl('/print', options?.queries),
    },
  },
} as const;

export const WHITE_LIST_ROUTES = [] as const;
