import { SAF_PRIORITY, SAF_STATUS } from '@purple/shared-types';

export const SafPriorityToBadgeVariant = {
  [SAF_PRIORITY.HIGH]: 'danger',
  [SAF_PRIORITY.MEDIUM]: 'warning',
  [SAF_PRIORITY.LOW]: 'info',
} as const;

export const SafStatusToBadgeVariant = {
  [SAF_STATUS.CLOSED]: 'neutral',
  [SAF_STATUS.NEW]: 'info',
  [SAF_STATUS.ON_HOLD]: 'warning',
  [SAF_STATUS.PRIORITIZED]: 'success-light',
} as const;
