import { Separator } from '@purple/ui';
import { ContactTabContainer } from '../../components';
import { AddressSection } from './AddressSection';
import { DetailsSection } from './DetailsSection';
import type { FC } from 'react';

export const BasicDetails: FC = () => {
  return (
    <ContactTabContainer title="Basic Details" className="gap-6 lg:flex-row">
      <DetailsSection />
      <Separator orientation="vertical" className="h-px w-full bg-grey-200 lg:h-auto lg:w-px" />
      <AddressSection />
    </ContactTabContainer>
  );
};
