import { getRandomIntegerInRange } from '@purple/shared-utils';
import { Skeleton } from '@purple/ui';

export const GeneralActionsSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4 px-4 pt-8">
      <Skeleton className="h-5 w-full max-w-[160px]" />
      <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
        <div className="flex min-h-[102px] w-full flex-col gap-2 rounded-lg border border-grey-200 bg-white px-6 py-4">
          <Skeleton className="h-5 w-full max-w-[90px]" style={{ maxWidth: getRandomIntegerInRange(60, 150) }} />
          <div className="mt-auto flex w-full flex-wrap items-baseline justify-start gap-1">
            <Skeleton className="h-10 w-full max-w-[60px]" />
          </div>
        </div>
        <div className="flex min-h-[102px] w-full flex-col gap-2 rounded-lg border border-grey-200 bg-white px-6 py-4">
          <Skeleton className="h-5 w-full max-w-[90px]" style={{ maxWidth: getRandomIntegerInRange(60, 150) }} />
          <div className="mt-auto flex w-full flex-wrap items-baseline justify-start gap-1">
            <Skeleton className="h-10 w-full max-w-[60px]" />
          </div>
        </div>
        <div className="flex min-h-[102px] w-full flex-col gap-2 rounded-lg border border-grey-200 bg-white px-6 py-4">
          <Skeleton className="h-5 w-full max-w-[90px]" style={{ maxWidth: getRandomIntegerInRange(60, 150) }} />
          <div className="mt-auto flex w-full flex-wrap items-baseline justify-start gap-1">
            <Skeleton className="h-10 w-full max-w-[60px]" />
          </div>
        </div>
        <div className="flex min-h-[102px] w-full flex-col gap-2 rounded-lg border border-grey-200 bg-white px-6 py-4">
          <Skeleton className="h-5 w-full max-w-[90px]" style={{ maxWidth: getRandomIntegerInRange(60, 150) }} />
          <div className="mt-auto flex w-full flex-wrap items-baseline justify-start gap-1">
            <Skeleton className="h-10 w-full max-w-[60px]" />
          </div>
        </div>
        <div className="flex min-h-[102px] w-full flex-col gap-2 rounded-lg border border-grey-200 bg-white px-6 py-4">
          <Skeleton className="h-5 w-full max-w-[90px]" style={{ maxWidth: getRandomIntegerInRange(60, 150) }} />
          <div className="mt-auto flex w-full flex-wrap items-baseline justify-start gap-1">
            <Skeleton className="h-10 w-full max-w-[60px]" />
          </div>
        </div>
      </div>
    </div>
  );
};
