import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIVITIES_QUERY_KEYS } from '../activities.const';
import { bulkActivitiesDelete } from '../requests';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActivityBulkDeletePayload } from '../activities.types';

export const useActivitiesBulkDelete = (
  options?: TCustomUseMutationOptions<unknown, AxiosError, TActivityBulkDeletePayload>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [ACTIVITIES_QUERY_KEYS.BULK_DELETE_ACTIVITIES],
    mutationFn: bulkActivitiesDelete,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_LIST] });
      queryClient.invalidateQueries({ queryKey: [ACTIVITIES_QUERY_KEYS.GET_ACTIVITY_INSTANCES_COUNT] });
      showSuccessToast('System message', 'Activities deleted successfully');
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      showErrorToast('System message', 'Failed to delete activities');
      options?.onError?.(...args);
    },
  });
};
