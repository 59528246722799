import type { ValueOf } from '@purple/shared-types';

export const ModalType = {
  // SAfs
  ADD_NEW_RULE: 'add-new-rule',
  EDIT_RULE: 'edit-rule',
  TRANSFER_SAF: 'transfer-saf',
  DEACTIVATE_SUBMITTER_TYPE: 'deactivate-submitter-type',
  ACTIVATE_SUBMITTER_TYPE: 'activate-submitter-type',
  CONFIRM_CHANGE_OWNER: 'confirm-change-owner',
  BULK_IMPORT_ROUTING_RULES: 'bulk-import-routing-rules',
  // GENERAL
  DATA_MAPPING: 'data-mapping',
  LOG_OUT: 'log-out',
  UNSAVED_CHANGES: 'unsaved-changes',
  SET_UP_TAKE_CONTROL: 'set-up-take-control',
  UPLOAD_DISTRICT_LOGO: 'upload-district-logo',
  CHANGE_DISTRICT_STATUS: 'change-district-status',
  CREATE_DISTRICT_CUSTOM_PAGE: 'create-district-custom-page',
  EDIT_DISTRICT_CUSTOM_PAGE: 'edit-district-custom-page',
  DELETE_DISTRICT_CUSTOM_PAGE: 'delete-district-custom-page',
  DELETE_DISTRICT_LOGO: 'delete-district-logo',
  USER_ACTIVATE_MODAL: 'user-activate-modal',
  BULK_USERS_ACTIVATE_MODAL: 'bulk-users-activate-modal',
  BULK_USERS_DEACTIVATE: 'bulk-users-deactivate',
  SAF_DELETE: 'saf-delete',
  SAF_DELETE_BULK: 'saf-delete-bulk',
  SAF_RULE_DELETE: 'saf-rule-delete',
  SAF_RULE_DELETE_BULK: 'saf-rule-delete-bulk',
  USER_DELETE: 'user-delete',
  USER_ROLES: 'user-roles',
  BULK_USERS_WITH_SAF_DEACTIVATE: 'bulk-users-with-saf-deactivate',
  BRAND_COLOR_PICKER: 'brand-color-picker',
  ADD_SSO_CONNECTION: 'add-sso-connection',
  DELETE_SSO_CONNECTION: 'delete-sso-connection',
  DELETE_ACTION: 'delete-action',
  ACTION_NOTE_PREVIEW: 'action-note-preview',
  // VISUALIZER
  FLAG_CALCULATION: 'visualizer-flag-calculation',
  DELETE_TREND_FLAG: 'visualizer-delete-flag',
  ADD_GRADE_FLAG: 'visualizer-add-grade-flag',
  UPDATE_FLAG_FORMULA_TYPE: 'visualizer-update-flag-formula-type',
  DELETE_ROLE: 'delete-role',
  DELETE_PERMISSION_GROUP: 'delete-permission-group',
  // SCHOOL
  DELETE_SCHOOL_HIERARCHY_NODE: 'delete-school-hierarchy-node',
  CHANGE_SCHOOL_STATUS: 'change-school-status',
  UPDATE_SCHOOL_BUSINESS_DAYS: 'update-school-business-days',
  UPDATE_SCHOOL_HOLIDAYS: 'update-school-holidays',
  // TAGS
  CREATE_CUSTOM_TAG: 'create-tag',
  UPDATE_CUSTOM_TAG: 'update-tag',
  DELETE_CUSTOM_TAG: 'delete-tag',
  // BANNER
  DELETE_BANNER: 'delete-banner',
  BULK_DELETE_BANNERS: 'bulk-delete-banners',
  MANAGE_BANNER: 'manage-banner',
  DELETE_USER_PERMISSION: 'delete-user-permission',
  REMOVE_NEGATIVE_PERMISSION: 'remove-negative-permission',
  ADD_INVITE_PURPLE_USER: 'add-invite-purple-user',
  BULK_INVITE_PURPLE_USERS: 'bulk-invite-purple-users',
  // CONTACTS
  ADD_CONTACT: 'add-contact',
  BULK_IMPORT_CONTACTS: 'bulk-import-contacts',
  // CONTACTS
  DELETE_CONTACTS_PRIORITY_LIST: 'delete-contacts-priority-list',
  MANAGE_CONTACTS_PRIORITY_LIST: 'manage-contacts-priority-list',
  SHARE_CONTACTS_PRIORITY_LIST: 'share-contacts-priority-list',
  ADD_TO_EXISTING_CONTACTS_PRIORITY_LIST: 'add-to-existing-contacts-priority-list',
  REMOVE_PRIORITY_LIST_CONTACTS: 'remove-priority-list-contacts',
  ADD_CONTACTS_TO_PRIORITY_LIST: 'add-contacts-to-priority-list',
  SEND_EMAIL_TO_CONTACTS: 'send-email-to-contacts',
  DELETE_CONTACT: 'delete-contact',
  ADD_CONTACT_STUDENTS: 'add-contact-students',
  // STUDENTS
  DELETE_STUDENT_GUARDIAN: 'delete-student-guardian',
  // FILES
  UPLOAD_FILE_PREVIEW: 'upload-file-preview',
  FILES_ACCESS_SETTINGS: 'files-access-settings',
  DELETE_FILE: 'delete-file',
  EDIT_FILE: 'edit-file',
  // ACTIVITIES
  DELETE_ACTIVITY: 'delete-activity',
  BULK_DELETE_ACTIVITIES: 'bulk-delete-activities',
  ACTIVITY_TYPE_STATUS_CHANGE: 'activity-type-status-change',
  // USER
  IMPERSONATE_PLATFORM_CHOOSER: 'impersonate-platform-chooser',
  // ACTIONS
  BULK_IMPORT_ACTIONS: 'bulk-import-actions',
} as const;

export type TModalName = ValueOf<typeof ModalType>;
