import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TakeActionType } from '@purple/shared-types';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { createBulkReminders } from './create-bulk-remindres';
import type { AxiosError } from 'axios';
import type { TBulkUploadResponse } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActionBulkUploadResponse } from './actions.types';

export const useBulkUploadReminders = (queryOptions?: TCustomUseMutationOptions<TBulkUploadResponse<TActionBulkUploadResponse<typeof TakeActionType.REMINDER>>, AxiosError<{ uploaded_file: string[]; missing_columns?: string[] }>>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...queryOptions,
    mutationKey: [ACTIONS_QUERY_KEYS.CREATE_BULK_ACTIONS, TakeActionType.REMINDER],
    mutationFn: createBulkReminders,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_LIST] });
    },
    onError: (error) => {
      const { response } = error;
      if (response && isClientError(error)) {
        const { uploaded_file, missing_columns } = response.data;
        if (uploaded_file) {
          showErrorToast('File error', uploaded_file.join('\n'));
          return;
        }
        if (missing_columns) {
          showErrorToast('Missing columns', missing_columns.join('\n'));
          return;
        }
        showErrorToast('System message', 'Unknown error occurred while uploading actions');
      }
    },
  });
};
