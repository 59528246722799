import { useMemo, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { Form, FormControl, FormField, MultiSelect, MultiSelectItem } from '@purple/ui';
import { useGroupList } from '~/services';
import { DEFAULT_SEARCH_DELAY } from '../BasicDetailArea/constants';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { updateBasicGroupsSchema } from './updateBasicGroupsSchema';

type TBasicGroupsEditProperties = {
  form: UseFormReturn<z.infer<typeof updateBasicGroupsSchema>>;
};

const BasicGroupsEdit = ({ form }: TBasicGroupsEditProperties) => {
  const [rolesSearch, setRolesSearch] = useState<string>('');
  const [debouncedRolesSearch] = useDebounceValue(rolesSearch, DEFAULT_SEARCH_DELAY);

  const { data: groupsData, isLoading: isGroupsLoading } = useGroupList({
    requestParameters: {
      search: debouncedRolesSearch,
    },
  });

  const groupsSelectOptions = useMemo(() => groupsData?.results.map((group) => ({
    label: group.name,
    value: group.id.toString(),
  })) ?? [], [groupsData]);

  return (
    <Form providerProps={form} className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="groups"
        render={({ field }) => (
          <FormControl>
            <MultiSelect
              selectedOptions={field?.value}
              showSearch
              shouldFilter={false}
              searchPlaceholder="Search group name..."
              placeholder="Select groups"
              modalPopover
              onOptionChange={(_, selected) => field.onChange(selected)}
              size="sm"
              searchValue={rolesSearch}
              onSearchChange={setRolesSearch}
              emptyContent="Role not found."
              loading={isGroupsLoading}
            >
              {groupsSelectOptions.map((option) => (
                <MultiSelectItem
                  key={option.value}
                  value={option.value}
                  option={option}
                />
              ))}
            </MultiSelect>
          </FormControl>
        )}
      />
    </Form>
  );
};

export { BasicGroupsEdit };
