import { type FC, memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@purple/hooks';
import { SAF_AREA_SUBCATEGORIES_LABELS, type TActionDetails, TakeActionDocumentType, type TTakeActionDocumentType } from '@purple/shared-types';
import { constructTakeActionTitle, isFieldExist, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { cn, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, DescriptionDetails, DescriptionItem, DescriptionTerm, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, MultiSelect, MultiSelectItem, NumberInput, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, Text } from '@purple/ui';
import { useActionChoices, useStudents, useUpdateAction, useUserSchools } from '~/services';
import { detailsSchema } from './schema';
import type * as z from 'zod';

const DEFAULT_STUDENTS_LIMIT = 100;

type TDetailsSectionEditFormProperties = {
  action: TActionDetails;
  formId: string;
  loadingChange: (isLoading: boolean) => void;
  editModeChange: (isEditMode: boolean) => void;
};

type TFormValues = z.infer<typeof detailsSchema>;

export const DetailsEditForm: FC<TDetailsSectionEditFormProperties> = memo(({ action, formId, loadingChange, editModeChange }) => {
  const { id, title, details, created_at, record_action_type } = action;

  const defaultValues: TFormValues = useMemo(
    () => ({
      title,
      document: details.document_as,
      studentId: details.student?.id,
      schoolId: details.school?.id,
      type: details.type,
      subType: details.subtype,
      numberOfResources: details.number_of_resources_selected ?? 0,
      focusAreas: details.focus_areas?.map((area) => area.id.toString()),
      services: details.service_categories?.map((category) => category.id),
      quantity: details.quantity ?? 0,
    }),
    [details, title],
  );

  const [debouncedStudentSearchValue, setDebouncedStudentSearchValue] = useState<string>('');
  const [debouncedSchoolSearchValue, setDebouncedSchoolSearchValue] = useState<string>('');

  const { data: schools, isLoading: isSchoolsLoading } = useUserSchools({ requestParameters: { search: debouncedSchoolSearchValue } });
  const { subTypeOptions, typeOptions, focusAreaOptions, serviceOptions } = useActionChoices({
    payload: {
      actionId: action.id,
      record_action_type: action.record_action_type,
      students: details.student ? [details.student.id] : [],
    },
    shouldFetch: true,
  });

  const { data: students, isLoading: isStudentsLoading } = useStudents({
    parameters: {
      search: debouncedStudentSearchValue,
      limit: DEFAULT_STUDENTS_LIMIT,
      offset: 0,
    },
  });

  const { mutate: updateAction } = useUpdateAction();

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(detailsSchema),
    defaultValues,
  });

  const focusAreas = useMemo(() => {
    if (!isFieldExist(details.focus_areas)) return;
    return details.focus_areas.length > 0 ? details.focus_areas.map((area) => area.name).join(', ') : '—';
  }, [details]);
  const serviceCategories = useMemo(() => {
    if (!isFieldExist(details.service_categories)) return;
    return details.service_categories.length > 0
      ? details.service_categories.map((category) => SAF_AREA_SUBCATEGORIES_LABELS[category.name]).join(', ')
      : '—';
  }, [details]);

  const schoolsOptions = useMemo(
    () =>
      schools?.results.map((school) => ({
        label: school.name,
        value: school.id,
      })) ?? [],
    [schools?.results],
  );

  const studentsOptions = useMemo(
    () =>
      students?.results.map((student) => ({
        label: student.full_name ?? 'Unidentified student',
        value: student.id,
      })) ?? [],
    [students?.results],
  );

  const debouncedStudentSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedStudentSearchValue(searchQuery);
  }, 500);
  const debouncedSchoolSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSchoolSearchValue(searchQuery);
  }, 500);

  const updateDetailsSectionHandler = (formData: TFormValues) => {
    loadingChange(true);
    updateAction({
      id,
      ...(isFieldExist(details.type) && { type: formData.type }),
      ...(isFieldExist(details.subtype) && { subtype: formData.subType }),
      ...(isFieldExist(details.number_of_resources_selected) && {
        number_of_resources_selected: formData.numberOfResources,
      }),
      ...(isFieldExist(focusAreas) && formData.focusAreas && { focus_areas: formData.focusAreas.map(Number) }),
      ...(isFieldExist(serviceCategories) && { service_categories: formData.services }),
      ...(isFieldExist(details.quantity) && { quantity: formData.quantity }),
      ...(isFieldExist(details.document_as) && {
        document_as: formData.document,
        title: formData.title,
      }),
      ...(isFieldExist(details.student) && { student: formData.studentId }),
      ...(isFieldExist(details.school) && { school: formData.schoolId }),
    }, {
      onSuccess: () => {
        form.reset();
        editModeChange(false);
      },
      onSettled: () => {
        loadingChange(false);
      },
    });
  };

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Form providerProps={form} className="flex w-full flex-col gap-1" id={formId} onSubmit={form.handleSubmit(updateDetailsSectionHandler)}>
      {isFieldExist(details.student) && (
        <FormField
          control={form.control}
          name="studentId"
          render={({ field, fieldState }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel className="font-primary text-base font-normal text-grey-600">Student</FormLabel>
              <div className="flex w-full max-w-[320px] flex-col gap-1">
                <ComboBox modal>
                  <FormControl>
                    <ComboBoxTrigger
                      isError={!!fieldState.error}
                      placeholder="Select student"
                      selectedLabel={
                        studentsOptions.find((option) => option.value === field.value)?.label
                        ?? details.student?.full_name
                      }
                      className="max-h-9"
                    />
                  </FormControl>
                  <ComboBoxContent
                    shouldFilter={false}
                    loading={isStudentsLoading}
                    searchPlaceholder="Search student..."
                    emptyContent="Student not found."
                    onSearchChange={debouncedStudentSearch}
                  >
                    {studentsOptions.map(({ label, value }) => (
                      <ComboBoxItem
                        key={value}
                        value={value}
                        selected={value === field.value}
                        onSelect={field.onChange}
                      >
                        {label}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      )}
      {isFieldExist(details.school) && (
        <FormField
          control={form.control}
          name="schoolId"
          render={({ field, fieldState }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel className="font-primary text-base font-normal text-grey-600">School</FormLabel>
              <div className="flex w-full max-w-[320px] flex-col gap-1">
                <ComboBox modal>
                  <FormControl>
                    <ComboBoxTrigger
                      isError={!!fieldState.error}
                      placeholder="Select school"
                      selectedLabel={
                        schoolsOptions.find((option) => option.value === field.value)?.label ?? details.school?.name
                      }
                      className="max-h-9"
                    />
                  </FormControl>
                  <ComboBoxContent
                    shouldFilter={false}
                    loading={isSchoolsLoading}
                    searchPlaceholder="Search school..."
                    emptyContent="School not found."
                    onSearchChange={debouncedSchoolSearch}
                  >
                    {schoolsOptions.map(({ label, value }) => (
                      <ComboBoxItem
                        key={value}
                        value={value}
                        selected={value === field.value}
                        onSelect={field.onChange}
                      >
                        {label}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      )}
      {isFieldExist(details.service_provider) && (
        <DescriptionItem>
          <DescriptionTerm>Service Provider</DescriptionTerm>
          <DescriptionDetails>
            <Text variant="size-16" type="body-400">{details.service_provider.name}</Text>
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.document_as) && (
        <FormField
          control={form.control}
          name="document"
          render={({ field, fieldState }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel className="font-primary text-base font-normal text-grey-600">Document as</FormLabel>
              <div className="flex w-full max-w-[320px] flex-col gap-1">
                <RadixSelect
                  onValueChange={(newValue) => {
                    field.onChange(newValue);
                    form.setValue('title', constructTakeActionTitle(
                      {
                        documentType: newValue as TTakeActionDocumentType,
                        actionType: record_action_type,
                        userName: details?.created_by?.full_name ?? 'Unknown User',
                        date: created_at,
                      },
                    ));
                  }}
                  value={field.value}
                >
                  <FormControl>
                    <RadixSelectTrigger
                      className={cn('max-h-9', {
                        'border-error-main': !!fieldState.error,
                      })}
                    >
                      <RadixSelectValue placeholder="Select document type" />
                    </RadixSelectTrigger>
                  </FormControl>
                  <RadixSelectContent>
                    {Object.values(TakeActionDocumentType).map((value) => (
                      <RadixSelectItem key={value} value={value}>
                        {snakeToCamelWithSpaces(value)}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      )}
      {isFieldExist(details.type) && (
        <FormField
          control={form.control}
          name="type"
          render={({ field, fieldState }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel className="font-primary text-base font-normal text-grey-600">Type</FormLabel>
              <div className="flex w-full max-w-[320px] flex-col gap-1">
                <ComboBox modal>
                  <FormControl>
                    <ComboBoxTrigger
                      isError={!!fieldState.error}
                      placeholder="Select type"
                      selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                      className="max-h-9"
                    />
                  </FormControl>
                  <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                    {typeOptions.map(({ label, value }) => (
                      <ComboBoxItem
                        key={value}
                        value={value}
                        selected={value === field.value}
                        onSelect={field.onChange}
                      >
                        {label}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      )}
      {isFieldExist(details.subtype) && (
        <FormField
          control={form.control}
          name="subType"
          render={({ field, fieldState }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel className="font-primary text-base font-normal text-grey-600">Subtype</FormLabel>
              <div className="flex w-full max-w-[320px] flex-col gap-1">
                <ComboBox modal>
                  <FormControl>
                    <ComboBoxTrigger
                      isError={!!fieldState.error}
                      placeholder="Select subtype"
                      selectedLabel={subTypeOptions.find((option) => option.value === field.value)?.label}
                      className="max-h-9"
                    />
                  </FormControl>
                  <ComboBoxContent searchPlaceholder="Search subtype..." emptyContent="Subtype not found.">
                    {subTypeOptions.map(({ label, value }) => (
                      <ComboBoxItem
                        key={value}
                        value={value}
                        selected={value === field.value}
                        onSelect={field.onChange}
                      >
                        {label}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      )}
      {isFieldExist(details.number_of_resources_selected) && (
        <FormField
          control={form.control}
          name="numberOfResources"
          render={({ field }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel className="font-primary text-base font-normal text-grey-600">
                Number of Resources
              </FormLabel>
              <div className="flex w-full max-w-[320px] flex-col gap-1">
                <FormControl>
                  <NumberInput
                    {...field}
                    type="number"
                    isError={!!form.formState.errors.numberOfResources}
                    placeholder="Enter number of resources"
                    className="h-9"
                    min={0}
                    changeOnWheel
                  />
                </FormControl>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      )}
      {isFieldExist(focusAreas) && (
        <FormField
          control={form.control}
          name="focusAreas"
          render={({ field }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel className="font-primary text-base font-normal text-grey-600">Focus Areas</FormLabel>
              <div className="flex w-full max-w-[320px] flex-col gap-1">
                <FormControl>
                  <MultiSelect
                    {...field}
                    isError={!!form.formState.errors.focusAreas}
                    modalPopover
                    showSearch
                    selectedOptions={focusAreaOptions.filter((service) => field.value?.includes(service.value))}
                    placeholder="Select areas"
                    classNames={{ trigger: 'min-h-9 py-1' }}
                    onOptionChange={field.onChange}
                  >
                    {focusAreaOptions.map((option) => (
                      <MultiSelectItem
                        key={option.value}
                        value={option.value}
                        option={option}
                        isSelected={field.value?.includes(option.value)}
                      />
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      )}
      {isFieldExist(serviceCategories)
      && (
        <FormField
          control={form.control}
          name="services"
          render={({ field }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel className="font-primary text-base font-normal text-grey-600">
                Service Categories
              </FormLabel>
              <div className="flex w-full max-w-[320px] flex-col gap-1">
                <FormControl>
                  <MultiSelect
                    {...field}
                    isError={!!form.formState.errors.services}
                    selectedOptions={serviceOptions.filter((service) => field.value?.includes(service.value))}
                    placeholder="Select categories"
                    classNames={{ trigger: 'min-h-9 py-1' }}
                    modalPopover
                    showSearch
                    onOptionChange={field.onChange}
                  >
                    {serviceOptions.map((option) => (
                      <MultiSelectItem
                        key={option.value}
                        value={option.value}
                        option={option}
                        isSelected={field.value?.includes(option.value)}
                      />
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      )}
      {isFieldExist(details.quantity) && (
        <FormField
          control={form.control}
          name="quantity"
          render={({ field }) => (
            <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
              <FormLabel className="font-primary text-base font-normal text-grey-600">Quantity</FormLabel>
              <div className="flex w-full max-w-[320px] flex-col gap-1">
                <FormControl>
                  <NumberInput
                    {...field}
                    type="number"
                    isError={!!form.formState.errors.quantity}
                    placeholder="Enter quantity"
                    className="h-9"
                    min={0}
                    changeOnWheel
                  />
                </FormControl>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      )}
      {isFieldExist(details.created_by) && (
        <DescriptionItem>
          <DescriptionTerm tag="p">Created By</DescriptionTerm>
          <DescriptionDetails tag="p">{details.created_by.full_name ?? 'N/A'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.saf) && (
        <DescriptionItem>
          {/* TODO: Add form field to update SAF id when the SAFs will be available */}
          <DescriptionTerm tag="p">Linked SAF</DescriptionTerm>
          <DescriptionDetails tag="p">{details.saf?.saf_number ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
    </Form>
  );
});
