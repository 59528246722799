import { AppTabs, Heading, Text } from '@purple/ui';
import { useContactsTabs } from './Tabs';

export const ContactsPage = () => {
  const { activeTab, contactsTabs, isTabsReady, tabChangeHandler } = useContactsTabs();
  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex flex-col gap-1">
        <Heading tag="h1" variant="size-22" type="heading-600" className="text-grey-title">
          Contacts
        </Heading>
        <Text variant="size-14" type="body-400" className="text-grey-950">
          List of contacts without Purple access
        </Text>
      </div>
      <AppTabs tabs={contactsTabs} listClassName="pl-2" value={activeTab} onValueChange={tabChangeHandler} isReady={isTabsReady} />
    </div>
  );
};
