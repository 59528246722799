import { useCallback, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { covertFullDateToDateWithoutTime } from '@purple/shared-utils';
import { Button, DateRangePicker, SearchInput, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTable, DataTableViewOptions, DistrictMultiSelect } from '~/components';
import { AdminRoutes, TableName } from '~/constants';
import { useDataTable } from '~/hooks';
import { DISTRICT_STATUSES, useFrequencyDistributionReport, useFrequencyDistributionReportExport } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { reportColumns } from './reportColumns';
import type { DateRange } from '@purple/ui';

const MIN_LOGINS = 3;
const FILE_NAME = 'frequency-distribution-users-report';

const DISTRICT_QUERY_NAME = 'district';

const UserFrequencyDistributionReport = () => {
  const [searchParameters] = useSearchParams();

  const { query: districtQuery, onQueryChange: onDistrictQueryChange } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const { query: rangeQuery, onQueryChange: onRangeQueryChange } = useQueryParameter({ queryName: 'range', resetOffset: true });

  const [timestamp__gte, timestamp__lte] = useMemo(() => {
    const [from, to] = rangeQuery ? rangeQuery.split(',') : [null, null];

    const convertedFrom = from ? covertFullDateToDateWithoutTime(from) : null;
    const convertedTo = to ? covertFullDateToDateWithoutTime(to) : null;

    return [convertedFrom, convertedTo];
  }, [rangeQuery]);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: reportData, isLoading: isReportLoading } = useFrequencyDistributionReport({
    requestParameters: {
      ...(districtQuery && { district: districtQuery }),
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      timestamp__gte,
      timestamp__lte,
      min_logins: MIN_LOGINS,
    },
  });

  const { mutate: exportReport, isPending: isExportingReport } = useFrequencyDistributionReportExport({
    fileName: FILE_NAME,
  });

  const users = useMemo(() => reportData?.results ?? [], [reportData?.results]);

  const { table } = useDataTable({
    name: TableName.REPORT_USER_FREQUENCY_DISTRIBUTION,
    columns: reportColumns,
    data: users,
    rowCount: reportData?.count,
  });

  const selectedDistricts = useMemo(() => {
    const array = districtQuery?.split(',') || [];
    return array;
  }, [districtQuery]);

  const exportClickHandler = () => {
    if (reportData) {
      exportReport({
        ...(districtQuery && { district: districtQuery }),
        search: debounceSearch,
        limit: reportData.count,
        offset: searchParameters.get(OFFSET_QUERY_NAME),
        ordering: searchParameters.get(SORT_QUERY_NAME),
        timestamp__gte,
        timestamp__lte,
        min_logins: MIN_LOGINS,
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
        },
      });
    }
  };

  const updateDatePeriod = useCallback((range: DateRange) => {
    const convertedFrom = covertFullDateToDateWithoutTime(range.from);
    const convertedTo = covertFullDateToDateWithoutTime(range.to || range.from);

    const rangeValue = `${convertedFrom},${convertedTo}`;
    onRangeQueryChange(rangeValue);
  }, [onRangeQueryChange]);

  const districtChangeHandler = (newValue: string[]) => {
    onDistrictQueryChange(newValue.join(','));
  };

  return (
    <div className="flex w-full flex-1 flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700 size-4 shrink-0" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Home.Root.path}>
          Back to Home Page
        </Link>
      </Button>
      <DataTable table={table} loading={isReportLoading} className="border-grey-200 shadow-custom-heavy rounded-lg border bg-white">
        <div className="flex w-full flex-col gap-4 p-4 pt-6">
          <Text variant="size-16" type="body-600" className="text-grey-title">User Login Frequency Distribution</Text>
          <div className="flex w-full items-center justify-between gap-4">
            <div className="flex w-full items-center gap-4">
              <div className="min-w-[200px] max-w-[200px]">
                <SearchInput
                  value={search}
                  placeholder="Search"
                  className="max-w-[300px]"
                  onChange={onSearchChange}
                  onClear={onClearSearch}
                />
              </div>
              <div className="min-w-[200px]">
                <DistrictMultiSelect values={selectedDistricts || ''} onChange={districtChangeHandler} placeholder="Select district" status={DISTRICT_STATUSES.PUBLISHED} />
              </div>
              <DateRangePicker initialDateFrom={timestamp__gte || ''} initialDateTo={timestamp__lte || ''} onUpdate={updateDatePeriod} triggerClassNames="h-10" />
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="secondary"
                  size="icon_40"
                  className="size-10 shrink-0"
                  iconLeft={<PurpleIcon name="download" />}
                  disabled={users.length === 0}
                  isLoading={isExportingReport}
                  onClick={exportClickHandler}
                />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>Export Table to Excel</TooltipContent>
              </TooltipPortal>
            </Tooltip>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </DataTable>
    </div>
  );
};

export { UserFrequencyDistributionReport };
