import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { ReadableContactType } from '@purple/shared-types';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import { CONTACTS_QUERY_KEYS } from '~/services';
import type { FC } from 'react';
import type { TContactDetailItem } from '~/services';

export const DetailsSectionContent: FC = () => {
  const { contactId } = useParams();
  const queryClient = useQueryClient();

  const contactDetails = queryClient.getQueryData<TContactDetailItem>([CONTACTS_QUERY_KEYS.GET_CONTACT_DETAILS, contactId]);

  return (
    <DescriptionList className="gap-2">
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">First Name</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {contactDetails?.first_name ?? '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">Last Name</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {contactDetails?.last_name ?? '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">Type</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {contactDetails && contactDetails.contact_type ? ReadableContactType[contactDetails.contact_type] : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">District</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {contactDetails?.district?.name ?? '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">Email</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {contactDetails?.email ?? '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem className="flex-nowrap">
        <DescriptionTerm className="w-1/2 shrink-0">Phone Number</DescriptionTerm>
        <DescriptionDetails className="w-1/2">
          {contactDetails && contactDetails.phone.length > 0 ? contactDetails.phone : '—'}
        </DescriptionDetails>
      </DescriptionItem>
    </DescriptionList>
  );
};
