import { formatDateShortMonthWithTime } from '@purple/shared-utils';
import { DatePicker, Form, FormControl, FormField, FormItem, FormMessage, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, Text } from '@purple/ui';
import { SafNeedsFilterComboBox, UserFilterComboBox } from '~/components';
import { SAFS_PRIORITY_OPTIONS, SAFS_STATUS_OPTIONS } from '~/constants';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { TAdminSafBasicDetailsView } from '~/services';
import type { updateSafDetailsSchema } from './updateSafDetailsSchema';

type TSafDetailsEditProperties = {
  data: TAdminSafBasicDetailsView;
  form: UseFormReturn<z.infer<typeof updateSafDetailsSchema>>;
};

const SafDetailsEdit = ({ data, form }: TSafDetailsEditProperties) => {
  const { subject, created_at, district } = data;

  return (
    <Form providerProps={form} className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Subject</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{subject}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">SAF Owner</Text>
        <FormField
          control={form.control}
          name="current_owner"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <UserFilterComboBox value={field.value} onChange={field.onChange} hasClearButton={false} className="h-[34px]" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Previous SAF Owner</Text>
        <FormField
          control={form.control}
          name="previous_owner"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <UserFilterComboBox value={field.value} onChange={field.onChange} hasClearButton={false} className="h-[34px]" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Date</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{formatDateShortMonthWithTime(created_at)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Prioritized Date</Text>
        <FormField
          control={form.control}
          name="priority_assigned_at"
          render={({ field, fieldState }) => (
            <FormItem className="flex w-full">
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  selected={field.value}
                  defaultMonth={field.value}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                  triggerClassName="max-h-9"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Status</Text>
        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItem className="w-full">
              <RadixSelect value={field.value} onValueChange={field.onChange}>
                <FormControl>
                  <RadixSelectTrigger className="h-[34px]">
                    <RadixSelectValue placeholder="Select status" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {SAFS_STATUS_OPTIONS.map((option) => (
                    <RadixSelectItem key={option.value} value={option.value} onSelect={field.onChange}>
                      {option.label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Priority</Text>
        <FormField
          control={form.control}
          name="priority"
          render={({ field }) => (
            <FormItem className="w-full">
              <RadixSelect value={field.value} onValueChange={field.onChange}>
                <FormControl>
                  <RadixSelectTrigger className="h-[34px]" hasClearButton onClearCallback={() => field.onChange('')}>
                    <RadixSelectValue placeholder="Select priority" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {SAFS_PRIORITY_OPTIONS.map((option) => (
                    <RadixSelectItem key={option.value} value={option.value} onSelect={field.onChange}>
                      {option.label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Need Type</Text>
        <FormField
          control={form.control}
          name="needs"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <SafNeedsFilterComboBox value={field.value} onChange={field.onChange} hasClearButton={false} districtId={district} isDistrictRequired className="h-[34px] max-w-[370px]" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </Form>
  );
};

export { SafDetailsEdit };
