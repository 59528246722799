import { getRandomIntegerInRange } from '@purple/shared-utils';
import { Skeleton } from '@purple/ui';

export const TagsSectionSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex h-8 flex-row items-center justify-between gap-2">
        <Skeleton className="h-[24px] w-[98px]" />
        <Skeleton className="h-[24px] w-[54px]" />
      </div>
      <div className="flex w-full flex-row gap-4">
        <Skeleton className="h-6 w-full rounded-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
        <Skeleton className="h-6 w-full rounded-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
        <Skeleton className="h-6 w-full rounded-full" style={{ maxWidth: getRandomIntegerInRange(80, 140) }} />
      </div>
    </div>
  );
};
