import { useMemo } from 'react';
import { Button, RadixPortal, Separator, useStepper } from '@purple/ui';
import { useUnsavedChanges } from '~/providers';
import { SET_UP_STEPS } from '../../constants';
import { SetUpHeader } from '../SetUpHeader';

type TStepCardContainerProperties = React.PropsWithChildren<{
  /**
   * The Id of the form that will be submitted.
   */
  formId?: string;
  /**
   * The loading state of the form.
   * @default false
   */
  isLoading?: boolean;
  isStepValid: boolean;
  onSave: () => void;
}>;

const StepCardContainer = (props: TStepCardContainerProperties) => {
  const { formId, isLoading = false, children, isStepValid, onSave } = props;

  const { checkUnsaved } = useUnsavedChanges();

  const { activeStep, prevStep } = useStepper();

  const showPrevious = useMemo(() => activeStep > 0, [activeStep]);
  const showNext = useMemo(() => {
    const stepsCount = SET_UP_STEPS.length;
    return stepsCount > activeStep + 1;
  }, [activeStep]);

  const previousStepClickHandler = () => {
    checkUnsaved(() => prevStep());
  };

  return (
    <div className="flex flex-col rounded-lg border border-grey-200 bg-white">
      <RadixPortal>
        <SetUpHeader canSaveAsDraft={isStepValid} onSave={onSave} isSaving={isLoading} />
      </RadixPortal>
      <div className="flex p-6">{children}</div>
      <Separator />
      <div className="flex justify-end px-6 py-4">
        {showPrevious && (
          <Button variant="tertiary" onClick={previousStepClickHandler}>
            Previous Step
          </Button>
        )}
        {showNext && (
          <Button type="submit" form={formId} variant="primary" isLoading={isLoading} disabled={isLoading}>
            Next Step
          </Button>
        )}
      </div>
    </div>
  );
};

export { StepCardContainer };
