import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { markAsRead } from './mark-as-read';
import { NOTIFICATIONS_QUERY_KEYS } from './notifications.const';

type TMarkAsReadOptions = {
  shouldRevalidate?: boolean;
};

export const useMarkAsRead = (options?: TMarkAsReadOptions) => {
  const { shouldRevalidate = false } = options || {};

  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [NOTIFICATIONS_QUERY_KEYS.MARK_NOTIFICATION_AS_READ],
    mutationFn: markAsRead,
    onSuccess: () => {
      if (shouldRevalidate) {
        queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_QUERY_KEYS.GET_NOTIFICATIONS_LIST] });
      }
    },
    onError: () => {
      showErrorToast('System message', 'Failed to mark notification as read');
    },
  });
};
