export const ACTIVITIES_QUERY_KEYS = {
  GET_ACTIVITY_LIST: 'GET_ACTIVITY_LIST',
  GET_ACTIVITY_FILTER_CHOICES: 'GET_ACTIVITY_FILTER_CHOICES',
  GET_ACTIVITY_FILTER_CHOICES_BY_ID: 'GET_ACTIVITY_FILTER_CHOICES_BY_ID',
  DELETE_ACTIVITY: 'DELETE_ACTIVITY',
  BULK_DELETE_ACTIVITIES: 'BULK_DELETE_ACTIVITIES',
  GET_ACTIVITY_INSTANCES_COUNT: 'GET_ACTIVITY_INSTANCES_COUNT',
  GET_ACTIVITY_ID_LIST: 'GET_ACTIVITY_ID_LIST',
  EXPORT: 'EXPORT-ACTIVITIES',
  GET_ACTIVITY_BASIC_INFO: 'GET_ACTIVITY_BASIC_INFO',
  GET_ACTIVITY_DETAILS: 'GET_ACTIVITY_DETAILS',
  GET_ACTIVITY_HISTORY: 'GET_ACTIVITY_HISTORY',
  UPDATE_ACTIVITY_DETAILS: 'UPDATE_ACTIVITY_DETAILS',
  UPDATE_RECURRING_ACTIVITY_DETAILS: 'UPDATE_RECURRING_ACTIVITY_DETAILS',
  GET_ACTIVITY_CHOICES: 'GET_ACTIVITY_CHOICES',
  UPDATE_ACTIVITY_TAGS: 'UPDATE_ACTIVITY_TAGS',
} as const;

export const ACTIVITY_TYPES_QUERY_KEYS = {
  GET_ACTIVITY_TYPES: 'GET_ACTIVITY_TYPES',
  GET_ACTIVITY_TYPE_DETAILS: 'GET_ACTIVITY_TYPE_DETAILS',
  GET_ACTIVITY_TYPE_ROLES: 'GET_ACTIVITY_TYPE_ROLES',
  GET_ACTIVITY_TYPE_GROUPS: 'GET_ACTIVITY_TYPE_GROUPS',
  UPDATE_ACTIVITY_TYPE: 'UPDATE_ACTIVITY_TYPE',
  UPDATE_ACTIVITY_TYPE_ROLES: 'UPDATE_ACTIVITY_TYPE_ROLES',
  UPDATE_ACTIVITY_TYPE_GROUPS: 'UPDATE_ACTIVITY_TYPE_GROUPS',
} as const;
